import { LangArrType } from "@feathers-client/i18n";

export interface ComponentOption {
  group: string;
  groupProps?: ComponentOptionProp;
  variants: ComponentVariant[];
  hideConfirm?: boolean;
}

export interface ComponentOptionProp {
  editorCols?: number;
}

export interface ComponentVariant {
  key: string;
  props?: Record<string, any>;
}

export interface ComponentDefinition {
  componentInfo?: ComponentOption;
  default: any;
}

export function defineComponent<T extends ComponentOption>(def: T) {
  return def;
}

export interface PageData {
  version: "v1";
  blocks: PageBlock[];
  name: LangArrType;
}

export interface PageBlock {
  id: string;
  name?: string;
  component: string;
  variant: string;
  props: Record<string, any>;
  styles?: Record<string, any>;
  responsive?: Record<string, any>[];
  slots?: Record<string, PageBlock[]>;
  bgStretchToFull?: boolean;
}

export interface PreviewCommandBase {
  type: string;
}

export type PreviewCommand =
  | PreviewCommandRequestSync
  | PreviewCommandSync
  | PreviewCommandClosed
  | PreviewCommandPing
  | PreviewCommandPong;

export interface PreviewCommandRequestSync extends PreviewCommandBase {
  type: "requestSync";
}

export interface PreviewCommandSync extends PreviewCommandBase {
  type: "sync";
  pageData: PageData;
  headerStyle: any;
}

export interface PreviewCommandClosed extends PreviewCommandBase {
  type: "closed";
}

export interface PreviewCommandPing extends PreviewCommandBase {
  type: "ping";
}

export interface PreviewCommandPong extends PreviewCommandBase {
  type: "pong";
}

export function stripKey(key: string) {
  if (key.startsWith("./")) key = key.substring(2);
  if (key.endsWith(".vue")) key = key.slice(0, -4);
  while (key.endsWith("/index")) {
    key = key.slice(0, -6);
  }
  return key.replace(/\/./g, r => r.slice(1).toUpperCase());
}
