import { getVersion } from "../nativeIntegrations";
import { ns } from "../messageQueue";

export async function supported() {
  if(!supportedTask) {
    supportedTask = (async () => {
      try {
        if (!getVersion()) return false;
        console.log("Check Auth support");
        if (!(await ns("auth").call("supported"))) return false;
        return true;
      } catch (e) {
        console.warn(e);
        return false;
      }
    })();
  }
  return supportedTask;
}

let supportedTask: Promise<boolean>;

export interface AuthCredentials {
  startURL: string;
  jwt?: string;
  username?: string;
  password?: string;
  save?: boolean;
  serverName?: string;
  shopId?: string;
  shopName?: string;
  serverVersion?: string;
  name?: any;
  userId?: string;
  deviceId?: string;
  deviceName?: string;
  deviceShortId?: string;
}

export function getLogin(newLogin?: boolean): Promise<AuthCredentials | false> {
  return ns("auth").call("getLogin", newLogin);
}

export async function setLogin(credentials: AuthCredentials, defer?: boolean) {
  return ns("auth").call("setLogin", credentials, defer);
}

export async function updateLogin(credentials: Partial<AuthCredentials>) {
  return ns("auth").call("updateLogin", credentials);
}

export async function reportLogin(success: boolean, metadata?: any) {
  return ns("auth").call("reportLogin", success, metadata);
}

export async function logout() {
  return ns("auth").call("logout");
}

export async function loginLoader() {
  return ns("auth").call("listLogins");
} 

export async function switchOtherLogin(login: any) {
  return ns("auth").call("switchOtherLogin", login);
}

export async function switchOtherServer(url: string) {
  return ns("auth").call("switchOtherServer", url);
}
