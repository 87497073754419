

import Dialog from "@feathers-client/mixins/Dialog";
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";

@Component
export default class AdjustFont extends mixins(Dialog) {

    beforeDestroy() {
        this.$pos.tempFontScale = undefined;
    }

    get scale() {
        return this.$pos.tempFontScale ?? this.$pos.cashier.fontScale ?? 0;
    }

    set scale(v : number) {
        this.$pos.tempFontScale = v;
    }

    get mscale() {
        return this.$pos.tempRemoteFontScale ?? this.$pos.cashier.remoteFontScale ?? 0;
    }

    set mscale(v : number) {
        this.$pos.tempRemoteFontScale = v;
    }
    
    get div() {
        return this.$pos.fontDiv;
    }

    get rdiv() {
        return this.$pos.remoteFontDiv;
    }

    getScale(value : number) {
        return (value < 0 ? 100 - Math.abs(value) / this.div * 80 : (100 + value * 10)).toFixed(0); 
    }

    loading = false;

    async apply() {
        this.loading = true;
        try {
            this.$pos.updateCashier(this.$pos.cashier as any, {
                fontScale: this.scale,
                remoteFontScale: this.mscale,
            });
            this.modalResult(true);
        } catch(e) {
            this.$store.commit("SET_ERROR", e.mssage);
        } finally {
            this.loading = false;
        }
    }
}

