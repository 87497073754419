import _ from "lodash";
// @ts-ignore
import { parseString } from "xml2js";
import { supported, request } from "../utils/httpProxy";
import qs from "qs";
import { Vue, Component, isBBPOS, isBBMSLP5, Prop, Watch } from "@feathers-client";
import { interfaces, connect, isLocal, getAddresses } from "../ports/socket";
import { bringToTop, launch } from "../utils/app";

const packageId = isBBMSLP5 ? "com.bbmsl.bbpay" : "com.bbpos.www.payment_allinpay"

export interface CallApiOpts {
  checkResponse?: boolean;
  tryLogin?: boolean;
  toTop?: boolean;
  body?: any;
  contentType?: string;
  timeout?: number;
}

export interface BBMSLConfig {
  posURL?: string;
  posUser?: string;
  posPass?: string;
}

@Component
export class BBMSLManager extends Vue {
  status: "notSetup" | "connected" | "disconnected" = "notSetup";
  serialNo: string = "";
  appVersion: string = "";

  get isBBPay() {
    return this.appVersion >= "2.0.0";
  }

  _bbmslSupported: Promise<boolean>;
  bbmslSupported() {
    if (!this._bbmslSupported) {
      return (this._bbmslSupported = supported());
    }
    return this._bbmslSupported;
  }

  async checkBBMSLSupported() {
    if (!(await this.bbmslSupported())) {
      const e = new Error(`${this.$t("bbpos.notSupported")}`);
      (e as any).className = "notSetup";
      throw e;
    }
  }

  async checkBBMSLSetuped(posURL?: string) {
    await this.checkBBMSLSupported();
    const url = posURL ?? this.settings.posURL;
    if (!url) {
      const e = new Error(`${this.$t("bbpos.noUrl")}`);
      (e as any).className = "notSetup";
      throw e;
    }
    return url;
  }

  @Watch("posURL")
  onBBMSLUrl(v) {
    if (!v) {
      this.status = "notSetup";
    } else {
      this.status = "disconnected";
      this.testPOS().catch(console.error);
    }
  }

  @Prop()
  getSetting: () => BBMSLConfig;

  @Prop()
  setSetting: (v: BBMSLConfig) => void;

  @Prop()
  getInfo: () => {
    shopId?: string;
    cashierId?: string;
  };

  @Prop()
  whenDestroy: () => void;

  get settings() {
    return this.getSetting?.();
  }

  set settings(v) {
    this.setSetting?.(v);
  }

  get posURL() {
    return this.settings?.posURL;
  }
  set posURL(v) {
    this.settings = {
      ...(this.settings || {}),
      posURL: v,
    };
  }

  get posUser() {
    return this.settings?.posUser;
  }
  set posUser(v) {
    this.settings = {
      ...(this.settings || {}),
      posUser: v,
    };
  }

  get posPass() {
    return this.settings?.posPass;
  }
  set posPass(v) {
    this.settings = {
      ...(this.settings || {}),
      posPass: v,
    };
  }

  created() {
    this.onBBMSLUrl(this.posURL);
  }

  beforeDestroy() {
    this.whenDestroy?.();
  }

  async callPOS<T = any>(params, posURL?: string, opts?: CallApiOpts) {
    const checkResponse = opts?.checkResponse ?? true;
    const tryLogin = opts?.tryLogin ?? true;
    const toTop = opts?.toTop ?? true;

    await this.checkBBMSLSupported();
    const url = await this.checkBBMSLSetuped(posURL);

    if(this.settings?.posUser && this.settings?.posPass) {
      params.UserName = this.settings?.posUser;
      params.Password = this.settings?.posPass;
    }

    let resp: Awaited<ReturnType<typeof request>>;
    try {
      resp = await request(`${url}/pos?${qs.stringify(params)}`, opts?.body, opts?.contentType, {
        timeout: opts?.timeout,
      });
    } catch (e) {
      if (e.message.includes("java.net.ConnectException") && isBBPOS) {
        console.log("going to launch bbpos");
        // try launch bbpos app
        await launch(packageId);
        await new Promise(resolve => setTimeout(resolve, 1000));
        await this.pollApi();
        return await this.callPOS(params, posURL, {
          checkResponse,
          tryLogin,
          toTop,
        });
      }
      throw e;
    }

    try {
      if (resp.statusCode !== 200) {
        throw new Error(resp.body);
      }
      const body: any = await new Promise((resolve, reject) =>
        parseString(resp.body, (err, data) => (err ? reject(err) : resolve(data))),
      );

      if (checkResponse) {
        const status = body?.WAPIResult?.status?.[0];
        const resultCode = body?.WAPIResult?.resultCode?.[0];
        const responseCode = body?.WAPIResult?.responseCode?.[0];
        const ErrorMessage = body?.WAPIResult?.ErrorMessage?.[0];
        const errorCode = body?.WAPIResult?.errorCode?.[0];

        if (ErrorMessage === "Device is Initializing" && isBBPOS) {
          await launch(packageId);
          await this.pollApi();
          return await this.callPOS(params, posURL, {
            checkResponse,
            tryLogin,
            toTop,
          });
        }

        if (resultCode === "RESULT_UNKNOWN_MERCHANT" && tryLogin && this.settings?.posUser && this.settings?.posPass) {
          await this.doLogin();
          return await this.callPOS(params, posURL, {
            checkResponse,
            tryLogin,
            toTop,
          });
        }

        if (
          (status && status !== "SUCCESS") ||
          (resultCode && resultCode !== "RESULT_OK") ||
          (responseCode && responseCode !== "0000")
        ) {
          const err: Error & { status?: string; resultCode?: number; body?: any } = new Error(
            ErrorMessage || resultCode || status || errorCode || "ERROR",
          );
          err.status = status;
          err.resultCode = resultCode;
          err.body = body;
          throw err;
        }
      }

      return body as T;
    } finally {
      if (toTop && isBBPOS) {
        try {
          await bringToTop();
        } catch (e) {
          console.warn(e);
        }
      }
    }
  }
  async cancelPOS() {
    try {
      await this.callPOS({
        transactionType: "CANCEL",
        isExternal: "true",
      });
      return true;
    } catch (e) {
      console.log("Cancel BBPOS Error: " + e.message);
      return false;
    }
  }
  async testPOS() {
    try {
      const body = await this.callPOS(
        {
          transactionType: "STATUS",
          isExternal: "true",
        },
        undefined,
        { checkResponse: false, toTop: false, tryLogin: false },
      );
      const status = body?.WAPIResult?.status?.[0];
      this.serialNo = body?.WAPIResult?.systemInfo?.[0]?.serialNumber?.[0];
      this.appVersion = body?.WAPIResult?.systemInfo?.[0]?.appVersion?.[0];
      this.status = "connected";
      return status;
    } catch (e) {
      this.status = "disconnected";
      return false;
    }
  }

  async testPOSPrint() {
    try {
      const body = await this.callPOS(
        {
          transactionType: "PRINT",
          printData: "<html><body><p size=20 align=center>Test Print</p></body></html>",
        },
        undefined,
        { checkResponse: true, toTop: false, tryLogin: false },
      );
      return true;
    } catch (e) {
      console.log("Test Print Error: " + e.message);
      return false;
    }
  }

  async doLogin() {
    const body = await this.callPOS(
      {
        transactionType: "LOGIN",
        isExternal: "true",
        UserName: this.settings?.posUser,
        Password: this.settings?.posPass,
      },
      undefined,
      { checkResponse: true, toTop: false, tryLogin: false },
    );
  }

  async scanPOS(cb: (s: string) => void) {
    const faces = (await interfaces()).filter(isLocal);
    for (let face of faces) {
      const iter = getAddresses(face);

      await Promise.all(
        new Array(256).fill(null).map(async thread => {
          while (true) {
            const next = iter.next();
            if (next.done) return;
            const ip = next.value;
            if (ip === face.address) return;
            const u = `http://${ip}:8080`;
            try {
              await Promise.race([
                this.callPOS(
                  {
                    transactionType: "STATUS",
                    isExternal: "true",
                  },
                  u,
                  { checkResponse: false, toTop: true, tryLogin: false },
                ),
                new Promise((resolve, reject) => setTimeout(reject, 15 * 1000)),
              ]);

              cb(u);
            } catch (e) {
              // console.log(e);
            }
          }
        }),
      );
    }
  }

  async pollApi() {
    for (let i = 0; i < 30; i++) {
      if (await this.testPOS()) {
        return;
      }
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
    throw new Error("BBPOS App cannot be launched");
  }

  async refundBBMSLPayment(paymentId: string) {
    const resp = await this.callPOS(
      {
        apiVersion: 21,
        transactionType: "QUERY_LAST_TRANSACTION",
        invoiceNumber: paymentId,
      },
      undefined,
      {
        checkResponse: false,
      },
    );
    const txnid = resp.WAPIResult?.receiptData?.[0]?.txnid?.[0];
    console.log(resp, txnid);
    if (resp?.WAPIResult?.status?.[0] === "SUCCESS") {
      const resp = await this.callPOS({
        apiVersion: 21,
        transactionType: "VOID",
        transactionId: txnid,
      });
      console.log(resp);
      return true;
    } else if (resp?.WAPIResult?.status?.[0] === "VOIDED") {
      return true;
    }
  }

  async openSettings() {
    return await this.$openDialog(
      // @ts-ignore
      import("./BBMSLDialog.vue"),
      {
        manager: this,
      },
      {
        maxWidth: "80%",
        contentClass: "editor-dialog",
      },
    );
  }
}
