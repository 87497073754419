

import { Vue, Component, Prop, FindType } from "@feathers-client";
import { ProductGroupExtend, Pricing } from "~/pages/products/index.vue"

@Component
export default class ProductStatusCell extends Vue {

  @Prop()
  product: ProductGroupExtend;

  @Prop()
  shop: string;

  @Prop()
  filterShops: string | string[];

  get item(): Partial<ProductGroupExtend['shopStatus'][string] | ProductGroupExtend> {
    return (this.shop ? this.product?.shopStatus[this.shop] : this.product) || {};
  }

  formatPrice(pricing: Pricing) {
    const parts: string[] = [];
    if (pricing.priceInt) {
      parts.push(`${this.$price(pricing.priceInt)}`);
    }
    if (pricing?.points?.length) {
      parts.push(...pricing.points.map(p => `${this.$td(this.$pos.pointDict[String(p.point)]?.name)}: ${p.value}`));
    }
    if(!parts.length) {
      parts.push(this.$t('basic.error') as string);
    }
    return parts.join(", ");
  }

  async openStatus() {
    await this.$openDialog(
      import("~/components/boxs/BatchProductEditDialog.vue"),
      {
        products: [this.product],
        shop: this.shop || this.filterShops,
        filterShops: Array.isArray(this.filterShops) ? this.filterShops : this.filterShops ? [this.filterShops] : [],
      },
      {
        maxWidth: "90vw",
      },
    );
  }

  get sellingStatus() {
    if(this.item.minPriceInt !== undefined) {
      if(this.item.minPriceInt === this.item.maxPriceInt) {
        return 'bg-green400';
      } else {
        return 'bg-orange400';
      }
    } else if(this.item.pricing) {
      if(this.item.pricing.priceInt || this.item.pricing.points?.length) {
        return 'bg-yellow000';
      } else {
        return 'bg-red400';
      }
    } else {
      return 'bg-grey400';
    }
  }

}

