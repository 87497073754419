
import { Vue, Component, Prop, VModel } from "@feathers-client";
import type { NavItem } from "./index.vue";

@Component
export default class BoxsNavBarItem extends Vue {
  @Prop()
  item: NavItem;

  expanded = false;

  get hasChild() {
    return !!this.item.items?.length;
  }

  get itemType() {
    return this.hasChild ? "div" : "nuxt-link";
  }

  onClick(e: Event) {
    if (this.hasChild) {
      e.preventDefault();
      e.stopPropagation();
      this.expanded = !this.expanded;
    }
  }
}
