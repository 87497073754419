
import _ from "lodash";
import { Component, Vue, Prop, PropSync, FindType, FindPopRawType, Watch, checkID, getID, mixins } from "@feathers-client";
import moment from "moment";

//type ScheduleType = FindPopRawType<"shipments", "shop/shipping/schedules">;
type ShipmentItemType = FindPopRawType<"shipments", "shop/shipping/shipments">;
type ShipmentType = FindType<"shop/shipping/shipments">;
import { getScheduleStatusColor } from "~/plugins/pos/util";
import { ShipmentScheduleExport } from "~/plugins/shipping/excel";

@Component
export default class ShipmentScheduleOrderManager extends mixins(ShipmentScheduleExport) {

  @Prop()
  deliveryDate!: string;

  @Prop()
  deliveryCar!: string;

  @Prop()
  additionalShipmentQuery!: any;

  @Prop()
  componentState: any;

  @Prop()
  searchDate: Date;

  @Prop()
  deliveryZone: FindType<"shop/shipping/zones">;

  @Prop()
  shipmentStatus: any;

  // @PropSync("orderIds")
  // orderIdsSync: string[];

  selectedItems = [];
  editing = false;
  selectAll = false;
  itemSelectState = {};

  sorting = false;
  sortOptions = [
    { text: this.$t("basic.sort.dateDesc"), value: "date", desc: true },
    { text: this.$t("basic.sort.dateAsce"), value: "date", desc: false },
    { text: this.$t("basic.sort.preferDeliveryTimeDesc"), value: "preferDeliveryTime", desc: true },
    { text: this.$t("basic.sort.preferDeliveryTimeAsce"), value: "preferDeliveryTime", desc: false },
  ]
  selectedSortOption = this.sortOptions[0];

  selectSort(item){
    this.selectedSortOption = item;
    this.sorting = false;
  }

  @Watch("selectAll")
  selectAllChanged() {
    if (this.selectAll) {
      this.itemSelectState = _.fromPairs(this.items.map(item => [item._id, true]));
    } else if (this.selectAll === false) {
      this.itemSelectState = _.fromPairs(this.items.map(item => [item._id, false]));
    }
  }

  @Watch("itemSelectState", { deep: true })
  itemSelectStateChanged() {
    this.selectedItems = Object.entries(this.itemSelectState)
      .filter(([k, v]) => v)
      .map(([k, v]) => k);
    if (Object.values(this.itemSelectState).every(v => v === true)) {
      this.selectAll = true;
    } else if (Object.values(this.itemSelectState).every(v => v === false)) {
      this.selectAll = false;
    } else {
      this.selectAll = null;
    }
  }

  get shipmentQuery() {
    const query: any = {
        // ...this.additionalShipmentQuery,
        // ...(this.searchDate
        //   ? {
        //       deliveryDate: this.searchDate,
        //     }
        //   : {}),
      $and: [
        {
        ...this.additionalShipmentQuery,
        ...(this.searchDate
          ? {
              deliveryDate: this.searchDate,
            }
          : {}),
        },
        { $or: [{ deliveryCar: { $exists: false } }, { deliveryCar: null }] },
        { $or: [{ deliveryDate: { $exists: false } }, { deliveryDate: null }] },
      ],
      $sort: {[this.selectedSortOption.value]:this.selectedSortOption.desc ? -1 : 1}
      // {
      //   date: -1,
      // },
    };
    if (this.deliveryZone) {
      query["address.district"] = {
        $in: this.deliveryZone.districts,
      };
    }
    switch (this.shipmentStatus) {
      case "all": {
        return { ...query, status: { $nin: ["splited", "merged", "cancelled"] } };
      }

      case "pending": {
        return { ...query, status: "pending", preferDeliveryTime: { $not: { $type: "date" } } };
      }

      case "confirmed": {
        return { ...query, status: "pending", preferDeliveryTime: { $type: "date" } };
      }

      case "scheduled": {
        return { ...query, status: "pending", deliveryCar: { $ne: null }, deliveryDate: { $ne: null } };
      }

      case "notDelivered": {
        return { ...query, status: "notDelivered" };
      }

      case "expired": {
        return {
          ...query,
          status: { $nin: ["received", "done"] },
          deliveryDate: { $lt: moment().format("YYYY-MM-DD") },
        };
      }
    }
    return query;
  }

  async schedule(item: ShipmentType) {
    // if (!this.deliveryDate) {
    //   this.$store.commit(
    //     "SET_ERROR",
    //     `${this.$t("pages.shop/shipping/schedules.deliveryDate")} ${this.$t("basic.isNotSet")}`,
    //   );
    //   return;
    // }
    // if (!this.deliveryCar) {
    //   this.$store.commit(
    //     "SET_ERROR",
    //     `${this.$t("pages.shop/shipping/schedules.deliveryCar")} ${this.$t("basic.isNotSet")}`,
    //   );
    //   return;
    // }
    const c = await this.$openDialog(
      import("~/components/boxs/ShipmentScheduleOrderAssignmentDialog.vue"),
      {},
      {
        maxWidth: "300px",
      },
    );
    if (!c || !c.deliveryDate || !c.deliveryCar) return;

    const { shipments } = await this.$feathers.service("shop/shipping/schedules/assign").create({
      deliveryDate: c.deliveryDate,
      deliveryCar: c.deliveryCar,
      shipments: [item._id],
    });
    Object.assign(item, shipments[0]);
    this.componentState.value += 1;
  }

  async batchSchedule() {
    // if (!this.deliveryDate) {
    //   this.$store.commit(
    //     "SET_ERROR",
    //     `${this.$t("pages.shop/shipping/schedules.deliveryDate")} ${this.$t("basic.isNotSet")}`,
    //   );
    //   return;
    // }
    // if (!this.deliveryCar) {
    //   this.$store.commit(
    //     "SET_ERROR",
    //     `${this.$t("pages.shop/shipping/schedules.deliveryCar")} ${this.$t("basic.isNotSet")}`,
    //   );
    //   return;
    // }
    if (!this.selectedItems.length) {
      this.$store.commit("SET_ERROR", `${this.$t("basic.order")} ${this.$t("basic.isNotSet")}`);
      return;
    }
    const c = await this.$openDialog(
      import("~/components/boxs/ShipmentScheduleOrderAssignmentDialog.vue"),
      {},
      {
        maxWidth: "300px",
      },
    );
    if (!c || !c.deliveryDate || !c.deliveryCar) return;

    const results = await this.$feathers.service("shop/shipping/schedules/assign").create({
      deliveryDate: c.deliveryDate,
      deliveryCar: c.deliveryCar,
      shipments: this.selectedItems,
    });
    this.componentState.value += 1;
  }

  editToggle() {
    this.editing = !this.editing;
  }
  sortToggle() {
    this.sorting = !this.sorting;
  }

  // async selectStatus() {
  //   const c = await this.$openDialog(
  //     import("~/components/boxs/ShipmentScheduleShipmentStatusDialog.vue"),
  //     {
  //       value: this.shipmentStatus,
  //       options: [
  //         { _id: "pending", name: { $t: "shipping.schedule.status.pending" } },
  //         { _id: "confirmed", name: { $t: "shipping.schedule.status.confirmed" } },
  //         { _id: "scheduled", name: { $t: "shipping.schedule.status.scheduled" } },
  //         { _id: "notDelivered", name: { $t: "shipping.schedule.status.notDelivered" } },
  //         { _id: "expired", name: { $t: "shipping.schedule.status.expired" } },
  //       ],
  //     },
  //     {
  //       maxWidth: "50vw",
  //     },
  //   );
  //   if (!c) return;
  //   this.shipmentStatus = c;
  // }

  getScheduleStatusColor = getScheduleStatusColor;

  items: any = null;
  //pendingRemove: string[] = [];
  // pendingRemoveState: object = {};

  get status() {
    return this.items ? this.items.status || "pending" : null;
  }

  async beforeMount() {
    await this.updateItem();
    // window.addEventListener("click", this.offFocus);
  }


  // @Watch("deliveryDate")
  // @Watch("deliveryCar")
  @Watch("shipmentQuery")
  @Watch("componentState.value")
  updateFilter() {
    this.items = null;
    this.updateItem();
  }

  async updateItem() {
    this.items = await this.$feathers
      .service("shop/shipping/shipments")
      .find({ query: { ...this.shipmentQuery, $paginate: false }, paginate: false });
    this.itemSelectState = Object.fromEntries(this.items.map(item => [getID(item), false]));
    // this.orderIdsSync = Object.keys(this.itemSelectState);
  }

  async mergeItems() {
    if (!this.selectedItems.length) {
      this.$store.commit("SET_ERROR", `${this.$t("basic.order")} ${this.$t("basic.isNotSet")}`);
      return;
    }
    if (this.selectedItems.length < 0) {
      this.$store.commit(
        "SET_ERROR",
        `${this.$t("basic.order")} ${this.$t("basic.isNotSet")}${this.$t("basic.toTwoOrMore")}`,
      );
      return;
    }
    const c = await this.$openDialog(
      import("~/components/boxs/ShipmentScheduleOrderMergeDialog.vue"),
      {
        value: this.selectedItems,
      },
      {
        maxWidth: "90vw",
      },
    );
    if (!c) return;
    this.$store.commit("SET_SUCCESS", this.$t("pages.shop/shipping/schedules.addOrderSuccessMessage"));
    this.componentState.value += 1;
  }

  async splitItems() {
    if (!this.selectedItems.length) {
      this.$store.commit("SET_ERROR", `${this.$t("basic.order")} ${this.$t("basic.isNotSet")}`);
      return;
    }
    // Now supporting multiple orders splitting
    // if(this.selectedItems.length >= 2){
    //   this.$store.commit("SET_ERROR", `${this.$t("basic.order")} ${this.$t("basic.isSet")}${this.$t("basic.toTwoOrMore")}`);
    //   return;
    // }
    const c = await this.$openDialog(
      import("~/components/boxs/ShipmentScheduleOrderSplitDialog.vue"),
      {
        value: this.selectedItems,
      },
      {
        maxWidth: "90vw",
      },
    );
    if (!c) return;
    this.$store.commit("SET_SUCCESS", this.$t("pages.shop/shipping/schedules.addOrderSuccessMessage"));
    this.componentState.value += 1;
  }

  async view(item: ShipmentType) {
    const c = await this.$openDialog(
      import("~/components/boxs/ShipmentScheduleOrderCancelDialog.vue"),
      {
        value: [item._id],
      },
      {
        maxWidth: "90vw",
      },
    );
    if (!c) return;
    this.$store.commit("SET_SUCCESS", this.$t("pages.shop/shipping/schedules.cancelOrderSuccessMessage"));
    this.componentState.value += 1;
  }

  async viewItems() {
    if (!this.selectedItems.length) {
      this.$store.commit("SET_ERROR", `${this.$t("basic.order")} ${this.$t("basic.isNotSet")}`);
      return;
    }
    const c = await this.$openDialog(
      import("~/components/boxs/ShipmentScheduleOrderCancelDialog.vue"),
      {
        value: this.selectedItems,
      },
      {
        maxWidth: "90vw",
      },
    );
    if (!c) return;
    this.$store.commit("SET_SUCCESS", this.$t("pages.shop/shipping/schedules.cancelOrderSuccessMessage"));
    this.componentState.value += 1;
  }
}
