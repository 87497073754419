const BinaryFieldSymbol = Symbol("BinaryFieldSymbol");

export interface BinaryPacketFieldInfo<T = any> {
  key: string | symbol;
  parser: (buffer: Buffer, index: number, instance: any) => { value: T; offset: number };
  serializer: (value: T, instance: any) => Buffer;
}

export class BinaryPacket {
  static fromBuffer<T extends BinaryPacket>(this: new () => T, buffer: Buffer, index = 0): T {
    return BinaryPacket.fromBufferWithOffset.call(this, buffer, index).instance as any;
  }

  static fromBufferWithOffset<T extends BinaryPacket>(this: new () => T, buffer: Buffer, index = 0): {
    instance: T;
    offset: number;
  } {
    const instance = new this();
    const metadata: BinaryPacketFieldInfo[] = instance[BinaryFieldSymbol] || [];

    for (const field of metadata) {
      const { value, offset } = field.parser(buffer, index, instance);
      index += offset;
      (instance as any)[field.key] = value;
    }

    return {
      instance,
      offset: index,
    };
  }

  toBuffer(): Buffer {
    const metadata: BinaryPacketFieldInfo[] = this[BinaryFieldSymbol] || [];

    let buffers: Buffer[] = [];
    for (const field of metadata) {
      const value = (this as any)[field.key];
      buffers.push(field.serializer(value, this));
    }

    return Buffer.concat(buffers);
  }
}

export function createFieldMetadata(options: Omit<BinaryPacketFieldInfo, "key">) {
  return function (target: Object, propertyKey: string | symbol) {
    const metadata: BinaryPacketFieldInfo[] = target[BinaryFieldSymbol] || [];
    metadata.push({
      ...options,
      key: propertyKey,
    });
    target[BinaryFieldSymbol] = metadata;
  };
}

export function BUF(length: (instance: any) => number) {
  return createFieldMetadata({
    parser: (buffer, index, instance) => {
      return {
        value: buffer.subarray(index, index + length(instance)),
        offset: length(instance),
      };
    },

    serializer: (value: Buffer, instance) => value.slice(0, length(instance)),
  });
}

export function STR(length: (instance: any) => number) {
  return createFieldMetadata({
    parser: (buffer, index, instance) => {
      return {
        value: Buffer.from(buffer).slice(index, index + length(instance)).toString('ascii').trimEnd(),
        offset: length(instance),
      };
    },

    serializer: (value: string, instance) =>
      Buffer.from((value ?? "").slice(0, length(instance)).padEnd(length(instance), " "), "ascii"),
  });
}

export const BYTE = createFieldMetadata({
  parser: (buffer, index) => {
    return {
      value: buffer.readUInt8(index),
      offset: 1,
    };
  },

  serializer: (value: number) => {
    const buffer = Buffer.alloc(1);
    buffer.writeUInt8(value);
    return buffer;
  },
});

export const U16BE = createFieldMetadata({
  parser: (buffer, index) => {
    return {
      value: buffer.readUInt16BE(index),
      offset: 2,
    };
  },

  serializer: (value: number) => {
    const buffer = Buffer.alloc(2);
    buffer.writeUInt16BE(value);
    return buffer;
  },
});

export function Struct(ctor: typeof BinaryPacket) {
  return createFieldMetadata({
    parser: (buffer, index, instance) => {
      const res = ctor.fromBufferWithOffset(buffer, index);
      return {
        value: res.instance,
        offset: res.offset,
      };
    },

    serializer: (value: BinaryPacket, instance) => {
      return value.toBuffer();
    }
  });
}

