import { Component, Vue, Prop, VModel, Watch, FindType } from "@feathers-client";
import {
  PageData,
  ComponentDefinition,
  ComponentOption,
  ComponentVariant,
  ComponentOptionProp,
  PageBlock,
  PreviewCommand,
  stripKey,
} from "./def";
import VueI18n from "vue-i18n";
import uuid from "uuid/v4";

export class SiteEditorComponent {
  component: any;
  info: ComponentOption;
  group: SiteEditorComponentGroup;

  editor: any;

  constructor(public key: string, componentDef: ComponentDefinition) {
    Object.defineProperty(this, "component", {
      get() {
        return componentDef.default;
      },
      enumerable: false,
    });
    Object.defineProperty(this, "editor", {
      get() {
        return editorContextRegistry[key];
      },
      enumerable: false,
    });
    this.info = componentDef.componentInfo;

    if (!componentVariantRegistry[key]) componentVariantRegistry[key] = {};
    if (!componentGroup[this.info.group]) {
      componentGroup[this.info.group] = new SiteEditorComponentGroup(this.info.group);
    }
    this.group = componentGroup[this.info.group];
    if (this.info.groupProps) {
      Object.assign(this.group.groupProps, this.info.groupProps);
    }

    for (let variantInfo of this.info.variants) {
      const variant = new SiteEditorComponentVariant(this, variantInfo);
      componentVariantRegistry[key][variantInfo.key] = variant;
      this.group.variants[variantInfo.key] = variant;
    }
  }
}

export class SiteEditorComponentGroup {
  constructor(public key: string) {}

  get nameKey() {
    return `componentGroups.${this.key}.name`;
  }

  variants: Record<string, SiteEditorComponentVariant> = {};
  groupProps: ComponentOptionProp = {};

  get variantList() {
    return Object.values(this.variants);
  }
}

export class SiteEditorComponentVariant {
  constructor(public component: SiteEditorComponent, public variant: ComponentVariant) {
    const icon = iconRegistry[this.fullKey];
    Object.defineProperty(this, "icon", {
      enumerable: false,
      get() {
        return icon;
      },
    });
  }

  icon: any;

  get fullKey() {
    return [this.group.key, this.key.slice(0, 1).toUpperCase() + this.key.slice(1)].join("");
  }

  get key() {
    return this.variant.key;
  }

  get group() {
    return this.component.group;
  }

  get nameKey() {
    return `componentGroups.${this.group.key}.variants.${this.key}`;
  }
}

const context = require.context("./components", true, /\.vue$/);
const editorContext = require.context("./editorComponents", true, /\.vue$/);
const editorContextRegistry = Object.fromEntries(
  editorContext.keys().map(k => [stripKey(k), editorContext(k).default] as const),
);

const componentRegistry: Record<string, SiteEditorComponent> = {};
const componentVariantRegistry: Record<string, Record<string, SiteEditorComponentVariant>> = {};
const componentGroup: Record<string, SiteEditorComponentGroup> = {};

const icons = require.context("!vue-svg-loader!./assets/editor", true, /\.svg$/);
const iconRegistry = Object.fromEntries(icons.keys().map(it => [it.slice(2, -4), icons(it)] as const));

const messages = require.context("./editorLocales/", true, /\.yml$/);

for (let key of context.keys()) {
  try {
    const componentDef: ComponentDefinition = context(key);
    const strippedKey = stripKey(key);

    if (componentDef.componentInfo) {
      const component = new SiteEditorComponent(strippedKey, componentDef);
      componentRegistry[strippedKey] = component;
    }
  } catch (e) {
    console.error("Failed to load component", key, e);
  }
}

@Component
export class SiteEditorManager extends Vue {
  @Prop()
  pageData: PageData;

  @Prop()
  headerStyle: any;

  get blocks() {
    return this.pageData.blocks || [];
  }

  get componentVariantRegistry() {
    return componentVariantRegistry;
  }

  get componentGroup() {
    return componentGroup;
  }

  i18n: VueI18n;

  created() {
    this.i18n = new VueI18n({
      messages: Object.fromEntries(messages.keys().map(it => [it.slice(2, -4), messages(it).default] as const)),
      root: this.$parent.$parent,
      locale: this.$parent.$parent.$i18n.locale,
    } as any);
    (this as any)._i18n = this.i18n;
    window.addEventListener("message", this.onMessage);
  }

  beforeDestroy() {
    window.removeEventListener("message", this.onMessage);
  }

  async headingSetting(block: PageBlock, page: FindType<"shop/pages">) {
    await this.$openDialog(
      import("./PageHeaderEditor.vue"),
      {
        manager: this,
        block,
        page,
        variant: componentVariantRegistry[block.component]?.[block.variant],
      },
      {
        maxWidth: "calc(min(90vw,1024px))",
        contentClass: "h-90vh",
        persistent: true,
      },
    );
  }

  async style(block: PageBlock) {
    if (!block.styles) {
      block.styles = {};
    }
    if (!block.responsive) block.responsive = [];
    if (!block.responsive.length) {
      block.responsive.push(
        {
          type: "Desktop",
          paddingTop: "",
          paddingLeft: "",
          paddingRight: "",
          paddingBottom: "",
          ...(block.styles ?? {}),
        },
        { type: "Mobile", paddingTop: "", paddingLeft: "", paddingRight: "", paddingBottom: "" },
      );
    }
    await this.$openDialog(
      import("./ComponentStyleEditor.vue"),
      {
        manager: this,
        block,
        variant: componentVariantRegistry[block.component]?.[block.variant],
      },
      {
        maxWidth: "700px",
        contentClass: "h-[500px]",
        persistent: true,
      },
    );
  }

  async edit(block: PageBlock) {
    await this.$openDialog(
      import("./ComponentEditor.vue"),
      {
        manager: this,
        block,
        variant: componentVariantRegistry[block.component]?.[block.variant],
      },
      {
        maxWidth: "calc(min(90vw,1024px))",
        contentClass: "h-90vh",
        persistent: true,
      },
    );
  }

  async clone(block: PageBlock) {
    const idx = this.blocks.findIndex(it => it.id === block.id);
    const cloned = structuredClone(block);
    cloned.id = uuid();
    if (idx !== -1) {
      this.blocks.splice(idx + 1, 0, cloned);
    } else {
      this.blocks.push(cloned);
    }
  }

  async remove(block: PageBlock) {
    const c = await this.$openDialog(
      import("@feathers-client/components-internal/RemoveDialog.vue"),
      {
        title: this.$t("pages.shop/pages.doYouWantToDelete"),
      },
      {
        maxWidth: "400px",
      },
    );
    if (!c) return;

    const idx = this.blocks.findIndex(it => it.id === block.id);
    if (idx !== -1) {
      this.blocks.splice(idx, 1);
    }
  }

  previewUrl: string;
  previewWindow: Window;
  previewOrigin: string;
  previewClosed = false;

  // preview tab is active or not
  get instantSync() {
    return (
      (!this.inlinePreviewLoaded && !this.previewClosed && !!this.previewWindow) ||
      (this.inlinePreviewLoaded && this.inlinePreviewing)
    );
  }

  mountPreview(win: Window, previewOrigin?: string) {
    this.inlinePreviewing = true;
    this.previewWindow = win;
    this.previewOrigin = previewOrigin ? new URL("/", previewOrigin).toString().slice(0, -1) : null;
    this.previewClosed = false;
  }

  preview = false;
  inlinePreviewLoaded = false;
  inlinePreviewing = false;

  async openPreview(url?: string, inline?: boolean) {
    this.preview = true;
    if (url !== undefined && this.previewUrl !== url) {
      this.previewUrl = url;
    }
    if (
      inline === true ||
      (inline === undefined && (this.inlinePreviewLoaded || !this.previewWindow || this.previewClosed))
    ) {
      this.inlinePreviewLoaded = true;
      this.inlinePreviewing = true;
    } else {
      if (!this.inlinePreviewLoaded && this.previewWindow && !this.previewClosed) {
        // if target tab does not response in 100ms, assume closed
        const pong = new Promise(resolve => {
          let timeout = setTimeout(() => {
            timeout = null;
            this.$off("pong", handler);
            resolve(false);
          }, 100);
          const handler = () => {
            if (timeout) clearTimeout(timeout);
            resolve(true);
          };
          this.$once("pong", handler);
        });
        await this.callPreview({
          type: "ping",
        });

        if (await pong) {
          this.previewWindow.focus();
          await this.syncData();
          return;
        }
      }
      this.previewOrigin = this.previewUrl ? new URL("/", this.previewUrl).toString().slice(0, -1) : null;
      this.previewWindow = window.open(this.previewUrl, "_blank");
      this.inlinePreviewLoaded = false;
      this.inlinePreviewing = false;
      this.previewClosed = false;
    }
    await this.syncData();
  }

  async syncData() {
    this.dirty = false;
    if (this.previewWindow) {
      await this.callPreview({
        type: "sync",
        pageData: this.pageData,
        headerStyle: this.headerStyle,
      });
    }
  }

  closeInlinePreview() {
    this.inlinePreviewing = false;
  }

  async callPreview(cmd: PreviewCommand) {
    if (!this.previewOrigin) return;
    this.previewWindow.postMessage(JSON.stringify(cmd), this.previewOrigin);
  }

  async onMessage(e: MessageEvent) {
    if (e.source !== this.previewWindow) return;
    if (e.origin !== this.previewOrigin) {
      console.warn("Invalid origin", e.origin);
      return;
    }

    if (!e.data) return;
    try {
      const data: PreviewCommand = typeof e.data === "string" ? JSON.parse(e.data) : e.data;
      switch (data.type) {
        case "requestSync": {
          await this.callPreview({
            type: "sync",
            pageData: this.pageData,
            headerStyle: this.headerStyle,
          });
          this.previewClosed = false;
          break;
        }
        case "closed": {
          this.previewClosed = true;
          break;
        }

        case "ping": {
          await this.callPreview({ type: "pong" });
          break;
        }

        case "pong": {
          this.$emit("pong");
          break;
        }
      }
    } catch (e) {}
  }

  defineProps<T>(block: PageBlock, defaultProps: T) {
    for (let [k, v] of Object.entries(defaultProps)) {
      if (block.props[k] === undefined) {
        Vue.set(block.props, k, v);
      }
    }
    const proxy = new Proxy(block.props, {
      get(target, p, receiver) {
        return target[p as any] ?? defaultProps[p as any];
      },
      set(target, p, newValue, receiver) {
        Vue.set(target, p as any, newValue);
        return true;
      },
    });

    return proxy as any as T;
  }

  dirty = false;
  syncTimeout: any;

  @Watch("pageData", { deep: true })
  @Watch("headerStyle", { deep: true })
  markDirty() {
    this.dirty = true;
    if (this.instantSync) {
      if (!this.syncTimeout) {
        this.syncData().catch(console.warn);
        this.syncTimeout = setTimeout(() => {
          this.syncTimeout = null;
          if (this.dirty) {
            this.syncData().catch(console.warn);
          }
        }, 50);
      }
    }
  }
}
