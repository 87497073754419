
import { Component, Vue, Prop } from "@feathers-client";
import { LangArrType } from "@feathers-client/i18n";
import { defineComponent } from "site-editor/def";

export const componentInfo = defineComponent({
  group: "video",
  groupProps: {
    editorCols: 2,
  },
  variants: [
    {
      key: "video",
    },
  ],
});

@Component
export default class SiteEditorVideo extends Vue {
  @Prop()
  variant: string;

  @Prop()
  title: LangArrType;

  @Prop()
  remarks: LangArrType;

  @Prop()
  type: string;

  @Prop()
  url: string;

  @Prop()
  file: any;

  @Prop()
  autoPlay: boolean;

  @Prop()
  titleDisplayMode: "left" | "center" | "right";

  @Prop()
  fontColor: string;

  @Prop()
  fontRemarksColor: string;

  @Prop({ type: String, default: "500" })
  height: string;

  get extractedId() {
    try {
      let format = /(.*?)(^|\/|v=)([a-z0-9_-]{11})(.*)?/gim;
      const result = format.exec(this.url);
      return result?.[3] || null;
    } catch (e) {
      //this.$store.commit("SET_ERROR", e.message);
    }
  }
}
