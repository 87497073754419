
import { Component, Prop, Vue, Watch, mixins, FindType, VModel, checkID, getID, PropSync } from "@feathers-client";
import { Order } from "@common/pos";
import NumPadDialog from "./numPadDialog.vue";
import _ from "lodash";

@Component({
  components: {
    NumPadDialog,
  },
})
export default class Cash extends Vue {
  @Prop()
  session: Order;

  @PropSync("cashValid")
  isCashValid: boolean;

  @PropSync("amountNumPadDialog")
  amountNumPadDialogSync: boolean;

  @PropSync("tipsNumPadDialog")
  tipsNumPadDialogSync: boolean;

  @Prop()
  paymentAmountInt: number;

  get items() {
    const amount = this.$pos.getHumanNumber(this.paymentAmountInt);

    const roundTo10 = this.$pos.fromHumanNumberToRaw(Math.ceil(amount / 10) * 10);
    const roundTo20 = this.$pos.fromHumanNumberToRaw(Math.ceil(amount / 20) * 20);
    const roundTo50 = this.$pos.fromHumanNumberToRaw(Math.ceil(amount / 50) * 50);
    const roundTo100 = this.$pos.fromHumanNumberToRaw(Math.ceil(amount / 100) * 100);
    const roundTo500 = this.$pos.fromHumanNumberToRaw(Math.ceil(amount / 500) * 500);

    if (this.$breakpoint.mdAndUp) {
      const items = _.uniqBy(
        [
          {
            _id: this.paymentAmountInt || 0,
            name: this.$t("payCash.noChange"),
          },
          {
            _id: roundTo10,
            name: this.$price(roundTo10, true),
          },
          {
            _id: roundTo20,
            name: this.$price(roundTo20, true),
          },
          {
            _id: roundTo50,
            name: this.$price(roundTo50, true),
          },
          {
            _id: roundTo100,
            name: this.$price(roundTo100, true),
          },
          {
            _id: roundTo500,
            name: this.$price(roundTo500, true),
          },
        ],
        it => it._id,
      );
      const noChange = items.shift();
      while (items.length < 6) {
        items.push({
          _id: -items.length,
          name: "",
        });
      }
      items.push(noChange);
      items.push({
        _id: -7,
        name: this.$t("tableView.markAsTips"),
      });
      return items;
    } else {
      const items = _.uniqBy(
        [
          {
            _id: this.paymentAmountInt || 0,
            name: this.$t("payCash.noChange"),
          },
          {
            _id: roundTo20,
            name: this.$price(roundTo20, true),
          },
          {
            _id: roundTo50,
            name: this.$price(roundTo50, true),
          },
          {
            _id: roundTo100,
            name: this.$price(roundTo100, true),
          },
        ],
        it => it._id,
      );
      return items;
    }
  }

  setReceived(received: number) {
    if (received < 0) {
      if (received === -7) {
        this.markAsTips();
      }
    } else {
      this.receivedInt = received;
    }
  }

  @Watch("paymentAmountInt")
  updatePrefill() {
    this.receivedInt = this.paymentAmountInt;
    this.isCashValid = this.changeValid;
  }

  beforeMount() {
    this.updatePrefill();
  }

  input: "tips" | "receive" = "receive";

  receivedInt: number = null;
  customReceived = "";
  userClear = true;

  get isCustomReceived() {
    return !this.items.find(it => checkID(it, this.receivedInt));
  }

  @Watch("received")
  onReceived(v) {
    if (v !== null) {
      this.customReceived = "";
    }
  }

  get humanReceived() {
    return this.$pos.getHumanNumber(this.receivedInt);
  }

  set humanReceived(v) {
    this.receivedInt = this.$pos.fromHumanNumberToRaw(v);
  }

  doAction(action: number | string) {
    if (action === "C" || this.customReceived === "") {
      this.receivedInt = this.items[0]._id;
      this.userClear = true;
    } else if (!this.isCustomReceived) {
      this.receivedInt = null;
      this.userClear = false;
    } else {
      this.userClear = false;
    }
  }

  resetCustomInput() {
    this.customReceived = "";
    this.receivedInt = this.paymentAmountInt;
    this.userClear = true;
  }

  get priceStr() {
    if (this.customReceived) {
      const parts = this.customReceived.split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return `${this.$pos.currency} ${parts.join(".")}`;
    } else {
      return this.$price(this.receivedInt || 0);
    }
  }

  @Watch("receivedInt")
  @Watch("customReceived")
  onWatchReceiveAmount(newVal, oldVal) {
    this.$emit("priceStr", this.priceStr);
  }

  get pricePadding() {
    if (this.customReceived) {
      const parts = this.customReceived.split(".");
      if (parts.length === 1) return ".00";
      else if (parts[1].length === 0) return "00";
      else if (parts[1].length === 1) return "0";
    }
    return "";
  }

  customTips = "";
  tipsUserClear = false;

  @Watch("tipsInt")
  onTips(v) {
    if (v !== null) {
      this.customTips = "";
    }
  }

  get tipsStr() {
    if (this.customTips) {
      const parts = this.customTips.split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return `${this.$pos.currency} ${parts.join(".")}`;
    } else {
      return this.$price(this.tipsInt || 0);
    }
  }

  get tipsPadding() {
    if (this.customTips) {
      const parts = this.customTips.split(".");
      if (parts.length === 1) return ".00";
      else if (parts[1].length === 0) return "00";
      else if (parts[1].length === 1) return "0";
    }
    return "";
  }

  doTipsAction(action: number | string) {
    if (action === "C" || this.customTips === "") {
      this.tipsInt = 0;
      this.tipsUserClear = true;
    } else {
      this.tipsUserClear = false;
    }
  }

  get changeInt() {
    let receivedInt = this.customReceived ? this.$pos.fromStringToNumber(this.customReceived) : this.receivedInt;
    if (isNaN(receivedInt)) {
      return null;
    }
    return receivedInt - this.paymentAmountInt;
  }

  get changeStr() {
    const changeInt = this.changeInt;
    if (changeInt === null) {
      return "-";
    } else {
      return this.$price(this.changeInt);
    }
  }

  @PropSync("changeStr")
  changeStrSync: string;

  @Watch("changeStr")
  onWatch(newVal, oldVal) {
    this.changeStrSync = newVal;
  }

  get changeValid() {
    let receivedInt = this.customReceived ? this.$pos.fromStringToNumber(this.customReceived) : this.receivedInt;
    return !isNaN(receivedInt) && receivedInt >= this.paymentAmountInt;
  }

  @Watch("changeValid")
  onChangeValid() {
    this.isCashValid = this.changeValid;
  }

  apply() {
    if (this.customReceived) {
      let receivedInt = this.$pos.fromStringToNumber(this.customReceived);
      if (isNaN(receivedInt)) return null;
      this.receivedInt = receivedInt;
    }
    return this.receivedInt >= this.paymentAmountInt ? this.receivedInt : null;
  }

  get tipsInt() {
    return this.session.manualDiscountInt;
  }

  set tipsInt(v) {
    this.session.manualDiscountInt = v;
  }

  get humanTips() {
    return this.$pos.getHumanNumber(this.tipsInt);
  }

  set humanTips(v) {
    this.tipsInt = this.$pos.fromHumanNumberToRaw(v);
  }

  async markAsTips() {
    let changeInt = this.changeInt;
    if (changeInt !== null) {
      this.session.manualDiscountInt += changeInt;
    }
  }
}
