
import { Vue, Component, Prop, mixins, FindType } from "@feathers-client";
import Dialog from "@feathers-client/mixins/Dialog";
import { ProductGroupExtend } from "~/pages/products/index.vue";
import _ from "lodash";
import BatchEditor from "@schemaEditor/batch/BatchEditor.vue";
import { EditorField } from "@schemaEditor/plugin";
import { ProductType } from "@common/common";

@Component({
  components: {
    BatchEditor,
  },
})
export default class BatchProductEditDialog extends mixins(Dialog) {
  @Prop()
  products: ProductGroupExtend[];

  @Prop()
  shop: string | string[];

  @Prop()
  filterShops: string[];

  shops: string[] = [];

  loading = false;
  progress = 0;

  async created() {
    if (this.shop) {
      this.shops = Array.isArray(this.shop) ? this.shop : [this.shop];
    } else {
      this.shops = this.$store.state.shops.map(shop => shop._id);
    }
  }

  get editFilter() {
    return {
      shop: {
        $in: this.shops,
      },
      productGroup: {
        $in: this.products.map(product => product._id),
      },
    };
  }

  get fields(): (string | EditorField)[] {
    const shops = this.$store.state.shops;
    const pricingFields: EditorField[] = [];

    const addPricingField = (shopFilter?: FindType<"shops">) => {
      pricingFields.push({
        type: "number",
        path: "$priceInt",
        name: shopFilter ? ({ $join: [shopFilter.name, " - ", { $t: "basic.price" }] } as any) : "basic.price",
        // @ts-ignore
        component: () => import("~/components/boxs/SingleCurrencyEditor.vue"),
        propsFunc: ({ item }: { item: ProductType }) => {
          let priceValue: number = undefined;
          for (let shop of item.shopTable || []) {
            if (shopFilter && shop.shop !== shopFilter._id) {
              continue;
            }
            if (shop.pricing?.length === 1 && !shop.pricing[0].points?.length) {
              if (priceValue === undefined) {
                priceValue = shop.pricing[0].priceInt;
              } else if (priceValue !== shop.pricing[0].priceInt) {
                priceValue = null;
                break;
              }
            } else if (shop.pricing?.length || priceValue !== undefined) {
              priceValue = null;
              break;
            }
          }
          if (priceValue === null) {
            return {
              placeholder: this.$t("enum.basic.status.mixed"),
            };
          } else {
            return {};
          }
        },
        getter(self, item: ProductType) {
          let priceValue: number = undefined;
          for (let shop of item.shopTable || []) {
            if (shopFilter && shop.shop !== shopFilter._id) {
              continue;
            }
            if (shop.pricing?.length === 1 && !shop.pricing[0].points?.length) {
              if (priceValue === undefined) {
                priceValue = shop.pricing[0].priceInt;
              } else if (priceValue !== shop.pricing[0].priceInt) {
                priceValue = null;
                break;
              }
            } else {
              priceValue = null;
              break;
            }
          }
          return priceValue;
        },
        setter(self, item: ProductType, value) {
          if (shopFilter) {
            if (!item.shopTable) {
              Vue.set(item, "shopTable", []);
            }
            let curShop = item.shopTable.find(shop => shop.shop === shopFilter._id);
            if (!curShop) {
              curShop = {
                shop: shopFilter._id,
                pricing: [],
                status: "selling",
              } as any;
              item.shopTable.push(curShop);
            }
          }
          for (let shop of item.shopTable) {
            if (shopFilter && shop.shop !== shopFilter._id) {
              continue;
            }
            if (shop.pricing.length) {
              shop.pricing[0].priceInt = value;
              shop.pricing[0].points = [];
            } else {
              shop.pricing = [
                {
                  priceInt: value,
                } as any,
              ];
            }
          }
        },
      });
    };

    addPricingField();
    for (let shop of shops) {
      addPricingField(shop);
    }

    return ["images", "name", "slug", ...pricingFields];
  }

  subFields = [
    {
      path: "shopTable",
      fields: "shop,status,warehouse,needDelivery,shippingMethods,allowOutOfStock,originalPriceInt",
      subFields: [
        {
          path: "pricing",
          fields: "priceInt",
        },
      ],
    },
  ];

  get defaultRow() {
    return {
      shop: this.products[0].shop,
      productGroup: this.products[0]._id,
    };
  }

  async updateMetadata() {
    try {
      await this.$feathers.service("shop/product/groups/updateSkuInfo").create({
        product: this.products[0]._id,
        updateSkuMeta: true,
      })
    } catch(e) {
      console.warn(e);
      this.$store.commit("SET_ERROR", e.message);
    }
  }
}
