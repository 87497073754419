
import { Component, Prop, Vue, ProvideReactive, InjectReactive } from "@feathers-client";
import { BatchEditorContext, BatchEditorItem, NormalizedBatchEditorConfig } from "./editorContext";
import _, { sumBy } from "lodash";
import EditorList from "@schemaEditor/EditorList.vue";
import { EditorField } from "@schemaEditor/plugin";

@Component({
  components: {
    EditorList,
  },
})
export default class BatchEditorSub extends Vue {
  get totalWidth() {
    return sumBy(this.field.fields, (f) => f.colWidthRem || 12.5) + 10;
  }

  @Prop()
  item: BatchEditorItem;

  @Prop()
  sourceItem: any;

  @Prop()
  field: NormalizedBatchEditorConfig;

  @Prop()
  slots: any[];

  get collection() {
    return this.field?.collection;
  }

  get sources() {
    const val = _.get(this.sourceItem ? this.sourceItem : this.item.item, this.field.path);
    if (this.field.multiple) {
      return val || [];
    } else {
      return val ? [val] : [];
    }
  }

  set sources(v) {
    _.set(
      this.sourceItem ? this.sourceItem : this.item.item,
      this.field.path,
      this.field.multiple ? v : v[0],
    );
  }

  get sourceItems() {
    return this.field?.type === "collection" && this.collection.asyncPreloadIds(this.sources);
  }

  markDirty() {
    if (this.item) {
      this.item.markDirty();
    }
  }

  async editDetails() {
    await this.$schemas.createAndEdit(this, this.collection.path, this.item.item);
  }

  @InjectReactive({ default: null })
  context: BatchEditorContext;

  async batchEdit(field: EditorField) {
    await this.$openDialog(
      import("./BatchEditorBatchDialog.vue"),
      {
        context: this.context,
        items: this.sourceItems,
        item: this.item,
        sources: this.sources,
        field,
        editor: this,
      },
      {
        maxWidth: "max(50vw,500px)",
      },
    );
  }
}
