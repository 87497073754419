
import MenuListCheckbox from "@feathers-client/components/MenuListCheckbox.vue";
import MenuListSwitch from "@feathers-client/components/MenuListSwitch.vue";
import { Component, Vue, VModel } from "@feathers-client";
import { Prop } from "vue-property-decorator";

@Component({
  components: {
    MenuListCheckbox,
    MenuListSwitch,
  },
})
export default class EditorCheckbox extends Vue {
  @VModel()
  inputValue: boolean;

  @Prop()
  text: string;

  @Prop({ default: "checkbox" })
  type: "checkbox" | "switch";
}
