
import _ from "lodash"
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import { checkID, getID, FindType } from '@feathers-client'
import type { Order } from '@common/pos'
import type { LineItem } from '@common/posLineItem'

@Component
export default class OrderSystemProductOption extends Vue {

    @Prop()
    session : Order

    @Prop()
    cart: LineItem

    checkID = checkID

    get product() { return this.cart.sku }
    get productOptions() {
        return [];
    }

    stockStatus(option: string, value: string) {
        return 'bg-green000'
    }
}
