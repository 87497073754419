
import Dialog from "@feathers-client/mixins/Dialog";
import { Component, mixins, Prop, FindType } from "@feathers-client";
import MenuListNum from "@feathers-client/components/MenuListNum.vue";
import { FindPopRawType } from "@feathers-client";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import EditorObjectPickerList from "@schemaEditor/EditorObjectPickerList.vue";
import { Watch } from "nuxt-property-decorator";
import uuid from 'uuid/v4';
import _, { indexOf } from 'lodash';
import DataTableForErp from "~/components/erp/DataTableForErp.vue";
import { ObjectID } from "@db";

export type minfoType = {
  _id: ObjectID
  name: any;
  size: any;
  mime: any;
  thumb: any;
  id: string;
  success: boolean;
  complete: boolean;
  processing: boolean;
  error: any;
  progress: number;
  date: Date;
  // canDelete: boolean;
}

@Component({
  components: {
    MenuListNum,
    EditorTextField,
    EditorObjectPickerList,
    DataTableForErp,
  }
})
export default class DocUploaderDialog extends mixins(Dialog) {

  @Prop()
  title: string

  @Prop()
  inputFieldTitle: string

  @Prop()
  btnWord: string

  @Prop({ default: true })
  multi: boolean

  @Prop({ default: true })
  withUploader: boolean

  attachments: {
    docID: string,
    minfo: minfoType,
  }[] = []

  loading = false;


  async addAttachment(listEl: typeof this.attachments[number]) {
    if (this.loading) return;
    this.loading = true;
    const file = document.createElement('input');
    file.style.display = 'none';
    file.type = 'file';
    file.accept = 'image/*,.pdf';
    // file.multiple = true;
    document.body.append(file);
    file.click();
    await new Promise((resolve) => file.onchange = resolve);
    if (file.files.length == 0) { this.loading = false; return; }
    await Promise.all(_.map(file.files, img => this.uploadFile(img, listEl)));
    file.remove();
    this.loading = false;
  }

  async uploadFile(mfile, listEl: typeof this.attachments[number]) {
    var data = new FormData();
    data.append('file', mfile, mfile.name);

    const info = {
      _id: null,
      name: mfile.name,
      size: mfile.size,
      mime: mfile.type,
      thumb: null,
      id: uuid(),
      success: false,
      complete: false,
      processing: true,
      error: null,
      progress: 0,
      date: null,
    };

    listEl.minfo = info;

    try {
      const response = await this.$feathers.post(`attachments/upload`, data, {
        onUploadProgress: (progressEvent) => {
          info.progress = progressEvent.loaded / progressEvent.total;
        }
      });

      const rinfo = (response.data || {}).info || {};
      _.assign(info, rinfo);
      info.success = true;
      info.complete = true;
      info.progress = 1;
      info.processing = false;

    } catch (e) {
      info.error = e.message;
      info.complete = true;
      info.processing = false;
    }
  }

  async downloadFile(fileID) {
    if (this.loading) return;
    this.loading = true;
    const file = await this.$feathers.service("attachments").get(
      fileID
    );
    const originUrl = file.thumb ? `data:image/png;base64,${file.thumb}` : this.$attach(file._id);
    // const fileName = file.name;
    const fileData = await fetch(originUrl);
    const url = URL.createObjectURL(await fileData.blob());
    // const url = file.thumb ? `data:image/png;base64,${file.thumb}` : this.$attach(file._id);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    a.target = '_blank';
    a.href = url;
    a.download = file.name;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
    this.loading = false;
  }

  handler: FindType<'shop/staffs'> = null;

  returnData() {
    if ((this.withUploader ? !this.handler : false) || this.attachments.find(el => el.docID === null || el.docID === undefined || el.docID === '')) {
      this.$store.commit("SET_ERROR", this.$t('subsequentPaymentOrders.erp.pleaseInputAllFields'));
      return;
    }
    const returnQuotations = _.map(this.attachments, el => {
      return {
        ...el,
        attachment: el.minfo._id,
        handler: this.handler
      }
    })
    this.modalResult(returnQuotations);
  }

  addNewQuoRow() {
    this.attachments.push({
      docID: null,
      minfo: null,
    })
  }

  async mounted() {
    this.addNewQuoRow()
  }


}
