const {SaxesParser} = require('saxes');
const {PassThrough} = require('readable-stream');

const textDecoder = typeof TextDecoder === 'undefined' ? null : new TextDecoder('utf-8');

function bufferToString(chunk) {
  if (typeof chunk === 'string') {
    return chunk;
  }
  if (textDecoder) {
    return textDecoder.decode(chunk);
  }
  return chunk.toString();
}

function streamToIterator(stream) {
  return {
    [Symbol.asyncIterator]: async function *(){
      const reader = stream.getReader()
      try {
        while (true) {
          const {done, value} = await reader.read()
          if (done) return
          yield value
        }
      }
      finally {
        reader.releaseLock()
      }
    }
  }
}

module.exports = async function* (iterable) {
  // TODO: Remove once node v8 is deprecated
  // Detect and upgrade old streams
  if (iterable.pipe && !iterable[Symbol.asyncIterator]) {
    iterable = iterable.pipe(new PassThrough());
  }
  const saxesParser = new SaxesParser();
  let error;
  saxesParser.on('error', err => {
    error = err;
  });
  let events = [];
  saxesParser.on('opentag', value => events.push({eventType: 'opentag', value}));
  saxesParser.on('text', value => {
    events.push({eventType: 'text', value});
  });
  saxesParser.on('closetag', value => events.push({eventType: 'closetag', value}));
  if(typeof TextDecoderStream !== undefined) {
    const iterator = iterable[Symbol.asyncIterator]();
    const stream = new ReadableStream({
      async pull(controller) {
        const { value, done } = await iterator.next();

        if (done) {
          controller.close();
        } else {
          controller.enqueue(value);
        }
      }
    });

    const decodeStream = new TextDecoderStream();
    stream.pipeThrough(decodeStream);
    for await (const chunk of streamToIterator(decodeStream.readable)) {
      saxesParser.write(chunk);
      // saxesParser.write and saxesParser.on() are synchronous,
      // so we can only reach the below line once all events have been emitted
      if (error) throw error;
      // As a performance optimization, we gather all events instead of passing
      // them one by one, which would cause each event to go through the event queue
      yield events;
      events = [];
    }
  } else {
    for await (const chunk of iterable) {
      saxesParser.write(bufferToString(chunk));
      // saxesParser.write and saxesParser.on() are synchronous,
      // so we can only reach the below line once all events have been emitted
      if (error) throw error;
      // As a performance optimization, we gather all events instead of passing
      // them one by one, which would cause each event to go through the event queue
      yield events;
      events = [];
    }
  }
};
