<script>
export default {
  methods: {
    initApi() {
        return (this._initPromise || (this._initPromise = this.initCore()))
    },
    async initCore() {
        if (!('BarcodeDetector' in window) || !((await BarcodeDetector.getSupportedFormats()).includes('qr_code'))) {
            console.log("Loading barcode scanner polyfill");
            window.BarcodeDetector = (await import("../polyfill/BarcodeDetector.ts")).default;
        }
    },
    async onDetect(resultPromise) {
      await this.initApi();
      this.$emit("detect", resultPromise);

      try {
        const { content } = await resultPromise;

        if (content !== null) {
          this.$emit("decode", content);
          if(this.$scanner) {
            this.$scanner.onResult({
              code: content,
              type: "camera",
            })
          }
        }
      } catch (error) {
        // fail silently
      }
    }
  }
};
</script>
