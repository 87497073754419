
import { Component, Vue, Prop, Watch } from "@feathers-client";
import { LangArrType } from "@feathers-client/i18n";
import { defineComponent } from "site-editor/def";
// @ts-ignore
import WorldSvg from "!vue-svg-loader!../../assets/header/world.svg";
// @ts-ignore
import MenuSvg from "!vue-svg-loader!../../assets/header/menu.svg";

export const componentInfo = defineComponent({
  group: "footer",
  groupProps: {
    editorCols: 2,
  },
  variants: [
    {
      key: "footer",
    },
  ],
  hideConfirm: true,
});

@Component({
  components: {
    WorldSvg,
    MenuSvg,
  },
})
export default class SiteEditorFooter extends Vue {
  @Prop()
  variant: string;

  @Prop()
  logo: any;

  @Prop()
  logoSize: "small" | "medium" | "large";

  @Prop()
  bgColor: string;

  @Prop()
  fontColor: string;

  @Prop()
  titleFontColor: string;

  @Prop()
  menus: SiteEditorFooterMenu[];

  @Prop()
  paymentMethods: string[];

  @Prop()
  socialMedia: SiteEditorFooterSoicalMedia[];

  @Prop()
  copyright: string;

  @Prop()
  term: SiteEditorFooterUrl;

  @Prop()
  privacy: SiteEditorFooterUrl;

  @Prop()
  disclaimer: SiteEditorFooterUrl;

  @Prop()
  additionalText: LangArrType;

  @Prop()
  additionalText2: LangArrType;

  mobilePanel = [];

  paymentIcon = {
    visa: require("../../assets/footer/visa.svg"),
    master: require("../../assets/footer/master.svg"),
    union: require("../../assets/footer/union.svg"),
    paypal: require("../../assets/footer/paypal.svg"),
    applePay: require("../../assets/footer/applePay.svg"),
    jcb: require("../../assets/footer/jcb.svg"),
    ae: require("../../assets/footer/ae.svg"),
    alipayhk: require("../../assets/footer/alipayhk.svg"),
    fps: require("../../assets/footer/fps.svg"),
    payme: require("../../assets/footer/payme.svg"),
    octopus: require("../../assets/footer/octopus.svg"),
    wechatpay: require("../../assets/footer/wechatpay.svg"),
    alipay: require("../../assets/footer/alipay.svg"),
    googlepay: require("../../assets/footer/googlepay.svg"),
  };

  getLinkProp(type: string, url: string) {
    if (!url) return { class: "cursor-default" };
    if (type === "external") return { href: url, target: "_blank", rel: "noopener noreferrer" };
    else return { to: this.$localePath(url) };
  }

  getLink(type: string, url: string) {
    if (!url) return "div";
    if (type === "internal") {
      return "nuxt-link";
    } else {
      return "a";
    }
  }

  get footerMode() {
    if (this.$store.state.footerMode) {
      return this.$store.state.footerMode;
    } else return "full";
  }
}

export interface SiteEditorFooterMenu {
  _id?: string;
  title?: LangArrType;
  items?: SiteEditorFooterMenuItem[];
  haveLink?: boolean;
  urlType?: string;
  url?: string;
  productName?: string;
}

export interface SiteEditorFooterSoicalMedia {
  id?: string;
  file: any;
  title: string;
  url: string;
}

export interface SiteEditorFooterMenuItem {
  _id?: string;
  title?: LangArrType;
  haveLink?: boolean;
  urlType?: string;
  url?: string;
  productName?: string;
}

export interface SiteEditorFooterUrl {
  id?: string;
  title?: string;
  otherName?: boolean;
  urlType?: string;
  url?: string;
}
