
import _ from "lodash";
import { Component, Prop, Vue, Watch, mixins, VModel, PropSync, getID } from "@feathers-client";
import { PosOrder } from "~/plugins/pos/order";
import { PaymentType } from "~/common/common";
import "~/plugins/payments";

@Component
export default class OrderSystemPaymentRecord extends Vue {
  @Prop()
  session: PosOrder;

  @Prop()
  item: PaymentType;

  @Prop(Boolean)
  mobile: boolean;

  loading = false;

  get query() {
    return {
      $populate: ["methodRef"],
    };
  }

  get canEdit() {
    return this.isDone && this.$pos.hasStaffRole("void");
  }

  get isDone() {
    return this.session.status === "done" || this.session.status === "confirmed";
  }

  editOrder() {
    this.$emit("editOrder");
  }

  async refund() {
    const c = await this.$openDialog(
      import("@feathers-client/components-internal/ConfirmDialog2.vue"),
      {
        title: this.$t("tableView.repay") + " ？ ",
        content: this.$t("tableView.repayDialog"),
        titleClass: "text-xl font-medium",
        contentClass: "text-mbase font-normal",
        dialogClass: "bg-dark-surface-neutral-subtle rounded-2xl",
        confirmText: "text-sm font-medium",
        cancelText: "text-sm text-black font-medium",
        confirm: this.$t("tableView.repay"),
      },
      {
        maxWidth: "500px",
      },
    );
    if (!c) return;
    this.loading = true;
    try {
      const payment = await this.$paymentManager.refundPayment(getID(this.item));
      Object.assign(this.item, payment);
      await this.session?.reload?.();
      await this.session.printInvoice({
        // cashbox,
      });
    } catch (e) {
      console.warn(e);
      this.$store.commit("SET_ERROR", e.message);
    } finally {
      this.loading = false;
    }
  }
}
