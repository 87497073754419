
import { Vue, Component, Prop, mixins } from "@feathers-client";
import Dialog from "@feathers-client/mixins/Dialog";
import Editor from "@schemaEditor/Editor.vue";
import { SiteEditorManager } from "site-editor";
import SiteEditorPagePreviewer from "site-editor/PagePreviewer.vue";

@Component({
  components: {
    Editor,
    SiteEditorPagePreviewer,
  },
})
export default class ProductEditor extends mixins(Dialog) {
  @Prop()
  pageId: string;

  @Prop()
  status: string;

  @Prop()
  activeId: string;

  @Prop()
  path: string;

  manager: SiteEditorManager = null;

  get frontendUrl() {
    let frontendUrl = this.$shop.posInvoiceDomain || this.$shop.domains?.[0] || this.$shop.subdomain?.[0] || "";
    if (!frontendUrl.startsWith("https://")) {
      frontendUrl = "https://" + frontendUrl;
    }

    if (frontendUrl.endsWith("/")) {
      frontendUrl.slice(0, -1);
    }
    return frontendUrl;
  }

  moreActions(item?) {
    return [
      {
        title: "basic.copy",
        icon: "$fileCopy",
        color: "black",
        action: () => this.cloneItem(item),
      },
      {
        title: "basic.delete",
        icon: "$deleteEcshop",
        color: "light-action-danger-default",
        action: () => this.confirmRemove(item),
      },
    ];
  }

  preview(item?) {
    this.manager = new SiteEditorManager({
      parent: this,
      propsData: {
        pageData: item.pageData,
        headerStyle: item.header,
      },
    });

    this.manager.openPreview(new URL("/pageEditor", this.frontendUrl).toString(), false);
  }

  async editItem(item?: any) {
    if (item.status === "published") {
      this.$router.push(
        `${this.$route.path.endsWith("/") ? this.$route.path : this.$route.path + "/"}edit/${
          this.pageId || ""
        }?cloneDraftId=${item?._id || ""}`,
      );
    } else {
      this.$router.push(
        `${this.$route.path.endsWith("/") ? this.$route.path : this.$route.path + "/"}edit/${
          this.pageId || ""
        }?draftId=${item._id || ""}`,
      );
    }

    this.modalResult(true);
    return;
  }

  async cloneItem(item?: any) {
    this.$router.push(
      `${this.$route.path.endsWith("/") ? this.$route.path : this.$route.path + "/"}edit/${
        item?.page || ""
      }?cloneDraft=${item?._id}`,
    );

    this.modalResult(true);
    return;
  }

  async deleteItem(item?) {
    try {
      await (this.$refs.editor as any).deleteItemCore(item);
      const remainingDraft = await this.$feathers.service("shop/pages/history").find({
        query: {
          page: item.page,
          status: "draft",
          $paginate: false,
        },
        paginate: false,
      });

      await this.$feathers.service("shop/pages").patch(item.page, {
        draft: remainingDraft.length > 1,
      });

      this.$store.commit("SET_SUCCESS", this.$t("basic.deleteSuccess"));
    } catch (e: any) {
      this.$store.commit("SET_ERROR", e.message);
    }
  }

  async confirmRemove(item?) {
    const c = await this.$openDialog(
      import("@feathers-client/components-internal/RemoveDialog.vue"),
      {
        title: this.$t("pages.shop/pages.doYouWantToDelete"),
      },
      {
        maxWidth: "400px",
      },
    );
    if (!c) return;
    this.deleteItem(item);
  }
}
