
import { Component, Prop, VModel, Vue, checkID } from "@feathers-client";
import type { SpecType } from "@common/common";
import { FindType } from "@feathers-client";

@Component
export default class SpecEditor extends Vue {
  styleOptions = {
    outlined: true,
    hideDetails: true,
    dense: true,
  };

  @Prop()
  product: FindType<"shop/product/groups">;

  @Prop()
  item: {
    spec: string;
    specValue?: string;
    value?: any;
  };

  get finalItem() {
    return this.item ?? this.product?.specs?.find(spec => checkID(spec.spec, this.spec));
  }

  @Prop()
  spec: SpecType;
}
