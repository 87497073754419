import _ from "lodash";
import moment from "moment";

import type { Order, QuotationPick } from "./pos";
import type { LineItem } from "./posLineItem";
import {
  DiscountType,
  DiscountMemberType,
  OrderTempPointType,
  CouponPopType,
  DiscountMemberFilterType,
  UserPointSummaryType,
  Vue,
  OrderDiscountType,
  OrderTaxType,
} from "./common";
import { Component, mixins, Prop, Watch } from "nuxt-property-decorator";

import { ProductInfo } from "./product";

import { getID, checkID } from "./util-core";
import { roundValue } from "./util";

export type DiscountResultErrorInfo = {
  price?: number;
  quantity?: number;
  member?: {
    member?: DiscountMemberType;
    quantity?: number;
    target?: number;
    price?: number;
    filtered?: {
      remain: number;
      picked: number;
      productIdx: number;
    }[];
  };
};

export type DiscountResultError = DiscountResultErrorGeneral | DiscountResultErrorOrder | DiscountResultErrorMember;

export interface DiscountResultErrorBase {
  $t: string;
  $ta?: any;
}

export interface DiscountResultErrorGeneral extends DiscountResultErrorBase {
  $t: // coupon rules
  | "discount.errors.couponNotValid"
    | "discount.errors.couponExpired"
    | "discount.errors.discountNotValid" // invalid coupon data
    | "discount.errors.noEffect" // nothing is applicable to discount
    | "discount.errors.discountTime"
    | "discount.errors.disabled"

    // no. of usage limit
    | "discount.errors.loginRequired"
    | "discount.errors.checkingUsage"
    | "discount.errors.usageLimit"

    // exclusive violations
    | "discount.errors.exclusive"
    | "discount.errors.exclusiveMax"

    // order related
    | "discount.errors.discountRank" // user rank
    | "discount.errors.shippingMethodNotApplicable";
}

export interface DiscountResultErrorOrder extends DiscountResultErrorBase {
  $t: "discount.errors.minQty" | "discount.errors.maxQty" | "discount.errors.minPrice" | "discount.errors.maxPrice";
  $ta: {
    current: number;
    target: number;
  };
}

export interface DiscountResultErrorMember extends DiscountResultErrorBase {
  $t:
    | "discount.errors.member.minQty"
    | "discount.errors.member.maxQty"
    | "discount.errors.member.minPrice"
    | "discount.errors.member.maxPrice";
  $ta: {
    member: DiscountMemberType;
    current: number;
    target: number;
    filtered: {
      picked: number;
      qty: number;
      price: number;
      productIdx: number;
    }[];
  };
}

export function checkFilter(filter: DiscountMemberFilterType, product: LineItem, efilter: DiscountMemberFilterType) {
  let flag = false;
  if (
    filter.all ||
    filter.skus.find(it => checkID(it, product.skuId)) ||
    filter.products.find(it => checkID(it, product.productGroup)) ||
    filter.categories.find(cat => product.sku.normalizedCategories.includes(getID(cat)))
  ) {
    // warehouse check is after product check
    if (!filter.warehouses?.length || filter.warehouses.find(it => checkID(it, product.warehouse))) {
      flag = true;
    }
  }

  if (
    flag &&
    efilter &&
    (
      efilter.skus.find(it => checkID(it, product.skuId)) ||
      efilter.products.find(it => checkID(it, product.productGroup)) ||
      efilter.categories.find(cat => product.sku.normalizedCategories.includes(getID(cat)))
  )) {
    flag = false;
  }
  return flag;
}

export function checkCoupon(coupon: CouponPopType): true | DiscountResultErrorGeneral {
  if (coupon.status !== "valid" || !coupon.template) return { $t: "discount.errors.couponNotValid" };
  if (moment(coupon.expiresAt).isBefore(moment()) || moment(coupon.template.validUntil).isBefore(moment()))
    return { $t: "discount.errors.couponExpired" };
  if (!coupon.template.discount) return { $t: "discount.errors.discountNotValid" };
  return true;
}

export type DiscountRulePick = [productIdx: number, quantity: number, unitPrice: number];

@Component
export class DiscountRule extends Vue {
  @Prop()
  discount: DiscountType;

  globalUsage: number | null = null;
  userUsage: number | null = null;

  get cal() {
    return this.$parent as DiscountCalculator;
  }

  get grouping() {
    return this.discount.grouping ?? false;
  }

  get phase() {
    return this.discount.phase ?? 0;
  }

  get conditionPhase() {
    return this.discount.conditionPhase ?? this.phase + 1;
  }

  get amountPhase() {
    return this.discount.amountPhase ?? this.phase + 1;
  }

  get groupKey() {
    return this.discount.grouping ? "$" + (this.discount.groupKey || "") : "nongroup";
  }

  get groupIndex() {
    return this.cal.groupKeys.indexOf(this.groupKey);
  }

  get productIndices() {
    if (!this.discount.members?.length) {
      return [this.cal.order.realProducts.map((p, idx) => idx)];
    }
    return this.discount.members.map(m => {
      return this.cal.order.realProducts
        .map((p, idx) => (checkFilter(m.filter, p, m.efilter) ? idx : -1))
        .filter(it => it !== -1);
    });
  }

  get usePointIndex() {
    return this.discount.usePoint ? this.cal.pointKeys.indexOf(getID(this.discount.usePoint)) : -1;
  }

  get rewardPointIndices() {
    return this.discount.rewardPoints?.map(p => this.cal.pointKeys.indexOf(getID(p.point)));
  }

  usePointNum = 0;
  useCouponCode = "";

  get maxDiscountInt() {
    return this.discount.maxDiscountInt || 0;
  }

  get minPriceInt() {
    return this.discount.minPriceInt || 0;
  }

  get maxPriceInt() {
    return this.discount.maxPriceInt || 0;
  }

  get members() {
    if (!this.discount.members?.length) {
      return [
        {
          percentageDiscount: 0,
          fixedDiscountInt: 0,
          stepDiscountInt: 0,
          stepPriceInt: 0,
          maxDiscountInt: 0,
          stepQty: 1,
          free: [],
        },
      ];
    }
    return (this.discount.members || []).map(member => ({
      percentageDiscount: member.percentageDiscount,
      fixedDiscountInt: member.fixedDiscountInt || 0,
      stepDiscountInt: member.stepDiscountInt || 0,
      maxDiscountInt: member.maxDiscountInt || 0,
      stepQty: member.stepQty || 1,
      stepPriceInt: member.stepPriceInt || 0,
      free: (member.free || []).map(f => ({
        productIdx: this.cal.freeKeys.indexOf(getID(f.product)),
        quantity: f.quantity,
        stepQuantity: f.stepQuantity,
      })),
    }));
  }

  compute(state: DiscountState): {
    pickedData: DiscountRulePick[][];
    errors: DiscountResultError[];
  } {
    const originalQtyGroup: readonly number[] = state.productQty[this.groupIndex];
    const originalAmountGroup: readonly number[] = state.productSubtotal[this.groupIndex];
    const qtyGroup = originalQtyGroup.slice();

    const allProductIndices = this.productIndices;
    const members = this.discount.members || [];

    const errors: DiscountResultError[] = [];
    const memberErrors: DiscountResultError[][] = _.times(allProductIndices.length, () => []);
    const pickedData: DiscountRulePick[][] = new Array(allProductIndices.length).fill(null);

    for (let memberIdx = 0; memberIdx < allProductIndices.length; memberIdx++) {
      const member = members[memberIdx];
      const productIndices = allProductIndices[memberIdx];

      let curQty = 0;
      let picked: DiscountRulePick[] = [];
      for (let i = 0; i < productIndices.length; i++) {
        const productIndex = productIndices[i];
        const pick = Math.min(member?.maxQty || Number.MAX_SAFE_INTEGER, qtyGroup[productIndex]);
        if (pick) {
          picked.push([productIndex, pick, Math.round(originalAmountGroup[productIndex] / qtyGroup[productIndex])]);
          curQty += pick;
        }
      }
      if (member) {
        if (member.stepQty && curQty % member.stepQty !== 0) {
          // TODO: make it to only handle integer
          while (curQty > member.stepQty && curQty % member.stepQty !== 0) {
            const it = _.last(picked);
            if (!--it[1]) picked.pop();
            curQty--;
          }
        }
        let totalQty = _.sumBy(picked, ([p]) => originalQtyGroup[p]);
        if (curQty < member.minQty || (member.minNonGroupQty && totalQty < member.minNonGroupQty)) {
          const remainQty = _.sumBy(picked, ([p]) => qtyGroup[p]);
          const remainTotalQty = totalQty;
          const needQuantity = Math.max(
            member.minQty - remainQty,
            member.minNonGroupQty ? member.minNonGroupQty - remainTotalQty : 0,
            0,
          );
          memberErrors[memberIdx].push({
            $t: "discount.errors.member.minQty",
            $ta: {
              member,
              current: totalQty,
              target: _.sumBy(picked, ([pidx, qty, price]) => qty) + needQuantity,
              filtered: picked.map(([pidx, qty, price]) => ({
                picked: qty,
                qty: qtyGroup[pidx],
                price,
                productIdx: pidx,
              })),
            },
          });
          continue;
        }
        if (member.maxQty === -1 && totalQty > 0) {
          memberErrors[memberIdx].push({
            $t: "discount.errors.member.maxQty",
            $ta: {
              member,
              current: totalQty,
              target: member.maxQty,
              filtered: picked.map(([pidx, qty, price]) => ({
                picked: qty,
                qty: qtyGroup[pidx],
                price,
                productIdx: pidx,
              })),
            },
          });
          continue;
        }
        let memberSum = _.sumBy(picked, p => p[1] * p[2]);
        if (member.minPriceInt && memberSum < member.minPriceInt) {
          memberErrors[memberIdx].push({
            $t: "discount.errors.member.minPrice",
            $ta: {
              member,
              current: memberSum,
              target: member.minPriceInt,
              filtered: picked.map(([pidx, qty, price]) => ({
                picked: qty,
                qty: qtyGroup[pidx],
                price,
                productIdx: pidx,
              })),
            },
          });
          continue;
        }
        if (member.maxPriceInt && memberSum > member.maxPriceInt) {
          memberErrors[memberIdx].push({
            $t: "discount.errors.member.maxPrice",
            $ta: {
              member,
              current: memberSum,
              target: member.maxPriceInt,
              filtered: picked.map(([pidx, qty, price]) => ({
                picked: qty,
                qty: qtyGroup[pidx],
                price,
                productIdx: pidx,
              })),
            },
          });
          continue;
        }
        for (let [productIndex, num] of picked) {
          qtyGroup[productIndex] -= num;
        }
      }

      pickedData[memberIdx] = picked;
    }

    if (this.discount.matchLoopQuantity) {
      let minQty = _.min(
        pickedData.map((p, memberIdx) => Math.floor(_.sumBy(p, s => s[1]) / (members[memberIdx]?.stepQty || 1))),
      );
      let maxQty = _.max(
        pickedData.map((p, memberIdx) => Math.floor(_.sumBy(p, s => s[1]) / (members[memberIdx]?.stepQty || 1))),
      );
      if (maxQty > minQty) {
        for (let memberIdx = 0; memberIdx < pickedData.length; memberIdx++) {
          const picked = pickedData[memberIdx];
          let curQty = _.sumBy(picked, p => p[1]);
          const targetQty = minQty * (members[memberIdx]?.stepQty || 1);

          while (curQty > targetQty) {
            const it = _.last(picked);
            if (!--it[1]) picked.pop();
            curQty--;
          }
        }
      }
    }

    const sumQty = _.sumBy(pickedData, p => _.sumBy(p, p => p[1]));
    const sumTotal = _.sumBy(pickedData, p => _.sumBy(p, p => p[1] * p[2]));
    if (this.discount.minQty && sumQty < this.discount.minQty)
      errors.push({
        $t: "discount.errors.minQty",
        $ta: {
          current: sumQty,
          target: this.discount.minQty,
        },
      });
    if (this.discount.maxQty && sumQty > this.discount.maxQty)
      errors.push({
        $t: "discount.errors.maxQty",
        $ta: {
          current: sumQty,
          target: this.discount.maxQty,
        },
      });
    if (this.minPriceInt && sumTotal < this.minPriceInt)
      errors.push({
        $t: "discount.errors.minPrice",
        $ta: {
          current: sumTotal,
          target: this.minPriceInt,
        },
      });
    if (this.maxPriceInt && sumTotal > this.maxPriceInt)
      errors.push({
        $t: "discount.errors.maxPrice",
        $ta: {
          current: sumTotal,
          target: this.maxPriceInt,
        },
      });

    if (this.discount.limitPerUser) {
      if (!this.cal.order.user) {
        errors.push({
          $t: "discount.errors.loginRequired",
        });
      } else if (this.userUsage === null) {
        errors.push({
          $t: "discount.errors.checkingUsage",
        });
      } else if (this.userUsage >= this.discount.limitPerUser) {
        errors.push({
          $t: "discount.errors.usageLimit",
        });
      }
    }
    if (this.discount.limitTotal) {
      if (this.globalUsage === null) {
        errors.push({
          $t: "discount.errors.checkingUsage",
        });
      } else if (this.globalUsage >= this.discount.limitTotal) {
        errors.push({
          $t: "discount.errors.usageLimit",
        });
      }
    }

    if (this.discount.shippingConditions?.length) {
      const rule = this.discount.shippingConditions.find(rule =>
        this.cal.order.preShippingGroups.find(method => !!checkID(rule.method, method.method)),
      );
      if (!rule) {
        errors.push({
          $t: "discount.errors.shippingMethodNotApplicable",
        });
      }
    }

    if (!errors.length) {
      if (this.discount.conditionOr) {
        const some = pickedData.find(it => !!it);
        if (some) {
          for (let i = 0; i < pickedData.length; i++) {
            if (!pickedData[i]) pickedData[i] = [];
          }
          return {
            pickedData,
            errors: [],
          };
        }
      } else {
        if (_.every(pickedData, p => !!p)) {
          return {
            pickedData,
            errors: [],
          };
        }
      }
    }

    pickedData.fill([]);
    return {
      pickedData,
      errors: errors.concat(_.flatMap(memberErrors)),
    };
  }

  static basicCheck(discount: DiscountType, order: Order) {
    const errors: DiscountResultError[] = [];

    DiscountRule.basicCheckInner(discount, order, errors);

    return !errors.length;
  }

  static basicCheckInner(discount: DiscountType, order: Order, errors: DiscountResultError[]) {
    if(discount.type === 'tax') {
      if(order.disabledTaxRules?.includes?.(discount._id)) {
        errors.push({ $t: "discount.errors.disabled" });
      }
    } else {
      if(order.disabledDiscountRules?.includes?.(discount._id)) {
        errors.push({ $t: "discount.errors.disabled" });
      }
    }

    if (discount.timeConditions?.length) {
      // check time condition
      const today = moment(order?.date || new Date());

      for (let cond of discount.timeConditions) {
        if (cond.weekdays && cond.weekdays.length > 0) {
          if (cond.weekdays.indexOf(today.get("weekday")) === -1) {
            errors.push({ $t: "discount.errors.discountTime" });
          }
        }

        if (cond.months && cond.months.length > 0) {
          if (cond.months.indexOf(today.get("month")) === -1) {
            errors.push({ $t: "discount.errors.discountTime" });
          }
        }

        if (cond.timeRange && cond.timeRange.length > 0) {
          if (
            !_.find(cond.timeRange, range => today.get("hour") >= range.startHour && today.get("hour") < range.endHour)
          ) {
            errors.push({ $t: "discount.errors.discountTime" });
          }
        }

        if (cond.dateRange && cond.dateRange.length > 0) {
          if (
            !_.find(cond.dateRange, range => today.get("date") >= range.startDay && today.get("date") < range.endDay)
          ) {
            errors.push({ $t: "discount.errors.discountTime" });
          }
        }
      }
    }

    if (discount.rankConditions?.length) {
      const ranks = order?.userRankLookup || {};
      if(discount.rankMode === "exclude"){
        if (discount.rankConditions.find(it => !!ranks[getID(it)])) {
          errors.push({ $t: "discount.errors.discountRank" });
        }
      }else{
        if (!discount.rankConditions.find(it => !!ranks[getID(it)])) {
          errors.push({ $t: "discount.errors.discountRank" });
        }
      }
    }
  }

  check(applied: DiscountApply[]): DiscountResultError[] {
    const errors: DiscountResultError[] = [];

    DiscountRule.basicCheckInner(this.discount, this.cal?.order, errors);

    return errors;
  }

  stepCheckExclusive(applied: DiscountApply[], didx: number) {
    const errors: DiscountResultError[] = [];
    let self = applied[didx];
    if (this.discount.exclusive) {
      const exclusiveKey = this.discount.exclusiveKey;
      const sameKey = applied.slice(0, didx).map((applied, idx) => {
        if (!applied?.errors?.length) {
          const d = this.cal.discounts[idx];
          if (d !== this && d.discount.exclusiveKey === exclusiveKey) {
            return idx;
          }
        }
        return -1;
      }).filter(it => it !== -1);
      const diffKey = applied.slice(0, didx).map((applied, idx) => {
        if (!applied?.errors?.length) {
          const d = this.cal.discounts[idx];
          if (d !== this && !(d.discount.exclusiveKey && d.discount.exclusiveKey === exclusiveKey)) {
            return idx;
          }
        }
        return -1;
      }).filter(it => it !== -1);

      if (diffKey.length) errors.push({ $t: "discount.errors.exclusive" });
      if (sameKey.length) {
        let maxNum = parseInt((exclusiveKey || '').split(":")[1]);
        if (isNaN(maxNum) || maxNum < 1) maxNum = 1;
        if (sameKey.length + 1 > maxNum) {
          errors.push({ $t: "discount.errors.exclusiveMax" });
        }
      }
    }
    if(errors.length) {
      if(!self) {
        self = applied[didx] = {};
      }
      self.errors = self.errors || [];
      self.errors.push(...errors);
    }
  }

  update(pickData: DiscountRulePick[][], cur: DiscountState): DiscountApply {
    let ruleDiscounted = 0;

    const discount = this.discount;
    const curQtyGroup: number[] = cur.productQty[this.groupIndex];
    const curAmount: number[] = cur.productSubtotal[this.groupIndex];
    const rootAmount: number[] = cur.productSubtotal[0];

    const members = this.members;

    let productDiscounts: number[];
    let productQty: number[];
    let pointsUsed: number[];
    let pointsReward: number[];
    let frees: number[];
    let includeTaxPercent: number[];
    let excludeTaxPercent: number[];
    let pointToUse = 0;

    switch (discount.type) {
      case "tax": {
        for (let memberIdx = 0; memberIdx < pickData.length; memberIdx++) {
          const member = members[memberIdx];
          const picked = pickData[memberIdx];
          if (discount.taxPercent) {
            if (discount.taxIncluded) {
              if (!includeTaxPercent) {
                includeTaxPercent = new Array<number>(curAmount.length).fill(0);
              }
              for (let i = 0; i < picked.length; i++) {
                if (discount.taxPercent) includeTaxPercent[picked[i][0]] += discount.taxPercent;
              }
            } else {
              if (!excludeTaxPercent) {
                excludeTaxPercent = new Array<number>(curAmount.length).fill(0);
              }
              for (let i = 0; i < picked.length; i++) {
                if (discount.taxPercent) excludeTaxPercent[picked[i][0]] += discount.taxPercent;
              }
            }
          }
        }
        break;
      }

      default: {
        const beforeTotal = _.sumBy(pickData, picked => _.sumBy(picked, p => (p[1] ? p[1] * p[2] : 0)));
        let pointDiscount = 0;

        const pointRatio = this.usePointIndex !== -1 ? discount.usePointRatioInt || 1 : 0;

        if (this.usePointIndex !== -1) {
          let pointToUse =
            ((Math.min(beforeTotal / pointRatio, this.usePointNum, cur.pointsRemain[this.usePointIndex] || 0) /
              (discount.usePointStep || 1)) |
              0) *
            (discount.usePointStep || 1);

          pointDiscount = pointToUse * pointRatio;
          if (discount.usePointFixedMaxInt) {
            if (pointDiscount > discount.usePointFixedMaxInt) pointDiscount = discount.usePointFixedMaxInt;
          }
          if (discount.usePointPercentageMax) {
            const max =
              Math.floor(Math.min(beforeTotal, discount.usePointPercentageMax * beforeTotal) / pointRatio) * pointRatio;
            if (pointDiscount > max) pointDiscount = max;
          }
        }

        let remainFixed = discount.fixedDiscountInt || 0;
        let remainPoint = pointDiscount;


        productDiscounts = new Array<number>(curAmount.length).fill(0);
        productQty = new Array<number>(curAmount.length).fill(0);

        // handle member discount
        for (let memberIdx = 0; memberIdx < pickData.length; memberIdx++) {
          const member = members[memberIdx];
          const picked = pickData[memberIdx];
          const totals = _.map(picked, p => (p[1] ? p[1] * p[2] : 0));
          const pickedQty = _.sumBy(picked, p => p[1]);

          const total = _.sum(totals);
          const ctotal = _.sumBy(picked, p => curAmount[p[0]]);

          let step = pickedQty / member.stepQty;
          if(member.stepPriceInt) {
            const priceStep = Math.floor(ctotal / member.stepPriceInt);
            if(priceStep < step) step = priceStep;
          }

          if (picked.length > 0 && pickedQty > 0) {
            let toDiscount =
              (total * ((member.percentageDiscount || 0) + (discount.percentageDiscount || 0))) / 100 +
              member.fixedDiscountInt +
              member.stepDiscountInt * step;

            if (toDiscount < 0) toDiscount = 0;
            const beforeFixed = toDiscount;
            toDiscount += remainFixed + remainPoint;

            if (toDiscount > total) toDiscount = total;
            if (member.maxDiscountInt && toDiscount > member.maxDiscountInt) toDiscount = member.maxDiscountInt;
            if (toDiscount > ctotal) toDiscount = ctotal;
            if (this.maxDiscountInt && ruleDiscounted + toDiscount > this.maxDiscountInt)
              toDiscount = this.maxDiscountInt - ruleDiscounted;

            ruleDiscounted += toDiscount;
            if (beforeFixed < toDiscount) remainFixed = remainFixed - (toDiscount - beforeFixed);
            if (remainFixed < 0) {
              remainPoint += remainFixed;
              remainFixed = 0;
            }

            let remainTotal = total;
            let remainDiscount = Math.floor(toDiscount);
            let remainQty = 0;

            for (let i = 0; i < picked.length; i++) {
              const [p, qty, unit] = picked[i];
              if (discount.grouping) {
                curQtyGroup[p] = Math.max(0, curQtyGroup[p] - qty);
              }
              const subTotal = qty * unit;
              const discountSliced = remainTotal
                ? Number((BigInt(remainDiscount) * BigInt(subTotal)) / BigInt(remainTotal))
                : remainQty
                ? Number((BigInt(remainDiscount) * BigInt(qty)) / BigInt(remainQty))
                : picked.length - i - 1
                ? Number(BigInt(remainDiscount) / BigInt(picked.length - i))
                : remainDiscount;

              remainTotal -= subTotal;
              remainQty -= qty;
              remainDiscount -= discountSliced;

              if(rootAmount !== curAmount) {
                // have grouping
                curAmount[p] -= (curAmount[p] / (curQtyGroup[p] + qty) * qty)
              }
              rootAmount[p] -= discountSliced;
              productDiscounts[p] += discountSliced;
              productQty[p] += qty;
            }
          }

          if (member.free.length) {
            frees = new Array<number>(this.cal.freeKeys.length).fill(0);

            for (let free of member.free) {
              frees[free.productIdx] += (free.quantity || 0) + (free.stepQuantity || 0) * step;
            }
          }
        }
        // handle point discount

        pointToUse = pointDiscount ? Math.ceil(pointDiscount / pointRatio) : 0;

        if (pointToUse > 0) {
          cur.pointsRemain[this.usePointIndex] = (cur.pointsRemain[this.usePointIndex] || 0) - pointToUse;
          pointsUsed = new Array<number>(cur.pointsRemain.length).fill(0);
          pointsUsed[this.usePointIndex] = Math.ceil(pointDiscount / pointRatio);
        }

        const sumTotal = _.sumBy(pickData, p => _.sumBy(p, p => p[1] * p[2]));
        if (discount.rewardPoints.length) {
          pointsReward = new Array<number>(cur.pointsRemain.length).fill(0);
          for (let pointIdx = 0; pointIdx < discount.rewardPoints.length; pointIdx++) {
            const point = discount.rewardPoints[pointIdx];
            let v = Math.floor(+((point.fixed || 0) + ((point.percentageInt || 0) * sumTotal) / 100)); // TODO: make it a flag

            if (point.max && v > point.max) v = point.max;

            if (v > 0 && cur.hasUser) {
              pointsReward[this.rewardPointIndices[pointIdx]] += v;
            }
          }
        }

        break;
      }
    }

    return {
      productQty,
      productDiscounts,
      pointsUsed,
      pointsReward,
      frees,
      pointToUse,
      includeTaxPercent,
      excludeTaxPercent,
    };
  }
}

@Component
export class DiscountCalculator extends Vue {
  get order() {
    return this.$parent as Order;
  }

  discounts: DiscountRule[] = [];
  discountDict: {
    [key: string]: DiscountRule;
  } = {};

  discountCache: {
    [key: string]: DiscountType[];
  } = {};

  flushUpdateTask: Promise<void>;

  couponDirty = false;

  resetUsage() {
    for (let d of this.discounts) {
      d.globalUsage = null;
      d.userUsage = null;
    }
  }

  flushUpdates() {
    if (this.flushUpdateTask) return this.flushUpdateTask;
    const fetchDict: {
      [key: string]: any;
    } = {};

    if (this.order.realProducts.length) {
      if (!this.discountCache["any"]) {
        fetchDict["any"] = {
          "query.any": true,
        };
      }

      for (let product of this.order.realProducts) {
        if (!this.discountCache[`product_${product.productGroup}`]) {
          fetchDict[`product_${product.productGroup}`] = {
            "query.products": getID(product.productGroup),
          };
        }

        if (!this.discountCache[`sku_${product.sku?._id}`]) {
          fetchDict[`sku_${product.sku?._id}`] = {
            "query.skus": getID(product.sku?._id),
          };
        }

        for (let cat of product.normalizedCategories) {
          if (!this.discountCache[`category_${cat}`]) {
            fetchDict[`category_${cat}`] = {
              "query.categories": getID(cat),
            };
          }
        }
      }
    }

    if (Object.keys(fetchDict).length || this.couponDirty) {
      this.couponDirty = false;
      this.flushUpdateTask = (async () => {

        if (Object.keys(fetchDict).length) {
          const rules = await this.order.$feathers.service("shop/product/discounts").find({
            query: {
              $or: Object.values(fetchDict),
              automatic: true,
              validFrom: { $lte: moment().toDate() },
              validTo: { $gt: moment().toDate() },
              status: "valid",
              $paginate: false,
              shop: this.order.item.shop,
            },
            paginate: false,
          });

          for (let [key, value] of Object.entries(fetchDict)) {
            this.discountCache[key] = rules.filter(r => {
              return _.every(Object.entries(value), ([k, v]) => {
                const vv = _.get(r, k);
                return Array.isArray(vv) ? !!vv.find(i => checkID(i, v)) : vv === v;
              });
            });
          }
        }

        const fetchGlobal = this.discounts.filter(d => d.discount.limitTotal && d.globalUsage === null);
        const fetchUser = this.order.user
          ? this.discounts.filter(d => d.discount.limitPerUser && d.userUsage === null)
          : [];

        await Promise.all([
          ...fetchGlobal.map(async d => {
            const stats = await this.order.$feathers.service("shop/product/discount/logs").find({
              query: {
                $limit: 0,
                status: "confirmed",
                discount: d.discount._id,
              },
            });
            const cstats = this.order._id
              ? await this.order.$feathers.service("shop/product/discount/logs").find({
                  query: {
                    $limit: 0,
                    status: "confirmed",
                    discount: d.discount._id,
                    order: this.order._id,
                  },
                })
              : null;
            d.globalUsage = stats.total - (cstats?.total ?? 0);
          }),
          ...fetchUser.map(async d => {
            const stats = await this.order.$feathers.service("shop/product/discount/logs").find({
              query: {
                $limit: 0,
                status: "confirmed",
                discount: d.discount._id,
                user: this.order.user._id,
              },
            });
            const cstats = this.order._id
              ? await this.order.$feathers.service("shop/product/discount/logs").find({
                  query: {
                    $limit: 0,
                    status: "confirmed",
                    discount: d.discount._id,
                    user: this.order.user._id,
                    order: this.order._id,
                  },
                })
              : null;
            d.userUsage = stats.total - (cstats?.total ?? 0);
          }),
        ]);
      })().then(
        () => {
          this.flushUpdateTask = null;
          this.flushUpdates();
        },
        e => {
          this.flushUpdateTask = null;
        },
      );
    }

    const toAdd: {
      [key: string]: DiscountType;
    } = {};

    const addList = (list: DiscountType[], product: LineItem) => {
      if (!list) return;
      for (let item of list) {
        if (this.discountDict[getID(item)]) continue;
        const included =
          item.query.any ||
          !!item.query.products?.find?.(p => checkID(p, product.productGroup)) ||
          !!item.query.skus?.find?.(p => checkID(p, product.sku)) ||
          !!item.query.categories?.find?.(c => !!product.normalizedCategories.find(c2 => checkID(c, c2)));

        const excluded =
          !!item.query.eproducts?.find?.(p => checkID(p, product.productGroup)) ||
          !!item.query.eskus?.find?.(p => checkID(p, product.sku)) ||
          !!item.query.ecategories?.find?.(c => !!product.normalizedCategories.find(c2 => checkID(c, c2)));

        if (included && !excluded) {
          toAdd[getID(item)] = item;
        }
      }
    };

    for (let product of this.order.realProducts) {
      addList(this.discountCache["any"], product);
      addList(this.discountCache[`product_${product.productGroup}`], product);
      addList(this.discountCache[`sku_${product.sku?._id}`], product);

      for (let cat of product.normalizedCategories) {
        addList(this.discountCache[`category_${cat}`], product);
      }
    }

    const toAddList = Object.values(toAdd);
    if (toAddList.length) {
      this.addDiscounts(toAddList);
    }
  }

  async applyCouponCode(code: string) {
    const rules = await this.order.$feathers.service("shop/product/discounts").find({
      query: {
        couponCode: code,
        validFrom: { $lte: moment().toDate() },
        validTo: { $gt: moment().toDate() },
        status: "valid",
        $paginate: false,
        shop: this.order.item.shop,
      },
      paginate: false,
    });

    if (rules.length) {
      this.addDiscounts(rules);
      for (let rule of rules) {
        const item = this.discountDict[getID(rule)];
        if (item) {
          item.useCouponCode = code;
        }
      }
    }
  }

  addDiscounts(discounts: DiscountType[]) {
    const rulesToAdd = discounts
      .filter(d => !this.discountDict[getID(d)])
      .map(
        d =>
          new DiscountRule({
            parent: this,
            propsData: {
              discount: d,
            },
          }),
      );
    for (let rule of rulesToAdd) {
      this.discountDict[getID(rule.discount)] = rule;
    }
    this.discounts = _.orderBy([...this.discounts, ...rulesToAdd], [d => d.discount.priority], ["desc"]);
    this.couponDirty = true;
    this.flushUpdates();
  }

  removeDiscounts(discounts: (DiscountType | string)[]) {
    const toRemove = this.discounts.filter(it => !!discounts.find(jt => checkID(it.discount, jt)));
    for (let item of toRemove) {
      item.$destroy();
      delete this.discountDict[getID(item.discount)];
    }
    this.discounts = this.discounts.filter(it => !toRemove.includes(it));
  }

  get phases() {
    return _.uniq(this.discounts.map(it => it.phase || 0)).sort();
  }

  get phaseRules() {
    const phases = this.phases;
    const phaseDiscounts: number[][] = [];
    for (let phase of phases) {
      phaseDiscounts.push(
        this.discounts
          .map((p, idx) => (phase <= p.phase && phase + 1 >= p.conditionPhase ? idx : -1))
          .filter(it => it !== -1),
      );
    }
    return phaseDiscounts;
  }

  get groupKeys() {
    return [
      'nongroup',
      ..._.uniq(this.discounts.filter(it => it.groupKey).map(it => it.groupKey)),
    ];
  }

  get pointKeys() {
    return _.uniq([
      ...this.discounts.map(it => getID(it.discount.usePoint)),
      ...this.discounts.flatMap(it => _.map(it.discount.rewardPoints, p => getID(p.point))),
    ]).filter(it => !!it);
  }

  get freeKeys() {
    return this.discounts.flatMap(d => d.discount.members.flatMap(m => (m.free || []).map(f => getID(f.product))));
  }

  get initState(): DiscountState {
    const products = this.order.realProducts.map(it => it.quantity ?? 0);
    const subtotals = this.order.realProducts.map(it => it.totalInt ?? 0);

    return {
      productQty: this.groupKeys.map(_k => products.slice()),
      productSubtotal: this.groupKeys.map(_k => subtotals.slice()),
      pointsRemain: this.pointKeys.map(v => this.order.availPoints[v] ?? 0),
      hasUser: !!this.order.user,
    };
  }

  hasEffect(apply: DiscountApply, discount: DiscountRule) {
    return (
      apply.errors?.length ||
      apply.frees?.length ||
      !!apply.pointsReward?.find?.(it => it !== 0) ||
      !!apply.pointsUsed?.find?.(it => it !== 0) ||
      !!apply.productDiscounts?.find?.(it => it !== 0) ||
      discount?.discount?.shippingConditions?.length ||
      apply?.excludeTaxPercent?.length ||
      apply?.includeTaxPercent?.length
    );
  }

  update() {
    this.flushUpdates();
    const states: DiscountState[] = [];
    const first = this.initState;
    states.push(first);
    const rules = this.phaseRules;
    const phases = this.phases;

    const discountApplied: DiscountApply[] = new Array(this.discounts.length).fill(null);

    for (let ruleIdx = 0; ruleIdx < discountApplied.length; ruleIdx++) {
      const errors = this.discounts[ruleIdx].check(discountApplied);
      if (errors.length) {
        discountApplied[ruleIdx] = { errors };
      }
    }

    for (let i = 0; i < phases.length; i++) {
      const phase = phases[i];

      const cur: DiscountState = {
        productQty: first.productQty.map(i => i.slice()),
        productSubtotal: first.productSubtotal.map(i => i.slice()),
        pointsRemain: first.pointsRemain.slice(),
        hasUser: first.hasUser,
      };

      for (let didx = 0; didx < discountApplied.length; didx++) {
        let discount = discountApplied[didx];
        if (discount && !discount.errors) {
          let rule = this.discounts[didx];
          if(!discount.errors) {
            if (rule.groupIndex) {
              // skip group index 0, which is non grouping
              let a = cur.productQty[rule.groupIndex];
              let b = discount.productQty;
              if (b) {
                for (let idx = 0; idx < a.length; idx++) {
                  a[idx] -= b[idx];
                  const price = first.productSubtotal[0][idx] / first.productQty[0][idx] * b[idx];
                  cur.productSubtotal[rule.groupIndex][idx] -= price;
                }
              }
            }
  
            if (discount.productDiscounts) {
              for (let idx = 0; idx < cur.productSubtotal.length; idx++) {
                cur.productSubtotal[0][idx] -= discount.productDiscounts[idx];
              }
            }
            if (discount.pointsUsed) {
              for (let idx = 0; idx < cur.pointsRemain.length; idx++) {
                cur.pointsRemain[idx] -= discount.pointsUsed[idx];
              }
            }
          }
        }
      }

      states.push(cur);
      for (let ruleIdx of rules[i]) {
        if (discountApplied[ruleIdx]?.errors?.length) {
          // already errored
          continue;
        }
        const rule = this.discounts[ruleIdx];
        rule.stepCheckExclusive(discountApplied, ruleIdx);
        if (discountApplied[ruleIdx]?.errors?.length) {
          // already errored
          continue;
        }
        const condIdx = Math.min(i + 1, _.sortedIndex(phases, rule.conditionPhase));

        let curPickedData: DiscountRulePick[][];

        if (condIdx <= i + 1) {
          // only check before
          const { pickedData, errors } = rule.compute(states[condIdx]);
          if (errors.length) {
            discountApplied[ruleIdx] = {
              errors,
            };
            continue;
          }
          curPickedData = pickedData;
        }

        const amountIdx = Math.min(i + 1, _.sortedIndex(phases, rule.amountPhase));

        if (!curPickedData || amountIdx !== condIdx) {
          const { pickedData, errors } = rule.compute(states[amountIdx]);
          if (errors.length) {
            discountApplied[ruleIdx] = {
              errors,
            };
            continue;
          }
          curPickedData = pickedData;
        }

        const apply = rule.update(curPickedData, cur);

        if (rule.phase === phase) {
          if (this.hasEffect(apply, rule)) {
            discountApplied[ruleIdx] = apply;
          } else {
            discountApplied[ruleIdx] = {
              errors: [{ $t: "discount.errors.noEffect" }],
            };
          }
        }
      }
    }

    // Finalize tax

    let includeTaxPercent: number[];
    let excludeTaxPercent: number[];
    let taxes: OrderTaxType[] = [];
    let productTaxes: number[] = [];
    for (let idx = 0; idx < discountApplied.length; idx++) {
      const it = discountApplied[idx];
      if (it.errors) continue;
      const d = this.discounts[idx];
      if (d.discount.type !== "tax") continue;

      includeTaxPercent = sumArray(includeTaxPercent, it.includeTaxPercent);
      excludeTaxPercent = sumArray(excludeTaxPercent, it.excludeTaxPercent);
    }

    if (includeTaxPercent || excludeTaxPercent) {
      const lastState = states.at(-1);

      const taxRules = Array.from(discountApplied.entries())
        .filter(it => !it[1].errors && this.discounts[it[0]].discount.type === "tax")
        .map(it => {
          const rule = this.discounts[it[0]].discount;
          const result = {
            tax: rule._id,
            name: rule.name,
            totalInt: 0,
            productAmountInt: 0,
            included: rule.taxIncluded ?? false,
            percent: rule.taxPercent,
          };
          return [it[0], it[1], result] as const;
        });

        const subtotals = lastState.productSubtotal[0];
      for (let i = 0; i < subtotals.length; i++) {
        const incPercent = includeTaxPercent?.[i] ?? 0;
        const extPercent = excludeTaxPercent?.[i] ?? 0;
        let productTaxAmt = 0;

        const adjustedAmount = Math.round(subtotals[i] / ((100 + incPercent) / 100));
        const incTaxAmount = subtotals[i] - adjustedAmount;

        if (incTaxAmount) {
          let curAmount = incTaxAmount;
          let curPercent = incPercent;
          productTaxAmt += incTaxAmount;
          for (let [idx, rule, result] of taxRules) {
            const percent = rule.includeTaxPercent?.[i];
            if (!percent) continue;
            // const curValue = Math.floor((curAmount * percent) / curPercent);
            // curAmount -= curValue;
            // curPercent -= percent;
            result.productAmountInt += adjustedAmount;
            // result.totalInt += curValue;
          }

          // if (curAmount) {
          //   throw new Error("Rounding error: " + curAmount);
          // }
        }

        const extTaxAmount = Math.round((adjustedAmount * extPercent) / 100);

        if (extTaxAmount) {
          let curAmount = extTaxAmount;
          let curPercent = extPercent;

          productTaxAmt += extTaxAmount;
          for (let [idx, rule, result] of taxRules) {
            const percent = rule.excludeTaxPercent?.[i];
            if (!percent) continue;
            // const curValue = Math.floor((curAmount * percent) / curPercent);
            // curAmount -= curValue;
            // curPercent -= percent;
            result.productAmountInt += adjustedAmount;
            // result.totalInt += curValue;
          }

          // if (curAmount) {
          //   throw new Error("Rounding error: " + curAmount);
          // }
        }

        productTaxes[i] = productTaxAmt;
      }

      for(let tax of taxRules) {
        const roundedSum = roundValue(tax[2].productAmountInt, this.order.subtotalRoundingFactorInt, this.order.subtotalRoundMode);
        
        tax[2].totalInt = roundValue(roundedSum * this.discounts[tax[0]].discount.taxPercent / 100, this.order.subtotalRoundingFactorInt, this.order.subtotalRoundMode);
        // productTax = adjustedAmount / tax[2].productAmountInt * tax[2].totalInt


        tax[2].productAmountInt = roundedSum;
        console.log('tax[2].productAmountInt',tax[2].productAmountInt)
        console.log('tax[2].totalInt',tax[2].totalInt)

      }

      taxes = taxRules.map(it => it[2]);
    }

    const discounts = discountApplied.map((it, idx) => {
      if (it.errors) return null;
      const d = this.discounts[idx];
      if (d.discount.type === "tax") return null;
      return {
        type: "discount",
        discount: getID(d.discount) as any,
        name: d.discount.name,
        totalInt: -_.sum(it.productDiscounts),
        rewardPoints: (it.pointsReward || [])
          .map((num, pointIdx) =>
            num
              ? {
                  point: this.pointKeys[pointIdx] as any,
                  value: num,
                }
              : null,
          )
          .filter(it => !!it),
        usePoints: (it.pointsUsed || [])
          .map((num, pointIdx) =>
            num
              ? {
                  point: this.pointKeys[pointIdx] as any,
                  value: num,
                }
              : null,
          )
          .filter(it => !!it),
        gifts: (it.frees || [])
          .map((num, giftIdx) =>
            num
              ? {
                  sku: this.freeKeys[giftIdx] as any,
                  quantity: num,
                }
              : null,
          )
          .filter(it => !!it),

        usePointValue: it.pointToUse ?? 0,
        useCouponCode: d.useCouponCode ?? null,

        relatedProduct: _.flatten(this.discounts[idx]?.productIndices),
      } as Partial<OrderDiscountType> as any as OrderDiscountType;
    });

    const globalItems = new Set(discounts);

    const productTax = Object.fromEntries(
      this.order.realProducts.map((p, idx) => {
        // const taxObject = productTaxes.map((d, didx) => {
        //   return d
        // })
        return [p.id, productTaxes[idx] ?? 0];
      })
    )

    const lineResults = Object.fromEntries(
      this.order.realProducts.map((p, idx) => {
        // console.log('this.order.realProducts', this.order.realProducts)
        const result = discountApplied.map((d, didx) => {
          if (!discounts[didx]) return null;
          if (!(d.productQty?.[idx] ?? 0)) {
            return null;
          }

          globalItems.delete(discounts[didx]);

          return {
            // is last matching product
            isLast: !d.productQty.slice(idx + 1).find(q => !!q),
            // is the only matching product
            isOnly: !d.productQty.find((it, jdx) => jdx !== idx && it > 0),
            discount: discounts[didx],
            appliedAmountInt: -(d.productDiscounts?.[idx] ?? 0),
          } as ProductDiscountInfo;
        });

        return [p.id, result.filter(it => !!it)];
      }),
    );


    return {
      rawResult: discountApplied,
      results: discountApplied.map((it, idx) => ({
        discount: this.discounts[idx].discount,
        errors: it.errors,
        products: this.discounts[idx].productIndices,
      })),
      discounts: discounts.filter(it => !!it),
      lineResults,
      globalItems: Array.from(globalItems),
      taxes,
      productTax: productTax
    };
  }
}

function sumArray(a: number[], b: number[]): number[] {
  if (!b) return a;
  if (!a) return b.slice();
  for (let i = 0; i < a.length; i++) {
    a[i] += b[i];
  }
  return a;
}

export interface ProductDiscountInfo {
  isLast: boolean;
  isOnly: boolean;
  discount: OrderDiscountType;
  appliedAmountInt: number;
}

export class DiscountState {
  productQty: number[][];
  productSubtotal: number[][];
  pointsRemain: number[];
  hasUser: boolean;
}

export class DiscountApply {
  productQty?: number[];
  productDiscounts?: number[];
  pointsUsed?: number[];
  pointsReward?: number[];
  frees?: number[];
  errors?: DiscountResultError[];
  pointToUse?: number;
  includeTaxPercent?: number[];
  excludeTaxPercent?: number[];
}
