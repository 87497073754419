
import { Component, Vue, Prop } from "@feathers-client";
import { PageBlock } from "../def";
import { SiteEditorComponentVariant, SiteEditorManager } from "..";
import EditorTranslateBox from "@schemaEditor/EditorTranslateBox.vue";
import EditorCheckbox from "@schemaEditor/EditorCheckbox.vue";
import EditorList from "@schemaEditor/EditorList.vue";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import EditorObjectPickerNew from "@schemaEditor/EditorObjectPickerNew.vue";
import { LangArrType } from "@feathers-client/i18n";
// @ts-ignore
import RarrowSvg from "!vue-svg-loader!../assets/rarrow.svg";
import ColorPicker from "../ColorPicker.vue";
import uuid from "uuid/v4";

@Component({
  components: {
    EditorTranslateBox,
    EditorCheckbox,
    EditorList,
    EditorTextField,
    EditorObjectPickerNew,
    RarrowSvg,
    ColorPicker,
  },
})
export default class SiteEditorProgressBar extends Vue {
  @Prop()
  block: PageBlock;

  @Prop()
  manager: SiteEditorManager;

  @Prop()
  variant: SiteEditorComponentVariant;

  showItemPerRowDropdown = false;

  defaultContactField = ["firstName", "lastName", "email"];

  getItem() {
    return {
      id: uuid(),
      field: null,
    };
  }

  get props() {
    return this.manager.defineProps(this.block, {
      title: [] as LangArrType,
      formName: "",
      uniqueEmail: false,
      titleDisplayMode: "left",
      remarks: [] as LangArrType,
      fontColor: "#000000FF",
      fontRemarksColor: "#000000FF",
      contactFields: [],
    });
  }

  get alignTypes() {
    return ["left", "center", "right"];
  }

  get contactFieldList() {
    return [
      {
        _id: "firstName",
        name: {
          $t: this.manager.$t("componentGroups.contactForm.firstName"),
        },
      },

      {
        _id: "lastName",
        name: {
          $t: this.manager.$t("componentGroups.contactForm.lastName"),
        },
      },
      {
        _id: "email",
        name: {
          $t: this.manager.$t("componentGroups.contactForm.email"),
        },
      },
    ];
  }
}
