import { render, staticRenderFns } from "./OrderManager.vue?vue&type=template&id=22eb43da&scoped=true"
import script from "./OrderManager.vue?vue&type=script&lang=ts"
export * from "./OrderManager.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22eb43da",
  null
  
)

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VBtn,VIcon,VImg})


/* hot reload */
if (module.hot) {
  var api = require("/home/jason/p/boxs/server/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('22eb43da')) {
      api.createRecord('22eb43da', component.options)
    } else {
      api.reload('22eb43da', component.options)
    }
    module.hot.accept("./OrderManager.vue?vue&type=template&id=22eb43da&scoped=true", function () {
      api.rerender('22eb43da', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/OrderManager.vue"
export default component.exports