export class CacheStorage {
  constructor(public dbName = "cacheStorage", public dbStoreName = "cacheStorage") {
    this.inited = this.init();
    this.inited.catch(console.error);
  }

  inited: Promise<void>;
  db: IDBDatabase;

  async init() {
    if (process.browser) {
      try {
        const self = this;
        this.db = await new Promise<IDBDatabase>((resolve, reject) => {
          var req = indexedDB.open(this.dbName, 1);
          req.onsuccess = function (evt) {
            resolve(this.result);
          };
          req.onerror = function (evt) {
            reject(this.error);
          };
          req.onblocked = function (evt) {
            reject(new Error("Database already open."));
          };
          req.onupgradeneeded = function (evt) {
            const db = this.result;
            if (!db.objectStoreNames.contains(self.dbStoreName)) {
              db.createObjectStore(self.dbStoreName);
            }
          };
        });
      } catch (e) { }
    }
  }

  async loadFile(
    url: string,
    update?: (buf: Buffer, date?: number) => Promise<void>,
    fetcher?: (url: string) => Promise<any>,
  ): Promise<Buffer> {
    await this.inited;
    let _resolve, _reject;
    const task = new Promise<Buffer>((resolve, reject) => {
      _resolve = resolve;
      _reject = reject;
    });
    let ongoing = process.browser ? true : false;
    if (process.browser) {
      (async () => {
        if (this.db) {
          try {
            let currentKey = await new Promise<any>((resolve, reject) => {
              var transaction = this.db.transaction(this.dbName, "readonly");
              var objectStore = transaction.objectStore(this.dbName);
              var request = objectStore.get(url);
              request.onerror = function (evt) {
                reject(this.error);
              };
              request.onsuccess = function (evt) {
                resolve(this.result);
              };
            });

            if (currentKey) {
              const buf = Buffer.from(currentKey.blob);
              if (_resolve) {
                _resolve(buf);
                await update?.(buf, currentKey.date);
              }
              _resolve = _reject = null;
            } else if(fetcher === null) {
              _reject?.(new Error("Not found"));
            }
          } catch (e) {
            console.warn(e);
            if (!ongoing) {
              _reject?.(e);
            }
          }
          ongoing = false;
        }
      })();
    }

    if (fetcher === null) {
      ongoing = false;
    } else {
      (async () => {
        try {
          let buf: Buffer;
          if (fetcher) {
            const r = await fetcher(url);
            if (r instanceof ArrayBuffer || r instanceof Array || typeof r === "string") {
              buf = Buffer.from(r as any);
            } else {
              buf = Buffer.from(JSON.stringify(r));
            }
          } else {
            const r = await fetch(url);
            if (!r.ok) {
              throw new Error("Invalid status: " + r.statusText);
            }
            Buffer.from(await r.arrayBuffer());
          }

          if (_resolve) {
            _resolve(buf);
          }

          const date = Date.now();
          if (process.browser && this.db) {
            (async () => {
              try {
                await new Promise<any>((resolve, reject) => {
                  var savedObject = {
                    id: url,
                    blob: buf,
                    date,
                  };
                  var transaction = this.db.transaction(this.dbName, "readwrite");
                  transaction.onerror = function (evt) {
                    reject(this.error);
                  };
                  transaction.onabort = function (evt) {
                    reject(this.error);
                  };
                  transaction.oncomplete = function (evt) {
                    resolve(savedObject);
                  };

                  var objectStore = transaction.objectStore(this.dbStoreName);
                  var request = objectStore.put(savedObject, url);
                });
              } catch (e) {
                console.warn(e);
              }
            })();
          }
          await update?.(buf, date);
        } catch (e) {
          console.warn(e);
          if (!ongoing) {
            _reject?.(e);
          }
        }
        ongoing = false;
      })();
    }

    return task;
  }

  async loadFileCache(url: string): Promise<CacheFile> {
    await this.inited;
    let currentKey = await new Promise<any>((resolve, reject) => {
      var transaction = this.db.transaction(this.dbName, "readonly");
      var objectStore = transaction.objectStore(this.dbName);
      var request = objectStore.get(url);
      request.onerror = function (evt) {
        reject(this.error);
      };
      request.onsuccess = function (evt) {
        resolve(this.result);
      };
    });
    if(!currentKey) {
      return null;
    }
    const buf = Buffer.from(currentKey.blob);
    return {
      data: buf,
      id: url,
      date: currentKey.date as number,
      metadata: currentKey,
    }
  }

  async saveFile(url: string, buf: Buffer, metadata: any = {}) {
    await this.inited;
    var savedObject = {
      id: url,
      blob: buf,
      date: Date.now(),
      ...metadata,
    };
    if (process.browser && this.db) {
      (async () => {
        try {
          await new Promise<any>((resolve, reject) => {
            var transaction = this.db.transaction(this.dbName, "readwrite");
            transaction.onerror = function (evt) {
              reject(this.error);
            };
            transaction.onabort = function (evt) {
              reject(this.error);
            };
            transaction.oncomplete = function (evt) {
              resolve(savedObject);
            };

            var objectStore = transaction.objectStore(this.dbStoreName);
            var request = objectStore.put(savedObject, url);
          });
        } catch (e) {
          console.warn(e);
        }
      })();
    }
    return savedObject as {
      data: Buffer;
      id: string;
      date: number;
      metadata: any;
    };
  }

  async listFiles(start?: string, end?: string) {
    await this.inited;
    return await new Promise<string[]>((resolve, reject) => {
      let request: IDBRequest<IDBValidKey[]>;
      var transaction = this.db.transaction(this.dbName, "readonly");
      transaction.onerror = function (evt) {
        reject(this.error);
      };
      transaction.onabort = function (evt) {
        reject(this.error);
      };
      transaction.oncomplete = function (evt) {
        resolve(request.result as any[]);
      };

      var objectStore = transaction.objectStore(this.dbStoreName);
      if(start && end) {
        request = objectStore.getAllKeys(IDBKeyRange.bound(start, end));
      } else if(start) {
        request = objectStore.getAllKeys(IDBKeyRange.lowerBound(start));
      } else if(end) {
        request = objectStore.getAllKeys(IDBKeyRange.upperBound(end));
      } else {
        request = objectStore.getAllKeys();
      }
    });
  }

  async deleteFile(id: string) {
    await this.inited;
    await new Promise<void>((resolve, reject) => {
      var transaction = this.db.transaction(this.dbName, "readwrite");
      transaction.onerror = function (evt) {
        reject(this.error);
      };
      transaction.onabort = function (evt) {
        reject(this.error);
      };
      transaction.oncomplete = function (evt) {
        resolve();
      };

      var objectStore = transaction.objectStore(this.dbStoreName);
      var request = objectStore.delete(id);
    });
  }

  async cleanFiles() {
    await this.inited;
    await new Promise<void>((resolve, reject) => {
      var transaction = this.db.transaction(this.dbName, "readwrite");
      transaction.onerror = function (evt) {
        reject(this.error);
      };
      transaction.onabort = function (evt) {
        reject(this.error);
      };
      transaction.oncomplete = function (evt) {
        resolve();
      };

      var objectStore = transaction.objectStore(this.dbStoreName);
      var request = objectStore.clear();
    });
  }
}

export interface CacheFile {
  data: Buffer;
  id: string;
  date: number;
  metadata: any;
}

const storages: Record<string, CacheStorage> = {};
export function getStorage(name: string = "cacheStorage") {
  let storage = storages[name];
  if (!storage) {
    storages[name] = storage = new CacheStorage(name, name);
  }
  return storage;
}
