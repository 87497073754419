
import { Component, Vue, Prop } from "@feathers-client";
import { LangArrType } from "@feathers-client/i18n";
import { defineComponent } from "site-editor/def";
import { SiteEditorProgressBarItem } from "../../editorComponents/progressBar.vue";

export const componentInfo = defineComponent({
  group: "progressBar",
  groupProps: {
    editorCols: 2,
  },
  variants: [
    {
      key: "progressBar",
    },
  ],
});

@Component
export default class SiteEditorFaq extends Vue {
  @Prop()
  variant: string;

  @Prop()
  title: LangArrType;

  @Prop()
  remarks: LangArrType;

  @Prop()
  items: SiteEditorProgressBarItem[];

  @Prop()
  titleDisplayMode: "left" | "center" | "right";

  @Prop()
  fontColor: string;

  @Prop()
  progressColor: string;

  @Prop()
  titleColor: string;

  @Prop()
  contentColor: string;

  mounted() {
    let options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.3,
    };

    let observer = new IntersectionObserver(this.callbackFunc, options);

    for (let i = 0; i < this.items.length; ++i) {
      observer.observe(document.getElementById(`progressBar${i}`));
    }
  }

  callbackFunc(entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const idx = parseInt(entry.target.id.slice(-1));
        entry.target.style.width = this.items[idx].progress + "%";
      }
    });
  }

  get bgProgressColor() {
    if (this.progressColor.length === 7) {
      return this.progressColor + "52";
    } else {
      return this.progressColor.slice(0, 7) + "52";
    }
  }
}
