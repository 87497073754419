var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"w-full whitespace-nowrap",style:({ '--item-height': '100%' })},[_c('div',{staticClass:"items-center btn-container <md:flex-wrap gap-y-5 light grid h-full grid-rows-1",class:{ light: _vm.light },attrs:{"tag":"div"}},_vm._l((_vm.linkTypes),function(item,idx){return _c('div',{key:item._id || idx,staticClass:"item-container h-$item-height inline-block",class:[{ selected: _vm.isSelected(item), readonly: _vm.readonly && item._id === 'external' }],on:{"click":function($event){return _vm.toggleSelected(item)}}},[(!(item._id === 'external' && _vm.readonly))?[_c('div',{staticClass:"list-item !h-10 !bg-inherit",attrs:{"role":"button"}},[_c('radio-icon',{staticClass:"mr-2",attrs:{"value":_vm.isSelected(item)},nativeOn:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.toggleSelected(item, true)}}}),_vm._v("\n          "+_vm._s(_vm.$td(item.name))+"\n        ")],1),_vm._v(" "),(item._id === 'internal' && !_vm.readonly)?_c('div',{staticClass:"d-flex flex-col gap-y-2"},[_c('editor-object-picker-list',{class:{ 'w-50': _vm.crop },attrs:{"outlined":"","items":[
              { name: _vm.manager.$t('urlPicker.main'), _id: 'main' },
              { name: _vm.manager.$t('urlPicker.categories'), _id: 'categories' },
              { name: _vm.manager.$t('urlPicker.brands'), _id: 'brands' },
              { name: _vm.manager.$t('urlPicker.tags'), _id: 'tags' },
              { name: _vm.manager.$t('urlPicker.product/groups'), _id: 'product/groups' },
              { name: _vm.manager.$t('urlPicker.articlePages'), _id: 'articlePages' },
              { name: _vm.manager.$t('urlPicker.others'), _id: 'others' },
            ],"clearable":"","required":""},model:{value:(_vm.internalLinkType),callback:function ($$v) {_vm.internalLinkType=$$v},expression:"internalLinkType"}}),_vm._v(" "),(item._id === 'internal' && _vm.internalLinkType === 'others')?_c('editor-text-field',{class:{ 'w-50': _vm.crop },model:{value:(_vm.internalLink),callback:function ($$v) {_vm.internalLink=$$v},expression:"internalLink"}}):(_vm.internalLinkType && _vm.internalLinkType !== 'main')?_c('editor-object-picker-list',{class:{ 'w-50': _vm.crop },attrs:{"label":_vm.manager.$t('urlPicker.type', { type: _vm.manager.$t(`urlPicker.${_vm.internalLinkType}`) }),"outlined":"","path":`shop/${_vm.internalLinkType}`,"name":[
              {
                field: ['name'],
                translate: true,
              },
            ],"query":_vm.query,"translate":"","clearable":"","required":""},on:{"update:cachedValue":_vm.setProductUrl},model:{value:(_vm.selectedId),callback:function ($$v) {_vm.selectedId=$$v},expression:"selectedId"}}):_vm._e()],1):(item._id === 'internal')?_c('div',{staticClass:"text-secondary pl-6",class:{ 'w-50': _vm.crop }},[_vm._v("\n          "+_vm._s(_vm.readonlyText)+"\n        ")]):_vm._e(),_vm._v(" "),(item._id === 'external')?_c('editor-text-field',{class:{ 'w-50': _vm.crop },model:{value:(_vm.externalLink),callback:function ($$v) {_vm.externalLink=$$v},expression:"externalLink"}}):_vm._e()]:_vm._e()],2)}),0)])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }