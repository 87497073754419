<template>
  <v-btn
    fab
    color="info"
    fixed
    right
    bottom
    @click="$vuetify.goTo(target, options)"
    id="backToTopBtn"

  >
    <v-icon>keyboard_arrow_up</v-icon>
  </v-btn>
</template>

<script>
import * as easings from "vuetify/es5/services/goto/easing-patterns";

export default {
  data() {
    return {
      type: "number",
      number: 0,
      selector: "#first",
      selected: "Button",
      elements: ["Button", "Radio group"],
      duration: 600,
      offset: 0,
      easing: "easeOutCubic",
      easings: Object.keys(easings),
    };
  },
  computed: {
    target() {
      const value = this[this.type];
      if (!isNaN(value)) return Number(value);
      else return value;
    },
    options() {
      return {
        duration: this.duration,
        offset: this.offset,
        easing: this.easing
      };
    },
    element() {
      if (this.selected === "Button") return this.$refs.button;
      else if (this.selected === "Radio group") return this.$refs.radio;
    }
  },
  
};
</script>

<style scoped>
#backToTopBtn {
  border-radius: 50%;
  transition: all 200ms ease-in;
}
</style>
