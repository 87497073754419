
import _ from "lodash";
import { Component, Vue, Prop, FindType, Watch, checkID } from "@feathers-client";
import { getStatusColor } from "~/plugins/pos/util";
import EditorDatePicker from "@schemaEditor/EditorDatePicker.vue";

type ShipmentType = FindType<"shop/shipping/shipments">;

@Component({
  components: {
    EditorDatePicker,
  },
})
export default class ShipmentScheduleCard extends Vue {
  @Prop()
  item: ShipmentType;

  changeDate = false;

  preferDeliveryTime: Date = null;

  fullAddress(address: ShipmentType["address"]) {
    return [address.company, address.address2, address.address].filter(it => !!it).join(",");
  }

  @Watch("item.preferDeliveryTime")
  updatePrefer() {
    this.preferDeliveryTime = this.item.preferDeliveryTime || null;
  }

  beforeMount() {
    this.updatePrefer();
  }

  countQuantity(item: ShipmentType) {
    return _.sumBy(item.products, p => p.quantity);
  }

  getOrders(item: ShipmentType) {
    return Array.from(new Set(item.products.map(it => it.order)));
  }

  getStatusColor = getStatusColor;

  async manage(hideStatus: boolean, orders) {
    const contents = orders?.filter(o=>o.customerComment).map(o=>({title:`#${o.orderId||o._id}`, content:o.customerComment}))
    try {
      const c = await this.$openDialog(
        import("~/components/boxs/ShipmentScheduleShipmentDialog.vue"),
        {
          item: this.item,
          hideStatus,
          contents,
        },
        {
          maxWidth: "80vw",
          contentClass: "h-100",
        },
      );
      if (!c) return;
    } catch (e) {
      console.warn(e);
      this.$store.commit("SET_ERROR", e.message);
    }
  }

  async savePrefer() {
    try {
      const resp = await this.$feathers.service("shop/shipping/shipments/update").create({
        id: this.item._id,
        shipment: {
          preferDeliveryTime: this.preferDeliveryTime,
        } as any,
      });
      Object.assign(this.item, resp);
      this.changeDate = false;
    } catch (e) {
      console.warn(e);
      this.$store.commit("SET_ERROR", e.message);
    }
  }

  async openInoviceLink(o: FindType<"shop/orders">) {
    let u: URL;
    if (this.$pos.cashier.invoiceTemplateType === "docx") {
      u = new URL(`${this.$config.apiUrl}/api/shop/orders/invoice`);
      u.searchParams.set("order", String(o._id));
      u.searchParams.set("token", await (this.$feathers as any).authentication.getAccessToken());
      u.searchParams.set("lang", this.$i18n.locale);
    } else {
      u = new URL(`/invoice/${o._id}?noprint=1`, location.toString());
    }
    try {
      await window.open(u);
    } catch (e) {
      console.warn(e);
    }
  }

  get remarks() {
    return this.item.remarks;
  }
}
