
import { Component, Vue, Prop } from "@feathers-client";
import Percentage from "./Percentage.vue";

@Component({
  components: {
    Percentage,
  },
})
export default class Overview extends Vue {
  @Prop() fontSize!: string
  @Prop() title!: string
  @Prop({ default: 0 }) latestAmount!: number
  @Prop({ default: 0 }) previousAmount!: number
  @Prop() type!: 'amount' | 'customer' | 'percentage' | 'amountInt'
  theme = false

  debug() {
    this.theme = !this.theme
    return this.$vuetify.theme.dark = this.theme
  }

  get formatting() {
    if (!this.latestAmount) { return 0 }
    let latestAmount: number | string = null
    if (this.type === 'amount') {
      latestAmount = `${this.$price(this.latestAmount, "preNum")}`
    }
    else if (this.type === "amountInt") {
      latestAmount = `${this.$price(this.latestAmount / 10000, 'pre')}`
    }
    else if (this.type === 'customer') {
      latestAmount = `${this.latestAmount}`
    }
    else if (this.type === 'percentage') {
      latestAmount = `${(this.latestAmount * 100).toFixed(3)}`
    }
    return latestAmount
  }
}
