

import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import { 
    DiscountMemberType,
} from "@common/common";

import DiscountAddonProduct from './DiscountAddonProduct.vue'
import DiscountAddonCategory from './DiscountAddonCategory.vue'

@Component({
    components: {
        DiscountAddonProduct,
        DiscountAddonCategory,
    },
})
export default class DiscountAddonMember extends Vue {

    @Prop()
    member : DiscountMemberType

    get products() {
        return this.member?.filter?.products || [];
    }

    get categories() {
        return this.member?.filter?.categories || [];
    }
}

