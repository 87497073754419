
import { Component, Prop, Vue, InjectReactive } from '@feathers-client';
import { PaymentType, LangArrType } from '@common/common';
import EditorTranslateBox from '@schemaEditor/EditorTranslateBox.vue';

@Component({
    components: {
        EditorTranslateBox,
    }
})
export default class PaymentInstructionEditor extends Vue {
    @Prop()
    label : string

    @Prop()
    value : LangArrType

    @InjectReactive('editorRoot')
    root : PaymentType

    get needInstruction() {
        return this.root?.type === 'bank';
    }

    get inputValue() {
        const v = this.value;
        return v || [];
    }

    set inputValue(v) {
        this.$emit('input', v);
    }
};
