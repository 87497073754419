
import { Component, Vue, Prop, Watch, FindType } from "@feathers-client";
import { LangArrType } from "@feathers-client/i18n";
import { defineComponent } from "site-editor/def";

export const componentInfo = defineComponent({
  group: "contactForm",
  groupProps: {
    editorCols: 2,
  },
  variants: [
    {
      key: "contactForm",
    },
  ],
});

@Component
export default class SiteEditorFaq extends Vue {
  @Prop()
  variant: string;

  @Prop()
  title: LangArrType;

  @Prop()
  remarks: LangArrType;

  @Prop()
  contactFields: { field: FindType<"shop/contactFields"> }[];

  @Prop()
  titleDisplayMode: "left" | "center" | "right";

  @Prop()
  fontColor: string;

  @Prop()
  fontRemarksColor: string;

  @Prop()
  formName: string;

  @Prop()
  uniqueEmail: boolean;

  firstName = "";
  lastName = "";
  email = "";
  fields = [];
  options = {};
  valid = false;

  async beforeMount() {
    const options = {};
    for (let field of this.contactFields) {
      options[field.field._id] = await this.$feathers
        .service("shop/contactField/values")
        .find({ query: { contactField: field.field._id, $paginate: false }, paginate: false });
    }

    this.options = options;
  }

  checkExist(val) {
    return !!val;
  }

  validEmail(email) {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  checkEmail(email) {
    return this.validEmail(email) || this.$t("format.email");
  }

  async submit() {
    try {
      const contactFields = [];
      for (let i = 0; i < this.contactFields.length; ++i) {
        if (!this.fields[i]) {
          contactFields.push({
            contactField: this.contactFields[i].field._id,
            contactFieldValue: [],
            value: null,
          });
        } else {
          switch (this.contactFields[i].field.type) {
            case "multiOption":
              contactFields.push({
                contactField: this.contactFields[i].field._id,
                contactFieldValue: this.fields[i],
                value: null,
              });
              break;
            case "option":
              contactFields.push({
                contactField: this.contactFields[i].field._id,
                contactFieldValue: [this.fields[i]],
                value: null,
              });
              break;
            case "boolean":
              contactFields.push({
                contactField: this.contactFields[i].field._id,
                contactFieldValue: [],
                value: this.fields[i] === "true",
              });
              break;
            case "string":
            case "number":
            case "mutliLineString":
              contactFields.push({
                contactField: this.contactFields[i].field._id,
                contactFieldValue: [],
                value: this.fields[i],
              });
              break;
          }
        }
      }
      await this.$feathers.service("shop/contactForm").create(
        {
          email: this.email,
          firstName: this.firstName,
          lastName: this.lastName,
          contactFields,
          tag: this.formName,
        },
        { query: { uniqueEmail: this.uniqueEmail } },
      );

      (this.$refs.contactForm as any).reset();
      this.$store.commit("SET_SNACKBAR", { message: this.$t("contactus.success") });
    } catch (err) {
      if (err?.data?.msg === "repeated-email") {
        this.$store.commit("SET_ERROR", this.$t("contactus.emailRepeated"));
      } else {
        this.$store.commit("SET_ERROR", err.message);
      }
    }
  }
}
