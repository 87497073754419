
import moment from "moment";
import _ from "lodash";
import { Component, Vue, Prop, FindType, Watch, checkID, VModel } from "@feathers-client";
import type { LangType } from "@feathers-client/i18n";
// @ts-ignore
import MenuListPicker, { SearchConfig } from "@feathers-client/components/MenuListPicker.vue";
import {
  getNameField,
  getNameFields,
  isTranslate,
  lookupType,
  convertFieldToSearch,
  isEnum,
} from "./plugin/utils";

@Component({
  components: {
    MenuListPicker,
  },
})
export default class EditorObjectPickerList extends Vue {
  @Prop()
  path: string;

  @Prop()
  staticPath: string;

  @Prop()
  label: string;

  @Prop()
  placeholder: string;

  @Prop()
  enterkeyhint: string;

  @VModel()
  inputValue: any;

  @Prop(Boolean)
  multiple: boolean;

  @Prop({ type: Boolean, default: false })
  required: boolean;

  @Prop(Boolean)
  readonly: boolean;

  @Prop(Boolean)
  returnObject: boolean;

  @Prop()
  name: SearchConfig[];

  @Prop()
  fields: SearchConfig[];

  @Prop({ type: Boolean, default: true })
  searchable: boolean;

  @Prop()
  args: any;

  @Prop()
  query: any;

  @Prop()
  sort: any;

  @Prop()
  items: any[];

  @Prop({ type: Boolean, default: false })
  preferSingle: boolean;

  @Prop(Boolean)
  resolve: boolean;

  @Prop()
  createNameField: string | SearchConfig;

  @Prop({ type: Boolean, default: true })
  border: boolean;

  @Prop(String)
  borderStyle: string;

  @Prop({ default: "border-gray-200" })
  inactiveClass: string;

  @Prop(String)
  activeClass: string;

  @Prop({ type: Boolean, default: false })
  nowrap: boolean;

  @Prop({ type: Boolean, default: true })
  showLabel: boolean;

  @Prop()
  hiddenLabel: string;

  @Prop(Boolean)
  ordered: boolean;

  mname: SearchConfig[] = null;
  mfields: SearchConfig[] = null;
  mlabel: LangType = null;
  mitems: any[] = null;

  get displayLabel() {
    return this.label ?? this.$td(this.mlabel);
  }

  beforeMount() {
    this.updateResolve();
  }

  @Watch("path")
  async updateResolve() {
    if (this.resolve && this.path) {
      await this.$schemas.init();
      const refRoute = this.$schemas.lookupRoute(this.path);
      const refTable = refRoute?.item;
      if (!refTable) return;
      const nameField = getNameField(refTable);
      const nameFields = getNameFields(refTable);

      const extraFields = (refTable.schema?.fields || []).filter(
        (it) => it.name !== nameField?.name && !nameFields.find((f) => f.name === it.name),
      );

      this.mfields = extraFields
        .filter((it) => (lookupType(it.type) === "string" && !isEnum(it)) || isTranslate(it.type))
        .map((it) => convertFieldToSearch(it));

      if (nameFields?.length) {
        this.mname = nameFields.map((it) => convertFieldToSearch(it));
      } else if (nameField) {
        this.mname = [convertFieldToSearch(nameField)];
      }

      this.mlabel = { $t: refRoute.name };
    }
  }

  @Watch("staticPath", { immediate: true })
  async onStaticPath() {
    if (!this.staticPath) {
      this.mitems = null;
      return;
    }
    try {
      this.mitems = (await this.$feathers.service(this.staticPath).find({
        query: this.query || {},
      })) as any as any[];
    } catch(e) {
      console.error(e);
    }
  }
}
