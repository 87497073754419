
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import { ImportFileSource, ImportFileData } from './base'
import JsZip from 'jszip'

@Component
export default class ImportArchiveSource extends mixins(ImportFileSource) {
    type = 'archive' as const
    fileList : FileList = null;

    get file() {
        return this.fileList?.[0];
    }
    
    @Watch('file')
    onFile() {
        this.reset();
    }

    zip : JsZip;

    async prepare() {
        if(!this.file) return false;
        this.zip = await JsZip.loadAsync(this.file);

        for(let path of Object.keys(this.zip.files)) {
            const file = this.zip.files[path];
            if(file.dir) continue;
            this.addFile(new ImportFileData({
                path,
                data: await file.async('blob'),
            }))
        }

        return true;
    }
}

