
import { Component, Vue, Prop } from "@feathers-client";
import { LangArrType } from "@feathers-client/i18n";
import { defineComponent } from "site-editor/def";

export const componentInfo = defineComponent({
  group: "map",
  groupProps: {
    editorCols: 2,
  },
  variants: [
    {
      key: "pinnedMap",
    },
  ],
});

@Component
export default class SiteEditorMap extends Vue {
  @Prop()
  variant: string;

  @Prop()
  title: LangArrType;

  @Prop()
  remarks: LangArrType;

  @Prop()
  location: string;

  @Prop()
  titleDisplayMode: "left" | "center" | "right";

  @Prop()
  fontColor: string;
}
