
import { checkID, Component, FindType, Prop, Vue, Watch } from "@feathers-client";

@Component
export default class AdPlayer extends Vue {
  currentAd: FindType<"shop/ads"> = null;

  currentAdResolve: () => void = null;
  currentAdCancellable = false;

  get banners() {
    return this.currentAd?.banners;
  }

  bannerIndex = 0;

  async playAd(ad: FindType<"shop/ads">, cancellable = false) {
    this.stopLoop();
    this.currentAd = ad;
    this.bannerIndex = 0;
    this.currentAdCancellable = cancellable;
    await new Promise<void>(resolve => {
      this.currentAdResolve = resolve;
    });
    this.currentAd = null;

    if (this.$pos.nextAd && !checkID(this.$pos.nextAd, ad)) {
      this.playAd(this.$pos.nextAd, true);
    }
  }

  @Watch("$pos.nextAd")
  onNextAdChanged() {
    debugger;
    if (!this.$pos.nextAd || checkID(this.$pos.nextAd, this.currentAd)) {
      return;
    }
    if (!this.currentAd || this.currentAdCancellable) {
      this.playAd(this.$pos.nextAd, true);
    }
  }

  currentPictureIdx = 0;

  picHandler = null;

  mounted() {
    this.onPictureChanged();
  }

  beforeDestroy() {
    this.stopLoop();
  }

  startLoop() {
    if (this.picHandler) {
      return;
    }
    this.picHandler = setTimeout(async () => {
      this.picHandler = null;
      await this.showNextPicture();
    }, 5000);
  }

  stopLoop() {
    if (this.picHandler) {
      clearTimeout(this.picHandler);
      this.picHandler = null;
    }
  }

  async showNextPicture() {
    this.currentPictureIdx = this.currentPictureIdx + 1;
    const banner = this.banners?.[this.currentPictureIdx];
    if (!banner) {
      this.stopLoop();
      if (this.currentAdResolve) {
        const r = this.currentAdResolve;
        this.currentAdResolve = null;
        r();
      }
      return;
    }
    this.startLoop();
    if (banner.type === "video") {
      // Reload and restart video
      await this.$nextTick();
      const video = document.querySelector(`#video-${this.currentPictureIdx}`) as HTMLVideoElement;
      if (video && video.src) {
        video.currentTime = 0;
        video.play();
      } else {
        this.showNextPicture();
      }
    }
  }

  @Watch("banners")
  onPictureChanged() {
    this.startLoop();
  }
}
