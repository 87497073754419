
import { Component, Prop, Watch, Vue } from "@feathers-client";

@Component
export default class StatusHost extends Vue {
  @Prop({ default: 5000 })
  timeout: number;

  errorTimeoutFunc: NodeJS.Timeout = null;

  successTimeoutFunc: NodeJS.Timeout = null;

  alertTimeoutFunc: NodeJS.Timeout = null;

  get error() {
    return !!this.$store.state.error;
  }
  set error(v) {
    if (!v) {
      this.$store.commit("SET_ERROR");
      clearTimeout(this.errorTimeoutFunc);
    }
  }
  get errorMessage() {
    return this.$store.state.error;
  }

  @Watch("error")
  errorTimeout() {
    if (this.error) {
      this.errorTimeoutFunc = setTimeout(() => {
        this.error = false;
      }, this.timeout);
    }
  }

  get success() {
    return !!this.$store.state.success;
  }
  set success(v) {
    if (!v) {
      this.$store.commit("SET_SUCCESS");
      clearTimeout(this.successTimeoutFunc);
    }
  }
  get successMessage() {
    return this.$store.state.success;
  }

  @Watch("success")
  successTimeout() {
    if (this.success) {
      this.successTimeoutFunc = setTimeout(() => {
        this.success = false;
      }, this.timeout);
    }
  }

  get alert() {
    return !!this.$store.state.alert;
  }
  set alert(v) {
    if (!v) {
      this.$store.commit("SET_ALERT");
      clearTimeout(this.alertTimeoutFunc);
    }
  }
  get alertMessage() {
    return this.$store.state.alert;
  }

  @Watch("alert")
  alertTimeout() {
    if (this.alert) {
      this.alertTimeoutFunc = setTimeout(() => {
        this.alert = false;
      }, this.timeout);
    }
  }

  get dark() {
    return this.$store.state?.dark;
  }
}
