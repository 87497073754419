
import { Component, Prop, VModel, Vue, Watch, Ref, FindType } from "@feathers-client";
import EditorObjectPickerList from "@schemaEditor/EditorObjectPickerList.vue";
import EditorTranslateBox from "@schemaEditor/EditorTranslateBox.vue";
import EditorTextField from "@schemaEditor/EditorTextField.vue";

@Component({
  components: {
    EditorObjectPickerList,
    EditorTextField,
    EditorTranslateBox,
  },
})
export default class SpecEditor extends Vue {
  styleOptions = {
    outlined: true,
    hideDetails: true,
    dense: true,
  };

  @Prop()
  item: {
    contactField: string;
    contactFieldValue?: string[];
    value?: any;
  };

  @Prop()
  contactField: FindType<"shop/contactFields">;

  get itemValue() {
    return this.item.value;
  }
  set itemValue(v) {
    if (this.contactField.type === "number") {
      v = Number(v);
      if (isNaN(v)) {
        return;
      }
    }
    Vue.set(this.item, "value", v);
  }
}
