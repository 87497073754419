import 'source-map-support/register'

import Vue from 'vue'
import Vue2Filters from 'vue2-filters'

// optional import of scroll behaviour
import DataTable, { options } from 'domore-table'
import VirtualList from 'domore-table/virtual'
import { Currencies, setScale, format, fromNumber } from '@feathers-client/currency'
options.secureExport = true;

setScale(100);

import _ from 'lodash'
Vue.use(DataTable);
Vue.use(VirtualList)
Vue.use(Vue2Filters)

Vue.filter('name', (ctx, value) => {
    return typeof value === 'string' ? value : value ? [value.title, value.lastName, value.firstName].filter(v => v).join(' ') : '';
})

Vue.filter('username', (ctx, value) => {
    if(!value) return ctx.$t('basic.no_user');
    if(typeof value === 'string') return value;
    return Vue.filter('name')(ctx, value.name) || value.email || value._id;
})

Vue.filter('findItem', (ctx, value, source) => {
    if(!value) return '';
    const list = _.get(ctx, source) || [];
    const cashier = _.find(list, it => it.id === value);
    return cashier && cashier.name || value;
})

Vue.filter('findItem2', (ctx, value, item, parent, header) => {
    if(!value) return '';
    const source = header.list;
    const list = _.get(ctx, source) || [];
    const cashier = _.find(list, it => it.id === value);
    return cashier && cashier.name || value;
})

Vue.filter('currency', function(ctx, price) {
    return ctx.$price(price);
});

Vue.prototype.$price = function(price, f : boolean | 'pre' | 'preNum' | 'noSign' = false) {
    if(!price && typeof price !== 'number') return '';
    if(typeof f === 'boolean') {
        return format(fromNumber(+price, this.$pos.currency ?? 'HKD'), undefined, f ? 'num' : 'short', true);
    }

    price = (+price).toFixed(2);
    const sign = price < 0 ? '-' : '';
    price = Math.abs(price);
    const num = Math.floor(price);
    const remain = ((price-num)*100).toFixed(0);
    const numStr = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const remainStr = _.padStart(remain.toString(), 2, '0');
    const priceStr = `${numStr}${f === 'preNum' ? '' : `.${remainStr}`}`;

    const currency = Currencies[this.$pos.currency]?.symbol ?? '$';
    return `${sign}${f === 'noSign' ? '' : currency + ' '}${priceStr}`;
}

Vue.filter('totalQuantity', function(ctx, items : { inventories: any[] }) {
    return _.sumBy(items.inventories, it => it.quantity);
})

Vue.filter('getSpec', function(ctx, value, item, parent, header) {
    return _.get(_.find(value, it => it.spec === header.spec), 'value') || '';
})

Vue.filter('orderId', function(ctx, value, item) {
    if(value && typeof value === 'object') {
        return value.orderId || value._id;
    } else if(typeof value === 'string') return value;
    return item.orderId || item._id;
})

Vue.filter('address', (ctx, value, item, parent, header) => {
    if (header.value === 'address') {
        if(value && typeof value === 'object') {
            return _.map(['address1', 'address2', 'city', 'country'], k => _.get(value, k, '')).filter(v => v).join(', ');
        } else if(typeof value === 'string') return value;
    } else {
        return _.map(['address1', 'address2', 'city', 'country'], k => _.get(item, k, '')).filter(v => v).join(', ');
    }
    return '';
})

if(!Object.getOwnPropertyDescriptor(Vue.prototype, '$shop')) {
    Object.defineProperty(Vue.prototype, '$shop', {
        enumerable: false,
        get() {
            return this.$store.state.shop;
        }
    });
}

if(!Object.getOwnPropertyDescriptor(Vue.prototype, '$qrPrefix')) {
    Object.defineProperty(Vue.prototype, '$qrPrefix', {
        enumerable: false,
        get() {
            return this.$config.qrPrefix || 'https://boxs.hk'
        }
    });
}

Vue.prototype.$checkAclWrite = function(item) {
    const shop = this.$shop && this.$shop._id;
    if(!shop || !item) return false;
    return item.shop === shop || item.accessShop && item.accessShop.find(it => it.shop === shop && it.write);
}

Vue.prototype.$checkAclRemove = function(item) {
    const shop = this.$shop && this.$shop._id;
    if(!shop || !item) return false;
    return item.shop === shop || item.accessShop && item.accessShop.find(it => it.shop === shop && it.owner);
}
