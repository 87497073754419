var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pos-popup rounded d-flex flex-col gap-y-4 p-4 h-full"},[_c('div',{staticClass:"text-secondary text-slg font-bold pa-5"},[_vm._v(" "+_vm._s(_vm.$t("pos.skuSelect.$"))+" ")]),_c('div',{staticClass:"flex-grow overflow-hidden basis-0"},[_c('div',{staticClass:"h-full overflow-y-auto d-flex flex-col gap-y-2"},[(_vm.group && _vm.group.skuSpecs)?_vm._l((_vm.group.skuSpecs),function(spec){return _c('div',{key:spec._id},[_c('div',{staticClass:"text-lg"},[_vm._v(" "+_vm._s(_vm.$td(spec.name))+" ")]),(spec.type === 'option' || spec.type === 'multiOption')?[_c('EditorObjectPickerNew',{attrs:{"path":"shop/spec/values","args":{ spec: spec._id }},model:{value:(_vm.specFilter[spec._id]),callback:function ($$v) {_vm.$set(_vm.specFilter, spec._id, $$v)},expression:"specFilter[spec._id]"}})]:(spec.type === 'boolean')?[_c('menu-list-check-box',{model:{value:(_vm.specFilter[spec._id]),callback:function ($$v) {_vm.$set(_vm.specFilter, spec._id, $$v)},expression:"specFilter[spec._id]"}})]:[_c('EditorObjectPickerNew',{attrs:{"items":_vm.getSpecValues(spec)},model:{value:(_vm.specFilter[spec._id]),callback:function ($$v) {_vm.$set(_vm.specFilter, spec._id, $$v)},expression:"specFilter[spec._id]"}})]],2)}):_vm._e(),_vm._l((_vm.filteredSkus),function(item){return _c('div',{key:item.item._id,staticClass:"grid grid-cols-[auto,minmax(0,2fr),repeat(2,minmax(0,1fr))] gap-x-2 items-center cursor-pointer",class:{
          'opacity-50':
            item.priceTable &&
            (item.priceTable.status === 'discontinued' ||
              item.priceTable.status === 'auto_soldOut' ||
              item.priceTable.cachedInventory === 0),
          'pointer-events-none': _vm.$pos.kioskMode
            ? item.priceTable &&
              (item.priceTable.status === 'discontinued' ||
                item.priceTable.status === 'auto_soldOut' ||
                item.priceTable.cachedInventory === 0)
            : false,
        },attrs:{"role":"button"},on:{"click":function($event){return _vm.modalResult(item)}}},[_c('img',{staticClass:"object-cover rounded-lg w-20 h-20",attrs:{"src":_vm.$thumb((item.images && item.images[0]) || (_vm.group && _vm.group.images && _vm.group.images[0]) || _vm.$shop.icon) ||
            require('~/assets/images/logo.png')}}),_c('div',[_vm._v(_vm._s(_vm.$td(item.name)))]),(!_vm.$pos.kioskMode)?_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.getInventory(item))+" ")]):_vm._e(),_c('div',{staticClass:"text-right",class:_vm.$pos.kioskMode ? 'col-span-2' : ''},[_vm._v(_vm._s(_vm.$price(item.generalPrice)))])])})],2)])])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }