
import Dialog from "@feathers-client/mixins/Dialog";
import { Component, mixins, Prop, FindType, getID } from "@feathers-client";
import MenuListNum from "@feathers-client/components/MenuListNum.vue";
import DataTableForErp from "~/components/erp/DataTableForErp.vue";
import { FindPopRawType } from "@feathers-client";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import EditorObjectPickerList from "@schemaEditor/EditorObjectPickerList.vue";
import { ObjectID } from "@db";
import { checkID } from "../../../common/util-core";

@Component({
  components: {
    MenuListNum,
    EditorTextField,
    EditorObjectPickerList,
    DataTableForErp,
  }
})
export default class ShipmentDialog extends mixins(Dialog) {

  async debug() {
    const res = await this.$feathers.service('shop/inventory/summaries').find({
      query: {
        shop: getID(this.$shop),
        warehouse: getID(this.productToBePrepared[0].warehouse),
        type: 'sku', // this.productToBePrepared[0].product.productId,
        sku: getID(this.productToBePrepared[0].product.productSku),
        $paginate: false,
      },
      paginate: false,
    });

    console.log('inventory', res)

    // const res = await this.$feathers.service('shop/erp/wholesaleOrders/prepareShipment').create({
    //   shop: this.$shop._id.toString(),
    //   order: this.order,
    //   updateList: this.productToBePrepared.map(el => {
    //     return {
    //       refID: el.product._id,
    //       sku: el.product.productId,
    //       warehouse: el.warehouse,
    //       quantity: el.product.quantity,
    //       shipmentStatus: 'preparing',
    //       allowOutOfStock: el.product.allowOutOfStock ?? false,
    //       handler: this.staff,
    //     }
    //   })
    // });
  }

  @Prop()
  order: FindType<'shop/erp/wholesaleOrders'>;

  @Prop()
  action: 'toPrepared' | 'toShipped'

  loading = false;

  get tableHeader() {
    return this.action === 'toPrepared' ?
      [
        this.$t("newSubsequentPaymentOrder.productName"),
        this.$t("orders.quantity"),
        this.$t("orders.price"),
        this.$t("pos.warehouse"),
        this.$t("warehouses.inventories"),
      ] :
      [
        this.$t("newSubsequentPaymentOrder.productName"),
        this.$t("orders.quantity"),
        this.$t("pos.warehouse"),
      ]
  }

  // to save products that is going to be changed to 'preparing' shipment status
  productToBePrepared: {
    product: FindType<'shop/erp/wholesaleOrders'>['products'][number],
    warehouse,
    toPrepared: boolean,
    showInventory: number,
  }[] = []

  productToBeShipped: {
    preparedProduct: FindType<'shop/erp/wholesaleOrders'>['productShipmentStatus'][number],
    productName,
    toShipped: boolean,
  }[] = []

  warehouseList: FindType<'shop/inventory/warehouses'>[] = []

  staff: string = null;

  remarks: string = null;

  async getSkuInventory(warehouse, item: typeof this.productToBePrepared[number]) {
    const res = await this.$feathers.service('shop/inventory/summaries').find({
      query: {
        shop: getID(this.$shop),
        warehouse: getID(warehouse),
        type: 'sku',
        sku: getID(item.product.productSku),
        $paginate: false,
      },
      paginate: false,
    });

    console.log(res)

    item.showInventory = res?.[0]?.amount || 0;
  }

  getWarehouseName(warehouseID: ObjectID<"ShopInventoryWarehouse">) {
    const warehouse = this.warehouseList.find(el => el._id === warehouseID)
    if (warehouse) {
      return this.$td(warehouse.name)
    }
    return ''
  }

  // get returnObject() {
  //   return {
  //     dealer: this.staff
  //   }
  // }

  async returnData() {
    if(this.loading) return;
    this.loading = true;
    if (this.staff === null) {
      this.$store.commit('SET_ERROR', `${this.$t('subsequentPaymentOrders.erp.pleaseSelect')} ${this.$t('subsequentPaymentOrders.erp.dealer')}` );
      this.loading = false;
      return;
    }
    let confirm;
    if (this.action === 'toPrepared') {
      const num = this.productToBePrepared.filter(el => el.toPrepared === true).length;
      if(num<1){
        this.$store.commit('SET_ERROR', 
        `${this.$t('subsequentPaymentOrders.erp.pleaseSelect')} ${this.$t('subsequentPaymentOrders.erp.prepareShip')} ${this.$t('subsequentPaymentOrders.erp.product')}` );
        this.loading = false;
        return;
      }
      confirm = await this.$openDialog(
        import("~/components/erp/dialogs/ErpConfirmDialog.vue"),
        {
          title: this.$t('subsequentPaymentOrders.erp.confirmFinish'),
          content: `${num} ${this.$t('subsequentPaymentOrders.erp.xProductToPreparing')}`,
        },
        {
          maxWidth: "400px"
        }
      );
    } else if (this.action === 'toShipped') {
      const num = this.productToBeShipped.filter(el => el.toShipped === true).length;
      if(num<1){
        this.$store.commit('SET_ERROR', 
        `${this.$t('subsequentPaymentOrders.erp.pleaseSelect')} ${this.$t('orders.shipments')} ${this.$t('subsequentPaymentOrders.erp.product')}` );
        this.loading = false;
        return;
      }
      confirm = await this.$openDialog(
        import("~/components/erp/dialogs/ErpConfirmDialog.vue"),
        {
          title: this.$t('subsequentPaymentOrders.erp.confirmFinish'),
          content: `${num} ${this.$t('subsequentPaymentOrders.erp.xProductToShipped')}`,
          moreContent: true,
          extraContent: this.$t('subsequentPaymentOrders.erp.thisMoveExportDN'),
        },
        {
          maxWidth: "400px"
        }
      );
    }
    if (!confirm) {this.loading = false;return;}
    let finish = false;
    if (this.action === 'toPrepared') {
      const res = await this.$feathers.service('shop/erp/wholesaleOrders/prepareShipment').create({
        shop: getID(this.$shop),
        order: this.order,
        updateList: this.productToBePrepared
          .filter(el => el.toPrepared === true)
          .map(el => {
            return {
              refProductID: el.product._id,
              sku: el.product.productSku,
              warehouse: el.warehouse,
              quantity: el.product.quantity,
              shipmentStatus: 'preparing',
              allowOutOfStock: el.product.allowOutOfStock ?? false,
              handler: this.staff,
            }
          })
      });
      if (res) finish = true;
      // console.log('pending', res)
    } else if (this.action === 'toShipped') {
      const res = await this.$feathers.service('shop/erp/wholesaleOrders/shipShipment').create({
        shop: getID(this.$shop),
        order: this.order,
        updateList: this.productToBeShipped
          .filter(el => el.toShipped === true)
          .map(el => {
            return {
              ...el.preparedProduct,
              handler: this.staff,
              remarks: this.remarks,
              toShip: true,
            }
          })
      });
      // TODO: create dn record
      const dnRecord = await this.$feathers.service('shop/erp/wholesaleOrders/createDeliveryRecord').create({
        shop: getID(this.$shop),
        orderID: this.order._id,
        productToShip: res,
      });

      if(dnRecord){
        this.loading = false;
        this.modalResult( {
          dnRecordID: dnRecord.newDeliveryRecord._id,
          ...dnRecord.newDeliveryRecord.deliveryNoteID ? {displayID: dnRecord.newDeliveryRecord.deliveryNoteID } : {},
        });
      } else {
        this.loading = false;
        this.$store.commit('SET_ERROR', 'dnRecord error');
      }

      //TODO: export dn
      // const dnData = await this.$feathers.service('shop/erp/wholesaleOrders/exportDeliveryNote').create({
      //   shop: this.$shop._id,
      //   orderID: this.order._id,
      //   dnRecord: dnRecord.newDeliveryRecord,
      // });

      // if (!dnData) {
      //   console.log('dn error');
      //   return;
      // }
      // const blob = new Blob([dnData['buffer']], { type: dnData['mime'] });
      // const url = window.URL.createObjectURL(blob);
      // const a = document.createElement('a');
      // document.body.appendChild(a);
      // a.style.display = 'none';
      // a.href = url;
      // a.download = `deliveryNote_${this.order._id}.pdf`;
      // // a.download = `${invoice._id}`;
      // a.click();
      // window.URL.revokeObjectURL(url);
      // a.remove();

      // console.log('shipped', res)
      // finish = true
    }

    if (!finish) {this.loading = false;return;}
    this.modalResult(true)
  }

  async beforeMount() {
    this.loading = true;
    const res = await this.$feathers.service('shop/inventory/warehouses').find({
      query: {
        shop: this.$shop._id,
        $paginate: false,
      },
      paginate: false,
    })
    // console.log('warehouses', res)
    this.warehouseList = res;
    this.loading = false;
  }

  async mounted() {
    if (this.order) {
      if (this.action === 'toPrepared') {
        // to find unshipped and non-custom product
        const preparedOrShippedProduct = this.order.productShipmentStatus
          .filter(el => el.shipmentStatus === 'preparing' || el.shipmentStatus === 'shipped')

        let nProduct = this.order.products.filter(el => el.productSku !== null)

        for (let product of nProduct) {
          if(preparedOrShippedProduct.find(el=>checkID(el.refProductID, product._id))) {
            
            continue
          }
          this.productToBePrepared.push({
            product: {
              ...product
            },
            warehouse: null,
            toPrepared: false,
            showInventory: 0
          });
        }
        console.log(this.productToBePrepared)
      } else if (this.action === 'toShipped') {
        for (let product of this.order.productShipmentStatus.filter(el => el.shipmentStatus === 'preparing')) {
          this.productToBeShipped.push({
            preparedProduct: { ...product },
            productName: this.order.products.find(el => checkID(el._id, product.refProductID))?.productName,
            toShipped: false,
          })
        }
      }
    }
  }


}
