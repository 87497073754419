
import { Component, Vue, Prop, Watch } from "@feathers-client";
@Component
export default class DropDownMenu extends Vue {
  @Prop()
  icon: string;

  @Prop()
  value: boolean;

  realMenu = false;

  @Watch("value", { immediate: true })
  onValue() {
    this.realMenu = this.value;
  }

  get menu() {
    return this.realMenu;
  }

  set menu(value: boolean) {
    this.realMenu = value;
    this.$emit("input", value);
  }
}
