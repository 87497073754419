
import { Component, Vue, Prop, PropSync, VModel, Watch, FindType, mixins } from "@feathers-client";
import Version from "~/mixins/version";

@Component
export default class LoginMenu extends mixins(Version) {
  @VModel() active: boolean

  langToggle: boolean = false;
  // get mvalue() {
  //   return this.active;
  // }
  // set mvalue(v) {
  //   this.$emit("close", v);
  // }
}
