
import { Component, Vue, Prop, Ref } from "@feathers-client";
import { PageBlock } from "../def";
import { SiteEditorComponentVariant, SiteEditorManager } from "..";
import EditorList from "@schemaEditor/EditorList.vue";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import EditorTranslateBox from "@schemaEditor/EditorTranslateBox.vue";
import EditorCheckbox from "@schemaEditor/EditorCheckbox.vue";
import EditorObjectPickerList from "@schemaEditor/EditorObjectPickerList.vue";
import ColorPicker from "../ColorPicker.vue";
import UrlPicker from "../UrlPicker.vue";
import uuid from "uuid/v4";
import CheckIcon from "@feathers-client/components-internal/CheckIcon.vue";
import _ from "lodash";
// @ts-ignore
import DeleteSvg from "!vue-svg-loader!../assets/delete.svg";
// @ts-ignore
import RarrowSvg from "!vue-svg-loader!../assets/rarrow.svg";
import {
  SiteEditorHeaderMenu,
  SiteEditorSubHeaderMenu,
  SiteEditorSubMenuItem,
  SiteEditorSubHeaderProduct,
} from "../components/header/index.vue";
import LangPicker from "../LangPicker.vue";

@Component({
  components: {
    EditorList,
    EditorTextField,
    EditorTranslateBox,
    EditorCheckbox,
    EditorObjectPickerList,
    LangPicker,
    ColorPicker,
    UrlPicker,
    CheckIcon,
    DeleteSvg,
    RarrowSvg,
  },
})
export default class SiteEditorHeader extends Vue {
  @Prop()
  block: PageBlock;

  @Prop()
  manager: SiteEditorManager;

  @Prop()
  variant: SiteEditorComponentVariant;

  // @Prop()
  // modalResult: (save: boolean) => void;

  @Ref()
  search: HTMLInputElement;

  @Ref()
  datalist: any;

  @Ref()
  router: any;

  @Ref()
  menuList: any;

  @Ref()
  subMenuList: any;

  @Ref()
  subMenuItemList: any;

  searchKeyword = "";
  onSearch = false;
  customQuery: any = {};

  editMenuContent = false;
  importLink = false;
  importItems = false;
  currentMenu: SiteEditorHeaderMenu = null;
  currentSubMenuItemIdx = null;
  currentImportItemList: SiteEditorHeaderMenu[] | SiteEditorSubMenuItem[] = null;
  productType = "";
  menuType: "menu" | "list" = null;
  menuInputType: "custom" | "auto" = "custom";

  selectedItems = [];
  haveError = false;
  errorMsg: any = "";

  get menuInputTypes() {
    return [
      {
        _id: "custom",
        name: {
          $t: this.manager.$t("componentGroups.header.custom", {
            name: this.manager.$t(`componentGroups.header.${this.menuType}`),
          }),
        },
        desc: this.manager.$t("componentGroups.header.customDesc"),
      },
      {
        _id: "auto",
        name: {
          $t: this.manager.$t("componentGroups.header.auto", {
            name: this.manager.$t(`componentGroups.header.${this.menuType}`),
          }),
        },
        desc: this.manager.$t("componentGroups.header.autoDesc"),
      },
    ];
  }

  get productTypes() {
    return [
      {
        _id: "categories",
        name: {
          $t: this.manager.$t("componentGroups.common.categories"),
        },
      },
      {
        _id: "tags",
        name: {
          $t: this.manager.$t("componentGroups.common.tags"),
        },
      },
      {
        _id: "brands",
        name: {
          $t: this.manager.$t("componentGroups.common.brands"),
        },
      },
    ];
  }

  getMenu() {
    return {
      _id: uuid(),
      title: [],
      haveLink: false,
      urlType: "internal",
      url: "",
      productName: "",
      haveProductImage: false,
      products: [],
      subMenus: [],
    } as SiteEditorHeaderMenu;
  }

  getProduct() {
    return {
      id: uuid(),
      file: null,
      urlType: "internal",
      url: null,
    } as SiteEditorSubHeaderProduct;
  }

  getSubMenu() {
    return {
      _id: uuid(),
      title: [],
      haveLink: false,
      urlType: "internal",
      url: "",
      items: [],
    } as SiteEditorSubHeaderMenu;
  }

  getItem() {
    return {
      _id: uuid(),
      title: [],
      urlType: "internal",
      url: "",
      productName: "",
    } as SiteEditorSubMenuItem;
  }

  tabEntryName(item, idx) {
    return this.$td(item.title) || `${this.manager.$t("componentGroups.header.itemList")} ${idx + 1}`;
  }

  goSearch() {
    this.onSearch = true;
    this.search?.blur?.();
    this.customQuery = {
      ...this.customQuery,
      $keyword: this.searchKeyword,
    };
  }

  goAddLink(type: "menu" | "list", list: any, idx?: number) {
    this.router.navigate("linkType");
    this.menuType = type;
    this.menuInputType = "custom";
    this.currentImportItemList = list;
    this.currentSubMenuItemIdx = idx;
  }

  addLink() {
    if (this.menuInputType === "auto") {
      this.router.navigate("importLink");
      this.importLink = true;
      this.productType = "";
    } else {
      this.router.navigate();
      this.menuType = null;
      this.currentImportItemList = null;
    }
  }

  cancelAddLink() {
    if (this.menuType === "menu") {
      this.menuList.removeItem(this.props.menus.length - 1);
    } else {
      this.subMenuItemList.removeItem(this.currentSubMenuItemIdx);
    }
    this.currentImportItemList = null;
    this.menuType = null;
    this.router.navigate();
  }

  get groupKey() {
    return this.variant.group.key;
  }

  get keyName() {
    return this.variant.key;
  }

  get props() {
    return this.manager.defineProps(this.block, {
      logo: null,
      bgColor: "#000000FF",
      fontColor: "#000000FF",
      transparent: false,
      menus: [] as SiteEditorHeaderMenu[],
    });
  }

  selectAll() {
    this.search?.select?.();
  }

  onSelect(value) {
    if (this.selectedItems.includes(value)) {
      this.selectedItems = this.selectedItems.filter(item => item._id !== value._id);
    } else {
      this.selectedItems.push(value);
    }
  }

  onDelete(value) {
    if (this.selectedItems.includes(value)) {
      this.selectedItems = this.selectedItems.filter(item => item._id !== value._id);
    }
  }

  displayItemPrice(value) {
    return this.$price(value.pricing[0]?.min?.price ?? 0, "pre");
  }

  exportSelectedItem() {
    const newItems = [];
    for (let item of this.selectedItems) {
      if (this.importLink) {
        newItems.push({
          _id: uuid(),
          title: item.name ? item.name : "",
          haveLink: true,
          urlType: "internal",
          url: `/shop?query=${JSON.stringify({
            currentFilter: {
              ...(this.productType === "categories"
                ? {
                    "categories.tree._id": { $in: item._id },
                  }
                : {}),
              ...(this.productType === "brands"
                ? {
                    "brand._id": { $in: item._id },
                  }
                : {}),
              ...(this.productType === "tags"
                ? {
                    "tags._id": { $in: item._id },
                  }
                : {}),
            },
          })}`,
          productName: item.name,
        });
      } else {
        newItems.push({
          id: uuid(),
          file: item.mainImage,
          title: item.name ? item.name : "",
          catergory: item.catergories?.[0]?.tree?.[0]?.name || "",
          slug: item.slug ? item.slug : "",
          urlType: "internal",
          url: `/product/${item.slug}?id=${item.productGroup}`,
          productGroup: item.productGroup,
        });
      }
    }

    if (this.importLink) {
      this.currentImportItemList.pop();
      this.currentImportItemList.push(...newItems);

      this.router.reset("editor");
      this.importLink = false;
      this.menuType = null;
      this.selectedItems = [];
      this.currentImportItemList = null;
    } else {
      if (this.currentMenu.products) {
        this.currentMenu.products.push(...newItems);
      } else {
        this.currentMenu.products = newItems;
      }

      this.router.reset("editor");
      this.importItems = false;
      this.selectedItems = [];
    }
  }

  showErrorMsg() {}

  onValidAndConfirm() {
    this.$emit("save");
  }
}
