
import { Component, Prop, Vue, Watch, mixins, Ref } from "nuxt-property-decorator";

@Component
export default class SwipeAction extends Vue {
  @Ref()
  right: HTMLElement;

  @Prop({default: "div"})
  tag: string;

  @Prop(Boolean)
  disabled: boolean;

  currentDelta: number;

  opened = false;

  onSwipeOpen() {
    if (!this.opened) return;
    const elem = this.$el as HTMLElement;
    elem.style.transition = "all 0.15s cubic-bezier(0.4, 0, 0.2, 1)";
    elem.style.transform = `translate(0,0)`;
    this.currentDelta = 0;
    this.opened = false;
  }

  mousedown(e: MouseEvent | TouchEvent) {
    if(this.disabled) return;
    if(this.opened) {
      let cur: HTMLElement = e.target as any;
      while(cur && cur !== e.currentTarget) {
        if(cur.classList.contains("swipe-right-container")) {
          return;
        }
        cur = cur.parentElement;
      }
    }
    const elem = this.$el as HTMLElement;
    const pointer = window.TouchEvent && e instanceof TouchEvent ? e.touches[0] : (e as MouseEvent);
    // e.stopPropagation();
    // e.preventDefault();
    const offsetX = pointer.clientX - (this.currentDelta || 0);
    const downX = pointer.clientX;
    const downY = pointer.clientY;
    const maxW = this.right.getBoundingClientRect().width;
    elem.style.transition = "";
    let moving = false;
    let moved = false;
    const mousemove = (e: MouseEvent | TouchEvent) => {
      const pointer = window.TouchEvent && e instanceof TouchEvent ? e.touches[0] : (e as MouseEvent);
      if (!moving && Math.abs(pointer.clientY - downY) > 5) {
        cancel();
        return;
      }
      if (!moving && Math.abs(pointer.clientX - downX) > 5) {
        moving = true;
        const evt =
          window.TouchEvent && e instanceof TouchEvent
            ? new TouchEvent("touchcancel", e as any)
            : new MouseEvent("mouseleave", e as any);
        e.target.dispatchEvent(evt);
      }
      if (!moving) return;
      const delta = Math.min(0, Math.max(-maxW, pointer.clientX - offsetX));
      if(Math.abs(pointer.clientX - offsetX) > 10) {
        moved = true;
      }
      if (e.cancelable) {
        e.preventDefault();
      }
      e.stopPropagation();
      this.currentDelta = delta;
      elem.style.transform = `translate(${delta}px, 0)`;
    };
    const mouseup = (e: MouseEvent | TouchEvent) => {
      cancel();
      if (!moving) {
        e.preventDefault();
        e.stopPropagation();
        this.$emit('click', e);
        return;
      }
      if (e.cancelable) {
        e.preventDefault();
      }
      e.stopPropagation();
      if(moved) {
        document.addEventListener("click", click, { capture: true });
        document.addEventListener("contextmenu", click, { capture: true });
        e.target.addEventListener("click", click);
      } else {
        this.$emit('click', e);
      }
      const progress = -this.currentDelta / maxW;
      elem.style.transition = "all 0.15s cubic-bezier(0.4, 0, 0.2, 1)";
      elem.style.transform = `translate(${progress > 0.5 ? -maxW + "px" : 0},0)`;
      const newOpened = progress > 0.5;
      if (newOpened !== this.opened) {
        this.opened = newOpened;
        if (this.opened) {
          this.$root.$emit("swipeOpen");
        }
      }
    };
    let cancel = () => {
      document.removeEventListener("mousemove", mousemove, { capture: true });
      document.removeEventListener("mouseup", mouseup, { capture: true });
      document.removeEventListener("touchmove", mousemove, { capture: true });
      document.removeEventListener("touchend", mouseup, { capture: true });
    };
    let click = () => {
      if (e.cancelable) {
        e.preventDefault();
      }
      e.stopImmediatePropagation();
      e.stopPropagation();
      document.removeEventListener("click", click, { capture: true });
      document.removeEventListener("contextmenu", click, { capture: true });
    };
    document.addEventListener("mousemove", mousemove, { capture: true });
    document.addEventListener("mouseup", mouseup, { capture: true });
    document.addEventListener("touchmove", mousemove, { capture: true, passive: false });
    document.addEventListener("touchend", mouseup, { capture: true });
  }
}
