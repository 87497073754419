
import { Component, Prop, Vue, Watch, mixins, Ref, VModel } from "nuxt-property-decorator";

@Component
export default class MenuListItem extends Vue {
    @Prop({ default: "w-4 h-4" })
    checkerClass: any

    @Prop()
    value: boolean

    changed = false;

    @Watch('value')
    toggleValue() {
        this.changed = true;
    }

    get color() {
        return this.value ? '#E58015' : '#333'
    }
}

