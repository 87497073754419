import { Component, Vue, getID } from "@feathers-client";
import _ from "lodash";
import { exportExcel, RowItem } from "domore-table/exportExcel";
import moment from "moment";

@Component
export class ShipmentScheduleExport extends Vue {
  async exportRawData(opts: any) {
    const rawOrders = await this.$feathers.service("shop/shipping/schedules/export").create(opts);

    const carName = opts.car
      ? this.$td((await this.$feathers.service("shop/shipping/cars").get(opts.car)).name)
      : "";

    const name = [opts.date, carName].filter((it) => !!it).join("-");

    const self = this;

    const headerRows: RowItem[] = [];
    if (opts.date) {
      headerRows.push({
        data: [self.$t("shipping.schedule.date") as string, opts.date],
      });
    }

    if (carName) {
      headerRows.push({
        data: [self.$t("shipping.schedule.car") as string, carName],
      });
    }

    await exportExcel(name, [
      {
        name: this.$t("orders.rawData.$") as string,
        ySplit: headerRows.length + 2,
        cols: {
          8: { hidden: true },
        },
        load: async function* () {
          yield {
            rows: [
              {
                data: [self.$t("orders.rawData.$") as string],
                row: {
                  font: { size: 16 },
                  alignment: {
                    vertical: "middle",
                    horizontal: "center",
                  },
                },
                merge: [[0, 8]],
              },
              ...headerRows,
              {
                data: [
                  self.$t("pages.shop/shipping/shipments.index") as string,
                  self.$t("pages.shop/shipping/shipments.orders") as string,
                  self.$t("basic.status") as string,
                  self.$t("pages.shop/shipping/shipments.preferDeliveryTime") as string,
                  self.$t("orders.rawData.productName") as string,
                  self.$t("orders.rawData.barcode") as string,
                  self.$t("orders.rawData.quantity") as string,
                  self.$t("orders.rawData.receiver.name") as string,
                  self.$t("orders.rawData.receiver.district") as string,
                  "ref",
                ],
                header: true,
              },
            ],
          };
          let index = 1
          for (let item of rawOrders.details) {
            const rows: RowItem[] = [];
            // order address
            rows.push({
              data: ['',
                self.$t("orders.rawData.receiver.name") as string,
                [item.address?.name?.lastName, item.address?.name?.firstName]
                  .filter((it) => !!it)
                  .join(" "),

              ]
            })
            rows.push({
              data: ['',
                self.$t("orders.rawData.receiver.phone") as string,
                item.address?.phone,
              ]
            })
            rows.push({
              data: ['',
                self.$t("orders.rawData.receiver.address") as string,
                item.address?.address,
                item.address?.address2,
                item.address?.city,
                item.address?.state,
                item.address?.district,
                item.address?.country,
              ]
            })
            for (let productItem of item.products) {
              let product = rawOrders.summary.find((it) => it._id === productItem.sku)?.product;

              const order = item.orders.find((it) => it._id === productItem.order);

              rows.push({
                data: [
                  index,
                  order?.orderId ?? order?._id ?? productItem.order,
                  item.status ? (self.$t("enum.basic.status." + item.status) as string) : "",
                  item.preferDeliveryTime ? moment(item.preferDeliveryTime).format("lll") : "-",
                  self.$td(product?.fullName ?? product?.name),
                  product?.slug,
                  productItem.quantity,
                  [item.address?.name?.lastName, item.address?.name?.firstName]
                    .filter((it) => !!it)
                    .join(" "),
                  item.address?.district,
                  item._id,
                ],
              });
              index++
            }
            rows[0].separator = true;
            yield {
              rows,
            };
          }
        },
      },
      {
        name: this.$t("orders.rawData.delivery") as string,
        ySplit: headerRows.length + 2,
        cols: {
          8: { hidden: true },
        },
        load: async function* () {
          yield {
            rows: [
              {
                data: [self.$t("orders.rawData.delivery") as string],
                row: {
                  font: { size: 16 },
                  alignment: {
                    vertical: "middle",
                    horizontal: "center",
                  },
                },
                merge: [[0, 8]],
              },
              ...headerRows,
              {
                data: [
                  self.$t("pages.shop/shipping/shipments.index") as string,
                  self.$t("pages.shop/shipping/shipments.orders") as string,
                  self.$t("basic.status") as string,
                  self.$t("pages.shop/shipping/shipments.preferDeliveryTime") as string,
                  self.$t("orders.rawData.productName") as string,
                  self.$t("shipping.schedule.label.num") as string,
                  self.$t("orders.rawData.receiver.name") as string,
                  self.$t("orders.rawData.receiver.district") as string,
                  self.$t("orders.rawData.receiver.phone") as string,
                  self.$t("orders.rawData.receiver.address") as string,
                  "ref",
                ],
                header: true,
              },
            ],
          };
          let index = 1
          for (let item of rawOrders.details) {
            yield {
              rows: [
                {
                  data: [
                    index,
                    item.orders.map((it) => it.orderId || it._id).join(","),
                    item.status ? (self.$t("enum.basic.status." + item.status) as string) : "",
                    item.preferDeliveryTime ? moment(item.preferDeliveryTime).format("lll") : "-",
                    '',//self.$td(item?.product?.fullName ?? item?.product?.name),
                    item.num,
                    [item.address?.name?.lastName, item.address?.name?.firstName]
                      .filter((it) => !!it)
                      .join(" "),
                    item.address?.district,
                    item.address?.phone,
                    [item.address?.address, item.address?.address2].filter(it => !!it).join(" "),
                    item._id,
                  ],
                },
              ],
            }; index++
          }
        },
      },
      {
        name: self.$t("orders.rawData.summary") as string,
        ySplit: headerRows.length + 2,
        cols: {
          3: { hidden: true },
        },
        maxColWidth: 80,
        load: async function* () {
          yield {
            rows: [
              {
                data: [self.$t("orders.rawData.summary") as string],
                row: {
                  font: { size: 16 },
                  alignment: {
                    vertical: "middle",
                    horizontal: "center",
                  },
                },
                merge: [[0, 4]],
              },
              ...headerRows,
              // total amount
              {
                data: [
                  self.$t("orders.rawData.orderTotal") as string,
                  rawOrders.summary.length
                ]
              },
              {
                data: [
                  self.$t("orders.rawData.productTotal") as string,
                  rawOrders.summary.reduce((a, b) => a + (b.quantity || 0), 0)
                ]
              },
              {
                data: [
                  self.$t("pages.shop/shipping/shipments.index") as string,
                  self.$t("orders.rawData.productName") as string,
                  self.$t("orders.rawData.barcode") as string,
                  self.$t("orders.rawData.quantity") as string,
                  "ref",
                ],
                header: true,
              },
            ],
          };
          let index = 1
          for (let item of rawOrders.summary) {
            yield {
              rows: [
                {
                  data: [
                    index,
                    self.$td(item?.product?.fullName ?? item?.product?.name ?? item._id),
                    item?.product?.slug,
                    item.quantity,
                    item._id,
                  ],
                },
              ],
            };
            index++
          }
        },
      },
    ]);
  }
}
