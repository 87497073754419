import { ns } from "pos-printer/messageQueue";
import { getVersion } from "pos-printer/nativeIntegrations";

export function supported(): Promise<boolean> {
  if (!getVersion()) return Promise.resolve(false);
  return Promise.race<boolean>([
    ns("http").call<boolean>("supported"),
    new Promise<boolean>(resolve => setTimeout(() => resolve(false), 15000)),
  ]);
}

let cachedSupportedValue: Promise<boolean> | undefined;
export function cachedSupported() {
  if (cachedSupportedValue) return cachedSupportedValue;
  return (cachedSupportedValue = supported());
}

export interface HttpRequestOptions {
  timeout?: number;
}

export function request(
  url: string,
  data?: Buffer | string | { [key: string]: string },
  contentType?: string,
  requestOptions?: HttpRequestOptions,
): Promise<{
  body: any;
  headers: {
    [key: string]: string;
  };
  statusCode: number;
}> {
  return ns("http").call("request", url, data, contentType, requestOptions);
}
