
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import { 
    DataTableDef, 
    DataTablePagination, 
    DataTablePaginated,
} from '../index'
import fieldDefs from '~/plugins/fieldDefs'
import _ from 'lodash'
import { MApplication } from "@feathersjs/feathers";


@Component
export default class QueryProvider extends Vue {

    @Prop()
    customSort : (item : any) => any;

    @Prop()
    feathers : any

    get mfeathers() : any {
        return this.feathers || this.$feathers;
    }

    convertQuery(
        data : DataTableDef,
        query : DataTablePagination,
        extraFilter? : any,
    ) {
        const service = data.path;

        const def = fieldDefs[data.path];
        let populate = (def && def.populate && _.map(def.populate, (v, k) => {
            if (typeof v === 'string') v = { path: v };
            const mdef = fieldDefs[v.path];
            return {
                path: k,
                select: v.select || (mdef && mdef.customView && mdef.customView.name) || (mdef && mdef.fields) || 'name',
            }
        })) || data.populate || [];
        const filterPopulate = _.get(extraFilter, '$populate');
        if (filterPopulate?.length) {
            populate = [...populate, ...filterPopulate];
            delete extraFilter['$populate'];
        }

        if (data.id) {
            return null;
        } else {
            if (data.invalid) {
                return null
            }
            const customFilter = { ...(query.currentFilter || {}) };
            if (customFilter.$and && !customFilter.$and.length) { delete customFilter.$and; }
            const sort = query.sortBy && query.sortBy.length ? _.fromPairs(_.map(query.sortBy, (sort, idx) => [
                sort, (query.sortDesc ? query.sortDesc[idx] ? -1 : 1 : 1)
            ])) : {};
            if(!sort._id) {
                sort._id = 1;
            }
            return {
                ...data.filter,
                ...customFilter,
                ...extraFilter,
                $limit: query.rowsPerPage === -1 ? (data.filter && data.filter.$limit || customFilter && customFilter.$limit || -1) : query.rowsPerPage,
                $skip: query.rowsPerPage !== false ? (query.page && (query.page - 1) * query.rowsPerPage) : 0,
                $sort: sort,
                ... (populate?.length ? {
                    $populate: populate,
                } : {}),
                $select: data.select || undefined,
                ...(query.cursor ? { $cursor: query.cursor } : {}),
            }
        }
    }

    async runQuery (
        data : DataTableDef,
        query : DataTablePagination,
        extraFilter? : any,
        // path, service, query, filter, select, mpopulate, id, invalid
    ) : Promise<DataTablePaginated> {
        const service = data.path && this.mfeathers.service(data.path);

        const def = fieldDefs[data.path];
        let populate = (def && def.populate && _.map(def.populate, (v, k) => {
            if (typeof v === 'string') v = { path: v };
            const mdef = fieldDefs[v.path];
            return {
                path: k,
                select: v.select || (mdef && mdef.customView && mdef.customView.name) || (mdef && mdef.fields) || 'name',
            }
        })) || data.populate || [];
        const filterPopulate = _.get(extraFilter, '$populate');
        if (filterPopulate?.length) {
            populate = [...populate, ...filterPopulate];
            delete extraFilter['$populate'];
        }

        if (data.id) {
            if (data.invalid) return null;
            return <any>service.get(data.id, <any>{
                query: {
                    ...data.filter,
                    ...extraFilter,
                    ... (populate?.length ? {
                        $populate: populate,
                    } : {}),
                    $select: data.select || undefined,
                }
            });
        } else {
            if (data.invalid) {
                return {
                    data: [],
                    total: 0,
                    limit: query.rowsPerPage === false ? 0 : query.rowsPerPage,
                    skip: 0,
                    sortBy: query.sortBy || [],
                    sortDesc: query.sortDesc || []
                }
            }
            const customFilter = { ...(query.currentFilter || {}) };
            if (customFilter.$and && !customFilter.$and.length) { delete customFilter.$and; }
            const sort = query.sortBy && query.sortBy.length ? _.fromPairs(_.map(query.sortBy, (sort, idx) => [
                sort, (query.sortDesc ? query.sortDesc[idx] ? -1 : 1 : 1)
            ])) : {};
            if(!sort._id) {
                sort._id = 1;
            }
            let result : any = await service.find({
                query: {
                    ...data.filter,
                    ...customFilter,
                    ...extraFilter,
                    $limit: query.rowsPerPage === -1 ? (data.filter && data.filter.$limit || customFilter && customFilter.$limit || -1) : query.rowsPerPage,
                    $skip: query.rowsPerPage !== false ? (query.page && (query.page - 1) * query.rowsPerPage) : 0,
                    $sort: sort,
                    ... (populate?.length ? {
                        $populate: populate,
                    } : {}),
                    $select: data.select || undefined,
                    ...(query.cursor ? { $cursor: query.cursor } : {}),
                }
            })
            if(!result.data) {
                result = {
                    data: result,
                    limit: result.length,
                    total: result.length,
                    skip: 0,
                }
            }
            if(this.customSort) {
                result.data = _.sortBy(result.data, this.customSort);
            }
            result.sortBy = query.sortBy || [];
            result.sortDesc = query.sortDesc || [];
            result.currentFilter = query.currentFilter || null;
            return result;
        }
    }
}

