
import { Component, Vue, Prop, getID } from "@feathers-client";
import { PageBlock } from "../def";
import { SiteEditorComponentVariant, SiteEditorManager } from "..";
import EditorObjectPickerList from "@schemaEditor/EditorObjectPickerList.vue";

@Component({
  components: {
    EditorObjectPickerList,
  },
})
export default class SiteEditorSocialMedia extends Vue {
  @Prop()
  block: PageBlock;

  @Prop()
  manager: SiteEditorManager;

  @Prop()
  variant: SiteEditorComponentVariant;

  fbConfig = null;

  async beforeMount() {
    this.fbConfig = (
      await this.$feathers.service("shop/options").find({
        query: {
          shop: getID(this.$shop),
          name: "fbConfig",
        },
      })
    )[0];
  }

  get pages() {
    return (
      this.fbConfig?.value?.pageInfos?.map(it => {
        return { ...it, _id: it.id };
      }) || []
    );
  }

  get groupKey() {
    return this.variant.group.key;
  }

  get keyName() {
    return this.variant.key;
  }

  get props() {
    return this.manager.defineProps(this.block, {
      fbPage: null,
    });
  }
}
