<template>
    <div :class="['picker', {dragging}]" :style="{
        width: mini ? '50px' :  '250px',
        height: mini ? '50px' : '150px',
    }"
        @dragenter="dragenter" 
        @dragover="dragover"
        @dragleave="dragleave"
        @drop="drop"
    >
        <media-library 
            :type="type"
            :parent="parent"
            :dir="dir"
            selecting
            v-model="mediaLibrary"
            @selected="addFiles"
        />

        <img @click="pickImage" :src="thumb" :style="{
            width: '100%',
            height: '100%',
            'object-position': 'center',
            'object-fit': 'contain'
        }"/>
        <div class="floating" v-if="minfo && !minfo.complete">
            <v-progress-circular :value="minfo.progress * 100" color="blue"></v-progress-circular>
        </div>
        <div class="floatingLabel" v-if="label||value">
            {{label}}
            <v-btn v-if="value" icon target="_blank" :href="$image(value)" small><v-icon>get_app</v-icon></v-btn> 
            <v-btn v-if="!readonly" icon small @click="addFile"><v-icon>add</v-icon></v-btn> 
            <v-btn v-if="!readonly" icon small @click="clearFile"><v-icon>clear</v-icon></v-btn> 
        </div>
    </div>
</template>

<script>

import uuid from 'uuid/v4'
import MediaLibrary from '~/components/MediaLibrary'

export default {
    components: {
        MediaLibrary
    },
    props: {
        'url': { type: String, default: '' },
        'parent': { type: String, default: '' },
        'dir': { type: String, default: '' },
        'type': { type: String, default: 'image/*' },
        value: {},
        mini: Boolean,
        defImage: String,
        attachment: Boolean,
        attachmentId: Boolean,
        label: String,
        name: String,
        readonly: Boolean,
    },

    data() {
        return {
            minfo: null,
            mediaLibrary: false,
            dragging: false,
        }
    },

    computed: {
        thumb() {
            if(this.value && this.value.thumb) {
                return `data:image/png;base64,${this.value.thumb}`;
            }
            if(this.attachmentId && this.value) return this.$thumb(this.value, true);
            return this.value && this.value.src || this.defImage || this.$config.appLogo || require('~/assets/images/logo.png');
        }
    },

    methods: {
        async addFile() {
            const file = document.createElement('input');
            file.style.display = 'none';
            file.type = 'file';
            file.accept = this.type;
            file.multiple = true;
            document.body.append(file);
            file.click();
            await new Promise((resolve) => file.onchange = resolve);
            if (file.files.length == 0) return;
            await Promise.all(_.map(file.files, img => this.uploadFile(img)));
        },
        clearFile() {
            this.$emit('input', null);
            this.minfo = null;
        },
        addFiles(files) {
            const f = files[0];
            this.mediaLibrary = false;
            if(this.attachmentId) {
                this.$emit('input', f._id);
            }
            else if(this.attachment) {
                this.$emit('input', f);
            } else {
                this.$emit('input', {
                    type: 'attachment',
                    attachment: f._id,
                    width: f.width,
                    height: f.height,
                    thumb: f.thumb,
                    src: f.src,
                });
            }
        },

        pickImage() {
            if(this.readonly) return;
            this.mediaLibrary = true;
        },

        async uploadFile(mfile) {
            var data = new FormData();
            data.append('file', mfile, mfile.name);

            const info = {
                name: mfile.name,
                size: mfile.size,
                mime: mfile.type,
                thumb: null,
                id: uuid(),
                success: false,
                complete: false,
                processing: true,
                error: null,
                progress: 0
            };
            this.minfo = info;

            try {
                const response = await this.$feathers.post(`attachments/upload`, data, {
                    onUploadProgress: (progressEvent) => {
                        info.progress = progressEvent.loaded / progressEvent.total;
                    }
                });

                const rinfo = (response.data || {}).info || {};
                _.assign(info, rinfo);
                info.success = true;
                info.complete = true;
                info.progress = 1;
                info.processing = false;
            } catch (e) {
                info.error = e.message;
                info.complete = true;
                info.processing = false;
            }

            if(this.attachmentId) {
                this.$emit('input', info._id);
            }
            else if(this.attachment) {
                this.$emit('input', info);
            } else {
                this.$emit('input', {
                    type: 'attachment',
                    attachment: info._id,
                    width: info.width,
                    height: info.height,
                    thumb: info.thumb,
                    src: info.src,
                });
            }
        },

        dragenter(e) {
            e.preventDefault();
            e.stopPropagation();
            if(e.dataTransfer.types.includes('Files')) {
                if(!this.dragging) {
                    this.dragging = true;
                    this.$emit('beginDrag');
                }
                e.dataTransfer.dropEffect = "copy"
                return;
            }
            if(this.dragging) {
                this.dragging = false;
                this.$emit('endDrag');
            }
            e.dataTransfer.dropEffect = "none"
        },
        dragover(e) {
            e.preventDefault();
            e.stopPropagation();
            if(e.dataTransfer.types.includes('Files')) {
                if(!this.dragging) {
                    this.dragging = true;
                    this.$emit('beginDrag');
                }
                e.dataTransfer.dropEffect = "copy"
                return;
            }
            if(this.dragging) {
                this.dragging = false;
                this.$emit('endDrag');
            }
            e.dataTransfer.dropEffect = "none"
        },
        async drop(e) {
            if(!this.dragging) return;
            e.preventDefault();
            e.stopPropagation();
            this.dragging = false;
            if(e.dataTransfer.types.includes('Files')) {
                const imgs = _.filter(e.dataTransfer.files, file => file.type.match('^image/'));
                if(imgs.length > 0) {
                    await this.uploadFile(imgs[0]);
                }
            }
            this.$emit('endDrag');
        },
        dragleave(e) {
            if(!this.dragging) return;
            e.preventDefault();
            e.stopPropagation();            
            this.dragging = false;
            this.$emit('endDrag');
        },
    }
}
</script>

<style scoped>
.picker {
    position: relative;
    cursor: pointer;
}
.picker.readonly {
    cursor: default;
}
.floating, .floatingLabel {
    position: absolute;
    top: 0;
    left: 0;
}

.floating {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.floatingLabel {
    margin: 10px;
    padding: 5px;
    background: rgba(255,255,255,0.8);
    border-radius: 5px;
    cursor: default;
}

.dragging {
    background: lightblue;
}
</style>
