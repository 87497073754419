
import { Component, Prop, Vue, Watch, mixins, Ref, VModel } from "nuxt-property-decorator";

@Component
export default class RadioIcon extends Vue {
  @Prop()
  text: string;

  @Prop({ default: "w-4 h-4" })
  checkerClass: any;

  @Prop()
  value: boolean;

  @Prop()
  selectedColor: string;
}
