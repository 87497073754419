
import Dialog from "@feathers-client/mixins/Dialog";
import { mixins } from "@feathers-client"
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import { Component } from "@feathers-client";

@Component({
  components: {
    EditorTextField,
  }
})
export default class KioskPasswordDialog extends mixins(Dialog) {

  password = "";

  confirm() {
    this.modalResult((this.$pos.cashier.kioskPassword || "0000") === this.password);
  }

};
