
import Dialog from "@feathers-client/mixins/Dialog";
import { Component, mixins, Prop, FindType } from "@feathers-client";
import MenuListButton from "@feathers-client/components/MenuListButton.vue";
import EditorObjectPickerList from "@schemaEditor/EditorObjectPickerList.vue";
import EditorTextField from "@schemaEditor/EditorTextField.vue";

@Component({
  components: {
    MenuListButton,
    EditorObjectPickerList,
    EditorTextField,
  }
})
export default class CancelConfirmDialog extends mixins(Dialog) {

  @Prop()
  title

  @Prop()
  docDetail

  handler = null;
  remarks = null;

  returnData(){
    if(this.handler==='' || !this.handler){
      this.$store.commit('SET_ERROR', this.$t('subsequentPaymentOrders.erp.pleaseSelectHandler'));
      return;
    }
    this.modalResult({
      handler: this.handler,
      remarks: this.remarks
    })
  }

}
