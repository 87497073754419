import { Component, Vue, mixins, FindType, Watch } from "@feathers-client";
import { checkID, getID, getOptions } from "@feathers-client/util";
import { OctopusManager } from "pos-printer/octopus";
import { BBMSLManager } from "pos-printer/bbmsl";
import { RazerPayConfig, RazerPayManager } from "pos-printer/razerpay";
import AllinpayPOS from "~/mixins/allinpay";
import { TurnCloudManager } from "pos-printer/turncloud";
import { CloudPayload, PaymentLike, PaymentMethodBase, PaymentOptions } from "pos-printer/payments/methodBase";


const methodContext = require.context("pos-printer/payments/methods", true, /\.ts$/, "lazy");
const methodDict = Object.fromEntries(
  methodContext.keys().map(key => [key.replace(/\.\/|\.ts/g, "").replace(/\/index$/, ""), key]),
);

@Component
export class PaymentManager extends Vue {
  initManager() {
    this.updateOctopus();
    this.updateBBMSL();
    this.updateRazer();
    this.updateTurnCloud();

    this.initPayments();
    this.scheduleSessionUpdate();
    return this;
  }

  methods: Record<string, PaymentMethodBase> = {};
  allMethods: Record<string, PaymentMethodBase> = {};

  @Watch("$pos.paymentMethods")
  async initPayments() {
    const methods = await Promise.all(
      this.$pos.paymentMethods.map(async method => [method.type, await this.initPayment(method)]),
    );
    this.methods = Object.fromEntries(methods.filter(it => it[1]));
  }

  async initPayment(method: FindType<"shop/payment/methods">) {
    const prev = this.allMethods[method.type];
    if (prev) {
      prev.updateMethod(method);
      return prev;
    } else if (methodDict[method.type]) {
      return await this._initPayment(method.type, method);
    }
  }

  private async _initPayment(type: string, method?: FindType<"shop/payment/methods">) {
    try {
      const methodClass = (await methodContext(methodDict[type]))?.default;
      if (this.allMethods[type]) {
        return this.allMethods[type];
      }
      const instance = new methodClass({
        parent: this,
        propsData: {
          initMethod: {
            ...method,
            props: method.options,
          },
          paymentManager: this,
          type: type,
        },
      });
      this.allMethods[type] = instance;
      return instance;
    } catch (e) {
      console.warn(`Failed to load payment method ${type}`, e);
    }
  }

  get methodConnectionCount() {
    return Object.keys(this.methods).length;
  }

  get methodSetupCount() {
    return Object.values(this.methods).reduce((a, b) => a + (b.hasConfig ? 1 : 0), 0);
  }

  get methodOnlineCount() {
    return Object.values(this.methods).reduce((a, b) => a + (b.connected ? 1 : 0), 0);
  }

  get methodErrorCount() {
    return Object.values(this.methods).reduce((a, b) => a + (b.error ? 1 : 0), 0);
  }

  getConfig(type: string) {
    return this.$pos.cashier.methodConfigs?.[type];
  }

  setConfig(type: string, config: any) {
    this.$pos.updateCurrentCashier({
      methodConfigs: {
        ...(this.$pos.cashier.methodConfigs || {}),
        [type]: config,
      },
    });
  }

  getMethod(type: string) {
    return this.methods[type];
  }

  async getAllMethod(type: string) {
    if (this.allMethods[type]) return this.allMethods[type];
    return await this._initPayment(type);
  }

  getInfo(type: string) {
    return {
      shopId: this.$shop?._id,
      cashierId: this.$pos.cashier?._id,
      shopName: this.$td(this.$shop?.name),
      cashierName: this.$td(this.$pos.cashier?.name) || this.$pos.cashier?.shortId,
      cashierShortId: this.$pos.cashier?.shortId,
      countryCode: "AU",
      // posVersion: "",
    };
  }

  start() { }

  // #region Razer
  razer: RazerPayManager = null;

  get hasRazer() {
    return this.$pos.paymentMethods.find(it => it.type === "razer");
  }

  @Watch("hasRazer")
  updateRazer() {
    return this.getRazer();
  }

  get razerConnected() {
    return this.razer?.status === "connected";
  }

  get razerError() {
    return this.razer?.status === "disconnected";
  }

  get razerStatus() {
    if (!this.razer) return "";
    switch (this.razer.status) {
      case "disconnected":
        return this.$t("enum.basic.status.disconnected");
      case "notSetup":
        return this.$t("octopus.manualConnect");
    }
  }


  getRazer(setup?: boolean) {
    console.log(!this.razer && this.hasRazer  && (setup || this.$pos.cashier?.razerConfig))
    if (!this.razer && this.hasRazer) {
      this.razer = new RazerPayManager({
        parent: this,
        propsData: {
          getSetting: () => {
            return this.$pos.cashier?.razerConfig;
          },
          setSetting: v => {
            this.$pos.updateCurrentCashier({
              razerConfig: v,
            })
          },
          whenDestroy: () => {
            this.razer = null;
          },
        },
      });
    }
    return this.razer;
  }

  connectRazer() {
    this.getRazer(true)?.openSettings?.();
  }

  // #endregion

  // #region TurnCloud

  @Watch("hasTurnCloud")
  updateTurnCloud() {
    return this.getTurnCloud();
  }

  turnCloud: TurnCloudManager = null;
  get hasTurnCloud() {
    return this.$pos.paymentMethods.find(it => it.type === "turnCloud");
  }

  get turnCloudError() {
    return this.turnCloud?.status === "disconnected";
  }

  get turnCloudConnected() {
    return this.turnCloud?.status === "connected";
  }


  get turnCloudStatus() {
    if (!this.turnCloud) return "";
    switch (this.turnCloud.status) {
      case "disconnected":
        return this.$t("enum.basic.status.disconnected");
      case "notSetup":
        return this.$t("octopus.manualConnect");
    }
  }

  connectTurnCloud() {
    this.getTurnCloud(true)?.openSettings?.();
  }

  getTurnCloud(setup?: boolean) {
    if (!this.turnCloud && this.hasTurnCloud && (setup || this.$pos.cashier?.turnCloudConfig)) {
      this.turnCloud = new TurnCloudManager({
        parent: this,
        propsData: {
          getSetting: () => {
            return this.$pos.cashier?.turnCloudConfig
          },
          setSetting: v => {
            this.$pos.updateCurrentCashier({
              turnCloudConfig: v,
            });
          },
          whenDestroy: () => {
            this.turnCloud = null;
          },
        },
      });
    }
    return this.turnCloud;
  }
  // #endregion

  octopus: OctopusManager = null;
  bbmsl: BBMSLManager = null;

  async refundPayment(paymentId: string, opts?: PaymentOptions) {
    let payment = await this.$feathers.service("shop/payments").get(paymentId);
    let refunded = false;
    let message: string;

    try {
      switch (payment.type) {
        case "bbpos": {
          const bbmsl = this.checkBBMSL();
          refunded = await bbmsl.refundBBMSLPayment(getID(payment));
          break;
        }
        default: {
          const method = this.$paymentManager.getMethod(payment.type);
          if (method) {
            await method.waitReady(true);
            let paymentLike: PaymentLike = {
              _id: payment._id,
              amount: payment.amountInt,
              status: "paid",
              metadata: payment.metadata,
              subType: payment.subMethod,
            };
            if(!opts) opts = {};
            if(!opts.cloudInvoke) opts.cloudInvoke = this.cloudInvoke;
            if (method.capabilities?.void) {
              try {
                const resp = await method.void(paymentLike, opts);
                if (resp) {
                  paymentLike = resp;
                }
                if (paymentLike.status === "refunded") {
                  refunded = true;
                }
              } catch (e) {
                if (method.capabilities?.restore) {
                  const resp = await method.restore(paymentLike, opts);
                  if (resp) {
                    paymentLike = resp;
                  }
                  if (paymentLike.status === "refunded") {
                    refunded = true;
                  }
                }
                // if void failed, try refund
                if(!refunded && !method.capabilities?.refund) {
                  throw e;
                }
              }
            }
            if (!refunded && method.capabilities?.refund) {
              try {
                const resp = await method.refund(paymentLike, opts);
                if (resp) {
                  paymentLike = resp;
                }
                if (paymentLike.status === "refunded") {
                  refunded = true;
                }
              } catch (e) {
                if (method.capabilities?.restore) {
                  const resp = await method.restore(paymentLike, opts);
                  if (resp) {
                    paymentLike = resp;
                  }
                  if (paymentLike.status === "refunded") {
                    refunded = true;
                  }
                }
                if(!refunded) {
                  throw e;
                }
              }
            }
          } else {
            refunded = true;
          }
          break;
        }
      }
    } catch (e) {
      refunded = false;
      message = e.message || `${e}`;
    }

    if (!refunded) {
      const c = await this.$openDialog(
        import("@feathers-client/components-internal/ConfirmDialog.vue"),
        {
          title: `${this.$t("payment.failedToCancel")}${message ? ": " + message : ""}`,
        },
        {
          maxWidth: "400px",
        },
      );
      if (!c) return payment;
    }

    payment = await this.$feathers.service("shop/payments/cancel").create({
      _id: payment._id,
    });

    return payment;
  }


  async cloudInvoke(paymentLike: PaymentLike, payload: CloudPayload, options?: PaymentOptions) {
    const resp = await this.$feathers.service("shop/payments/update").create({
      _id: paymentLike._id,
      payload,
    });
    paymentLike.status = resp.status;
    paymentLike.metadata = resp.metadata;
    paymentLike.errorMessage = resp.errorMessage;
    return paymentLike;
  }

  get hasOctopus() {
    return this.$pos.paymentMethods.find(it => it.type === "octopus");
  }

  get hasBBMSL() {
    return this.$pos.paymentMethods.find(it => it.type === "bbpos");
  }

  get connectionCount() {
    return (
      [
        this.hasOctopus,
        this.hasBBMSL,
        // this.hasHase,
        this.hasTurnCloud,
        this.hasRazer,
        // this.hasSimplePaymentIntegration,
      ].reduce((a, b) => a + (!!b ? 1 : 0), 0) + this.methodConnectionCount
    );
  }

  get connectionSetupCount() {
    return (
      [
        this.hasOctopus && this.$pos.cashier?.octopus,
        this.hasBBMSL && this.$pos.cashier?.bbmsl,
        // this.hasHase && this.$pos.cashier?.hase,
        this.hasTurnCloud && this.$pos.cashier?.turnCloudConfig,
        // this.hasRazer && this.$pos.cashier?.razer,
        // this.hasSimplePaymentIntegration && this.$pos.cashier?.simplePaymentIntegration,
      ].reduce((a, b) => a + (!!b ? 1 : 0), 0) + this.methodSetupCount
    );
  }

  get onlineCount() {
    return (
      [
        this.octopusConnected,
        this.bbmslConnected,
        // this.haseConnected,
        this.turnCloudConnected,
        this.razerConnected,
        // this.simplePaymentIntegrationConnected,
      ].reduce((a, b) => a + (!!b ? 1 : 0), 0) + this.methodOnlineCount
    );
  }

  get errorCount() {
    return (
      [
        this.octopusError,
        this.bbmslError,
      ].reduce((a, b) => a + (!!b ? 1 : 0), 0) + this.methodErrorCount
    );
  }

  @Watch("hasOctopus")
  updateOctopus() {
    return this.getOctopus();
  }

  getOctopus(setup?: boolean) {
    if (!this.octopus && this.hasOctopus && (setup || this.$pos.cashier.octopus)) {
      this.octopus = new OctopusManager({
        parent: this,
        propsData: {
          getSetting: () => {
            return this.$pos.cashier.octopus;
          },
          setSetting: v => {
            this.$pos.updateCurrentCashier({
              octopus: v,
            });
          },
          getInfo: () => {
            return {
              shopId: this.$shop?._id,
              cashierId: this.$pos?.cashier?._id,
              locationId: this.hasOctopus?.options?.locationId,
              shopName: this.$td(this.$shop?.name),
              cashierName: this.$td(this.$pos.cashier?.name) || this.$pos.cashier?.shortId,

              cloudArgs: {
                method: this.hasOctopus?._id,
                shop: this.$shop?._id,
              }
            };
          },
          whenDestroy: () => {
            this.octopus = null;
          },
        },
      });
    }
    return this.octopus;
  }

  get octopusStatus() {
    if (!this.octopus) return "";
    switch (this.octopus.status) {
      case "connecting":
        return this.octopus.hasConnection ? this.$t("enum.basic.status.connecting") : this.$t("octopus.manualConnect");
      case "starting":
        return this.$t("enum.basic.status.starting");
      case "manual":
        return this.$t("octopus.manualConnect");
    }
  }

  get octopusError() {
    return this.octopus?.status === "disconnected" || this.octopus?.status === "error";
  }

  get octopusConnected() {
    return this.octopus?.status === "connected";
  }

  connectOctopus() {
    this.getOctopus(true)?.openSettings?.();
  }

  getBBMSL(setup?: boolean) {
    if (!this.bbmsl && this.hasBBMSL && (setup || this.$pos?.cashier?.bbmsl)) {
      this.bbmsl = new BBMSLManager({
        parent: this,
        propsData: {
          getSetting: () => {
            return (
              this.$pos?.cashier?.bbmsl || (this.$pos?.cashier?.bbposUrl ? {
                posURL: this.$pos?.cashier?.bbposUrl,
                posUser: this.$pos?.cashier?.bbposUser,
                posPass: this.$pos?.cashier?.bbposPass,
              } : null)
            );
          },
          setSetting: v => {
            this.$pos.updateCurrentCashier({
              bbmsl: v,
              ...(!v ? {
                bbposUrl: null,
              } : {}),
            })
          },
          getInfo: () => {
            return {
              shopId: this.$shop?._id,
              cashierId: this.$pos?.cashier?._id,
              locationId: this.hasOctopus?.options?.locationId,
            };
          },
          whenDestroy: () => {
            this.bbmsl = null;
          },
        },
      });
    }
    return this.bbmsl;
  }

  checkBBMSL() {
    if (!this.bbmsl) {
      throw new Error(String(this.$t("bbmsl.notSetup")));
    }
    return this.bbmsl;
  }

  @Watch("hasBBMSL")
  updateBBMSL() {
    return this.getBBMSL();
  }

  get bbmslError() {
    return this.bbmsl?.status === "disconnected";
  }

  get bbmslConnected() {
    return this.bbmsl?.status === "connected";
  }

  get bbmslStatus() {
    if (!this.bbmsl) return "";
    switch (this.bbmsl.status) {
      case "disconnected":
        return this.$t("enum.basic.status.disconnected");
      case "notSetup":
        return this.$t("octopus.manualConnect");
    }
  }


  async connectBBMSL() {
    this.getBBMSL(true)?.openSettings?.();
  }

  deferSessionUpdateTime: number = 0;
  sessionUpdateTimer: any;

  deferSessionUpdate() {
    this.deferSessionUpdateTime = Date.now();
  }

  @Watch("$pos.session")
  onSessionUpdated(v, ov) {
    if (v === ov) return;
    if (Date.now() - this.deferSessionUpdateTime < 60 * 1000) {
      return;
    }
    this.scheduleSessionUpdate();
  }

  scheduleSessionUpdate() {
    if (this.sessionUpdateTimer) {
      clearTimeout(this.sessionUpdateTimer);
      this.sessionUpdateTimer = null;
    }
    this.sessionUpdateTimer = setTimeout(this.sessionUpdateCheck, 10 * 1000);
  }

  async sessionUpdateCheck() {
    this.sessionUpdateTimer = null;
    if (this.$shop._id && !checkID(this.$pos?.session, this.$pos.cacheOptions.lastSession)) {
      if (this.$pos?.session) {
        await this.triggerShopOpen();
      } else {
        await this.triggerShopClose();
      }
    }
  }

  async triggerShopOpen() {
    if (this.octopus) {
      try {
        await this.octopus.waitReady(true);
        await this.octopus.download();
      } catch (e) {
        this.$store.commit("SET_ERROR", e.message);
      }
    }
    if (this.$shop._id) {
      this.$pos.cacheOptions.lastSession = getID(this.$pos?.session);
    }
  }

  async triggerShopClose() {
    if (this.octopus) {
      try {
        await this.octopus.waitReady(true);
        await this.octopus.upload();
      } catch (e) {
        this.$store.commit("SET_ERROR", e.message);
      }
    }
    if (this.$shop._id) {
      // this.$pos.cacheOptions.lastSession = null;
    }
  }
}

Object.defineProperty(Vue.prototype, "$paymentManager", {
  get(this: Vue) {
    return (
      (<any>this.$root.$options).$paymentManager ||
      ((<any>this.$root.$options).$paymentManager = new PaymentManager(getOptions(this.$root)).initManager())
    );
  },
});

declare module "vue/types/vue" {
  export interface Vue {
    $paymentManager: PaymentManager;
  }
}
