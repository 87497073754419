

import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import { ImportConfig, ImportField, ImportContext, ImportFileSource } from "./importCommon";

@Component({
    components: {
    },
})
export default class EditorImportFileSelect extends Vue {
    @Prop()
    context : ImportContext

    @Prop()
    item : ImportConfig

    get valid() {
        return !this.enumList.find(it => !it.value?.length);
    }

    get enums(): Record<string, string[]> { return this.item.props.enums || {} }
    set enums(v) {
        Vue.set(this.item.props, 'enums', v || {});
    }

    async beforeMount() {
        await this.$i18n.loadAllLocales?.();
        const locales = this.$i18n.availableLocales;
        for(let item of this.enumList) {
            if(!this.enums[item._id]) {
                const set = new Set<string>();
                set.add(item._id);
                for(let locale of locales) {
                    set.add(this.$td(item.name, locale));
                }
                if(!set.size) continue;
                this.enums = {
                    ...this.enums,
                    [item._id]: Array.from(set)
                }
            }
        }
    }

    get enumList() : { _id: string, name: any, value: string[] }[] {
        const self = this;
        return (this.item.field?.field?.props?.items || []).map(it => ({
            ...it,
            get value() {
                return  self.enums[it._id] || [];
            },
            set value(v) {
                self.enums = {
                    ...self.enums,
                    [it._id]: v || []
                }
            }
        }))
    }



}

