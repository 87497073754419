import type { RazerPayMessage } from "./interface";

export const responseCodes = {
  "01": "PLEASE CALL ISSUER",
  "02": "PLEASE CALL REFERRAL",
  "03": "INVLD MERCHANT",
  "04": "PLS. PICK UP CARD",
  "05": "DO NOT HONOUR",
  "06": "ERROR",
  "07": "Pickup Card, Spl Cond",
  "08": "VERIFY ID AND SIGN",
  "10": "Appvd for Partial Amt",
  "11": "Approved (VIP)",
  "12": "INVLD TRANSACTION",
  "13": "INVLD AMT",
  "14": "INVLD CARD NUM",
  "15": "No such Issuer",
  "16": "Approved, Update Tk 3",
  "17": "Customer Cancellation",
  "18": "Customer Dispute",
  "19": "RE-ENTER TRANSACTION",
  "20": "INVALID RESPONSE",
  "21": "NO TRANSACTIONS",
  "22": "Suspected Malfunction",
  "23": "Unaccepted Trans Fee",
  "24": "Declined, wrong P55",
  "25": "Declined, wrong crypto",
  "26": "Dup Rec,Old Rec Rplcd",
  "27": "FIELD EDIT ERROR",
  "28": "FILE LOCKED OUT",
  "29": "File Update Error",
  "30": "FORMAT ERROR",
  "31": "BANK NOT SUPPORTED",
  "32": "Completed Partially",
  "33": "EXPIRED CARD",
  "34": "SUSPECTED FRAUD",
  "35": "Contact Acquirer",
  "36": "Restricted Card",
  "37": "Call Acq. Security",
  "38": "PIN tries Exceeded",
  "39": "No Credit Account",
  "40": "FUNC. NOT SUPPORTED",
  "41": "LOST CARD",
  "42": "NO UNIVERSAL ACCOUNT",
  "43": "Please Call – CC",
  "44": "No Investment Account",
  "45": "ISO ERROR #45",
  "46": "PLS INSERT CARD",
  "47": "ISO ERROR #47",
  "48": "ISO ERROR #48",
  "49": "ISO ERROR #49",
  "50": "ONLINE PIN REQUESTED",
  "51": "INSUFFICIENT FUND",
  "52": "NO CHEQUE ACC",
  "53": "NO SAVINGS ACCOUNT",
  "54": "EXPIRED CARD",
  "55": "Incorrect PIN",
  "56": "No Card Record",
  "57": "Txn not Permtd-card",
  "58": "TRANS NOT PERMITTED",
  "59": "Suspected Fraud",
  "60": "CONTACT ACQUIRER",
  "61": "EXCEED LIMIT",
  "62": "Restricted Card",
  "63": "SECURITY VIOLATION",
  "64": "ORG AMOUNT INCORRECT",
  "65": "Freq. Limit Exceed",
  "66": "CALL ACQ’S SECURITY",
  "67": "HARD CAPTURE",
  "68": "Resp Recvd too Late",
  "69": "ISO ERROR #69",
  "70": "ISO ERROR #70",
  "71": "ISO ERROR #71",
  "72": "ISO ERROR #72",
  "73": "FUNCTION NOT PERMITTED",
  "74": "No Comm With Host",
  "75": "PIN TRIES EXCEED",
  "76": "KEY SYNC ERROR",
  "77": "Resvd. For Nat. use",
  "78": "OLD ROC NOT FOUND",
  "79": "BATCH ALREADY OPEN",
  "80": "Resvd. For Nat. use",
  "81": "Private error #81",
  "82": "Private error #82",
  "83": "INVALID PIN",
  "84": "Private error #84",
  "85": "BATCH NOT FOUND",
  "86": "PRIVATE ERROR #86",
  "87": "Private error #87",
  "88": "HAVE CM CALL AMEX",
  "89": "RESVD. FOT NAT. USE",
  "90": "Cutoff in Process",
  "91": "NETWORK ERROR",
  "92": "Trans can’t be Routed",
  "93": "TXN CAN NOT BE COMPLETED",
  "94": "DUPL TRANSMISSION",
  "95": "RECONCILE ERROR",
  "96": "INVALID MESSAGE",
  "97": "Resvd. For Nat. use",
  "98": "Resvd. For Nat. use",
  "99": "Resvd. For Nat. use",
  "XD": "Pay Account ID Mismatch (Batch NOT Empty)",
  "XE": "Pay Account ID Mismatch (Batch Empty)",
  "NA": "TRANSACTION NOT SUCCESS",
  "CP": "Card Present",
  "MS": "MUST SETTLE",
  "NF": "RECORD NOT FOUND",
  "CE": "TRANS COMM ERROR",
  "CC": "CANNOT CANCEL",
  "UA": "USER ABORT",
  "ST": "SCREEN TIMEOUT",
  "WE": "WALLET ERROR",
  "UR": "UPLOADING RECEIPT",
  "E2": "TRANSACTION NOT PERMITTED",
  "MR": "MAX E-RECEIPT THRESHOLD REACHED",
  "DE": "DB ERROR",
  "TP": "TRANSACTION PENDING",
  "TR": "TRANSACTION REJECTED",
  "DT": "DUPLICATE TRANSACTION"
};

export const fieldToFieldCode: Record<keyof RazerPayMessage['body'], string> = {
  "payAccountId": "00",
  "approvalCode": "01",
  "responseText": "02",
  "transactionDate": "03",
  "transactionTime": "04",
  "retrievalReferenceNo": "06",
  "receiptRequiredFlag": "09",
  "terminalId": "16",
  "receiptFooterMerchantCopy": "17",
  "receiptFooterCustomerCopy": "18",
  "receiptData": "19",
  "encryptedCardNo": "29",
  "maskedPan": "30",
  "expiryDate": "31",
  "cardIssueDate": "32",
  "memberExpiryDate": "33",
  "accountBalance": "38",
  "transactionStatus": "39",
  "amount": "40",
  "cashbackAmount": "42",
  "batchNo": "50",
  "forceSettlementFlag": "52",
  "screenText": "61",
  "screenTimeout": "62",
  "traceNo": "64",
  "invoiceNo": "65",
  "transactionId": "66",
  "extendedInvoiceNo": "67",
  "salesTotal": "80",
  "offlineSalesTotal": "81",
  "voidSalesTotal": "82",
  "cashbackTotal": "83",
  "voidCashbackTotal": "84",
  "refundTotal": "85",
  "voidRefundTotal": "86",
  "batchTransactionCount": "87",
  "customData": "99",
  "malaysianNric": "A1",
  "nameAsPerIc": "A2",
  "isoMessage": "AA",
  "cashierId": "C0",
  "compareCardFlag": "C1",
  "compareCardNumber": "C2",
  "merchantName": "D0",
  "merchantNo": "D1",
  "cardIssuerName": "D2",
  "hostName": "D3",
  "cardLabel": "D4",
  "cardholderName": "D5",
  "contactType": "D6",
  "phone": "D7",
  "email": "D8",
  "transactionLabel": "D9",
  "hostCount": "DA",
  "aid": "E0",
  "applicationProfile": "E1",
  "cid": "E2",
  "applicationCryptogram": "E3",
  "tsi": "E4",
  "tvr": "E5",
  "cardEntryMode": "E6",
  "eppTenure": "ET",
  "serialNo": "FF",
  "walletProductId": "W1",
  "customDataParsed": null,
};

export const fieldCodeToField: Record<string, keyof RazerPayMessage['body']> = {
  "00": "payAccountId",
  "01": "approvalCode",
  "02": "responseText",
  "03": "transactionDate",
  "04": "transactionTime",
  "06": "retrievalReferenceNo",
  "09": "receiptRequiredFlag",
  "16": "terminalId",
  "17": "receiptFooterMerchantCopy",
  "18": "receiptFooterCustomerCopy",
  "19": "receiptData",
  "29": "encryptedCardNo",
  "30": "maskedPan",
  "31": "expiryDate",
  "32": "cardIssueDate",
  "33": "memberExpiryDate",
  "38": "accountBalance",
  "39": "transactionStatus",
  "40": "amount",
  "42": "cashbackAmount",
  "50": "batchNo",
  "52": "forceSettlementFlag",
  "61": "screenText",
  "62": "screenTimeout",
  "64": "traceNo",
  "65": "invoiceNo",
  "66": "transactionId",
  "67": "extendedInvoiceNo",
  "80": "salesTotal",
  "81": "offlineSalesTotal",
  "82": "voidSalesTotal",
  "83": "cashbackTotal",
  "84": "voidCashbackTotal",
  "85": "refundTotal",
  "86": "voidRefundTotal",
  "87": "batchTransactionCount",
  "99": "customData",
  "A1": "malaysianNric",
  "A2": "nameAsPerIc",
  "AA": "isoMessage",
  "C0": "cashierId",
  "C1": "compareCardFlag",
  "C2": "compareCardNumber",
  "D0": "merchantName",
  "D1": "merchantNo",
  "D2": "cardIssuerName",
  "D3": "hostName",
  "D4": "cardLabel",
  "D5": "cardholderName",
  "D6": "contactType",
  "D7": "phone",
  "D8": "email",
  "D9": "transactionLabel",
  "DA": "hostCount",
  "E0": "aid",
  "E1": "applicationProfile",
  "E2": "cid",
  "E3": "applicationCryptogram",
  "E4": "tsi",
  "E5": "tvr",
  "E6": "cardEntryMode",
  "ET": "eppTenure",
  "FF": "serialNo",
  "W1": "walletProductId",
};

export enum RazerPayTransactionType {
  VERIFY_PAY_ACCOUNT_ID = '00',
  CARD_DETAIL_INQUIRY = '10',
  BALANCE_INQUIRY = '11',
  LAST_TRANSACTION_INQUIRY = '12',
  READ_MYKAD = '13',
  READ_MAGNETIC_CARD = '14',
  PURCHASE = '20',
  TOP_UP = '21',
  PRE_AUTH = '22',
  PRE_AUTH_COMPLETION = '23',
  VOID = '40',
  REFUND = '41',
  SETTLEMENT = '50',
  PRINT_REPORT = '60',
  DISPLAY_SCREEN = '61',
  CARDHOLDER_INQUIRY = '90',
  FORWARDING_MESSAGE = '99',
  PING = 'FF',
  CANCEL = 'XX',
}

// 01 – Alipay
// 02 – WeChat CN
// 03 – WeChat MY
// 04 – Razer Pay
// 05 – Boost
// 06 – TNG WALLET
// 07 – Maybank
// 08 – GrabPay
// 09 – ShopeePay
// 10 – UnionPay QR
// 99 – DuitNow QR
export enum RazerPaymentMethod {
  CREDIT = '00',
  ALIPAY = '01',
  WECHAT_CN = '02',
  WECHAT_MY = '03',
  RAZER_PAY = '04',
  BOOST = '05',
  TNG_WALLET = '06',
  MAYBANK = '07',
  GRAB_PAY = '08',
  SHOPEE_PAY = '09',
  UNION_PAY_QR = '10',
  DUITNOW_QR = '99',
}

export const CardEntryMode = {
  "10": "Chip Card",
  "20": "Contactless",
  "30": "Swipe",
  "40": "Fallback",
  "50": "Manual",
  "60": "QR",
}

export const customFieldCodeToField: Record<string, keyof RazerPayMessage['body']['customDataParsed']> = {
  "B102": "OTSRRedemptionPoint",
  "B103": "OTSRRedemptionAmount",
  "B104": "OTSRNetSaleAmount",
  "B105": "OTSRBalancePoint",
  "B601": "cashbackConvenienceFee",
  "B901": "walletStoreID",
  "B902": "walletTerminalCode",
  "B903": "walletInvoiceNumber",
  "B904": "walletPayerID",
  "B905": "walletReferenceID",
  "B906": "walletTransactionID",
  "B907": "walletCurrencyCode",
  "B908": "walletAmount",
  "B909": "walletExchangeRate",
  "B910": "walletProductName",
  "B911": "walletResponseCode",
};

