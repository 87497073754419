
import { Component, Prop, Vue, Watch, mixins, VModel, PropSync } from "nuxt-property-decorator";
import { SearchField } from "./plugin";
import components from "./components";

@Component({
  components,
})
export default class EditorSearchMenuChip extends Vue {
  @Prop()
  item: SearchField;

  @Prop(Boolean)
  pin: boolean;

  @Prop()
  dateCutOffTime: string;

  @Prop()
  context: any;

  menu = false;

  toggleCond() {
    const idx = this.item.conds.indexOf(this.item.cond);
    this.item.cond = this.item.conds[(idx + 1) % this.item.conds.length];
  }

  clearCond() {
    this.item.value1 = null;
    this.item.value2 = null;
  }

  get isSearchActive() {
    return (
      this.item.value1 !== null &&
      this.item.value1 !== "" &&
      !(Array.isArray(this.item.value1) && !this.item.value1.length)
    );
  }
}
