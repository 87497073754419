
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import _ from "lodash";
import Dialog from "domore-table/mixins/Dialog";
import {} from "@feathers-client";
import type { ObjectID } from "@db";
import EditorSinglePage from "@schemaEditor/EditorSinglePage.vue";
import type { InventoryEntry, InventoryAction } from "@server/shop/inventory";
import { ReadableWebToNodeStream } from "readable-web-to-node-stream";
import ExcelStream from "@schemaEditor/workbook-reader";
import moment from "moment";
import QrcodeStream from "@feathers-client/qrcode-scanner/components/QrcodeStream.vue";
import { checkID } from "@feathers-client";
import { ScannerEvent } from "pos-printer/scanner";

type InventoryEntryTemp = InventoryEntry & {
  slug?: string;
};

function getCellValue(cellValue: any) {
  if (cellValue && typeof cellValue === "object") {
    if (cellValue.richText) {
      return _.map(cellValue.richText, r => r.text ?? "").join("");
    }
  }
  return `${cellValue ?? ""}`;
}

@Component({
  components: {
    EditorSinglePage,
    QrcodeStream,
  },
})
export default class InventoryManagement extends mixins(Dialog()) {
  item: InventoryAction = {
    action: "restock",
    warehouse: null,
    target: null,
    entries: [],
    comment: "",
  };

  @Prop()
  initAction: InventoryAction;

  @Prop()
  productGroup: string;

  scanning = false;

  sameValue = false;

  sameValueDelta = 0;

  get disabled() {
    return !(this.item.entries.length > 0 && _.every(this.item.entries, x => !!x.sku));
  }

  get skuQuery() {
    return this.productGroup
      ? {
          productGroup: this.productGroup,
        }
      : {};
  }

  defaultEntry = {
    delta: 0,
    sku: null,
    editing: false,
  };

  beforeMount() {
    if (this.initAction) {
      if (this.initAction.action) this.item.action = this.initAction.action;
      if (this.initAction.warehouse) this.item.warehouse = this.initAction.warehouse;
      if (this.initAction.entries) this.item.entries.push(...this.initAction.entries);
    }
  }

  mounted() {
    this.$scanner.registerHandler(this.onScanner);
  }

  beforeDestroy() {
    this.$scanner.unregisterHandler(this.onScanner);
  }

  warehouseFields = [{ translate: true, field: "name" }];
  skuFields = [
    { translate: true, field: "fullName" },
    { translate: true, field: "name" },
  ];
  lotFields = [
    { field: "reference", mode: "none" },
    { field: "entryDate", mode: "none" },
    { field: "expiryDate", mode: "none" },
    { field: "location", mode: "none" },
    { field: "amount" },
  ];

  loading = false;

  _cachedQuries: any;
  cachedQuery(warehouse: string, sku: string) {
    if (!this._cachedQuries) this._cachedQuries = {};
    return (
      this._cachedQuries[warehouse + sku] ||
      (this._cachedQuries[warehouse + sku] = {
        sku,
        warehouse,
        used: false,
      })
    );
  }

  get actions() {
    return [
      {
        _id: "restock",
        name: { $t: "edit_product.sku.inventory.restock" },
      },
      {
        _id: "adjust",
        name: { $t: "edit_product.sku.inventory.adjust" },
      },
      {
        _id: "export",
        name: { $t: "edit_product.sku.inventory.export" },
      },
      {
        _id: "transfer",
        name: { $t: "edit_product.sku.inventory.transfer" },
      },
      {
        _id: "setTo",
        name: { $t: "edit_product.sku.inventory.setTo" },
      },
    ];
  }

  applySameValueDelta() {
    for (let sku of this.item.entries) {
      sku.delta = this.sameValueDelta;
    }
  }

  async confirm() {
    this.loading = true;
    try {
      await this.$feathers.service("shop/inventory/actions").create(this.item);
      this.modalResult(true);
    } catch (e) {
      this.$store.commit("SET_ERROR", e.message);
    } finally {
      this.loading = false;
      this.$store.commit("SET_SUCCESS", this.$t("inventory.management.actionsSuccess"));
    }
  }

  async doImport() {
    const file = document.createElement("input");
    file.style.display = "none";
    file.type = "file";
    file.accept = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    document.body.append(file);
    file.click();
    await new Promise(resolve => (file.onchange = resolve));
    if (file.files.length == 0) return;
    const mfile = file.files[0];

    const r = mfile.stream() as any as ReadableStream;
    const nr = new ReadableWebToNodeStream(r);
    const wb = new ExcelStream(nr, {
      styles: "cache",
    });

    try {
      this.loading = true;

      let chunks: InventoryEntryTemp[] = [];

      const flushChunk = async () => {
        chunks = chunks.filter(it => (it.sku || it.slug) && it.delta !== undefined);
        if (!chunks.length) {
          return;
        }
        const slugChunk = chunks.filter(it => !it.sku && it.slug);
        const skus = slugChunk.length
          ? await this.$feathers.service("shop/product/skus").find({
              query: {
                slug: {
                  $in: slugChunk.map(it => it.slug),
                },
                $select: ["_id", "slug"],
                $paginate: false,
              },
              paginate: false,
            })
          : [];

        for (let item of chunks) {
          if (item.slug && !item.sku) {
            item.sku = skus.find(it => it.slug === item.slug)?._id;
          }
        }

        chunks = chunks.filter(it => !!it.sku);

        this.item.entries.push(...chunks);
        chunks = [];
      };

      for await (const item of wb as any) {
        if (item.eventType !== "worksheet") {
          continue;
        }
        const ws = item.value;
        let headerInit = false;
        let refIdx = -1;
        let skuIdx = -1;
        let quantityIdx = -1;
        let priceIdx = -1;
        let expiryIdx = -1;
        let supplierIdx = -1;
        let locationIdx = -1;
        let referenceIdx = -1;
        let commentIdx = -1;

        for await (const row of ws as any) {
          let values = row.values;
          if (!headerInit) {
            headerInit = true;
            refIdx = values.findIndex(it => getCellValue(it).toLowerCase() === "ref");
            skuIdx = values.findIndex(it => getCellValue(it).toLowerCase() === "sku");
            quantityIdx = values.findIndex(it => getCellValue(it).toLowerCase() === "quantity" || it === "數量");
            priceIdx = values.findIndex(it => getCellValue(it).toLowerCase() === "price" || it === "單價");
            expiryIdx = values.findIndex(it => getCellValue(it).toLowerCase() === "expiry" || it === "過期日");
            supplierIdx = values.findIndex(it => getCellValue(it).toLowerCase() === "supplier" || it === "供應商");
            locationIdx = values.findIndex(it => getCellValue(it).toLowerCase() === "location" || it === "地點");
            referenceIdx = values.findIndex(it => getCellValue(it).toLowerCase() === "reference" || it === "參考編號");
            commentIdx = values.findIndex(it => getCellValue(it).toLowerCase() === "comment" || it === "備註");

            if ((refIdx === -1 && skuIdx === -1) || quantityIdx === -1) {
              throw new Error("Invalid header");
            }
            continue;
          }
          chunks.push({
            sku: getCellValue(values[refIdx]),
            slug: getCellValue(values[skuIdx]) ? String(getCellValue(values[skuIdx])) : undefined,
            delta: getCellValue(values[quantityIdx]) ? +getCellValue(values[quantityIdx]) : undefined,
            unitPrice: getCellValue(values[priceIdx]) ? +getCellValue(values[priceIdx]) : undefined,
            expiryDate: getCellValue(values[expiryIdx]) ? moment(getCellValue(values[expiryIdx])).toDate() : undefined,
            supplier: getCellValue(values[supplierIdx]),
            location: getCellValue(values[locationIdx]),
            reference: getCellValue(values[referenceIdx]),
            comment: getCellValue(values[commentIdx]),
          });

          if (chunks.length >= 100) {
            await flushChunk();
          }
        }

        // breaking change: to support summary sheet, only use first sheet
        break;
      }
      if (chunks.length) {
        await flushChunk();
      }
      console.log("done");
    } catch (e) {
      this.$store.commit("SET_ERROR", e.message);
    } finally {
      this.loading = false;
    }
  }

  async onScanner(item: ScannerEvent) {
    const code = `${item.code || ""}`.trim();
    if (!code) return;

    let skuId = null;
    if (code.startsWith("http://") || code.startsWith("https://")) {
      // handle url
      const domains = new Set(
        [
          this.$shop?.posInvoiceDomain,
          window.location.hostname,
          "boxs.hk",
          this.$qrPrefix ? this.$qrPrefix.substring(this.$qrPrefix.lastIndexOf("/") + 1) : "",
        ].filter(it => !!it),
      );

      const u = new URL(code);
      if (domains.has(u.hostname)) {
        const parts = u.pathname.substring(1).split("/");
        const remain = parts.slice(1);
        const remainFull = remain.join("/");

        switch (parts[0]) {
          case "p": {
            const data = Buffer.from(remainFull, "base64");
            const groupId = data.slice(0, 12).toString("hex");
            const item = (
              await this.$feathers.service("shop/product/searches").find({
                query: {
                  productGroup: groupId,
                  shop: this.$shop._id,
                  $paginate: false,
                },
                paginate: false,
              })
            )[0];
            let extra = data.slice(12);
            for (let i = 0; i < extra.length; ) {
              const c = String.fromCharCode(extra[i++]);
              switch (c) {
                case "s":
                  skuId = extra.slice(i, i + 12).toString("hex");
                  i += 12;
                  break;
                default:
                  throw new Error(`${this.$t("pos.error.badExtraCode")}`);
              }
            }
            break;
          }
        }
      }
    }

    const skus = await this.$feathers.service("shop/product/skus").find({
      query: {
        $or: [
          {
            ...(skuId ? { _id: skuId } : { slug: code }),
          },
          ...(this.$pos.posCode
            ? [
                {
                  specs: {
                    $elemMatch: {
                      spec: this.$pos.posCode,
                      value: code,
                    },
                  },
                },
              ]
            : []),
        ],
        $paginate: false,
      },
      paginate: false,
    });

    for (let sku of skus) {
      const entry = this.item.entries?.find?.(it => checkID(it.sku, sku._id));
      if (entry) {
        entry.delta += 1;
      } else {
        this.item.entries.push({
          sku: sku._id,
          delta: 1,
          unitPrice: undefined,
          expiryDate: undefined,
          supplier: undefined,
          location: undefined,
          reference: undefined,
          comment: undefined,
        });
      }
    }
    if (skus.length) {
      this.scanning = false;
    }
  }
}
