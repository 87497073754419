
import { Component, Prop, Ref, Watch, Vue, VModel, PropSync, checkID, getID, Emit, InjectReactive } from "@feathers-client";
// @ts-ignore
import type MenuListPicker from "./MenuListPicker.vue";
// @ts-ignore
import type VirtualDataList from "domore-table/VirtualDataList.vue";
// @ts-ignore
import draggable from "vuedraggable";
import { escapeRegExp } from "../util";

const sharedSearch: Record<string, string> = {};

@Component({
  components: {
    draggable,
  },
})
export default class MenuListPickerDropdown extends Vue {
  filterQuery: any = {};

  lastCachedValue: any[] = [];

  @VModel()
  activeMenu: boolean;

  @PropSync("searchKeyword")
  searchKeywordSync: string;

  @Emit()
  chooseItem(item: any) {
    return item;
  }

  @Emit()
  createItem() {}

  get hasSelection() {
    return this.selectedIds.length > 0;
  }

  get selectedIds() {
    return this.parent.multiple
      ? Array.isArray(this.parent.inputValue)
        ? this.parent.inputValue.map(it => getID(it))
        : []
      : this.parent.inputValue
        ? [getID(this.parent.inputValue)]
        : [];
  }

  get cachedNotSelected() {
    if (!this.parent.ordered) return [];
    return this.lastCachedValue.filter(it => !this.keySet[getID(it)]);
  }

  get keySet() {
    return Object.fromEntries(this.selectedIds.map((it, idx) => [it, idx + 1]));
  }

  get staticItems() {
    const search = escapeRegExp((this.searchKeywordSync || "").toLowerCase().trim());

    if (!search) {
      return this.parent.items;
    } else if (this.parent.items) {
      const reg = new RegExp(search, "i");
      const fields = this.parent.normalizedFields;
      const getter = this.parent.getter;
      return this.parent.items.filter(it => {
        return fields.some(field => {
          const value = getter(it, field);
          return reg.test(`${value ?? ""}`);
        });
      });
    }
  }

  mounted() {
    if (this.searchKeywordSync) {
      this.goSearch();
    }
  }

  @Watch("activeMenu", { immediate: true })
  onActiveMenu() {
    if (this.activeMenu && !matchMedia("(pointer:coarse)").matches) {
      Vue.nextTick(() => {
        (this.$refs.input as any)?.focus();
      });
    }
    if (this.activeMenu) {
      if (!this.parent.stringOnly && this.parent.path) {
        const cur = sharedSearch[this.parent.path];
        if (cur !== undefined && cur !== this.searchKeywordSync) {
          this.setSearch(cur || "");
        }
      }
      this.updateLastCache();
    }
  }

  get parent() {
    return this.$parent as MenuListPicker;
  }

  setSearch(v) {
    if (!this.parent.stringOnly && this.parent.path) {
      sharedSearch[this.parent.path] = v;
    }
    this.searchKeywordSync = v;
    this.queueSearch();
  }

  queueSearch() {
    if (this.parent.stringOnly) return;
    if (this.parent.combo) {
      this.parent.inputValue = null;
    }
    this.goSearch();
  }

  goSearch() {
    if (!this.parent.path) {
      return;
    }
    if (this.parent.serverSearch) {
      this.filterQuery = {
        $keyword: this.searchKeywordSync,
        ...(this.lastCachedValue.length ? { _id: { $nin: this.lastCachedValue.map(it => getID(it)) } } : {}),
      };
    } else {
      const conds = this.parent.normalizedFields
        .concat(this.parent.normalizedSearchFields)
        .filter(it => !!it.mongo)
        .map(it => ({
          [it.mongoField || (Array.isArray(it.field) ? it.field.filter(it => it !== "*").join(".") : it.field)]:
            it.mongo,
        }));
      this.filterQuery = {
        ...(conds.length
          ? {
              $or: conds,
            }
          : {}),
      };
    }
    this.updateLastCache();
  }

  updateLastCache() {
    if (this.parent.stringOnly) {
      this.lastCachedValue = this.parent.cachedNames.map(it => ({
        _id: it[0],
        name: it[0],
      }));
      return;
    }
    
    if (!this.parent.path) return;

    this.lastCachedValue = Array.isArray(this.parent.cachedValue)
      ? this.parent.cachedValue.slice()
      : this.parent.cachedValue
        ? [this.parent.cachedValue]
        : [];
  }

  get finalQuery() {
    return {
      ...(this.parent.query || {}),
      ...this.filterQuery,
      ...(this.lastCachedValue.length ? { _id: { $nin: this.lastCachedValue.map(it => getID(it)) } } : {}),
    };
  }

  listLoaded(initLoad: boolean) {
    if (initLoad) {
      this.resetFocus();
    }
  }

  focusAction = 0;

  resetFocus() {
    if (
      (this.parent.multiple && Array.isArray(this.parent.inputValue) && this.parent.inputValue.length === 1) ||
      (!this.parent.multiple && this.parent.inputValue)
    ) {
      const idx = this.dataList.items.findIndex(it => this.keySet[it._id]);
      if (idx !== -1) {
        this.focusAction = idx;
        return;
      }
    }
    this.focusAction = !this.dataList.items.length && this.parent.searchKeyword && this.parent.canCreate ? -1 : 0;
  }

  @Ref()
  dataList: VirtualDataList;

  navigateFocus(offset: number) {
    let newFocus = this.focusAction + offset;
    if (newFocus < 0) {
      if (this.searchKeywordSync && this.parent.canCreate) {
        if (newFocus === -2) {
          newFocus = this.dataList.items.length - 1;
        } else {
          newFocus = -1;
        }
      } else {
        newFocus = this.dataList.items.length - 1;
      }
    } else if (newFocus >= this.dataList.items.length) {
      newFocus = this.searchKeywordSync && this.parent.canCreate ? -1 : 0;
    }
    this.focusAction = newFocus;
    this.dataList.scrollToIndex(this.focusAction);
  }

  async submit() {
    if (this.parent.stringOnly) {
      this.chooseItem(this.searchKeywordSync);
      this.searchKeywordSync = "";
      Vue.nextTick(() => {
        this.updateLastCache();
      });
      return;
    }
    if (!this.dataList) return;
    if (this.focusAction === -1 && this.parent.canCreate) {
      await this.createItem();
      return;
    }
    const item = this.dataList.items[this.focusAction];
    if (item) {
      if (this.keySet[item._id]) {
        this.activeMenu = false;
        return;
      }
      this.chooseItem(item);
      if (!this.parent.combo) {
        this.searchKeywordSync = "";
      }
    } else if (this.parent.combo) {
      this.parent.inputValue = this.parent.multiple ? [] : null;
    }
  }

  escape() {
    this.activeMenu = false;
  }

  focus() {}

  clear() {
    this.parent.inputValue = this.parent.multiple ? [] : null;
  }

  copy(e: ClipboardEvent) {
    const selection = document.getSelection();
    if (selection.toString()) return;
    e.preventDefault();
    e.clipboardData.setData(
      "application/json",
      JSON.stringify({
        path: this.parent.path,
        ids: this.selectedIds,
      }),
    );
  }

  @InjectReactive({ default: null, from: "feathers" })
  feathers: any;

  getFeathers() {
    return (this as any).__reactiveInject__?.feathers || this.$feathers;
  }

  async paste(e: ClipboardEvent) {
    const data = e.clipboardData.getData("application/json");
    if (!data) return;
    e.preventDefault();
    try {
      const { path, ids } = JSON.parse(data);
      if (path !== this.parent.path || !ids?.length) return;
      const items = await this.getFeathers().service(this.parent.path).find({
        query: {
          ...this.parent.query,
          _id: { $in: ids },
          $limit: ids.length,
        },
      });
      const finalItems = Array.isArray(items) ? items : items.data;
      if (Array.isArray(finalItems)) {
        if (this.parent.multiple) {
          for (let item of finalItems) {
            if (!this.selectedIds.includes(getID(item))) {
              this.chooseItem(item);
            }
          }
        } else {
          this.chooseItem(finalItems[0]);
        }
      }
    } catch (e) {
      console.warn(e);
    }
  }
}
