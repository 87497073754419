
import { AddressHelper } from "@common/addressHelper";
import { Prop, Component, Vue, VModel, Watch } from '@feathers-client'
import type { 
    ShipmentAddressType,
} from '@server/shop/shippings/index'

@Component
export default class AddressEditor extends Vue {
    @Prop()
    address: AddressHelper

    @Prop()
    addressType: 'from' | 'to' | 'default'

    @VModel()
    inputValue: Partial<ShipmentAddressType>

    @Prop(Boolean)
    readonly: boolean

    optionFields = [{field:'name', translate: true}];

    maddress : AddressHelper = null;

    get finalAddress() {
        return this.maddress || this.address;
    }

    created() {
        if(!this.address) {
            this.maddress = new AddressHelper({
                parent: this,
                propsData: {
                    addressType: this.addressType
                }
            })
            if(this.inputValue) {
                this.maddress.setAddress(this.inputValue);
            } else {
                this.$emit('input', this.maddress.address);
            }
        }
    }

    @Watch('inputValue')
    onAddress() {
        this.maddress.setAddress(this.inputValue);
    }

}

