
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import { DataTableDef, DataTableHeader, DataTablePagination, DataTablePaginated } from './index'
import VirtualDataTableCell from './VirtualDataTableCell'
import type VirtualDataTable from './VirtualDataTable.vue'
import {
    VBtn,
} from 'vuetify/lib'
import qs from 'qs'

const StateSymbol = Symbol("StateSymbol");

@Component({
    components: {
        VBtn,
        VirtualDataTableCell,
    }
} as any)
export default class VirtualDataTableRow extends Vue {
    @Prop()
    index : number

    @Prop()
    source : any

    @Prop()
    context : VirtualDataTable

    appendInit = false;

    checkAcl(type: string) {
        if (this.context?.byPassAcl) return true;
        return this.context[type] && (!this.context.acl || this.context.acl(this, type as any, this.source))
    }

    onHover() {
        if(!this.appendInit) {
            this.appendInit = true;
        }
    }

    onClick(e: MouseEvent) {
        if(this.selectable) {
          e.preventDefault();
          e.stopPropagation();
          if(e.shiftKey && this.context.lastClickIndex !== undefined) {
            const start = this.context.lastClickIndex;
            const end = this.index;
            const min = Math.min(start, end);
            const max = Math.max(start, end);
            this.context.mselected = this.context.mloader.store.slice(min, max + 1);
          } else {
            this.selected = !this.selected;
            this.context.lastClickIndex = this.index;
          }
        }
    }

    preventSelectShift(e: MouseEvent) {
        if(this.selectable && e.shiftKey) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    get selected() {
        return this.context.mselected.indexOf(this.source) !== -1;
    }

    set selected(v) {
        if(v === this.selected) return;
        if(v) {
            this.context.mselected.push(this.source);
        } else {
            const idx = this.context.mselected.indexOf(this.source);
            this.context.mselected.splice(idx, 1);
        }
    }

    get rowSize() {
        return this.context.rowSize;
    }

    get appendRow() {
        return this.context.appendRow;
    }

    get isMobile() {
        return this.context.isMobile;
    }

    get expand() {
        return this.context.$scopedSlots.expand;
    }

    get selectable() {
        return this.context.mselect;
    }

    get editPath() {
        let path = this.context.rootPath || this.$route.path
        if(!path.startsWith('/')) {
          path = '/' + path;
        }
        const q : any = {...this.$route.query, edit: this.source._id};
        delete q.locale;
        return this.context.inlineEdit 
            ? '?' + qs.stringify(q)
            : `${path.endsWith('/') ? path : path + '/'}edit/${this.source._id}`;
    }

    get clonePath() {
        const path = this.context.rootPath || this.$route.path
        const q : any = {...this.$route.query, edit: this.source._id};
        delete q.locale;
        return this.context.inlineEdit 
            ? '?' + qs.stringify({q})
            : `${path.endsWith('/') ? path : path + '/'}edit/?clone=${this.source._id}`;
    }

    idState: StateType = null;

    beforeMount() {
        this.updateState();
    }

    @Watch('source')
    updateState() {
        let v = this.source[StateSymbol];
        if(!v) {
            this.source[StateSymbol] = v = {
                expanded: false,
            };
        }
        this.appendInit = false;
        this.idState = v;
    }

}

interface StateType {
    expanded: boolean
}

