
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";

import { ImportFileSource as ImportFileSourceBase } from './base'
import ImportFolderSource from './folder.vue'
import ImportArchiveSource from './archive.vue'
import ImportUrlSource from './url.vue'
import _ from 'lodash'

export class ImportFileSource extends ImportFileSourceBase {
    static create(type : string, root : Vue) {
        switch(type) {
            case 'folder': return new ImportFolderSource(_.omit(root.$nuxt.$options, 'render'));
            case 'archive': return new ImportArchiveSource(_.omit(root.$nuxt.$options, 'render'));
            case 'url': return new ImportUrlSource(_.omit(root.$nuxt.$options, 'render'));
        }
    }
}


