
import { Component, mixins, Prop } from '@feathers-client'
import HeaderProvider from 'domore-table/mixins/HeaderProvider';
import { EditorField } from "./plugin";

@Component
export default class EditorGroupPreview extends mixins(HeaderProvider) {
  @Prop()
  field: EditorField

  @Prop()
  item: any

  render(_c) {
    let cell = this.renderItem(_c, { item: this.item, header: this.field.header });
    if(!cell || Array.isArray(cell) && !cell?.length || cell?.text === '' || cell?.text === '0') {
      if(this.field.header.hideEmpty) return;
      cell = this._v('-')
    }

    return _c('div', {
      staticClass: 'contents'
    }, [
      _c('div', [this._v(typeof this.field.name === 'string' ? this.$t(this.field.name) as string : this.$td(this.field.name))]),
      _c('div', {
        staticClass: 'font-bold'
      }, [cell]),
    ])
  }
}

