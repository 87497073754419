import { getVersion } from "../nativeIntegrations";
import { ns, MainMessageQueue } from "../messageQueue";
import url from "url";
import { EventEmitter } from "events";
import { init as initCloud } from "./cloud";
import { self } from "../messageQueue";

declare const PresentationRequest: any;

export abstract class Screen extends EventEmitter {
  constructor(
    public id: string,
    public url: string,
    public opts: StartScreenOpts,
  ) {
    super();
    screensID[id] = this;
  }

  initCore() {
    this.load = new Promise(resolve => {
      this.queue.register("", {
        loaded: () => {
          this.loaded = true;
          this.emit("loaded");
          resolve();
        },
      });
    });
  }

  queue: MainMessageQueue;

  load: Promise<void>;
  loaded = false;
  closed = false;

  async close(disposing?: boolean) {
    if (this.closed) return;
    this.closed = true;
    delete screens[this.url];
    delete screensID[this.id];
    this.emit("close");
  }

  abstract sendMessage(message: any): Promise<void>;
}

export class NativeScreen extends Screen {
  constructor(
    public id: string,
    public url: string,
    opts: StartScreenOpts,
  ) {
    super(id, url, opts);
    this.queue = new MainMessageQueue("", m => {
      ns("present")
        .call("sendMessage", this.id, m)
        .catch(e => console.warn(e));
    });
    this.initCore();
  }
  async close(disposing?: boolean) {
    await super.close(disposing);
    if (!disposing) await ns("present").call("stop", this.id);
  }

  async sendMessage(message: any) {
    await ns("present").call("sendMessage", this.id, message);
  }
}

export class CloudScreen extends Screen {
  constructor(
    public id: string,
    public url: string,
    public app: any,
    opts: StartScreenOpts,
  ) {
    super(id, url, opts);
    this.queue = new MainMessageQueue("", m => {
      this.sendMessage(m);
    });
    this.sessionMessage = this.sessionMessage.bind(this);
    app.service("cloudCast/sessionMessage").on("created", this.sessionMessage);

    this.initCore();
  }

  get kid() {
    return this.opts.kid;
  }

  async close(disposing?: boolean) {
    await super.close(disposing);
    this.app.service("cloudCast/sessionMessage").off("created", this.sessionMessage);
    if (!disposing) {
      try {
        await this.app.service("cloudCast/session").remove(this.id, {
          query: {
            kid: this.kid,
          },
        });
      } catch (e) {
        console.warn(e);
      }
    }
  }

  async sendMessage(message: any) {
    await this.app.service("cloudCast/sessionMessage").create({
      session: this.id,
      kid: this.kid,
      payload: message,
      receiver: false,
    });
  }

  sessionMessage(event) {
    if (event.session !== this.id) return;
    this.queue.onMessage(event.payload);
  }
}

export class PresentationScreen extends Screen {
  constructor(
    public connection: any,
    public url: string,
    public opts: StartScreenOpts,
  ) {
    super(connection.id, url, opts);
    this.queue = new MainMessageQueue("", m => {
      this.sendMessage(m);
    });
    this.connection.addEventListener("message", e => {
      try {
        this.queue.onMessage(JSON.parse(e.data));
      } catch (e) {
        console.warn(e);
      }
    });
    this.connection.addEventListener("close", () => {
      delete localStorage["lastPresentationId"];
      this.close(true);
    });
    this.connection.addEventListener("terminate", () => {
      delete localStorage["lastPresentationId"];
      this.close(true);
    });
    this.initCore();
  }

  async close(disposing?: boolean) {
    await super.close(disposing);
    if (!disposing) {
      this.connection.terminate();
      delete localStorage["lastPresentationId"];
    }
  }

  async sendMessage(message: any) {
    this.connection.send(JSON.stringify(message));
  }
}

let screens: {
  [key: string]: Screen;
} = {};
let screensID: {
  [key: string]: Screen;
} = {};

export function supported() {
  if (!getVersion()) return Promise.resolve(false);
  return Promise.race([
    ns("present").call("supported"),
    new Promise(resolve => setTimeout(() => resolve(false), 1000)),
  ]).catch(e => false);
}

export async function getExternalDisplay(): Promise<number | undefined> {
  try {
    const display = await ns("present").call("getExternalDisplay");
    return display?.id;
  } catch (e) {
    return undefined;
  }
}

let inited = false;

export function init() {
  if (inited) {
    return;
  }
  inited = true;
  ns("present").on("closed", id => {
    const screen = screensID[id];
    if (screen) screen.close(true);
  });
  ns("present").call("reset");
  ns("present").on("message", message => {
    const screen = screensID[message.from];
    if (screen) {
      screen.queue.onMessage(message.data);
    }
    if (!message.from) {
      queue.onMessage(message.data);
    }
  });
}

declare const navigator: any;
export async function initWithOptions(query: any = {}, initCb?: (first?: boolean) => void) {
  if (query.mode === "cast") {
    const initCast = (await import("./cast")).default;
    await initCast();
    initCb?.(true);
  } else if (query.mode === "presentation") {
    if (navigator.presentation?.receiver) {
      let curConnection = null;
      let presentTimeout = null;
      let connectionReadyCb = null;
      let connectionReady: Promise<void> = new Promise(resolve => {
        connectionReadyCb = resolve;
      });
      function messageListener(event) {
        try {
          queue.onMessage(JSON.parse(event.data));
        } catch (e) {
          console.warn(e);
        }
      }
      function close() {
        if (presentTimeout) {
          clearTimeout(presentTimeout);
          presentTimeout = null;
        }
        presentTimeout = setTimeout(() => {
          presentTimeout = null;
          if (!curConnection) {
            window.close();
          }
        }, 60 * 1000);
      }
      function setConnection(connection) {
        if (presentTimeout) {
          clearTimeout(presentTimeout);
          presentTimeout = null;
        }
        if (curConnection) {
          curConnection.removeEventListener("message", messageListener);
          curConnection.removeEventListener("close", close);
          curConnection.close();
        }
        curConnection = connection;
        curConnection.addEventListener("message", messageListener);
        curConnection.addEventListener("close", close);
        const isFirst = !!connectionReadyCb;
        connectionReadyCb();
        connectionReadyCb = null;
        initCb?.(isFirst);
      }
      queue.postMessage = async m => {
        await connectionReady;
        if (curConnection) {
          curConnection.send(JSON.stringify(m));
        }
      };
      navigator.presentation.receiver.connectionList.then(list => {
        if (list.connections.length > 0) {
          setConnection(list.connections[list.connections.length - 1]);
        }
        list.addEventListener("connectionavailable", function (event) {
          setConnection(event.connection);
        });
      });
      inited = true;
    }
  }
  init();
  initCb?.(true);
}

export async function createScreen(path: string, castId?: string, opts?: StartScreenOpts) {
  let screen = screens[path];
  if (screen) return screen;

  if (opts?.type === "cloud") {
    return createCloudScreen(path, opts);
  }
  if (opts?.type === "presentation") {
    return createPresentationScreen(path, opts);
  }
  if (!(await supported())) return null;

  init();
  const remoteId = await ns("present").call<string>(
    "start",
    url.resolve(window.location.toString(), path),
    castId,
    opts,
  );
  if (!remoteId) return null;
  opts = opts || {};
  opts.type = "native";
  return (screens[path] = new NativeScreen(remoteId, path, opts));
}

export async function createCloudScreen(path: string, opts?: StartScreenOpts) {
  const cloud = await initCloud();
  const resp = await cloud.service("cloudCast/session").create({
    kid: opts?.kid,
    url: new URL(path, window.location.toString()).toString(),
  });

  opts = opts || {};
  opts.type = "cloud";

  return new CloudScreen(resp.id, path, cloud, opts);
}

export async function createPresentationScreen(path: string, opts?: StartScreenOpts) {
  if (typeof PresentationRequest === "undefined") {
    throw new Error("Presentation API not supported");
  }
  // set query param mode=presentation
  const rpath = path.includes("?") ? `${path}&mode=presentation` : `${path}?mode=presentation`;
  const request = new PresentationRequest([rpath]);
  if (localStorage["lastPresentationId"]) {
    try {
      const info = JSON.parse(localStorage["lastPresentationId"]);
      if (info.path === path) {
        return new PresentationScreen(await request.reconnect(info.id), path, opts);
      }
    } catch (e) {
      console.warn(e);
    }
  }
  const connection = await request.start();
  localStorage["lastPresentationId"] = JSON.stringify({
    id: connection.id,
    path,
  });
  return new PresentationScreen(connection, path, opts);
}

export async function createScreenWithDialog(
  root: Vue,
  path: string,
  castId?: string,
  keep?: boolean,
): Promise<Screen> {
  let screen = screens[path];
  if (screen) return screen;
  return await root.$openDialog(
    // @ts-ignore
    import("./CastSetup.vue"),
    {
      path,
      castId,
      keep,
    },
    {
      contentClass: "editor-dialog",
    },
  );
}

export let queue = new MainMessageQueue("", message =>
  ns("present")
    .call("sendMessage", null, message)
    .catch(e => console.warn(e)),
);

export interface StartScreenOpts {
  keep?: boolean;
  displayId?: number | null;
  kid?: string;
  type?: "cloud" | "native" | "presentation";
  useDefaultDisplay?: boolean;
}
