<template>
  <v-app ref="app" class="w-full">
    <v-snackbar top v-model="error">
      {{ errorMessage }}
      <v-btn text color="pink" @click.native="error = false" v-t="'basic.close'"></v-btn>
    </v-snackbar>
    <v-content v-if="!breakpoint.mdAndDown" style="height: 100%">
      <div class="flex h-full">
        <!-- <v-snackbar top v-model="error">
          {{ errorMessage }}
          <v-btn text color="pink" @click.native="error=false" v-t="'basic.close'"></v-btn>
        </v-snackbar> -->
        <div class="h-full w-min-[465px] loginGradient grid content-between py-5 px-10">
          <div>
            <div class="flex justify-start items-center py-8">
              <template v-if="isDefaultName">
                <img class="w-50" :src="require('~/assets/images/boxsStoreWhite.png')" />
              </template>
              <template v-else>
                <img class="w-13.75" :src="$config.appLogo || require('~/assets/images/icon.png')" />
                <div class="pl-4 text-white font-normal text-5xl">{{ $config.appName }}</div>
              </template>
            </div>
            <div class="subtitle-profit">{{ $t("login.subtitle") }}</div>
          </div>
          <div class="grid gap-3">
            <div class="version-border flex justify-center align-center gap-[8px] mb-5 p-2">
              <div>{{ $t("login.version") }}: {{ versionNumber }} ({{ buildNumber }})</div>
            </div>

            <v-list :class="langToggle == true ? '' : '!hidden'" class="language-box !p-0">
              <div class="grid px-3">
                <div
                  v-for="item in $store.state.locales"
                  :key="item.code"
                  :value="$store.state.locale === item.code"
                  @click="$store.dispatch('changeLang', { code: item.code, $i18n })"
                  class="!text-grey100 py-2 border-bottom cursor-pointer"
                >
                  <span>{{ item.name }}</span>
                </div>
              </div>
            </v-list>

            <a @click="langToggle = !langToggle" class="a-text block !text-white text-center">
              {{ $t("login.languages") }}</a
            >
            <a
              class="a-text block !text-white text-center"
              :href="$store.state.locale==='zh-cn'||$store.state.locale==='zh-hk'||$store.state.locale==='zh-tw'?'https://boxshk.notion.site/boxsStore-65b5f57caa614cdcb4fe3fb7021d25c5':'https://boxshk.notion.site/boxsStore-Help-Center-9c5294dec7e841579c3efb8a04804bf3'"
              target="_blank"
              >{{ $t("login.helpCentre") }}</a
            >
          </div>
        </div>
        <div class="loginSide grid relative justify-center items-center">
          <nuxt></nuxt>
          <div class="absolute max-w-125 bottom-10 right-0 left-0 m-auto">
            <a class="a-text block text-center !text-green200 leading-[16px]" @click="helpToggle = !helpToggle">
              {{ $t("login.forgotPassword") }}</a
            >
            <div
              class="help-message block text-center !text-grey400 font-medium mt-4"
              :class="helpToggle == false ? '!hidden' : ''"
            >
              {{ $t("login.forgotPasswordMessage") }}
            </div>
          </div>
        </div>
      </div>
    </v-content>
    <v-content v-else>
      <div class="h-full grid content-between justify-center pt-9 pb-5 px-7">
        <div class="flex justify-start items-center pt-3 px-5">
          <template v-if="isDefaultName">
            <img class="w-50" :src="require('~/assets/images/boxsStore.png')" />
          </template>
          <template v-else>
            <img class="w-8.25" :src="$config.appLogo || require('~/assets/images/icon.png')" />
            <div class="pl-4 !text-purple100 font-normal text-3xl">{{ $config.appName }}</div>
          </template>
        </div>
        <div class="px-5 -mt-8">
          <nuxt></nuxt>
        </div>
        <div class="flex h-12 gap-5 justify-between items-center">
          <div class="grid gap-4 w-70">
            <a class="forgot-password-mobile !text-green200 leading-[14px] font-400" @click="helpToggle = !helpToggle">
              {{ $t("login.forgotPassword") }}</a
            >
            <div class="help-message !text-grey400 leading-[14px] mb-8" :class="helpToggle == false ? '!hidden' : ''">
              {{ $t("login.forgotPasswordMessage") }}
            </div>
          </div>
          <div>
            <img
              @click="
                () => {
                  menuToggle = true;
                }
              "
              :src="require('~/assets/images/login/maun.png')"
            />
          </div>
        </div>
      </div>
      <transition name="slide">
        <LoginMenu class="menu-box" v-model="menuToggle" @close="closeMenu"> </LoginMenu> 
      </transition>
    </v-content>
  </v-app>
</template>

<script>
import _ from "lodash";
import LoginMenu from "../components/LoginMenu.vue";
import Version from "../mixins/version";

export default {
  mixins: [Version],
  data() {
    return {
      isHydrated: false,
      helpToggle: false,
      langToggle: false,
      menuToggle: false,
    };
  },
  head() {
    return {
      title: this.$config.appName || 'boxsStore',
      htmlAttrs: {
        style: this.$breakpoint.mdAndDown ? "overflow-y: auto;" : "overflow: hidden; height: 100%;",
      },
      bodyAttrs: {
        style: "overflow: hidden; height: 100%;",
      },
    };
  },
  computed: {
    isDefaultName() {
      return !this.$config.appName || this.$config.appName === "boxsStore" || this.$config.appName === "BOXS"
    },
    error: {
      get() {
        return !!this.$store.state.error;
      },
      set(v) {
        if (!v) {
          this.$store.commit("SET_ERROR");
        }
      },
    },
    breakpoint() {
      // just an example, could be one specific value if that's all you need
      return this.isHydrated
        ? this.$vuetify.breakpoint
        : {
            mdAndDown: false,
          }; // "empty" $breakpoint object with initial values
    },
    errorMessage() {
      return this.$store.state.error;
    },
  },
  components: {
    LoginMenu,
  },
  async mounted() {
    this.isHydrated = true;
    document.addEventListener("gesturestart", this.gesture);
    this.$store.commit("INIT_LOCAL");
  },
  beforeDestroy() {
    document.removeEventListener("gesturestart", this.gesture);
  },
  methods: {
    gesture(e) {
      e.preve, ntDefault();
    },
    closeMenu() {
      this.menuToggle = false;
    },
  },
};
</script>

<style scoped>
button {
  border: 1px solid black;
}

.loginGradient {
  background: linear-gradient(
      180deg,
      rgba(62, 57, 106, 0.4) 0%,
      rgba(58, 52, 98, 0.36875) 19.27%,
      rgba(0, 0, 0, 0) 100%
    ),
    rgba(128, 117, 242, 0.9);
  /* min-width: 422px; */
}

.loginSide {
  background: white;
  width: 100%;
}

.loginSide .v-card {
  border: none;
}

.app-name {
  padding-left: 14px;
  font-style: normal;
}

.version-border {
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.7);
}

.subtitle-profit {
  /* font-family: "Barlow"; */
  font-style: normal;
  font-weight: 450;
  font-size: 26px;
  line-height: 40px;
  color: #ffffff;
}

.a-text {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  text-underline-offset: 2px;
}

.help-message {
  font-size: 12px;
}

.language-box {
  box-sizing: border-box;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);

  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
}

.border-bottom:not(:last-child) {
  border-bottom: 1px solid rgba(224, 224, 224, 0.5);
}

.forgot-password-mobile {
  /* font-family: 'Barlow'; */
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 2;
}

.slide-leave-active,
.slide-enter-active {
  transition: 0.3s ease-in-out;
}

.slide-enter-from {
  transform: translateY(-100%);
}

.slide-leave-to {
  transform: translateY(100%);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

.menu-box {
  animation: 0.3s ease-in-out alternate slidein;
  /* animation-duration: 0.5s; */
  animation-name: menu-in;
  z-index: 9;
  /* animation-direction: alternate; */
}

@keyframes menu-in {
  from {
    /* bottom: -200px; */
  }

  to {
    bottom: 0;
  }
}

@supports (-webkit-touch-callout: none) {
  #app >>> .v-application--wrap {
    min-height: -webkit-fill-available !important; /* safari */
  }
}
</style>
