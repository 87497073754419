
import { Component, Prop, Watch, getID, mixins } from "@feathers-client";
import Dialog from "domore-table/mixins/Dialog";
import NumPadNew from "@feathers-client/components/NumPadNew.vue";
import { Currencies } from "@feathers-client/currency";
import { LineItem } from "~/common/posLineItem";
import { Order } from "~/common/pos";

@Component({ components: { NumPadNew } })
export default class ManualAdjustDialog extends mixins(Dialog()) {
  @Prop()
  item: LineItem;

  @Prop()
  order: Order;

  selectedTab = "singlePrice";
  adjustment = "";
  placeholder = "";

  mounted() {
    this.reset();
  }

  get tabs() {
    return ["singlePrice", "fixedAdjust", "percentAdjust"];
  }

  get max() {
    switch (this.selectedTab) {
      case "singlePrice":
        return 10000000;
      case "fixedAdjust":
        return this.item.unitPrice;
      case "percentAdjust":
        return 100;
    }
  }

  get currencySymbol() {
    return Currencies[this.$pos.currency]?.symbol ?? '$'
  }

  @Watch("selectedTab")
  reset() {
    this.adjustment = "";
    switch (this.selectedTab) {
      case "singlePrice":
        this.placeholder = String(this.item?.unitPrice);
        break;
      case "fixedAdjust":
        this.placeholder = this.item?.manualPercentDiscount ? "0" : String(Math.abs(this.item?.manualDiscount));
        break;
      case "percentAdjust":
        this.placeholder = String(this.item?.manualPercentDiscount);
        break;
    }
  }

  async confirm() {
    switch (this.selectedTab) {
      case "singlePrice":
        if (Number(this.adjustment) !== this.item?.unitPrice) {
          this.item.unitPrice = Number(this.adjustment);
        }
        break;
      case "fixedAdjust":
        this.item.manualPercentDiscount = 0;
        this.item.productPrice = this.adjustment ? this.item.unitPrice - Number(this.adjustment) : null;
        break;
      case "percentAdjust":
        this.item.manualPercentDiscount = this.adjustment ? Number(this.adjustment) : 0;
        this.item.productPrice = this.adjustment ? this.item.unitPrice * (100 - Number(this.adjustment)) / 100 : null;
        break;
    }

    this.modalResult(this.item);
  }
}
