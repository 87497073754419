import { ns } from "../messageQueue";
import { EventEmitter } from "events";
import { getVersion } from "../nativeIntegrations";
import type Vue from "vue";
import { EspDevice, EspDeviceConfig } from "../esp32";
import { install as initBluetooth } from "../utils/bluetoothPolyfill";
import { getDriverNameDict } from "./usb";

export interface SerialPortSelection {
  type: "serial" | "webSerial" | "espSerial";
  port?: string;
  espConfig?: EspDeviceConfig;
  device?: any;
  vendorId?: string;
  productId?: string;
}

export interface SerialPortInfo {
  path: string;
  vendorId: string;
  productId: string;
  serialNumber: string;
  manufacturer: string;
  locationId: string;
}

export function getDevices() {
  return ns("serial").call<{
    devices?: SerialPortInfo[];
    status: boolean;
  }>("getDevices");
}

export default class SerialDevice extends EventEmitter {
  constructor(
    public name: string,
    public id: string,
  ) {
    super();
    this.handle = ns("serial").on(`data-${this.id}`, this.onData);
    this.handleClose = ns("serial").on(`close-${this.id}`, this.onClose);
  }

  closed = false;
  handle: string;
  handleClose: string;

  onData = (buf: Buffer) => {
    this.emit("data", buf);
  };

  onClose = () => {
    if (this.closed) return;
    this.closed = true;
    ns("serial").off(this.handle);
    ns("serial").off(this.handleClose);
    this.handle = null;
    this.handleClose = null;
    this.emit("close");
  };

  async send(data: Buffer) {
    const buf = Array(data.length);
    for (let i = 0; i < data.length; i++) buf[i] = data[i];
    await ns("serial").call("send", this.id, buf);
  }

  async close() {
    if (this.closed) return;
    this.closed = true;
    ns("serial").off(this.handle);
    ns("serial").off(this.handleClose);
    this.handle = null;
    this.handleClose = null;
    this.emit("close");
    await ns("serial").call("close", this.id);
  }
}

export interface SerialConfig {
  baudRate?: number;
  dataBits?: 5 | 6 | 7 | 8;
  stopBits?: 1 | 1.5 | 2;
  parity?: "odd" | "even";
}

export async function connect(name, config?: SerialConfig) {
  return new SerialDevice(name, await ns("serial").call("connect", name, config));
}

export function supported(): Promise<boolean> {
  if (!getVersion()) return Promise.resolve(false);
  return Promise.race([
    ns("serial").call("supported"),
    new Promise(resolve => setTimeout(() => resolve(false), 1000)),
  ]).catch(e => false);
}

export async function portPicker(
  self: Vue,
  path?: SerialPortSelection,
  humanInteraction?: boolean,
  useNative?: boolean,
  espSerialMode: "TurnCloud" | "Octopus" = "TurnCloud",
  driverName?: string,
  log?: (msg: string) => void,
): Promise<SerialPortSelection> {
  if (path?.type === "webSerial") {
    if (!humanInteraction) {
      await self.$openDialog(
        // @ts-ignore
        import("@feathers-client/components-internal/ConfirmDialog.vue"),
        {
          title: "Connect Web Serial",
        },
        {
          maxWidth: "400px",
        },
      );
    }
    try {
      // @ts-ignore
      const device = await navigator.serial.requestPort({});
      return {
        type: "webSerial",
        device,
      };
    } catch (e) {
      self.$store.commit("SET_ERROR", e.message);
    }
  }
  if (path?.type === "espSerial") {
    try {
      await initBluetooth();
      log?.("Connecting to ESP32");
      const esp = await EspDevice.create(path.espConfig, self, log);
      await esp.connect();
      if (esp.operationMode !== espSerialMode) {
        await esp.sendCmd({
          SetOperationMode: {
            [espSerialMode]: {},
          },
        });
      }
      return {
        type: "espSerial",
        espConfig: path.espConfig,
        device: esp,
        vendorId: esp.vid.toString(),
        productId: esp.pid.toString(),
      };
    } catch (e) {
      self.$store.commit("SET_ERROR", e.message);
    }
  }
  let devices = { devices: [] as SerialPortInfo[], status: false } as Awaited<ReturnType<typeof getDevices>>;
  if (await supported()) {
    try {
      devices = await getDevices();
    } catch (e) {
      console.warn(e);
    }
  }
  if (path?.type === "serial") {
    for (let device of devices.devices) {
      if (device.path === path.port) {
        if(driverName) {
          getDriverNameDict()[device.path] = driverName;
        }
        return path;
      }
    }
    for (let device of devices.devices) {
      if (device.productId && device.productId === path.productId && device.vendorId === path.vendorId) {
        if(driverName) {
          getDriverNameDict()[device.path] = driverName;
        }
        return {
          type: "serial",
          port: device.path,
          vendorId: device.vendorId,
          productId: device.productId,
        };
      }
    }
  }

  if (humanInteraction) {
    const device = await self.$openDialog(
      // @ts-ignore
      import("../dialogs/SerialDevicePicker.vue"),
      {
        devices: devices.devices,
        useNative,
        espSerialMode,
        driverNameDict: getDriverNameDict(),
      },
      {
        maxWidth: "80%",
        contentClass: "editor-dialog",
      },
    );

    if(driverName && device) {
      getDriverNameDict()[device.path] = driverName;
    }

    return device;
  }
}
