import { Vue, Component, Prop } from "@feathers-client";
import { VueClass } from "vue-class-component/lib/declarations";
import momentTZ from "moment-timezone";
import moment from "moment";
import { CacheStorage, getStorage } from "@feathers-client/storage";
import msgpack5 from "msgpack5";

export interface LoggerType {
  log(...str: string[]): void;
  writeLog(buf: string): void;
  flushLogBuffer(): void;
  syncLogs(): Promise<void>;
  getLogStorage(): Promise<CacheStorage>;
  getOldLogStorage(): Promise<CacheStorage>;
  downloadLogs(start?: string, end?: string, type?: "zip" | "merged"): Promise<void>;
  downloadLog(key: string): Promise<void>;
  viewLog(): Promise<void>;
  flushLogs(): Promise<void>;
}

export interface LoggerOptions {
  timezone?: string;
  formatName?(this: any): string;
  name: string;
}

const msgpack = msgpack5();

export function Logger(loggerOpts: LoggerOptions) {
  const lastLogName = `last${loggerOpts.name}Log`;
  const storageName = `${loggerOpts.name}Logs`;
  const oldStorageName = `old${loggerOpts.name}Logs`;

  @Component
  class Logger extends Vue {
    _logBuffer: Buffer;
    _logOffset: number;
    _logDirty: boolean;
    _logDirtyTimer: any;
    _logStart: number;
    _logStorage: CacheStorage;
    _oldLogStorage: CacheStorage;

    @Prop()
    getInfo: () => {
      shopId?: string;
      shopName?: string;
      cashierId?: string;
      cashierName?: string;
    };

    created() {
      window.addEventListener("beforeunload", this._unloadLog);
      this._flushOldLog();
      this._saveDraftLog();
    }

    beforeDestroy() {
      window.removeEventListener("beforeunload", this._unloadLog);
      if (this._logDirtyTimer) {
        clearTimeout(this._logDirtyTimer);
        this._logDirtyTimer = null;
      }
    }

    _unloadLog() {
      if (this._logOffset) {
        const logName = this._formatLogName();
        const info = { logName };
        localStorage[lastLogName] =
          `${JSON.stringify(info)}\r\n` +
          new TextDecoder().decode(this._logBuffer.slice(0, this._logOffset)) +
          `\r\n----End of log ${this._formatLogName()}----`;
        this._logOffset = 0;
      }
    }

    async _flushOldLog() {
      if (localStorage[lastLogName]) {
        let log: string = localStorage[lastLogName];
        delete localStorage[lastLogName];

        let logName = this._formatLogName();

        const firstLineIdx = log.indexOf("\r\n");
        if (firstLineIdx !== -1 && log[0] === "{") {
          try {
            const json = JSON.parse(log.slice(0, firstLineIdx));
            logName = json.logName;
            log = log.slice(firstLineIdx + 2);
          } catch (e) {
            console.warn(e);
          }
        }

        if (await this._saveLogBuffer(new TextEncoder().encode(log), logName)) return;
      }
      await this.syncLogs();
    }

    async _saveDraftLog() {
      if (this._logDirtyTimer) {
        clearTimeout(this._logDirtyTimer);
        this._logDirtyTimer = null;
      }
      if (this._logDirty) {
        localStorage[lastLogName] =
          new TextDecoder().decode(this._logBuffer.slice(0, this._logOffset)) +
          `\r\n----End of log ${this._formatLogName()}----`;
        this._logDirty = false;
      }
      if (this._logOffset && Date.now() - this._logStart > 24 * 60 * 60 * 1000) {
        this.flushLogBuffer();
      }
      this._logDirtyTimer = setTimeout(
        () => {
          this._logDirtyTimer = null;
          this._saveDraftLog();
        },
        60 * 5 * 1000,
      ); // save log every 5min
    }

    log(...str: string[]) {
      console.log(...str);
      const formattedDate = loggerOpts?.timezone
        ? momentTZ().tz(loggerOpts.timezone).format("YYYY-MM-DDTHH:mm:ss.SSSZZ")
        : moment().format("YYYY-MM-DDTHH:mm:ss.SSSZZ");
      this.writeLog(`${formattedDate} [JS] ${str.join(" ")}\r\n`);
    }

    writeLog(buf: string, noFlush?: boolean) {
      const encoded = new TextEncoder().encode(buf);
      if (!this._logBuffer || this._logOffset + encoded.length > this._logBuffer.length) {
        const last = this.flushLogBuffer();
        if (encoded.length > this._logBuffer.length) {
          const fullBuf = Buffer.concat([
            ...(last.isContinue ? [Buffer.from("----Continue----\r\n")] : []),
            Buffer.from(`----Start of log ${last.logName}----\r\n`),
            Buffer.from(encoded),
            Buffer.from(`\r\n----End of log ${last.logName}----\r\n\r\n----Continue----\r\n`),
          ]);
          this._saveLogBuffer(fullBuf, last.logName + "_large_cont");
          return;
        }
      }
      const logBuffer = this._logBuffer;
      for (let i = this._logOffset, j = 0; j < encoded.length; i++, j++) {
        logBuffer[i] = encoded[j];
      }
      this._logOffset += encoded.length;
      this._logDirty = true;
    }

    sessionDate: Date;

    flushLogBuffer() {
      const logName: string = this._formatLogName();
      const isContinue = !!this._logOffset;
      let isNew = false;
      if (this._logOffset) {
        const buf = Buffer.concat([
          Buffer.from(Uint8Array.prototype.slice.call(this._logBuffer, 0, this._logOffset)),
          Buffer.from(`\r\n----End of log ${logName}----\r\n----Continue----\r\n`),
        ]);
        this._saveLogBuffer(buf, logName + "_cont");
      }
      this._logOffset = 0;
      if (!this._logBuffer) {
        isNew = true;
        this._logBuffer = Buffer.alloc(64 * 1024);
        if (isContinue) {
          this.writeLog(`----Continue----\r\n`);
        }
        this.writeLog(`----Start of log ${logName}----\r\n`);
      }
      this._logStart = Date.now();
      return {
        logName,
        isContinue,
        isNew,
      };
    }

    async getLogStorage() {
      if (!this._logStorage) {
        this._logStorage = await getStorage(storageName);
      }
      return this._logStorage;
    }

    async getOldLogStorage() {
      if (!this._oldLogStorage) {
        this._oldLogStorage = await getStorage(oldStorageName);
      }
      return this._oldLogStorage;
    }

    async _saveLogBuffer(buf: Uint8Array, logName = this._formatLogName()) {
      if (buf.length) {
        try {
          const storage = await this.getLogStorage();
          await storage.saveFile(`${logName}.log`, Buffer.from(buf));
          await this.syncLogs();
          return true;
        } catch (e) {
          console.warn(e);
        }
      }
    }

    async syncLogs() {
      const storage = await this.getLogStorage();
      const oldStorage = await this.getOldLogStorage();

      for (let key of await storage.listFiles()) {
        try {
          const data = await storage.loadFile(key, null, null);
          await this.$feathers.service("cloudLog/logs").create(
            msgpack
              .encode({
                module: loggerOpts.name,
                path: key,
                data,
              })
              .slice(),
          );

          await oldStorage.saveFile(key, data);
          await storage.deleteFile(key);
        } catch (e) {
          console.warn(e);
        }
      }
    }

    _formatLogName() {
      const extraDetails = loggerOpts?.formatName?.call(this);
      const { shopId, cashierId } = this.getInfo?.() || {};

      if (!this.sessionDate) {
        this.sessionDate = new Date();
      }

      return `${moment(this.sessionDate).format("YYYY-MM-DD-HH-mm-ss")}_${moment().format("YYYY-MM-DD-HH-mm-ss")}_${
        shopId || "unknown"
      }_${cashierId || "unknown"}_${loggerOpts.name || "unknown"}${extraDetails ? "_" + extraDetails : ""}`;
    }

    async flushLogs() {
      this.flushLogBuffer();
      await this.syncLogs();
    }

    async downloadLogs(start?: string, end?: string, type: "zip" | "merged" = "zip") {
      const JSZip = (await import("jszip")).default;
      const zip = new JSZip();
      const storage = await this.getLogStorage();
      const oldStorage = await this.getOldLogStorage();
      const logName = `${loggerOpts.name}_${start || "all"}_${end || "all"}`;

      if (type === "merged") {
        const blobs: Blob[] = [];

        for (let file of await oldStorage.listFiles(start, end)) {
          blobs.push(new Blob([await oldStorage.loadFile(file, null, null)], { type: "text/plain" }));
          blobs.push(new Blob(["\r\n"], { type: "text/plain" }));
        }

        for (let file of await storage.listFiles(start, end)) {
          blobs.push(new Blob([await storage.loadFile(file, null, null)], { type: "text/plain" }));
          blobs.push(new Blob(["\r\n"], { type: "text/plain" }));
        }

        await downloadFile(blobs, `${logName}.log`, "text/plain");
      } else {
        for (let file of await storage.listFiles(start, end)) {
          zip.file("pending/" + file, await storage.loadFile(file, null, null));
        }

        for (let file of await oldStorage.listFiles(start, end)) {
          zip.file("old/" + file, await oldStorage.loadFile(file, null, null));
        }

        const blob = await zip.generateAsync({ type: "blob" });
        await downloadFile([blob], `${logName}.zip`, blob.type);
      }
    }

    async downloadLog(key: string) {
      const storage = await this.getLogStorage();
      const oldStorage = await this.getOldLogStorage();
      try {
        const data = await storage.loadFile(key, null, null);
        await downloadFile([data], key, "text/plain");
        return;
      } catch (e) {}

      try {
        const data = await oldStorage.loadFile(key, null, null);
        await downloadFile([data], key, "text/plain");
      } catch (e) {}
    }

    async viewLog() {
      return await this.$openDialog(
        // @ts-ignore
        import("./LoggerDialog.vue"),
        {
          manager: this,
        },
        {
          maxWidth: "80%",
          contentClass: "editor-dialog",
        },
      );
    }
  }

  return Logger as VueClass<LoggerType & Vue>;
}

export async function downloadFile(blobs: BlobPart[], name: string, mime?: string) {
  if ((window as any).downloadFile) {
    return await (window as any).downloadFile(blobs, name, mime);
  }
  const blob = new Blob(blobs, { type: mime });
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style.display = "none";
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = name;
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
}
