
import _ from "lodash";
import Editor from "@schemaEditor/Editor.vue";
import { Component, Vue, Prop, FindType } from "@feathers-client";

@Component({
  components: {
    Editor,
  },
})
export default class Shipping extends Vue {

}
