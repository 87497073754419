
import { Component, Prop, Vue, Watch, mixins, FindType, VModel, checkID, getID, PropSync, Ref } from "@feathers-client";
import { PosOrder } from "~/plugins/pos/order";
import _ from "lodash";
import BBPOS from "~/mixins/bbpos";
import AllinpayPOS from "~/mixins/allinpay";
import type { AllinpayInfo } from "~/mixins/allinpay";
import { RazerPayHeader, RazerPayManager, RazerPayMessage } from "pos-printer/razerpay";
import '~/plugins/payments'

@Component({})
export default class Done extends mixins(BBPOS, AllinpayPOS) {
  get voidPermission() {
    return !(this.$store.getters.role === "pos" && !this.$store.getters.permissionDict.void);
  }

  @Prop()
  session: PosOrder;

  @Prop({ required: false, default: false })
  isCreateOrder: boolean;

  currentPayment: FindType<'shop/payments'> | null = null;

  loading = false;

  get isDone() {
    return this.session.status === "confirmed" || this.session.status === "done";
  }

  get isVoid() {
    return this.session.status === "void";
  }

  get isRefunded() {
    return this.session.paymentStatus === "refunded";
  }

  get isCancelled() {
    return this.session.status === "cancelled";
  }

  get frontendPayLink() {
    return new URL(`https://${this.$shop?.posInvoiceDomain ?? window.location.hostname}/o/${this.$props.session._id}`);
  }

  async editOrder() {
    this.$emit('goList');
    this.session.status = "pending"
  }

  async cancelOrder() {
    const paymentMethods = this.$pos.paymentMethods;
    const c = await this.$openDialog(
      import("@feathers-client/components-internal/ConfirmDialog.vue"),
      {
        title: this.$t("basic.doYouWantToCancel"),
      },
      {
        maxWidth: "400px",
      },
    );
    if (!c) return;

    this.loading = true;
    try {
      for (const payment of this.session.payments) {
        const paymentMethod = paymentMethods.find(e=>e._id === payment.method)
        if (paymentMethod) {
          switch (paymentMethod.type) {
            case 'bbpos': {
              await this.refundBBMSLPayment(payment)
              break;
            }
            case 'allinpay': {
              await this.refundAllinpayPayment(payment)
              break;
            }
            case 'razer': {
              await this.refundRazerPayment(payment)
              break;
            }
            case 'turnCloud': {
              await this.refundTurnCloudPayment(payment)
              break;
            }
            default: {
              break;
            }
          }
        }
      }
      const sessionData = await this.$feathers.service("shop/orders/cancel").create({
        _id: this.session._id,
        paymentStatus: "refunded",
      });
      await this.session.reload();
      if (this.$pos.cashier.printOnFinish) {
        await this.printInvoice();
      }
    } catch (e) {
      console.warn(e);
      this.$store.commit("SET_ERROR", e.message);
    } finally {
      this.loading = false;
    }
  }

  async refundBBMSLPayment(payment: FindType<"shop/payments">) {
    const resp = await this.callPOS(
      {
        apiVersion: 21,
        transactionType: "QUERY_LAST_TRANSACTION",
        invoiceNumber: payment._id,
      },
      undefined,
      {
        checkResponse: false,
      },
    );
    const txnid = resp.WAPIResult?.receiptData?.[0]?.txnid?.[0];
    console.log(resp, txnid);
    if (resp?.WAPIResult?.status?.[0] === "SUCCESS") {
      const resp = await this.callPOS({
        apiVersion: 21,
        transactionType: "VOID",
        transactionId: txnid,
      });
      console.log(resp);
      return true;
    } else if (resp?.WAPIResult?.status?.[0] === "VOIDED") {
      return true;
    }
  }

  async refundAllinpayPayment(payment: FindType<"shop/payments">) {
    let allinpayInfo: AllinpayInfo;
    try {
      allinpayInfo = await this.getAllinpayInfo();
    } catch (e) {
      throw new Error("Cannot connect to terminal");
    }

    if (allinpayInfo.TER_ID !== payment.metadata?.TER_ID) {
      throw new Error("Please refund on original terminal with ID" + payment.metadata?.TER_ID);
    }

    if (allinpayInfo.MERCH_ID !== payment.metadata?.MERCH_ID) {
      throw new Error("Please refund on original terminal with merchant ID" + payment.metadata?.MERCH_ID);
    }

    let resp;

    try {
      resp = await this.callAllinpayPOS({
        BUSINESS_ID: "600000002", // BUSI_QUERY_ORDER_RESULT
        TRANS_TRACE_NO: String(payment._id),
      });
    } catch (e) {
      if (e.REJCODE === "1FF") {
        return false;
      }
    }
    let refunded = resp.TRANS_STATE === "VOID" || resp.TRANS_STATE === "RETURNED";
    if (resp.TRANS_STATE === "PAIED" || resp.CARD_ORGN) {
      if (resp.CARD_ORGN) {
        // try to find refund order
        try {
          const resp = await this.callAllinpayPOS({
            BUSINESS_ID: "600000002", // BUSI_QUERY_ORDER_RESULT
            TRANS_TRACE_NO: String(payment._id) + "REF",
          });
          if (resp) {
            refunded = true;
          }
        } catch (e) {}
      }
    }

    if (!refunded) {
      const resp = await this.callAllinpayPOS({
        BUSINESS_ID: payment.subMethod === "CREDIT" ? "200100001" : "200300001",
        AMOUNT: Math.floor(payment.amountInt / 100)
          .toString()
          .padStart(12, "0"),
        ORIG_TRACE_NO: payment.metadata.TRACE_NO,
        TRANS_TRACE_NO: String(payment._id) + "REF",
        CURRENCY: payment.currency || "HKD",
      });

      return true;
    }
  }

  async refundRazerPayment(payment: FindType<"shop/payments">) {
    const razer = this.$paymentManager.getRazer();
    await razer.waitReady(true);
    const cancelHeader = new RazerPayHeader();
    cancelHeader.transactionCode = "40";
    const message = payment.metadata as RazerPayMessage;
    const cancelPayload: Partial<RazerPayMessage> = {
      header: cancelHeader,
      body: {
        payAccountId: "00000000000000000000", // This field is use in another project
        transactionId: Buffer.from(String(payment._id), 'hex').toString('ascii'),
        invoiceNo: message?.body?.invoiceNo,
        extendedInvoiceNo: message?.body?.customDataParsed?.walletInvoiceNumber,
      },
    };
    const resp = await razer.doTransaction(cancelPayload);
    return true;
  }

  async refundTurnCloudPayment(payment: FindType<"shop/payments">) {
    const turnCloud = this.$paymentManager.getTurnCloud();
    await turnCloud.waitReady(true);
    const resp = await turnCloud.doTransaction({
      payType: payment.metadata?.payType,
      actionType: "refund",
      orderNo: String(payment._id),
    });

    return true;
  }

  fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
    } catch (err) {}

    document.body.removeChild(textArea);
  }

  copyTextToClipboard(text) {
    if (!navigator.clipboard) {
      this.fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(
      function () {},
      function (err) {},
    );
  }

  copyUrl() {
    this.copyTextToClipboard(this.frontendPayLink);
  }

  async endSession() {
    // TODO: end order
    // this.loading = true;
    // try {
    //     await this.session.atomic({
    //         endTime: new Date(),
    //     });
    //     this.$emit('close');
    // } finally {
    //     this.loading = false;
    // }
  }

  async printInvoice() {
    // TODO: print order
    await this.session.printInvoice();
  }

  async copyOrder() {
    this.$emit("nextOrder", null, {
      clone: this.session._id,
    });
  }

  autoFinishTimer: any;

  mounted() {
    if (this.$pos.kioskMode && this.$pos.cashier.kioskFinishTimeout) {
      this.counter = this.$pos.cashier.kioskFinishTimeout;
      this.autoFinishTimer = setInterval(this.tick, 1000);
    }
    this.currentPayment = this.session.payments?.slice?.(-1)?.[0] ?? null;
  }

  @Watch('session.payments')
  updatePayments() {
    this.currentPayment = this.session.payments?.slice?.(-1)?.[0] ?? null;
  }

  beforeDestroy() {
    if (this.autoFinishTimer) {
      clearInterval(this.autoFinishTimer);
      this.autoFinishTimer = null;
    }
  }

  counter = 0;

  async tick() {
    if (this.counter) {
      this.counter--;
      if (!this.counter) {
        this.$root.$emit("kioskReset");
      }
    }
  }

  get counterText() {
    return this.counter ? ` (${this.counter})` : "";
  }
}
