
import { Component, Vue, Prop, Watch } from "@feathers-client";

@Component
export default class Percentage extends Vue {
  @Prop({ default: 0 }) latestData!: number;
  @Prop({ default: 0 }) lastData!: number;

  get percentageChange() {
    if (!this.latestData && !this.lastData) {
      return 0
    }
    if (this.latestData - this.lastData === 0) {
      return 0;
    }
    const values = ((this.latestData - this.lastData) / this.lastData) * 100;
    return parseFloat(values.toFixed(1));
  }
}
