import { ns } from "./messageQueue";
import _ from "lodash";
import Vue from "vue";

export function getVersion() {
  const version = window.navigator.userAgent.match(/BOXSPOS(?:|Kiosk)\/(\d+\.\d+\.\d+)/);
  return version && version[1];
}

export function isKiosk() {
  const version = window.navigator.userAgent.match(/BOXSPOSKiosk\/(\d+\.\d+\.\d+)/);
  return version && version[1];
}

let inited = false;
let context;
let currentDialog = null;

export interface NativeBluetoothDevice {
  deviceName?: string;
  deviceId?: string;
}

class PickerDialog {
  devices: NativeBluetoothDevice[] = [];
  constructor() {
    currentDialog = this;
    this.devices = [];
    this.show()
      .then(r => {
        ns("bluetooth").call("pick", r || "");
      })
      .catch(e => {
        console.warn(e);
        ns("bluetooth").call("pick", "");
      })
      .then(() => {
        return new Promise(resolve => setTimeout(resolve, 1000));
      })
      .finally(() => {
        currentDialog = null;
      });
  }

  async show() {
    // @ts-ignore
    return await context.$openDialog(
      import("./dialogs/BluetoothSelector.vue"),
      {
        devices: this.devices,
      },
      {
        maxWidth: "min(600px,80vw)",
        contentClass: "h-full",
      },
    );
  }

  addDevices(list: NativeBluetoothDevice[]) {
    _.each(list, item => {
      const cur = this.devices.find(it => it.deviceId === item.deviceId);
      if (cur) {
        _.assign(cur, item);
      } else {
        this.devices.push(item);
      }
    });
  }
}

let pickerCb: (devices: NativeBluetoothDevice[]) => void;

export function setBluetoothPicker(updateCb: (devices: NativeBluetoothDevice[]) => void) {
  pickerCb = updateCb;
}

export function sendBluetoothPick(device: NativeBluetoothDevice) {
  if (!getVersion()) return;
  ns("bluetooth").call("pick", device?.deviceId || "");
}

interface BluetoothLock {
  tag: string;
  resolve?: () => void;
  reject?: (err: any) => void;
  lockCb?: () => void;
  unlockCb?: () => void;
}

const locks: BluetoothLock[] = [];
const lockMap: { [tag: string]: BluetoothLock } = {};
let activeLock: BluetoothLock;

export function lockBluetooth(tag: string, lockCb?: () => void, unlockCb?: () => void) {
  if (!activeLock) {
    activeLock = {
      tag,
      lockCb,
      unlockCb,
    };
    lockCb?.();
    return;
  }
  unlockBluetooth(tag);
  return new Promise<void>((resolve, reject) => {
    const cur = {
      tag,
      resolve,
      reject,
      lockCb,
      unlockCb,
    };
    lockMap[tag] = cur;
    locks.push(cur);
  });
}

export function unlockBluetooth(tag: string) {
  if (activeLock && activeLock.tag === tag) {
    activeLock.unlockCb?.();
    activeLock = null;
    const next = locks.shift();
    if (next) {
      delete lockMap[next.tag];
      activeLock = next;
      next.lockCb?.();
      next.resolve();
    }
    return;
  }
  const cur = lockMap[tag];
  if (cur) {
    delete lockMap[tag];
    cur.reject?.(new Error("unlock"));
    const idx = locks.findIndex(it => it === cur);
    if (idx !== -1) {
      locks.splice(idx, 1);
    }
  }
}

export function init(ctx: Vue) {
  if (inited) return;
  inited = true;
  context = ctx;

  if (!getVersion()) return;

  ns("bluetooth").on<{
    deviceList: NativeBluetoothDevice[];
  }>("picker", args => {
    if (pickerCb) {
      pickerCb(args.deviceList);
      return;
    }
    if (!currentDialog) {
      currentDialog = new PickerDialog();
    }
    currentDialog.addDevices(args.deviceList);
  });
}
