var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex flex-col text-[var(--fg-color)]",class:`variant-${_vm.variant}`},[_c('div',{staticClass:"d-flex flex-col gap-y-6 text-[var(--fg-color)] pb-6",class:{ 'items-center': _vm.titleDisplayMode === 'center', 'items-end': _vm.titleDisplayMode === 'right' },style:({
      '--fg-color': _vm.fontColor,
    })},[(_vm.$td(_vm.title))?_c('div',{staticClass:"font-bold text-4xl <md:text-xl pb-2",domProps:{"innerHTML":_vm._s(_vm.$htmlContent(_vm.$td(_vm.title)))}}):_vm._e(),_vm._v(" "),(_vm.$td(_vm.remarks))?_c('div',{staticClass:"font-bold text-xl mb-6 <md:text-base",domProps:{"innerHTML":_vm._s(_vm.$htmlContent(_vm.$td(_vm.remarks)))}}):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"d-flex flex-col gap-y-10"},_vm._l((_vm.items),function(item,idx){return _c('div',{staticClass:"grid grid-cols-2 gap-x-5 text-xl items-center",style:({
        '--progress-color': _vm.progressColor,
        '--progress-bg-color': _vm.bgProgressColor,
        '--title-color': _vm.titleColor,
        '--content-color': _vm.contentColor,
      })},[_c('div',{staticClass:"text-[var(--title-color)] text-right"},[_vm._v(_vm._s(_vm.$td(item.title)))]),_vm._v(" "),_c('div',{staticClass:"rounded-full bg-[var(--progress-bg-color)]"},[_c('div',{class:`transition-all duration-1000 rounded-full px-4 py-3 bg-[var(--progress-color)] text-[var(--content-color)] whitespace-nowrap`,style:({ width: 0 }),attrs:{"id":`progressBar${idx}`}},[_vm._v("\n          "+_vm._s(_vm.$td(item.desc))+"\n        ")])])])}),0)])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }