

import _ from "lodash"
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import moment from 'moment'
import { Order } from "@common/pos";
import { DiscountType } from '@common/common'
import { FindPopRawType, FindType } from '@feathers-client'
import QrcodeStream from "@feathers-client/qrcode-scanner/components/QrcodeStream.vue";

@Component({
    components:{
        QrcodeStream,
    }    
})
export default class OrderSystemDiscount extends Vue {
    @Prop()
    session: Order;

    customTips = false;
    tipsStr = '';
    couponCode: string = '';
    scanning: boolean = false;

    get priceModifyPermission() {
        return !(this.$store.getters.role === 'pos' && !this.$store.getters.permissionDict.price)
    }
    get sumWithoutManualInt() {
        return _.sumBy(this.session.charges, c => c.type !== 'manualDiscount' ? c.amountInt : 0)
    }

    get sumWithoutManualHuman() {
        return this.$pos.getHumanNumber(this.sumWithoutManualInt);
    }

    get presetTips() {
        return [
            { _id: -1, name: '-1' },
            { _id: -2, name: '-2' },
            { _id: -5, name: '-5' },
            { _id: -10, name: '-10' },
            { _id: -20, name: '-20' },
            { _id: -50, name: '-50' },
            { _id: -100, name: '-100' },
            { _id: 1, name: '1' },
            { _id: 2, name: '2' },
            { _id: 5, name: '5' },
            { _id: 10, name: '10' },
            { _id: 20, name: '20' },
            { _id: 50, name: '50' },
            { _id: 100, name: '100' },
        ].filter(it => this.$pos.fromHumanNumberToRaw(it._id) + this.sumWithoutManualInt >= 0)
    }

    get isCustomTips() {
        return this.tips && !this.presetTips.find(it => it._id === this.tips);
    }

    get tips() { return this.$pos.getHumanNumber(this.session.manualDiscountInt) }
    set tips(v) {
        v = !isNaN(+v) ? +v : 0;
        this.session.beginEdit();
        this.session.manualDiscountInt = this.$pos.fromHumanNumberToRaw(v);
        this.customTips = false;
    }

    get manualDiscounts() {
        return this.session.discountCal?.discounts?.map?.(it => it.discount) ?? [];
    }

    addManual(item: DiscountType) {
        this.session.beginEdit();
        this.session.discountCal.addDiscounts([item]);
    }

    removeManual(item: DiscountType) {
        this.session.beginEdit();
        this.session.discountCal.removeDiscounts([item]);
    }

    clearManual(item: DiscountType) {
        this.session.beginEdit();
        this.session.discountCal.removeDiscounts(this.$pos.manualDiscounts);
    }

    clearDiscount() {
        this.session.beginEdit();
        this.session.manualDiscountInt = 0;
    }

    coupon: DiscountType;
    couponNotFound: boolean = false;

    async addCoupon(item: DiscountType) {
        try {
            this.coupon = (await this.$feathers.service('shop/product/discounts').find({
                query: {
                    couponCode: this.couponCode,
                    validTo: {
                        $gte: moment().toDate(),
                    },
                    validFrom: {
                        $lte: moment().toDate(),
                    },
                    $limit: 1
                }
            })).data[0];
            if (!this.coupon) {
                this.couponNotFound = true;
                throw new Error('Coupon not found');
            }
            this.session.beginEdit();
            this.session.discountCal.addDiscounts([this.coupon])
            this.couponNotFound = false;
        } catch (e) {
            console.error(e)
        }
    }

    removeCoupon(item: DiscountType) {
        this.session.beginEdit();
        this.session.discountCal.removeDiscounts([this.coupon]);
        this.couponNotFound = false;
    }

    onDecode(code) {
        this.couponCode = code;
        this.scanning = false;
    }
}

