
import { Component, Vue, Prop } from "@feathers-client";
import { LangArrType } from "@feathers-client/i18n";
import { defineComponent } from "site-editor/def";
import _ from "lodash";
import { SiteEditorIconItem } from "../../editorComponents/icon.vue";

export const componentInfo = defineComponent({
  group: "icon",
  groupProps: {
    editorCols: 2,
  },
  variants: [
    {
      key: "icon",
    },
  ],
});

@Component
export default class SiteEditorImage extends Vue {
  @Prop()
  variant: string;

  @Prop()
  title: LangArrType;

  @Prop()
  remarks: LangArrType;

  @Prop()
  items: SiteEditorIconItem[];

  @Prop()
  titleDisplayMode: "left" | "center" | "right";

  @Prop()
  fontColor: string;

  @Prop()
  fontRemarksColor: string;

  @Prop()
  size: "small" | "large";

  getLinkProp(item: SiteEditorIconItem) {
    if (!item.url) return {};
    if (item.urlType === "external") return { href: item.url, target: "_blank", rel: "noopener noreferrer" };
    else return { to: this.$localePath(item.url) };
  }

  getLink(item: SiteEditorIconItem) {
    if (!item.url) return "div";
    if (item.urlType === "internal") {
      return "nuxt-link";
    } else {
      return "a";
    }
  }
}
