
import { Component, Prop, Vue, Watch, mixins, VModel, PropSync } from "nuxt-property-decorator";
import EditorQuill from "./EditorQuill.vue";

@Component({
  components: {
    EditorQuill,
  },
})
export default class EditorTextField extends Vue {
  @Prop()
  label: string;

  @VModel()
  inputValue: string;

  @Prop(Boolean)
  multiLine: boolean;

  @Prop(Boolean)
  number: boolean;

  @Prop(Boolean)
  integer: boolean;

  @Prop(Number)
  minValue: number;

  @Prop(Number)
  maxValue: number;

  @Prop(Boolean)
  editor: boolean;

  @Prop({ type: Number, default: 6 })
  rows: number;

  @Prop(Boolean)
  readonly: boolean;

  @Prop(String)
  placeholder: string;

  @Prop(Number)
  placeholderValue: number;

  @Prop()
  pattern: string;

  @Prop()
  prefix: string;

  @Prop()
  suffix: string;

  @Prop()
  type: string;

  @Prop()
  autocomplete: string;

  @Prop()
  name: string;

  @Prop()
  containerClass: any;

  @Prop({ type: Boolean, default: true })
  stepperSpace: boolean;

  @Prop({ type: String, default: "border-grey200" })
  borderStyle: string;

  @Prop({ type: String, default: "border-orange400" })
  activeBorderColor: string;

  @Prop({ type: String, default: "text-primary" })
  inputTextStyle: string;

  @Prop()
  enterkeyhint: string;

  @Prop(Boolean)
  fill: boolean;

  @Prop(Boolean)
  selectAllOnFocus: boolean;

  @Prop(Number)
  maxlength: number;

  active = false;

  focus() {
    this.active = true;
    this.$emit("focus");
    if(this.selectAllOnFocus) {
      this.$nextTick(() => {
        const textarea = this.$el.querySelector("textarea");
        if(textarea) {
          textarea.select();
        }
        const input = this.$el.querySelector("input");
        if(input) {
          input.select();
        }
      });
    }
  }

  blur() {
    this.active = false;
    this.$emit("blur");
  }
}
