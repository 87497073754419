export const BOXS_CLOUD_URL = process.env.BOXS_CLOUD_URL ? process.env.BOXS_CLOUD_URL : "https://cloud.boxs.hk/api/";

export async function boxsCloudGet(url: string) {
  const u = new URL(url, BOXS_CLOUD_URL);
  const resp = await fetch(u.toString());
  if (!resp.ok) {
    if ((resp.headers.get("content-type") || "").includes("application/json")) {
      const j = await resp.json();
      const e = new Error(j.message || JSON.stringify(j));
      (e as any).data = j;
      throw e;
    }
    throw new Error("Invalid status: " + resp.statusText);
  }
  return await resp.json();
}

export async function boxsCloudPost(url: string, body: any) {
  const u = new URL(url, BOXS_CLOUD_URL);
  const resp = await fetch(u.toString(), {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!resp.ok) {
    if ((resp.headers.get("content-type") || "").includes("application/json")) {
      const j = await resp.json();
      const e = new Error(j.message || JSON.stringify(j));
      (e as any).data = j;
      throw e;
    }
    throw new Error("Invalid status: " + resp.statusText);
  }
  return await resp.json();
}
