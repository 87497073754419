
import { Component, Vue, Prop, Ref } from "@feathers-client";
import { PageBlock } from "../def";
import { SiteEditorComponentVariant, SiteEditorManager } from "..";
import EditorList from "@schemaEditor/EditorList.vue";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import EditorTranslateBox from "@schemaEditor/EditorTranslateBox.vue";
import EditorCheckbox from "@schemaEditor/EditorCheckbox.vue";
import EditorObjectPickerList from "@schemaEditor/EditorObjectPickerList.vue";
import ColorPicker from "../ColorPicker.vue";
import UrlPicker from "../UrlPicker.vue";
import uuid from "uuid/v4";
import CheckIcon from "@feathers-client/components-internal/CheckIcon.vue";
import _ from "lodash";
// @ts-ignore
import DeleteSvg from "!vue-svg-loader!../assets/delete.svg";
// @ts-ignore
import RarrowSvg from "!vue-svg-loader!../assets/rarrow.svg";
import {
  SiteEditorFooterMenu,
  SiteEditorFooterMenuItem,
  SiteEditorFooterSoicalMedia,
  SiteEditorFooterUrl,
} from "../components/footer/index.vue";
import LangPicker from "../LangPicker.vue";
import { LangArrType } from "@feathers-client/i18n";

@Component({
  components: {
    EditorList,
    EditorTextField,
    EditorTranslateBox,
    EditorCheckbox,
    EditorObjectPickerList,
    LangPicker,
    ColorPicker,
    UrlPicker,
    CheckIcon,
    DeleteSvg,
    RarrowSvg,
  },
})
export default class SiteEditorImage extends Vue {
  @Prop()
  block: PageBlock;

  @Prop()
  manager: SiteEditorManager;

  @Prop()
  variant: SiteEditorComponentVariant;

  // @Prop()
  // modalResult: (save: boolean) => void;

  @Ref()
  search: HTMLInputElement;

  @Ref()
  datalist: any;

  @Ref()
  router: any;

  @Ref()
  menuItemList: any;

  importIcon = false;
  editMenuContent = false;
  importLink = false;
  currentMenuItemIdx = null;
  currentImportItemList: SiteEditorFooterMenuItem[] = null;
  productType = "";
  menuInputType: "custom" | "auto" = "custom";

  selectedItems = [];
  haveError = false;
  errorMsg: any = "";

  get menuInputTypes() {
    return [
      {
        _id: "custom",
        name: {
          $t: this.manager.$t("componentGroups.footer.custom", {
            name: this.manager.$t(`componentGroups.footer.link`),
          }),
        },
        desc: this.manager.$t("componentGroups.footer.customDesc"),
      },
      {
        _id: "auto",
        name: {
          $t: this.manager.$t("componentGroups.footer.auto", {
            name: this.manager.$t(`componentGroups.footer.link`),
          }),
        },
        desc: this.manager.$t("componentGroups.footer.autoDesc"),
      },
    ];
  }

  get productTypes() {
    return [
      {
        _id: "categories",
        name: {
          $t: this.manager.$t("componentGroups.common.categories"),
        },
      },
      {
        _id: "tags",
        name: {
          $t: this.manager.$t("componentGroups.common.tags"),
        },
      },
      {
        _id: "brands",
        name: {
          $t: this.manager.$t("componentGroups.common.brands"),
        },
      },
    ];
  }

  get paymentMethod() {
    return [
      { _id: "visa", name: { $t: "Visa" } },
      { _id: "master", name: { $t: "Master" } },
      { _id: "union", name: { $t: "UnionPay" } },
      { _id: "ae", name: { $t: "American Express" } },
      { _id: "jcb", name: { $t: "JCB" } },
      { _id: "payme", name: { $t: "Payme" } },
      { _id: "fps", name: { $t: "FPS" } },
      { _id: "octopus", name: { $t: "Octopus" } },
      { _id: "alipayhk", name: { $t: "AlipayHK" } },
      { _id: "paypal", name: { $t: "Paypal" } },
      { _id: "applePay", name: { $t: "Apple Pay" } },
      { _id: "wechatpay", name: { $t: "WeChat Pay" } },
      { _id: "alipay", name: { $t: "Alipay" } },
      { _id: "googlepay", name: { $t: "Google Pay" } },
    ];
  }

  getMenu() {
    return {
      _id: uuid(),
      title: [],
      items: [],
      urlType: "internal",
      url: "",
      productName: "",
    } as SiteEditorFooterMenu;
  }

  getSoicalMedia() {
    return {
      id: uuid(),
      title: "",
      file: null,
      url: null,
    } as SiteEditorFooterSoicalMedia;
  }

  getItem() {
    return {
      _id: uuid(),
      title: [],
      urlType: "internal",
      url: "",
      productName: "",
    } as SiteEditorFooterMenuItem;
  }

  tabEntryName(item, idx) {
    return this.$td(item.title) || `${this.manager.$t("componentGroups.footer.itemList")} ${idx + 1}`;
  }

  goAddLink(list: any, idx?: number) {
    this.router.navigate("linkType");
    this.menuInputType = "custom";
    this.currentImportItemList = list;
    this.currentMenuItemIdx = idx;
  }

  goSetIcon() {
    this.importIcon = true;
    this.router.navigate("importIcon");
  }

  returnSetLink() {
    this.importIcon = false;
    this.router.navigate();
  }

  addLink() {
    if (this.menuInputType === "auto") {
      this.router.navigate("importLink");
      this.importLink = true;
      this.productType = "";
    } else {
      this.router.navigate();
      this.currentImportItemList = null;
    }
  }

  cancelAddLink() {
    this.menuItemList.removeItem(this.currentMenuItemIdx);
    this.currentImportItemList = null;
    this.router.navigate();
  }

  get sizeTypes() {
    return [
      {
        _id: "small",
        name: {
          $t: this.manager.$t("componentGroups.common.small"),
        },
      },
      {
        _id: "medium",
        name: {
          $t: this.manager.$t("componentGroups.common.medium"),
        },
      },
      {
        _id: "large",
        name: {
          $t: this.manager.$t("componentGroups.common.large"),
        },
      },
    ];
  }

  get groupKey() {
    return this.variant.group.key;
  }

  get keyName() {
    return this.variant.key;
  }

  get props() {
    return this.manager.defineProps(this.block, {
      logo: null,
      logoSize: "small",
      bgColor: "#000000FF",
      titleFontColor: "#FFFFFFFF",
      fontColor: "#FFFFFFFF",
      paymentMethods: [] as string[],
      socialMedia: [] as SiteEditorFooterSoicalMedia[],
      copyright: "",
      term: { url: null, urlType: "internal" } as SiteEditorFooterUrl,
      privacy: { url: null, urlType: "internal" } as SiteEditorFooterUrl,
      disclaimer: { url: null, urlType: "internal" } as SiteEditorFooterUrl,
      menus: [] as SiteEditorFooterMenu[],
      additionalText: [] as LangArrType,
      additionalText2: [] as LangArrType,
    });
  }

  selectAll() {
    this.search?.select?.();
  }

  onSelect(value) {
    if (this.selectedItems.includes(value)) {
      this.selectedItems = this.selectedItems.filter(item => item._id !== value._id);
    } else {
      this.selectedItems.push(value);
    }
  }

  onDelete(value) {
    if (this.selectedItems.includes(value)) {
      this.selectedItems = this.selectedItems.filter(item => item._id !== value._id);
    }
  }

  displayItemPrice(value) {
    return this.$price(value.pricing[0]?.min?.price ?? 0, "pre");
  }

  exportSelectedItem() {
    const newItems = [];
    for (let item of this.selectedItems) {
      newItems.push({
        _id: uuid(),
        title: item.name ? item.name : "",
        haveLink: true,
        urlType: "internal",
        url: `/shop?query=${JSON.stringify({
          currentFilter: {
            ...(this.productType === "categories"
              ? {
                  "categories.tree._id": { $in: item._id },
                }
              : {}),
            ...(this.productType === "brands"
              ? {
                  "brand._id": { $in: item._id },
                }
              : {}),
            ...(this.productType === "tags"
              ? {
                  "tags._id": { $in: item._id },
                }
              : {}),
          },
        })}`,
        productName: item.name,
      });
    }

    if (this.importLink) {
      this.currentImportItemList.pop();
      this.currentImportItemList.push(...newItems);

      this.router.reset("editor");
      this.importLink = false;
      this.selectedItems = [];
      this.currentImportItemList = null;
    }
  }

  showErrorMsg() {}

  onValidAndConfirm() {
    this.$emit("save");
  }
}
