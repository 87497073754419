
export default {
    users: {
        fields: ['name', 'name.firstName', 'name.lastName', 'email'],
        view: {
            name: ['name.firstName', 'name.lastName', 'email'],
        }
    },
    "shop/subshops": {
        fields: ['name'],
        view: {
            translate: true,
            name: ['name'],
        }
    }
}
