

import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import EditorObjectPickerList from '@schemaEditor/EditorObjectPickerList.vue'
import type { SearchConfig } from "@schemaEditor/plugin/defs";

@Component({
    components: {
        EditorObjectPickerList,
    }
})
export default class CategoryPicker extends Vue {

    @Prop()
    label: string

    @Prop({ type: Boolean })
    outlined : boolean

    @Prop()
    value : {
        _id: string,
        icon: any,
        name: any[],
        tree: {
            _id: string,
            name: any[]
        }[],
    }[]
    
    get categories() {
        return this.value?.map?.(it => ({
            ...it,
            name: it.tree?.[it.tree?.length - 1]?.name,
        })) ?? [];
    }

    set categories(v) {
        if(!v) v = [];
        this.$emit('input', v);
    }

    get fields() {
      return [
        {
          field: ['tree', '*', 'name'],
          mongoField: 'tree.name',
          multiple: true,
          translate: true,
        }
      ] as SearchConfig[]
    }

}

