
import { Component, Vue, FindType, Prop, escapeRegExp, unescapeRegExp } from "@feathers-client";
import type { SearchField } from "@schemaEditor/plugin";

@Component
export default class SpecSearch extends Vue {
  specs: FindType<"shop/specs">[] = [];

  @Prop()
  search: SearchField;

  async mounted() {
    try {
      this.specs = await this.$feathers.service("shop/specs").find({
        query: {
          $paginate: false,
          $or: [{ searchable: true }, { showInList: true }],
        },
        paginate: false,
      });
    } catch (e) {
      console.error(e);
    }
  }

  getSearchSpecItem(spec: FindType<"shop/specs">) {
    return this.search?.value1?.$and?.find(v => v.specs.$elemMatch.spec === spec._id)?.specs.$elemMatch;
  }

  setSearchSpecItem(spec: FindType<"shop/specs">, value: any) {
    const and = (this.search.value1?.$and ?? []).slice();
    const targetIdx = and.findIndex(v => v.specs.$elemMatch.spec === spec._id);
    if (targetIdx !== -1) {
      and.splice(and.indexOf(targetIdx), 1);
    }
    if (value) {
      and.push({ specs: { $elemMatch: { spec: spec._id, ...value } } });
    }
    if (!and.length) {
      this.search.cond = null;
      this.search.value1 = null;
    } else {
      this.search.cond = "custom";
      this.search.value1 = { $and: and };
    }
  }

  getSpecItem(spec: FindType<"shop/specs">) {
    const self = this;
    return {
      spec: spec._id,
      get specValue() {
        return self.getSearchSpecItem(spec)?.specValue;
      },
      set specValue(value) {
        if (value && (!Array.isArray(value) || !value.length)) {
          self.setSearchSpecItem(spec, { specValue: value });
        } else {
          self.setSearchSpecItem(spec, null);
        }
      },
      get value() {
        if (spec.type === "string") {
          const text = self.getSearchSpecItem(spec)?.value?.$regex;
          if (text) {
            return unescapeRegExp(text);
          } else {
            return "";
          }
        } else {
          return self.getSearchSpecItem(spec)?.value;
        }
      },
      set value(value) {
        if (spec.type === "string") {
          self.setSearchSpecItem(
            spec,
            value
              ? {
                  value: {
                    $regex: escapeRegExp(value),
                    $options: "i",
                  },
                }
              : null,
          );
        } else {
          self.setSearchSpecItem(spec, value ? { value } : null);
        }
      },
    };
  }
}
