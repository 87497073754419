
import Dialog from "@feathers-client/mixins/Dialog";
import { Component, mixins, Prop, FindType } from "@feathers-client";
import MenuListButton from "@feathers-client/components/MenuListButton.vue";

@Component({
  components: {
    MenuListButton,
  }
})
export default class ErpConfirmDialog extends mixins(Dialog) {

  @Prop()
  title

  @Prop()
  content

  @Prop()
  extraContent

  @Prop()
  loopContentTitle

  @Prop()
  loopContent

  @Prop()
  loopContentTitle2

  @Prop()
  loopContent2

  @Prop({ type: Boolean, default: false })
  moreContent: boolean


}
