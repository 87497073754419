
import MVue, { VueConstructor } from 'vue'
import { Component, Prop, Watch } from 'nuxt-property-decorator'
import { VueClass } from 'vue-class-component/lib/declarations'
import _, { uniq } from 'lodash'
// @ts-ignore
import { MApplication, FindType, FindPopRawType, ObjRet, UnionToIntersection } from '@feathersjs/feathers';
import { AdminApplication, AppApplication } from 'serviceTypes'
import { ObjectID as DBRef, DB } from '@db';
import { checkID, getID } from './util'
import { createDecorator } from 'vue-class-component'
import type * as currencyType from '@mfeathers/currency'

declare module 'vue/types/vue' {
    export interface Vue {
        $td(name : LangType) : string
        $enum(name : string, type : string) : string
    }
}

export const Vue = MVue;

export type LangArrType = { lang: string, value: string }[];
export type LangObjType = { $t?: string, $join? : LangType[], $a? : any, $ta?: { [key : string] : LangType} }
export type LangType = LangArrType | LangObjType | string;

export type ShopType = FindType<'shops', AdminApplication>;
export type AttachmentType = FindType<'attachments', AdminApplication>;
export type AdminType = FindType<'users', AdminApplication>;
export type StaffType = FindType<'shop/staffs', AdminApplication>;
export type UserType = FindType<'shop/users', AdminApplication>;
export type RankType = FindType<'shop/ranks', AdminApplication>;

export type ShippingAddress = FindType<'shop/user/addresses', AdminApplication>;

export type CouponTemplateType = FindType<'shop/coupon/templates', AdminApplication>;
export type CouponType = FindType<'shop/coupons', AdminApplication>;
export type CouponPopType = FindPopRawType<['template'], 'shop/coupons', AdminApplication>;

export type SessionType = FindType<'shop/pos/sessions', AdminApplication>;

export interface OrderPointType {
    point: string
    fixed: number
    percentage: number
    max: number
}

export interface OrderTempPointType {
    point: string
    value: number
}

export type DiscountMemberType = DiscountType['members'][number]
export type DiscountMemberFilterType = Partial<DiscountMemberType['filter']>
export type TimeConditionType = DiscountType['timeConditions'][number]
export type ShippingConditionType = DiscountType['shippingConditions'][number]

export type DiscountType = FindType<'shop/product/discounts', AdminApplication>;

export type CategoryType = FindType<'shop/categories', AdminApplication>;
export type BrandType = FindType<'shop/brands', AdminApplication>;
export type SpecType = FindType<'shop/specs', AdminApplication>;
export type SpecValueType = FindType<'shop/spec/values', AdminApplication>;
export type WarehouseType = FindType<'shop/inventory/warehouses', AdminApplication>;

export type ProductGroupType = FindType<'shop/product/groups', AdminApplication>;
export type ProductType = FindType<'shop/product/skus', AdminApplication>;
export type ProductSearchType = FindType<'shop/product/searches', AdminApplication>;
export type ProductOptionType = FindType<'shop/product/options', AdminApplication>;
export type ProductOptionChoiceType = ProductOptionType['choices'][number];
export type ProductOptionChoiceCustomizationType = ProductOptionChoiceType['customizations'][number];
export type ProductCustomizationType = FindType<'shop/product/customizations', AdminApplication>;
export type ShopOptionType = ProductType['shopTable'][number];
export type PriceOptionType = ShopOptionType['pricing'][number]

export type PointType = FindType<'shop/points', AdminApplication>;
export type PaymentMethodType = FindType<'shop/payment/methods', AdminApplication>;

export interface UserPointSummaryType {
    value: number
}

// export interface UserPointSummaryType extends DBType {
//     value: number
// }

export type UserPointType = FindType<'shop/user/points', AdminApplication>;

export type PaymentMethod = FindType<'shop/payment/methods', AppApplication>;

export type PaymentType = FindType<'shop/payments', AdminApplication>;

export type InventoryType = FindType<'shop/inventories', AdminApplication>;

export type RewardCouponType = OrderProductType['rewardCoupons'][number]
export type RewardPointType = OrderProductType['rewardPoints'][number]
export type UsePointType = OrderProductType['usePoints'][number]
export type OrderChargeType = OrderProductType['charges'][number]

export type OrderProductType = OrderType['products'][number]
export type OrderProductOption = OrderProductType['options'][number]
export type OrderProductSelection = OrderProductOption['selections'][number]
export type OrderProductCustomization = OrderProductSelection['customizations'][number]
export type OrderDiscountType = OrderType['discounts'][number]
export type OrderTaxType = OrderType['taxes'][number]

export type OrderType = FindType<'shop/orders', AdminApplication>;
export type OrderShippingInfoType = OrderType['shippingMethods'][number];
export type OrderShippingProductType = OrderShippingInfoType['products'][number];
export type ShippingMethodType = FindType<'shop/shipping/methods', AdminApplication>;
export type ShippingType = OrderType['address'];
export type CashierType = FindType<'shop/pos/cashiers', AdminApplication>;

export type ShopBookingSetting = FindType<'shop/booking/settings', AdminApplication>;
export type ShopBookingTimetable = FindType<'shop/booking/timetables', AdminApplication>;
export type ShopBookingTimetableEntry = FindType<'shop/booking/timetable/entries', AdminApplication>;

export type WholesaleOrderType = FindType<'shop/erp/wholesaleOrders', AdminApplication>;
export type PurchaseOrderType = FindType<'shop/erp/purchaseOrders', AdminApplication>;

export interface PosContext {
    currentAdmin? : AdminType
    currentStaff?: StaffType
    cashier? : CashierType
    $shop?: ShopType
    $pos: {
        shippingMethods?: ShippingMethodType[],
        warehouse?: WarehouseType,
    },
    $feathers? : MApplication<any>
    $source?: 'pos' | 'eshop'
    $enum(t : string, v : string) : string
    $t(t : string) : string
    $td(t : any) : string
    $set?(o : any, k : string, v : any);
    getHumanNumber(amount: number, currency: string): number;
    fromHumanNumber(amount: number, currency: string): currencyType.CurrencyType;
}

export function enumerable(value: boolean) {
    return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
        descriptor.enumerable = value;
    };
}

export type WeightType = 'kg' | 'g' | 'oz' | 'lb' | 'tael' | 'hk_catty';

export function getLangs(value : LangType) {
    if(Array.isArray(value)) {
        return value.map(it => it.lang);
    } else if(value && typeof value === 'object') {
        return Object.keys(value);
    } else return [];
}

export function langJoin(join : string, ...values : LangType[]) : LangArrType {
    if(!values || !values.length) return [];
    const uniqLang = _.uniq(_.flatMap(values, getLangs));
    if(!uniqLang.length) {
        return [{ lang: 'en', value: values.join(join) }];
    }
    return uniqLang.map(lang => ({
        lang,
        value: values.map(v => translate(v, lang)).join(join),
    }))
}

export function translate (item : LangType, lang : string) {
    if (typeof item === 'string') return item;
    else if (!item) return '';

    if(_.isArray(item)) {
        let enValue, locValue, defValue;
        _.each(item, v => {
            if(v.lang === lang) locValue = v.value;
            if(v.lang === 'en') enValue = v.value;
            defValue = v.value;
        })
        return locValue || enValue || defValue;
    } 

    return item[lang] || item['en'] || _.map(item, it => it)[0] || '';
};


export function checkSkuPriceOptionType(
    option : PriceOptionType,
    user: UserType,
    quantity: number
) {
    if(!option.conditions?.length) return true;
    for(let cond of option.conditions) {
        if(cond.ranks?.length) {
            if(cond.mode === 'exclude'){
              if(cond.ranks?.find?.(r => 
                  user?.ranks?.find?.(ur => checkID(ur.rank, r))
              )) continue;
            }else{
              if(!cond.ranks?.find?.(r => 
                  user?.ranks?.find?.(ur => checkID(ur.rank, r))
              )) continue;
            }
        }
        if(cond.quantity && quantity < cond.quantity) {
            continue;
        }
        return true;
    }
    return false;
}


interface PopOptions<TKey extends string = any, TVal = any> {
    path: TKey
    value?: TVal
    service?: string
    create?: Constructor<TVal>
    multiple?: boolean
}

export interface Constructor<T> {
    new (...args : any[]): T
}

export function asPopOptions<TVal>(create?: Constructor<TVal>) {
    return function<TKey extends string>(path : TKey, service: string) {
        return {
            path,
            service,
            create,
        } as PopOptions<TKey, TVal>
    }
}

export function asPopOptionsArray<TVal>(create?: Constructor<TVal>) {
  return function<TKey extends string>(path : TKey, service: string) {
      return {
          path,
          service,
          create,
          multiple: true,
      } as any as PopOptions<TKey, TVal[]>
  }
}

export type DbClassType<T, TKeys extends keyof T> = {
    [K in TKeys]: T[K]
}

export type DbPopClassType<T, TKeys> = UnionToIntersection<(TKeys extends keyof T ? {
    [K in TKeys]: ObjRet<DB, T[K]>
} : TKeys extends readonly any[] ? (TKeys[0] extends infer TSubKeys ? TSubKeys extends keyof T ? {
    [K in TSubKeys]: ObjRet<DB, T[K]>
} : never : never) : TKeys extends PopOptions<infer Key, infer Val> ? {
    [K in Key]: Val
} : never)>;

export type DbPopIdType<T, TKeys extends keyof T> = UnionToIntersection<(TKeys extends string ? {
    [K in `${TKeys}Id`]: string
} : {})>

export type DbClassConstructor<T, TRet> = {
    new (item : Partial<T>): TRet;
}

export function dbMixin<T>() {
    return function<TKeys extends keyof T>(...keys : TKeys[]) {
        const propDef: PropertyDescriptorMap = Object.fromEntries(keys.map(k => [
            k,
            {
                get(this : DbClass) {
                    return this.item[k];
                },
                set(this : DbClass, v) {
                    this.item[k] = v;
                }
            }
        ]))
        class DbClass {
            item : any
            get _id() { return this.item._id }
            constructor(item) {
                this.item = item;
            }
            toJSON() {
                return this._id;
            }
        }
        Object.defineProperties(DbClass.prototype, propDef);
        return DbClass as any as DbClassConstructor<T, DbClassType<T, TKeys> & {_id: string, item: T}>;
    }
}

type PopToPath<T> = T extends string ? T : T extends readonly any[] ? T[0] : T extends PopOptions<infer TKey> ? TKey : never;

export function dbMixinVue<T>() {
    return function<
        TKeys extends readonly (keyof T)[], 
        TPop extends readonly (keyof T | readonly [keyof T, string] | PopOptions)[], 
    >(
        keys: TKeys = [] as any, 
        populate_Opts: TPop = [] as any,
    ) {
        const populate : PopOptions<any, any>[] = populate_Opts.map(it => typeof it === 'object' ? Array.isArray(it) ? {
            path: it[0] as string,
            service: it[1],
        } : it : {
            path: it as string
        }) as any[];
        const populateKeys = populate.map(it => it.path);

        (keys as any).push('_id');
        const propDef: PropertyDescriptorMap = Object.fromEntries(keys.map(k => [
            k,
            {
                get(this : DbVueClass) {
                    return this.item?.[k];
                },
                set(this : DbVueClass, v) {
                    this.item[k] = v;
                }
            }
        ]))

        const computedPropDef: PropertyDescriptorMap = Object.fromEntries(populate.map(k => [
            k.path,
            {
                get(this : DbVueClass) {
                    return this.populateCache?.[k.path];
                },
                set(this : DbVueClass, v : any) {
                    this.populateCache[k.path] = v;
                    if(k.multiple) {
                      this.item[k.path] = v ? v.map(it => getID(it)) : null;
                    } else {
                      this.item[k.path] = getID(v);
                    }
                }
            }
        ]))

        const computedIdPropDef: PropertyDescriptorMap = Object.fromEntries(populateKeys.map(k => [
            k + 'Id',
            {
                get(this : DbVueClass) {
                    return this.item?.[k];
                },
                set(this : DbVueClass, v) {
                    this.item[k] = v;
                }
            }
        ]))

        function FieldDefine(map : PropertyDescriptorMap) {
            return (component) => {
                Object.defineProperties(component, map);
            }
        }

        const emptyFields = {
            ...Object.fromEntries(keys.map(k => [k, undefined])),
            ...Object.fromEntries(populateKeys.map(k => [k, null])),
        }
        const emptyCache = Object.fromEntries(populateKeys.map(k => [k, null]));

        @Component({
            computed: {
                ...propDef,
                ...computedPropDef,
                ...computedIdPropDef,
            }
        })
        class DbVueClass extends MVue {
            item : any = null;
            populateCache : any = null;
            initItem : any = null;
            initPopulateCache : any = null;
            mediting = false;
            _linkedItems: DbVueClass[];
            get editing() {
                return this.mediting;
            }
            set editing(v) {
              this.mediting = v;
              if(this._linkedItems) {
                for(let item of this._linkedItems) {
                  item.editing = v;
                }
              }
            }
            context: PosContext
            _id : string;
            async init(context: PosContext, initItem = {}, initPopulateCache = {}) {
                this.context = context;
                this.item = _.defaults({}, initItem, emptyFields);
                this.populateCache = _.defaults({}, initPopulateCache, emptyCache);
                for(let pop of populate) {
                    const v = this.item[pop.path];
                    if(pop.multiple) {
                      if(v?.length) {
                        const needToFind = v.filter(it => !(typeof it === 'object' && !(it as any)._bsontype));
                        const findDict: Record<string, any> = {};
                        if(needToFind.length) {
                          const vv = await (this.context.$feathers.service(pop.service) as any).find({
                            query: {
                              _id: needToFind,
                              $paginate: false,
                            },
                            paginate: false,
                          });
                          if(vv.length !== needToFind.length) {
                            throw new Error("Missing some populate items")
                          }
                          for(let it of vv) {
                            findDict[getID(it)] = it;
                          }
                        }
                        const normalized = v.map(it => typeof it === 'object' && !(it as any)._bsontype ? it : findDict[getID(it)]);
                        this.populateCache[pop.path] = normalized.map(it => pop.create ? it instanceof pop.create ? it : new pop.create((this as any).context, it) : it);
                        this.item[pop.path] = normalized.map(it => getID(it))
                      } else {
                        this.populateCache[pop.path] = [];
                      }
                    } else {
                      if(v) {
                          if(typeof v === 'object' && !(v as any)._bsontype) {
                              this.populateCache[pop.path] = pop.create ? v instanceof pop.create ? v : new pop.create((this as any).context, v) : v;
                              this.item[pop.path] = getID(v);
                          } else {
                              const vv = await (this.context.$feathers.service(pop.service) as any).get(v);
                              this.populateCache[pop.path] = pop.create ? vv instanceof pop.create ? vv : new pop.create((this as any).context, vv) : vv;
                          }
                      }
                    }
                }
            }
            attachLinked(item: DbVueClass) {
              if(this.editing) {
                item.editing = true;
              }
              if(!this._linkedItems) {
                this._linkedItems = [];
              }
              this._linkedItems.push(item);
            }
            destroyed() {
              if(this._linkedItems) {
                for(let item of this._linkedItems) {
                  item.$destroy();
                }
              }
            }
        }
        return DbVueClass as any as VueClass<
            DbClassType<T, TKeys[number]> & 
            DbPopClassType<T, TPop[number]> & 
            DbPopIdType<T, PopToPath<TPop[number]>> &
            {
              _id: string, 
              item: T, 
              populateCache: any, 
              editing: boolean, 
              context: PosContext, 
              init: (context : PosContext, initItem?: any, initPopulateCache?: any) => Promise<void>, 
              updateCompute: () => void ,
              attachLinked: (item: any) => void,
            }
        >;
    }
}

export function CachedComputed(target: Vue, key: string) {
    let def = Object.getOwnPropertyDescriptor(target, key);
    createDecorator(function (componentOptions, k) {
        componentOptions.computed[key] = {
            get(this : any) {
                if(!this.editing) {
                    return this.item?.[key];
                } else {
                    return def.get.apply(this);
                }
            },
            set: def.set,
        }
    })(target, key);
    (target as any)[`on_${key}`] = function() {
        if(this.editing && this.item) {
            this.item[key] = this[key];
        }
    };
    if(!(target as any)._computeList) {
        (target as any)._computeList = [];
    }
    const list = (target as any)._computeList;
    list.push(key);
    (target as any).updateCompute = function() {
        for(let key of list) {
            this[`on_${key}`]();
        }
    }
    Watch(key)(target, `on_${key}`);
}

