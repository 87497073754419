
import EditorCheckbox from "./EditorCheckbox.vue";
import { Component, Vue, VModel } from "@feathers-client";
import { Prop } from "vue-property-decorator";

@Component({
  components: {
    EditorCheckbox,
  },
})
export default class EditorStatusCheckbox extends Vue {
  @VModel()
  inputValue: string;

  @Prop()
  label: string;

  @Prop({ default: "checkbox" })
  type: "checkbox" | "switch";

  @Prop()
  trueValue: string;

  @Prop()
  falseValue: string;

  @Prop()
  trueValues: string | string[];

  get normalTrue() {
    return this.trueValues
      ? typeof this.trueValues === "string"
        ? this.trueValues.split(",")
        : this.trueValues
      : [this.trueValue];
  }

  get boolValue() {
    return this.normalTrue.includes(this.inputValue);
  }

  set boolValue(v) {
    this.inputValue = v ? this.trueValue : this.falseValue;
  }
}
