import Vue from "vue";
import VueI18n from "vue-i18n";
import _ from "lodash";
import moment from "moment";
import { boxsCloudGet, boxsCloudPost } from "pos-printer/ports/cloud";
import { Component, Prop } from "nuxt-property-decorator";
import { getStorage } from "@feathers-client/storage";

if (process.client) {
  global.__translateHelperInject__ = {
    Vue,
    Component,
    Prop,
  };
}

declare let PRODUCTION_MODE: boolean;

Vue.use(VueI18n);

Vue.filter("t", function (ctx, value, item, parent, header) {
  return ctx.$td(value);
});

Vue.filter("tf", function (ctx, value, item, parent, header) {
  const t = _.get(value, header.tpath + "t");
  const v = (!_.isEmpty(t) && t) || _.get(value, header.tpath);
  return ctx.$td(v);
});

export function bind(el, binding, vnode) {
  t(el, binding, vnode);
}

function localeEqual(el, vnode) {
  const vm = vnode.context;
  return el._locale === vm.$i18n.locale;
}

export function update(el, binding, vnode, oldVNode) {
  if (localeEqual(el, vnode) && _.isEqualWith(binding.value, binding.oldValue)) return;
  t(el, binding, vnode);
}

function t(el, binding, vnode) {
  let value = binding.value;
  let result = "";

  const vm = vnode.context;
  if (!vm) {
    console.warn("not exist Vue instance in VNode context");
    return;
  }
  const i18n = vm.$i18n;
  if (!i18n) {
    console.warn("not exist VueI18n instance in Vue instance");
    return;
  }
  const mappedLocale = mappedLocales[i18n.locale] || i18n.locale;

  if (value) {
    if (value.$join) {
      return _.map(value.$join, it => vm.$td(it)).join("");
    }
    let $ta = value.$ta;
    let $a = value.$a;
    if (value.path) {
      if (value.args) $ta = value.args;
      value = value.path;
    }

    if (typeof value === "string") {
      result = value;
    } else if (_.isArray(value)) {
      let enValue, locValue, defValue;
      _.each(value, v => {
        if (v.lang === mappedLocale) locValue = v.value;
        if (v.lang === "en") enValue = v.value;
        defValue = v.value;
      });
      result = locValue || enValue || defValue;
    } else if (typeof value === "object") {
      if (value.$t) {
        if ($ta) {
          const args = {
            ...$a,
            ..._.mapValues($ta, it => vm.$td(it)),
          };
          result = vm.$i18n.t(value.$t, args);
        } else if ($a) {
          result = vm.$i18n.t(value.$t, $a);
        } else {
          result = i18n.t(value.$t);
        }
      } else {
        result = value[mappedLocale] || value["en"] || _.map(value, it => it)[0] || "";
      }
    }
  }

  el._vt = el.textContent = result;
  el._locale = mappedLocale;
}

Vue.directive("td", {
  bind,
  update,
});

let i18NInit = false;

Vue.prototype.$ensureI18N = function () {
  if (process.browser) {
    if (i18NInit) return;
    moment.locale(this.$store.getters.localeCode);
    i18NInit = true;
  }
};

const mappedLocales = {
  "zh-hk": "cht",
  "zh-cn": "chs",
  "en-us": "en",
  "en-hk": "en",
  en: "en",
};
const unmappedLocales = {
  cht: "zh-hk",
  chs: "zh-cn",
};

Vue.prototype.$td = function (item, locale) {
  if (typeof item === "string") return item;
  else if (!item) return "";

  const i18n: VueI18n = this.$i18n || this.$root.$i18n;
  const unmapLocale = locale ? unmappedLocales[locale] || locale : i18n.locale;
  locale = locale || i18n.locale;
  locale = mappedLocales[locale] || locale;

  if (item.$join) {
    return _.map(item.$join, it => this.$td(it, locale)).join("");
  } else if (item.$t) {
    let result;
    if (item.$ta) {
      const args = {
        ...item.$a,
        ..._.mapValues(item.$ta, it => this.$td(it, locale)),
      };
      result = i18n.t(item.$t, unmapLocale, args);
    } else if (item.$a) {
      result = i18n.t(item.$t, unmapLocale, item.$a);
    } else {
      result = i18n.t(item.$t, unmapLocale, {});
    }
    if(item.$fallback && result === item.$t) {
      result = this.$td(item.$fallback, locale);   
    }
    if(result) return result;
  } else if (_.isArray(item)) {
    let enValue, locValue, defValue;
    _.each(item, v => {
      if (v.lang === locale) locValue = v.value;
      if (v.lang === "en") enValue = v.value;
      defValue = v.value;
    });
    return locValue || enValue || defValue;
  }

  return item[locale] || item["en"] || _.map(item, it => it)[0] || "";
};

Vue.prototype.$tdf = function (item, fallback) {
  return (!_.isEmpty(item) && this.$td(item)) || fallback;
};

const i18n = context => {
  const { app, store, $config } = context;
  const dict = {};
  let missingList: any[] = [];
  let missingTimer: any = null;

  if (process.client && PRODUCTION_MODE && process.env.BUILD_INFO) {
    try {
      const j = localStorage["cachedMissingList"];
      if (j) {
        const list = JSON.parse(j);
        if (process.env.BUILD_INFO === list.builInfo) {
          Object.assign(dict, Object.fromEntries(list.list.map(k => [k, true])));
        }
      }
    } catch (e) {}
  }

  app.i18n = new VueI18n({
    locale: store.state.locale,
    fallbackLocale: "en",
    silentTranslationWarn: true,
    missing: (async (lang, key, vm) => {
      if (!dict[key]) {
        dict[key] = true;
        if (missingTimer) {
          clearTimeout(missingTimer);
          missingTimer = null;
        }
        missingList.push({
          key,
          url: vm?.$route?.path,
          locale: lang,
        });
        missingTimer = setTimeout(
          async () => {
            const list = missingList;
            missingList = [];
            missingTimer = null;
            if (!PRODUCTION_MODE) {
              try {
                const r = await fetch(`${app.$config.apiUrl}/api/missingTranslations`, {
                  method: "POST",
                  body: JSON.stringify(list),
                  headers: {
                    "Content-Type": "application/json",
                  },
                });
                await r.text();
              } catch (e) {
                console.warn(e);
              }
            } else {
              try {
                localStorage["cachedMissingList"] = JSON.stringify({
                  buildInfo: process.env.BUILD_INFO || "{}",
                  list: Object.keys(dict),
                });
              } catch (e) {
                console.warn(e);
              }
              await boxsCloudPost("missingTranslate", {
                list,
                app: appName,
              });
            }
          },
          PRODUCTION_MODE ? 3000 : 3000,
        );
      } else {
        return key;
      }
    }) as any,
    messages: {
      "zh-hk": require("~/locales/zh-hk.json"),
      "zh-cn": require("~/locales/zh-cn.json"),
      en: require("~/locales/en.json"),
    },
  });

  app.i18n.loadAllLocales = async () => {
    await Promise.all(Array.from(remoteLocales).map(locale => loadRemoteLocaleInner(locale)));
  };

  app.$t = app.i18n.t.bind(app.i18n);
  let loaded = false;
  let remoteLocales = new Set<string>();
  app.i18n.remoteLocales = remoteLocales;
  app.i18n.boxsCloudGet = boxsCloudGet;
  app.i18n.boxsCloudPost = boxsCloudPost;
  const appName = "retail";
  app.i18n.appName = appName;
  app.i18n.loadDraft = !PRODUCTION_MODE;

  app.i18n.initI18N = async (loadAllLocales?: boolean) => {
    app.i18n.inited = true;
    remoteLocalesTask = {};
    console.log("loading locales");

    const preLocales = JSON.stringify(store.state.locales || {});

    if (localStorage["cachedLocales"]) {
      try {
        const locales = JSON.parse(localStorage["cachedLocales"]);
        if (locales?.locales) {
          const localesMap = Object.fromEntries(locales.locales.map(it => [it.locale, {
            ...it,
            code: it.locale,
            iso: it.code || it.iso || it.locale,
          }]));
          app.i18n.remoteLocales = remoteLocales = new Set(locales.locales.map(it => it.locale));
          store.commit("SET_LOCALES", Object.assign(JSON.parse(preLocales), localesMap));
          loaded = true;
        }
      } catch (e) {}
    }

    async function loadLocales() {
      try {
        const locales = await boxsCloudGet(
          `locales/${appName}${app.i18n.loadDraft ? "-draft" : ""}.json${
            app.i18n.loadDraft ? `?${Math.random()}` : ""
          }`,
        );
        localStorage["cachedLocales"] = JSON.stringify(locales);

        if (locales?.locales) {
          const localesMap = Object.fromEntries(locales.locales.map(it => [it.locale, {
            ...it,
            code: it.locale,
            iso: it.code || it.iso || it.locale,
          }]));
          app.i18n.remoteLocales = remoteLocales = new Set(locales.locales.map(it => it.locale));
          store.commit("SET_LOCALES", Object.assign(JSON.parse(preLocales), localesMap));
          loaded = true;
        }
      } catch (e) {}
    }

    if (!loaded || loadAllLocales) {
      await loadLocales();
    } else {
      loadLocales().catch(console.error);
    }

    if (loadAllLocales || !PRODUCTION_MODE) {
      await app.i18n.loadAllLocales();
    }
  };

  let remoteLocalesTask: Record<string, Promise<void>> = {};
  app.i18n.loadRemoteLocale = locale => {
    if (!loaded) {
      return;
    }
    let task = remoteLocalesTask[locale];
    if (!task) {
      task = remoteLocalesTask[locale] = loadRemoteLocaleInner(locale);
    }
    return task;
  };

  const storage = getStorage();

  async function loadRemoteLocaleInner(locale: string) {
    if (!remoteLocales.has(locale)) return;
    try {
      await storage.loadFile(
        `locales/${appName}_${locale}.json`,
        async buf => {
          try {
            let messages = {};
            try {
              if(locale === 'en') {
                messages = require("~/locales/en.json");
              } else if(locale === 'zh-hk') {
                messages = require("~/locales/zh-hk.json");
              } else if(locale === 'zh-cn') {
                messages = require("~/locales/zh-cn.json");
              }
            } catch(e) {
              console.warn(e);
            }
            const body = JSON.parse(buf.toString());
            deepDefaults(body, messages);
            app.i18n.setLocaleMessage(locale, body);
          } catch (e) {
            console.warn(e);
          }
        },
        (u) => boxsCloudGet(u + (app.i18n.loadDraft ? `?${Math.random()}` : `?build=${process.env.BUILD_INFO || ''}`)),
      );
    } catch (e) {
      console.warn(e);
    }
  }

  if (module.hot) {
    module.hot.accept(["~/locales/zh-hk.json", "~/locales/zh-cn.json", "~/locales/en.json"], function () {
      app.i18n.setLocaleMessage("zh-hk", require("~/locales/zh-hk.json"));
      app.i18n.setLocaleMessage("zh-cn", require("~/locales/zh-cn.json"));
      app.i18n.setLocaleMessage("en", require("~/locales/en.json"));
    });
  }

  if (process.client && !PRODUCTION_MODE) {
    import("@feathers-client/i18n/helper").then(c => c.default(context));
  }
};

function deepDefaults(obj, defaults) {
  if (!obj) return;
  for (const key in defaults) {
    if (obj[key] === undefined) {
      obj[key] = defaults[key];
    } else if (typeof obj[key] === "object" && typeof defaults[key] === "object") {
      deepDefaults(obj[key], defaults[key]);
    }
  }
}

export default i18n;
