import {
  parse,
  Node,
  TextNode,
  NodeTypes,
  ElementTypes,
  TemplateNode,
  ElementNode,
  InterpolationNode,
  ExpressionNode,
  AttributeNode,
  SimpleExpressionNode,
  DirectiveNode,
  ConstantTypes,
} from "@vue/compiler-dom";
import Vue from "vue";

import type { PrintSequence } from "pos-printer/printSequence";

import { compile as compileAngular } from "angular-expressions";
import { PrintTable } from "pos-printer/printSequence/table";
import type { LabelSequenceBase } from "pos-printer/labelSequence/base";
// @ts-ignore
import type { PrintGraph } from "pos-printer/printSequence/graph";
import { TextLine } from "pos-printer/printSequence/rawLine";

export function compile(source: string) {
  const ast = parse(source, {
    whitespace: "condense",
  });

  // console.log(ast);

  const node = compileNode(ast);

  // console.log(node);

  return node;
}

export function compileNode(node: Node): PrinterTemplateNodeBase<PrintSequence> | null {
  switch (node.type) {
    case 0 as NodeTypes.ROOT:
      return new PrinterTemplateNodeRoot(node, compileNode);
    case 1 as NodeTypes.ELEMENT:
      return compileElementNode(node as ElementNode);
    case 2 as NodeTypes.TEXT:
      return new PrinterTemplateNodeText(node);
    case 3 as NodeTypes.COMMENT:
      break;
    case 5 as NodeTypes.INTERPOLATION:
      return new PrinterTemplateNodeInterpolation(node);
    default:
      console.warn("Unhandled node type", node);
  }
  return null;
}

export function compileElementNode(node: ElementNode): PrinterTemplateNodeBase<PrintSequence> | null {
  switch (node.tagType) {
    case 0 as ElementTypes.ELEMENT:
    case 1 as ElementTypes.COMPONENT:
      switch (node.tag.toLowerCase()) {
        case "div":
        case "span":
        case "pre":
        case "text-break":
        case "text-bitmap":
          return new PrinterTemplateNodeBlock(node);

        case "br":
          return new PrinterTemplateNodeStaticText(node, "\n");

        case "feed":
          return new PrinterTemplateNodeFeed(node);
        case "fill":
        case "hr":
          return new PrinterTemplateNodeFill(node);
        case "cut":
          return new PrinterTemplateNodeCut(node);
        case "cashbox":
          return new PrinterTemplateNodeCashbox(node);
        case "beep":
          return new PrinterTemplateNodeBeep(node);
        case "qr":
          return new PrinterTemplateNodeQR(node);
        case "barcode":
          return new PrinterTemplateNodeBarcode(node);
        case "script":
          if (node.props.find(it => it.name === "skip")) return null;
          return new PrinterTemplateNodeScript(node);
        case "img":
          return new PrinterTemplateNodeImage(node);
        case "tr":
        case "tr-bitmap":
          return new PrinterTemplateNodeRow(node);
        case "td":
          return new PrinterTemplateNodeCol(node);
        case "template":
          return new PrinterTemplateNodeTemplate(node, compileNode);
        case "graph":
          return new PrinterTemplateNodeGraph(node);
        case "graph-qr":
          return new PrinterTemplateNodeGraphQR(node);
        case "graph-bar":
          return new PrinterTemplateNodeGraphBar(node);
        default:
          if (node.tag.startsWith("components-") || node.tag.startsWith("page-") || node.tag === "component") {
            return new PrinterTemplateCustomComponent(node, "thermal");
          }
          console.warn("Unhandled node tag", node);
      }
      break;
    case 3 as ElementTypes.TEMPLATE:
      return new PrinterTemplateNodeTemplate(node, compileNode);
  }
  return null;
}

export function compileExpression(node: ExpressionNode) {
  switch (node.type) {
    case 4 as NodeTypes.SIMPLE_EXPRESSION:
      if (node.isStatic) {
        return new Expression(node.content, true, node);
      } else {
        return new Expression(node.content, false, node);
      }
      break;
    default:
      console.warn("Unhandled node tag", node);
      break;
  }
  return new Expression(null, true);
}

export function compileProps(props: (AttributeNode | DirectiveNode)[]) {
  const result: Record<string, Expression> = {};
  for (let prop of props) {
    if (prop.type === 6) {
      result[prop.name] = new Expression(prop.value?.content, true, prop);
    } else if (prop.type === 7 && prop.name === "bind") {
      const arg = compileExpression(prop.arg as any);
      const exp = compileExpression(prop.exp as any);

      const key = arg.eval({});
      if (key) {
        result[key] = exp;
      }
    }
  }
  return result;
}

export type ExecutionContext = any;

export interface TextFragment {
  text: string;
  debugLoc?: any;
}

export abstract class PrinterTemplateNodeBase<T> {
  constructor(public node: Node) {
    const props = (this.node as any)?.props as (AttributeNode | DirectiveNode)[];
    if (props) {
      this.props = compileProps(props);
      for (let prop of props) {
        if (prop.type === 7) {
          if (prop.name === "for") {
            const parts = ((prop.exp as any)?.content as string).split(" in ");
            const first = parts[0].trim().replace(/\(/g, "").replace(/\)/g, "");

            const exp = parts[1];
            this.loop = new Expression(exp);
            this.loopVar = first.split(",")[0].trim();
            this.loopIndex = (first.split(",")[1] || "").trim();
          } else if (prop.name === "if" || prop.name === "else-if") {
            this.cond = compileExpression(prop.exp as any);
            if (prop.name === "else-if") this.condNeedFailed = true;
          } else if (prop.name === "else") {
            this.condNeedFailed = true;
          }
        }
      }
    }
  }

  props: Record<string, Expression> = {};

  deleteProp(key: string) {
    Vue.delete(this.props, key);
    this.elementNode.props = this.elementNode.props.filter(it => it.name !== key);
  }

  setProp(key: string, value: string) {
    const curProp = this.props[key];
    if (curProp) {
      curProp.expression = value;
      (curProp.node as AttributeNode).value.content = value;
      return;
    }
    const node = {
      type: 6 as NodeTypes.ATTRIBUTE,
      name: key,
      value: {
        type: 2 as NodeTypes.TEXT,
        content: value,
        loc: this.node.loc,
      },
      loc: this.node.loc,
    } as AttributeNode;
    this.elementNode.props.push(node);
    Vue.set(this.props, key, new Expression(value, true, node));
  }

  updateCond() {
    if (this.cond) {
      this.elementNode.props = this.elementNode.props.filter(
        it => it.name !== "if" && it.name !== "else-if" && it.name !== "else",
      );
      if (this.cond) {
        const node: DirectiveNode = {
          type: 7 as NodeTypes.DIRECTIVE,
          name: this.condNeedFailed ? "else-if" : "if",
          exp: {
            type: 4 as NodeTypes.SIMPLE_EXPRESSION,
            constType: 0,
            content: this.cond.expression,
            isStatic: this.cond.isStatic,
            loc: this.node.loc,
          },
          arg: null,
          modifiers: [],
          loc: this.node.loc,
        };
        this.elementNode.props.push(node);
      } else if (this.condNeedFailed) {
        const node: DirectiveNode = {
          type: 7 as NodeTypes.DIRECTIVE,
          name: "else",
          exp: null,
          arg: null,
          modifiers: [],
          loc: this.node.loc,
        };
        this.elementNode.props.push(node);
      }
    }
  }

  cond: Expression;
  loop: Expression;
  loopVar: string;
  loopIndex: string;
  condNeedFailed = false;
  debugLoc: any;

  get textNode() {
    return this.node as TextNode;
  }

  get interpolationNode() {
    return this.node as InterpolationNode;
  }

  get elementNode() {
    return this.node as ElementNode;
  }

  abstract executeInner(sequence: T, context: ExecutionContext): Promise<void> | void;

  getDebugLoc() {
    if (!this.debugLoc) {
      this.debugLoc = {
        start: this.node.loc.start.offset,
        end: this.node.loc.end.offset,
        sl: this.node.loc.start.line,
        el: this.node.loc.end.line,
        sc: this.node.loc.start.column,
        ec: this.node.loc.end.column,
      };
    }
    return this.debugLoc;
  }

  flatten(set: Set<PrinterTemplateNodeBase<T>>) {
    set.add(this);
  }

  walk(
    cb: (node: PrinterTemplateNodeBase<T>, parent: PrinterTemplateNodeBase<T>) => void,
    parent: PrinterTemplateNodeBase<T> = null,
  ) {
    cb(this, parent);
  }

  async execute(sequence: T, context: ExecutionContext, lastCondFailed = false): Promise<boolean> {
    if (this.condNeedFailed && !lastCondFailed) {
      return false;
    }
    if (this.cond) {
      if (!this.cond.eval(context)) return true;
    }
    if ((sequence as any).includeDebug && this.node?.loc) {
      (sequence as any).debugLoc = this.getDebugLoc();
    }
    if (this.loop) {
      let list = this.loop.eval(context);
      if (list) {
        if (typeof list === "number") {
          list = new Array(list).fill(null).map((it, idx) => [idx, idx + 1]);
        } else if (Array.isArray(list)) {
          list = list.map((it, idx) => [idx, it]);
        } else if (typeof list === "object") {
          list = Object.entries(list);
        }

        for (let [k, v] of list) {
          if (this.loopVar) context[this.loopVar] = v;
          if (this.loopIndex) context[this.loopIndex] = k;

          await this.executeInner(sequence, context);
        }
      }
    } else {
      await this.executeInner(sequence, context);
    }
    return false;
  }

  getTextInner(context: ExecutionContext): TextFragment[] {
    return [];
  }

  getTextValue(context: ExecutionContext) {
    return this.getText(context)
      .map(it => it.text)
      .join("");
  }

  getText(context: ExecutionContext) {
    if (this.cond) {
      if (!this.cond.eval(context)) return [];
    }

    if (this.loop) {
      const result: TextFragment[][] = [];
      let list = this.loop.eval(context);
      if (list) {
        if (typeof list === "number") {
          list = new Array(list).fill(null).map((it, idx) => [idx, idx + 1]);
        } else if (Array.isArray(list)) {
          list = list.map((it, idx) => [idx, it]);
        } else if (typeof list === "object") {
          list = Object.entries(list);
        }

        for (let [k, v] of list) {
          if (this.loopVar) context[this.loopVar] = v;
          if (this.loopIndex) context[this.loopIndex] = k;

          result.push(this.getTextInner(context));
        }
      }
      return result.flat();
    }

    return this.getTextInner(context);
  }

  getPropValue<T>(context: ExecutionContext, key: string, def?: T): T {
    const prop = this.props[key];
    if (!prop) return def;
    const val = prop.eval(context);
    if (val === undefined) return def;
    if (typeof def === "number") {
      const nval = +val;
      if (isNaN(nval)) return def;
      return nval as any;
    } else if (typeof def === "boolean") {
      return val && ((val !== "0" && val !== "false") as any);
    }
    return val;
  }

  clone(): this {
    const node = structuredClone(this.node);
    return new (this.constructor as any)(node);
  }
}

export class PrinterTemplateNodeTemplate<T> extends PrinterTemplateNodeBase<T> {
  constructor(
    node: Node,
    public compileNode: (elem: Node) => PrinterTemplateNodeBase<T>,
  ) {
    super(node);
    this.children = (this.templateNode.children || []).map(it => compileNode(it)).filter(it => !!it) as any[];
  }

  get templateNode() {
    return this.node as TemplateNode;
  }

  flatten(set: Set<PrinterTemplateNodeBase<T>>) {
    super.flatten(set);
    for (let item of this.children) {
      item.flatten(set);
    }
  }

  walk(
    cb: (node: PrinterTemplateNodeBase<T>, parent: PrinterTemplateNodeBase<T>) => void,
    parent: PrinterTemplateNodeBase<T> = null,
  ) {
    super.walk(cb, parent);
    for (let item of this.children) {
      item.walk(cb, this);
    }
  }

  children: PrinterTemplateNodeBase<T>[];

  async executeInner(sequence: T, context: ExecutionContext) {
    let lastCondFailed = false;
    for (let item of this.children) {
      lastCondFailed = await item.execute(sequence, context, lastCondFailed);
    }
  }

  getTextInner(context: ExecutionContext): TextFragment[] {
    return this.children.flatMap(item => item.getText(context));
  }

  replaceChild(oldChild: PrinterTemplateNodeBase<T>, newChild: PrinterTemplateNodeBase<T>) {
    const idx = this.children.indexOf(oldChild);
    if (idx !== -1) {
      if (newChild) {
        this.children.splice(idx, 1, newChild);
        this.templateNode.children.splice(idx, 1, newChild.node as any);
      } else {
        this.children.splice(idx, 1);
        this.templateNode.children.splice(idx, 1);
      }
    }
  }

  moveChild(idx: number, newIdx: number, count = 1) {
    if (newIdx < 0) return;
    const child = this.children.splice(idx, count);
    this.children.splice(newIdx, 0, ...child);

    const node = this.templateNode.children.splice(idx, count);
    this.templateNode.children.splice(newIdx, 0, ...node);
  }

  insertChild(child: PrinterTemplateNodeBase<T>, idx: number) {
    this.children.splice(idx, 0, child);
    this.templateNode.children.splice(idx, 0, child.node as any);
  }

  deleteChild(child: PrinterTemplateNodeBase<T>, migrateCondition = true) {
    const idx = this.children.indexOf(child);
    if (idx !== -1) {
      if (migrateCondition && child.cond) {
        const nextNode = this.children[idx + 1];
        if (nextNode && nextNode.condNeedFailed) {
          nextNode.condNeedFailed = child.condNeedFailed;
          if (nextNode.cond) {
            // v-else-if
            nextNode.cond = child.cond.and(nextNode.cond);
          } else {
            // v-else
            nextNode.cond = child.cond.not();
          }
          nextNode.updateCond();
        }
      }

      this.children.splice(idx, 1);
      this.templateNode.children.splice(idx, 1);
    }
  }

  clone(): this {
    const node = structuredClone(this.node);
    const clone = new (this.constructor as any)(node, this.compileNode);
    return clone;
  }
}

export class PrinterTemplateCustomComponent<T> extends PrinterTemplateNodeBase<T> {
  constructor(
    node: Node,
    public printerType: "label" | "thermal" = "thermal",
  ) {
    super(node);
  }

  get componentName() {
    if (this.elementNode.tag.startsWith("page-")) {
      return this.elementNode.tag.slice(5);
    }
    return this.elementNode.tag;
  }

  async executeInner(sequence: T, context: ExecutionContext) {
    let componentName = this.componentName;

    if (componentName === "component") {
      componentName = this.props["is"]?.eval(context);
    }

    const inner: PrinterTemplateNodeBase<T> = await context.resolveTemplate?.(componentName, this.printerType);
    if (!inner) {
      console.warn("Component not found", componentName);
    } else {
      const innerContext = {
        ...context,
      };
      for (let [k, v] of Object.entries(this.props)) {
        innerContext[k] = v.eval(context);
      }
      await inner.execute(sequence, innerContext);
    }
  }

  clone(): this {
    const node = structuredClone(this.node);
    return new (this.constructor as any)(node, this.printerType);
  }
}

export class PrinterTemplateNodeRoot<T> extends PrinterTemplateNodeTemplate<T> {}

export class PrinterTemplateNodeText extends PrinterTemplateNodeBase<PrintSequence> {
  executeInner(sequence: PrintSequence, context: ExecutionContext): void {
    sequence.rawText(sequence.getText(this.textNode.content));
  }

  getTextInner(context: ExecutionContext) {
    return [{ text: this.textNode.content, debugLoc: context.includeDebug && this.getDebugLoc() }];
  }

  get content() {
    return this.textNode.content;
  }

  set content(value: string) {
    this.textNode.content = value;
  }

  static createFromText(text: string) {
    return new PrinterTemplateNodeText({
      type: 2 as NodeTypes.TEXT,
      content: text,
      loc: {
        source: "",
        start: { offset: 0, line: 0, column: 0 },
        end: { offset: text.length, line: 0, column: text.length },
      },
    } as TextNode);
  }
}

export abstract class PrinterTemplateNodeInterpolationBase<T> extends PrinterTemplateNodeBase<T> {
  constructor(node: Node) {
    super(node);
    this.expression = compileExpression(this.interpolationNode.content);
  }

  expression: Expression;

  getTextInner(context: ExecutionContext): TextFragment[] {
    let r: string = this.expression.eval(context);
    if (typeof r === "number") {
      r = `${r}`;
    } else if (r && typeof r !== "string") {
      console.warn(r);
      r = "";
    } else if (!r) {
      r = "";
    }
    return [{ text: r, debugLoc: context.includeDebug && this.getDebugLoc() }];
  }
}

export class PrinterTemplateNodeInterpolation extends PrinterTemplateNodeInterpolationBase<PrintSequence> {
  executeInner(sequence: PrintSequence, context: ExecutionContext): void {
    sequence.rawText(sequence.getText(this.getTextValue(context)));
  }

  static createFromExpression<T>(expression: string) {
    const node: InterpolationNode = {
      type: 5 as NodeTypes.INTERPOLATION,
      content: {
        type: 4 as NodeTypes.SIMPLE_EXPRESSION,
        content: expression,
        isStatic: false,
        loc: {
          source: "",
          start: { offset: 0, line: 0, column: 0 },
          end: { offset: expression.length, line: 0, column: expression.length },
        },
        constType: 0 as ConstantTypes.NOT_CONSTANT,
      },
      loc: {
        source: "",
        start: { offset: 0, line: 0, column: 0 },
        end: { offset: expression.length, line: 0, column: expression.length },
      },
    };
    return new PrinterTemplateNodeInterpolation(node);
  }
}

export class PrinterTemplateNodeTemplateElement<T> extends PrinterTemplateNodeTemplate<T> {
  get elementNode() {
    return this.node as ElementNode;
  }
}

const StylesSymbol = Symbol("StylesSymbol");
function applyFontSize(
  self: PrinterTemplateNodeBase<PrintSequence>,
  sequence: PrintSequence,
  context: ExecutionContext,
) {
  const inherited = curInheritedStyle(self, context);
  let msize: number = inherited?.fontSize?.size;
  let sizes: [number, number] = inherited?.fontSize?.sizes;
  let yScale = 1;

  let dirty = false;

  if (self.props["size"]) {
    const size = self.props["size"].eval(context);
    if (typeof size === "string" && size.indexOf(",") !== -1) {
      sizes = [(msize = +size.split(",")[0]), +size.split(",")[1]];
      sequence.fontSize(sizes[0], sizes[1]);
      yScale = (sizes[1] + 1) / (sizes[0] + 1);
      dirty = true;
    } else if (typeof size === "string" || typeof size === "number") {
      sizes = [(msize = +size), +size];
      sequence.fontSize(msize, msize);
      dirty = true;
    }
  }

  let finalPxSize = dirty ? (msize || 0) * 12 + 24 : inherited?.fontSize?.pxSize || 24;
  const fontSize = self.props["fontSize"]?.eval?.(context);
  if (typeof fontSize === "string" || typeof fontSize === "number") {
    finalPxSize = +fontSize;
    dirty = true;
  }

  if (dirty) {
    // @ts-ignore
    sequence.pxSize = finalPxSize;

    curStyle(self, context).fontSize = {
      size: msize,
      sizes,
      pxSize: finalPxSize,
    };
  }

  return {
    size: msize,
    sizes,
    pxSize: finalPxSize,
    yScale,
  };
}

function applyAlign(self: PrinterTemplateNodeBase<PrintSequence>, sequence: PrintSequence, context: ExecutionContext) {
  let malign = curInheritedStyle(self, context).align || "left";
  if (self.props["align"]) {
    const align = self.props["align"].eval(context);
    if (align === "left") {
      sequence.left();
    } else if (align === "right") {
      malign = "right";
      sequence.right();
    } else if (align === "center") {
      malign = "center";
      sequence.center();
    }
    curStyle(self, context).align = malign;
  }
  return malign;
}

function applyBold(self: PrinterTemplateNodeBase<PrintSequence>, sequence: PrintSequence, context: ExecutionContext) {
  let mbold: boolean = undefined;
  if (self.props["weight"]) {
    const weight = self.props["weight"].eval(context);
    if (weight === "normal") {
      sequence.bold(false);
      mbold = false;
    } else if (weight === "bold" || !weight) {
      sequence.bold(true);
      mbold = true;
    }
    curStyle(self, context).bold = mbold;
  }
  return mbold;
}

function applyColor(self: PrinterTemplateNodeBase<PrintSequence>, sequence: PrintSequence, context: ExecutionContext) {
  let mcolor: number = undefined;
  if (self.props["color"]) {
    const color = self.props["color"].eval(context);
    if (color === "red" || color == 1 || color === true) {
      mcolor = 1;
      sequence.color(1);
    } else {
      mcolor = 0;
      sequence.color(0);
    }
    curStyle(self, context).color = mcolor;
  }
  return mcolor;
}

function applyTight(self: PrinterTemplateNodeBase<PrintSequence>, sequence: PrintSequence, context: ExecutionContext) {
  let mtight = curInheritedStyle(self, context).tight ?? true;
  const tight = self.props["tight"]?.eval?.(context);
  if (typeof tight === "boolean" || +tight === 0) {
    mtight = false;
    curStyle(self, context).tight = mtight;
  }
  return mtight;
}

function applyItalic(self: PrinterTemplateNodeBase<PrintSequence>, sequence: PrintSequence, context: ExecutionContext) {
  let italic = curInheritedStyle(self, context).italic || false;
  const italicE = self.props["fontStyle"];
  if (italicE) {
    const italicV = italicE.eval(context);
    if (
      (typeof italicV === "boolean" && italicV) ||
      (!isNaN(+italicV) && +italicV) ||
      italicV === "true" ||
      italicV === "italic"
    ) {
      italic = true;
      // @ts-ignore
      sequence.italic?.(true);
    } else {
      italic = false;
      // @ts-ignore
      sequence.italic?.(false);
    }
    curStyle(self, context).italic = italic;
  }

  return italic;
}

function applyFont(self: PrinterTemplateNodeBase<PrintSequence>, sequence: PrintSequence, context: ExecutionContext) {
  if (self.props["font"]) {
    const font = self.props["font"].eval(context);
    if (typeof font === "string" || typeof font === "number") {
      sequence.font(+font);
    }
    curStyle(self, context).font = font;
  }
}

function curStyle(self: PrinterTemplateNodeBase<PrintSequence>, context: ExecutionContext) {
  const styles = context[StylesSymbol] || (context[StylesSymbol] = []);
  const last = styles[styles.length - 1];
  return last?.style || {};
}

function curInheritedStyle(self: PrinterTemplateNodeBase<PrintSequence>, context: ExecutionContext) {
  const styles = context[StylesSymbol] || (context[StylesSymbol] = []);
  const last = styles[styles.length - 1];
  return last?.inherited || {};
}

function pushStyle(self: PrinterTemplateNodeBase<PrintSequence>, context: ExecutionContext) {
  const styles = context[StylesSymbol] || (context[StylesSymbol] = []);
  const last = styles[styles.length - 1];
  const cur = {
    inherited: {
      ...(last?.inherited || {}),
      ...(last?.style || {}),
    },
    style: {},
  };
  styles.push(cur);
  return cur;
}

function popStyle(self: PrinterTemplateNodeBase<PrintSequence>, sequence: PrintSequence, context: ExecutionContext) {
  const style = context[StylesSymbol]?.pop?.();
  if (!style) return;

  if (style.style.fontSize) {
    const sizes = style.inherited.fontSize?.sizes;
    sequence.fontSize(sizes?.[0] ?? 0, sizes?.[1] ?? 0);
    // @ts-ignore
    sequence.pxSize = sizes?.pxSize ?? 24;
  }

  if (style.style.align) {
    const align = style.inherited.align || "left";
    if (align === "left") {
      sequence.left();
    } else if (align === "right") {
      sequence.right();
    } else if (align === "center") {
      sequence.center();
    }
  }

  if (style.style.bold !== undefined) {
    sequence.bold(style.inherited.bold);
  }

  if (style.style.italic !== undefined) {
    // @ts-ignore
    sequence.italic?.(style.inherited.italic);
  }

  if (style.style.color !== undefined) {
    sequence.color(style.inherited.color);
  }

  if (style.style.font !== undefined) {
    sequence.font(style.inherited.font);
  }
}

export class PrinterTemplateNodeTemplateElementImpl extends PrinterTemplateNodeTemplateElement<PrintSequence> {
  constructor(node: Node) {
    super(node, compileNode);
  }
}

export class PrinterTemplateNodeBlock extends PrinterTemplateNodeTemplateElementImpl {
  get elementNode() {
    return this.node as ElementNode;
  }

  async executeInner(sequence: PrintSequence, context: ExecutionContext) {
    pushStyle(this, context);
    const { pxSize } = applyFontSize(this, sequence, context);
    const align = applyAlign(this, sequence, context);
    const bold = applyBold(this, sequence, context);
    const color = applyColor(this, sequence, context);
    const tight = applyTight(this, sequence, context);
    const italic = applyItalic(this, sequence, context);

    switch (this.elementNode.tag) {
      case "div":
        sequence.raw(sequence.getPreLine());
        context.divLevel = (context.divLevel || 0) + 1;
        break;
    }
    if (this.elementNode.tag === "text-break") {
      if (sequence.includeDebug) {
        context.includeDebug = true;
        const rawContent = this.getText(context);
        const content: string[] = rawContent
          .map(i => i.text)
          .join("")
          .split("\n");
        const table = sequence.ntable();
        const lines = getTableLines(rawContent, content, table, align);
        table.column(lines, { size: 0, align: align, padding: 0 });
        table.print();
      } else {
        const content = this.getTextValue(context);
        sequence.textWithBreak(content);
      }
    } else if (this.elementNode.tag === "pre") {
      if (sequence.includeDebug) {
        context.includeDebug = true;
        const rawContent = this.getText(context);
        const content: string[] = rawContent
          .map(i => i.text)
          .join("")
          .split("\n");
        const table = sequence.ntable();
        const lines = getTableLines(rawContent, content, table, align, true);
        table.column(lines, { size: 0, align: align, padding: 0 });
        table.print();
      } else {
        const content = this.getTextValue(context);
        sequence.text(content);
      }
    } else if (this.elementNode.tag === "text-bitmap") {
      const content = this.getTextValue(context);
      let hiRes: boolean | number = true;

      const sHiRes = this.props["res"]?.eval?.(context);
      if (typeof sHiRes === "boolean" || +sHiRes === 0) {
        hiRes = false;
      } else if (typeof sHiRes === "string" || !isNaN(+sHiRes)) {
        hiRes = +sHiRes;
      }
      if (sequence.bitmapText) {
        if(!await sequence.bitmapText({
          text: content,
          align: align as any,
          size: pxSize,
          fontFamily: this.props["fontFamily"]?.eval?.(context),
          italic,
          color,
          bold,
          hiRes,
          tight,
        })) {
          sequence.text(content);
        }
      } else {
        sequence.text(content);
      }
    } else {
      await super.executeInner(sequence, context);
    }
    switch (this.elementNode.tag) {
      case "div":
        sequence.raw(sequence.getPostLine());
        sequence.raw(sequence.getLine());
        context.divLevel--;
        // @ts-ignore
        sequence.flushLine?.();
        break;
      case "br":
        if (context.divLevel) {
          sequence.raw(sequence.getPostLine());
          sequence.raw(sequence.getLine());
          // @ts-ignore
          sequence.flushLine?.();
          sequence.raw(sequence.getPreLine());
        } else {
          sequence.feed(5);
        }
        break;
    }

    popStyle(this, sequence, context);
  }

  get isPre() {
    return this.elementNode.tag === "pre";
  }

  set isPre(value: boolean) {
    this.elementNode.tag = value ? "pre" : "span";
  }

  get isRoot() {
    return this.elementNode.tag === "div" || this.elementNode.tag === "text-bitmap";
  }

  get isBitmap() {
    return this.elementNode.tag === "text-bitmap";
  }

  set isBitmap(value: boolean) {
    this.elementNode.tag = value ? "text-bitmap" : "div";
  }

  static createFromText(text: string) {
    const elem: ElementNode = {
      type: 1 as NodeTypes.ELEMENT,
      tag: "div",
      tagType: 0 as ElementTypes.ELEMENT,
      props: [],
      children: [
        {
          type: 2 as NodeTypes.TEXT,
          content: text,
          loc: {
            source: "",
            start: { offset: 0, line: 0, column: 0 },
            end: { offset: text.length, line: 0, column: text.length },
          },
        },
      ],
      loc: {
        source: "",
        start: { offset: 0, line: 0, column: 0 },
        end: { offset: text.length, line: 0, column: text.length },
      },
      codegenNode: null,
      isSelfClosing: false,
      ns: 0,
    };
    return new PrinterTemplateNodeBlock(elem);
  }

  static wrap(node: PrinterTemplateNodeBase<PrintSequence>, tag: string = "span") {
    const elem: ElementNode = {
      type: 1 as NodeTypes.ELEMENT,
      tag,
      tagType: 0 as ElementTypes.ELEMENT,
      props: [],
      children: [node.node as any],
      loc: {
        source: "",
        start: { offset: 0, line: 0, column: 0 },
        end: { offset: 0, line: 0, column: 0 },
      },
      codegenNode: null,
      isSelfClosing: false,
      ns: 0,
    };
    return new PrinterTemplateNodeBlock(elem);
  }
}

export class PrinterTemplateNodeStaticText extends PrinterTemplateNodeTemplateElementImpl {
  constructor(
    node: Node,
    public text: string,
  ) {
    super(node);
  }

  getTextInner(context: any): TextFragment[] {
    return [{ text: this.text, debugLoc: context.includeDebug && this.getDebugLoc() }];
  }

  clone(): this {
    const node = structuredClone(this.node);
    return new (this.constructor as any)(node, this.text);
  }
}

export class PrinterTemplateNodeFeed extends PrinterTemplateNodeBase<PrintSequence> {
  constructor(node: Node) {
    super(node);
  }

  executeInner(sequence: PrintSequence, context: any) {
    const n = this.props["n"]?.eval?.(context);
    sequence.feed(n ? +n : undefined);
  }

  static create() {
    const elem: ElementNode = {
      type: 1 as NodeTypes.ELEMENT,
      tag: "feed",
      tagType: 0 as ElementTypes.ELEMENT,
      props: [],
      children: [],
      loc: {
        source: "",
        start: { offset: 0, line: 0, column: 0 },
        end: { offset: 0, line: 0, column: 0 },
      },
      codegenNode: null,
      isSelfClosing: true,
      ns: 0,
    };
    return new PrinterTemplateNodeFeed(elem);
  }
}

export class PrinterTemplateNodeFill extends PrinterTemplateNodeBase<PrintSequence> {
  constructor(node: Node) {
    super(node);
  }

  executeInner(sequence: PrintSequence, context: any) {
    const n = this.props["n"]?.eval?.(context);
    sequence.fill(this.props["char"]?.eval?.(context) ?? undefined, n ? +n : undefined);
  }

  static create() {
    const elem: ElementNode = {
      type: 1 as NodeTypes.ELEMENT,
      tag: "fill",
      tagType: 0 as ElementTypes.ELEMENT,
      props: [],
      children: [],
      loc: {
        source: "",
        start: { offset: 0, line: 0, column: 0 },
        end: { offset: 0, line: 0, column: 0 },
      },
      codegenNode: null,
      isSelfClosing: true,
      ns: 0,
    };
    return new PrinterTemplateNodeFill(elem);
  }
}

export class PrinterTemplateNodeCut extends PrinterTemplateNodeBase<PrintSequence> {
  constructor(node: Node) {
    super(node);
  }

  executeInner(sequence: PrintSequence, context: any) {
    sequence.cut();
  }

  static create() {
    const elem: ElementNode = {
      type: 1 as NodeTypes.ELEMENT,
      tag: "cut",
      tagType: 0 as ElementTypes.ELEMENT,
      props: [],
      children: [],
      loc: {
        source: "",
        start: { offset: 0, line: 0, column: 0 },
        end: { offset: 0, line: 0, column: 0 },
      },
      codegenNode: null,
      isSelfClosing: true,
      ns: 0,
    };
    return new PrinterTemplateNodeCut(elem);
  }
}

export class PrinterTemplateNodeCashbox extends PrinterTemplateNodeBase<PrintSequence> {
  executeInner(sequence: PrintSequence, context: any) {
    sequence.cashBox(48, 50);
  }
}

export class PrinterTemplateNodeBeep extends PrinterTemplateNodeBase<PrintSequence> {
  executeInner(sequence: PrintSequence, context: any) {
    const n = this.props["n"]?.eval?.(context);
    const t = this.props["t"]?.eval?.(context);
    sequence.beep(n ? +n : undefined, t ? +t : undefined);
  }
}

export class PrinterTemplateNodeQR extends PrinterTemplateNodeTemplateElementImpl {
  async executeInner(sequence: PrintSequence, context: any) {
    pushStyle(this, context);
    applyAlign(this, sequence, context);

    const n = this.props["n"]?.eval?.(context) || undefined;
    const w = this.props["w"]?.eval?.(context) || undefined;
    const url = this.getTextValue(context);
    sequence.printQR(url, typeof n === "undefined" ? n : +n, typeof w === "undefined" ? w : +w);

    popStyle(this, sequence, context);
  }
}

export class PrinterTemplateNodeBarcode extends PrinterTemplateNodeTemplateElementImpl {
  async executeInner(sequence: PrintSequence, context: any) {
    pushStyle(this, context);
    applyAlign(this, sequence, context);

    const n = this.props["type"]?.eval?.(context) ?? "code128";
    const chars = this.props["chars"]?.eval?.(context) ?? "below";
    const h = this.props["h"]?.eval?.(context);

    const url = this.getTextValue(context);
    sequence.printCode(n, url, chars, h !== undefined ? +h : undefined);

    popStyle(this, sequence, context);
  }
}

export class PrinterTemplateNodeImage extends PrinterTemplateNodeBase<PrintSequence> {
  async executeInner(sequence: PrintSequence, context: any) {
    pushStyle(this, context);
    applyAlign(this, sequence, context);

    await sequence.printImageTag(this.props["tag"]?.eval?.(context));

    popStyle(this, sequence, context);
  }
}

let graphSymbol = Symbol("graphSymbol");

export class PrinterTemplateNodeGraph extends PrinterTemplateNodeTemplate<PrintSequence> {
  constructor(node: Node) {
    super(node, compileNode);
  }

  async executeInner(sequence: PrintSequence, context: any) {
    if (context[graphSymbol]) {
      await super.executeInner(sequence, context);
      return;
    }

    pushStyle(this, context);
    applyAlign(this, sequence, context);

    const w = +(this.props["w"]?.eval?.(context) ?? "0");
    const h = +(this.props["h"]?.eval?.(context) ?? "0");

    // @ts-ignore
    const graph = sequence.graph?.(w, h);
    if (graph) {
      context[graphSymbol] = graph;
      await super.executeInner(sequence, context);
      context[graphSymbol] = null;
      await graph.print();
    }

    popStyle(this, sequence, context);
  }
}

export class PrinterTemplateNodeGraphQR extends PrinterTemplateNodeTemplateElementImpl {
  async executeInner(sequence: PrintSequence, context: ExecutionContext) {
    if (context[graphSymbol]) {
      const graph: PrintGraph = context[graphSymbol];
      const x = this.getPropValue(context, "x", 0);
      const y = this.getPropValue(context, "y", 0);
      const w = this.getPropValue(context, "w", 0);
      const h = this.getPropValue(context, "h", 0);
      const s = this.getPropValue(context, "s", 0);
      const v = this.getPropValue(context, "v", 0);
      const e = this.getPropValue(context, "e", "M");

      const content = this.getTextValue(context);

      graph.qrCode(content, x, y, w, h, v, s, e);
    } else {
      await super.executeInner(sequence, context);
    }
  }
}

export class PrinterTemplateNodeGraphBar extends PrinterTemplateNodeTemplateElementImpl {
  async executeInner(sequence: PrintSequence, context: ExecutionContext) {
    if (context[graphSymbol]) {
      const graph: PrintGraph = context[graphSymbol];
      const x = this.getPropValue(context, "x", 0);
      const y = this.getPropValue(context, "y", 0);
      const w = this.getPropValue(context, "w", 0);
      const h = this.getPropValue(context, "h", 0);
      const s = this.getPropValue(context, "s", 1);
      const type = this.getPropValue(context, "type", "code39");

      const content = this.getTextValue(context);

      graph.barCode(content, type, x, y, w, h, s);
    } else {
      await super.executeInner(sequence, context);
    }
  }
}

let tableSymbol = Symbol("tableSymbol");

export class PrinterTemplateNodeRow extends PrinterTemplateNodeTemplate<PrintSequence> {
  constructor(node: Node) {
    super(node, compileNode);
  }

  async executeInner(sequence: PrintSequence, context: ExecutionContext) {
    if (context[tableSymbol]) {
      await super.executeInner(sequence, context);
      return;
    }

    pushStyle(this, context);
    const { pxSize, yScale } = applyFontSize(this, sequence, context);
    applyFont(this, sequence, context);
    const tight = applyTight(this, sequence, context);
    const italic = applyItalic(this, sequence, context);
    const bold = applyBold(this, sequence, context);
    const color = applyColor(this, sequence, context);

    const table = sequence.ntable();
    context[tableSymbol] = table;
    await super.executeInner(sequence, context);
    context[tableSymbol] = null;

    if (this.props["colPadding"]) {
      // @ts-ignore
      table.colPadding = +this.props.colPadding.eval(context);
    }

    if (this.elementNode.tag === "tr-bitmap") {
      let hiRes: boolean | number = true;
      const sHiRes = this.props["res"]?.eval?.(context);
      if (typeof sHiRes === "boolean" || +sHiRes === 0) {
        hiRes = false;
      } else if (typeof sHiRes === "string" || !isNaN(+sHiRes)) {
        hiRes = +sHiRes;
      }

      if (sequence.bitmapTable) {
        await sequence.bitmapTable(table, {
          // @ts-ignore
          fontSize: pxSize,
          // @ts-ignore
          size: pxSize,
          scaleY: yScale,
          fontFamily: this.props["fontFamily"]?.eval?.(context),
          italic,
          bold,
          color,
          hiRes,
          tight,
        });
      } else {
        table.print();
      }
    } else {
      table.print();
    }

    popStyle(this, sequence, context);
  }

  get isBitmap() {
    return this.elementNode.tag === "tr-bitmap";
  }

  set isBitmap(value: boolean) {
    this.elementNode.tag = value ? "tr-bitmap" : "tr";
  }

  static createFromText(texts: string[]) {
    const cols: ElementNode[] = texts.map(text => {
      const elem: ElementNode = {
        type: 1 as NodeTypes.ELEMENT,
        tagType: 1 as ElementTypes.COMPONENT,
        tag: "td",
        ns: 0,
        isSelfClosing: false,
        props: [],
        loc: {
          source: "",
          start: { offset: 0, line: 0, column: 0 },
          end: { offset: text.length, line: 0, column: text.length },
        },
        codegenNode: null,
        children: [
          {
            type: 2 as NodeTypes.TEXT,
            content: text,
            loc: {
              source: "",
              start: { offset: 0, line: 0, column: 0 },
              end: { offset: text.length, line: 0, column: text.length },
            },
          },
        ],
      };
      return elem;
    });
    const elem: ElementNode = {
      type: 1 as NodeTypes.ELEMENT,
      tagType: 1 as ElementTypes.COMPONENT,
      tag: "tr",
      ns: 0,
      isSelfClosing: false,
      props: [],
      loc: {
        source: "",
        start: { offset: 0, line: 0, column: 0 },
        end: { offset: 0, line: 0, column: 0 },
      },
      codegenNode: null,
      children: cols,
    };

    return new PrinterTemplateNodeRow(elem);
  }
}

export class PrinterTemplateNodeCol extends PrinterTemplateNodeTemplateElementImpl {
  async executeInner(sequence: PrintSequence, context: ExecutionContext) {
    if (context[tableSymbol]) {
      const table: PrintTable = context[tableSymbol];
      const align = this.props["align"]?.eval?.(context) ?? "left";
      const valign = this.props["valign"]?.eval?.(context) ?? "top";
      const padding = this.props["padding"]?.eval?.(context);
      const size = +(this.props["n"]?.eval?.(context) ?? 0);

      let color: number = undefined;
      let bold: boolean = undefined;
      let weight: number = undefined;
      let italic: boolean = undefined;
      let fontFamily: string = undefined;
      let fontSize: number = undefined;
      let inverted: boolean = undefined;

      if (this.props["weight"]) {
        const weightV = this.props["weight"].eval(context);
        if (weightV === "normal") {
          bold = false;
        } else if (weightV === "bold" || !weightV) {
          bold = true;
        }
      }
      if (this.props["color"]) {
        const colorV = this.props["color"].eval(context);
        if (colorV === "red" || colorV == 1) {
          color = 1;
        } else {
          color = 0;
        }
      }
      if (this.props["w"]) {
        weight = +this.props["w"].eval(context);
        if (isNaN(weight)) {
          weight = undefined;
        }
      }
      if (this.props["fontStyle"]) {
        const weightV = this.props["fontStyle"].eval(context);
        if (weightV === "normal") {
          italic = false;
        } else if (weightV === "italic" || !weightV) {
          italic = true;
        }
      }
      if (this.props["fontFamily"]) {
        fontFamily = this.props["fontFamily"].eval(context);
      }
      if (this.props["fontSize"]) {
        fontSize = +this.props["fontSize"].eval(context);
      }
      if (this.props["inverted"]) {
        inverted = this.props["inverted"].eval(context) !== false;
      }

      if ((sequence as any).includeDebug) {
        context.includeDebug = true;
      }

      const rawContent = this.getText(context);
      const content: string[] = rawContent
        .map(i => i.text)
        .join("")
        .split("\n");

      if ((sequence as any).includeDebug) {
        const lines = getTableLines(rawContent, content, table, align);
        // @ts-ignore
        table.column(lines, {
          size,
          align,
          valign,
          padding,
          bold,
          color,
          weight,
          italic,
          fontFamily,
          fontSize,
          // @ts-ignore
          inverted,
        });
      } else {
        // @ts-ignore
        table.column(content, {
          size,
          align,
          valign,
          padding,
          bold,
          color,
          weight,
          italic,
          fontFamily,
          fontSize,
          // @ts-ignore
          inverted,
        });
      }
    } else {
      await super.executeInner(sequence, context);
    }
  }

  static createFromText(text: string) {
    const elem: ElementNode = {
      type: 1 as NodeTypes.ELEMENT,
      tagType: 1 as ElementTypes.COMPONENT,
      tag: "td",
      ns: 0,
      isSelfClosing: false,
      props: [],
      loc: {
        source: "",
        start: { offset: 0, line: 0, column: 0 },
        end: { offset: text.length, line: 0, column: text.length },
      },
      codegenNode: null,
      children: [
        {
          type: 2 as NodeTypes.TEXT,
          content: text,
          loc: {
            source: "",
            start: { offset: 0, line: 0, column: 0 },
            end: { offset: text.length, line: 0, column: text.length },
          },
        },
      ],
    };
    return new PrinterTemplateNodeCol(elem);
  }
}

export class PrinterTemplateNodeScript<T> extends PrinterTemplateNodeBase<T> {
  constructor(node: Node) {
    super(node);
    const inner = this.elementNode.children[0] as TextNode;
    this.expression = new Expression(inner?.content ?? "");
  }

  expression: Expression;

  executeInner(sequence: T, context: ExecutionContext): void {
    this.expression.eval(context);
  }
}

export class Expression {
  constructor(
    public expression: string | null,
    public isStatic: boolean = false,
    public node: Node = null,
  ) {
    if (!this.isStatic) {
      this.func = compileAngular(expression || "");
    }
  }

  func: (context: any) => any;

  eval(context: ExecutionContext) {
    return this.func ? this.func(context) : this.expression === undefined ? true : this.expression;
  }

  not() {
    const exp = `!(${this.expression})`;
    return new Expression(exp, false, {
      type: 4 as NodeTypes.SIMPLE_EXPRESSION,
      content: exp,
    } as SimpleExpressionNode);
  }

  and(other: Expression) {
    const exp = `(${this.expression}) && (${other.expression})`;
    return new Expression(exp, false, {
      type: 4 as NodeTypes.SIMPLE_EXPRESSION,
      content: exp,
    } as SimpleExpressionNode);
  }
}

function getTableLines(rawContent: TextFragment[], content: string[], table: PrintTable, align: string, pre?: boolean) {
  const locWithOffset = rawContent.reduce(
    (acc, cur) => {
      if (acc.length === 0) {
        return [[0, cur.text.length, cur.debugLoc]] as [number, number, any][];
      } else {
        const ofs = acc[acc.length - 1][1];
        acc.push([ofs, ofs + cur.text.length, cur.debugLoc]);
        return acc;
      }
    },
    [] as [number, number, any][],
  );

  let prevOfs = 0;
  const lines = content.map(it => {
    while (locWithOffset.length && locWithOffset[0][1] <= prevOfs) {
      locWithOffset.shift();
    }
    const debugLocs: [number, number, any][] = [];
    const end = prevOfs + it.length + 1;
    while (locWithOffset.length && locWithOffset[0][0] < end) {
      const isEnded = locWithOffset[0][1] <= end;
      const item = isEnded ? locWithOffset.shift() : locWithOffset[0];
      debugLocs.push([Math.max(0, item[0] - prevOfs), Math.max(0, item[1] - prevOfs), item[2]]);
      if (!isEnded) {
        break;
      }
    }
    const line = new TextLine(table, it, { align, debugLocs, pre });
    prevOfs += it.length + 1;
    return line;
  });
  return lines;
}

// #region Label

export function compileLabel(source: string) {
  const ast = parse(source, {
    whitespace: "condense",
  });

  // console.log(ast);

  const node = compileLabelNode(ast);

  // console.log(node);

  return node;
}

export function compileLabelNode(node: Node): PrinterTemplateNodeBase<LabelSequenceBase> | null {
  switch (node.type) {
    case 0 as NodeTypes.ROOT:
      return new PrinterTemplateNodeRoot(node, compileLabelNode);
    case 1 as NodeTypes.ELEMENT:
      return compileLabelElementNode(node as ElementNode);
    case 2 as NodeTypes.TEXT:
      return new PrinterTemplateNodeTextLabel(node);
    case 3 as NodeTypes.COMMENT:
      break;
    case 5 as NodeTypes.INTERPOLATION:
      return new PrinterTemplateNodeInterpolationLabel(node);
    default:
      console.warn("Unhandled node type", node);
  }
  return null;
}

export function compileLabelElementNode(node: ElementNode): PrinterTemplateNodeBase<LabelSequenceBase> | null {
  switch (node.tagType) {
    case 0 as ElementTypes.ELEMENT:
    case 1 as ElementTypes.COMPONENT:
      switch (node.tag.toLowerCase()) {
        case "script":
          if (node.props.find(it => it.name === "skip")) return null;
          return new PrinterTemplateNodeScript(node);
        case "template":
          return new PrinterTemplateNodeTemplate(node, compileLabelNode);

        case "text":
        case "vtext":
        case "div":
        case "span":
        case "pre":
          return new PrinterTemplateNodeLabelText(node);

        case "br":
          return new PrinterTemplateNodeLabelStaticText(node, "\n");

        case "counter":
          return new PrinterTemplateNodeLabelCounter(node);

        case "img":
          return new PrinterTemplateNodeLabelImage(node);

        case "qr":
          return new PrinterTemplateNodeLabelQR(node);
        case "barcode":
          return new PrinterTemplateNodeLabelBarcode(node);

        case "rect":
          return new PrinterTemplateNodeLabelRect(node);

        case "page":
          return new PrinterTemplateNodeLabelPage(node);

        default:
          if (node.tag.startsWith("components-")) {
            return new PrinterTemplateCustomComponent(node, "label");
          }
          console.warn("Unhandled node tag", node);
      }
      break;
    case 3 as ElementTypes.TEMPLATE:
      return new PrinterTemplateNodeTemplate(node, compileLabelNode);
  }
  return null;
}

export class PrinterTemplateNodeTextLabel extends PrinterTemplateNodeBase<LabelSequenceBase> {
  executeInner(sequence: LabelSequenceBase, context: ExecutionContext): void {}

  getTextInner(context: ExecutionContext) {
    return [{ text: this.textNode.content, debugLoc: context.includeDebug && this.getDebugLoc() }];
  }

  get content() {
    return this.textNode.content;
  }

  set content(value: string) {
    this.textNode.content = value;
  }

  static createFromText(text: string) {
    const elem: TextNode = {
      type: 2 as NodeTypes.TEXT,
      content: text,
      loc: {
        source: "",
        start: { offset: 0, line: 0, column: 0 },
        end: { offset: text.length, line: 0, column: text.length },
      },
    };
    return new PrinterTemplateNodeTextLabel(elem);
  }
}

export class PrinterTemplateNodeTemplateElementLabelImpl extends PrinterTemplateNodeTemplateElement<LabelSequenceBase> {
  constructor(node: Node) {
    super(node, compileLabelNode);
  }
}

export class PrinterTemplateNodeInterpolationLabel extends PrinterTemplateNodeInterpolationBase<LabelSequenceBase> {
  async executeInner(sequence: LabelSequenceBase, context: ExecutionContext) {}

  static createFromExpression<T>(expression: string) {
    const node: InterpolationNode = {
      type: 5 as NodeTypes.INTERPOLATION,
      content: {
        type: 4 as NodeTypes.SIMPLE_EXPRESSION,
        content: expression,
        isStatic: false,
        loc: {
          source: "",
          start: { offset: 0, line: 0, column: 0 },
          end: { offset: expression.length, line: 0, column: expression.length },
        },
        constType: 0 as ConstantTypes.NOT_CONSTANT,
      },
      loc: {
        source: "",
        start: { offset: 0, line: 0, column: 0 },
        end: { offset: expression.length, line: 0, column: expression.length },
      },
    };
    return new PrinterTemplateNodeInterpolationLabel(node);
  }
}

export class PrinterTemplateNodeLabelPage extends PrinterTemplateNodeTemplateElementLabelImpl {
  async executeInner(sequence: LabelSequenceBase, context: ExecutionContext) {
    sequence.reset();
    if (this.props["size"]) {
      const size = this.props["size"].eval(context);
      if (typeof size === "string" && size.indexOf(",") !== -1) {
        sequence.size(+size.split(",")[0], +size.split(",")[1]);
      } else if (typeof size === "string" || typeof size === "number") {
        sequence.size(+size, +size);
      }
    } else {
      sequence.size(40, 30);
    }

    if (this.props["gap"]) {
      const size = this.props["gap"].eval(context);
      if (typeof size === "string" && size.indexOf(",") !== -1) {
        sequence.gap(+size.split(",")[0], +size.split(",")[1]);
      } else if (typeof size === "string" || typeof size === "number") {
        sequence.gap(+size, +size);
      }
    } else {
      sequence.gap(1.5, 0);
    }

    const rotate = this.getPropValue(context, "r", undefined);
    const direction = this.getPropValue(context, "d", undefined);

    if (rotate !== undefined) {
      sequence.rotate(+rotate);
    }

    if (direction !== undefined) {
      sequence.direction(+direction);
    }

    // @ts-ignore
    sequence.page?.();

    await super.executeInner(sequence, context);

    const num = this.props["num"] ? +this.props["num"].eval(context) || 1 : 1;
    sequence.print(num);

    if (rotate !== undefined) {
      sequence.rotate(0);
    }

    if (direction !== undefined) {
      sequence.direction(0);
    }
  }
}

export class PrinterTemplateNodeLabelCounter extends PrinterTemplateNodeTemplateElementLabelImpl {
  async executeInner(sequence: LabelSequenceBase, context: ExecutionContext) {
    sequence.counter(
      this.getPropValue(context, "id", 0),
      this.getPropValue(context, "step", 1),
      this.getPropValue(context, "start", "1"),
    );
  }
}

export class PrinterTemplateNodeLabelStaticText extends PrinterTemplateNodeTemplateElementLabelImpl {
  constructor(
    node: Node,
    public text: string,
  ) {
    super(node);
  }

  getTextInner(context: any): TextFragment[] {
    return [{ text: this.text, debugLoc: context.includeDebug && this.getDebugLoc() }];
  }

  clone(): this {
    const node = structuredClone(this.node);
    return new (this.constructor as any)(node, this.text);
  }
}

export class PrinterTemplateNodeLabelDrawing extends PrinterTemplateNodeTemplateElementLabelImpl {
  getPosition(context: ExecutionContext, sequence: LabelSequenceBase) {
    if (this.props["pos"]) {
      const size = this.props["pos"].eval(context);
      if (typeof size === "string" && size.indexOf(",") !== -1) {
        return [
          this.parseDelta(size.split(",")[0], sequence.lastX),
          this.parseDelta(size.split(",")[1], sequence.lastY),
        ];
      } else if (typeof size === "string" || typeof size === "number") {
        return [+size, +size];
      }
    } else {
      return [this.getPropValue(context, "x", 0), this.getPropValue(context, "y", 0)];
    }
  }

  getSize(context: ExecutionContext) {
    if (this.props["size"]) {
      const size = this.props["size"].eval(context);
      if (typeof size === "string" && size.indexOf(",") !== -1) {
        return [+size.split(",")[0], +size.split(",")[1]];
      } else if (typeof size === "string" || typeof size === "number") {
        return [+size, +size];
      }
    } else {
      return [this.getPropValue(context, "w", 0), this.getPropValue(context, "h", 0)];
    }
  }

  parseDelta(value: string, origin: number) {
    if (value.startsWith("+") || value.startsWith("-")) {
      return +value + origin;
    } else {
      return +value;
    }
  }
}

export class PrinterTemplateNodeLabelText extends PrinterTemplateNodeLabelDrawing {
  async executeInner(sequence: LabelSequenceBase, context: ExecutionContext) {
    const pos = this.getPosition(context, sequence);
    const text = this.getTextValue(context);
    const chinese = this.getPropValue(context, "chinese", Buffer.from(text).length > text.length ? true : false);
    const font = this.getPropValue(context, "font", chinese ? sequence.chineseFont : "1");
    const align = this.getPropValue(context, "align", "left");
    const fontSize = this.getPropValue(context, "fontSize", 1);
    const stroke = this.getPropValue(context, "stroke", false as boolean);

    const rotate = this.getPropValue(context, "r", undefined);

    if (rotate !== undefined) {
      sequence.rotate(+rotate);
    }

    if (stroke) {
      sequence.stroke(true);
    }

    if (this.elementNode.tag.toLocaleLowerCase() === "vtext") {
      await sequence.vtext(text, pos[0], pos[1], font, fontSize);
    } else {
      const size = this.getSize(context);
      await sequence.text(text, pos[0], pos[1], size[0], align, size[1] === 0 ? undefined : size[1], font, fontSize);
    }

    if (rotate !== undefined) {
      sequence.rotate(0);
    }

    if (stroke) {
      sequence.stroke(false);
    }
  }

  get isPre() {
    return this.elementNode.tag.toLocaleLowerCase() === "pre";
  }

  set isPre(value: boolean) {
    this.elementNode.tag = value ? "pre" : "text";
  }

  static createFromText(text: string) {
    const elem: ElementNode = {
      type: 1 as NodeTypes.ELEMENT,
      tag: "text",
      tagType: 1 as ElementTypes.COMPONENT,
      props: [],
      children: [
        {
          type: 2 as NodeTypes.TEXT,
          content: text,
          loc: {
            source: "",
            start: { offset: 0, line: 0, column: 0 },
            end: { offset: text.length, line: 0, column: text.length },
          },
        },
      ],
      loc: {
        source: "",
        start: { offset: 0, line: 0, column: 0 },
        end: { offset: text.length, line: 0, column: text.length },
      },
      codegenNode: null,
      isSelfClosing: false,
      ns: 0,
    };
    return new PrinterTemplateNodeLabelText(elem);
  }

  static wrap(node: PrinterTemplateNodeBase<LabelSequenceBase>) {
    const elem: ElementNode = {
      type: 1 as NodeTypes.ELEMENT,
      tag: "span",
      tagType: 1 as ElementTypes.COMPONENT,
      props: [],
      children: [node.node as any],
      loc: {
        source: "",
        start: { offset: 0, line: 0, column: 0 },
        end: { offset: 0, line: 0, column: 0 },
      },
      codegenNode: null,
      isSelfClosing: false,
      ns: 0,
    };
    return new PrinterTemplateNodeLabelText(elem);
  }
}

export class PrinterTemplateNodeLabelRect extends PrinterTemplateNodeLabelDrawing {
  async executeInner(sequence: LabelSequenceBase, context: ExecutionContext) {
    const pos = this.getPosition(context, sequence);
    const size = this.getSize(context);

    sequence.rect(pos[0], pos[1], size[0], size[1]);
  }
}

export class PrinterTemplateNodeLabelQR extends PrinterTemplateNodeLabelDrawing {
  async executeInner(sequence: LabelSequenceBase, context: ExecutionContext) {
    const pos = this.getPosition(context, sequence);
    const ecc = this.getPropValue(context, "ecc", "H");
    const width = this.getPropValue(context, "w", 4);
    sequence.qrcode(this.getTextValue(context), pos[0], pos[1], ecc, width);
  }
}

export class PrinterTemplateNodeLabelBarcode extends PrinterTemplateNodeLabelDrawing {
  async executeInner(sequence: LabelSequenceBase, context: ExecutionContext) {
    const pos = this.getPosition(context, sequence);
    const height = this.getPropValue(context, "h", 10);
    const type = this.getPropValue(context, "type", "ean13");
    const width = this.getPropValue(context, "w", 2);
    sequence.barcode(this.getTextValue(context), pos[0], pos[1], height, type, width);
  }
}

export class PrinterTemplateNodeLabelImage extends PrinterTemplateNodeLabelDrawing {
  async executeInner(sequence: LabelSequenceBase, context: ExecutionContext) {
    const pos = this.getPosition(context, sequence);
    await sequence.printImageTag(
      pos[0],
      pos[1],
      this.getPropValue(context, "tag", null),
      this.getPropValue(context, "url", null),
    );
  }
}

// #endregion
