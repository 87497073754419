
import { Component, Vue, Prop } from "@feathers-client";
import { EditorConfig } from "./plugin";
import { AppApplication } from "serviceTypes";
import "./editor.scss";
import EditorMulti from "./EditorMulti.vue";
import HeaderRender from "domore-table/HeaderRender.vue";
import type { EditorPageMixin } from "./mixins/EditorPageMixin";

@Component({
  components: {
    EditorMulti,
    HeaderRender,
  },
})
export default class EditorSideMenu extends Vue {
  @Prop()
  config: EditorConfig;

  @Prop(Boolean)
  readonly: boolean;

  @Prop(Boolean)
  canReload: boolean;

  @Prop(Boolean)
  canRemove: boolean;

  @Prop(Boolean)
  canCancel: boolean;

  @Prop(Boolean)
  canSave: boolean;

  @Prop()
  pageMixin: EditorPageMixin<any>;

  @Prop()
  item: any;

  get hasContent() {
    return this.hasCreate || this.hasModified || this.canReloadInner || this.canRemoveInner || this.config?.help
  }

  get canReloadInner() {
    return this.canReload || !!this.pageMixin?.itemId;
  }

  get canRemoveInner() {
    return (this.canRemove || this.pageMixin?.canRemove) && (!this?.acl || this.acl(this, "remove", this.item));
  }

  get canCancelInner() {
    return this.canCancel || !!this.pageMixin;
  }

  get canSaveInner() {
    return this.canSave || !!this.pageMixin;
  }

  get acl() {
    return this.config.acl && this.$schemas.aclList[this.config.acl];
  }

  doRemove() {
    if (this.pageMixin) {
      this.pageMixin.remove();
    } else {
      this.$emit("remove");
    }
  }

  doReload() {
    if (this.pageMixin) {
      this.pageMixin.confirmReload();
    } else {
      this.$emit("reload");
    }
  }

  doCancel() {
    if (this.pageMixin) {
      this.pageMixin.goBack();
    } else {
      this.$emit("cancel");
    }
  }

  get hasCreate() {
    return !!this.config?.item?.schema?.fields?.find?.((it) => it.name === "date");
  }

  get hasCreateByAdmin() {
    const admin = this.config?.item?.schema?.fields?.find?.((it) => it.name === "admin");
    return admin && this.config.getHeader(admin);
  }

  get hasCreateByUser() {
    const admin = this.config?.item?.schema?.fields?.find?.((it) => it.name === "user");
    return admin && this.config.getHeader(admin);
  }

  get hasModified() {
    return !!this.config?.item?.schema?.fields?.find?.((it) => it.name === "modified");
  }

  get hasModifiedBy() {
    const admin = this.config?.item?.schema?.fields?.find?.((it) => it.name === "modifiedBy");
    return admin && this.config.getHeader(admin);
  }

  get hasDeleted() {
    return !!this.config?.item?.schema?.fields?.find?.((it) => it.name === "deletedAt");
  }

  restoreItem() {
    this.item.deletedAt = null;
    this.pageMixin?.markDirty?.();
  }
}
