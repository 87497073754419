import { render, staticRenderFns } from "./EditSeparateProductTable.vue?vue&type=template&id=c78d50b0"
import script from "./EditSeparateProductTable.vue?vue&type=script&lang=js"
export * from "./EditSeparateProductTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/home/jason/p/boxs/server/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c78d50b0')) {
      api.createRecord('c78d50b0', component.options)
    } else {
      api.reload('c78d50b0', component.options)
    }
    module.hot.accept("./EditSeparateProductTable.vue?vue&type=template&id=c78d50b0", function () {
      api.rerender('c78d50b0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/boxs/EditSeparateProductTable.vue"
export default component.exports