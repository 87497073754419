import { ns } from "../messageQueue";
import { EventEmitter } from "events";
import { getVersion } from "../nativeIntegrations";
import Vue from "vue";

export interface UsbDeviceInfo {
  name: string;
  vendorId: number;
  productId: number;
  product?: string;
  manufacturer?: string;
  serial?: string;
}

export interface UsbConfigEndpoint {
  endpointNumber: number;
  maxPacketSize: number;
  direction: number;
  attributes: number;
  type: number;
  interval: number;
  address: number;
}

export interface UsbConfigInterface {
  id: number;
  name: string;
  iclass: number;
  subclass: number;
  protocol: number;

  endpoints: UsbConfigEndpoint[];
}

export interface UsbConfig {
  id: number;
  name: string;
  interfaces: UsbConfigInterface[];
}

export function getDevices() {
  return ns("usb").call<{
    devices: UsbDeviceInfo[];
  }>("getDevices");
}

export function getConfig(name: string) {
  return ns("usb").call<UsbConfig[]>("getConfig", name);
}

export function getDeviceInfo(name: string) {
  return ns("usb").call<UsbDeviceInfo>("getDeviceInfo", name);
}

export class UsbDevice extends EventEmitter {
  closed = false;
  handle: string;
  readers: UsbReader[] = [];

  constructor(
    public name: string,
    public id: string,
  ) {
    super();
    this.handle = ns("usb").on("detach", d => {
      if (!this.handle) return;
      if (name === d.name) {
        this.close();
      }
    });
  }

  async startRead(ifId: number, epId: number) {
    const reader = this.startReader(ifId, epId);
    reader.on("data", d => this.emit("data", d));
    reader.on("error", d => this.emit("error", d));
    reader.on("end", d => this.emit("end", d));
    reader.on("stop", d => this.emit("stop", d));
    return reader.inited;
  }

  startReader(ifId: number, epId: number) {
    let reader = this.readers.find(it => it.ifId === ifId && it.epId === epId);
    if (!reader) {
      reader = new UsbReader(this, ifId, epId);
    }
    return reader;
  }

  public async readControlTransfer(
    requestType: number,
    request: number,
    requestValue: number,
    requestIndex: number,
    size: number,
    timeout: number,
  ): Promise<Buffer> {
    const resp = await ns("usb").call(
      "readControlTransfer",
      this.id,
      requestType,
      request,
      requestValue,
      requestIndex,
      size,
      timeout,
    );
    return Buffer.from(resp, "base64");
  }

  public writeControlTransfer(
    requestType: number,
    request: number,
    requestValue: number,
    requestIndex: number,
    buffer: string | Buffer | number[],
    size: number,
    timeout: number,
  ): Promise<void> {
    if (buffer instanceof Buffer) buffer = buffer.toString("base64");
    return ns("usb").call(
      "writeControlTransfer",
      this.id,
      requestType,
      request,
      requestValue,
      requestIndex,
      buffer,
      size,
      timeout,
    );
  }

  public async getRawDescriptors() {
    const resp = await ns("usb").call<string>("getRawDescriptors", this.id);
    return Buffer.from(resp, "base64");
  }

  async send(data: Buffer) {
    const buf = Array(data.length);
    for (let i = 0; i < data.length; i++) buf[i] = data[i];
    await ns("usb").call("send", this.id, buf);
  }

  async close() {
    if (this.closed) return;
    this.closed = true;
    if(this.handle) {
      ns("usb").off(this.handle);
      this.handle = null;
    }
    for (let reader of this.readers) {
      reader.stop();
    }
    this.readers = [];
    this.emit("close");
    await ns("usb").call("close", this.id);
    this.removeAllListeners();
  }
}

export class UsbReader extends EventEmitter {
  constructor(
    public device: UsbDevice,
    public ifId: number,
    public epId: number,
  ) {
    super();
    this.device.readers.push(this);
    this.inited = this.init();
  }

  inited: Promise<void>;

  async init() {
    await Vue.nextTick();
    try {
      let multiReader = false;
      try {
        multiReader = await ns("usb").call("supportsMultiReader");
      } catch (e) {}
      if (multiReader) {
        this.handleData = ns("usb").on(`data/${this.device.id}/${this.ifId}/${this.epId}`, d => {
          this.emit("data", d);
        });
        this.handleError = ns("usb").on(`error/${this.device.id}/${this.ifId}/${this.epId}`, d => {
          this.emit("error");
          this.stop();
        });
        this.handleEnd = ns("usb").on(`end/${this.device.id}/${this.ifId}/${this.epId}`, d => {
          this.emit("end");
          this.stop();
        });
      } else {
        this.handleData = ns("usb").on(`data/${this.device.id}`, d => {
          this.emit("data", d);
        });
        this.handleError = ns("usb").on(`error/${this.device.id}`, d => {
          this.emit("error");
          this.stop();
        });
        this.handleEnd = ns("usb").on(`end/${this.device.id}`, d => {
          this.emit("end");
          this.stop();
        });
      }
      await ns("usb").call("startRead", this.device.id, this.ifId, this.epId);
    } catch (e) {
      console.warn(e);
      this.stop();
    }
  }

  handleData: string;
  handleError: string;
  handleEnd: string;

  stop() {
    if (this.handleData) {
      ns("usb").off(this.handleData);
      this.handleData = null;
    }
    if (this.handleError) {
      ns("usb").off(this.handleError);
      this.handleError = null;
    }
    if (this.handleEnd) {
      ns("usb").off(this.handleEnd);
      this.handleEnd = null;
    }
    this.emit("stop");
    this.removeAllListeners();
    const idx = this.device.readers.indexOf(this);
    idx !== -1 && this.device.readers.splice(idx, 1);
  }
}

const driverNameDict: Record<string, string> = {};

export function getDriverNameDict() {
  return driverNameDict;
}

export async function connect(name, allowWriteOnly = false, driverName?: string) {
  if (!(await ns("usb").call("requestUserPermission", name))) {
    throw new Error("Not granted");
  }
  if(driverName) {
    driverNameDict[name] = driverName;
  }
  return new UsbDevice(name, await ns("usb").call("connect", name, allowWriteOnly));
}

export function supported() {
  if (!getVersion()) return Promise.resolve(false);
  return Promise.race([
    ns("usb").call<boolean>("supported"),
    new Promise<boolean>(resolve => setTimeout(() => resolve(false), 15000)),
  ]).catch(e => false);
}

let attachHandler;
let detachHandler;

export function init($root: Vue) {
  if (attachHandler) return;
  attachHandler = ns("usb").on("attach", (device: UsbDeviceInfo) => {
    $root.$emit("deviceAttach", device);
  });
  detachHandler = ns("usb").on("detach", (device: UsbDeviceInfo) => {
    $root.$emit("deviceDetach", device);
  });
}
