
import { EventEmitter } from 'events'
import Vue from 'vue'
import type { Paginated } from '@feathersjs/feathers'
import _ from 'lodash'
import { getID } from '@feathers-client';

export let ObservableArray = null;

export abstract class ListLoaderBase<T> extends EventEmitter {
    abstract readonly store: T[];

    abstract readonly loaded: boolean;
    limit : number = 10;
    loading = false;
    pageStart : number = 0;
    session: number = 0;

    abstract readonly total : number;
    abstract reset(delay: boolean);

    setPageStart(pageStart : number) {
        this.pageStart = pageStart;
        this.reset(false);
        return this.execute();
    }

    executor: Promise<void> | null = null;

    execute(props?: any) {
        // console.log('try exec', {
        //     executor: !!this.executor,
        //     loaded: this.loaded
        // });
        if (this.executor) return this.executor;
        if (this.loaded) return Promise.resolve();
        this.executor = this._executeCore(props);
        return this.executor;
    }

    abstract executeCore(props?: any) : Promise<void>;
    async _executeCore(props?: any) {
        const session = this.session;
        try {
            this.loading = true;
            await this.executeCore(props);
            // console.log('loaded', this.data.length);
        } catch (e : any) {
            console.log(e.message);
            console.log(e.stack);
        } finally {
            if (session === this.session) {
                this.loading = false;
                this.executor = null;
            }
        }
    }

    setLimit(limit : number) {
        this.limit = limit;
    }
}

export default class ListLoader<T, TTypes = any, TPath extends keyof TTypes = any> extends ListLoaderBase<T> {
    store: T[] = [];
    data : any = ObservableArray ? new ObservableArray([]) : null;
    _set: Map<string, T> = new Map();
    $feathers!: any
    loaded: boolean = false;

    query: any = {};
    params: any = {};
    path: TPath;
    service: any;
    cursor: number = 0;
    total: number = 0;
    noPaginate: boolean = false;
    noCache : number = -1;
    continuous = false;
    ensureUnique = false;
    $root : Vue
    cache : any
    
    apiLocks : (() => void)[] = null;

    constructor(
        path: TPath,
        props: {
            $root: Vue
            $feathers: any;
            query?: any;
            params?: any;
            noPaginate?: boolean
            cache? : any
            limit? : number
            ensureUnique?: boolean
        }
    ) {
        super();
        
        // console.log(props);
        this.limit = Math.max(10, props.limit || this.limit);
        this.$feathers = props.$feathers;
        this.path = path;
        this.query = props.query || this.query;
        this.params = props.params || this.params;
        this.noPaginate = props.noPaginate || false;
        this.$root = props.$root;
        this.cache = props.cache;
        this.ensureUnique = props.ensureUnique ?? false;
        this.service = <any>this.$feathers.service(path);
        this.execute();
    }

    setQuery(query, updateCb?: () => void) {
        const cur = JSON.stringify(this.query);
        const target = JSON.stringify(query);
        if(cur === target) return this.executor;
        this.query = query;
        updateCb?.();
        this.reset(false);
        return this.execute();
    }

    setParams(query) {
        this.params = query;
        this.reset(false);
        return this.execute();
    }

    reset(delay: boolean = true) {
        this.session++;
        this.cursor = 0;
        this.total = 0;
        this.loading = false;
        this.loaded = false;
        if (!delay) {
            this.data && this.data.splice(0, this.data.length);
            this.store.splice(0, this.store.length);
            this._set.clear();
        }
        this.noCache = -1;
        this.executor = null;
        this.apiLocks = null;
        this.emit('reset');
    }

    async executeCore(executeProps?: any) {
        const oneTimeQuery = executeProps?.query ?? {};

        let session = this.session;
        const cursor = this.cursor;
        await Vue.nextTick();
        if(this.session !== session) return;
        let cached = false;
        try {
            let query = {
                ...this.query,
                ...this.params,
                ...(this.noPaginate ? {} : {
                    $limit: this.limit,
                }),
                $skip: cursor + this.pageStart,
                ...oneTimeQuery,
            };
            query = JSON.parse(JSON.stringify(query));

            let cpaged : Paginated<T> = null;
            if(this.cache && (this.noCache === -1 || this.noCache >= cursor)) {
                cpaged = await this.cache.get(<string>this.path, query, this.$root && this.$root.$store.getters.userId);
                if(cpaged && (!cpaged.data || !Array.isArray(cpaged.data))) cpaged = null;
            }
            if (session !== this.session) return;
            const offsetIndex = this.cursor ? this.store.length : 0;
            if(cpaged) {
                // 
                this.total = cpaged.total;
                if (!this.cursor) {
                    this.data && this.data.splice(0, this.data.length);
                    this.store.splice(0, this.store.length);
                    this._set.clear();
                    await new Promise(resolve => setTimeout(resolve, 500));
                }
                this.cursor += cpaged.data.length;
                if(this.ensureUnique) {
                    cpaged.data = cpaged.data.filter(it => {
                        const id = typeof (it as any)?._id === 'object' ? JSON.stringify((it as any)._id) : getID(it);
                        if(!this._set.has(id)) {
                            this._set.set(id, it)
                            return true;
                        } else {
                            console.warn('duplicated key', id);
                            return false;
                        }
                    })
                }
                for (let item of cpaged.data) {
                    this.data && this.data.push(item);
                }
                this.store.push(...cpaged.data);
                this.emit('inserted', {
                    offset: offsetIndex,
                    items: cpaged.data,
                })
                if (cpaged.data.length === 0 || this.cursor >= cpaged.total) {
                    this.loaded = true;
                    if(this.total !== this.store.length) {
                        this.total = this.store.length;
                    }
                    this.emit('loaded');
                }
                this.loading = false;
                this.executor = null;
                cached = true;

                if(this.continuous) {
                    await new Promise<void>((resolve) => setTimeout(resolve, 500)); // delay sometime for ui update
                    if (session !== this.session) return;
                    this.execute();
                }
            }

            if(this.apiLocks) {
                await new Promise<void>(resolve => this.apiLocks.push(resolve));
                if (session !== this.session) return;
            } else {
                this.apiLocks = [];
            }

            const userId = this.$root && this.$root.$store.getters.userId
            let paged = <Paginated<T>>(<any>await this.service.find({
                query: query
            }));
            if (session !== this.session) return;
            if(this.noPaginate) {
                this.loaded = true;
                this.emit('loaded');
                paged = <any>{
                    total: (<any>paged).length,
                    data: <any>paged,
                }
            }
            if(Array.isArray(paged)) {
                console.warn(`Need noPaginate for ${String(this.path)}?`)
            }
            let pageCount = paged.data.length;
            if(this.ensureUnique) {
                paged.data = paged.data.filter(it => {
                    const id = typeof (it as any)?._id === 'object' ? JSON.stringify((it as any)._id) : getID(it);
                    if(!this._set.has(id)) {
                        this._set.set(id, it)
                        return true;
                    } else {
                        console.warn('duplicated key', id);
                        return false;
                    }
                })
            }
            this.cache && this.cache.set(paged, <string>this.path, query, userId); // async

            if(!cpaged || !_.isEqual(paged.data, cpaged.data)) {
                let handled = false;
                if(cpaged) {
                    if(cpaged.total !== paged.total || 
                        cpaged.data.length !== paged.data.length || 
                        !cpaged.data.reduce((cur, v, index) => cur && (<any>paged.data[index])._id === (<any>v)._id, true)
                    ) {
                        // need reset
                        session = ++this.session;
                        this.apiLocks = null;
                        this.total = paged.total;
                        this.loaded = offsetIndex >= this.total;
                        this.cursor = offsetIndex;
                        this.data && this.data.splice(offsetIndex, this.data.length - offsetIndex);
                        this.store.splice(offsetIndex, this.store.length - offsetIndex);
                        this.noCache = offsetIndex + paged.data.length;
                        this.emit('reset');
                        if(this.loaded) {
                            this.emit('loaded');
                        }
                    } else {
                        // do inline update
                        for(let i = 0; i < paged.data.length; i++) {
                            this.data && (this.data[i + offsetIndex] = paged.data[i]);
                            Vue.set(this.store, i + offsetIndex, paged.data[i]);
                        }
                        this.emit('updated', {
                            offset: offsetIndex,
                            items: paged.data,
                        })
                        handled = true;
                    }
                }

                if(!handled) {
                    this.total = paged.total;
                    if (!this.cursor) {
                        this.data && this.data.splice(0, this.data.length);
                        this.store.splice(0, this.store.length);
                        this._set.clear();
                        await new Promise<void>(resolve => setTimeout(resolve, 500));
                    }
                    this.cursor += pageCount;
                    for (let item of paged.data) {
                        this.data && this.data.push(item);
                        this.store.push(item);
                    }
                    if (pageCount === 0 || this.cursor >= paged.total) this.loaded = true;
                    this.emit('inserted', {
                        offset: offsetIndex,
                        items: paged.data,
                    })
                    await Vue.nextTick();
                }
            }

            if(session !== this.session) return;

            if(this.apiLocks && this.apiLocks.length) {
                this.apiLocks.shift()();
            } else {
                this.apiLocks = null;
            }

            if(this.continuous && !this.apiLocks) {
                await new Promise((resolve) => setTimeout(resolve, 500)); // delay sometime for ui update
                if(session === this.session) {
                    cached = true;
                    this.loading = false;
                    this.executor = null;
                    cached = true;
                    this.execute();
                }
            }

            // console.log('loaded', this.data.length);
        } catch (e) {
            this.loaded = true;
            this.emit('loaded');
            throw e;
        }
    }
}

export class ChunkItem<T> {
    constructor(public parent : ChunkListLoader<T>, public index: number) {
    }

    get items() {
        const idx = this.index * this.parent.columns;
        return this.parent.loader.store.slice(idx, idx + this.parent.columns);
    }
}

export class ChunkListLoader<T> extends ListLoaderBase<ChunkItem<T>> {
    constructor(public loader : ListLoaderBase<T>, public columns : number) {
        super();
    }

    setLimit(limit : number) {
        super.setLimit(limit);
        this.loader.setLimit(limit * this.columns);
    }

    setColumns(columns : number) {
        this.columns = columns;
    }

    reset(delay: boolean = true) {
        this.loader.reset(delay);
        this.emit('reset');
    }

    executeCore() {
        return this.loader.execute();
    }

    _store : ChunkItem<T>[] = [];

    get store() {
        const currentTotal = Math.floor((this.loader.store.length + this.columns - 1) / this.columns);
        if(this._store.length > currentTotal) {
            this._store.splice(currentTotal, this._store.length - currentTotal);
        } else if(this._store.length < currentTotal) {
            const start = this._store.length;
            const num = currentTotal - start;
            this._store.push(..._.times(num, i => new ChunkItem(this, start + i)))
        }
        return this._store;
    }

    get total() {
        return Math.floor((this.loader.total + this.columns - 1) / this.columns);
    }

    get loaded() {
        return this.loader.loaded;
    }
}

export class StaticListLoader<T> extends ListLoaderBase<T> {
    loaded: boolean = true;
    query: any = {};
    constructor(public store : T[]) {
        super();
    }

    reset(delay: boolean = true) {
        this.emit('reset');
    }

    executeCore() {
        this.emit('loaded');
        return Promise.resolve();
    }

    get total() {
        return this.store.length;
    }

    setQuery(query) {
      this.query = query;
    }
}
