import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import LRUCache from "lru-cache";
import _ from "lodash";
import { FindType } from "@feathers-client";
import {
  // @ts-ignore
  FindType as ServerFindType,
} from "@feathersjs/feathers";
@Component
export class CacheLoader<T = any> extends Vue {
  lruCache: LRUCache<string, Promise<T> | T>;
  queue: {
    key: string;
    resolve: (v: T) => void;
    reject: (e: any) => void;
  }[];

  @Prop()
  path: string;

  @Prop()
  args: any;

  @Prop({ default: "_id", type: String })
  itemKey: keyof T;

  @Prop()
  subscribe: boolean;
  subscribed = false;

  @Prop()
  feathers: any;

  get mfeathers() {
    return this.feathers || this.$feathers;
  }

  beforeCreate() {
    this.lruCache = new LRUCache();
    this.queue = [];
  }

  created() {
    if (this.subscribe) {
      this.subscribed = true;
      this.mfeathers.service(this.path).on("patched", this.onPatch);
    }
  }

  beforeDestroy() {
    if (this.subscribed) {
      this.mfeathers.service(this.path).off("patched", this.onPatch);
    }
  }

  onPatch(item: T) {
    const id = item[this.itemKey] as any as string;
    if (!id || !this.lruCache.has(id)) return;
    this.lruCache.set(id, item);
    this.$emit("update", item);
    this.$emit(`update:${id}`, item);
  }

  query(id: string, beginLoad: () => void, endLoad: (v?: T) => void) {
    const v = this.lruCache.get(id);
    if (v !== undefined) {
      if (v instanceof Promise) {
        beginLoad();
        v.then(endLoad, e => {
          endLoad();
        });
      } else {
        return v;
      }
    } else {
      const needSchedule = this.queue.length === 0;
      const p = new Promise<T>((resolve, reject) => {
        this.queue.push({
          key: id,
          resolve,
          reject,
        });
      });
      this.lruCache.set(id, p);
      beginLoad();
      p.then(
        v => {
          this.lruCache.set(id, v);
          endLoad(v);
        },
        e => {
          endLoad();
        },
      );

      if (needSchedule) {
        this.scheduleQueue();
      }
    }
  }

  async scheduleQueue() {
    await new Promise(resolve => setTimeout(resolve, 0));
    const q = this.queue.slice();
    this.queue.splice(0, this.queue.length);

    for (let chunk of _.chunk(q, 100)) {
      try {
        const table = (await this.mfeathers.service(this.path).find({
          query: {
            [this.itemKey]: {
              $in: chunk.map(v => v.key),
            },
            $limit: 100,
            ...(this.args || {}),
          },
        })) as any;
        const items = table.data ? table.data : table;
        if (Array.isArray(items)) {
          const itemToDict: {
            [key: string]: any;
          } = {};
          for (let item of items) {
            itemToDict[item[this.itemKey]] = item;
          }

          for (let item of chunk) {
            const v = itemToDict[item.key];
            if (v) {
              item.resolve(v);
            } else {
              item.reject(new Error("Not Found"));
            }
          }
        }
      } catch (e) {
        console.warn(e);
        for (let item of chunk) {
          item.reject(e);
        }
      }
    }
  }
}

type App = Vue["$feathers"]["_services"];

export function getCacheLoader<TPath extends keyof App>(
  context: Vue,
  path: TPath,
  args?: any,
  itemKey = "_id",
): CacheLoader<ServerFindType<TPath, App>> {
  const ref: any = context.$root;
  let cache: {
    [key: string]: CacheLoader;
  } = ref._asyncPopulates || (ref._asyncPopulates = {});
  const cacheKey = (path as string) + JSON.stringify(args || null) + itemKey;
  let loader: CacheLoader = cache[cacheKey];
  return loader;
}

export function updateCachedValue<TPath extends keyof App>(
  context: Vue,
  path: TPath,
  item: ServerFindType<TPath, App>,
  args?: any,
  itemKey = "_id",
) {
  const cache = getCacheLoader(context, path, args, itemKey);
  if (cache) {
    cache.onPatch(item);
  }
}
