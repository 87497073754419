
import Dialog from "@feathers-client/mixins/Dialog";
import { Component, mixins, Prop, FindType } from "@feathers-client";
import MenuListNum from "@feathers-client/components/MenuListNum.vue";
import { FindPopRawType } from "@feathers-client";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import EditorObjectPickerList from "@schemaEditor/EditorObjectPickerList.vue";
import { Watch } from "nuxt-property-decorator";
import uuid from 'uuid/v4';
import _ from 'lodash';
import DataTableForErp from "~/components/erp/DataTableForErp.vue";

@Component({
  components: {
    MenuListNum,
    EditorTextField,
    EditorObjectPickerList,
    DataTableForErp,
  }
})
export default class PaymentDialog extends mixins(Dialog) {

  // @Prop()
  // order: FindPopRawType<[], 'shop/erp/wholesaleOrders'> = null

  @Prop({default: 'receive'})
  recordReceiveOrPay: 'receive' | 'pay'

  @Prop()
  docType: 'mStmt' | 'inv' // monthly statement, invoice

  @Prop()
  doc: any

  loading = false;

  paymentOptions = [
    {
      name: this.$t('paymentMethod.cash'),
      value: 'cash'
    },
    {
      name: this.$t('paymentMethod.bank'),
      value: 'bank transfer'
    },
    {
      name: this.$t('subsequentPaymentOrders.erp.cheque'),
      value: 'cheque'
    },
  ]

  tableHeader = [
    this.$t('subsequentPaymentOrders.erp.invoiceID'),
    this.$t('subsequentPaymentOrders.dueDate'),
    this.$t('subsequentPaymentOrders.erp.amount'),
  ]

  minfo = null

  minfoArr: {
    name: any;
    size: any;
    mime: any;
    thumb: any;
    id: string;
    success: boolean;
    complete: boolean;
    processing: boolean;
    error: any;
    progress: number;
    canDelete: boolean;
  }[] = []

  async addFile() {
    if (this.loading) return;
    this.loading = true;
    const file = document.createElement('input');
    file.style.display = 'none';
    file.type = 'file';
    file.accept = 'image/*,.pdf';
    file.multiple = true;
    document.body.append(file);
    file.click();
    await new Promise((resolve) => file.onchange = resolve);
    if (file.files.length == 0) { this.loading = false; return; }
    await Promise.all(_.map(file.files, img => this.uploadFile(img)));
    file.remove();
    this.loading = false;
  }

  async uploadFile(mfile) {
    var data = new FormData();
    data.append('file', mfile, mfile.name);

    const info = {
      name: mfile.name,
      size: mfile.size,
      mime: mfile.type,
      thumb: null,
      id: uuid(),
      success: false,
      complete: false,
      processing: true,
      error: null,
      progress: 0
    };
    // this.minfo = info;

    try {
      const response = await this.$feathers.post(`attachments/upload`, data, {
        onUploadProgress: (progressEvent) => {
          info.progress = progressEvent.loaded / progressEvent.total;
        }
      });

      const rinfo = (response.data || {}).info || {};
      _.assign(info, rinfo);
      info.success = true;
      info.complete = true;
      info.progress = 1;
      info.processing = false;

      this.minfoArr.push({
        ...info,
        canDelete: false
      })


    } catch (e) {
      info.error = e.message;
      info.complete = true;
      info.processing = false;
    }
  }

  async downloadFile(fileID) {
    if (this.loading) return;
    this.loading = true;
    const file = await this.$feathers.service("attachments").get(
      fileID
    );
    console.log('download', file)
    const url = file.thumb ? `data:image/png;base64,${file.thumb}` : this.$attach(file._id)
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    a.target = '_blank';
    a.href = url;
    a.download = file.name;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
    this.loading = false;
  }

  deleteFile(file) {
    this.minfoArr.splice(this.minfoArr.indexOf(file), 1);
  }

  async enterCanDelete(file){
    file.canDelete = true;
    setTimeout( ()=> file.canDelete=false,
      3000
    );
  }

  paymentOption = null

  remarks: string = null

  paymentDate: any = null

  staff: string = null

  get returnObject() {
    return {
      // amountInt: this.Inputamount * 10000,
      paymentDate: this.paymentDate,
      handler: this.staff,
      files: this.minfoArr,
      remarks: this.remarks,
      paymentMethod: this.paymentOption
    }
  }

  get docAmtInt() {
    if (this.docType === 'inv')
      return this.doc.amountInt;
    if (this.docType === 'mStmt')
      return (_.sumBy(this.doc.invoices, 'amountInt'));
  }

  returnData() {
    if (this.paymentOption === null || this.paymentOption === '' ||
      this.staff === null ||
      (this.paymentDate === null || this.paymentDate === "")) {
      this.$store.commit("SET_ERROR", this.$t('subsequentPaymentOrders.erp.pleaseInputAllFields'));
      return;
    }
    this.modalResult(this.returnObject)
  }

  async beforeMount() {
    // const res = await this.$feathers.service('users').find();
  }


}
