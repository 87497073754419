
import { Component, Vue, Prop } from "@feathers-client";
import { LangArrType } from "@feathers-client/i18n";
import { defineComponent } from "site-editor/def";
// eslint-disable-next-line @typescript-eslint/no-var-requires
import * as Facebook from "fb-sdk-wrapper";
// @ts-ignore
import facebookStarSvg from "!vue-svg-loader!../../assets/facebookStar.svg";

export const componentInfo = defineComponent({
  group: "socialMedia",
  groupProps: {
    editorCols: 2,
  },
  variants: [
    {
      key: "fbReview",
    },
  ],
});

@Component({
  components: {
    facebookStarSvg,
  },
})
export default class SiteEditorSocialMedia extends Vue {
  @Prop()
  variant: string;

  @Prop()
  fbPage: string;

  ratings = null;

  currentDisplay = 5;

  async beforeMount() {
    if (this.fbPage)
      this.ratings = await this.$feathers.service("users/facebook/review").create({
        pageId: this.fbPage,
      });
  }

  get reviews() {
    return this.ratings.ratings || [];
  }

  get displayedReviews() {
    return this.reviews.slice(0, this.currentDisplay);
  }

  displayMore() {
    if (this.currentDisplay < this.reviews.length)
      this.currentDisplay = Math.min(this.reviews.length, this.currentDisplay + 5);
  }
}
