
import { Component, Prop, Vue, Watch, mixins, Ref, PropSync } from "@feathers-client";
import { PosOrder } from "~/plugins/pos/order";
import { ScanningRequest } from "./cart.vue";

@Component
export default class CartPanel extends Vue {
  @Prop()
  session: PosOrder;

  @PropSync("loading")
  loadingSync: boolean;

  @PropSync("peopleMenu")
  peopleMenuSync: boolean;

  @Prop()
  isPaying: boolean;

  @Prop()
  pending: ScanningRequest[];

  selectingCart = false;

  cleanOnUpdated = false;

  selectAll() {
    if (this.session.products.length) {
      this.selectingCart = true;
      for (let item of this.session.products) {
        item.selected = false;
      }
    }
  }

  cancelSelect() {
    this.selectingCart = false;
  }

  get isOngoing() {
    return this.session.status === "pending";
  }

  clearAllItem() {
    this.session.clearCart();
    this.$emit("updated");
  }

  get canMultiSelect() {
    return !!this.session.products?.length;
  }

  get selectableItems(): any[] {
    return this.selectingCart ? this.session.products : [];
  }

  get selectedItems() {
    return this.selectableItems.filter(it => (it as any).selected);
  }

  get selectedCartClearable() {
    return this.session.products.filter(it => it.selected);
  }

  clearSelected() {
    for (let item of this.selectedCartClearable) {
      this.session.remove(item);
    }
    if (!this.selectableItems.length) {
      this.cancelSelect();
    }
  }

  toggleSelectAll() {
    if (this.selectableItems.length === this.selectedItems.length) {
      for (let item of this.selectableItems) {
        Vue.set(item, "selected", false);
      }
    } else {
      for (let item of this.selectableItems) {
        Vue.set(item, "selected", true);
      }
    }
  }

  async print() {
    await this.session.ensureOrder();
    await this.session.printInvoice();
  }

  async saveOrder() {
    this.session.ensureOrder();
    this.session.editing = false;
  }
  
  toggleSelectMember() {
    this.peopleMenuSync = !this.peopleMenuSync;
  }
}
