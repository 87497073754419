
import { Component, FindType, Prop, Vue } from "@feathers-client";
interface OrderStatus {
  status: string;
}
@Component({})
export default class StatusPicker extends Vue {
  @Prop({ default: [] }) statusList: OrderStatus

  isOpen: boolean = false;
  status = undefined ;
  name = 'all'
  setStatus(status) {
    this.status = status;
    this.$emit('updateStatus', this.status)
    this.isOpen = false
    if(status === undefined) {
      this.name = "all"
    } else {
      this.name = status
    }
  }

  mounted() {
    this.setStatus(undefined)
  }
}
