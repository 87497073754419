
import { Vue, Component, Prop, VModel } from "@feathers-client";

@Component
export default class BoxsProfileBtn extends Vue {
  @Prop()
  shopName: string;

  @Prop()
  userName: string;

  @Prop()
  email: string;

  @Prop()
  user: any;

  @Prop()
  shop: any;

  @Prop()
  loginLoader: () => Promise<any>;

  @Prop()
  switchOtherLogin: (login: any) => Promise<any>;

  @Prop()
  switchOtherServer: (url: string) => Promise<any>;

  get finalShopName() {
    return this.shopName || this.shop?.name;
  }

  get finalEmail() {
    return this.email || this.user?.email;
  }

  get finalUserName() {
    return formatUserName(this.userName || this.user?.name);
  }

  get emailOrUser() {
    return this.finalUserName || this.finalEmail;
  }

  getEmailOrUser(user: any) {
    if (!user) return "";
    return formatUserName(user.name || user.email || user.username);
  }

  getAvatar(emailOrUser: string) {
    if (!emailOrUser) return "";
    return emailOrUser
      .split(/[^A-Za-z0-9]/)
      .map(it => it.trim()[0])
      .filter(it => !!it)
      .slice(0, 2)
      .join("");
  }

  get avatar() {
    return this.getAvatar(this.emailOrUser);
  }

  getAvatarColor(user: string) {
    return avatarColor(user);
  }

  get avatarColor() {
    return avatarColor(this.user?._id || "");
  }

  get otherLogins() {
    return this.$store.state.savedLogins.filter(it => it.user._id !== this.$store.getters.userId);
  }

  otherServerLogins: any[] = [];

  menu = false;

  async switchLogin(login) {
    this.menu = false;
    try {
      const post = await this.$feathers.post(
        "authentication",
        {
          strategy: "jwt",
          accessToken: login.accessToken,
        },
        {
          headers: {
            Authorization: "",
          },
        },
      );
      if (post.status === 200 || post.status === 201) {
        await (this.$feathers as any).logout();
        const ret = await (this.$feathers as any).authenticate({
          strategy: "jwt",
          accessToken: post.data.accessToken,
        });
        this.$emit("switchLogin", ret);
        this.$store.commit("SET_USER", ret.user);
        this.$router.replace("/");
      } else if (post.status >= 500 || post.status === 401) {
        this.$store.commit("REMOVE_LOGIN", login.user._id);
        this.$store.commit("SET_ERROR", this.$t("errors.loginExpired"));
      }
    } catch (e) {
      if(e.response?.status === 404 && e.response?.data?.error?.code === 404 || e.response?.status === 500 && e.response?.data?.error?.code === 404) {
        this.$store.commit("REMOVE_LOGIN", login.user._id);
        this.$store.commit("SET_ERROR", this.$t("errors.loginExpired"));
        return;
      }
      console.error(e);
      this.$store.commit("SET_ERROR", e.message);
    }
  }

  switchServer(portal) {
    if (this.switchOtherServer) {
      this.switchOtherServer(portal.url);
    } else {
      window.location.href = portal.url;
    }
  }

  get portals() {
    let portals = this.$config.portals || [];

    if (this.otherServerLogins.length) {
      portals = portals.filter(
        it =>
          !this.otherServerLogins.find(
            jt => (jt.domain && it.url.includes(jt.domain)) || (jt.server && it.url.includes(jt.server)),
          ),
      );
    }

    return portals;
  }

  async toggleMenu() {
    this.menu = !this.menu;
    if (this.menu) {
      if (this.loginLoader) {
        try {
          this.otherServerLogins = await this.loginLoader();
        } catch (e) {
          console.warn(e);
        }
      }
    }
  }

  doSwitchOtherLogin(login: any) {
    this.menu = false;
    if (this.switchOtherLogin) {
      this.switchOtherLogin(login);
    }
  }
}

export function formatUserName(name: any) {
  if (!name) return;
  if (typeof name === "object") {
    return [name.title, name.lastName, name.firstName].filter(v => v).join(" ");
  }
  return `${name}`;
}

export function avatarColor(str: string) {
  return RGB2HEX(HSL2RGB(...hsl(str)));
}

function djb2(str) {
  var hash = 5381;
  for (var i = 0; i < str.length; i++) {
    hash = (hash << 5) + hash + str.charCodeAt(i); /* hash * 33 + c */
  }
  return hash;
}

/**
 * Returns the hash in [h, s, l].
 * Note that H ∈ [0, 360); S ∈ [0, 1]; L ∈ [0, 1];
 *
 * @param {String} str string to hash
 * @returns {Array} [h, s, l]
 */
function hsl(str: string): [number, number, number] {
  const tS = [0.35, 0.5, 0.65];
  const tL = [0.35, 0.5, 0.65];

  var H, S, L;
  var hash = Math.abs(djb2(str));

  H = hash % 359; // note that 359 is a prime
  hash = Math.ceil(hash / 360);
  S = tS[hash % tS.length];
  hash = Math.ceil(hash / tS.length);
  L = tL[hash % tL.length];

  return [H, S, L];
}

/**
 * Convert RGB Array to HEX
 *
 * @param {Array} RGBArray - [R, G, B]
 * @returns {String} 6 digits hex starting with #
 */
const RGB2HEX = function (RGBArray: number[]) {
  var hex = "#";
  RGBArray.forEach(function (value) {
    if (value < 16) {
      hex += 0;
    }
    hex += value.toString(16);
  });
  return hex;
};

/**
 * Convert HSL to RGB
 *
 * @see {@link http://zh.wikipedia.org/wiki/HSL和HSV色彩空间} for further information.
 * @param {Number} H Hue ∈ [0, 360)
 * @param {Number} S Saturation ∈ [0, 1]
 * @param {Number} L Lightness ∈ [0, 1]
 * @returns {Array} R, G, B ∈ [0, 255]
 */
const HSL2RGB = function (H: number, S: number, L: number) {
  H /= 360;

  var q = L < 0.5 ? L * (1 + S) : L + S - L * S;
  var p = 2 * L - q;

  return [H + 1 / 3, H, H - 1 / 3].map(function (color) {
    if (color < 0) {
      color++;
    }
    if (color > 1) {
      color--;
    }
    if (color < 1 / 6) {
      color = p + (q - p) * 6 * color;
    } else if (color < 0.5) {
      color = q;
    } else if (color < 2 / 3) {
      color = p + (q - p) * 6 * (2 / 3 - color);
    } else {
      color = p;
    }
    return Math.round(color * 255);
  });
};
