

import { Context } from '@nuxt/types';
import helper from '../helper'
import _ from 'lodash'
import { Currencies, fromHumanNumberToRaw, getHumanNumber } from '.';

export default helper('currencyEditor', (ctx : Context) => {
    if(process.server) return;
    ctx.app.$schemas.registerType('currency', {
        // @ts-ignore
        component: () => import('./CurrencyEditor.vue'),
        format: 'currency'
    })

    ctx.app.$schemas.registerType('singleCurrency', {
        fromDb (self, value, root) {
          return isNaN(+value) || !root.currency ? undefined : getHumanNumber(value, root.currency);
        },
        toDb (self, value, root) {
          return isNaN(+value) || !root.currency ? undefined : fromHumanNumberToRaw(value, root.currency);
        }
    })

    ctx.app.$schemas.registerType("currencyType", {
      component: "editor-object-picker-list",
      props: {
        items: Object.entries(Currencies).filter(it => !(it[1] as any).hidden).map(it => ({
          name: it[1].name,
          _id: (it[1] as any).alias?.[0] ?? it[0],
        }))
      }
    });
});

