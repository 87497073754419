
import { Component, Prop, Vue, Watch, mixins, Ref } from "nuxt-property-decorator";

import _ from "lodash";
import uuid from "uuid/v4";
import MediaLibrary from "~/components/MediaLibrary.vue";
import draggable from "vuedraggable";

@Component({
  components: {
    MediaLibrary,
    draggable,
  },
})
export default class EditorUploader extends Vue {
  @Prop()
  label: string;

  @Prop({ default: "" })
  url: string;

  @Prop({ default: "" })
  dir: string;

  @Prop({ default: "image/*" })
  type: string;

  @Prop(Boolean)
  attachmentId: boolean;

  @Prop({ type: Boolean, default: true })
  hasCover: boolean;

  previewIndex = 0;

  @Prop()
  value: any;

  @Prop(Boolean)
  mini: boolean;

  realFiles = [];
  editingItem = null;
  model = false;
  dragging = false;
  mediaLibrary = false;

  imageAreaOverflow = false;
  scrollEnd = false;
  scrollBack = false;

  resizeObserver: ResizeObserver;
  @Ref()
  scrollArea: HTMLElement;

  @Ref()
  list: any;

  async mounted() {
    await this.loadList();
    this.checkOverFlow();

    if (this.scrollArea && ResizeObserver !== undefined) {
      this.resizeObserver = new ResizeObserver(this.checkOverFlow);
      this.resizeObserver.observe(this.scrollArea);
      if(this.list?.$el) {
        this.resizeObserver.observe(this.list.$el);
      }
    }
  }

  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }
  }

  get mfiles() {
    return this.realFiles.filter((file) => file.mime.match(/(image|video)\/.*/) && file._id);
  }

  addFiles(files) {
    const f = files.map((info) =>
      _.assign(
        {
          success: true,
          complete: true,
          processing: true,
          error: null,
          progress: 1,
        },
        info,
      ),
    );
    this.realFiles = _.concat(this.realFiles, f);
    if (!this.attachmentId) {
      this.$emit(
        "input",
        this.realFiles.filter((s) => s.success),
      );
    } else {
      this.$emit(
        "input",
        this.realFiles.filter((s) => s.success).map((it) => it._id),
      );
    }
    this.mediaLibrary = false;
  }

  async loadList() {
    let files = this.value || [];
    if (this.attachmentId && files.length) {
      const rawAttachments = (
        await (this as any).$feathers.service("attachments").find({
          query: {
            $limit: 100,
            _id: {
              $in: files,
            },
          },
        })
      ).data;

      // Make sure file seq
      const filesMap = {};
      rawAttachments.forEach((v) => {
        filesMap[v._id] = v;
      });
      files = files.map((f) => filesMap[f]);

      if (!files[0]) {
        files = this.value;
      }
    }
    this.realFiles = files
      .map((info) =>
        _.assign(
          {
            success: true,
            complete: true,
            processing: true,
            error: null,
            progress: 1,
            id: uuid(),
          },
          info,
        ),
      )
      .concat(this.realFiles.filter((it) => !it.complete));
  }

  addFile() {
    this.mediaLibrary = true;
  }

  async uploadFile(mfile) {
    const info = {
      name: mfile.name,
      size: mfile.size,
      mime: mfile.type,
      thumb: null,
      id: uuid(),
      success: false,
      complete: false,
      processing: true,
      error: null,
      progress: 0,
      _id: null,
    };
    this.realFiles.push(info);

    var reader = new FileReader();
    const rfile = new Promise<any>((resolve) => (reader.onload = resolve));
    reader.readAsDataURL(mfile);
    const e = await rfile;

    var img = new Image();
    img.crossOrigin = "anonymous";
    const rimg = new Promise<any>((resolve) => (img.onload = resolve));
    img.src = e.target.result;
    await rimg;

    var data = new FormData();
    data.append("file", mfile, mfile.name);

    try {
      const response = await (this as any).$feathers.post(`attachments/upload/${this.dir}`, data, {
        onUploadProgress: (progressEvent) => {
          info.progress = progressEvent.loaded / progressEvent.total;
        },
      });

      const rinfo = (response.data || {}).info || {};
      _.assign(info, rinfo);
      info.success = true;
      info.complete = true;
      info.progress = 1;
    } catch (e) {
      info.error = e.message;
      info.complete = true;
    }

    if (!this.attachmentId) {
      this.$emit(
        "input",
        this.realFiles.filter((s) => s.success),
      );
    } else {
      this.$emit(
        "input",
        this.realFiles.filter((s) => s.success).map((it) => it._id),
      );
    }
  }

  async selectFile(file) {
    const idx = this.mfiles.indexOf(file);
    if (idx !== -1) {
      this.model = true;
      this.previewIndex = idx;
    }
  }

  async deleteFile(item) {
    this.realFiles.splice(this.realFiles.indexOf(item), 1);

    if (!this.attachmentId) {
      this.$emit(
        "input",
        this.realFiles.filter((s) => s.success),
      );
    } else {
      this.$emit(
        "input",
        this.realFiles.filter((s) => s.success).map((it) => it._id),
      );
    }
  }

  async saveName(item) {
    this.editingItem = null;
    if (!this.attachmentId) {
      this.$emit("input", this.realFiles);
    }
  }

  isImage(file) {
    return file.mime.match(/image\/.*/);
  }

  dragenter(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.types.includes("Files")) {
      if (!this.dragging) {
        this.dragging = true;
        this.$emit("beginDrag");
      }
      e.dataTransfer.dropEffect = "copy";
      return;
    }
    if (this.dragging) {
      this.dragging = false;
      this.$emit("endDrag");
    }
    e.dataTransfer.dropEffect = "none";
  }
  dragover(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.types.includes("Files")) {
      if (!this.dragging) {
        this.dragging = true;
        this.$emit("beginDrag");
      }
      e.dataTransfer.dropEffect = "copy";
      return;
    }
    if (this.dragging) {
      this.dragging = false;
      this.$emit("endDrag");
    }
    e.dataTransfer.dropEffect = "none";
  }
  async drop(e) {
    if (!this.dragging) return;
    e.preventDefault();
    e.stopPropagation();
    this.dragging = false;
    if (e.dataTransfer.types.includes("Files")) {
      const imgs = _.filter(e.dataTransfer.files, (file) => file.type.match("^image/"));
      await Promise.all(imgs.map((img) => this.uploadFile(img)));
    }
    this.$emit("endDrag");
  }
  dragleave(e) {
    if (!this.dragging) return;
    e.preventDefault();
    e.stopPropagation();
    this.dragging = false;
    this.$emit("endDrag");
  }

  @Watch("url")
  @Watch("dir")
  @Watch("value")
  updateList() {
    this.loadList();
    this.checkOverFlow();
  }

  draggingFiles = false;
  @Watch("draggingFiles")
  onDragFile(v) {
    setTimeout(() => {
      if (!v) {
        if (!this.attachmentId) {
          this.$emit(
            "input",
            this.realFiles.filter((s) => s.success),
          );
        } else {
          this.$emit(
            "input",
            this.realFiles.filter((s) => s.success).map((it) => it._id),
          );
        }
      }
    }, 100);
  }

  toLeft() {
    if (this.scrollArea) {
      this.scrollArea.scrollBy({
        left: -120,
        top: 0,
        behavior: "smooth",
      });
      this.checkOverFlow();
    }
  }

  toRight() {
    if (this.scrollArea) {
      this.scrollArea.scrollBy({
        left: 120,
        top: 0,
        behavior: "smooth",
      });
      this.checkOverFlow();
    }
  }

  checkOverFlow() {
    if (this.scrollArea) {
      this.imageAreaOverflow = this.scrollArea.scrollWidth > this.scrollArea.clientWidth;
      this.scrollBack = this.scrollArea.scrollLeft > 0;
      this.scrollEnd =
        this.scrollArea.scrollLeft >= this.scrollArea.scrollWidth - this.scrollArea.clientWidth;
    }
  }
}
