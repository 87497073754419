
import { Component, Prop, Vue } from "@feathers-client";
import EditorDatePicker from "@schemaEditor/EditorDatePicker.vue";
@Component({
  components: {
    EditorDatePicker,
  },
})
export default class CustomDateRangePicker extends Vue {
  isOpen = false;
  @Prop() buttonClass: string;
  @Prop({ default: {} }) dateStart: Object;
  @Prop({ default: {} }) dateEnd: Object;
  @Prop() label: string;
  @Prop() startDateLabel: string;
  @Prop() endDateLabel: string;
  get dateStartSync() {
    return this.dateStart;
  }
  set dateStartSync(v) {
    this.$emit("update:dateStart", v);
  }
  get dateEndSync() {
    return this.dateEnd;
  }
  set dateEndSync(v) {
    this.$emit("update:dateEnd", v);
  }
}
