
export { Readable as ReadStream, Writable as WriteStream } from 'stream'
import { Writable } from 'stream'
import { ReadableWebToNodeStream } from 'readable-web-to-node-stream'

export function closeSync() {
}

export const constants = require('constants');
export function rmdirSync() {
}
export function stat(path, cb) {
    cb(new Error());
}
export function open(path, flag, mode, cb) {
    cb(null, 1);
}
export function close(path, cb) {
    cb();
}
export function unlink(path, cb) {
    delete fileDict[path];
    cb();
}
export function unlinkSync(path) {
  delete fileDict[path];
}

const fileDict = {};

export function createReadStream(path) {
    const blob = fileDict[path].toBlob();
    const stream = new ReadableWebToNodeStream(blob.stream());
    stream.size = blob.size;
    return stream;
}

const nop = () => void 0;

class ReadWriteBuf {
    constructor(size) {
        this.size = size;
        // the buffer
        this.buffer = Buffer.alloc(size);
        // read index
        this.iRead = 0;
        // write index
        this.iWrite = 0;
    }

    toBuffer() {
        if (this.iRead === 0 && this.iWrite === this.size) {
            return this.buffer;
        }

        const buf = Buffer.alloc(this.iWrite - this.iRead);
        this.buffer.copy(buf, 0, this.iRead, this.iWrite);
        return buf;
    }

    get length() {
        return this.iWrite - this.iRead;
    }

    get eod() {
        return this.iRead === this.iWrite;
    }

    get full() {
        return this.iWrite === this.size;
    }

    read(size) {
        let buf;
        // read size bytes from buffer and return buffer
        if (size === 0) {
            // special case - return null if no data requested
            return null;
        }

        if (size === undefined || size >= this.length) {
            // if no size specified or size is at least what we have then return all of the bytes
            buf = this.toBuffer();
            this.iRead = this.iWrite;
            return buf;
        }

        // otherwise return a chunk
        buf = Buffer.alloc(size);
        this.buffer.copy(buf, 0, this.iRead, size);
        this.iRead += size;
        return buf;
    }

    write(chunk, offset, length) {
        // write as many bytes from data from optional source offset
        // and return number of bytes written
        const size = Math.min(length, this.size - this.iWrite);
        chunk.copy(this.buffer, this.iWrite, offset, offset + size);
        this.iWrite += size;
        return size;
    }

    finish() {
        this.blob = new Blob([this.toBuffer()]);
        this.buffer = null;
    }

    toBlob() {
        if(!this.blob) {
            this.blob = new Blob([this.toBuffer()])
        }
        return this.blob;
    }
}


class WritableStream extends Writable {
    constructor(options) {
        super();
        options = options || {};
        this.bufSize = options.bufSize || 1024 * 1024;
        this.buffers = [];

        // batch mode fills a buffer completely before passing the data on
        // to pipes or 'readable' event listeners
        this.batch = options.batch || false;

        // where in the current writable buffer we're up to
        this.inPos = 0;

        this.encoding = null;
        this.blob = null;
    }

    toBuffer() {
        switch (this.buffers.length) {
            case 0:
                return null;
            case 1:
                return this.buffers[0].toBuffer();
            default:
                return Buffer.concat(this.buffers.map(rwBuf => rwBuf.toBuffer()));
        }
    }

    toBlob(mime) {
        if(!this.blob) {
            return new Blob(this.buffers.map(r => r.toBlob()), mime ? { type: mime } : undefined);
        }
        
        return this.blob;
    }

    // writable
    // event drain - if write returns false (which it won't), indicates when safe to write again.
    // finish - end() has been called
    // pipe(src) - pipe() has been called on readable
    // unpipe(src) - unpipe() has been called on readable
    // error - duh

    _getWritableBuffer() {
        if (this.buffers.length) {
            const last = this.buffers[this.buffers.length - 1];
            if (!last.full) {
                return last;
            }
        }
        const buf = new ReadWriteBuf(this.bufSize);
        this.buffers.push(buf);
        return buf;
    }
    _writeToBuffers(chunk) {
        let inPos = 0;
        const inLen = chunk.length;
        while (inPos < inLen) {
            // find writable buffer
            const buffer = this._getWritableBuffer();

            // write some data
            inPos += buffer.write(chunk, inPos, inLen - inPos);
            if(buffer.full) {
                buffer.finish();
            }
        }
    }
    async write(data, encoding, callback) {
        this.blob = null;
        if (encoding instanceof Function) {
            callback = encoding;
            encoding = 'utf8';
        }
        callback = callback || nop;

        // encapsulate data into a chunk
        let chunk = data;

        // now, do something with the chunk
        this._writeToBuffers(chunk);
        return true;
    }
    cork() {
    }
    _flush(/* destination */) {
    }
    uncork() {
        this._flush();
    }
    end(chunk, encoding, callback) {
        this.blob = null;
        const writeComplete = error => {
            if (error) {
                callback(error);
            } else {
                this._flush();
                this.emit('finish');
            }
        };
        if (chunk) {
            this.write(chunk, encoding, writeComplete);
        } else {
            writeComplete();
        }
    }
}

export let MemWritableStream = WritableStream;

export function createWriteStream(path) {
    return fileDict[path] = new WritableStream();
}