
import { Component, Vue, Prop } from "@feathers-client";
import { PageBlock } from "../def";
import { SiteEditorManager } from "..";
import EditorCheckbox from "@schemaEditor/EditorCheckbox.vue";
import EditorList from "@schemaEditor/EditorList.vue";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import EditorTranslateBox from "@schemaEditor/EditorTranslateBox.vue";
import ColorPicker from "../ColorPicker.vue";
import uuid from "uuid/v4";
import { LangArrType } from "@feathers-client/i18n";
import { SiteEditorQA } from "../components/faq/index.vue";

@Component({
  components: {
    EditorCheckbox,
    EditorList,
    EditorTextField,
    EditorTranslateBox,
    ColorPicker,
  },
})
export default class SiteEditorFaq extends Vue {
  @Prop()
  block: PageBlock;

  @Prop()
  manager: SiteEditorManager;

  getFaq() {
    return {
      id: uuid(),
    } as SiteEditorQA;
  }

  get titleDisplayModeType() {
    return ["left", "center", "right"];
  }

  get props() {
    return this.manager.defineProps(this.block, {
      title: [] as LangArrType,
      remarks: [] as LangArrType,
      faq: [] as SiteEditorQA[],
      titleDisplayMode: "left",
      fontColor: "#000000FF",
    });
  }
}
