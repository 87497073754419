
import Dialog from "@feathers-client/mixins/Dialog";
import { ProductGroupInfo, ProductInfo } from "@common/product";
import { Component, Prop, mixins } from "@feathers-client";
import { ProductType, ProductSearchType, ProductGroupType } from "@common/common";
import { FindType, getID, checkID } from "@feathers-client";
import { FindPopRawType } from "@feathers-client";
import EditorObjectPickerNew from "@schemaEditor/EditorObjectPickerNew.vue";
import _ from "lodash";

@Component({
  components: {
    EditorObjectPickerNew,
  },
})
export default class SkuSelector extends mixins(Dialog) {
  @Prop()
  product: ProductSearchType;

  @Prop()
  skus: ProductType[];

  @Prop()
  warehouse: string;

  summaryDict: Record<string, number> = null;

  get skuItems() {
    return this.skus.map(it => new ProductInfo(this as any, it));
  }

  specFilter: Record<string, any> = {};

  group: FindPopRawType<["skuSpecs"], "shop/product/groups"> = null;

  getSpecValues(spec: FindType<"shop/specs">) {
    const values = this.skus.map(it => {
      const value = it.specs?.find?.(s => String(s.spec) === String(spec._id));
      switch (spec.type) {
        case "multiOption":
        case "option":
          return value?.specValue;
        case "boolean":
        case "number":
        case "string":
        default:
          return value?.value === undefined ? undefined : `${value?.value}`;
        case "lang":
          return value?.value;
      }
    });
    return Array.from(new Set(values))
      .filter(it => !!it)
      .map(v =>
        Array.isArray(v)
          ? {
              _id: this.$td(v, "en"),
              name: v,
            }
          : {
              _id: v,
              name: v,
            },
      );
  }

  get filteredSkus() {
    return this.skuItems.filter(sku => {
      for (let [k, v] of Object.entries(this.specFilter)) {
        if (v === null || v === undefined) continue;
        const spec = sku.specs?.find?.(s => String(s.spec) === k);
        if (!spec) return false;
        const value = spec.specValue || spec.value;
        if (Array.isArray(value) && value[0].lang) {
          if (this.$td(value[0], "en") !== v) {
            return false;
          }
        } else if (String(v) !== String(value)) {
          return false;
        }
      }
      return true;
    });
  }

  async mounted() {
    const productGroups = Array.from(new Set(this.skus.map(it => it.productGroup)));
    if (productGroups.length === 1) {
      this.group = await this.$feathers.service("shop/product/groups").get(productGroups[0], {
        query: {
          $populate: ["skuSpecs"],
        },
      } as const);
    }
    const skus = await this.$feathers.service("shop/product/skus").find({
      query: {
        _id: { $in: this.skus.map(it => it._id) },
        $paginate: false,
      },
      paginate: false,
    });
    const skuDict = Object.fromEntries(skus.map(it => [String(it._id), it] as const));

    for (let sku of this.skus) {
      const newSku = skuDict[sku._id];
      if (newSku) {
        Object.assign(sku, newSku);
      }
    }

    const skuByWarehouse = _.groupBy(
      skus,
      it => getID(it.shopTable?.find?.(s => s.shop === this.$shop._id)?.warehouse) ?? this.warehouse ?? null
    );

    const summaryDict: Record<string, number> = {};

    for (let [warehouse, skus] of Object.entries(skuByWarehouse)) {
      const summaries = await this.$feathers.service("shop/inventory/summaries").find({
        query: {
          warehouse,
          sku: { $in: skus.map(it => it._id) },
          $paginate: false,
        },
        paginate: false,
      });

      for(let sku of summaries) {
        summaryDict[getID(sku.sku)] = sku.amount;
      }
    }

    this.summaryDict = summaryDict;
  }

  getInventory(item: ProductInfo) {
    if(this.summaryDict) {
      return this.summaryDict[item._id] || 0;
    }
    if(item.priceTable && item.priceTable.warehouse && checkID(item.priceTable.warehouse, this.warehouse)) {
      return "-";
    }
    return item.priceTable ? item.priceTable.cachedInventory : "-"
  }
}
