
import { Component, Prop, VModel, Vue, Watch, Ref } from "@feathers-client";
import type { SpecType, SpecValueType } from "@common/common";
import EditorObjectPickerList from "@schemaEditor/EditorObjectPickerList.vue";
import EditorTranslateBox from "@schemaEditor/EditorTranslateBox.vue";
import EditorTextField from "@schemaEditor/EditorTextField.vue";

@Component({
	components: {
		EditorObjectPickerList,
		EditorTextField,
		EditorTranslateBox,
	},
})
export default class SpecEditor extends Vue {
	styleOptions = {
		outlined: true,
		hideDetails: true,
		dense: true,
	};

	@Prop()
	item: {
		spec: string;
		specValue?: string;
		value?: any;
	};

	@Prop()
	spec: SpecType;

	get itemValue() {
		return this.item.value;
	}
	set itemValue(v) {
		if (this.spec.type === "number") {
			v = Number(v);
			if (isNaN(v)) {
				return;
			}
		}
		Vue.set(this.item, "value", v);
    this.$emit("updated");
	}
}
