
import {
    DirectiveOptions,
} from 'vue'

import { Component, Vue } from '../'

@Component
class PreventOverscroll extends Vue {
    _clientX: number
    _clientY: number
    _scrolling: boolean
    movable = true;

    created() {
        document.addEventListener('touchstart', this.startMove, { capture: true, passive: false });
        document.addEventListener('touchmove', this.canMove, { passive: false });
        document.addEventListener('touchend', this.endMove);
        document.addEventListener('gesturestart', this.gesturestart, {
            capture: true,
            passive: false,
        });
    }
    
    beforeDestroy() {
        document.removeEventListener('touchstart', this.startMove, { capture: true });
        document.removeEventListener('touchmove', this.canMove);
        document.removeEventListener('touchend', this.endMove);
        document.removeEventListener('gesturestart', this.gesturestart, {
            capture: true,
        });
    }

    gesturestart(e) {
        e.preventDefault();
        e.stopPropagation();
    }

    startMove(event) {
        if (event.targetTouches.length === 1) {
            this._clientX = event.targetTouches[0].clientX;
            this._clientY = event.targetTouches[0].clientY;
        }
    }

    canMove(event) {
        if (!this.movable) {
            if (event.targetTouches.length === 1) {
                if(this.hasScrollable(event.target, event.targetTouches[0].clientX - this._clientX, event.targetTouches[0].clientY - this._clientY)) {
                    this._scrolling = true;
                } else if(!this._scrolling) {
                    event.stopPropagation();
                    event.preventDefault();
                }
            }
        }
    }

    hasScrollable(target, clientX, clientY) {
        if(target.classList.contains('gesture-scrollable')) return true;
        if(
            target.classList.contains('scrollable') ||
            target.classList.contains('v-data-table__wrapper') ||
            target.classList.contains('v-menu__content')
        ) {
            if(target.scrollHeight > target.clientHeight && Math.abs(clientY) > Math.abs(clientX)) {
                if((target.scrollTop > 0 || clientY < 0) &&
                    (target.scrollHeight - target.scrollTop > target.clientHeight || clientY > 0)) {
                        return true;
                    }
            }
            if(target.scrollWidth > target.clientWidth && Math.abs(clientX) > Math.abs(clientY)) {
                if((target.scrollLeft > 0 || clientX < 0) &&
                    (target.scrollWidth - target.scrollLeft > target.clientWidth || clientX > 0)) {
                        return true;
                    }
            }
        }
        return target.parentElement && this.hasScrollable(target.parentElement, clientX, clientY);
    }

    endMove(event) {
        if (event.targetTouches.length === 0) {
            this._scrolling = false;
        }
    }
}

export default {
    bind(e, binding, vnode) {
        const instance = vnode.componentInstance;
        const overscroll = new PreventOverscroll({
            parent: instance as any,
        });
        (instance as any)._overscroll = overscroll;
        overscroll.movable = binding.value ?? false;
    },
    update(e, binding, vnode) {
        const instance = vnode.componentInstance;
        const overscroll: PreventOverscroll = (instance as any)._overscroll;
        if(overscroll) {
            overscroll.movable = binding.value ?? false;
        }
    },
    unbind(e, binding, vnode) {
        const instance = vnode.componentInstance;
        const overscroll: PreventOverscroll = (instance as any)._overscroll;
        if(overscroll) {
            overscroll.$destroy();
            (instance as any)._overscroll = null;
        }
    },
} as DirectiveOptions

