

import Dialog from "@feathers-client/mixins/Dialog";
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import CashierSetup from './CashierSetup.vue'

@Component({
    components: {
        CashierSetup,
    }
})
export default class CashierSetupDialog extends mixins(Dialog) {

}

