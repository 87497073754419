import moment from 'moment'

import 'moment/locale/zh-cn'
import 'moment/locale/zh-hk'
import 'moment/locale/ms-my'
import 'moment/locale/th'
import 'moment/locale/vi'
import 'moment/locale/en-au'
import 'moment/locale/ja'
import 'moment/locale/zh-tw'

export default (ctx, inject) => {
  ctx.$moment = moment
  inject('moment', moment)
}
