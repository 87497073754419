
import Dialog from "@feathers-client/mixins/Dialog";
import { Component, mixins, Prop, FindType, getID } from "@feathers-client";
import MenuListNum from "@feathers-client/components/MenuListNum.vue";
import { FindPopRawType } from "@feathers-client";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import EditorObjectPickerList from "@schemaEditor/EditorObjectPickerList.vue";
import { } from "../../../common/util-core";

@Component({
  components: {
    MenuListNum,
    EditorTextField,
    EditorObjectPickerList,
  }
})
export default class DeliveryNoteListDialog extends mixins(Dialog) {

  @Prop()
  orderID

  loading = false;

  deliveryNotes: FindType<'shop/erp/wholesaleOrder/deliveryRecord'>[] = [];

  async exportDeliveryNote(record: typeof this.deliveryNotes[number]) {
    if (this.loading) return;
    this.loading = true;
    try {
      const dnData = await this.$feathers.service('shop/erp/wholesaleOrders/exportDeliveryNote').create({
        shop: getID(this.$shop),
        orderID: this.orderID,
        dnRecordID: record._id,
      });

      if (!dnData) {
        console.log('dn error');
        this.loading = false;
        return;
      }
      const blob = new Blob([dnData['buffer']], { type: dnData['mime'] });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style.display = 'none';
      a.href = url;
      a.download = `deliveryNote_${record.deliveryNoteID ?? record._id}.pdf`;
      // a.download = `${invoice._id}`;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
      this.loading = false;
      this.$store.commit('SET_SUCCESS', this.$t('subsequentPaymentOrders.erp.exportDNSuccess'));
    } catch (e) {
      this.loading = false;
      this.$store.commit('SET_ERROR', e.message);
    }
  }

  async beforeMount() {

    this.loading = true;
    const res = await this.$feathers.service('shop/erp/wholesaleOrder/deliveryRecord').find({
      query: {
        refOrder: this.orderID,
        $paginate: false,
        $sort: { issueDate: -1 }
      },
      paginate: false
    })

    this.deliveryNotes = res

    this.loading = false;
  }


}
