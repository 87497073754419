
import { Component, Auth, Prop, Vue, FindType } from "@feathers-client";
import DatePicker from "~/components/DatePicker.vue";
import EditorObjectPickerList from "@schemaEditor/EditorObjectPickerList.vue";
import DataTableForErp from "~/components/erp/DataTableForErp.vue";
import moment from "moment";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import { Watch } from "nuxt-property-decorator";
import MenuListNum from "@feathers-client/components/MenuListNum.vue";
import MenuListButton from "@feathers-client/components/MenuListButton.vue";
import { getID } from "../../common/util-core";

@Component({
  components: {
    DatePicker,
    EditorObjectPickerList,
    DataTableForErp,
    EditorTextField,
    MenuListNum,
    MenuListButton,
  },
})
export default class WholesaleOrderForm extends Vue {
  async debug() {
    // const f = await this.$feathers.service("shop/erp/wholesaleOrders").find({
    //   query: {
    //     shop: this.$shop._id
    //   }
    // });
    // debugger
    const res = await this.$feathers.service("shop/erp/companyUserCredit/remainingCredit").find({
      query: {
        shop: this.$shop,
        companyUser: this.customer,
      },
    });
    console.log("api test", res);
  }

  @Prop()
  docType: string;  // for quo and wholesale(wso) at this stage

  remarks: string = "";

  shippingMethod: string = "";

  quantity = 0;

  loading = false;

  cartHeader = [
    this.$t("newSubsequentPaymentOrder.productName"),
    this.$t("orders.price"),
    this.$t("orders.quantity"),
    this.$t("order.discount.$"),
    this.$t("subsequentPaymentOrders.erp.subtotal(origin)"),
    this.$t("subsequentPaymentOrders.erp.subtotal(final)"),
  ];

  quotationCart: {
    sku: FindType<'shop/product/skus'>;
    quantity: number;
    finalPriceInt: number;
    originalPriceInt: number;
    discountRate: number;
    discountAmt: number;
    custProductName: string;
  }[] = [];

  quotationDueDate: Date = new Date();

  handler = ''
  contactPerson = ''
  contactPersonTel = ''
  recipient = ''
  recipientTel = ''
  customer: any = "";
  customerCreditAmt = 0;

  billAddr: FindType<'shop/erp/companyUser/addresses'> = null;
  @Watch('billAddr')
  onBillAddr(n: typeof this.billAddr, o) {
    if (n) {
      this.contactPerson = `${n.name.firstName} ${n.name.lastName}`;
      this.contactPersonTel = n.phone;
    }
  }

  shipAddr: FindType<'shop/erp/companyUser/addresses'> = null;
  @Watch('shipAddr')
  onShipAddr(n: typeof this.shipAddr, o) {
    if (n) {
      this.recipient = `${n.name.firstName} ${n.name.lastName}`;
      this.recipientTel = n.phone;
    }
  }

  get customerAddrQuery() {
    return {
      companyUser: this.customer
    }
  }

  @Watch("customer")
  onCustomer(n, o) {
    if (n === null) {
      this.customerCreditAmt = 0;
      this.billAddr = null;
      this.shipAddr = null;
      return;
    }
    this.loadCustomerCredit();
  }

  totalUsedCredit = 0;

  async loadCustomerCredit() {
    const remainingCredit = await this.$feathers.service("shop/erp/companyUserCredit/remainingCredit").find({
      query: {
        shop: this.$shop,
        companyUser: this.customer,
      },
    });
    this.customerCreditAmt = remainingCredit['remainingCredit'];
    this.totalUsedCredit = remainingCredit['allUsedCredit'] ? remainingCredit['allUsedCredit'] : 0;
  }

  changeQuantity(quantity, cartEl) {
    cartEl.quantity = quantity;
    if (cartEl.discountRate !== 1) {
      cartEl.discountAmt = cartEl.finalPriceInt * cartEl.quantity * (1 - cartEl.discountRate);
    }
  }

  deleteCartItem(cartEl) {
    this.quotationCart.splice(this.quotationCart.indexOf(cartEl), 1)
  }

  async resetAll() {
    const c = await this.$openDialog(
      import("./dialogs/ErpConfirmDialog.vue"),
      {
        title: this.$t('subsequentPaymentOrders.erp.confirmFinish'),
        content: this.$t('subsequentPaymentOrders.erp.dataWillNotBeSaved'),
      },
      {
        maxWidth: "400px"
      }
    );
    if (!c) {
      this.loading = false;
      return;
    }
    this.customer = null
    this.quotationCart = []
    this.shippingMethod = "";
    this.contactPerson = null;
    this.contactPersonTel = null;
    this.recipient = null;
    this.recipientTel = null;
    this.billAddr = null;
    this.shipAddr = null;
    this.handler = null;
    this.remarks = null;
  }

  async changeDiscount(cartEl) {
    if (this.loading) return;
    this.loading = true;
    const dialogData = await this.$openDialog(
      import("~/components/erp/dialogs/EditCartDiscount.vue"),
      {
        cartElement: cartEl,
      },
      {
        maxWidth: "400px",
      },
    );
    if (!dialogData) {
      // cartEl = originCartEl;
      this.loading = false;
      return;
    }
    cartEl.discountAmt = dialogData.discAmtInt;
    cartEl.discountRate = dialogData.realDiscRate;
    this.loading = false;
  }

  changePrice(price, cartEl) {
    const roundedPrice = Math.round(price * 100) / 100
    const priceInt = roundedPrice * 10000;
    cartEl.finalPriceInt = priceInt;
    if (cartEl.discountRate !== 1) {
      cartEl.discountAmt = Math.round(cartEl.finalPriceInt * cartEl.quantity * (1 - cartEl.discountRate));
    }
  }

  changeFinalSubtotal(subtotal, cartEl) {
    const subtotalInt = subtotal * 10000;
    cartEl.discountAmt = cartEl.finalPriceInt * cartEl.quantity - subtotalInt;
    console.log("discountamt", cartEl.discountAmt);
    if (cartEl.discountAmt < 0) {
      cartEl.discountAmt = 0;
    }
    cartEl.discountRate = 1;
  }

  getProductName(cartSku) {
    return cartSku.name?.length ? this.$td(cartSku.name) : cartSku.fullName?.length ? this.$td(cartSku.fullName) : '-'
  }

  get totalFinalSubtotal() {
    return this.totalSubtotal - this.totalDiscountAmt
  }

  get totalDiscountAmt() {
    if (this.quotationCart.length < 1) {
      return 0;
    }
    let sum = 0;
    for (let cartEl of this.quotationCart) {
      sum += cartEl.discountAmt;
    }
    return Math.round(sum);
  }

  get totalSubtotal() {
    if (this.quotationCart.length < 1) {
      return 0;
    }
    let sum = 0;
    for (let cartEl of this.quotationCart) {
      sum += cartEl.finalPriceInt * cartEl.quantity;
    }
    return sum;
  }

  async addCustomProductDialog() {
    if (this.loading) return;
    this.loading = true;
    const dialogData = await this.$openDialog(import("~/components/erp/dialogs/CustomProduct.vue"),
      {},
      {
        maxWidth: "650px"
      });
    if (!dialogData) {
      this.loading = false;
      return;
    }
    // await this.quotationCart.push(...dialogData)
    await this.addDialogDataToCart(dialogData);
    console.log(this.quotationCart);
    this.loading = false;
  }

  async addProductDialog() {
    if (this.loading) return;
    this.loading = true;
    const dialogData = await this.$openDialog(import("~/components/erp/dialogs/ProductsPickerDialog.vue"),
      {
        title: this.$t("newSubsequentPaymentOrder.addProducts")
      },
      {
        maxWidth: "1200px"
      });
    if (!dialogData) {
      this.loading = false;
      return;
    }
    // await this.quotationCart.push(...dialogData)
    await this.addDialogDataToCart(dialogData);
    console.log(this.quotationCart);
    this.loading = false;
  }

  addDialogDataToCart(
    dialogData: {
      sku: any;
      custProductName: string;
      quantity: number;
      price: number;
    }[],
  ) {
    for (let dialogEl of dialogData) {
      this.quotationCart.push({
        discountRate: 1,
        discountAmt: 0,
        originalPriceInt: dialogEl.price,
        finalPriceInt: dialogEl.price,
        sku: dialogEl.sku,
        quantity: dialogEl.quantity,
        custProductName: dialogEl.custProductName || null,
      });

    }
  }

  async createOrder(orderType: string) {
    if (this.loading) return;
    this.loading = true;
    if (this.customer === "" || this.customer === null) {
      this.$store.commit('SET_ERROR', 'Please select customer');
      // throw new Error('Please select customer')
      this.loading = false;
      return;
    } else if (this.quotationCart.length < 1) {
      this.$store.commit('SET_ERROR', 'Please add products');
      // throw new Error('Please add products')
      this.loading = false;
      return;
    } else if (orderType === 'wso' && this.shippingMethod === "" || this.shippingMethod === null) {
      this.$store.commit('SET_ERROR', 'Please select shipping Method');
      this.loading = false;
      return;
    } else if (orderType === 'wso' && !this.shipAddr) {
      this.$store.commit('SET_ERROR', 'Please select shipping address');
      this.loading = false;
      return;
    } else if (!this.billAddr) {
      this.$store.commit('SET_ERROR', 'Please select billing address');
      this.loading = false;
      return;
    } else if (!this.handler || !this.contactPerson || !this.contactPersonTel ||
      (orderType === 'wso' && (!this.recipient || !this.recipientTel))) {
      this.$store.commit('SET_ERROR', 'Not enough information');
      this.loading = false;
      return;
    } else if (this.totalFinalSubtotal < 0) {
      this.$store.commit('SET_ERROR', 'Cannot create order/quotation with negative number of amount');
      this.loading = false;
      return;
    }


    let dialogTitle = 'subsequentPaymentOrders.erp.confirmFinish';
    let dialogContent = 'subsequentPaymentOrders.erp.noEditAfterConfirm';
    let dialogExtraContent = ''

    if (this.totalFinalSubtotal > this.customerCreditAmt) {
      dialogTitle = 'subsequentPaymentOrders.erp.exceedCredit';
      dialogContent = 'subsequentPaymentOrders.erp.confirmFinishWhenExceedCredit';
      dialogExtraContent = `${this.$t('subsequentPaymentOrders.erp.exceededCreditAmt')} ${this.$price(this.totalUsedCredit - this.customerCreditAmt)}`;
    }
    const c = await this.$openDialog(
      import("./dialogs/ErpConfirmDialog.vue"),
      {
        title: this.$t(dialogTitle),
        content: this.$t(dialogContent),
        moreContent: this.totalFinalSubtotal > this.customerCreditAmt,
        extraContent: dialogExtraContent,
      },
      {
        maxWidth: "400px"
      }
    );
    if (!c) {
      this.loading = false;
      return;
    }
    const allItemsInCart = [];
    // debugger
    for (let item of this.quotationCart) {
      const itemToCart = {
        productSku: item.sku?._id || null,
        quantity: item.quantity,
        finalPriceInt: Math.round(item.finalPriceInt),
        originalPriceInt: Math.round(item.originalPriceInt),
        totalDiscountAmtInt: Math.round(item.discountAmt),
        productName: !item.sku ? { lang: "", value: item.custProductName } :
          item.sku?.name
            ? item.sku.name
            : item.sku.fullName,
        // {
        //   lang: item.sku?.name?.[0]?.lang || "",
        //   value: item.sku?.name?.[0]?.value || item.custProductName || "",
        // },
        // shippingStatus: item.sku ? "unshipped" : "na",
        desc: item.sku ? item.sku.desc : null,
        allowOutOfStock: item.sku?.shopTable?.[0]?.allowOutOfStock || false,
      };
      allItemsInCart.push(itemToCart);
    }

    if (orderType === 'quo') {
      const newQuotation = await this.$feathers.service("shop/erp/wholesaleOrders/orderHandler").create({
        orderType: "quotation",
        status: "unconfirmed",
        quotationExpiryDate: this.quotationDueDate,
        date: moment().toDate(),
        customer: this.customer,
        remarks: this.remarks,
        // testing: true,
        shop: this.$shop._id,
        shippingMethod: this.shippingMethod,
        products: allItemsInCart,
        staff: this.handler,
        lastModifiedBy: this.handler,
        contactPerson: this.contactPerson,
        contactTel: this.contactPersonTel,
        recipient: this.recipient,
        recipientTel: this.recipientTel,
        billingAddress: this.billAddr._id
      });
      if (newQuotation) {
        this.loading = false;
        this.$router.replace(
          `/erp/wholesaleOrders/quoSuccess?id=${newQuotation._id}&orderType=quo&orderId=${newQuotation.orderId
          }`
        );
      } else {
        this.loading = false;
        console.log("quo error");
      }
    } else {
      const newWSOrder = await this.$feathers.service("shop/erp/wholesaleOrders/orderHandler").create({
        orderType: "order",
        status: "unpaid",
        date: moment().toDate(),
        customer: this.customer,
        remarks: this.remarks,
        // testing: true,
        shop: this.$shop._id,
        shippingMethod: this.shippingMethod,
        products: allItemsInCart,
        staff: this.handler,
        lastModifiedBy: this.handler,
        contactPerson: this.contactPerson,
        contactTel: this.contactPersonTel,
        billingAddress: this.billAddr._id,
        shippingAddress: this.shipAddr._id,
        recipient: this.recipient,
        recipientTel: this.recipientTel,
      });
      if (newWSOrder) {
        this.loading = false;
        this.$router.replace(`/erp/wholesaleOrders/quoSuccess?id=${newWSOrder._id}&orderType=wso&orderId=${newWSOrder.orderId
          }`);
      } else {
        this.loading = false;
        console.log("wso error");
      }
    }

    if (orderType === 'wso') {
      this.$store.commit('SET_SUCCESS', this.$t('subsequentPaymentOrders.erp.wsoSuccess'));
    } else if (orderType === 'quo') {
      this.$store.commit('SET_SUCCESS', this.$t('subsequentPaymentOrders.erp.quoSuccess'));
    }

    this.loading = false;


  }
}
