

import _ from 'lodash'
import { Component, Vue, Prop, FindType, Watch, checkID } from '@feathers-client'
import { getStatusColor } from "~/plugins/pos/util";

type ScheduleType = FindType<'shop/shipping/schedules'>

@Component
export default class ShipmentScheduleCard extends Vue {

    @Prop()
    item: ScheduleType

  getStatusColor = getStatusColor;

  async manage() {
    try {
      const c = await this.$openDialog(
        import("~/components/boxs/ShipmentScheduleStatusDialog.vue"),
        {
          item: this.item
        },
        {
          maxWidth: "80vw",
          contentClass: "h-100"
        }
      );
      if (!c) return;
    } catch (e) {
      console.warn(e);
      this.$store.commit("SET_ERROR", e.message);
    }
  }
}

