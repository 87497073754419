
import Vue from 'vue'
import DataTable from './DataTable.vue'
import DataTableNew from './DataTableNew.vue'
import VirtualDataTable from './VirtualDataTable.vue'
import VirtualDataTableCell from './VirtualDataTableCell'
import Renderer from './Renderer'
import { Context } from '@nuxt/types';
import moment from 'moment'
import { VNode } from 'vue'
import VirtualDataList from "./VirtualDataList.vue"

export let options = {
    secureExport: false,
};

const localeToMoment = {
    cht: 'zh-hk',
    chs: 'zh-cn',
    en: 'en',
}

interface PressContext {
    config: {
        long?: number,
        maxDeltaX?: number,
        maxDeltaY?: number,
        stop?: boolean,
        prevent?: boolean,
    },
    igs: boolean
    ige: boolean
    int: number
    ic: boolean
    ig: boolean
    ite: boolean
    clientX: number
    clientY: number
    long(e: MouseEvent | TouchEvent);
    mousedown(e : MouseEvent);
    mouseup(e : MouseEvent);
    mouseleave(e : MouseEvent);
    mousemove(e : MouseEvent);
    captureClick(e : Event);
    click(e : Event);
    cancel();
    touchstart(e : TouchEvent);
    touchmove(e : TouchEvent);
    touchend(e : TouchEvent);
    contextmenu(e: MouseEvent);
}

declare global {
    interface HTMLElement {        
        $press: PressContext
    }
}

export default {
    install(Vue : Vue.VueConstructor) {
        Vue.component('virtual-data-list', <any>VirtualDataList)
        Vue.component('data-table', DataTable)
        Vue.component('data-table-new', DataTableNew)
        Vue.component('virtual-data-table', VirtualDataTable)
        Vue.component('virtual-data-table-cell', VirtualDataTableCell)
        Vue.component('renderer', <any>Renderer)

        Vue.filter('table-date', (ctx : Vue, value : moment.Moment | string) => {
            const locale = ctx.$i18n && localeToMoment[ctx.$i18n.locale] || ctx.$store?.getters?.mlocaleCode || ctx.$store?.getters?.localeCode || ctx.$i18n.locale;
            return value ? (value instanceof moment ? <moment.Moment>value : moment(value)).locale(locale).format('lll') : '-'
        })

        Vue.filter('table-date-only', (ctx : Vue, value : moment.Moment | string) => {
            const locale = ctx.$i18n && localeToMoment[ctx.$i18n.locale] || ctx.$store?.getters?.mlocaleCode || ctx.$store?.getters?.localeCode || ctx.$i18n.locale;
            return value ? (value instanceof moment ? <moment.Moment>value : moment(value)).locale(locale).format('ll') : '-'
        })

        Vue.filter('table-enum', (ctx : Vue, value : string, item, parent, header : DataTableHeader) => {
            const map = header.enumList?.[value] || value || '';
            return map ? ctx.$t(map) : '-';
        });

        Vue.filter('table-enum-t', (ctx : Vue, value : string, item, parent, header : DataTableHeader) => {
            const map = header.enumList?.[value] || value || '';
            return map ? ctx.$td(map) : '-';
        });

        Vue.filter('table-t', (ctx, value) => {
            return ctx.$td(value);
        })

        Vue.filter('table-b', (ctx, value) => {
            if (value == undefined) {
                return ''
            }
            return ctx.$t('basic.' + (value || false).toString())
        })

        // v-press

        var emit = (vnode : VNode, name : string, data? : any) => {
            var handlers = (vnode.data && vnode.data.on) ||
                (vnode.componentOptions && vnode.componentOptions.listeners);
          
            if (handlers && handlers[name]) {
                handlers[name].fns(data);
            }
        }

        Vue.directive('press', {
            bind (el, binding, vnode) {
                let p : PressContext = el.$press = {
                    config: binding.value || {},
                    igs: false,
                    ige: false,
                    ite: false,
                    mousedown(e) {
                        if(p.config.prevent) {
                            e.preventDefault();
                        }
                        if(p.config.stop) {
                            e.stopPropagation();
                        }
                        if(p.igs) {
                            p.igs = false;
                            return;
                        }
                        p.clientX = e.clientX;
                        p.clientY = e.clientY;
                        p.cancel();
                        p.int = <number><any>setTimeout(() => p.long(e), p.config.long || 1000);
                    },
                    mousemove(e) {
                        if(!p.int) return;
                        if(p.config.prevent) {
                            e.preventDefault();
                        }
                        if(p.config.stop) {
                            e.stopPropagation();
                        }
                        const deltaX = Math.abs(e.clientX - p.clientX)
                        const deltaY = Math.abs(e.clientY - p.clientY)
                        if(deltaX >= (p.config.maxDeltaX ?? 20) || deltaY >= (p.config.maxDeltaY ?? 20)) {
                            p.cancel();
                        }
                    },
                    mouseup(e) {
                        if(p.config.prevent) {
                            e.preventDefault();
                        }
                        if(p.config.stop) {
                            e.stopPropagation();
                        }
                        if(p.ige) {
                            p.ige = false;
                            return;
                        }
                        if(p.ig) {
                            p.cancel();

                            // prevent long hold event bubbling down to inner elements
                            window.addEventListener(
                                'click',
                                p.captureClick,
                                true
                            );
                            setTimeout(() => {
                                window.removeEventListener('click', p.captureClick, true);
                            }, 10);
                            return;
                        }
                        p.cancel();
                        p.ic = true;
                    },
                    captureClick(e) {
                        e.stopPropagation();
                        e.preventDefault();
                    },
                    mouseleave(e) {
                        p.cancel();
                    },
                    click(e) {
                        if(p.ic) {
                            emit(vnode, 'shortpress', e);
                            p.cancel();
                        }
                    },
                    touchstart(e) {
                        if(p.config.prevent) {
                            e.preventDefault();
                        }
                        if(p.config.stop) {
                            e.stopPropagation();
                        }
                        p.igs = true;
                        p.cancel();
                        p.int = <any>setTimeout(() => p.long(e), p.config.long || 1000);
                        if (e.targetTouches.length === 1) {
                            p.clientX = e.targetTouches[0].clientX;
                            p.clientY = e.targetTouches[0].clientY;
                        }
                        if(!p.ite) {
                          document.addEventListener('touchend', p.touchend, { capture: true });
                          p.ite = true;
                        }
                    },
                    touchmove(e) {
                        if(!p.igs) return;
                        if (e.targetTouches.length === 1) {
                            const deltaX = Math.abs(e.targetTouches[0].clientX - p.clientX)
                            const deltaY = Math.abs(e.targetTouches[0].clientY - p.clientY)
                            if(deltaX > (p.config.maxDeltaX ?? 20) || deltaY > (p.config.maxDeltaY ?? 20)) {
                                p.cancel();
                            }
                        }
                    },
                    touchend(e) {
                        p.ige = true;
                        if(p.ig) {
                            p.cancel();
                            return;
                        }
                        p.cancel();
                        p.ic = true;
                        if(p.ite) {
                          document.removeEventListener('touchend', p.touchend, { capture: true });
                          p.ite = false;
                        }
                    },
                    long(e) {
                        p.int = 0;
                        p.ig = true;
                        emit(vnode, 'longpress', e);
                        if(p.ite) {
                          document.removeEventListener('touchend', p.touchend, { capture: true });
                          p.ite = false;
                        }
                    },
                    cancel() {
                        if(p.int) {
                            clearTimeout(p.int);
                            p.int = 0;
                        }
                        p.ig = false;
                        p.ic = false;
                        document.removeEventListener('touchend', p.touchend, { capture: true });
                    },
                    contextmenu(e) {
                        e.preventDefault();
                        e.stopPropagation();
                    },
                    int: 0,
                    ig: false,
                    ic: false,
                    clientX: 0,
                    clientY: 0,
                }
                el.addEventListener('mousedown', p.mousedown);
                el.addEventListener('mouseup', p.mouseup);
                el.addEventListener('mouseleave', p.mouseleave);
                el.addEventListener('mousemove', p.mousemove);
                el.addEventListener('click', p.click);
                el.addEventListener('touchmove', p.touchmove, { passive: true});
                el.addEventListener('touchstart', p.touchstart);
                el.addEventListener('touchcancel', p.cancel);
                el.addEventListener('contextmenu', p.contextmenu);
            },
            update(el, binding, vnode) {
                let p = el.$press;
                if(p) {
                    p.config = binding.value || {};
                }
            },
            unbind (el, binding, vnode) {
                let p = el.$press;
                if(p) {
                    el.removeEventListener('mousedown', p.mousedown);
                    el.removeEventListener('mouseup', p.mouseup);
                    el.removeEventListener('mouseleave', p.mouseleave);
                    el.removeEventListener('mousemove', p.mousemove);
                    el.removeEventListener('click', p.click);
                    el.removeEventListener('touchmove', p.touchmove);
                    el.removeEventListener('touchstart', p.touchstart);
                    el.removeEventListener('touchcancel', p.mouseleave as any);
                    el.removeEventListener('contextmenu', p.contextmenu);
                    if(p.ite) {
                      document.removeEventListener('touchend', p.touchend, { capture: true });
                      p.ite = false;
                    }
                    delete el.$press;
                }
            }
        });

    }
}

export type LangArrType = { lang: string, value: string }[];
export type LangObjType = { $t?: string, $join? : LangType[], $a? : any, $ta?: { [key : string] : LangType} }
export type LangType = LangArrType | LangObjType | string;

export interface DataTablePaginated {
    data : any[];
    total? : number;
    skip?: number
    limit? : number
    sortBy? : string[]
    sortDesc? : boolean[]
    currentFilter? : any
    cursors? : {
        next? : any
        previous? : any
    }
}

export interface DataTableFilter {
    [key : string] : any
}

export interface DataTablePopObj {
    path: string
    populate? : DataTablePop[]
}
export type DataTablePop = string | DataTablePopObj;

export interface DataTableDef {
    path? : string
    subpath?: string
    id? : string
    static? : boolean
    filter? : DataTableFilter
    name? : LangType
    sortBy? : string[]
    sortDesc? : boolean[]
    invalid? : boolean
    populate: DataTablePop[]
    select? : string[]

    editingItem? : any

    prepend? : any[];

    // query string key
    query? : string

    data? : DataTablePaginated;
}

export interface DataTableHeader {
    computed?: boolean

    // when sorting header path (ui) != data header path
    headerValue? : string | string[] | ((item : any) => string)
    linkValue? : string | string[] | ((item : any) => string)
    value? : string

    source? : string | ((item : any) => string)

    noLink?: boolean
    linkSource?: string | ((item : any) => string)
    trailingSlash?: boolean
    direct?: boolean

    unique?: boolean
    limit?: number

    path? : string
    paths? : {
        path: string
        format? :  string | ((self : Vue, value : any, item : any) => string)
    }[]

    objectOnly? : boolean
    format? :  string | ((self : Vue, value : any, item : any) => string)

    itemKey? : string

    filter? : any
    populate? : DataTablePop[]
    select? : string[]

    type? : string
    slot? : string

    text? : string
    hideDelete? : boolean

    multiple? : boolean
    action? : boolean
    sortable? : boolean
    sortField? : string
    enumList?: Record<string, string>

    inner?: DataTableHeader[]

    exportOrder?: number
    exportHeader?: any

    debug?: boolean
}

export interface DataTablePagination {
    rowsPerPage? : number | false
    itemsPerPage?: number | false
    page? : number
    total? : number
    cursor? : any
    sortBy? : string[]
    sortDesc? : boolean[]
    currentFilter? : any
}


export interface ItemAction {
    icon?: string
    name?: string
    action?: string
    altText?: string
    to?: string
}

export interface ItemListProp {
    feathers? : string
    sortBy?: string | string[]
    descending?: boolean | boolean[]
    sortDesc? : boolean | boolean[]
    update? : (props : ItemListProp, ctx : Context) => void;
    fetch? : (props : ItemListProp, ctx : Context) => void;
    name? : LangType
    flat? : boolean
    fullPage? : boolean
    preActions? : ItemAction[],
    postActions? : ItemAction[],
    search? : boolean
    export? : boolean
    refresh? : boolean
    noAdd? : boolean
    innerAction? : boolean
    query? : string
    path? : string
    select? : string[]
    populate? : DataTablePop[]
    filter? : any
    invalid? : boolean
    extra? : any
    deferMount? : boolean
    rowsPerPage? : number
    defaultFilter?: any
}

export type AclHandler = (context: Vue, action: 'create' | 'patch' | 'remove' | 'clone', item?: any) => boolean;
