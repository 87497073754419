<template>
<renderer :render="func || renderDefault"/>
</template>

<script>
export default {
    props: {
        func: {},
    },
    methods: {
        renderDefault() {
            const node = this.$scopedSlots.default(this.$scopedSlots);
            for(let n of node) {
                if(n.data && n.data.scopedSlots) {
                    n.data.scopedSlots.$stable = true;
                }
            }
            return node;
        }
    }
}
</script>