

import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";

@Component
export default class LazyRender extends Vue {

    loaded = false;
    observer: IntersectionObserver;

    mounted() {
        if(IntersectionObserver !== undefined && this.$el) {
            this.observer = new IntersectionObserver(this.onUpdate);
            this.observer.observe(this.$el);
        }
    }

    dispose() {
        if(this.observer) {
            this.observer.disconnect();
            this.observer = null;
        }
    }

    beforeDestroy() {
        this.dispose();
    }

    onUpdate(entries: IntersectionObserverEntry[]) {
        if(entries[0]?.isIntersecting) {
            this.loaded = true;
            this.dispose();
        }
    }

}

