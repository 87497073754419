
import { ResizeObserver as VueResizeObserver } from "vue-resize";
import { Component, Vue, Prop } from "@feathers-client";
import _ from "lodash";

import "vue-resize/dist/vue-resize.css";

@Component({
  components: {
    ResizeObserver: VueResizeObserver,
  },
})
export default class ResizeSensor extends Vue {
  supported = false;
  sensor: ResizeObserver;

  beforeMount() {
    if (process.client && ResizeObserver !== undefined) {
      this.supported = true;
    }
  }

  mounted() {
    if (this.supported) {
      this.sensor = new ResizeObserver(this.emitResized);
      this.sensor.observe(this.$el);
    }
  }

  beforeDestroy() {
    if (this.sensor) {
      this.sensor.disconnect();
      this.sensor = null;
    }
  }

  @Prop({
    type: Number,
    default: 50,
  })
  debounce: number;

  handleResize() {
    this.emitResized();
  }

  get emitResized() {
    if (!this.debounce) {
      return () => {
        return this.$emit("resized", {
          width: this.$el.clientWidth,
          height: this.$el.clientHeight,
        });
      };
    }
    return _.debounce(() => {
      // console.log('resize', {
      //     width: this.$el.clientWidth,
      //     height: this.$el.clientHeight,
      // });
      return this.$emit("resized", {
        width: this.$el.clientWidth,
        height: this.$el.clientHeight,
      });
    }, this.debounce);
  }
}
