import { Prop, Component, Vue, getOptions, Watch } from "@feathers-client";
import { evalCond } from "../importField";
import type { EditorConfig, EditorField } from "../plugin";
import uuid from "uuid/v4";
import _ from "lodash";
import { LangArrType } from "@feathers-client/i18n";

// controller for batch edit session
@Component
export class BatchEditorContext extends Vue {
  collections: Record<string, BatchEditorCollection> = {};
  dirty = 0;
  deleted = 0;
  new = 0;
  draft: BatchEditActionList = null;

  @Prop()
  path: string; // root path

  service(path: string) {
    return (
      this.collections[path] ||
      (this.collections[path] = new BatchEditorCollection(
        getOptions(this, {
          path,
        }),
      ))
    );
  }

  async init() {
    try {
      // @ts-ignore
      const draft = (
        await this.$feathers.service("importDrafts").find({
          query: {
            path: this.path,
            status: "draft",
            $limit: 1,
            $sort: {
              date: -1,
            },
          },
        })
      ).data[0];

      if (draft) {
        this.draft = draft;
        if (draft.create) {
          for (let item of draft.create) {
            await this.service(item.path).create(item.data);
          }
        }
        if (draft.patch) {
          // TODO: check diff
          for (let item of draft.patch) {
            await this.service(item.path).patch(item.id, item.data);
          }
        }
        if (draft.remove) {
          for (let item of draft.remove) {
            await this.service(item.path).remove(item.id);
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  async saveDraft(name?: string) {
    try {
      const list: BatchEditActionList = {
        create: [],
        patch: [],
        remove: [],
        path: this.path,
        ...(name ? { name } : {}),
      };

      for (let collection of Object.values(this.collections)) {
        for (let item of Object.values(collection.itemDict)) {
          if (item.deleted) {
            list.remove.push({ path: collection.path, id: item.id });
          } else if (item.new) {
            list.create.push({ path: collection.path, data: item.item, id: item.id });
          } else if (item.dirty) {
            list.patch.push({
              path: collection.path,
              id: item.id,
              data: item.item,
              original: item.original,
            });
          }
        }
      }

      if (this.draft) {
        // @ts-ignore
        this.draft = await this.$feathers.service("importDrafts").patch(this.draft._id, list);
      } else {
        // @ts-ignore
        const draft = await this.$feathers.service("importDrafts").create(list);
        this.draft = draft;
      }
    } catch (error) {
      console.error(error);
      this.$store.commit("SET_ERROR", error.message);
    }
  }

  async save() {
    try {
      const list: BatchEditActionList = {
        create: [],
        patch: [],
        remove: [],
      };

      for (let collection of Object.values(this.collections)) {
        for (let item of Object.values(collection.itemDict)) {
          if (item.deleted) {
            list.remove.push({ path: collection.path, id: item.id });
          } else if (item.new) {
            list.create.push({ path: collection.path, data: item.item, id: item.id });
          } else if (item.dirty) {
            list.patch.push({ path: collection.path, id: item.id, data: item.item });
          }
        }
      }

      const { createIds } = await this.$feathers.service("imports/batch").create(list);
      const idMaps: Record<string, string> = {};

      for (let i = 0; i < list.create.length; i++) {
        const newId = createIds[i];
        idMaps[list.create[i].id] = newId;
      }

      function deepFix(item: any) {
        if (!item) return;
        if (Array.isArray(item)) {
          for (let i = 0; i < item.length; i++) {
            const v = item[i];
            if (typeof v === "string" && idMaps[v]) {
              item[i] = idMaps[v];
            } else if (v && typeof v === "object") {
              deepFix(v);
            }
          }
        } else if (typeof item === "object") {
          for (let [k, v] of Object.entries(item)) {
            if (typeof v === "string" && idMaps[v]) {
              item[k] = idMaps[v];
            } else if (v && typeof v === "object") {
              deepFix(v);
            }
          }
        }
      }

      for (let i = 0; i < list.create.length; i++) {
        const newId = createIds[i];
        const cur = list.create[i];
        const collection = this.collections[cur.path];
        if (collection) {
          const item = collection.itemDict[cur.id];
          if (item) {
            collection.itemDict[newId] = item;
            item.id = newId;
            item.item._id = newId;
            deepFix(item.item);
            item.original = structuredClone(item.item);
            item.dirty = false;
            item.editing = false;
          }
        }
      }

      for (let i = 0; i < list.patch.length; i++) {
        const cur = list.patch[i];
        const collection = this.collections[cur.path];
        if (collection) {
          const item = collection.itemDict[cur.id];
          if (item) {
            deepFix(item.item);
            item.original = structuredClone(item.item);
            item.dirty = false;
            item.editing = false;
          }
        }
      }

      for (let i = 0; i < list.remove.length; i++) {
        const cur = list.remove[i];
        const collection = this.collections[cur.path];
        if (collection) {
          const item = collection.itemDict[cur.id];
          if (item) {
            delete collection.itemDict[item.id];
            if (item.id !== item.oid) {
              delete collection.itemDict[item.oid];
            }
            item.$destroy();
          }
        }
      }

      if (this.draft) {
        // @ts-ignore
        await this.$feathers.service("importDrafts").patch(this.draft._id, { status: "saved" });
      }

      this.resetStats();
    } catch (error) {
      console.error(error);
      this.$store.commit("SET_ERROR", error.message);
    }
  }

  async deleteDraft() {
    try {
      if (this.draft) {
        // @ts-ignore
        await this.$feathers.service("importDrafts").patch(this.draft._id, {
          status: "deleted",
        });
        this.draft = null;
      }
    } catch (error) {
      console.error(error);
      this.$store.commit("SET_ERROR", error.message);
    }
  }

  resetStats() {
    let dirty = 0;
    let deleted = 0;
    let mnew = 0;

    for (let collection of Object.values(this.collections)) {
      collection.resetStats();

      dirty += collection.dirty;
      deleted += collection.deleted;
      mnew += collection.new;
    }

    this.dirty = dirty;
    this.deleted = deleted;
    this.new = mnew;
  }

  markDirty() {
    this.dirty++;
  }

  markClean() {
    if (this.dirty) {
      this.dirty--;
    }
  }

  markDeleted(dirty = false) {
    if (dirty) {
      this.dirty--;
    }
    this.deleted++;
  }

  markRestored() {
    this.deleted--;
  }

  markNew() {
    this.new++;
  }
}

// controller for each table
@Component
export class BatchEditorCollection extends Vue {
  @Prop()
  path: string;

  selection: Set<BatchEditorItem> = new Set();
  selectionSize: number = 0;

  itemDict: Record<string, BatchEditorItem> = {};
  items: BatchEditorItem[] = [];
  deletedItems: BatchEditorItem[] = [];

  paginate = true;
  dirty = 0;
  deleted = 0;
  new = 0;

  async get(id: string, params: any = {}) {
    const cur = this.itemDict[id];
    if (cur) {
      return cur.item;
    } else {
      return await this.$feathers.service(this.path).get(id);
    }
  }

  addCachedItem(id: string, original?: any) {
    const cur = this.itemDict[id];
    if (cur) {
      return cur;
    } else {
      const item = this.addItem(id, original);
      if (!original) {
        this.pendingList.add(item);
        this.scheduleLoadPending();
      }
      return item;
    }
  }

  async find(params: any = {}) {
    const query = params.query;

    // simple fix for query
    if (query._id) {
      if (typeof query._id === "string" && isTempId(query._id)) {
        params.query = {
          ...params.query,
          _id: { $in: [] }, // force empty result
        };
      } else if (Array.isArray(query._id) || Array.isArray(query._id.$in)) {
        const finalIds = (Array.isArray(query._id) ? query._id : query._id.$in).filter(
          (it) => !isTempId(it),
        );
        params.query = {
          ...params.query,
          _id: { $in: finalIds },
        };
      }
    }

    let maybePaginated = (await this.$feathers.service(this.path).find(params)) as any;

    const isPaginated = !!maybePaginated?.data;
    let data: any[] = isPaginated ? maybePaginated.data : maybePaginated;

    data = data.filter((item) => {
      const cur = this.itemDict[item._id];
      if (cur) {
        return false;
      }
      return true;
    });
    if ((isPaginated && !query.$skip) || !isPaginated) {
      const prepend = Object.values(this.itemDict).filter((it) => {
        return !it.deleted && evalCond(query, it.item);
      });
      data.unshift(...prepend.map((it) => it.item));
    }
    if (isPaginated) {
      maybePaginated.data = data;
    } else {
      maybePaginated = data;
    }
    return maybePaginated;
  }

  private addItem(id: string, original?: any, idx = -1) {
    const item = new BatchEditorItem(getOptions(this, {}));
    item.id = id;
    item.oid = id;
    if (original) {
      item.original = original;
    }
    item.reset();
    this.itemDict[item.id] = item;
    if (idx === -1) {
      this.items.push(item);
    } else {
      this.items.splice(idx, 0, item);
    }
    return item;
  }

  removeItem(item: BatchEditorItem) {
    const idx = this.items.indexOf(item);
    if (idx !== -1) {
      this.items.splice(idx, 1);
      if (item.original) {
        // mark pending delete for existing item
        item.markDeleted();
        this.deletedItems.push(item);
      } else {
        // just remove "new" item
        delete this.itemDict[item.id];
        this.new--;
        (this.$parent as BatchEditorContext).new--;
      }
    }
  }

  restoreItem(item: BatchEditorItem) {
    const idx = this.deletedItems.indexOf(item);
    if (idx !== -1) {
      this.deletedItems.splice(idx, 1);
      item.markRestored();
      this.items.push(item);
      this.itemDict[item.id] = item;
    }
  }

  purgeCleanItems(filter?: any) {
    const toRemoveIds = new Set<string>();
    for (let item of this.items) {
      if (!(item.dirty || item.new || item.deleted) && evalCond(filter, item.item)) {
        toRemoveIds.add(item.id);
      }
    }
    this.items = this.items.filter((it) => !toRemoveIds.has(it.id));
    for (let id of toRemoveIds) {
      delete this.itemDict[id];
    }
  }

  cloneItem(item: BatchEditorItem) {
    const idx = this.items.indexOf(item);
    const newItem = this.addItem(`$$${this.path}$${uuid()}`, undefined, idx + 1);
    newItem.item = structuredClone(item.item);
    newItem.editing = newItem.dirty = true;
    this.markNew();
    delete newItem.item._id;
  }

  async create(data: any) {
    const newId = data._id && data._id.startsWith("$$") ? data._id : `$$${this.path}$${uuid()}`;
    data._id = newId;
    const item = this.addItem(newId);
    item.item = data;
    item.editing = item.dirty = true;
    this.markNew();
    return item.item;
  }

  async patch(id: string, data: any, params: any = {}) {
    const query = params.query || {};
    if (id) {
      let cur = this.itemDict[id];
      if (!cur) {
        const toPatch = await this.$feathers.service(this.path).get(id, params);
        cur = this.addItem(toPatch._id, toPatch);
        cur.patch(data);
        cur.editing = true;
        cur.markDirty();
        return cur.item;
      } else {
        if (cur.deleted || !evalCond(query, cur.item)) {
          throw new Error("Not found");
        }
        cur.patch(data);
        cur.markDirty();
        return cur.item;
      }
    } else {
      throw new Error("todo");
    }
  }

  async remove(id?: string, params: any = {}) {
    const query = params.query || {};
    if (id) {
      let cur = this.itemDict[id];
      if (cur) {
        if (cur.deleted || !evalCond(query, cur.item)) {
          throw new Error("Not found");
        }
        cur.markDeleted();
        return cur.item;
      } else {
        const toPatch = await this.$feathers.service(this.path).get(id, params);
        cur = this.addItem(toPatch._id, toPatch);
        cur.markDeleted();
        return cur.item;
      }
    } else {
      throw new Error("todo");
    }
  }

  async preloadItems(filter: any) {
    const items = (await this.$feathers.service(this.path).find({
      query: {
        ...(filter || {}),
        $paginate: false,
      },
      paginate: false,
    })) as any;
    for (let item of items) {
      this.addItem(item._id, item);
    }
  }

  pendingList: Set<BatchEditorItem> = new Set();

  asyncPreloadIds(ids: string[]) {
    return ids.map((id) => {
      let cur = this.itemDict[id];
      if (!cur) {
        cur = this.addItem(id);
        this.pendingList.add(cur);
        this.scheduleLoadPending();
      }
      return cur;
    });
  }

  _scheduleLoadPendingTimer: any;
  scheduleLoadPending() {
    if (this._scheduleLoadPendingTimer) return;
    this._scheduleLoadPendingTimer = setTimeout(async () => {
      this._scheduleLoadPendingTimer = null;
      const list = Array.from(this.pendingList.values());
      const dict = Object.fromEntries(list.map((it) => [it.id, it] as const));
      this.pendingList.clear();

      const items = (await this.$feathers.service(this.path).find({
        query: {
          _id: { $in: list.map((it) => it.id) },
          $paginate: false,
        },
      })) as any;

      for (let item of items) {
        dict[item._id].original = structuredClone(item);
        dict[item._id].item = item;
      }
    }, 100);
  }

  resolveConfig() {
    return this.$schemas.getConfigByApiPath(this.path as any, "*batch");
  }

  markDirty() {
    this.dirty++;
    (this.$parent as BatchEditorContext).markDirty();
  }

  markClean() {
    if (this.dirty) {
      this.dirty--;
      if (this.dirty === 0) {
        (this.$parent as BatchEditorContext).markClean();
      }
    }
  }

  markDeleted(dirty = false) {
    if (dirty) {
      this.dirty--;
    }
    this.deleted++;
    (this.$parent as BatchEditorContext).markDeleted(dirty);
  }

  markRestored() {
    this.deleted--;
    (this.$parent as BatchEditorContext).markRestored();
  }

  markNew() {
    this.new++;
    (this.$parent as BatchEditorContext).markNew();
  }

  resetStats() {
    let dirty = 0;
    let deleted = 0;
    let mnew = 0;

    if (Object.keys(this.itemDict).length !== this.items.length) {
      this.items = this.items.filter((it) => this.itemDict[it.id] || this.itemDict[it.oid]);
    }

    for (let item of this.items) {
      if (item.dirty) {
        dirty++;
      }
      if (item.deleted) {
        deleted++;
      }
      if (item.new) {
        mnew++;
      }
    }

    this.dirty = dirty;
    this.deleted = deleted;
    this.new = mnew;
  }

  removeSelected() {
    for (let item of this.selection) {
      this.removeItem(item);
      item.selected = false;
    }
  }

  restoreSelected() {
    for (let item of this.selection) {
      this.restoreItem(item);
      item.selected = false;
    }
  }

  undoSelected() {
    for (let item of this.selection) {
      item.reset();
    }
  }
}

@Component
export class BatchEditorItem extends Vue {
  get parent() {
    return this.$parent as BatchEditorCollection;
  }

  id: string = null;
  oid: string = null;

  original: any = null;
  item: any = null;
  deleted = false;
  expanded = false;
  dirty = false;
  editing = false;
  selected = false;

  @Watch("selected")
  onUpdateSelected(v, ov) {
    if (v !== ov) {
      if (v) {
        this.parent.selection.add(this);
      } else {
        this.parent.selection.delete(this);
      }
      this.parent.selectionSize = this.parent.selection.size;
    }
  }

  get new() {
    return !this.original;
  }

  reset() {
    if (this.new) return;
    if (this.dirty) {
      this.dirty = false;
      this.parent.markClean();
    }
    this.item = structuredClone(this.original || {});
    this.item._id = this.id;
  }

  patch(updated: any) {
    for (let [k, v] of Object.entries(updated)) {
      Vue.set(this.item, k, v);
    }
  }

  markDirty() {
    if (!this.dirty) {
      this.dirty = true;
      this.parent.markDirty();
    }
  }

  markDeleted() {
    if (!this.deleted) {
      this.deleted = true;
      this.parent.markDeleted(this.dirty && !this.new);
    }
  }

  markRestored() {
    if (this.deleted) {
      this.deleted = false;
      this.parent.markRestored();
    }
  }
}

export function isTempId(id: string) {
  return id?.startsWith?.("$$");
}

export interface BatchEditorConfig {
  path: string; // path to the id field
  fields?: string | (string | EditorField)[];
  slot?: string;
  subFields?: BatchEditorConfig[];
  expanded?: boolean;
}

export interface NormalizedBatchEditorConfig {
  _normalized: true;
  name: string | LangArrType;
  type: "array" | "collection";
  path: string; // path to the id field
  collection: BatchEditorCollection;
  config: EditorConfig;
  fields: EditorField[];
  slot?: string;
  subFields?: NormalizedBatchEditorConfig[];
  multiple?: boolean;
  expanded?: boolean;
}

export interface BatchEditActionList {
  create?: BatchEditAction[];
  patch?: BatchEditAction[];
  remove?: BatchEditAction[];
  path?: string;
  name?: string;
}

export interface BatchEditAction {
  data?: any;
  query?: any;
  id?: string;
  path?: string;
  original?: any;
}
