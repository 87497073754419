<template>
    <v-menu
        ref="menu"
        :close-on-content-click="false"
        v-model="menu"
        transition="scale-transition"
        offset-y
        :nudge-right="40"
        min-width="290px"
        :return-value.sync="date"
    >
        <template v-slot:activator="{ on }">
            <v-text-field
                v-on="on"
                :label="label"
                v-model="date"
                prepend-icon="event"
                readonly
                :clearable="clearable"
            ></v-text-field>
        </template>
        <v-date-picker v-model="date" no-title scrollable :min="minDate" >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
        </v-date-picker>
    </v-menu>
</template>

<script>

import moment from 'moment'

export default {
    props: {
        value: {},
        label: {},
        clearable: { type: Boolean, default: false, },
        local: { type: Boolean, default: false },
        time: { type: Boolean, default: false },
        canBeforeToday: { type: Boolean, default: true }
    },
    data () {
        return {
            menu: false,
            format: this.time ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD'
        }
    },
    computed: {
        date: {
            get () { 
                if(!this.value) return '';
                let date;
                if(this.local) {
                    date = moment(this.value || new Date());
                } else {
                    date = moment(this.value || new Date()).utcOffset('+8');
                }
                if (this.time) {
                    return date.format('YYYY-MM-DD HH:mm');
                } else {
                    return date.format('YYYY-MM-DD');
                }
            },
            set (val) {
                let newVal;
                if (val) {
                    let date;
                    if (this.local) {
                        date = moment(val, 'YYYY-MM-DD');
                    } else {
                        date = moment.parseZone(val, 'YYYY-MM-DD');
                    }
                    if (this.time) {
                        date.set({ hour: this.value.getHours(), minute: this.value.getMinutes() });
                    }
                    newVal = date.toDate();
                } else {
                    newVal = '';
                }
                this.$emit('input', newVal);
            }
        },

        minDate: {
          get(){
            let date;
            if(!this.canBeforeToday){
              if(this.local) {
                date = moment(new Date());
              } else {
                date = moment(new Date()).utcOffset('+8');
              }
              return date.format(this.format)
            } else {
              return ''
            }
            
          }
        }
    }
}
</script>