
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import _ from 'lodash'
import { 
    VAutocomplete, 
    VSelect,
    VListItemContent,
    VListItemTitle, 
    VListItemSubtitle,
    VChip,
    VCombobox,
} from 'vuetify/lib'
import moment from "moment";


@Component({
    components: {
        VCombobox,
        VAutocomplete,
        VSelect,
        VListItemContent,
        VListItemTitle,
        VListItemSubtitle,
        VChip,
    }
})
export default class EditorObjectPicker extends Vue {
    @Prop()
    value : any

    @Prop(String)
    label : string

    @Prop(String)
    path : string

    @Prop({ type: String, default: 'name'})
    itemText : string

    @Prop({ type: String, default: '_id'})
    itemValue : string

    @Prop({})
    fields : string[]

    @Prop()
    items : any[]

    @Prop()
    cond : any

    @Prop()
    args : any

    @Prop({ type: Boolean, default: false })
    multiple : boolean

    @Prop({ type: Boolean })
    outlined : boolean

    @Prop({ type: String })
    prefix : string

    @Prop({ type: String })
    prependIcon : string

    @Prop({ type: Boolean, default: false })
    readonly : boolean

    @Prop({ type: Boolean, default: false })
    clearable : boolean

    @Prop({ type: Boolean, default: false })
    returnObject : boolean

    @Prop({ type: Boolean, default: false })
    hideDetails : boolean

    @Prop({ type: Boolean, default: false })
    pickFirst : boolean

    @Prop({ type: Boolean, default: false })
    all : boolean
    
    @Prop({ type: Boolean, default: false })
    translate : boolean

    @Prop({ type: Boolean, default: false })
    required: boolean

    @Prop()
    name : string[]

    @Prop()
    subtitle : string[]

    @Prop({ type: Boolean })
    dense : Boolean

    @Prop({ type: Boolean })
    comboBox : Boolean

    @Prop()
    backgroundColor: string

    @Prop(Boolean)
    picker: boolean

    @Prop(Boolean)
    list: boolean

    @Prop({ default: false, type: Boolean })
    preferSingle: boolean

    initLoaded = false;
    loading = false;
    search = '';
    mcond : any = null;
    lastQuery : any = null;
    fitems : any[] = [];
    canCache = false;
    selectedCache : any = {};
    reg : RegExp = null;

    async mounted () {
        await this.loadObjects();
        await Vue.nextTick();
        this.initLoaded = true;
        if(!this.select && this.pickFirst) {
            if(this.mitems.length) {
                this.select = this.returnObject ? this.mitems[0] : this.mitems[0][this.itemValue];
            }
        }
    }

    @Watch('search')
    onSearch(v, ov) {
        if(!this.initLoaded) return;
        if (v === ov) return;
        if (typeof v === 'object') return;
        this.reg = this.search && new RegExp(`(${escapeRegExp(this.search)})`, 'gi');
        return this.loadObjects();
    }

    @Watch('cond', { deep: true})
    onCond (val) {
        if (_.isEqual(val, this.mcond)) return;
        this.mcond = _.cloneDeep(val);
        this.clearCache();
        return this.loadObjects();
    }

    @Watch('path')
    onPath (v, ov) {
        if (v === ov) return;
        this.lastQuery = null;
        this.fitems = [];
        this.clearCache();
        return this.loadObjects();
    }

    @Watch('value')
    async onValue(value) {
        if(value) {
            const extra = await this.loadSelectionNotInData(this.fitems)
            this.fitems = this.fitems.concat(extra)
        }
    }

    get mitems () {
        return this.items ? _.map(this.items, item => {
            if(!item.hasOwnProperty('$text')) {
                Object.defineProperty(item, '$text', {
                    enumerable: false,
                    get: () => this.computeText(item, this.name, true)
                });
            }
            return item;
        }) : this.fitems;
    }

    addValue(item) {
        if(!item.hasOwnProperty('$text')) {
            Object.defineProperty(item, '$text', {
                enumerable: false,
                get: () => this.computeText(item, this.name, true)
            });
        }
        this.selectedCache[item._id] = item;
        this.fitems.push(item);
        if(this.multiple) {
            this.$emit('input', [...this.select, item._id]);
        } else {
            this.$emit('input', item._id);
        }
    }

    get select () {
        return this.value;
    }
    set select (val) {
        if(this.comboBox) {
            if(this.multiple) {
                for(let i = (this.select || []).length; i < val.length; i++) {
                    if(typeof val[i] === 'string') {
                        this.$emit('create', val[i]);
                        this.$emit('input', this.select || []);
                        return;
                    } else if(val[i] &&typeof val[i] === 'object') {
                        val[i] = val[i][this.itemValue];
                    } else {
                    }
                }
            } else if(typeof val === 'string') {
                const oval = this.select;
                this.$emit('input', typeof oval === 'object' ? 0 : null);
                Vue.nextTick(() => {
                    this.$emit('input', oval);
                })
                return;
            } else if(val &&typeof val === 'object') {
                val = val[this.itemValue];
            } else {
                val = null;
            }
        }
        this.$emit('input', val || (this.multiple ? [] : null));
        if (!this.path) return;
        if(val) {
            _.each(this.multiple ? val : [val], key => {
                if(!this.selectedCache[key]) {
                    this.selectedCache[key] = this.fitems.find(it => _.get(it, this.itemValue) === key);
                }
            })
        }
    }

    get mfields () {
        return this.fields
    }
    get mitemText () {
        return this.name ? '$text' : this.itemText;
    }

    get service() {
        return this.path && this.$feathers.service(this.path)
    }

    render (_c) {
        const child = ['append-item', 'no-data'].filter(it => !!this.$slots[it]).map(it => [
            _c('template', {
                slot: it
            }, this.$slots[it])
        ]);

        return _c((!this.items) ? this.comboBox ? 'v-combobox' : 'v-autocomplete' : 'v-select', {
            attrs: {
                label: this.label,
                loading: this.loading,
                cacheItems: this.canCache,
                searchInput: this.search,
                items: this.mitems,
                itemValue: this.itemValue,
                itemText: this.mitemText,
                filter: (this.mfields) ? (() => true) : undefined,
                multiple: this.multiple,
                prefix: this.prefix,
                prependIcon: this.prependIcon,
                clearable: this.clearable,
                readonly: this.readonly,
                returnObject: this.comboBox || this.returnObject,
                hideDetails: this.hideDetails,
                outlined: this.outlined,
                backgroundColor: this.backgroundColor,
                dense: this.dense,
                menuProps: {
                    offsetY: true,
                    bottom: true,
                    contentClass: 'object-picker-menu primary--text',
                    nudgeBottom: -2,
                }
            },
            on: {
                'update:searchInput': ($$v) => {
                    this.search = $$v;
                    this.$emit('search', $$v);
                },
                'update:search-input': ($$v) => {
                    this.search = $$v;
                    this.$emit('search', $$v);
                },
                keydown: (e) => {
                    if(e.keyCode === 13) {
                        this.$emit('create', this.search);
                    }
                }
            },
            model: {
                value: this.select,
                callback: ($$v) => { this.select = $$v },
            },
            scopedSlots: {
                item: this.name ? ((data) => _c('v-list-item-content', {
                }, [
                    _c('v-list-item-title', { domProps: { innerHTML: this._s(this.computeText(data.item, this.name)) } }),
                    this.subtitle && _c('v-list-item-subtitle', { domProps: { innerHTML: this._s(this.computeText(data.item, this.subtitle)) } }),
                ], 1)) : undefined,
                selection: this.comboBox ? ({item}) => {
                    const itemValue = item ? item._id || item : null;
                    const curItem = itemValue ? this.mitems.find(it => it[this.itemValue] === itemValue) : null
                    return _c('v-chip', [
                        this._s(curItem ? curItem[this.mitemText] : item ? item._id || item : '')
                    ]);
                } : undefined,
                'append-item': this.$scopedSlots['append-item'],
                'no-data': this.$scopedSlots['append-item'],
            },
        }, child)
    }
    
    async loadObjects () {
        if (!this.path || this.list) return;
        this.loading = true;
        try {
            const service = this.service;
            const reg = (this.all || this.picker) ? null : this.search && escapeRegExp(this.search);

            let query : any = reg && this.mfields && this.mfields.length ? {
                $or: _.map(this.mfields, f => ({
                    [f]: {
                        $regex: reg,
                        $options: 'i'
                    }
                }))
            } : {};

            if (this.cond && !this.mcond) this.mcond = _.cloneDeep(this.cond);

            if (this.mcond) {
                if (query) {
                    query = {
                        $and: [
                            query,
                            this.mcond,
                        ]
                    }
                } else {
                    query = this.mcond;
                }
            }

            if(this.args) {
                query = {
                    ...this.args,
                    ...query,
                }
            }

            query = query || {};
            if(this.all || this.picker) {
                query.$paginate = false;
            }
            if (_.isEqual(query, this.lastQuery)) return;
            this.lastQuery = _.cloneDeep(query);

            const result = await (service as any).find({query})
            let data = _.get(result, 'data')
                || (_.isArray(result) ? result as unknown as any[] : [])
                || [];

            const extra = await this.loadSelectionNotInData(data, service);
            if(extra.length > 0)
                data = data.concat(extra)

            if (this.name) {
                _.each(data, this.addComputedText)
            }
            this.fitems = data;
        } finally {
            this.loading = false;
        }
    }

    async loadSelectionNotInData(data = [], service = this.service) {
        if(!service) return [];
        const selection = []
            if(this.select && (this.multiple && this.select.length > 0 || !this.multiple)) {
                const dict = _.fromPairs(_.map(data, it => [_.get(it, this.itemValue), true]));
                const needFetch = [];
                _.each(this.multiple ? this.select : [this.select], key => {
                    if(dict[key]) return;
                    else if(this.selectedCache[key]) {
                        selection.push(this.selectedCache[key]);
                    } else {
                        needFetch.push(key);
                    }
                });

                if(needFetch.length > 0) {
                    let extra =  _.get(await (service as any).find({
                        query: {
                            ...this.args,
                            [this.itemValue]: {
                                $in: needFetch,
                            },
                            $limit: needFetch.length,
                        }
                    }), 'data') || [];
                    _.each(extra, it => this.selectedCache[_.get(it, this.itemValue)] = it);
                    if (this.name) {
                        _.each(extra, this.addComputedText)
                    }
                    selection.push(...extra);
                }
            }
        return selection;
    }

    addComputedText(item) {
        if(!item.hasOwnProperty('$text')) {
            Object.defineProperty(item, '$text', {
                enumerable: false,
                get: () => this.computeText(item, this.name, true)
            });
        }
    }

    computeText (item, view : string[], text? : boolean) {
        const isHtml = !text;
        const html = [];
        if (!view) return '';
        isHtml && html.push('<div style="display: flex">');
        if (typeof view === 'string') view = [view];
        _.each(view, it => {
            isHtml && html.push('<div style="flex: 1">');
            let val = _.get(item, it) || '';
            if(this.translate) val = this.$td(val) || '';
            if ((typeof val !== 'string' || val.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/g)) && moment(val).isValid() && typeof val != 'number') val = moment(val).format('YYYY-MM-DD HH:mm')
            if (typeof val === 'object') val = JSON.stringify(val);
            val = `${val}`
            if (this.reg && isHtml) {
                val.split(this.reg).map((it, idx) => {
                    if (idx % 2 === 1) {
                        html.push('<span class="v-list__tile__mask">');
                        html.push(escapeHTML(it));
                        html.push('</span>');
                    } else {
                        html.push(escapeHTML(it));
                    }
                });
            } else {
                html.push((isHtml && escapeHTML(val)) || val);
            }
            isHtml && html.push('</div>');
        });
        isHtml && html.push('</div>');
        return html.join((text && '/') || '');
    }
    
    clearCache() {
        this.selectedCache = {};
    }
}


function escapeRegExp (text) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}
var escapeHTML = function (unsafe) {
    return unsafe.replace(/[&<"']/g, function (m) {
        switch (m) {
        case '&':
            return '&amp;';
        case '<':
            return '&lt;';
        case '"':
            return '&quot;';
        default:
            return '&#039;';
        }
    });
};
