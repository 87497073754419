
import _ from "lodash";
import { Component, mixins, Vue, FindType, Ref } from "@feathers-client";
import { VModel } from "vue-property-decorator";

@Component
export default class StaffPicker extends Vue {
  staff = false;

  @VModel()
  inputValue: FindType<"shop/staffs">

}
