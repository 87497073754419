
import { Component, Prop, Vue, Watch, mixins, Ref } from "nuxt-property-decorator";
// @ts-ignore
import MinusIcon from "../components-internal/MinusIcon.vue";
// @ts-ignore
import PlusIcon from "../components-internal/PlusIcon.vue";

@Component({
  components: {
    PlusIcon,
    MinusIcon,
  },
})
export default class MenuListNum extends Vue {
  @Prop()
  containerClass: any;

  @Prop()
  placeholder: string;

  @Prop()
  prefix: string;

  @Prop()
  suffix: string;

  @Prop()
  value: number;

  @Prop(Boolean)
  readonly: boolean;

  @Prop({ type: Number, default: 1 })
  step: number;

  @Prop({ type: Boolean, default: true })
  stepper: boolean;

  @Prop({ type: Boolean, default: true })
  stepperSpace: boolean;

  numPad = false;

  customReceived = "";

  @Prop({ type: String, default: "done" })
  enterkeyhint: string;

  @Prop({ type: Boolean, default: true })
  integer: boolean;

  @Prop({ type: Boolean })
  allowEmpty: boolean;

  @Prop({ type: Number })
  placeholderValue: number;

  @Prop({ type: String, default: "number" })
  type: string;

  @Prop({ type: Number })
  max: number;

  @Watch("numPad")
  onNumPad(v) {
    if (!v) this.customReceived = "";
  }

  get inputValue() {
    if (this.allowEmpty && this.value === null) {
      return this.placeholderValue;
    }
    return this.value;
  }

  set inputValue(v: number) {
    if (this.allowEmpty && v === null) {
      this.$emit("input", null);
      return;
    }
    v = this.integer ? Math.floor(v) : v;
    if (this.minValue !== undefined && this.minValue !== null) v = Math.max(v, this.minValue);
    if (this.maxValue !== undefined && this.maxValue !== null) v = Math.min(v, this.maxValue);

    if (v !== this.inputValue) {
      this.$emit("input", v);
    }
  }

  active = false;

  focused() {
    if(!this.disableInput){
      this.active = true;
    }
  }

  submitValue() {
    if(!this.disableInput){
      this.active = false;
      if (this.customReceived !== "" && !isNaN(+this.customReceived)) {
        this.inputValue = +this.customReceived;
      }
      this.customReceived = "";
    }
  }

  @Prop({ type: Number, default: 0 })
  minValue: number;

  @Prop({ type: Number })
  maxValue: number;

  @Prop({ type: Boolean , default : false})
  disableInput: boolean;

  applyDetla(delta: number) {
    if (typeof this.inputValue !== 'number') {
      if(isNaN(this.inputValue)) {
        this.inputValue = this.placeholderValue || 0;
      } else {
        this.inputValue = +this.inputValue + delta;
      }
    } else {
      this.inputValue += delta;
    }
  }
}
