
import { Component, Vue, VModel, Prop, Ref } from "@feathers-client";
import EditorTextField from "@schemaEditor/EditorTextField.vue";

@Component({
  components: {
    EditorTextField,
  },
})
export default class ColorPicker extends Vue {
  @VModel()
  inputValue: string;

  @Prop()
  default: string;

  @Ref()
  colorInput: HTMLDivElement;

  colorFocus = false;

  beforeMount() {
    if (!this.inputValue) {
      this.inputValue = this.default || "";
    }
  }
}
