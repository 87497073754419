
import Dialog from "@feathers-client/mixins/Dialog";
import { Component, mixins, Prop, FindType } from "@feathers-client";
import components from "@schemaEditor/components";
import Editor from "@schemaEditor/Editor.vue";
import { Ref, Watch } from "nuxt-property-decorator";
import DataTableForErp from "~/components/erp/DataTableForErp.vue";
import ObjectPicker from "~/components/ObjectPicker.vue";
import { Pop, AdminFindType } from "@feathersjs/feathers";
import _ from "lodash";
import EditorObjectPickerList from "@schemaEditor/EditorObjectPickerList.vue";
import VirtualDataList from "~/dep/domore-table/VirtualDataList.vue"
import { Types } from "mongoose";

export type ProductList = {
  product: AdminFindType<"shop/product/skus">;
  sku?: string;
}[];

@Component({
  components: {
    ...components,
    DataTableForErp,
    Editor,
    ObjectPicker,
    EditorObjectPickerList,
    VirtualDataList
  },
})
export default class ProductsPickerDialog extends mixins(Dialog) {

  @Prop()
  title: string

  @Prop()
  addNewSku: boolean;

  @Prop()
  productList: ProductList;

  @Ref("table")
  table: Vue & {};

  productQuery: any =
    {
      $populate: [
        // {
        //   path: 'sku',
        //   model: 'ShopInventorySummary',
        // },
        {
          path: 'productGroup',
          model: 'ShopProductGroup',
          populate: ['brand']
        },
      ],
    }

  async beforeMount() {
    // console.log(await this.$feathers.service('shop/product/skus').find({
    //   query: {
    //     ...this.productQuery
    //   }
    // }))
    // this.loadData();
  }

  get cartHeader() {
    return [
      this.$t("subsequentPaymentOrders.erp.selectedItems"),
      this.addNewSku ? this.$t('subsequentPaymentOrders.erp.cost') : this.$t("orders.price"),
      this.$t("orders.quantity"),
      this.$t("basic.total"),
      " ",
    ];
  }

  // get products() {
  //   return this.productList ? _.map(this.productList, p => p.product) : null;
  // }

  getInventory(product) {
    for (let shopTable of product.shopTable) {
      if (shopTable.cachedInventory === 0) {
        continue
      }
      return shopTable.cachedInventory
    }
    return 0
  }

  getProductCat(productSku) {
    if (!productSku.categories) return '-';

    for (let cat of productSku.categories) {
      if (!cat.tree) continue;
      for (let treeEl of cat.tree) {
        if (!treeEl.name) continue;
        for (let nameEl of treeEl.name) {
          if (this.findBy === 'all') return nameEl.value;
          else if (nameEl.value.includes(this.productKeyword)) {
            return nameEl.value;
          }
        }
      }
    }
    return "-";
  }

  getProductBrandName(productSku) {
    if (!productSku.productGroup || !productSku.productGroup.brand || !productSku.productGroup.brand.name) return "";
    return this.$td(productSku.productGroup.brand.name);
  }

  getProductPrice(productSku) {
    if (!productSku.shopTable) {
      return 0;
    } // should be return latest pos price instead of 0

    const firstShopTable = productSku.shopTable.find(el => this.$shop._id === el.shop?._id || el);
    const erpPricing = firstShopTable?.pricing.find(el => el.tag === "erp");
    if (erpPricing) {
      return erpPricing.priceInt ?? erpPricing.price ?? 0;
    }
    return firstShopTable?.pricing[0]?.priceInt ?? firstShopTable?.pricing[0]?.price ?? 0; // should be return latest pos price instead of 0
  }

  cart: {
    sku: object,
    quantity: number,
    price: number,
  }[] = [];

  addProductToCart(sku, qty) {
    const product = {
      sku: sku,
      quantity: qty,
      price: this.getProductPrice(sku),
    };
    this.cart.push(product);
  }

  removeProductFromCart(sku) {
    for (let i = 0; i < this.cart.length; i++) {
      const currEl = this.cart[i];
      if (currEl.sku['_id'] === sku._id) {
        this.cart.splice(i, 1);
      }
    }
  }

  deleteCartItem(cartEl) {
    this.cart.splice(this.cart.indexOf(cartEl), 1)
  }

  checkIfSkuInCart(product) {
    const existEl = _.find(
      this.cart,
      el => el.sku["_id"] === product._id,
    );
    if (existEl) {
      return existEl;
    }
  }

  updateCart(int, product) {
    console.log("int", int);
    if (int > 0) {
      let skuInCart = this.checkIfSkuInCart(product);
      if (skuInCart) {
        skuInCart.quantity = int;
      } else {
        this.addProductToCart(product, int);
      }
    } else {
      this.removeProductFromCart(product);
    }
  }


  productKeyword = "";
  productKeywordInput = "";
  findBy: "brand" | "category" | "all" = "all";
  get findByTypes() {
    return [
      {
        _id: 'brand',
        name: this.$t("pos.brands"),
      },
      {
        _id: 'category',
        name: this.$t("pos.summary.category"),
      },
      {
        _id: 'all',
        name: this.$t("enum.basic.status.all"),
      },
    ]
  }

  @Watch('findBy')
  onFindBy(n, o) {
    if (n === null || n === '') {
      this.findBy = 'all'
    }
  }

  loading = false;
  async searchProduct() {
    if (this.loading) return;
    this.loading = true;
    this.productKeyword = this.productKeywordInput;
    if (this.productKeyword === "") {
      this.productQuery = {
        $populate: [
          {
            path: 'productGroup',
            model: 'ShopProductGroup',
            populate: ['brand']
          },
        ],
      }
      this.loading = false;
      return;
    }

    switch (this.findBy) {
      case "brand":
        const allbrandName = await this.$feathers.service('shop/brands').find({
          query: {
            shop: this.$shop._id,
            'name.value': { $regex: `.*${this.productKeyword}.*` },
            $paginate: false,
            $select: ['_id']
          },
          paginate: false
        });
        const allpGroup = await this.$feathers.service('shop/product/groups').find({
          query: {
            shop: this.$shop._id,
            brand: {
              $in: _.map(allbrandName, el => el._id),
            },
            $paginate: false,
            $select: ['_id']
          },
          paginate: false
        });

        this.productQuery = {
          productGroup: {
            $in: _.map(allpGroup, el => el._id),
          },
          $populate: [
            {
              path: 'productGroup',
              model: 'ShopProductGroup',
              populate: ['brand'],
            },
          ],
        }
        break;

      case "category":
        const allpGroup2 = await this.$feathers.service('shop/product/groups').find({
          query: {
            shop: this.$shop._id,
            'categories.tree.name.value': { $regex: `.*${this.productKeyword}.*` },
            $paginate: false,
            $select: ['_id']
          },
          paginate: false
        });
        this.productQuery = {
          productGroup: {
            $in: _.map(allpGroup2, el => el._id),
          },
          $populate: [
            {
              path: 'productGroup',
              model: 'ShopProductGroup',
              populate: ['brand'],
            },
          ],
        }
        break;

      case "all":
        const allbrandName2 = await this.$feathers.service('shop/brands').find({
          query: {
            shop: this.$shop._id,
            'name.value': { $regex: `.*${this.productKeyword}.*` },
            $paginate: false,
            $select: ['_id']
          },
          paginate: false
        });
        const allpGroup3 = await this.$feathers.service('shop/product/groups').find({
          query: {
            shop: this.$shop._id,
            $or: [
              {
                'categories.tree.name.value': { $regex: `.*${this.productKeyword}.*` }
              },
              {
                brand: { $in: _.map(allbrandName2, el => el._id) },
              },
            ],
            $paginate: false,
            $select: ['_id']
          },
          paginate: false
        });

        this.productQuery = {
          $or: [
            {
              productGroup: {
                $in: _.map(allpGroup3, el => el._id),
              },
            },
            {
              "name.value": { $regex: `.*${this.productKeyword}.*` }
            }
          ],
          $populate: [
            {
              path: 'productGroup',
              model: 'ShopProductGroup',
              populate: ['brand'],
            },
          ],
        }
        break;
      default:
        break;
    }
    this.loading = false;
  }

  @Watch("findBy")
  onProductKeywordBy(n, o) {
    console.log("n", n);
  }

  getProductName(cartEl) {
    return cartEl.sku?.name?.length ? this.$td(cartEl.sku?.name) : cartEl.sku?.fullName?.length ? this.$td(cartEl.sku?.fullName) : '-'
  }

  async debug() {
    const res = await this.$feathers.service("warehouses/inventories/summary").find({
      query: {
        // shop: this.$shop._id,
        $paginate: false,
        // From Cyrus: This not work because you see somthing wrong
        // ---> right hand side is the shop/inventory/summaries
        // $populate: [{path: 'sku', model:'ShopProductSku'},]
        //  'productGroup',
        //  'warehouse'
        // ]
      },
      paginate: false,
    });

    console.log("debug res", res);
  }

}
