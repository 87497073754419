import { render, staticRenderFns } from "./ProductsPickerDialog.vue?vue&type=template&id=6f6af1e5"
import script from "./ProductsPickerDialog.vue?vue&type=script&lang=ts"
export * from "./ProductsPickerDialog.vue?vue&type=script&lang=ts"
import style0 from "./ProductsPickerDialog.vue?vue&type=style&index=0&id=6f6af1e5&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VDivider,VIcon})


/* hot reload */
if (module.hot) {
  var api = require("/home/jason/p/boxs/server/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6f6af1e5')) {
      api.createRecord('6f6af1e5', component.options)
    } else {
      api.reload('6f6af1e5', component.options)
    }
    module.hot.accept("./ProductsPickerDialog.vue?vue&type=template&id=6f6af1e5", function () {
      api.rerender('6f6af1e5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/erp/dialogs/ProductsPickerDialog.vue"
export default component.exports