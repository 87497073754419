
import { mixins, Component, Prop } from '@feathers-client';
import HeaderProvider from './mixins/HeaderProvider';
import VirtualDataTableCell from './VirtualDataTableCell'
import type { DataTableHeader } from './index'

@Component({
  components: {
    VirtualDataTableCell,
  }
})
export default class HeaderRender extends mixins(HeaderProvider) {

  @Prop()
  header: DataTableHeader

  @Prop()
  value: any

}

