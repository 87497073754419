
import { Component, Prop, Vue, Watch, mixins, Ref } from "nuxt-property-decorator";
import ButtonBase from "../components-internal/ButtonBase.vue";

@Component
export default class MenuListItem extends mixins(ButtonBase) {
  @Prop(Boolean)
  loading: boolean;

  @Prop(Boolean)
  fill: Boolean;

  @Prop()
  icon: string;

  @Prop({ type: Boolean })
  lock: boolean;

  @Prop({ type: Boolean })
  paddedLock: boolean;

  @Prop({ type: Boolean, default: false })
  wordwrap: boolean;
}
