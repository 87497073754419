
import { Component, Vue, Prop, FindType, PropSync } from "@feathers-client";
import VueApexCharts from "vue-apexcharts";
import Percentage from "../Percentage.vue";
import _, { keyBy } from "lodash";
import moment from "moment";
import { isConstructorDeclaration } from "typescript";

@Component({
  components: {
    Percentage,
    VueApexCharts,
  },
})
export default class NewLineChart extends Vue {
  @Prop() latestStartDate!: Date;
  @Prop() latestEndDate!: Date;
  @Prop() previousStartDate!: Date;
  @Prop() previousEndDate!: Date;
  @Prop() latestChartData!: any;
  @Prop() previousChartData!: any;
  @Prop({ default: '' }) title!: string;
  @Prop({ default: '' }) type!: 'amount' | 'customer' | 'percentage' | 'amountInt';
  @Prop({ default: '' }) mode!: string;
  @Prop({ default: 0 }) latestAmount!: any;
  @Prop({ default: 0 }) previousAmount!: any;

  shop: FindType<"shops"> = null;
  formatDataObject = [];
  formatPreviousDateObject = [];
  seriesData: any
  get newLineChartOption() {
    let options = {
      series: this.series,
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        parentHeightOffset: 15,
        redrawOnParentResize: true,
      },
      colors: ["#4F3DD5", "#BDBDBD"],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
        width: this.$vuetify.breakpoint.mdAndDown ? 2 : 3,
      },
      markers: {
        size: this.$vuetify.breakpoint.mdAndDown ? 3 : 4,
        colors: ["#fff"],
        strokeColors: ["#4F3DD5", "#BDBDBD"],
        strokeDashArray: 10,
        hover: {
          size: 5,
        },
      },
      grid: {
        borderColor: "#ccc",
        strokeDashArray: 2,
      },
      xaxis: {
        type: 'category', //this.mode == 'today' ? 'time' : 'time',
        labels: {
          datetimeUTC: false,
          format: this.mode == 'today' ? 'HH:mm' : 'MM-dd',
          trim: false,
          rotate: 0,
          minHeight: 40,
          hideOverlappingLabels: true,
        },
        axisTicks: {
          show: false,
        },
      },
      tooltip: {
        x: {
          show: false, format: 'MMM dd HH:mm',
        },
        y: {
          show: false,
          formatter: undefined,
        },
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
          const data = w.globals.initialSeries.map((x) => x.data[dataPointIndex]);
          const globals = w.globals;
          return `<div class='p-3 text-white bg-[rgba(51,51,51,0.8)]'>
              <div>${this.$t(this.title)}</div>
              <div> <span class='inline-block rounded-full w-3 h-3 bg-[#4F3DD5] '> </span> ${globals.seriesNames[0]}  ${this.type === 'amountInt' ? this.$price(data[0].y, 'pre') : (data[0].y)}</div>
              <div> <span class='inline-block rounded-full w-3 h-3 bg-[#BDBDBD] '> </span> ${globals.seriesNames[1]}   ${this.type === 'amountInt' ? this.$price(data[1].y, 'pre') : (data[1].y)}</div>
              </div>`;
        }
      },
    };
    return options;
  }
  get series() {
    if (!this.latestChartData && !this.previousChartData) {
      return []
    }
    let seriesDataAfterGen = this.genChartSeriesElements(this.latestChartData, this.previousChartData)
    this.seriesData = this.seriesFormatting(seriesDataAfterGen)
    let seriesDataCopy = _.cloneDeep(this.seriesData)
    let series = seriesDataCopy.map((data) => {
      return {
        name: data.name,
        data: this.mode == 'today' ? this.fillMissingDataPointsDay(data.data) : this.fillMissingDataPointsOthers(data.data)
      }
    })
    return series
  }
  genChartSeriesElements(latestArray, previousArray) {
    let latest = _.map((latestArray), (v, k) => ({
      date: k,
      value: v.value,
      hour: v.hour,
      values: v.values
    }));
    let latest2 = this.checkAndAddDate(latest, this.latestStartDate, this.latestEndDate, this.mode);
    let previous = _.map((previousArray), (v, k) => ({
      date: k,
      value: v.value,
      hour: v.hour,
      values: v.values
    }));
    let previous2 = this.checkAndAddDate(previous, this.previousStartDate, this.previousEndDate, this.mode);
    return [latest2, previous2];
  }
  seriesFormatting(array) {
    if (array) {
      if (this.mode == 'today') {
        const shopOpenMin = 0;
        const shopCloseMin = 0;
        // Process the data and return it in the desired format
        let result = []
        for (let i of array) {
          i.map(({ date, hour, values }) => {
            const newData = hour.map((h, index) => {
              const minutes = index === 0 ? shopOpenMin : (index === hour.length - 1 ? shopCloseMin : 0);
              return { x: `${date} ${h.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`, y: Math.round(values[index] * 100) / 100 };
            });
            return result.push({ name: date, data: newData });
          });
        }
        return result
      }
      else {
        let a = this.transformData(array)
        return a
      }
    }
  }
  transformData(data) {
    if (data) {
      return [
        {
          name: this.mode == "week" ? this.$t("chartMessage.thisWeek") : this.$t("chartMessage.thisMonth"),
          data: data[0]
            .filter((obj) => moment(obj.date).isBetween(this.latestStartDate, this.latestEndDate, null, "[]"))
            .map((obj) => ({ x: moment(obj.date).format("MM-DD"), y: (obj.value).toFixed(0) })),
          index: 3
        },
        {
          name: this.mode == "week" ? this.$t("chartMessage.lastWeek") : this.$t("chartMessage.lastMonth"),
          data: data[1]
            .filter((obj) => moment(obj.date).isBetween(this.previousStartDate, this.previousEndDate, null, "[]"))
            .map((obj) => ({ x: moment(obj.date).format("MM-DD"), y: (obj.value).toFixed(0) })),
          index: 2
        },
      ];
    }
  };
  fillMissingDataPointsDay(data, defaultValue = 0) { // done series for day
    if (data && data.length) {

      let newData = [];
      let index = data.length - 1
      let start = moment(data[0].x).hour()
      let end = moment(data[index].x).hour()
      let dataLength = end - start + 1

      for (let i = start; i < dataLength; i++) { // 24 need to modify when confirm 
        let found = false;
        for (let j = 0; j < data.length; j++) {
          let date = moment(data[j].x);
          if (date.hours() === i) {
            // let tempData = data[j]
            // tempData.x = moment(tempData.x)
            newData.push({ x: date.format("HH:mm"), y: Math.round(data[j].y * 100) / 100 });
            found = true;
            break;
          }
        }
        if (!found) {
          newData.push({
            x: moment(data[0].name).hours(i).minute(0).format("HH:mm"),
            y: defaultValue,
          });
        }
      }
      return newData;
    } else {
      return []
    }
  }
  fillMissingDataPointsOthers(data, defaultValue = 0) { // done series for week or month
    if (data && data.length) {
      const newData: any = [];
      const dates = data.map((d) => d.x,);
      const startDate = moment(dates[0], 'MM-DD');
      const endDate = moment(dates[dates.length - 1], 'MM-DD');
      const currentDate = startDate;

      while (currentDate.diff(endDate, 'days') <= 0) {
        const dateString = currentDate.format('MM-DD');
        const dataIndex = dates.indexOf(dateString);
        if (dataIndex !== -1) {
          // Date already exists in the data array
          newData.push(data[dataIndex]);
        } else {
          // Date is missing, add a new data point with the default value
          newData.push({
            x: currentDate.format('MM-DD'),
            y: defaultValue,
          });
        }
        currentDate.add(1, 'day');
      }
      return newData;
    } else {
      return []
    }
  }
  checkAndAddDate(oldArray, startDate, endDate, mode, emptyValue: any = []) {
    let arrayBeforeSort = _.cloneDeep(oldArray);
    let array = arrayBeforeSort.sort((a, b) => {
      if (moment(a.date) < moment(b.date)) return -1;
      if (moment(a.date) > moment(b.date)) return 1;
      return 0
    })
    if (array) {
      const shopOpen = 0;
      const shopClose = 23;
      if (!array.length) {
        array.push({ date: moment(startDate).format("YYYY-MM-DD"), hour: [shopOpen, shopClose], values: [0, 0], value: 0 });
      } else {
        const firstDate = moment(array[0]?.date);
        const lastDate = moment(array[array.length - 1]?.date);
        // Check Start Hour
        if (moment(startDate).isSame(firstDate, "day")) {
          if (!array[0].hour.find(e => e == shopOpen)) {
            array[0].hour.unshift(shopOpen);
            array[0].values.unshift(0);
          }
        }
        // Check End Hour
        if (moment(endDate).isSame(lastDate, "day")) {
          if (!array[0].hour.find(e => e == shopClose)) {
            array[0].hour.push(shopClose);
            array[0].values.push(0);
          }
        }
      }
      const firstDate = moment(array[0]?.date);
      const lastDate = moment(array[array.length - 1]?.date);
      // Check Start Date
      if (!moment(startDate).isSame(firstDate, "day")) {
        const newEntry = {
          date: this.formatDate(startDate),
          hour: emptyValue,
          value: 0,
          values: [0, 0],
        };
        array.unshift(newEntry);
      }
      // Check End Date
      if (!moment(endDate).isSame(lastDate, "day")) {
        const newEntry = {
          date: this.formatDate(endDate),
          hour: emptyValue,
          value: 0,
          values: [0, 0],
        };
        array.push(newEntry);
      }
      return array
    }
  }
  formatDate(date) {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    return formattedDate;
  }
}
