
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import { Order } from "@common/pos";
import { PaymentMethodType } from "@common/common";
import _ from "lodash";

@Component
export default class OrderSystemPointItem extends Vue {
  @Prop()
  session: Order;

  @Prop()
  paymentMethod: PaymentMethodType;

  amount = 0;
  amountStr: string = "";

  mounted() {
    this.amount = this.maxUse;
    this.amountStr = `${this.amount}`;
  }

  @Watch("amount")
  onAmount() {
    this.$emit(
      "update:pointValid",
      this.amount > 0 && this.amount <= this.availPoint && (!this.$pos.kioskMode || this.amount === this.maxPoint),
    );
  }

  get point() {
    return this.paymentMethod.options?.point;
  }

  get availPoint() {
    return Math.max(0, (this.session.availPoints[this.point] ?? 0) - this.usePoint);
  }

  get usePoint() {
    return this.session.usePoints.find(it => it.point === this.point)?.value ?? 0;
  }

  get maxPoint() {
    let amount = this.session.outstandingInt;
    return Math.ceil(amount / (this.paymentMethod.options.usePointRatioInt || 1));
  }

  get maxUse() {
    const maxPoint = this.maxPoint;
    if (maxPoint > this.availPoint) {
      return this.availPoint;
    }
    return maxPoint;
  }

  apply() {
    if (this.amountStr) {
      let receivedInt = +this.amountStr;
      if (isNaN(receivedInt)) return null;
      this.amount = receivedInt;
    }
    return this.amount > 0 && this.amount <= this.availPoint && (!this.$pos.kioskMode || this.amount === this.maxPoint)
      ? this.amount
      : null;
  }
}
