<template>
<v-card>
    <v-card-title class="headline" v-t="'pos.productBundleSelect.$'"></v-card-title>
    <v-card-text>
        <v-list>
            <v-list-item @click="modalResult(item)" v-for="item in bundles" :key="item._id">
                <v-list-item-avatar :height="48">
                    <v-img contain :width="48" :height="48" :src="$thumb(item)"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>{{item.name}}</v-list-item-title>
                    <v-list-item-subtitle><formatter :value="item.price" format="currency"/> ({{getQuantity(item)}})</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item @click="modalResult(true)">
                <v-list-item-content>
                    <v-list-item-title>{{$t('pos.productBundleSelect.singleOnly')}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-card-text>
</v-card>
</template>

<script>

import Dialog from '@feathers-client/mixins/Dialog'
import nuxtend from '@feathers-client/nuxtend'
import _ from 'lodash'

export default nuxtend({
    mixins: [
        Dialog
    ],
    props: {
        bundles: {},
    },
    methods: {
        getQuantity(items) {
            return _.sumBy(items.products, p => p.quantity)
        }
    }

})

</script>
