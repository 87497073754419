
import { Component, Vue, Prop, Ref } from "@feathers-client";
import { PageBlock } from "../def";
import { SiteEditorComponentVariant, SiteEditorManager } from "..";
import EditorList from "@schemaEditor/EditorList.vue";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import EditorTranslateBox from "@schemaEditor/EditorTranslateBox.vue";
import EditorCheckbox from "@schemaEditor/EditorCheckbox.vue";
import EditorObjectPickerList from "@schemaEditor/EditorObjectPickerList.vue";
import ColorPicker from "../ColorPicker.vue";
import UrlPicker from "../UrlPicker.vue";
import uuid from "uuid/v4";
import { LangArrType } from "@feathers-client/i18n";
import CheckIcon from "@feathers-client/components-internal/CheckIcon.vue";
// @ts-ignore
import DeleteSvg from "!vue-svg-loader!../assets/delete.svg";
// @ts-ignore
import RarrowSvg from "!vue-svg-loader!../assets/rarrow.svg";
// @ts-ignore
import HotspotSvg from "!vue-svg-loader!../assets/hotspot.svg";
import LangPicker from "../LangPicker.vue";

@Component({
  components: {
    EditorList,
    EditorTextField,
    EditorTranslateBox,
    EditorCheckbox,
    EditorObjectPickerList,
    LangPicker,
    ColorPicker,
    UrlPicker,
    CheckIcon,
    DeleteSvg,
    RarrowSvg,
    HotspotSvg,
  },
})
export default class SiteEditorImageWithPin extends Vue {
  @Prop()
  block: PageBlock;

  @Prop()
  manager: SiteEditorManager;

  @Prop()
  variant: SiteEditorComponentVariant;

  // @Prop()
  // modalResult: (save: boolean) => void;

  @Ref()
  search: HTMLInputElement;

  @Ref()
  datalist: any;

  importItemsIndex = null;
  currentHotspotIndex = null;
  originalHotspot = { coordX: null, coordY: null };
  selectedItems = [];
  searchKeyword = "";
  onSearch = false;
  customQuery: any = {};
  haveError = false;
  errorMsg: any = "";

  isStaticItem = this.groupKey === "gallery" && (this.keyName === "irregular" || this.keyName === "gether");

  get currentHotspot() {
    if (this.currentHotspotIndex !== null) return this.editingSlideShow.items[this.currentHotspotIndex];
    else return null;
  }

  get listTypes() {
    return [
      {
        _id: "all",
        name: {
          $t: this.manager.$t("componentGroups.common.all"),
        },
      },
      {
        _id: "categories",
        name: {
          $t: this.manager.$t("componentGroups.common.categories"),
        },
      },
      {
        _id: "tags",
        name: {
          $t: this.manager.$t("componentGroups.common.tags"),
        },
      },
      {
        _id: "brands",
        name: {
          $t: this.manager.$t("componentGroups.common.brands"),
        },
      },
    ];
  }

  get titleDisplayModeType() {
    return ["left", "center", "right"];
  }

  getItem() {
    return {
      id: uuid(),
      coordX: null,
      coordY: null,
      file: null,
      urlType: "internal",
      url: null,
    } as SiteEditorPinItem;
  }

  getSlideShow() {
    return {
      id: uuid(),
      bgImage: [
        {
          id: uuid(),
          file: null,
          displayPrice: true,
          displayTooltip: true,
        },
      ] as SiteEditorBgImage[],
      items: [] as SiteEditorPinItem[],
    };
  }

  get groupKey() {
    return this.variant.group.key;
  }

  get keyName() {
    return this.variant.key;
  }

  get imageItemSuggest() {
    if (this.isStaticItem)
      return this.manager.$t(`componentGroups.${this.groupKey}.imageItemSuggest2`, {
        count: this.keyName === "gether" ? 3 : 5,
      });
    else return this.manager.$t(`componentGroups.${this.groupKey}.imageItemSuggest`);
  }

  get props() {
    return this.manager.defineProps(this.block, {
      slideShows: [
        {
          bgImage: [
            {
              id: uuid(),
              file: null,
              displayPrice: true,
              displayTooltip: true,
            },
          ],
          items: [],
        },
      ] as SiteEditorImageWithPinSlideShow[],
      title: [] as LangArrType,
      bgColor: "#FFFFFFFF",
      hotspotColor: "#FFFFFFFF",
      titleDisplayMode: "left",
      fontColor: "#000000FF",
    });
  }

  slideShowEditingIndex = 0;
  onIndexChange(v: number) {
    this.slideShowEditingIndex = v;
  }

  get editingSlideShow() {
    return this.props.slideShows[this.slideShowEditingIndex];
  }

  tabEntryName(item, idx) {
    return `${this.manager.$t("componentGroups.imageWithPin.slideShow")} ${idx + 1}`;
  }

  selectAll() {
    this.search?.select?.();
  }

  goSearch() {
    this.onSearch = true;
    this.search?.blur?.();
    this.customQuery = {
      ...this.customQuery,
      $keyword: this.searchKeyword,
    };
  }

  onSelect(value) {
    if (this.selectedItems.includes(value)) {
      this.selectedItems = this.selectedItems.filter(item => item._id !== value._id);
    } else {
      this.selectedItems.push(value);
    }
  }

  displayItemPrice(value) {
    return this.$price(value.pricing[0]?.min?.price ?? 0, "pre");
  }

  onDelete(value) {
    if (this.selectedItems.includes(value)) {
      this.selectedItems = this.selectedItems.filter(item => item._id !== value._id);
    }
  }

  exportSelectedItem() {
    const selectedItems = this.selectedItems;
    const newItems = [];
    for (let item of selectedItems) {
      newItems.push({
        id: uuid(),
        productGroup: item.productGroup,
        file: item.mainImage,
        title: item.name ? item.name : "",
        slug: item.slug ? item.slug : "",
        price: item.pricing[0]?.min?.price ?? 0,
        urlType: "internal",
        url: `/product/${item.slug}?id=${item.productGroup}`,
        coordX: null,
        coordY: null,
      });
    }

    this.props.slideShows[this.importItemsIndex].items.push(...newItems);

    this.selectedItems = [];
    this.onSearch = false;
    this.importItemsIndex = null;
    (this.$refs.router as any).navigate();
  }

  showErrorMsg() {
    // if (this.isStaticItem && this.props.items.length !== (this.keyName === "irregular" ? 5 : 3)) {
    //   this.errorMsg = this.manager.$t("componentGroups.common.valid.numOfItem");
    // }
    // let isItemValid = true;
    // for (let item of this.props.items) {
    //   if (this.$td(item.desc).length > 10) isItemValid = false;
    //   if (!isItemValid) break;
    // }
    // if (!isItemValid) this.errorMsg = this.manager.$t("componentGroups.common.valid.desc");
  }

  onValidAndConfirm() {
    // let isItemValid = true;
    // for (let item of this.props.items) {
    //   if (this.$td(item.desc).length > 10) isItemValid = false;
    //   if (!isItemValid) break;
    // }
    // this.haveError = !isItemValid;

    // if (this.isStaticItem && this.props.items.length !== (this.keyName === "irregular" ? 5 : 3)) {
    //   this.haveError = true;
    // }
    // if (this.haveError) {
    //   this.showErrorMsg();
    //   return;
    // }

    this.$emit("save");
  }

  setImportImage(index, navigate) {
    this.importItemsIndex = index;
    navigate("importImage");
  }

  setHotspotIndex(index, navigate) {
    this.currentHotspotIndex = index;
    this.originalHotspot.coordX = this.currentHotspot?.coordX;
    this.originalHotspot.coordY = this.currentHotspot?.coordY;

    navigate("setHotspot");
  }

  onSaveHotspot() {
    this.currentHotspotIndex = null;
    (this.$refs.router as any).navigate();
  }

  onCancelHotspot() {
    this.currentHotspot.coordX = this.originalHotspot.coordX;
    this.currentHotspot.coordY = this.originalHotspot.coordY;

    this.currentHotspotIndex = null;
    (this.$refs.router as any).navigate();
  }

  onSetHotspot(event) {
    this.currentHotspot.coordX = ((event.offsetX - 28.5) / (this.$refs.image as any).$el.clientWidth) * 100;
    this.currentHotspot.coordY = ((event.offsetY - 27.5) / (this.$refs.image as any).$el.clientHeight) * 100;
  }
}

export class SiteEditorPinItem {
  id?: string;
  slug?: string;
  file?: any;
  title?: LangArrType;
  desc?: LangArrType;
  price?: number;
  urlType: string;
  url?: string;
  bgColor?: string;
  fromImport?: boolean;
  coordX?: number;
  coordY?: number;
  productGroup?: string;
}

export class SiteEditorBgImage {
  id?: string;
  file?: any;
  displayPrice?: boolean;
  displayTooltip?: boolean;
}

export class SiteEditorImageWithPinSlideShow {
  id?: string;
  bgImage?: SiteEditorBgImage[];
  items?: SiteEditorPinItem[];
}
