

import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import { Order } from "@common/pos";
import { DiscountType } from '@common/common'
import _ from 'lodash'

@Component
export default class OrderSystemPointItem extends Vue {
    @Prop()
    session : Order;

    @Prop()
    discount: DiscountType

    @Prop()
    point: string

    get availPoint() {
        return this.session.availPoints[this.point] ?? 0;
    }

    get currentRule() {
        return this.session.discountCal.discounts.find(
            it => `${it.discount.usePoint}` === this.point
        )
    }

    get currentApplied() {
        return this.session.discounts.find(it => it.discount === this.currentRule?.discount?._id);
    }

    get amountWithoutCurrentRule() {
        return this.session.amountInt - (this.currentApplied?.totalInt ?? 0);
    }

    get step() {
        return this.discount.usePointStep || 1;
    }

    get maxUse() {
        let amount = this.amountWithoutCurrentRule;
        const beforeTotal = this.session.charges.find(it => it.type === 'product')?.amountInt ?? 0;

        if(this.discount.usePointPercentageMax) {
            amount = Math.min(amount, this.discount.usePointPercentageMax * beforeTotal / 100);
        }

        if(this.discount.usePointFixedMaxInt) {
            amount = Math.min(amount, this.discount.usePointFixedMaxInt);
        }

        let maxPoint = (amount / (this.discount.usePointRatioInt || 1));
        if(maxPoint > this.availPoint) {
            maxPoint = this.availPoint;
        }

        return Math.floor(maxPoint / this.step) * this.step;
    }

    customTips = false;
    tipsStr = '';

    get presetTips() {
        let presets = [
            1, 2, 5, 10, 20, 50, 100
        ].map(it => it * this.step).filter(it => it <= this.maxUse);
        
        if(this.maxUse) presets.push(this.maxUse);
        presets = _.sortBy(_.uniq(presets));

        return presets.map(it => ({
            _id: it,
            name: `${it}`
        }))
    }

    get isCustomTips() {
        return this.tips && !this.presetTips.find(it => it._id === this.tips);
    }

    get tips() { return this.currentRule?.usePointNum ?? 0 }
    set tips(v) {
        v = !isNaN(+v) ? +v : 0;

        if(!this.currentRule || this.currentRule.discount._id !== this.discount._id) {
            // need to switch rule
            if(this.currentRule) {
                this.session.discountCal.removeDiscounts([this.currentRule.discount]);
            }
            this.session.discountCal.addDiscounts([this.discount]);
        }

        if(this.currentRule) {
            this.currentRule.usePointNum = v;
        }
        this.customTips = false;
    }

}

