
import { Component, Prop, Watch, Vue } from '@feathers-client'
import uuid from 'uuid/v4'

@Component
export default class FullscreenHost extends Vue {

    beforeMount() {
        document.addEventListener("fullscreenchange", this.fullscreenChange);
        document.addEventListener("webkitFullscreenchange", this.fullscreenChange);
    }

    beforeDestroy() {
        document.removeEventListener("fullscreenchange", this.fullscreenChange);
        document.removeEventListener("webkitFullscreenchange", this.fullscreenChange);
    }

    fullscreenChange(event) {
        this.$store.commit('SET_FULLSCREEN', event.fullscreen);
    }

    fullscreen() {
        if (document.fullscreenElement) {
            if (document.exitFullscreen) {
                document.exitFullscreen()
                this.$store.commit('SET_FULLSCREEN', false);
            }
            return;
        }
        if ((document as any).webkitFullscreenElement) {
            if ((document as any).webkitFullscreenElement) {
                (document as any).webkitExitFullscreen()
                this.$store.commit('SET_FULLSCREEN', false);
            }
        }
        if (!(window.navigator as any).standalone) {
            const elem = document.querySelector('#app') as HTMLElement;
            const rfq = elem.requestFullscreen || (elem as any).webkitRequestFullscreen || (elem as any).mozRequestFullScreen || (elem as any).msRequestFullscreen;
            if (!rfq) return;
            rfq.call(elem);
        }
        this.$store.commit('SET_FULLSCREEN', true);
    }

    exitFullscreen() {
        if (document.fullscreenElement && document.exitFullscreen)
            document.exitFullscreen()
        if ((document as any).webkitFullscreenElement && (document as any).webkitExitFullscreen)
            (document as any).webkitExitFullscreen()
        this.$store.commit('SET_FULLSCREEN', false);
    }

}

