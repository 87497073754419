<template>
<v-card>
    <v-card-title class="headline" v-t="'pos.productSelect.$'"></v-card-title>
    <v-card-text>
        <v-list>
            <v-list-item @click="modalResult(item)" v-for="item in products" :key="item._id">
                <v-list-item-content>
                    <v-list-item-title>{{$td(item.name)}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-card-text>
</v-card>
</template>

<script>

import Dialog from '@feathers-client/mixins/Dialog'
import nuxtend from '@feathers-client/nuxtend'

export default nuxtend({
    mixins: [
        Dialog
    ],
    props: {
        products: {},
    }

})

</script>
