
import uuid from "uuid/v4";
import _ from "lodash";
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";

type Attachment = {
  _id?: string;
  path: string;
  dir: string;
  name: string;
  size: number; //file size
  mime: string; // file mime
  type: string;
  source: string;
  parent: string;
  date: Date;
  userContent: boolean;

  thumb: Buffer;
  thumbWebp: Buffer;

  width: number; // image width
  height: number; // image height
  duration: number; // video duration

  sizes: {
    format: string;
    width: number;
    height: number;
    src: string; // optimized file
  }[];

  meta: any;
  status: string;
  src: string;
  hash: string;
};

@Component
export default class extends Vue {
  @Prop()
  value: string;
  cachedInfo: Attachment = null;
  uploadingFile: File = null;
  loading = false;

  @Prop({ type: Boolean })
  readonly: boolean;

  error: string = null;

  @Prop()
  dir: string;

  @Prop()
  parent: string;

  @Prop()
  type: string;

  get inputValue(): File {
    return (
      this.uploadingFile ||
      (this.cachedInfo
        ? ({
            name: this.cachedInfo.name,
            size: this.cachedInfo.size,
          } as any)
        : this.value
          ? this.type === "buffer"
            ? {
                name: "buffer",
                size: this.value?.length,
              }
            : this.type === "base64"
              ? {
                  name: "buffer",
                  size: Buffer.from(this.value).length,
                } as any
              : ({
                  name: this.value,
                  size: 0,
                } as any)
          : null)
    );
  }

  set inputValue(val: File) {
    if (val) {
      if (val instanceof Blob) {
        this.uploadFile(val);
      }
    } else {
      this.$emit("input", null);
    }
  }

  @Watch("value")
  async onValueUpdated() {
    if (this.type === "buffer" || this.type === "base64") return;
    if (!this.value) {
      this.cachedInfo = null;
      return;
    }
    if (this.cachedInfo && this.cachedInfo._id === this.value) return;
    this.cachedInfo = null;
    try {
      const info = <any>await (<any>this.$feathers.service("attachments")).get(this.value);
      if (info._id === this.value) {
        this.cachedInfo = info;
      }
    } catch (e) {
      console.warn(e);
    }
  }

  mounted() {
    this.onValueUpdated();
  }

  async uploadFile(mfile: File) {
    this.uploadingFile = mfile;
    var data = new FormData();
    data.append("file", mfile, mfile.name);
    this.error = null;
    this.loading = true;

    try {
      if (this.type === "buffer" || this.type === "base64") {
        const fileReader = new FileReader();
        const buf = await new Promise<Buffer>((resolve, reject) => {
          fileReader.onload = () => {
            resolve(Buffer.from(fileReader.result as ArrayBuffer));
          };
          fileReader.onerror = () => {
            reject(fileReader.error);
          };
          fileReader.readAsArrayBuffer(mfile);
        });
        if(this.type === "base64") {
          this.$emit("input", buf.toString("base64"));
        } else {
          this.$emit("input", buf);
        }
      } else {
        if (typeof (this.$feathers as any).uploadFile === "function") {
          // By jason
          const response = await (this.$feathers as any).uploadFile(mfile);
          this.cachedInfo = response;
          this.$emit("input", this.cachedInfo._id);
        } else {
          const response = await (this.$feathers as any).post(
            `attachments/upload/${this.dir || "others"}/${this.parent || "others"}`,
            data,
            {
              onUploadProgress: (progressEvent) => {},
            },
          );

          if (this.uploadingFile !== mfile) return;

          const rinfo = (response.data || {}).info || {};
          this.cachedInfo = rinfo;
          this.$emit("input", this.cachedInfo._id);
        }
      }
    } catch (e: any) {
      this.error = e.message;
    } finally {
      if (this.uploadingFile === mfile) {
        this.uploadingFile = null;
        this.loading = false;
      }
    }
  }
}
