import type { MApplication } from "@feathersjs/feathers";
import type { AdminApplication, SchemaApplication } from "serviceTypes";
import type { Store } from "vuex";
import type {
  SchemaDefParamsService,
  SchemaDefJson,
  SchemaFieldJson,
  EditorConfig as DBEditorConfig,
} from "@db/schema";
export type { SchemaTypeFullJson } from "@db/schema";
import helper from "../helper";
import _ from "lodash";
import expressions from "angular-expressions";
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import { Context } from "@nuxt/types";
import { VProgressCircular } from "vuetify/lib";
import type { ImportContext, WorksheetProcessor, WorksheetReader } from "../importCommon";
import { getOptions } from "@feathers-client/util";
import { CurrentApp } from "@feathers-client/index";

import { EditorConfig } from "./config";
export { EditorConfig } from "./config";

import type {
  EditorFieldOptions,
  EditorField,
  CustomType,
  AclHandler,
  RoleDef,
  EditorGroupOptions,
  GUIHeader,
  TranslateRules,
} from "./defs";

import { compileProps } from "./utils";
import EditorPageMixinFactory, { EditorPageMixin } from "../mixins/EditorPageMixin";

export const mappedLocales = {
  "zh-hk": "cht",
  "zh-cn": "chs",
  "en-us": "en",
  "en-hk": "en",
};

export * from "./defs";
declare const PRODUCTION_MODE: boolean;

expressions.filters.indexOf = (input, search) => {
  if (!input) return -1;
  return input.indexOf(search);
};

@Component
export class SchemaHelper extends Vue {
  _init: Promise<void>;
  $store: Store<any>;
  i18n: any;
  userId: string;
  appName: string;
  schemas: {
    [key: string]: SchemaDefJson;
  };
  pathToSchemas: {
    [key: string]: SchemaDefJson;
  } = {};
  routes: {
    [key: string]: EditorConfig;
  };
  allRoutes: {
    [key: string]: EditorConfig;
  };
  pathToEdit: Record<string, string> = {};
  routeList: EditorConfig[] = [];
  pageList: GUIHeader[] = [];
  components: {
    [key: string]: () => Promise<any>;
  } = {};
  customTypes: Record<string, CustomType> = {};
  importPreprocessors: Record<
    string,
    (
      context: ImportContext,
      parent: WorksheetReader,
    ) => WorksheetProcessor | Promise<WorksheetProcessor>
  > = {};
  loaded = false;
  hasSlideEditorHost = false;
  slidePanels: EditorPageMixin<any>[] = [];

  aclList: Record<string, AclHandler> = {};

  locale = "";

  get fontList() {
    return [{ family: "", variant: [] }];
  }

  get locales() {
    return Object.entries(this.$store.state.locales).map(([k, v]) => ({
      _id: mappedLocales[k] || k,
      name: (v as any).name,
    }));
  }

  get finalLocale() {
    return (
      this.locale ||
      this.$store.state.translateLocale ||
      mappedLocales[this.$store.state.locale] ||
      this.$store.state.locale
    );
  }

  defaultExport = true;
  defaultImport = true;
  defaultCreate = true;
  defaultPatch = true;
  defaultRemove = true;
  defaultClone = true;

  constructor(options) {
    super(options);
  }

  created() {
    this.registerCommon();
  }

  onLocale(locale?: string) {
    this.locale = mappedLocales[locale] || locale;
  }

  clearTypes() {
    this.customTypes = {};
    this.registerCommon();
  }

  registerCommon() {
    this.registerType("statusSwitch", {
      component: "editor-status-checkbox",
      colWidthRem: 5,
    });

    this.registerType("locale", {
      component: "editor-object-picker-list",
      props: {
        items: () => {
          const locales =
            (this as any).$store?.state?.translateLocales ||
            (this as any).$i18n ||
            (this as any).i18n?.locales ||
            {};

          return Object.entries(locales).map(([k, v]) => ({
            _id: (v as any).id || (v as any).code || k,
            name: (v as any).name,
          }));
        },
      },
      format: (self, v) => {
        if (v) {
          const locales =
            (this as any).$store?.state?.translateLocales ||
            (this as any).$i18n ||
            (this as any).i18n?.locales ||
            {};

          const locale = Object.entries(locales).find(
            ([k, it]) => ((it as any).id || (it as any).code || k) === v,
          );
          if (locale) return self.$td((locale[1] as any).name);
          return v;
        } else {
          return "-";
        }
      },
    });

    this.registerType("base64", {
      component: "editor-file-picker",
      props: {
        type: "base64",
      },
    });

    this.registerType("itemList", {
      component: "editor-object-picker-list",
    });
  }

  normalizeType(type: CustomType) {
    type = _.cloneDeep(type);
    if (typeof type.component === "function") {
      let promise: Promise<any>;
      const func = type.component;
      Object.defineProperty(type, "component", {
        get() {
          if (this._component) return this._component;
          else if (!promise) {
            promise = (async () => {
              console.log("begin load component");
              const ret = await func();
              this._component = ret.default || ret;
            })();
          }
          return VProgressCircular;
        },
      });
      (<any>Vue).util.defineReactive(type, "_component");
    }
    if (typeof type.searchComponent === "function") {
      let promise: Promise<any>;
      const func = type.searchComponent;
      Object.defineProperty(type, "searchComponent", {
        get() {
          if (this._searchComponent) return this._searchComponent;
          else if (!promise) {
            promise = (async () => {
              console.log("begin load searchComponent");
              const ret = await func();
              this._searchComponent = ret.default || ret;
            })();
          }
          return VProgressCircular;
        },
      });
      (<any>Vue).util.defineReactive(type, "_searchComponent");
    }
    return type;
  }

  registerType(name: string, type: CustomType) {
    this.customTypes[name] = this.normalizeType(type);
  }

  mergeType(name: string, type: CustomType) {
    this.customTypes[name] = Object.assign(this.customTypes[name] || {}, this.normalizeType(type));
  }

  registerAcl(name: string, acl: AclHandler) {
    this.aclList[name] = acl;
  }

  registerImportPreprocess(
    name: string,
    cb: (
      context: ImportContext,
      parent: WorksheetReader,
    ) => WorksheetProcessor | Promise<WorksheetProcessor>,
  ) {
    this.importPreprocessors[name] = cb;
  }

  hooks: Record<string, ((...args: any[]) => any | Promise<any>)[]> = {};

  registerHook(name: string, func: (...args: any[]) => any | Promise<any>) {
    let list = this.hooks[name];
    if (!list) {
      list = this.hooks[name] = [];
    }
    list.push(func);
  }

  async callHook(name: string, ...args: any[]) {
    const list = this.hooks[name];
    if (!list) return;
    let ret: any;
    for (let item of list) {
      ret = (await item(...args)) ?? ret;
    }
    return ret;
  }

  init(force?: boolean) {
    const userId = this.$store.getters.userId;
    if (userId !== this.userId || force) {
      this.userId = userId;
      this._init = null;
    }
    if (this._init) {
      return this._init;
    }
    const initInner = this.initCore();
    this._init = initInner;
    initInner.catch(() => {
      this._init = null;
    });
    return this._init;
  }

  async initCore() {
    let configs = await (<MApplication<SchemaApplication>>(<any>this).$feathers)
      .service("schemas")
      .find({});
    // Workaround for offline data
    if (Array.isArray(configs)) {
      configs = configs[0];
    }
    this.schemas = configs.schemas || {};
    this.appName = configs.appName || "";

    this.routes = {};
    this.allRoutes = {};
    this.pathToEdit = {};
    this.routeList = [];
    this.cachedApiRoute = {};

    const routeCreateList: {
      cfg: DBEditorConfig;
      serviceCfg: SchemaDefParamsService;
      item: SchemaDefJson;
    }[] = [];

    for (let [name, item] of Object.entries(this.schemas)) {
      if (!item.params || !item.params.services?.[this.appName]) continue;
      const serviceCfg = item.params.services?.[this.appName];
      const extraCfgs = item.params.extraServices?.[this.appName];

      if (item.params.editor) {
        const cfgs: DBEditorConfig[] = Array.isArray(item.params.editor)
          ? item.params.editor
          : typeof item.params.editor === "object"
          ? [item.params.editor]
          : [{}];

        for (let cfg of cfgs) {
          routeCreateList.push({
            cfg,
            serviceCfg,
            item,
          });
          this.pathToEdit[serviceCfg.path] = this.resolveRootPath(cfg, serviceCfg);
        }
      }
      this.pathToSchemas[serviceCfg.path] = item;
      if (extraCfgs) {
        for (let cfg of extraCfgs) {
          this.pathToSchemas[cfg.path] = item;
        }
      }
    }

    for (let { cfg, serviceCfg, item } of routeCreateList) {
      const route = this.convertSchema(serviceCfg, item, cfg);
      this.routes[route.rootPath] = route;
      this.allRoutes[route.rootPath] = route;
      this.routeList.push(route);
    }

    this.updatePageList();
    this.loaded = true;
  }

  resolveRootPath(cfg: DBEditorConfig, serviceCfg: SchemaDefParamsService) {
    return (
      "/" + (cfg.rootPath || cfg.path || serviceCfg.path) + (cfg.trailingSlash ?? true ? "/" : "")
    );
  }

  _stringIds: Set<string>;

  useStringId(id: string) {
    if (!PRODUCTION_MODE) {
      if (!this._stringIds) {
        this._stringIds = new Set();
      }
      if (!this._stringIds.has(id)) {
        this._stringIds.add(id);
        if (this.i18n && this.i18n.t && this.i18n.t(id) === id) {
          if (typeof localStorage !== "undefined" && localStorage["collectTrace"]) {
            try {
              throw new Error(id);
            } catch (e) {
              if (this.i18n && this.i18n.t && this.i18n.t(id) === id) {
                console.warn(id, e);
              }
            }
          }
        }
      }
    }
    return id;
  }

  // Lookup route even editor is false
  lookupRoute(route: string) {
    if (!route.startsWith("/")) {
      route = "/" + route;
    }
    let item = this.allRoutes[route];
    if (item !== undefined) return item;
    const schema = this.pathToSchemas[route.substring(1)];
    if (schema) {
      const serviceCfg = schema.params.services?.[this.appName];
      const cfgs: DBEditorConfig[] = Array.isArray(schema.params.editor)
        ? schema.params.editor
        : typeof schema.params.editor === "object"
        ? [schema.params.editor]
        : [{}];

      for (let cfg of cfgs) {
        const route = this.convertSchema(serviceCfg, schema, cfg);
        this.allRoutes[route.rootPath] = item = route;
      }
    } else {
      this.allRoutes[route] = item = null;
    }
    return item;
  }

  convertTempSchema(path: string, schema: SchemaDefJson, cfg: DBEditorConfig = {}) {
    return this.convertSchema(
      {
        paginate: false,
        path,
      },
      schema,
      cfg,
    );
  }

  getRefTable(field: SchemaFieldJson) {
    const ref = field.params?.ref;
    const refTable = ref && this.schemas?.[ref];
    return refTable;
  }

  getRefPath(field: SchemaFieldJson) {
    const ref = field.params?.ref;
    const refTable = this.getRefTable(field);
    if (refTable) {
      const cfg = refTable.params?.services?.[this.appName];
      const path = cfg?.path || ref.substring(0, 1).toLowerCase() + ref.substring(1) + "s";
      return path;
    }
    return null;
  }

  getEditorPath(path: string) {
    return this.pathToEdit[path];
  }

  convertSchema(
    serviceCfg: SchemaDefParamsService,
    item: SchemaDefJson,
    cfg: DBEditorConfig,
    selector?: string,
    replaceRoot?: string,
  ): EditorConfig {
    return new EditorConfig(this, serviceCfg, item, cfg, selector, replaceRoot);
  }

  addComponents(context: __WebpackModuleApi.RequireContext) {
    context.keys().forEach((key) => {
      const originKey = key;
      key.startsWith("./") && (key = key.substr(2));
      key.indexOf(".") !== -1 && (key = key.substr(0, key.lastIndexOf(".")));

      this.components[key] = () => context(originKey);
    });
  }

  hasRole(role: string) {
    const user = this.$store.state.user || {};
    if (user.role) {
      if (typeof user.role === "string" && user.role === role) return true;
      else if (Array.isArray(user.role) && user.role.indexOf(role) !== -1) return true;
    }
    if (user.roles) {
      if (typeof user.roles === "string" && user.roles === role) return true;
      else if (Array.isArray(user.roles) && user.roles.indexOf(role) !== -1) return true;
    }
    if (user.scopes) {
      if (typeof user.scopes === "string" && user.scopes === role) return true;
      else if (Array.isArray(user.scopes) && user.scopes.indexOf(role) !== -1) return true;
    }
    return false;
  }

  updatePageList() {
    const rootMenu: GUIHeader[] = [];
    const allMenus: GUIHeader[] = [];

    for (let route of this.routeList) {
      if (!route.menu) continue;
      if (route.roles) {
        const role = route.roles.find((it) => this.hasRole(it.role));
        if (!role) continue;
      }
      const gp = (route.group || "").split(".").filter((it) => !!it);
      let curList: GUIHeader[] = rootMenu;
      const gpPath = [];
      for (let gpKey of gp) {
        gpPath.push(gpKey);
        let gpItem = curList.find((it) => it.gpKey === gpKey);
        if (!gpItem) {
          curList.push(
            (gpItem = {
              title: "pages.groups." + gpPath.join("_"),
              action: "",
              href: "",
              items: [],
              gpKey: gpKey,
              gpIcon: route.groupIcon,
              order: 0,
            }),
          );
          allMenus.push(gpItem);
        }
        curList = gpItem.items;
      }
      const item: GUIHeader = {
        title: route.name,
        action: route.icon,
        href: route.rootPath,
        order: route.order,
      };
      curList.push(item);
      allMenus.push(item);
    }

    allMenus.forEach((item) => {
      if (item.items) {
        item.order = item.items[0].order;
      }
    });

    allMenus.forEach((item) => {
      if (item.items) {
        item.items = _.sortBy(item.items, (it) => it.order);
      }
    });

    allMenus.reverse().forEach((item) => {
      if (item.items) {
        item.action = item.gpIcon || item.items[0].action;
      }
    });

    this.pageList = rootMenu;
  }

  sortDeep(fields: EditorField[]) {
    for (let f of fields) {
      if (f.component === "editor-list") {
        if (f.inner) {
          f.inner = this.sortDeep(this.sortFields(f.inner));
        }
      } else {
        if (f.inner) {
          this.sortDeep(f.inner);
        }
        if (f.default) {
          this.sortDeep(f.default);
        }
      }
    }
    return fields;
  }

  readOnlyDeep(fields: EditorField[]) {
    return fields.map((f) => {
      f = { ...f };
      if (f.inner) {
        f.inner = this.readOnlyDeep(f.inner);
      }
      if (f.default) {
        f.default = this.readOnlyDeep(f.default);
      }
      f.props = { ...f.props };
      f.props.readonly = true;
      f.props.clearable = false;
      return f;
    });
  }

  sortFields(fields: EditorField[], groupFields = true) {
    fields = fields.filter((it) => !it.optional);

    const unsorted = fields.filter((it) => !it.sort);
    let sorted = fields.filter((it) => !!it.sort);

    while (sorted.length) {
      let updated = false;
      const remains: typeof sorted = [];
      for (let field of sorted) {
        let inserted = false;
        for (let part of field.sort.split("|")) {
          const sign = part[0];
          const remain = part.slice(1);
          const r = fields.findIndex((it) => it.path === remain);
          if (r === -1) continue;

          if (sign === "<") {
            unsorted.splice(r, 0, field);
          } else {
            unsorted.splice(r + 1, 0, field);
          }
          inserted = true;
          updated = true;
          break;
        }
        if (!inserted) {
          remains.push(field);
          continue;
        }
      }
      sorted = remains;
      if (!updated && sorted.length) {
        unsorted.push(...remains);
        break;
      }
    }

    if (groupFields) {
      const gpDict: Record<string, EditorField> = {};
      const rootGroups: EditorField[] = [];

      let hasGpDef = false;
      let hasNonGpDef = false;

      for (let item of unsorted) {
        if (item.gp === undefined) {
          hasNonGpDef = true;
        } else {
          hasGpDef = true;
        }
      }

      for (let item of unsorted.slice()) {
        if (item.component === "editor-group") continue;
        if (!item._inner && item.inner && !item.innerPrimitive) {
          item._inner = item.inner;
          item.inner = this.sortFields(item.inner, true);
        }
        if (!item._default && item.default) {
          item._default = item.default;
          item.default = this.sortFields(item.default, true);
        }
        if (!item.gp) {
          if (item.component === "editor-list") {
            rootGroups.push(item);
            continue;
          }
          if (hasGpDef) {
            // add an advanced group
            item.gp = "";
          } else {
            // add an empty group
            item.gp = "adv";
          }
        }
        if (!gpDict[item.gp]) {
          const idx = unsorted.indexOf(item);
          // first item of gp
          const gpParts = item.gp.split(".");
          if (gpParts.length > 1) {
            let parent: EditorField;
            for (let i = 0; i < gpParts.length; i++) {
              const curPath = gpParts.slice(0, i + 1).join(".");
              let curDict = gpDict[curPath];
              if (!curDict) {
                curDict = gpDict[curPath] = {
                  component: "editor-group",
                  props: {},
                  default: [],
                  path: curPath,
                } as EditorField;
                if (i === 0) {
                  if (item.gp === "") {
                    rootGroups.unshift(curDict);
                  } else {
                    rootGroups.push(curDict);
                  }
                }
                if (parent) {
                  parent.default.push(curDict);
                }
              }
              if (i !== gpParts.length - 1) {
                if (!curDict.group) curDict.group = {};
                curDict.group.hasInnerGroup = true;
              }
              parent = curDict;
            }
          } else {
            const curDict = (gpDict[item.gp] = {
              component: "editor-group",
              props: {},
              default: [],
              path: item.gp,
            } as EditorField);
            if (item.gp === "") {
              rootGroups.unshift(curDict);
            } else {
              rootGroups.push(curDict);
            }
          }
        }
        gpDict[item.gp].default.push(item);
      }

      for (let [gpName, gp] of Object.entries(gpDict)) {
        const gpConfig: EditorGroupOptions = _.merge(
          {},
          ..._.map(gp.default, (p) => p.group),
          gp.group,
        );
        if (gpConfig.name) {
          gp.name = this.useStringId(gpConfig.name + (gpConfig.hasInnerGroup ? ".$" : ""));
          gp.nameField = "label";
        }
        if (gpConfig.props) {
          Object.assign(gp.props, gpConfig.props);
        }
        const props = gp.props;
        gp.props = {};
        compileProps(gp, props);

        gp.displayPath = "groups." + gpName;
        gp.inner = gp.default.filter((it) => it.group?.preview);
        // gp.default = gp.default.filter((it) => !it.group?.preview);
        if (gp.inner.length) {
          gp.props.hasPreview = true;
        }
      }
      return rootGroups;
    }

    return unsorted;
  }

  normalizedRoles(roles: RoleDef | RoleDef[]) {
    if (!roles) return null;
    const rolesList = Array.isArray(roles) ? roles : [roles];
    return rolesList.map((role) => {
      if (typeof role === "string") {
        return {
          role,
          read: true,
          write: true,
        };
      } else {
        return {
          role: role.role,
          read: role.read ?? true,
          write: role.write ?? true,
        };
      }
    });
  }

  cachedApiRoute: Record<string, EditorConfig> = {};

  getConfigByApiPath(path: keyof CurrentApp, selector?: string, replaceRoot?: string) {
    if (!this.loaded) return null;
    const taggedPath = `${String(path)}${selector ? `#${selector}` : ""}${
      replaceRoot ? `@${replaceRoot}` : ""
    }`;

    let resp = this.cachedApiRoute[taggedPath];
    if (resp) return resp;
    const item = this.pathToSchemas[String(path)];
    if (!item) return null;
    const cfg = item.params.services?.[this.appName];
    const cfgs: DBEditorConfig[] = Array.isArray(item.params.editor)
      ? item.params.editor
      : typeof item.params.editor === "object"
      ? [item.params.editor]
      : [{}];

    const route = this.convertSchema(cfg, item, cfgs[0], selector, replaceRoot);
    return (this.cachedApiRoute[taggedPath] = route);
  }

  translateRules: TranslateRules;

  setTranslateRule(rules: TranslateRules) {
    this.translateRules = rules;
  }

  appendTranslateRule(rules: TranslateRules) {
    if (!this.translateRules) {
      this.translateRules = rules;
    } else {
      _.merge(this.translateRules, rules);
    }
  }

  matchTranslateRule(tp: string, name: string): string {
    if (!this.translateRules) return null;
    if (tp.startsWith("pages.")) tp = tp.substring(6);
    if (tp.endsWith(".")) tp = tp.slice(0, -1);
    const local = _.get(this.translateRules, tp)?.[name];
    if (local) return local;

    const g = this.translateRules["common"];
    if (g && g.includes(name)) return "common." + name;

    return null;
  }

  async createAndEdit<TKey extends keyof CurrentApp>(self: Vue, path: string, data: any) {
    if (this.hasSlideEditorHost) {
      const panel = new (EditorPageMixinFactory<TKey>({
        path: path as any,
      }))({
        parent: self,
        propsData: {
          initItem: data,
          nested: true,
        },
      });
      panel.savePromise = new Promise((resolve) => {
        panel.savePromiseFunc = resolve;
      });
      await panel.initTask;
      this.slidePanels.push(panel);
      return panel.savePromise;
    } else {
      // TODO
      throw new Error("TODO");
    }
  }

  async reportEditResult(panel: EditorPageMixin<any>, save = true) {
    if (save) {
      await panel.save();
    }
    const idx = this.slidePanels.indexOf(panel);
    if (idx !== -1) {
      this.slidePanels.splice(idx, 1);
    }
    if (panel.savePromiseFunc) {
      panel.savePromiseFunc(save ? panel.item : null);
    }
  }

  resolveFontCss(style) {
    let variant = "";

    if (style.variant.includes("italic")) {
      variant += "italic";
      style.variant.replace("italic", "");
    }

    switch (style.variant) {
      case "100":
        variant += " 100";
        break;
      case "200":
        variant += " 200";
        break;
      case "300":
        variant += " 300";
        break;
      case "500":
        variant += " 500";
        break;
      case "600":
        variant += " 600";
        break;
      case "700":
        variant += " 700";
        break;
      case "800":
        variant += " 800";
        break;
      case "900":
        variant += " 900";
        break;
      case "1000":
        variant += " 1000";
        break;
      case "regular":
      case "400":
      default:
        variant += " 400";
        break;
    }

    return `${variant} ${style.size}px/${style.lineHeight}px ${style.family}`;
  }
}

export default helper("schemas", (ctx: Context) => {
  const helper = new SchemaHelper(getOptions(ctx.app));
  process.nextTick(() => (helper.i18n = ctx.app.i18n));
  return helper;
});

declare module "vue/types/vue" {
  export interface Vue {
    $schemas?: SchemaHelper;
  }
}

declare module "@nuxt/types" {
  export interface NuxtAppOptions {
    $schemas?: SchemaHelper;
  }
}
