import EditorObjectPickerNew from "./EditorObjectPickerNew.vue";
import EditorObjectPickerList from "./EditorObjectPickerList.vue";
import EditorFilePicker from "./EditorFilePicker.vue";
import EditorTranslateBox from "./EditorTranslateBox.vue";
import EditorDateObject from "./EditorDateObject.vue";
import EditorDatePicker from "./EditorDatePicker.vue";
import EditorDatePickerPreset from "./EditorDatePickerPreset.vue";
import EditorUploader from "./EditorUploader.vue";
import EditorGroup from "./EditorGroup.vue";
import EditorGroupObject from "./EditorGroupObject.vue";
import EditorList from "./EditorList.vue";
import EditorMulti from "./EditorMulti.vue";
import EditorTextField from "./EditorTextField.vue";
import EditorCheckbox from "./EditorCheckbox.vue";
import EditorStatusCheckbox from "./EditorStatusCheckbox.vue";
import EditorGroupPreview from "./EditorGroupPreview";
import EditorCombobox from "./EditorCombobox.vue";

export default {
  EditorObjectPickerNew,
  EditorObjectPickerList,
  EditorFilePicker,
  EditorTranslateBox,
  EditorDateObject,
  EditorDatePicker,
  EditorDatePickerPreset,
  EditorUploader,
  EditorGroup,
  EditorGroupObject,
  EditorList,
  EditorMulti,
  EditorTextField,
  EditorCheckbox,
  EditorStatusCheckbox,
  EditorGroupPreview,
  EditorCombobox,
}