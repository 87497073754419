
import { Vue, Component, mixins, Prop } from "@feathers-client";
import { Order } from "@common/pos";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import moment from "moment";
import { FindType } from "@feathers-client";
import { PropSync } from "nuxt-property-decorator";

@Component({
  components: {
    EditorTextField,
  },
})
export default class CashierIdleDialog extends Vue {
  @Prop()
  session: Order;

  @Prop(Boolean)
  payLoading: boolean;

  posIdle = false;
  bannerIndex = 0;

  banners: FindType<"shop/pos/banners">[] = [];

  get hasOrder() {
    return (
      !this.payLoading &&
      (this.$pos.cashier.kioskIdleScreen
        ? !this.posIdle
        : this.session?._id || !this.session?.cartEmpty || this.session?.userId)
    );
  }

  get kioskIdleTimeout() {
    return (this.$pos.cashier.kioskIdleTimeout || 60) * 1000;
  }

  get kioskClearTimeout() {
    return this.kioskIdleTimeout + 10 * 1000;
  }

  kioskReset() {
    this.$emit("reset");
    if (this.$pos.cashier.kioskIdleScreen) {
      this.posIdle = true;
    }
  }

  kioskStart() {
    this.$emit("reset");
    this.posIdle = false;
  }

  async mounted() {
    this.banners = await this.$feathers.service("shop/pos/banners").find({
      query: {
        $paginate: false,
        publish: {
          $lt: moment().toDate(),
        },
        expiry: {
          $gt: moment().toDate(),
        },
        type: "kiosk",
        status: "published",
        $sort: {
          publish: 1,
        },
      },
      paginate: false,
    });
    if (this.$pos.cashier.kioskIdleScreen) {
      this.posIdle = true;
    }
  }
}
