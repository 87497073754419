
import Dialog from "@feathers-client/mixins/Dialog";
import { Component, mixins, Prop, FindType } from "@feathers-client";
import MenuListNum from "@feathers-client/components/MenuListNum.vue";
import MenuListButton from "@feathers-client/components/MenuListButton.vue";

@Component({
  components: {
    MenuListNum,
    MenuListButton,
  }
})
export default class EditCartDiscount extends mixins(Dialog) {

  @Prop()
  cartElement: {
    sku: Record<string, any>,
    quantity: number,
    finalPriceInt: number,
    originalPriceInt: number,
    discountRate: number,
    discountAmt: number
  };

  get returnObject() {
    return {
      realDiscRate: (this.discountMethod === 'rate' ? this.discountRate : 1),
      discAmtInt: this.discountAmt * 10000
    }
  }

  discountMethod: "rate" | "amount" = "rate"

  discountRate = 0
  // @Watch('discountRate')
  // onDiscountRate(){
  //   console.log('dr', this.discountRate)
  // }
  discountAmt = 0

  async beforeMount() {
    if (this.cartElement.discountAmt !== 0) {
      const cartTotalInt = this.cartElement.finalPriceInt * this.cartElement.quantity
      this.discountRate = Math.round((cartTotalInt - this.cartElement.discountAmt) / cartTotalInt * 100) / 100
    } else {
      this.discountRate = this.cartElement.discountRate
    }
    this.discountAmt = this.cartElement.discountAmt / 10000
  }

  changeDiscountRate(newDiscRate) {
    const roundedRate = Math.round(newDiscRate * 100) / 100
    // const realRate = roundedRate / 100;
    // this.cloneCartEl.discountRate = realRate;
    this.discountRate = roundedRate
    this.discountAmt = Math.round((this.cartElement.finalPriceInt * this.cartElement.quantity * (1 - roundedRate) / 10000) * 100) / 100
  }

  changeDiscountAmt(newDiscAmt) {
    const roundedAmt = Math.round(newDiscAmt * 100) / 100
    const discAmtInt = roundedAmt * 10000
    const cartTotalInt = this.cartElement.finalPriceInt * this.cartElement.quantity
    if (discAmtInt > cartTotalInt) {
      this.discountAmt = cartTotalInt / 10000
    } else {
      this.discountAmt = roundedAmt
    }
    // this.cartElement.discountRate = 1
    // this.discountRate = Math.round(((cartTotalInt - this.discountAmt * 10000) / cartTotalInt) * 100 * 100) / 100
  }


}
