import feathers from '@feathersjs/feathers';
import fio from '@feathersjs/socketio-client';
import SocketIO from 'socket.io-client'
import _ from 'lodash'
import { BOXS_CLOUD_URL } from '../ports/cloud'

export type CloudApp = feathers.Application<any> & {
    waitConnected(): Promise<void>
}

export let connected = false;

let promise: Promise<CloudApp>;

export function init() {
    return (promise || (promise = initCore()))
}

async function initCore() {
    const u = new URL("/", BOXS_CLOUD_URL).toString();
    console.log(">>>>>CLOUD API URL >>>>>>", u);
    let socket = SocketIO.connect(u, { path: '/api/socket.io', transports: ['websocket'] });

    socket.on('connect', function() {
        console.log('Socket connected');
        connected = true;
        app.emit('connected');
    })
    
    socket.on('reconnect', function() {
        if(global.isIOS) {
            console.log('Socket disconnected');
            connected = false;
        }
    })
    
    socket.on('disconnect', function() {
        console.log('Socket disconnected');
        connected = false;
    })

    const app = feathers()
        .configure(fio(<any>socket, { timeout: 30000 }))

    const mapp = app as any;

    mapp.waitConnected = function() {
        return new Promise<void>(resolve => {
            if(connected) {
                resolve();
            } else {
                app.once('connected', resolve)
            }
        });
    }

    return mapp;
}