import uuid from "uuid/v4";

const cloudAuthKeyName = process.env.CLOUD_AUTH_KEY_NAME || "posKeys";

const keyType = {
  name: "ECDSA",
  namedCurve: "P-256",
} as const;

function binToUrlBase64(bin: Buffer | ArrayBuffer | string) {
  return Buffer.from(bin as any)
    .toString("base64")
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+/g, "");
}

let initPromise: Promise<void> = null;
export function init() {
  return initPromise || (initPromise = initCore());
}

export let publicKey: CryptoKey;
export let privkey: CryptoKey;
export let keyHash: string;
export let jwk: JsonWebKey;

export async function sign(headers: any = {}, claims: any = {}, time = Date.now(), key?: CryptoKey | string) {
  let curJwk: JsonWebKey;
  let curKeyHash: string;
  if (typeof key === "string" || !key) {
    const keyObj = await getKey(key as string);
    key = keyObj.privkey;
    curJwk = keyObj.jwk;
    curKeyHash = keyObj.keyHash;
  } else if(key) {
    curJwk = await crypto.subtle.exportKey("jwk", key);
    const sortedPub = '{"crv":"CRV","kty":"EC","x":"X","y":"Y"}'
      .replace("CRV", curJwk.crv)
      .replace("X", curJwk.x)
      .replace("Y", curJwk.y);
    const hash = await crypto.subtle.digest({ name: "SHA-256" }, Buffer.from(sortedPub));
    curKeyHash = Buffer.from(hash).toString("base64");
  }
  headers.typ = "JWT";
  headers.alg = "ES256";
  headers.jwk = curJwk;
  claims.iss = window.location.hostname;
  claims.exp = ((time / 1000) | 0) + 15 * 60;
  claims.jti = uuid();
  claims.sub = curKeyHash;
  headers.kid = curKeyHash;
  var jws = {
    // JWT "headers" really means JWS "protected headers"
    protected: binToUrlBase64(JSON.stringify(headers)),

    // JWT "claims" are really a JSON-defined JWS "payload"
    payload: binToUrlBase64(JSON.stringify(claims)),
    signature: null,
  };

  var data = Buffer.from(jws.protected + "." + jws.payload);

  const signature = await crypto.subtle.sign({ name: "ECDSA", hash: { name: "SHA-256" } }, key, data);
  jws.signature = binToUrlBase64(signature);

  return jws.protected + "." + jws.payload + "." + jws.signature;
}

export async function signBuf(data: Buffer, key?: CryptoKey | string) {
  if (typeof key === "string" || !key) {
    const keyObj = await getKey(key as string);
    key = keyObj.privkey;
  }
  const signature = await crypto.subtle.sign({ name: "ECDSA", hash: { name: "SHA-256" } }, key, data);
  return Buffer.from(signature);
}

async function workaroundSafariBug() {
  const isSafari = /(Safari|AppleWebKit)\//.test(navigator.userAgent) && !/Chrom(e|ium)\//.test(navigator.userAgent);

  // No point putting other browsers through this mess.
  if (!isSafari || !(indexedDB as any).databases) return;

  let intervalId;

  await new Promise((resolve, reject) => {
    const tryIdb = () => (indexedDB as any).databases().then(resolve, reject);
    intervalId = setInterval(tryIdb, 100);
    try {
      tryIdb();
    } catch (e) {
      reject(e);
    }
  });

  clearInterval(intervalId);
}

export interface KeyCache {
  keyHash: string;
  publicKey: CryptoKey;
  privkey: CryptoKey;
  jwk: JsonWebKey;
  shortId: string;
}

let db: IDBDatabase;
const keys: Record<string, Promise<KeyCache>> = {};

export async function getKey(key: string = "deviceKey", create = true) {
  await initDB();
  let task = keys[key];
  if (!task) {
    task = getKeyInner(key, create);
    keys[key] = task;
    if (!create) {
      task.then(result => {
        if (!result) {
          delete keys[key];
        }
      });
    }
  }
  return await task;
}

interface DbKey {
  id: string;
  publicKey: CryptoKey;
  privateKey: CryptoKey;
  spki: ArrayBuffer;
}

async function getKeyInner(key: string, create: boolean): Promise<KeyCache> {
  let currentKey = await new Promise<DbKey>((resolve, reject) => {
    const transaction = db.transaction(cloudAuthKeyName, "readonly");
    const objectStore = transaction.objectStore(cloudAuthKeyName);
    const request = objectStore.get(key);
    request.onerror = function (evt) {
      reject(this.error);
    };
    request.onsuccess = function (evt) {
      resolve(this.result);
    };
  });
  if (!currentKey && create) {
    const genkey = (await crypto.subtle.generateKey(keyType, false, ["sign", "verify"])) as CryptoKeyPair;
    const spki = await crypto.subtle.exportKey("spki", genkey.publicKey);

    currentKey = await new Promise<DbKey>((resolve, reject) => {
      const savedObject: DbKey = {
        publicKey: genkey.publicKey,
        privateKey: genkey.privateKey,
        id: key,
        spki: spki,
      };
      const transaction = db.transaction(cloudAuthKeyName, "readwrite");
      transaction.onerror = function (evt) {
        reject(this.error);
      };
      transaction.onabort = function (evt) {
        reject(this.error);
      };
      transaction.oncomplete = function (evt) {
        resolve(savedObject);
      };

      const objectStore = transaction.objectStore(cloudAuthKeyName);
      objectStore.add(savedObject, key);
    });
  }

  if (!currentKey) return null;

  const publicKey = currentKey.publicKey;
  const privkey = currentKey.privateKey;
  const jwk = await crypto.subtle.exportKey("jwk", publicKey);

  var sortedPub = '{"crv":"CRV","kty":"EC","x":"X","y":"Y"}'
    .replace("CRV", jwk.crv)
    .replace("X", jwk.x)
    .replace("Y", jwk.y);

  const hash = await crypto.subtle.digest({ name: "SHA-256" }, Buffer.from(sortedPub));
  const keyHash = Buffer.from(hash).toString("base64");

  return {
    keyHash,
    publicKey,
    privkey,
    jwk,
    shortId: keyHash.slice(0, 8),
  };
}

let initDBPromise: Promise<void> = null;
export function initDB() {
  return initDBPromise || (initDBPromise = initDBInner());
}

async function initDBInner() {
  await workaroundSafariBug();
  db = await new Promise<IDBDatabase>((resolve, reject) => {
    var req = indexedDB.open(cloudAuthKeyName, 2);
    req.onsuccess = function (evt) {
      resolve(this.result);
    };
    req.onerror = function (evt) {
      reject(this.error);
    };
    req.onblocked = function (evt) {
      reject(new Error("Database already open."));
    };
    req.onupgradeneeded = function (evt) {
      const db = this.result;
      if (!db.objectStoreNames.contains(cloudAuthKeyName)) {
        db.createObjectStore(cloudAuthKeyName);
      }
    };
  });
}

export async function initCore() {
  await initDB();

  const key = await getKey();
  if (key) {
    publicKey = key.publicKey;
    privkey = key.privkey;
    keyHash = key.keyHash;
    jwk = key.jwk;
  }
}
