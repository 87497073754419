
import { Component, Vue, Prop, Watch, Ref } from "@feathers-client";
import { LangArrType } from "@feathers-client/i18n";
import { defineComponent } from "site-editor/def";
import NestedRouter from "@feathers-client/components/NestedRouter.vue";
// @ts-ignore
import WorldSvg from "!vue-svg-loader!~/dep/site-editor/assets/header/world.svg";
// @ts-ignore
import MenuSvg from "!vue-svg-loader!~/dep/site-editor/assets/header/menu2.svg";
// @ts-ignore
import CartSvg from "!vue-svg-loader!~/dep/site-editor/assets/header/cart.svg";
// @ts-ignore
import SearchSvg from "!vue-svg-loader!~/dep/site-editor/assets/header/search.svg";
// @ts-ignore
import UserSvg from "!vue-svg-loader!~/dep/site-editor/assets/header/user.svg";
// @ts-ignore
import CloseSvg from "!vue-svg-loader!~/dep/site-editor/assets/header/close.svg";
// @ts-ignore
import LarrowSvg from "!vue-svg-loader!~/dep/site-editor/assets/header/larrow.svg";
// @ts-ignore
import RarrowSvg from "!vue-svg-loader!~/dep/site-editor/assets/rarrow.svg";
import ResizeSensor from "@feathers-client/components/ResizeSensor.vue";

export const componentInfo = defineComponent({
  group: "header",
  groupProps: {
    editorCols: 2,
  },
  variants: [
    {
      key: "header",
    },
  ],
  hideConfirm: true,
});

@Component({
  components: {
    NestedRouter,
    ResizeSensor,
    UserSvg,
    CartSvg,
    SearchSvg,
    WorldSvg,
    MenuSvg,
    RarrowSvg,
    LarrowSvg,
    CloseSvg,
  },
})
export default class SiteEditorHeader extends Vue {
  @Prop()
  variant: string;

  @Prop()
  logo: any;

  @Prop()
  bgColor: string;

  @Prop()
  fontColor: string;

  @Prop()
  transparent: boolean;

  @Prop()
  menus: SiteEditorHeaderMenu[];

  @Prop()
  title: LangArrType;

  @Prop()
  loginDialog: () => void;

  @Prop()
  cartMenu: () => void;

  @Prop({ type: Boolean, default: false })
  informativeSite: boolean;

  @Ref()
  worldIcon: HTMLDivElement;

  @Ref()
  mobileWorldIcon: HTMLDivElement;

  @Ref()
  router: any;

  searchText = null;

  drawer: boolean = false;

  mobilePanel = [];

  hoveredMenu: any = null;

  selectedMobileMenu: SiteEditorHeaderMenu = null;

  selectedMobileSubMenu: SiteEditorSubHeaderMenu = null;

  hovered = false;

  @Ref()
  desktopMenu: HTMLDivElement;

  @Ref()
  desktopLogo: HTMLImageElement;

  @Ref()
  desktopAction: HTMLDivElement;

  @Ref()
  desktopMenuContainer: HTMLDivElement;

  @Ref()
  desktopContainer: HTMLDivElement;

  @Ref()
  desktopSubContainer: HTMLDivElement;

  @Ref()
  mobileContainer: HTMLDivElement;

  get showBgColor() {
    return !this.pageTransparent || this.isTop;
  }

  isTop = true;

  langMenu = false;

  mobileSearch = false;

  get pageTransparent() {
    if (this.$store.state.headerStyle.transparent != null) return this.$store.state.headerStyle.transparent;
    else return this.transparent;
  }

  get pageBgColor() {
    return this.$store.state.headerStyle.bgColor || this.bgColor;
  }

  get pageFontColor() {
    return this.$store.state.headerStyle.fontColor || this.fontColor;
  }

  get localeName() {
    return (this.$i18n as any).locales.find(v => v.code === this.$i18n.locale).name;
  }

  mounted() {
    this.isTop = document.documentElement.scrollTop > 0;
    document.addEventListener("scroll", this.onScroll, {
      passive: false,
    });

    this.$router.afterEach(() => {
      this.handleResize();
    });
  }

  @Watch("localeName")
  handleResize() {
    this.desktopContainer.classList.remove("!hidden");
    const subContainerChildWidth = this.desktopMenu.clientWidth + this.desktopAction.clientWidth;
    if (subContainerChildWidth > this.desktopSubContainer.clientWidth) {
      this.desktopMenu.classList.add("!justify-start");
    } else {
      this.desktopMenu.classList.remove("!justify-start");
    }
    if (this.desktopMenu.clientWidth > this.desktopMenuContainer.clientWidth) {
      this.desktopContainer.classList.add("!hidden");
      this.mobileContainer.classList.remove("!hidden");
    } else {
      this.mobileContainer.classList.add("!hidden");
    }
  }

  beforeDestroy() {
    document.removeEventListener("scroll", this.onScroll);
  }

  onScroll() {
    this.isTop = document.documentElement.scrollTop > 0;
  }

  switchLocale(locale) {
    (this.$i18n as any).setLocale(locale);
  }

  goSearch() {
    this.$i18nReplace({
      name: "shop-all",
      query: {
        query: {
          currentFilter: {
            $keyword: this.searchText,
          },
        },
      },
      params: [""],
    });
  }

  getLinkProp(type: string, url: string) {
    if (!url) return { class: "cursor-default" };
    if (type === "external") return { href: url, target: "_blank", rel: "noopener noreferrer" };
    else return { to: this.$localePath(url) };
  }

  getLink(type: string, url: string, haveLink: boolean = true) {
    if (!url || !haveLink) return "div";
    if (type === "internal") {
      return "nuxt-link";
    } else {
      return "a";
    }
  }

  @Watch("drawer")
  closeMobileLang() {
    if (this.drawer) {
      document.documentElement.style.height = "100vh";
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.height = "auto";
      document.documentElement.style.overflow = "unset";
    }
    this.langMenu = false;
    this.mobileSearch = false;
  }

  @Watch("$route.fullPath")
  closeModel() {
    this.drawer = false;
    this.langMenu = false;
    this.mobileSearch = false;
  }
}

export interface SiteEditorHeaderMenu {
  _id?: string;
  productGroup?: string;
  title?: LangArrType;
  haveLink?: boolean;
  urlType?: string;
  url?: string;
  productName?: string;
  haveProductImage?: boolean;
  products?: SiteEditorSubHeaderProduct[];
  subMenus?: SiteEditorSubHeaderMenu[];
}

export interface SiteEditorSubHeaderProduct {
  id?: string;
  slug?: string;
  file?: any;
  title?: LangArrType;
  catergory?: LangArrType;
  urlType: string;
  url?: string;
}

export interface SiteEditorSubHeaderMenu {
  _id?: string;
  title?: LangArrType;
  haveLink?: boolean;
  urlType?: string;
  url?: string;
  items?: SiteEditorSubMenuItem[];
}

export interface SiteEditorSubMenuItem {
  _id?: string;
  title?: LangArrType;
  urlType?: string;
  url?: string;
  productName?: string;
}
