
import { Component, Vue, Prop, Watch } from "@feathers-client";
import { PageBlock } from "../def";
import { SiteEditorManager } from "..";
import EditorCheckbox from "@schemaEditor/EditorCheckbox.vue";
import EditorList from "@schemaEditor/EditorList.vue";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import EditorTranslateBox from "@schemaEditor/EditorTranslateBox.vue";
import RatioPicker from "../RatioPicker.vue";
import ColorPicker from "../ColorPicker.vue";
import UrlPicker from "../UrlPicker.vue";
// @ts-ignore
import RarrowSvg from "!vue-svg-loader!../assets/rarrow.svg";
import { LangArrType } from "@feathers-client/i18n";

@Component({
  components: {
    EditorCheckbox,
    EditorList,
    EditorTextField,
    EditorTranslateBox,
    ColorPicker,
    RatioPicker,
    UrlPicker,
    RarrowSvg,
  },
})
export default class SiteEditorHeader extends Vue {
  @Prop()
  block: PageBlock;

  @Prop()
  manager: SiteEditorManager;

  timePicker = false;

  created() {
    if (!this.props.mainButton) this.props.mainButton = { enabled: false, urlType: "internal", url: "", title: [] };
    if (!this.props.subButton) this.props.subButton = { enabled: false, urlType: "internal", url: "", title: [] };
    if (!this.props.translateFile) this.props.translateFile = [] as LangArrType;
  }

  get videoTypes() {
    return [
      { _id: "upload", name: { $t: this.manager.$t("componentGroups.video.upload") } },
      { _id: "url", name: { $t: this.manager.$t("componentGroups.video.url") } },
    ];
  }

  get stretchTypes() {
    return [
      { _id: "full", name: { $t: this.manager.$t("componentGroups.headerImage.stretchToFull") } },
      { _id: "normal", name: { $t: this.manager.$t("componentGroups.headerImage.stretchWithMargin") } },
    ];
  }

  get mediaTypes() {
    return [
      { _id: "image", name: { $t: this.manager.$t("componentGroups.headerImage.image") } },
      { _id: "video", name: { $t: this.manager.$t("componentGroups.headerImage.video") } },
    ];
  }

  get postionTypes() {
    return [
      { _id: "left", name: { $t: this.manager.$t("componentGroups.ribbon.byLeft") } },
      { _id: "right", name: { $t: this.manager.$t("componentGroups.ribbon.byRight") } },
    ];
  }

  get resizeMethodTypes() {
    return [
      { _id: "fixed", name: { $t: this.manager.$t("componentGroups.headerImage.fixed") } },
      { _id: "ratio", name: { $t: this.manager.$t("componentGroups.headerImage.ratio") } },
    ];
  }

  get props() {
    return this.manager.defineProps(this.block, {
      stretchType: "full",
      fixedHeight: "500",
      resizeMethod: "fixed",
      ratio: "16/9",
      type: "image",
      videoType: "upload",
      title: [] as LangArrType,
      content: [] as LangArrType,
      translateFile: [] as LangArrType,
      file: null,
      url: null,
      positionIndex: 5,
      position: "left",
      bgColor: "#FFFFFFFF",
      fgColor: "#000000FF",
      mainButton: { enabled: false, urlType: "internal", url: "", title: [] } as SiteEditorButton,
      subButton: { enabled: false, urlType: "internal", url: "", title: [] } as SiteEditorButton,
    });
  }
}

export interface SiteEditorButton {
  enabled?: boolean;
  urlType?: string;
  url?: string;
  fgColor?: string;
  bgColor?: string;
  title?: LangArrType;
}
