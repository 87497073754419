
import { Component, Vue, Prop } from "@feathers-client";
import { PageBlock } from "../def";
import { SiteEditorManager } from "..";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import EditorTranslateBox from "@schemaEditor/EditorTranslateBox.vue";
import { LangArrType } from "@feathers-client/i18n";
import ColorPicker from "../ColorPicker.vue";

@Component({
  components: {
    EditorTextField,
    EditorTranslateBox,
    ColorPicker,
  },
})
export default class SiteEditorRibbon extends Vue {
  @Prop()
  block: PageBlock;

  @Prop()
  manager: SiteEditorManager;

  get props() {
    return this.manager.defineProps(this.block, {
      title: [] as LangArrType,
      remarks: [] as LangArrType,
      location: "",
      titleDisplayMode: "left",
      fontColor: "#000000FF",
    });
  }

  get titleDisplayModeType() {
    return ["left", "center", "right"];
  }

  get location() {
    return this.props.location === "" ? "HongKong" : this.props.location;
  }
}
