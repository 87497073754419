<template>
  <v-card style="height: 100%; overflow: hidden" class="d-flex flex-column">
    <v-card-title class="headline" v-t="'pos.dialogs.cashCheck.$'"></v-card-title>
    <v-card-text class="flex-grow-1 overflow-hidden basis-0" style="overflow: hidden">
      <div class="h-full overflo-y-auto scrollable">
        <v-form v-model="valid">
          <v-list>
            <template v-if="last">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title v-t="'cashCheck.lastDate'" />
                  <v-list-item-subtitle
                    ><formatter format="moment" :value="last.date"
                  /></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title v-t="'cashCheck.lastType'" />
                  <v-list-item-subtitle
                    ><formatter
                      format="enum"
                      :args="[null, null, { type: 'cashCheckType' }]"
                      :value="last.type"
                  /></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title v-t="'cashCheck.lastAmount'" />
                <v-list-item-subtitle
                  ><formatter format="currency" :value="original"
                /></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <template v-if="summary && checking">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title v-t="'cashCheck.period'" />
                  <v-list-item-subtitle v-if="minCursor">
                    {{ $t("cashCheck.startPeriod")
                    }}<formatter format="moment" :value="minCursor" />
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    >{{ $t("cashCheck.endPeriod") }}<formatter format="moment" :value="now"
                  /></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title v-t="'cashCheck.summary.$'" />
                  <v-list-item-subtitle>
                    {{ $t("cashCheck.summary.paid") }}{{ summary.paids.amount }} ({{
                      summary.paids.total
                    }}
                    {{ $t("cashCheck.summary.orders") }})
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ $t("cashCheck.summary.refunded") }}{{ summary.refunds.amount }} ({{
                      summary.refunds.total
                    }}
                    {{ $t("cashCheck.summary.orders") }})
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title v-t="'cashCheck.expectedDelta'" />
                  <v-list-item-subtitle
                    ><formatter format="currency" :value="expected"
                  /></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title v-t="'cashCheck.expectedAmount'" />
                  <v-list-item-subtitle
                    ><formatter format="currency" :value="expected + original"
                  /></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>

          <editor-oject-picker-new
            :disabled="!!type"
            :items="cashCheckType"
            v-model="currentType"
            required
            :label="$t('pos.dialogs.cashCheck.type')"
          />
          <v-text-field
            :readonly="hasDetails"
            :label="$t('pos.dialogs.cashCheck.delta')"
            v-model.number="delta"
            :rules="[
              (v) => {
                switch (currentType) {
                  case 'in':
                    if (v <= 0) return $t('pos.dialogs.cashCheck.errors.shouldBePositive');
                    break;
                  case 'out':
                    if (v >= 0) return $t('pos.dialogs.cashCheck.errors.shouldBeNegative');
                    break;
                }
                return true;
              },
            ]"
          />
          <v-text-field
            :readonly="hasDetails"
            :label="$t('pos.dialogs.cashCheck.amount')"
            v-model="amount"
            :rules="[
              (v) => {
                if (v === '') return $t('pos.dialogs.cashCheck.errors.required');
                return true;
              },
            ]"
          />
          <v-text-field
            v-if="checking"
            readonly
            :label="$t('pos.dialogs.cashCheck.diff')"
            v-model.number="diff"
          />
          <v-checkbox :label="$t('pos.dialogs.cashCheck.hasDetails')" v-model="hasDetails" />

          <template v-if="hasDetails">
            <simple-list
              :label="$t('pos.dialogs.cashCheck.details')"
              v-model="details"
              :default="{
                unit: 0,
                number: 0,
              }"
            >
              <template slot="item" slot-scope="item">
                <v-text-field
                  :label="$t('pos.dialogs.cashCheck.unit')"
                  v-model.number="item.item.unit"
                />
                <v-text-field
                  :label="$t('pos.dialogs.cashCheck.number')"
                  v-model.number="item.item.number"
                />
              </template>
            </simple-list>
          </template>

          <v-textarea :label="$t('pos.dialogs.cashCheck.comment')" v-model="comment" />
        </v-form>
      </div>
    </v-card-text>
    <v-card-actions class="d-flex flex-wrap">
      <div class="flex-no-wrap mt-2">
        <v-btn @click="loadData" :loading="loading" v-t="'basic.reload'" />
        <v-btn @click="copyFromLast" v-t="'basic.copyFromLast'" />
      </div>
      <v-spacer></v-spacer>
      <div class="flex-no-wrap mt-2">
        <v-btn
          :loading="loading"
          color="red darken-1"
          @click.native="modalResult(false)"
          v-t="'basic.cancel'"
        ></v-btn>
        <v-btn
          :disabled="!valid"
          :loading="loading"
          color="blue"
          @click.native="save"
          v-t="'basic.ok'"
        ></v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import Dialog from "@feathers-client/mixins/Dialog";
import nuxtend from "@feathers-client/nuxtend";
import moment from "moment";
import _ from "lodash";
import EditorObjectPickerNew from '@schemaEditor/EditorObjectPickerNew.vue'

export default nuxtend({
  mixins: [Dialog],
  props: {
    type: {},
    cashier: {},
    waitPermission: {},
  },
  mounted() {
    this.loadData();
  },
  components: {
    EditorObjectPickerNew
  },
  data() {
    return {
      loading: false,
      last: null,
      lastCheck: null,
      currentType: "beginCheck",
      summary: null,
      now: null,
      details: [],
      hasDetails: false,
      mamount: "",
      comment: "",
      valid: false,

      cashCheckType: [
          {
              id: 'in', 
              name: { $t: 'cashCheckType.in' },
          },
          {
              id: 'out', 
              name: { $t: 'cashCheckType.out' },
          },
          {
              id: 'beginCheck', 
              name: { $t: 'cashCheckType.beginCheck' },
          },
          {
              id: 'middleCheck', 
              name: { $t: 'cashCheckType.middleCheck' },
          },
          {
              id: 'endCheck', 
              name: { $t: 'cashCheckType.endCheck' },
          },
      ]
    };
  },
  computed: {
    checking() {
      return (
        this.currentType === "beginCheck" ||
        this.currentType === "middleCheck" ||
        this.currentType === "endCheck"
      );
    },

    minCursor() {
      if (!this.lastCheck || !this.lastCheck.checkCursor) return null;
      const v = moment.max(
        moment(this.lastCheck.checkCursor.lastRefund),
        moment(this.lastCheck.checkCursor.lastPaid),
      );
      if (!v.isValid()) return null;
      return v;
    },

    original() {
      return this.last ? +this.last.amount : 0;
    },

    amount: {
      get() {
        if (this.hasDetails) return _.sumBy(this.details, (it) => it.unit * it.number);
        else return this.mamount;
      },
      set(v) {
        if (!this.hasDetails) this.mamount = +v;
      },
    },

    delta: {
      get() {
        return +this.amount - +this.original;
      },
      set(v) {
        if (!isNaN(v)) {
          this.amount = +this.original + +v;
        }
      },
    },

    expected() {
      if (!this.summary) return 0;
      if (!this.checking) return this.delta;
      return this.summary.paids.amount - this.summary.refunds.amount;
    },

    diff() {
      return this.delta - this.expected;
    },
  },
  methods: {
    async loadData() {
      this.loading = true;
      try {
        const lastCheck = (
          await this.$feathers.service("shop/pos/cashboxLogs").find({
            query: {
              cashier: this.cashier,
              $shop: true,
              $limit: 1,
              $sort: { date: -1 },
              type: {
                $in: ["middleCheck", "beginCheck", "endCheck"],
              },
              status: "valid",
            },
          })
        ).data[0];

        const last = (
          await this.$feathers.service("shop/pos/cashboxLogs").find({
            query: {
              cashier: this.cashier,
              $shop: true,
              $limit: 1,
              $sort: { date: -1 },
              status: "valid",
            },
          })
        ).data[0];

        const defType = lastCheck
          ? lastCheck.type === "beginCheck"
            ? "endCheck"
            : "beginCheck"
          : "in";
        this.currentType = this.type || defType;

        const summary = await this.$feathers.service("shop/pos/cashboxLogs/summary").find({
          query: {
            $shop: true,
            cursor: lastCheck && lastCheck.checkCursor,
            cashier: this.cashier,
          },
        });

        this.now = new Date();
        this.summary = summary;

        this.last = last;
        this.lastCheck = lastCheck;
      } catch (e) {
        this.$store.commit("SET_ERROR", e.message);
      } finally {
        this.loading = false;
      }
    },

    copyFromLast() {
      this.amount = (this.last && this.last.amount) || 0;
      this.hasDetails = (this.last && this.last.hasDetails) || false;
      this.details = (this.last && this.last.details) || [];
    },

    async save() {
      try {
        if (this.waitPermission && this.diff < 0) {
          await this.waitPermission("manager", true);
        }
        this.loading = true;
        const item = await this.$feathers.service("shop/pos/cashboxLogs").create({
          cashier: this.cashier,
          from: (this.last && this.last.amount) || 0,
          amount: this.amount,
          expectedDelta: this.expected,
          type: this.currentType,
          comment: this.comment,
          hasDetails: this.hasDetails,
          details: this.details,
          admin: this.admin,
          $shop: true,
        });
        this.modalResult(item);
      } catch (e) {
        this.$store.commit("SET_ERROR", e.message);
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>

<style scoped>
.scroll-area {
  position: relative;
  margin: auto;
  width: 100%;
  height: 100%;
}
</style>
