import { Component, Vue, Prop } from "@feathers-client";
import { LangType } from "@feathers-client/i18n";

export interface PaymentMethodField {
  name?: LangType;
  type?: "text" | "number" | "password" | "boolean"
  multiLine?: boolean;
}

export interface PaymentMethodCapabilities {
  name?: LangType;
  placeholder?: boolean;
  void?: boolean;
  refund?: boolean;
  cancel?: boolean;
  restore?: boolean;
  adjust?: boolean;
  abortable?: boolean;
  settlement?: boolean;
  autoSettle?: boolean;
  subMethods?: PaymentSubMethod[];
  cloud?: boolean;
  propsFields?: Record<string, PaymentMethodField>;
  secretsFields?: Record<string, PaymentMethodField>;
  localFields?: Record<string, PaymentMethodField>;
  statusFields?: Record<string, PaymentMethodField>;
  actions?: {
    name: LangType;
    method: string;
  }[];
  defaultOptionDialog?: boolean;
  authData?: "qrcode";
  subMethodsKey?: string;
  envs?: {
    id: string;
    testing?: boolean;
    name?: LangType;
  }[];
  networks?: PaymentNetwork[];
}

export interface PaymentNetwork {
  id: string;
  name: LangType;
}

export interface PaymentMethodLike {
  name?: LangType;
  props?: any;
  secrets?: any;
}

export interface PaymentSubMethod {
  name: LangType;
  subType: string;
  icon?: string;
  props?: any;
  authData?: "qrcode";
  method?: PaymentMethodLike;
}

export interface PaymentManagerLike {
  getConfig: (type: string) => any;
  setConfig: (type: string, config: any) => void;
  getInfo: (type: string) => {
    shopId?: string;
    shopName?: string;
    cashierId?: string;
    cashierShortId?: string;
    cashierName?: string;
    countryCode?: string;
    posVersion?: string;
    mode?: "kiosk" | "pos";
  };
}

export interface CloudPayload {
  method: "cancel" | "restore" | "refund" | "void";
}

export interface PaymentOptions {
  signal?: AbortSignal;
  statusCb?: (message: LangType, statusCode?: number) => void;
  subscribe?: (payment: PaymentLike, options?: PaymentOptions) => Promise<PaymentLike>;
  cloudInvoke?: (payment: PaymentLike, payload: CloudPayload, options?: PaymentOptions) => Promise<PaymentLike>;
  retries?: number;
}

export interface PaymentLike {
  _id?: any;
  orderPaymentId?: string;
  amount: number;
  tips?: number;
  surcharge?: number;
  currency?: string;
  subType?: string;
  props?: any;
  metadata?: any;
  status: "pending" | "paid" | "error" | "cancelled" | "paying" | "refunded";
  message?: string;
  errorMessage?: string;
  network?: string;
  authData?: any;
  qrCodeUrl?: string;
  qrCodeData?: string;

  paymentId?: string;
}

export class PaymentError extends Error {
  constructor(
    message: string,
    public payment: PaymentLike,
  ) {
    super(message);
  }
}

@Component
export class PaymentMethodBase extends Vue {
  method: PaymentMethodLike = null;
  methods: PaymentMethodLike[] = [];
  serialNo: string = "";

  @Prop()
  initMethod: PaymentMethodLike | PaymentMethodLike[];

  @Prop()
  paymentManager: PaymentManagerLike;

  @Prop()
  type: string;

  status: string = "notSetup";
  supported: boolean | null = null;

  get info() {
    return this.paymentManager?.getInfo?.(this.type);
  }

  get name() {
    return this.capabilities?.name ?? this.method?.name;
  }

  get capabilities() {
    return this.getCapabilities();
  }

  get connected() {
    return this.status === "connected" || this.status === "cloud";
  }

  get error() {
    return this.status === "error";
  }

  get statusText() {
    return this.$t("enum.basic.status." + this.status) as string;
  }

  get hasConnection() {
    return false;
  }

  get hasSetup() {
    return this.hasConnection;
  }

  async created() {
    this.updateMethod(this.initMethod);
    await this.preInit();
    if (this.capabilities.placeholder) return;
    if (this.config) {
      this.connect().catch(console.error);
    }
  }

  get rawConfig() {
    return this.paymentManager.getConfig(this.type) || this.getInitConfig();
  }

  get config() {
    return this.rawConfig || {};
  }

  set config(config: any) {
    this.paymentManager.setConfig(this.type, config);
  }

  updateMethod(method: PaymentMethodLike | PaymentMethodLike[]) {
    if (Array.isArray(method)) {
      this.methods = method;
      this.method = method[0];
    } else {
      this.methods = [method];
      this.method = method;
    }
  }

  preInit() {}

  getCapabilities(): PaymentMethodCapabilities {
    return {};
  }

  getInitConfig() {
    return null;
  }

  get hasConfig() {
    return !!this.rawConfig;
  }

  get subMethodsKey() {
    return this.capabilities?.subMethodsKey ?? "subMethods";
  }

  get subMethods(): PaymentSubMethod[] {
    const subMethods = this.capabilities.subMethods;
    if (!subMethods) return null;
    const enabledMethods: string[] = this.methods.flatMap(m => m?.props?.[this.subMethodsKey] ?? []);
    const set = new Set(enabledMethods);
    return subMethods.filter(m => set.has(m.subType));
  }

  subMethodsForMethod(method: PaymentMethodLike) {
    const subMethods = this.capabilities.subMethods;
    if (!subMethods) return null;
    const enabledMethods: string[] = method?.props?.[this.subMethodsKey] ?? [];
    const set = new Set(enabledMethods);
    return subMethods.filter(m => set.has(m.subType));
  }

  getSettingsDialog() {
    return null;
  }

  async openSettings() {
    const dialog = await this.getSettingsDialog();
    if (!dialog) return;
    return await this.$openDialog(
      dialog,
      {
        manager: this,
      },
      {
        maxWidth: "80%",
        contentClass: "editor-dialog",
      },
    );
  }

  async beginPay(payment: PaymentLike) {}

  async pay(payment: PaymentLike, options?: PaymentOptions): Promise<PaymentLike> {
    throw new Error("Not implemented");
  }

  async adjust(payment: PaymentLike, adjustOptions: {
    diffAmount: number;
  }, options?: PaymentOptions): Promise<PaymentLike> {
    throw new Error("Not implemented");
  }

  async restore(payment: PaymentLike, options?: PaymentOptions): Promise<PaymentLike> {
    throw new Error("Not implemented");
  }

  async refund(payment: PaymentLike, options?: PaymentOptions): Promise<PaymentLike> {
    throw new Error("Not implemented");
  }

  async void(payment: PaymentLike, options?: PaymentOptions): Promise<PaymentLike> {
    throw new Error("Not implemented");
  }

  async cancel(payment?: PaymentLike, options?: PaymentOptions): Promise<void | boolean | "async"> {
    throw new Error("Not implemented");
  }

  async connect(user?: boolean, reconnect?: boolean) {
    throw new Error("Not implemented");
  }

  async reset() {
    this.config = null;
  }

  async test() {
    return true;
  }

  async settlement(options?: PaymentOptions) {
    throw new Error("Not implemented");
  }

  async waitReady(forceUser?: boolean | "background", timeout = 0, signal?: AbortSignal) {
    if (!this.hasConnection && !this.hasSetup) {
      await this.openSettings();
    }

    if (!this.hasConnection && forceUser !== "background") {
      await this.connect(true);
    }
    // @ts-ignore
    signal?.throwIfAborted?.();
    if (!this.hasConnection && forceUser !== "background") {
      throw new Error("Not connected");
    }
    let cancelReject: (e: Error) => void;
    const cancelHandler = () => {
      cancelReject?.(new Error("Aborted"));
    };
    if(signal) {
      signal.addEventListener("abort", cancelHandler);
    }
    if (this.status === "disconnected") {
      await this.connect();
    }
    while (this.status !== "connected" && this.status !== "cloud") {
      // @ts-ignore
      signal?.throwIfAborted?.();
      await new Promise((resolve, reject) => {
        this.$once("connected", resolve);
        if (timeout) {
          setTimeout(() => reject(new Error("Timeout connecting")), timeout);
        }
        cancelReject = reject;
      });

      await new Promise(resolve => setTimeout(resolve, 10));
      break;
    }
  }

  getAuthDataMode(subType: string) {
    const subMethod = this.subMethods?.find(m => m.subType === subType);
    return subMethod?.authData === undefined ? this.capabilities.authData : subMethod.authData;
  }
}
