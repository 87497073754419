
import { Component, Vue, Prop, FindType, Watch, checkID } from '@feathers-client'
import { ShipmentOrder, ShippingProduct } from '~/plugins/pos/shipment'


@Component
export default class ShipmentOrderView extends Vue {
    @Prop()
    order: ShipmentOrder

    removeItem(item: ShippingProduct) {
        item.$destroy();
    }
}

