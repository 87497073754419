


import _ from 'lodash'
import Editor from '@schemaEditor/Editor.vue'
import { Component, Vue, Prop } from '@feathers-client'

@Component({
    components: {
        Editor,
    }
})
export default class Shipping extends Vue {

    @Prop(Boolean)
    nested: boolean

    @Prop()
    filter: any

    @Prop()
    headers: string[]

    refresh() {
        (this.$refs?.editor as any)?.refresh?.();
    }

}


