import _, { set } from 'lodash'
import Vue from 'vue'
const objectAssign = _.assign;

function bindContext (methods, context) {
    const target = {'$store': context.store, '$axios': context.$axios}
    const result = {}
    for (let key in methods) {
        result[key] = methods[key].bind(target)
    }
    return result
}

function createAsyncDataFunction (component, originalAsyncData, methods) {
    return async (context) => {
        let data = {}
        for (let idx in component.mixins) {
            let mixin = component.mixins[idx]
            if (typeof mixin.asyncData !== 'undefined') {
                let r = await mixin.asyncData(context)
                if(r === 'shortcut') return data;
                objectAssign(data, r)
            }
        }
        let target = {}
        objectAssign(target, bindContext(methods, context))
        if(originalAsyncData) {
            let r = await originalAsyncData.apply(target, [context])
            objectAssign(data, r)
        }
        return data
    }
}

function createFetchFunction (component, originalFetch, methods) {
    return async (context) => {
        for (let idx in component.mixins) {
            let mixin = component.mixins[idx]
            if (typeof mixin.fetch !== 'undefined') {
                await mixin.fetch(context)
            }
        }
        let target = {}
        objectAssign(target, bindContext(methods, context))
        if(originalFetch) {
            await originalFetch.apply(target, [context])
        }
    }
}

function mergeMethods (component) {
    let methods = {}
    if (component.mixins) {
        component.mixins.forEach(mixin => {
            if (mixin.methods) {
                objectAssign(methods, mixin.methods)
            }
        })
    }
    if (component.methods) {
        objectAssign(methods, component.methods)
    }
    return methods
}

export default function nuxtend (component) {
    let {asyncData, fetch} = component
    const methods = mergeMethods(component)
    if(_.some(component.mixins, m => m.fetch) || fetch) {
        component.fetch = createFetchFunction(component, fetch, methods)
    }
    if(_.some(component.mixins, m => m.asyncData) || asyncData) {
        component.asyncData = createAsyncDataFunction(component, asyncData, methods)
    }
    const dataCreators = [
        component.data,
        ..._.map(component.mixins, c => c.data)
    ].filter(it => !!it);
    const getData = (vm) => {
        return _.merge({}, ...dataCreators.map(it => it.call(vm)));
    }

    if(component.loadData) {
        if(process.server) {
            component.asyncData = async function({ store, app, route, redirect }) {
                const fakeContext = {
                    ...app,
                    $store: store,
                    $route: route,
                    $router: app.router,
                    $i18n: app.i18n,
                    $t: () => '',
                    $i18nReplace: (url, perm) => {
                        const u = Vue.prototype.$localePath.call(fakeContext, url);
                        if(perm === true) {
                            redirect(301, u);
                        } else {
                            redirect(u);
                        }
                    }
                };
                fakeContext.$root = fakeContext;
                _.each(component.methods, (v, k) => fakeContext[k] = v.bind(fakeContext))
                _.each(component.computed, (v, k) => Object.defineProperty(fakeContext, k, typeof v === 'function' ? {
                    get: v.bind(fakeContext)
                } : {
                    get: v.get.bind(fakeContext)
                }));
                _.each(Vue.prototype, (v, k) => {
                    if(typeof v === 'function' && !fakeContext[k]) fakeContext[k] = v.bind(fakeContext);
                })
    
                const data = getData(app.$uiApp) || {};
                Object.defineProperties(fakeContext, _.fromPairs(_.map(data, (v, k) => [
                    k,
                    {
                        get() { return data[k] },
                        set(v) { data[k] = v }
                    }
                ])) as any);
    
                await component.loadData.apply(fakeContext);
                data.serverLoaded = true;
                return data;
            }
        } else {
            const mounted = component.mounted || function() {};
            component.asyncData = () => {
                return {};
            }
            component.mounted = function() {
                mounted.call(this);
                if(this.serverLoaded) {
                    this.dataReady?.();
                    return;
                }
                component.loadData.apply(this).then(()=>{
                    this.dataReady?.();
                });
            }
        }
        component.methods ??= {};
        component.methods.loadData = component.loadData;
    }
    return component
}
