import Vue from "vue";
import _ from "lodash";
import moment from "moment";
import { LangType } from "./i18n";
import { Context } from "@nuxt/types";

Vue.prototype.$attach = function (image: any) {
  if (typeof image === "string") {
    return `${this.$config.apiUrl}/api/attachments/${image}`;
  } else if (image) {
    return image.src;
  }
  return null;
};

Vue.prototype.$image = function (image: any, size?: string) {
  if (typeof image === "string") {
    return `${this.$config.apiUrl}/api/attachments/${image}${size ? "." + size : ""}.${
      this.$store.state.supportWebp ? "webp" : "jpg"
    }`;
  } else if (image) {
    return image.src;
  }
  return null;
};

let cache: Record<string, any> = {};
let cachew: Record<string, any> = {};

Vue.prototype.$imageSet = function (image: any) {
  if (!image) return null;
  const webp = this.$store.state.supportWebp;
  const dict = webp ? cachew : cache;
  let thumb;
  if (typeof image === "object") {
    if (!image._id) return image.src;
    thumb = image.thumb;
    image = image._id;
  }

  let res = dict[image];
  if (!res) {
    const ext = `.${webp ? "webp" : "jpg"}`;
    const base = `${this.$config.apiUrl}/api/attachments/${image}`;
    const sizes = [
      ["small", 400],
      ["medium", 800],
      ["large", 1200],
      ["exlarge", 2000],
      ["exlargex2", 4000],
    ];
    res = dict[image] = {
      lazySrc: thumb || `${this.$config.apiUrl}/api/thumbs/${image}.${webp ? "webp" : "jpg"}`,
      src: `${base}${ext}`,
      srcset: sizes.map(([name, width]) => `${base}.${name}${ext} ${width}w`).join(","),
    };
  }

  return res;
};

Vue.prototype.$thumb = function (item: any, showDef?: boolean) {
  if (typeof item === "string" && item)
    return `${this.$config.apiUrl}/api/thumbs/${item}.${this.$store.state.supportWebp ? "webp" : "jpg"}`;
  else if (item) {
    if (!item.thumb) return showDef ? this.$config?.appLogo || require("~/assets/images/logo.png") : "";
    if (item.thumb.indexOf("data:") === 0) return item.thumb;
    else if (!item.mime) return "data:image/png;base64," + item.thumb.toString("base64");
    else return "data:" + item.mime + ";base64," + item.thumb.toString("base64");
  }
  return "";
};

Vue.prototype.$avatar = function (user) {
  return user
    ? `${this.$config.apiUrl}/api/users/pic/${user._id || user}`
    : this.$config?.appLogo || require("~/assets/images/logo.png");
};

Vue.prototype.$htmlContent = function (this: Vue, item: string) {
  if (!item) return item;
  item = `${item}`;
  item = item.replace(/\{\{ATTACHMENT\(([a-f\d]{24})\)\}\}/gi, (m, id) => {
    return this.$image(id);
  });
  return item;
};

Vue.prototype.$confirm = function (this: Vue, message: LangType, opts?: any, cb?: (id: string) => void) {
  return this.$openDialog(
    import("@feathers-client/components-internal/ConfirmDialog2.vue"),
    {
      title: message,
      ...opts,
    },
    {
      maxWidth: "500px",
      persistent: opts?.persistent,
    },
    cb,
  );
};

// smartSize
Vue.filter("smartSize", (value, showSize = true, showUnit = true) => {
  let unit = "B";
  if (value > 512) {
    value /= 1024;
    unit = "KB";
    if (value > 512) {
      value /= 1024;
      unit = "MB";
      if (value > 512) {
        value /= 1024;
        unit = "GB";
        if (value > 512) {
          value /= 1024;
          unit = "TB";
        }
      }
    }
    if (value < 1) value = Math.round(value * 100) / 100;
    else if (value < 10) value = Math.round(value * 10) / 10;
    else value = Math.floor(value);
  }
  if (showSize && showUnit) return value + " " + unit;
  else if (showSize) return "" + value;
  else if (showUnit) return unit;
});

// v-focus
Vue.directive("focus", {
  inserted: function (el, binding) {
    if (binding.value) {
      var input = el.querySelector("input");
      input ? input.focus() : el.focus();
    }
  },
  update(el, binding, vnode, oldVnode) {
    if (binding.value !== binding.oldValue) {
      var input = el.querySelector("input");
      if (binding.value) {
        input ? input.focus() : el.focus();
      } else {
        input ? input.blur() : el.blur();
      }
    }
  },
});

declare global {
  interface HTMLElement {
    handler?: any;
    fhandler?: any;
    $escape?: any;

    $fixOverscroll: FixOverScroll;
    $keypress: () => void;
  }
  interface Window {
    $escape?: any;
  }
}

// v-action
Vue.directive("action", {
  bind(el, binding, vnode) {
    const root = vnode.context.$root;
    const router = vnode.context.$router;
    const store = vnode.context.$store;
    if (!el.handler) {
      el.handler = {};
    }
    _.each(binding.modifiers, (v, k) => {
      el.handler[k] = async action => {
        if (binding.value instanceof Function) {
          store.commit("SET_PROCESSING", action && action.action);
          try {
            await binding.value(action);
          } catch (e: any) {
            store.commit("SET_ERROR", e.message);
          } finally {
            store.commit("SET_PROCESSING", null);
          }
        } else {
          router.push(binding.value);
        }
      };
      root.$on(k, el.handler[k]);
    });
  },
  unbind(el, binding, vnode) {
    if (!el.handler) return;
    const root = vnode.context.$root;
    _.each(binding.modifiers, (v, k) => {
      root.$off(k, el.handler[k]);
      delete el.handler[k];
    });
  },
});

// v-feathers
Vue.directive("feathers", {
  bind(el, binding, vnode) {
    const feathers = vnode.context.$feathers as any;
    if (!el.fhandler) {
      el.fhandler = {};
    }
    const paths = Object.keys(binding.modifiers);
    if (binding.arg) paths.push(binding.arg);
    for (let k of paths) {
      if (k === "created" || k === "patched" || k === "removed" || k === "updated") continue;
      el.fhandler[k] = data => {
        if (binding.value) binding.value(data);
      };
      const s: any = feathers.service(k);
      if (binding.modifiers.created) s.on("created", el.fhandler[k]);
      if (binding.modifiers.patched) s.on("patched", el.fhandler[k]);
      if (binding.modifiers.removed) s.on("removed", el.fhandler[k]);
      if (binding.modifiers.updated) s.on("updated", el.fhandler[k]);
    }
  },
  unbind(el, binding, vnode) {
    const feathers = vnode.context.$feathers as any;
    const paths = Object.keys(binding.modifiers);
    if (binding.arg) paths.push(binding.arg);
    for (let k of paths) {
      if (k === "created" || k === "patched" || k === "removed" || k === "updated") continue;
      const s: any = feathers.service(k);
      if (binding.modifiers.created) s.removeListener("created", el.fhandler[k]);
      if (binding.modifiers.patched) s.removeListener("patched", el.fhandler[k]);
      if (binding.modifiers.removed) s.removeListener("removed", el.fhandler[k]);
      if (binding.modifiers.updated) s.removeListener("updated", el.fhandler[k]);
      delete el.fhandler[k];
    }
  },
});

// v-escape
Vue.directive("escape", {
  bind(el, binding) {
    el.$escape = () => {
      return binding.value;
    };
    if (!window.$escape) {
      window.$escape = e => {
        if (e.keyCode === 27) {
          const h = window.$escape.handlers;
          for (var i = h.length - 1; i >= 0; i--) {
            if (h[i] && h[i]() && h[i]()(e)) {
              e.stopPropagation();
              e.preventDefault();
              break;
            }
          }
        }
      };
      window.$escape.handlers = [];
      window.addEventListener("keydown", window.$escape);
    }
    window.$escape.handlers.push(el.$escape);
  },
  unbind(el, binding) {
    if (el.$escape) {
      if (window.$escape) {
        const h = window.$escape.handlers;
        const idx = h.indexOf(el.$escape);
        idx !== -1 && h.splice(idx, 1);
      }
      delete el.$escape;
    }
  },
});

// v-keypress
Vue.directive("keypress", {
  bind(el, binding) {
    el.$keypress = () => {
      return binding.value;
    };
    if (!(window as any).$keypress) {
      (window as any).$keypress = e => {
        const h = (window as any).$keypress.handlers;
        for (var i = h.length - 1; i >= 0; i--) {
          if (h[i] && h[i]() && h[i]()(e)) {
            e.stopPropagation();
            e.preventDefault();
            break;
          }
        }
      };
      (window as any).$keypress.handlers = [];
      window.addEventListener("keydown", (window as any).$keypress);
    }
    (window as any).$keypress.handlers.push(el.$keypress);
  },
  unbind(el, binding) {
    if (el.$keypress) {
      if ((window as any).$keypress) {
        const h = (window as any).$keypress.handlers;
        const idx = h.indexOf(el.$keypress);
        idx !== -1 && h.splice(idx, 1);
      }
      delete el.$keypress;
    }
  },
});

// v-fix-overscroll

class FixOverScroll {
  constructor(public elem: Vue) {
    this.startMove = this.startMove.bind(this);
    this.canMove = this.canMove.bind(this);
    this.endMove = this.endMove.bind(this);
  }

  _clientX: number = 0;
  _clientY: number = 0;
  _scrolling: boolean = false;

  startMove(event: TouchEvent) {
    if (event.targetTouches.length === 1) {
      this._clientX = event.targetTouches[0].clientX;
      this._clientY = event.targetTouches[0].clientY;
    }
  }

  canMove(event: TouchEvent) {
    if (this.elem.$store.state.fullPage) {
      event.stopPropagation();
      if (event.targetTouches.length === 1) {
        if (
          this.hasScrollable(
            event.target as HTMLElement,
            event.targetTouches[0].clientX - this._clientX,
            event.targetTouches[0].clientY - this._clientY,
          )
        ) {
          this._scrolling = true;
        } else if (!this._scrolling) {
          event.preventDefault();
        }
      }
    }
  }

  hasScrollable(target: HTMLElement, clientX: number, clientY: number) {
    if (target.classList.contains("scrollable") || target.classList.contains("v-data-table__wrapper")) {
      if (target.scrollHeight > target.clientHeight && Math.abs(clientY) > Math.abs(clientX)) {
        if (
          (target.scrollTop > 0 || clientY < 0) &&
          (target.scrollHeight - target.scrollTop > target.clientHeight || clientY > 0)
        ) {
          return true;
        }
      }
      if (target.scrollWidth > target.clientWidth && Math.abs(clientX) > Math.abs(clientY)) {
        if (
          (target.scrollLeft > 0 || clientX < 0) &&
          (target.scrollWidth - target.scrollLeft > target.clientWidth || clientX > 0)
        ) {
          return true;
        }
      }
    }
    return target.parentElement && this.hasScrollable(target.parentElement, clientX, clientY);
  }

  endMove(event: TouchEvent) {
    if (event.targetTouches.length === 0) {
      this._scrolling = false;
    }
  }
}

// v-fix-overscroll
Vue.directive("fix-overscroll", {
  bind(el, binding, vnode) {
    const item = (el.$fixOverscroll = new FixOverScroll(vnode.context));
    el.addEventListener("touchstart", item.startMove);
    el.addEventListener("touchmove", item.canMove);
    el.addEventListener("touchend", item.endMove);
  },
  unbind(el, binding, vnode) {
    if (el.$fixOverscroll) {
      const item = el.$fixOverscroll;
      el.addEventListener("touchstart", item.startMove);
      el.addEventListener("touchmove", item.canMove);
      el.addEventListener("touchend", item.endMove);
      el.$fixOverscroll = null;
    }
  },
});

// v-scroll-to-bottom
Vue.directive("scroll-to-bottom", {
  bind(el) {
    setTimeout(() => {
      el.scrollTop = el.scrollHeight;
    }, 100);
  },
});

if (!Vue.prototype.hasOwnProperty("$moment")) {
  Object.defineProperty(Vue.prototype, "$moment", {
    get() {
      return this.$root.$options.$moment || moment;
    },
  });
}

Vue.filter("moment", (ctx, value: string | moment.Moment) => {
  if (ctx.$i18n) {
    ctx.$ensureI18N();
    return value
      ? (value instanceof moment ? <moment.Moment>value : moment(value)).locale(ctx.$i18n.locale).format("lll")
      : "-";
  } else {
    return value ? (value instanceof moment ? <moment.Moment>value : moment(value)).format("lll") : "-";
  }
});

Vue.filter("date", (ctx, value: string | moment.Moment) => {
  if (ctx.$i18n) {
    ctx.$ensureI18N();
    return value
      ? (value instanceof moment ? <moment.Moment>value : moment(value)).locale(ctx.$i18n.locale).format("ll")
      : "-";
  } else {
    return value ? (value instanceof moment ? <moment.Moment>value : moment(value)).format("ll") : "-";
  }
});

Vue.filter("join", function (ctx, items) {
  if (items instanceof Array) return items.join(", ");
  return items;
});

let _androidTouchFixApplied = false;
export function applyAndroidTouchFix() {
  if (process.client) {
    if (_androidTouchFixApplied) return;
    _androidTouchFixApplied = true;
    let capturingButton: HTMLElement | null = null;
    document.addEventListener(
      "touchstart",
      e => {
        if (e.touches.length > 1) return;

        let cur = e.target as HTMLElement;
        if (cur.tagName !== "INPUT" && cur.tagName !== "TEXTAREA") {
          while (cur) {
            if (cur.getAttribute("role") === "button") {
              break;
            }
            cur = cur.parentElement;
          }
        }

        if (cur) {
          capturingButton = cur;
        }
      },
      { capture: true },
    );
    document.addEventListener(
      "touchend",
      e => {
        if (capturingButton) {
          let isSameElem = false;

          if (e.cancelable) {
            const bounds = capturingButton.getBoundingClientRect();
            const x = e.changedTouches[0].clientX;
            const y = e.changedTouches[0].clientY;

            isSameElem = x >= bounds.left && x <= bounds.right && y >= bounds.top && y <= bounds.bottom;
          }

          if (isSameElem) {
            e.preventDefault();
            e.stopPropagation();
            const btn = capturingButton;
            setTimeout(() => {
              btn.click();
              if (btn.tagName === "INPUT" || btn.tagName === "TEXTAREA") {
                btn.focus();
              }
            }, 0);
          }

          capturingButton = null;
        }
      },
      { capture: true },
    );
  }
}

Object.defineProperty(Vue.prototype, "$features", {
  get(this: Vue) {
    return this.$store?.getters?.features || this.$config?.features || {};
  },
});

export default function (ctx: Context) {
  if(!ctx) return;
  Object.defineProperty(ctx, "$features", {
    get() {
      return ctx.store?.getters?.features || ctx.$config?.features || {};
    },
  });
}
