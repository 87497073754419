
import { Component, Vue, Prop, Watch, Ref } from "@feathers-client";
import { SiteEditorManager } from ".";

@Component({})
export default class SiteEditorPagePreviewer extends Vue {
  @Prop()
  manager: SiteEditorManager;

  tempManager = new SiteEditorManager({
    parent: this,
    propsData: {},
  });

  get currentManager() {
    return this.manager || this.tempManager;
  }

  @Ref()
  iframe: HTMLIFrameElement;

  @Watch("manager.inlinePreviewLoaded")
  async onPreviewLoad(v) {
    if (!v) return;
    await Vue.nextTick();
    if (!this.iframe) return;
    this.manager.mountPreview(this.iframe.contentWindow, this.iframe.src);
  }
}
