
  import { FindType } from "@feathers-client";
  import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
  import { CashierType } from "@common/common";
  import moment from 'moment';

  type idList = FindType<'shop/order/idLists'>
  
  @Component
  export default class ErpIdList extends Vue {
      getPreview(item : FindType<'shop/order/idLists'>) {
          let current = item.current || 0;
          if ((item.startNumber || 0) > current) {
              current = item.startNumber || 0;
          }
          if (item.type === 'date') {
              const formattedDate = this.formatDate(item.dateFormat);
              if (item.lastDate && formattedDate === moment(item.lastDate).format(item.dateFormat)) {
                  return `${item.prefix}${formattedDate}${`${current}`.padStart(item.padding, '0')}`;
              } else {
                  return `${item.prefix}${formattedDate}${`${item.startNumber || 0}`.padStart(item.padding, '0')}`;
              }
          }
          return `${item.prefix}${`${current}`.padStart(item.padding, '0')}${item.suffixRandomDigits ? "".padStart(item.suffixRandomDigits, 'X') : ''}`;
      }
  
      @Prop(Boolean)
      eshop : boolean
  
      @Prop()
      value : string

      @Prop()
      name : string

      @Prop()
      docType: idList['erpDocType']
  
      get orderIdList() { return this.value }
      set orderIdList(v) { this.$emit('input', v) }
  
      loading = false;
  
      typeList = ['normal', 'date'];
  
      async mounted() {
          this.loading = true;
          try {
              await this.$pos.loadCashiers();
              this.loading = false;
          } catch(e) {
              this.$store.commit("SET_ERROR", e.message);
          }
      }
  
      isUsed(id) {
          return this.orderIdList === id;
      }
  
      async useIdFormat(item : FindType<'shop/order/idLists'>) {
          if(this.eshop) {
            // debugger
              this.orderIdList = item._id;
          } else {
              return
              // try {
              //     this.loading = true;
              //     await this.$pos.updateCashier(this.cashier, {
              //         orderIdList: item._id
              //     })
              // } catch(e) {
              //     this.$store.commit("SET_ERROR", e.message);
              // } finally {
              //     this.loading = false;
              // }
          }
      }
  
      async removeIdFormat(item : FindType<'shop/order/idLists'>) {
          if(this.eshop) {
              this.orderIdList = null;
          } else {
              return
              // try {
              //     this.loading = true;
              //     const cashier = this.$pos.cashiers?.find?.(it => it.orderIdList === item._id);
              //     if(cashier) {
              //         await this.$pos.updateCashier(this.cashier, {
              //             orderIdList: null,
              //         })
              //     }
              // } catch(e) {
              //     this.$store.commit("SET_ERROR", e.message);
              // } finally {
              //     this.loading = false;
              // }
          }
      }
  
      async deleteItem(item  : FindType<'shop/order/idLists'>) {
          try {
              this.loading = true;
              await this.$feathers.service('shop/order/idLists').patch(item._id, {
                  hidden: true,
              })
              Vue.set(item, 'hidden', true);
          } catch(e) {
              this.$store.commit("SET_ERROR", e.message);
          } finally {
              this.loading = false;
          }
      }
  
      startNumberUpdated(num: number) {
  
      }
  
      formatDate(format: string) {
          return moment(new Date()).format(format);
      }
  }
  