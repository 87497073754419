var render = function render(){var _vm=this,_c=_vm._self._c;return _c('data-table',{staticClass:"mt-6",attrs:{"actions":"","data":{
        subpath: 'separateProducts',
        name: { $t: 'edit_product.separateProducts' }
    },"headers":[
        { text: _vm.$t('edit_product.name'), sortable: false, value: 'product', source: 'products', select: ['name'], path: null, format: 'tf', tpath: 'name', direct: true, filter: { $shop:true, }  },
        { text: _vm.$t('edit_product.sku.inventory.sku'), sortable: false, value: 'sku', format: 'tf', tpath: 'name', computed: true },
        { text: _vm.$t('edit_product.separateRatio'), sortable: false, value: 'ratio' },
    ],"default":{
        product: null,
        sku: null,
        ratio: 0,
    },"paginate":0,"compute":(item, self) => ({
        get product() {
            return self.get(item, {
                value: 'product',
                source: 'products',
                filter: { $shop:true, },
                select: ['name'],
                path: null,
            }, true);
        },

        get productId() {
            return item.product
        },

        set productId(v) {
            item.product = v;
            item.sku = null;
        },
    })},scopedSlots:_vm._u([{key:"editor",fn:function(edit){return [_c('object-picker',{attrs:{"label":_vm.$t('edit_product.sku.inventory.product'),"path":"products","args":{$shop:true, },"item-text":"name"},model:{value:(edit.computed.productId),callback:function ($$v) {_vm.$set(edit.computed, "productId", $$v)},expression:"edit.computed.productId"}}),(edit.computed.product && edit.computed.product.skus && edit.computed.product.skus.length)?_c('object-picker',{attrs:{"label":_vm.$t('edit_product.sku.inventory.sku'),"items":edit.computed.product && edit.computed.product.skus || [],"args":{$shop:true, },"readonly":!!edit.item._id,"item-value":"id","item-text":"name"},model:{value:(edit.item.sku),callback:function ($$v) {_vm.$set(edit.item, "sku", $$v)},expression:"edit.item.sku"}}):_vm._e(),_c('v-text-field',{attrs:{"label":_vm.$t('edit_product.separateRatio')},model:{value:(edit.item.ratio),callback:function ($$v) {_vm.$set(edit.item, "ratio", _vm._n($$v))},expression:"edit.item.ratio"}})]}}]),model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}})
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }