
import { Component, Vue, FindType, Prop } from "@feathers-client";

@Component
export default class SessionSummary extends Vue {
  @Prop()
  session: FindType<"shop/pos/sessions">;

  @Prop()
  cash: FindType<"shop/pos/sessions">["cash"];

  @Prop()
  all: FindType<"shop/pos/sessions">["all"];

  @Prop()
  fromCheck: FindType<"shop/pos/cashboxLogs">;
}
