

import Dialog from "@feathers-client/mixins/Dialog";
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
@Component({
    components: {
    },
})
export default class DiscountAddonProduct extends Vue {

    @Prop()
    product: string | any

    productItem : any = null;

    get name() {
        return this.productItem ? (this as any).$td(this.productItem.name) : `${this.product}`;
    }

    get price() {
        return this.productItem?.price ?? '';
    }

    async mounted() {
        try {
            if(!this.product) return;
            if(typeof this.product === 'string') {
                this.productItem = await this.$feathers.service('products').get(this.product);
            } else {
                this.productItem = this.product;
            }
        } catch(e) {
        }
    }

}

