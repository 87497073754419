

import { Component, Vue, Prop, Ref } from "@feathers-client";
import { OctopusManager } from ".";

@Component
export default class OctopusOptions extends Vue {
  get manager(): OctopusManager {
    // @ts-ignore
    return this.$paymentManager?.octopus;
  }
}

