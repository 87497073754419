import _ from "lodash";
import type { PrintTable } from "./table";

export interface RawLineOpts {
  [key: string]: any;
  align?: string;
  debugLocs?: [number, number, any][];
}

export interface TextLineOpts {
  [key: string]: any;
  align?: string;
  debugLocs?: [number, number, any][];
  pre?: boolean;
}

export class RawLine {
  align: string;
  constructor(
    public buf: Buffer,
    public chars: number,
    public opts: RawLineOpts = {},
  ) {
    this.align = opts.align;
  }
}

export class TextLine {
  align: string;
  body: {
    buf: Buffer;
    length: number;
    word: boolean;
  }[];
  length: number = 0;
  constructor(
    public table: PrintTable,
    public text: string,
    public opts: TextLineOpts = {},
  ) {
    this.text = text ? text.toString() + "" : "";
    this.align = opts.align;
    const body: {
      buf: Buffer;
      length: number;
      word: boolean;
    }[] = [];
    for (let it of this.text.split(/(\s+)/)) {
      if (!it) continue;
      let prev: string[] = [];
      let isMulti = false;

      function flush() {
        if (prev.length) {
          if (isMulti) {
            for (let part of splitText(prev.join(""))) {
              body.push({
                buf: Buffer.from(table.seq.getText(part)),
                length: 2,
                word: false,
              });
            }
          } else {
            body.push({
              buf: Buffer.from(table.seq.getText(prev.join(""))),
              length: prev.length,
              word: true,
            });
          }
          prev = [];
        }
      }

      for (let part of it) {
        const curIsMulti = part >= "\u0100";

        if (isMulti !== curIsMulti) {
          flush();
          isMulti = curIsMulti;
        }

        prev.push(part);
      }
      flush();
    }
    this.body = body;
  }

  split(size: number, wrap = false, align?: string) {
    if (size <= 0 || isNaN(size)) return [];
    const result: RawLine[] = [];
    let buf: Buffer[] = [];
    let len = 0;
    for (let word of this.body) {
      if ((len > 0 && len + word.length > size) || word.buf[0] === 10) {
        result.push(new RawLine(Buffer.concat(buf), len, this.opts));
        buf = [];
        len = 0;
      }
      if (word.buf[0] === 10 || word.buf[0] === 13) continue;
      if (!this.opts.pre && !len && word.buf.length === 1 && word.buf[0] === 0x20) continue;
      while (!len && word.length > size && word.word) {
        // handle too long word
        result.push(new RawLine(word.buf.slice(0, size), size, this.opts));
        word.buf = word.buf.slice(size);
        word.length = word.buf.length;
      }
      buf.push(word.buf);
      len += word.length;
    }
    if (buf.length > 0) result.push(new RawLine(Buffer.concat(buf), len, this.opts));
    if (this.opts.pre && result.length === 0) result.push(new RawLine(Buffer.from(""), 0, this.opts));

    // trim each line if align is set
    if(align) {
      if(align === "left" || align === "center") {
        for(let line of result) {
          let trimIdx = 0;
          while(line.buf[line.chars - trimIdx - 1] === 0x20 || line.buf[line.chars - trimIdx - 1] === 0x09 || line.buf[line.chars - trimIdx - 1] === 0x0A || line.buf[line.chars - trimIdx - 1] === 0x0D || line.buf[line.chars - trimIdx - 1] === 0x00) {
            trimIdx++;
          }
          if (trimIdx) {
            line.buf = line.buf.slice(0, line.chars - trimIdx);
            line.chars -= trimIdx;
          }
        }
      }
      if(align === "right" || align === "center") {
        for(let line of result) {
          let trimIdx = 0;
          while(line.buf[trimIdx] === 0x20 || line.buf[trimIdx] === 0x09 || line.buf[trimIdx] === 0x0A || line.buf[trimIdx] === 0x0D || line.buf[trimIdx] === 0x00) {
            trimIdx++;
          }
          if (trimIdx) {
            line.buf = line.buf.slice(trimIdx);
            line.chars -= trimIdx;
          }
        }
      }
    }

    return result;
  }
}

export function splitText(text: string): string[] {
  try {
    if (typeof Intl !== undefined && typeof (Intl as any).Segmenter !== undefined) {
      const segments = new (Intl as any).Segmenter("en", { granularity: "grapheme" }).segment(text);
      return Array.from(segments, ({ segment }) => segment);
    }
  } catch (e) {
    console.warn(e);
  }

  return text.split("");
}
