import { PrintQueue } from "pos-printer/printQueue";
import { LabelSequence } from "pos-printer/labelSequence";
import Vue from "vue";
import moment from "moment";
import { convertWeight } from "@common/weightUtils";
import { getID } from '@feathers-client'
import { ProductType, ProductGroupType } from "@common/common";
import { ProductInfo, ProductGroupInfo } from "@common/product";

export const labelSizes = [
  {
    key: "default",
    width: 40,
    height: 30
  },
  {
    key: "small",
    width: 30,
    height: 20
  }
] as const;

export type LabelSizeKey = typeof labelSizes[number]["key"];

export type ProductLabelSequenceOption = {
  num?: number;
  sku?: ProductType;
  product?: ProductGroupType;
  weight?: number;
  quantity?: number;
  showNum?: boolean;
  showID?: boolean;
  showDate?: boolean;
  labelSizeKey?: LabelSizeKey;
};

export default async function productSequencer(
  queue: PrintQueue,
  context: Vue,
  opts: ProductLabelSequenceOption
): Promise<LabelSequence> {
  if (opts.labelSizeKey) {
    switch (opts.labelSizeKey) {
      case "default":
        return await defaultSequencer(queue, context, opts);
      case "small":
        return await smallSequencer(queue, context, opts);
    }
  }
  return await defaultSequencer(queue, context, opts);
}

async function defaultSequencer(
  queue: PrintQueue,
  context: Vue,
  opts: ProductLabelSequenceOption
): Promise<LabelSequence> {
  const sequence = queue.createSequence<LabelSequence>();
  const num = opts.num || 1;

  const sku = opts.sku ? new ProductInfo(context as any, opts.sku) : null;
  const product = opts.product ? new ProductGroupInfo(context as any, opts.product) : null;

  let name = context.$td(sku?.item?.fullName ?? product?.item?.name);
  const productId = opts.sku?.productGroup ?? opts.product?._id;

  const params = [Buffer.from(String(productId), "hex")];
  const productDisplayId = opts.sku?.slug ?? opts.product?.slug ?? Buffer.concat(params).toString("base64");

  sequence
    .reset()
    .size(40, 30)
    .gap(1.5, 0);
  let originalPrice = sku?.originalPrice ?? (await product?.getPriceInfo?.())?.originalPrice;
  let price = sku?.generalPrice ?? (await product?.getPriceInfo?.())?.generalPrice;

  if (sku) {
    const b = Buffer.alloc(13);
    b[0] = "s".charCodeAt(0);
    Buffer.from(sku._id, "hex").copy(b, 1);
    params.push(b);

    if (sku.weightType === "weighted" && opts.weight !== undefined) {
      const weightUnit = sku.weightUnit || "kg";
      const weight = opts.weight || 1;
      const kgWeight = convertWeight(weight, weightUnit as any);
      let weightText = weight.toFixed(6);
      if (+weightText !== (+weightText | 0)) weightText = "~" + weightText;
      name = `${weightText}${context.$enum(weightUnit, "weightUnit")} ${name}`;
      if (weightUnit !== "kg") {
        sequence.text(`@ ${kgWeight.toFixed(6)}kg`, 160, 192);
      }
      if (originalPrice) originalPrice = originalPrice * opts.quantity;
      if (price) price = price * opts.quantity;
    }
  }

  sequence.text(name, 12, 12, 296, "center", 2, sequence.chineseFont);
  if (originalPrice && originalPrice !== price) {
    sequence.stroke(true);
    sequence.text(context.$price(originalPrice), 160, 84, 160, "center", 1, "3");
    sequence.stroke(false);
    sequence.text(context.$price(price), 160, 120, 160, "center", 1, "3");
    // if(product.promoTag) {
    //     sequence.text(product.promoTag, 160, 156, 160, "center", 1, "3");
    // }
  } else {
    sequence.text(context.$price(price), 160, 120, 160, "center", 1, "3");
  }

  if (opts.quantity) {
    const b = Buffer.alloc(5);
    b[0] = "q".charCodeAt(0);
    b.writeFloatLE(opts.quantity || 1, 1);
    params.push(b);
  }

  sequence.text(
    [
      ...(opts.showID !== false ? [productDisplayId] : []),
      ...(opts.showDate !== false ? [moment().format("DD/MM/YYYY")] : [])
    ].join(" "),
    12,
    216
  );

  if (opts.showNum !== false) {
    sequence.counter(1, 1, "00001");
    sequence.vtext("@1", 260, 204);
  }

  const productCode = Buffer.concat(params).toString("base64");
  sequence.qrcode(`${context.$qrPrefix}/p/${productCode}`, 12, 64, params.length > 1 ? "M" : "H", 3);
  sequence.print(num);

  return sequence;
}

async function smallSequencer(
  queue: PrintQueue,
  context: Vue,
  opts: ProductLabelSequenceOption
): Promise<LabelSequence> {
  const sequence = queue.createSequence<LabelSequence>();
  const num = opts.num || 1;

  const sku = opts.sku ? new ProductInfo(context as any, opts.sku) : null;
  const product = opts.product ? new ProductGroupInfo(context as any, opts.product) : null;

  let name = context.$td(sku?.item?.fullName ?? product?.item?.name);
  const productId = opts.sku?.productGroup ?? opts.product?._id;

  const params = [Buffer.from(String(productId), "hex")];

  sequence
    .reset()
    .size(30, 20)
    .gap(1.5, 0);
  let price = sku?.generalPrice ?? (await product?.getPriceInfo?.())?.generalPrice;
  let barcode;

  if (sku) {
    name = `${name} ${context.$td(opts.sku.name)}`;
    const b = Buffer.alloc(13);
    b[0] = "s".charCodeAt(0);
    Buffer.from(opts.sku._id, "hex").copy(b, 1);
    params.push(b);
    barcode = opts.sku.specs.find(s => <any>s.spec === <any>context.$shop.posCode);

    if (sku.weightType === "weighted" && opts.weight !== undefined) {
      const weightUnit = sku.weightUnit || "kg";
      const weight = opts.weight || 1;
      let weightText = weight.toFixed(6);
      if (+weightText !== (+weightText | 0)) weightText = "~" + weightText;
      name = `${weightText}${context.$enum(weightUnit, "weightUnit")} ${name}`;
      if (price) price = price * opts.quantity;
    }
  } else {
    barcode = product.specs.find(s => <any>s.spec === <any>context.$shop.posCode);
  }

  sequence.text(name, 6, 4, 222, "center", 2, sequence.chineseFont);

  if (opts.quantity) {
    const b = Buffer.alloc(5);
    b[0] = "q".charCodeAt(0);
    b.writeFloatLE(opts.quantity || 1, 1);
    params.push(b);
  }

  if (barcode && !opts.quantity) {
    sequence.barcode(barcode.value, 40, 62, 30);
    sequence.text(Vue.filter("currency")(context, price), 6, 126, 176, "center", 1, "2");
  } else {
    sequence.text(Vue.filter("currency")(context, price), 110, 80, 116, "center", 1, "2");
    const productCode = Buffer.concat(params).toString("base64");
    sequence.qrcode(`${context.$qrPrefix}/p/${productCode}`, 8, 54, "L", 2);
  }

  sequence.print(num);

  return sequence;
}

export interface ShippingLabelOption {
  logo?: string;
  url?: string;
  num?: number;
  deliveryDate?: string;
  orderIds?: string[];
  phone?: string;
  title?: string;
  site?: string;

  zone?: string;
  district?: string;
  address?: string

  remarks?: string;
}

export async function shippingLabel(
  queue: PrintQueue,
  context: Vue,
  opts: ShippingLabelOption
): Promise<LabelSequence> {
  const sequence = queue.createSequence<LabelSequence>();
  sequence
    .reset()
    .size(70, 100)
    .gap(1, 0);

  // 560 x 800

  const imgSrc =
    opts?.logo || context.$image(getID(context.$shop?.icon)) || require("~/assets/images/logo.png");
  await sequence.printImage(50, 50, imgSrc, 200);
  if (opts.url) {
    sequence.qrcode(opts.url, 50, 400 + 130, "H", 5);
  }
  sequence.counter(1, 1, "01");
  sequence.vtext("@1", 560 - 200, 800 - 80, "3", 2);
  sequence.text(
    "/" + (opts.num || 1).toString().padStart(2, "0"),
    560 - 200 + 64,
    800 - 80,
    undefined,
    undefined,
    undefined,
    "3",
    2
  );

  if (opts.title) {
    sequence.text(opts.title, 280 + 20, 50, undefined, undefined, undefined, sequence.chineseFont, 2);
  }

  if (opts.phone) {
    sequence.text(opts.phone, 280 + 20, 50 + 100, undefined, undefined, undefined, sequence.chineseFont, 1);
  }

  if (opts.site) {
    sequence.text(
      opts.site,
      280 + 20,
      50 + 100 + 24,
      undefined,
      undefined,
      undefined,
      sequence.chineseFont,
      1
    );
  }
  sequence.text(
    opts.remarks,
    280 + 20,
    50 + 100 + 48,
    undefined,
    undefined,
    undefined,
    sequence.chineseFont,
    1
  );

  sequence.rect(50, 250, 460, 2);

  sequence.text(
    [opts.zone, opts.district].filter(it => !!it).join(" / "),
    50,
    250 + 20,
    undefined,
    undefined,
    undefined,
    sequence.chineseFont,
    2
  );

  sequence.text(
    opts.address,
    50,
    250 + 70,
    460,
    undefined,
    undefined,
    sequence.chineseFont,
  );

  if (opts.orderIds?.length) {
    sequence.text(
      opts.orderIds.join(" "),
      50,
      250 + 20 + 70 + 40,
      460,
      undefined,
      2,
      sequence.chineseFont,
      2
    );
  }

  if (opts.deliveryDate) {
    sequence.text(
      `運送日期: ${opts.deliveryDate}`,
      50,
      480,
      undefined,
      undefined,
      undefined,
      sequence.chineseFont,
      1
    );
  }

  sequence.rect(50, 510, 460, 2);
  sequence.print(opts.num ?? 1);

  return sequence;
}
