import { Vue, Component, Watch } from "@feathers-client";
import type { PosHelper } from "../pos";

export interface PositionType {
  areaId: number;
  mapId: number;
  name: string;
  noiseRadius: number;
  pos: [number, number, number];
  rxAnchorId: number;
  rxAnchorSn: string;
  rxRssi: number;
  sn: string;
  sourceId: number;
  time: number;
  _id: number;
}

export interface FenceType {
  _id: number;
  eventTimeMs: number;
  eventStatus: number;
  entityId: number;
  entityCategory: string;
  entityName: string;
  pos: [number, number, number];
  tagId: number;
  mapId: number;
  mapName: string;
  fenceId: number;
  fenceName: string;
}

@Component
export class IPSManager extends Vue {
  connected = false;
  position: [number, number, number] = null;
  areaId: number = null;
  mapId: number = null;

  lastDirPos: [number, number, number] = null;
  direction: number = null;

  fenceId: number = null;
  fenceName: string = null;

  get parent() {
    return this.$parent as PosHelper;
  }

  created() {
    (this.$feathers as any).on("connected", this.init);
    (this.$feathers as any).on("disconnected", this.disconnect);
    this.$feathers.service("shop/ips/position").on("created", this.onPosition);
    this.$feathers.service("shop/ips/fence").on("created", this.onFence);
  }

  disconnect() {
    this.connected = false;
  }

  @Watch("parent.cashier.ipsTag")
  async init() {
    this.connected = false;
    if (!this.$shop?._id) return;
    if (!this.parent?.cashier?._id || !this.parent?.cashier?.ipsTag) return;

    try {
      const ips = await this.$feathers.service("shop/ips/subscribe").create({
        tag: this.parent.cashier.ipsTag,
      });
      this.connected = true;
    } catch (e) {
      console.warn(e);
    }
  }

  onPosition(position: PositionType) {
    if (`${position._id}` !== this.parent.cashier.ipsTag) return;
    this.position = position.pos;
    this.areaId = position.areaId;
    if (!this.lastDirPos || distance(this.lastDirPos, position.pos) > 0.5) {
      if (this.lastDirPos) {
        this.direction = (Math.atan2(position.pos[1] - this.lastDirPos[1], position.pos[0] - this.lastDirPos[0]) * 180 / Math.PI) | 0;
      }
      this.lastDirPos = position.pos;
    }

    if (this.mapId !== position.mapId) {
      this.mapId = position.mapId;
      this.$emit("mapChanged", position.mapId);
    }
  }

  onFence(fence: FenceType) {
    if (`${fence._id}` !== this.parent.cashier.ipsTag) return;
    if (this.fenceId !== fence.fenceId) {
      this.fenceId = fence.fenceId;
      this.fenceName = fence.fenceName;
      this.$emit("fenceChanged", fence.fenceId);

      const ads = this.$pos.directZoneAds[fence.fenceId];
      if (ads?.length) {
        this.$pos.nextAd = ads[(Math.random() * ads.length) | 0];
      }
    }
  }
}

function distance(a: [number, number, number], b: [number, number, number]) {
  const x = a[0] - b[0];
  const y = a[1] - b[1];
  return Math.sqrt(x * x + y * y);
}
