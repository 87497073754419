

import Vue, { FunctionalComponentOptions, VNode } from 'vue'

export default <FunctionalComponentOptions>{
    functional: true,
    render(_c, ctx) {
        const { props, parent } = ctx;
        return props.render ? props.isSlot ? props.render(props.useContext ? ctx : props) : props.render(_c, props.useContext ? ctx : props) : parent._e();
    }
};

