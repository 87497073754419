
import { Component, Vue, Prop, Watch, Emit } from "@feathers-client";
// @ts-ignore
import type MenuListPicker from "./MenuListPicker.vue";
// @ts-ignore
import CheckIcon from "../components-internal/CheckIcon.vue";

@Component({
  components: {
    CheckIcon,
  },
})
export default class MenuListPickerItem extends Vue {
  @Prop()
  parent: MenuListPicker;

  @Prop(Boolean)
  focused: boolean;

  @Prop()
  selected: number;

  @Prop()
  item: any;
}
