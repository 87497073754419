import { ns } from "../messageQueue";
import { EventEmitter } from "events";
import { getVersion } from "../nativeIntegrations";

export interface UsbDeviceInfo {
  name: string;
  vendorId: number;
  productId: number;
}

export interface InterfaceInfo {
  address: string;
  mac: string;
  netmask: string;
}

export function interfaces() {
  return ns("socket").call<InterfaceInfo[]>("interfaces");
}

export function ipv4ToNum(address: string) {
  const parts = address.split(".").map(it => +it);
  if (parts.length !== 4 || parts.find(it => isNaN(it) || it < 0 || it > 255)) {
    throw new Error("Invalid ipv4");
  }
  return Buffer.from(parts).readUInt32BE(0);
}

export function numToIPV4(address: number) {
  const buf = Buffer.alloc(4);
  buf.writeUInt32BE(address, 0);
  return Array.from(buf).join(".");
}

export function* getAddresses(iface: InterfaceInfo) {
  const mask = ipv4ToNum(iface.netmask || "255.255.255.0");
  const ip = ipv4ToNum(iface.address);
  const count = (mask & -mask) - 1;

  for (let i = 1; i < count; i++) {
    yield numToIPV4(((ip & mask) | i) >>> 0);
  }
}

export function isLocal(iface: InterfaceInfo) {
  if (iface.address >= "192.168." && iface.address <= "192.169.") {
    return true;
  }
  if (iface.address >= "172.16." && iface.address <= "172.32.") {
    return true;
  }
  // skip 24 bit block
  return false;
}

export class SocketDevice extends EventEmitter {
  closed = false;
  handle: string;
  dhandle: string;

  constructor(public id: string) {
    super();
    this.handle = ns("socket").on(`close-${id}`, d => {
      if (!this.handle) return;
      this.close();
    });
    this.dhandle = ns("socket").on(`data-${id}`, d => {
      this.emit("data", Buffer.from(new Uint8Array(d)));
    });
  }

  async send(data: Buffer) {
    const buf = Array(data.length);
    for (let i = 0; i < data.length; i++) buf[i] = data[i];
    await ns("socket").call("send", this.id, buf);
  }

  async flush() {
    await ns("socket").call("flush", this.id);
  }

  async close() {
    if (this.closed) return;
    this.closed = true;
    ns("socket").off(this.handle);
    ns("socket").off(this.dhandle);
    this.handle = null;
    this.dhandle = null;
    this.emit("close");
    await ns("socket").call("close", this.id);
  }
}

export async function connect(host: string, port: number) {
  return new SocketDevice(await ns("socket").call("connect", host, port));
}

export async function mdns(query?: MDNSQuery) {
  return (await ns("socket").call("mdns", query)) as MDNSResponse[];
}

export async function scanner(port: number, opts?: SocketScannerOptions) {
  return new SocketScanner(await ns("socket").call("scanner", port, opts));
}

export interface SocketScannerOptions {
  ignoreAddresses?: string[];
  concurrency?: number;
  interval?: number;
}

export interface MDNSQuery {
  name?: string;
  timeout?: number;
}

export interface MDNSResponse {
  host: string;
  ip?: string;
  port: number;
  txt: Record<string, string>;
}

export function supported() {
  if (!getVersion()) return Promise.resolve(false);
  return Promise.race([
    ns("socket").call<boolean>("supported"),
    new Promise<boolean | string[]>(resolve => setTimeout(() => resolve(false), 15000)),
  ]).catch(e => false);
}

export class SocketScanner extends EventEmitter {
  handle: string;
  dhandle: string;
  started = false;

  constructor(public id: string) {
    super();
    this.handle = ns("socket").on(`scanner/connection/${id}`, d => {
      if (!this.id) {
        ns("socket").call("close", d.id);
        return;
      }
      this.emit("connection", {
        socket: new SocketDevice(d.id),
        host: d.host,
        port: d.port,
      });
    });
    this.dhandle = ns("socket").on(`scanner/end/${id}`, d => {
      if (!this.id) return;
      this.emit("end");
    });
  }

  async start() {
    if (this.started) return;
    this.started = true;
    return ns("socket").call("startScanner", this.id);
  }

  stop() {
    if (!this.id) return;
    ns("socket").call("stopScanner", this.id);
  }
}
