
import _ from "lodash";
import {
  Component,
  Vue,
  mixins,
  Prop,
  FindType,
  FindPopRawType,
  Watch,
  checkID,
  getID
} from "@feathers-client";
import Dialog from "@feathers-client/mixins/Dialog";
type ShipmentType = FindType<"shop/shipping/shipments">;
import Printer from "~/mixins/Printer";
import { LabelSequence } from "pos-printer/labelSequence";
import { shippingLabel } from "~/plugins/printer/label";
import { getUrl } from "~/plugins/shortCodeUtils";

@Component
export default class ShipmentScheduleLabelDialog extends mixins(Dialog, Printer("label", "label")) {
  num = 1;

  @Prop()
  item: ShipmentType;

  beforeMount() {
    this.num = (this.item.metadata?.num ?? this.item.containers?.length) || 1;
  }

  async save() {
    try {
      const resp = await this.$feathers.service("shop/shipping/shipments/update").create({
        id: this.item._id,
        shipment: {
          "metadata.num": this.num
        } as any
      });
      Object.assign(this.item, resp);
      this.modalResult(true);
    } catch (e) {
      this.$store.commit("SET_ERROR", e.message);
    }
  }

  async print() {
    try {
      await this.setupPrinter();
      const resp = await this.$feathers.service("shop/shipping/shipments/update").create({
        id: this.item._id,
        shipment: {
          "metadata.num": this.num
        } as any
      });
      Object.assign(this.item, resp);

      const orders = await this.$feathers.service("shop/orders").find({
        query: {
          $paginate: false,
          _id: {
            $in: Array.from(new Set(this.item.products.map(it => it.order)))
          },
          $select: ["_id", "orderId"]
        },
        paginate: false
      });

      const sequence = await shippingLabel(this.printer, this, {
        url: getUrl(this, "shipment", this.item._id).url,
        num: this.num,
        deliveryDate: this.item.deliveryDate,
        orderIds: orders.map(it => it.orderId || getID(it._id)),
        phone: this.item.address?.phone,
        title: this.$td(this.$shop.name),
        site: this.$shop.posInvoiceDomain,

        zone: "Zone A",
        district: this.item.address?.district,
        address: this.item.address?.address,

        remarks: ""
      });

      await this.printer.print(sequence.getJob("label"));
      this.modalResult(true);
    } catch (e) {
      this.$store.commit("SET_ERROR", e.message);
    }
  }
}
