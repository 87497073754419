import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import _ from "lodash";
import ComputeProvider from "./ComputeProvider";
import qs from "qs";
import { DataTableHeader } from "../index";

declare const API_URL: string;

export function $thumb(this: any, item: any) {
  let url: string;
  if (this && this.$config && this.$config.apiUrl) {
    url = this && this.$config.apiUrl;
  } else {
    url = API_URL;
  }
  if (item && typeof item === "string" && item.startsWith("data:")) {
    return item.split(",")[1];
  }
  if (item) return `${url}/api/thumbs/${item._id || item}`;
  return "";
}

export function $image(this: any, item: any) {
  let url: string;
  if (this && this.$config && this.$config.apiUrl) {
    url = this && this.$config.apiUrl;
  } else {
    url = API_URL;
  }
  if (item) return `${url}/api/attachments/${item._id || item}`;
  else this.$config.appLogo || require("~/assets/images/logo.png");
  return null;
}

export function getPathAdv(item: any, path: string | string[] | ((item: any) => any)) {
  if (typeof path === "function") {
    return path(item);
  } else if (Array.isArray(path)) {
    for (let i = 0; i < path.length; i++) {
      const k = path[i];
      if (k === "*") {
        if (Array.isArray(item)) {
          if (i + 1 < path.length) {
            return item.map(it => getPathAdv(it, path.slice(i + 1)));
          } else return item;
        } else return [];
      } else if (item != null) {
        item = item[k];
      }
    }
    return item;
  } else {
    return _.get(item, path);
  }
}

@Component
export default class HeaderProvider extends mixins(ComputeProvider) {
  fetchItems: {
    source: string;
    select?: string[];
    header: DataTableHeader;
    prefix: string;
    id: string;
    itemKey: string;
  }[] = null;
  _fetchCache: {
    [key: string]: any;
  } = {};
  // for creating reactivity
  currentFetch: any = null;
  pendingsFetches: any[] = null;

  @Prop()
  feathers: any;

  get mfeathers(): any {
    return this.feathers || this.$feathers;
  }

  get(item: any, header: DataTableHeader, objectOnly?: boolean) {
    if (header.debug) {
      debugger;
    }
    const srcItem = header.computed ? this.getComputed(item) : item;
    const headerValue = header.headerValue === undefined ? header.value : header.headerValue;
    const value = headerValue ? getPathAdv(srcItem, headerValue) : srcItem;
    let list = header.multiple ? value || [] : [value];

    if (header.unique) {
      list = Array.from(new Set(list));
    }

    let limitReached = false;

    if (header.limit && list.length > header.limit) {
      list = list.slice(0, header.limit);
      limitReached = true;
    }

    if (list && !Array.isArray(list)) {
      list = [list];
    }

    const values = list.map(value => {
      const pathList = header.paths
        ? header.paths
        : [
            {
              path: header.path === null ? header.path : header.path || "name",
              format: header.format,
            },
          ];

      const fetchAll = !!pathList.find(it => it.path === null);
      const prefix = pathList.map(it => it.path).join("/") + "_" || "";
      const itemKey = header.itemKey || "_id";
      const fetchSelect = header.select || fetchAll ? undefined : [itemKey, ...pathList.map(it => it.path)];

      let sitem: any = null;
      if (header.source) {
        if (!this._fetchCache) this._fetchCache = {};
        sitem = this._fetchCache[prefix + value];
        if (sitem === undefined) {
          this._fetchCache[prefix + value] = this.currentFetch = { value: null };
          this.queuePending();
          if (value) {
            this.fetchItems.push({
              source: header.source instanceof Function ? header.source(item) : header.source,
              header,
              prefix,
              id: value,
              select: fetchSelect,
              itemKey,
            });
          }
          if (objectOnly || header.objectOnly) return null;
        } else if (sitem?.value === null) {
          if (objectOnly || header.objectOnly) return null;
          sitem = null;
        } else {
          sitem = sitem?.value;
        }
      }

      if (typeof value !== "number" && typeof value !== "boolean" && !value) {
        return value;
      }

      const results = pathList.map(pathItem => {
        let cur = value;
        if (sitem) {
          cur = pathItem.path !== null ? _.get(sitem, pathItem.path) : sitem;
        }
        if (pathItem.format) {
          const filter = typeof pathItem.format === "function" ? pathItem.format : Vue.filter(pathItem.format);
          if (filter) {
            cur = filter(this, cur, item, (<any>this).mvalue, header);
          } else {
            console.warn(`Vue filter ${pathItem.format} not found`);
          }
        }
        return cur;
      });

      if (objectOnly || header.objectOnly) {
        return results[0];
      } else {
        return results.filter(it => typeof it === "number" || !!it).join("/");
      }
    });
    if (objectOnly || header.objectOnly) {
      return header.multiple ? values : values[0];
    } else {
      if (limitReached) {
        values.push("...");
      }
      return values.map(it => (it === undefined ? "" : it)).join(",");
    }
  }

  queuePending() {
    if (!this.fetchItems) {
      this.fetchItems = [];
      if (!this.pendingsFetches) this.pendingsFetches = [];
      const finalize = () => {
        const idx = this.pendingsFetches.indexOf(fetchPromise);
        idx !== -1 && this.pendingsFetches.splice(idx, 1);
      };
      const fetchPromise = (async () => {
        await Vue.nextTick();
        const f = this.fetchItems;
        this.fetchItems = null;
        const types = _.groupBy(f, it => it.prefix + it.source);
        await Promise.all(
          _.map(types, async (it, source) => {
            const chunks = _.chunk(it, 100);
            const prefix = it[0].prefix;
            const header = it[0].header;
            const service = this.mfeathers.service(it[0].source);
            const itemKey = it[0].itemKey;

            for (let chunk of chunks) {
              const ids = chunk.map(i => i.id);
              try {
                const items = await service.find({
                  query: {
                    [itemKey]: {
                      $in: ids,
                    },
                    ...header.filter,
                    $limit: 100,
                    $populate: header.populate,
                    $select: it[0].select,
                    // $disableSoftDelete: true,
                  },
                });
                const itemData = Array.isArray(items) ? items : items?.data ?? [];
                for (let item of itemData) {
                  const store = this._fetchCache[prefix + item[itemKey]];
                  if (store) store.value = item;
                }
              } catch (e) {
                console.warn(`Error fetching ${it[0].source}: ${itemKey} = ${ids.join(",")}`, e);
              }
            }
          }),
        );
      })().then(finalize, finalize);
      this.pendingsFetches.push(fetchPromise);
    }
  }

  getLink(item, header: DataTableHeader) {
    // @ts-ignore
    if (header.noLink || header.multiple || !this.$config?.features?.noTableLink || this.$features?.noTableLink)
      return undefined;
    let value, source;
    if (header.linkSource) {
      value = getPathAdv(item, header.linkValue || header.value);
      source = header.linkSource;
      source = source instanceof Function ? source(item) : source;
      if (!source) return undefined;
    } else if (header.source) {
      source = header.source instanceof Function ? header.source(item) : header.source;
      value = getPathAdv(item, header.value);
      const pathList = header.paths
        ? header.paths
        : [
            {
              path: header.path === null ? header.path : header.path || "name",
              format: header.format,
            },
          ];
      const prefix = pathList.map(it => it.path).join("/") + "_" || "";
      const itemKey = header.itemKey || "_id";
      if (value && typeof value === "object") value = value[itemKey];

      const store = this._fetchCache?.[prefix + item[itemKey]];
      if (store?.value?.deleted) return undefined;
    } else return undefined;

    if (header.direct) {
      return `/${source}${header.trailingSlash ?? true ? "/" : ""}edit/${value}`;
    }
    if (!value) return undefined;
    return `/${source}${header.trailingSlash ?? true ? "/" : ""}?${qs.stringify({ query: { editor: value } })}`;
  }

  async waitPending() {
    if (!this.pendingsFetches) return false;
    await Promise.all(this.pendingsFetches);
    return true;
  }

  clearCacheUsage(thresold: number, trimTo: number) {
    if (!this._fetchCache) return;
    const len = Object.keys(this._fetchCache).length;
    if (len > thresold) {
      const keyToTrim = Object.keys(this._fetchCache).slice(0, len - trimTo);
      for (let key of keyToTrim) {
        delete this._fetchCache[key];
      }
    }
  }

  renderItem(_c, { item, header }: { item: any; header: DataTableHeader }) {
    let value = header.type === "multi" ? this.get(item, header, true) : this.get(item, header);
    const link = this.getLink(item, header);

    let mitem;
    switch (header.type) {
      case "thumbURL":
        if (value) {
          mitem = _c("img", {
            attrs: {
              src: value,
            },
            style: {
              width: "50px",
              padding: "1px",
            },
          });
        } else {
          mitem = this._v(this._s(""));
        }
        break;
      case "thumb":
        if (typeof value === "string" && value.indexOf(",") !== -1) {
          value = value.split(",")[0];
        }
        mitem = _c("img", {
          attrs: {
            src: (this as any).$imgHelper?.thumb?.(value) || ((<any>this).$thumb?.(value) ?? $thumb.call(this, value)),
          },
          style: {
            width: "50px",
            padding: "1px",
          },
        });
        break;
      case "thumbItem":
        mitem = _c("img", {
          attrs: {
            src: (this as any).$imgHelper?.thumbURL?.(value) ?? (<any>this).$thumb?.(value) ?? $thumb.call(this, value),
          },
          style: {
            width: "50px",
            padding: "1px",
          },
        });
        break;
      case "custom":
        mitem = this.$scopedSlots?.[header.slot]?.({
          item,
          header,
          value,
        });
        break;
      case "multi": {
        if (header.multiple) {
          if (!value?.length) return;
          return _c(
            "div",
            {
              staticClass: "table-item-sep",
            },
            (value || []).map(it => {
              return _c(
                "div",
                {
                  staticClass: "table-header-sep",
                },
                header.inner.map(h => {
                  return _c("div", [
                    this.renderItem(_c, {
                      item: it,
                      header: h,
                    }),
                  ]);
                }),
              );
            }),
          );
        } else if (value) {
          return _c(
            "div",
            {
              staticClass: "table-header-sep",
            },
            header.inner.map(h => {
              return _c("div", [
                this.renderItem(_c, {
                  item: value,
                  header: h,
                }),
              ]);
            }),
          );
        }
        break;
      }
      default:
        mitem = this._v(this._s(value));
        break;
    }

    return link
      ? _c(
          "nuxt-link",
          {
            attrs: {
              to: link,
            },
            staticClass: "primary--text",
            style: "text-decoration: none !important",
          },
          Array.isArray(mitem) ? mitem : [mitem],
        )
      : mitem;
  }
}
