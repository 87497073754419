
import EditorList from "@schemaEditor/EditorList.vue";
import { Component, Prop, VModel, Vue, Watch, Ref } from "@feathers-client";

@Component({
  components: {
    EditorList,
  },
})
export default class contactFieldEditor extends Vue {
  @VModel()
  inputValue: {
    contactField: string;
    contactFieldValue?: string[];
    value?: any;
  }[];

  hasContactField(id: any) {
    return (this.inputValue ?? []).find(it => it.contactField === id);
  }

  addContactField(item) {
    this.inputValue.push({
      contactField: item,
      contactFieldValue: [],
      value: null,
    });
  }

  adding = false;

  addFunc() {
    this.adding = !this.adding;
  }

  searchKeyword = "";
  query: any = {};
  @Ref()
  search: HTMLInputElement;

  goSearch() {
    this.search?.blur?.();
    this.query = {
      ...this.query,
      $keyword: this.searchKeyword,
    };
  }

  selectAll() {
    this.search?.select?.();
  }
}
