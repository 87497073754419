
import { Component, Prop, Vue, Watch, mixins, VModel, Ref } from "nuxt-property-decorator";
import { lowSpec } from "../util";

@Component
export default class NestedRouter extends Vue {
  @Prop({ type: String, default: "default" })
  rootPage: string;

  @Prop({ default: lowSpec ? "" : "slide" })
  transition: string;

  @Prop()
  contentClass: string;

  @Prop(Boolean)
  pageMode: boolean;

  reversed = false;
  resetDirection() {
    if (this.reversed) {
      this.reversed = false;
      this.$emit('unload', this.pages.pop());
    }
  }

  pages: [string, any][] = [];

  get currentPage() {
    return this.pages[this.pages.length - 1];
  }

  @Watch("currentPage")
  onCurrentPage(v) {
    this.$emit("update:currentPage", v?.[0]);
    this.$emit("update:currentPageParams", v);
  }

  created() {
    this.pages.push([this.rootPage, {}]);
  }

  navigate(to?: string | [string, any], singleInstance = false) {
    if (!to) {
      if (this.pages.length > 1) {
        this.reversed = true;
      } else {
        this.$emit("close");
      }
    } else {
      const toKey = Array.isArray(to) ? to[0] : to;
      const props = Array.isArray(to) ? to[1] : {};
      if (this.reversed) {
        this.$emit('unload', this.pages.pop());
        this.reversed = false;
      }
      if (singleInstance) {
        const idx = this.pages.findIndex(it => it[0] === toKey);
        idx !== -1 && this.pages.splice(idx, 1);
      }
      this.pages.push([toKey, props]);
    }
  }
  
  reset(to?: string | [string, any], singleInstance = false) {
    this.pages.splice(0, this.pages.length);
    this.reversed = true;
    this.navigate(to, singleInstance);
  }
}
