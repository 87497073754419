import { Component, Vue, Prop } from "@feathers-client";
import { EspDevice, EspDeviceSerial } from "../esp32";
import SerialDevice, { connect, portPicker, SerialPortSelection } from "../ports/serial";
export * from "./enum";
import { ns } from "../messageQueue";
import { RazerPayBody, RazerPayHeader, RazerPayMessage, RazerPayTransportHeader } from "./interface";
import { CardEntryMode, customFieldCodeToField, fieldCodeToField, fieldToFieldCode, responseCodes } from "./enum";
import _ from "lodash";

export interface RazerPayConfig {
  serial?: SerialPortSelection;
}

@Component
export class RazerPayManager extends Vue {
  status: "notSetup" | "connected" | "disconnected" = "notSetup";

  created() {
    this.connect().catch(console.error);
  }

  @Prop()
  getSetting: () => RazerPayConfig;

  @Prop()
  setSetting: (v: RazerPayConfig) => void;

  @Prop()
  whenDestroy: () => void;

  beforeDestroy() {
    this.whenDestroy?.();
  }

  get settings() {
    return this.getSetting?.();
  }

  set settings(v) {
    this.setSetting?.(v);
  }

  get serialConfig() {
    return this.settings?.serial;
  }

  set serialConfig(s) {
    this.settings = {
      ...(this.settings || {}),
      serial: s,
    };
  }

  needReconnect: boolean;
  serial: SerialDevice = null;
  webSerial: any = null;
  webSerialWriter: any = null;
  espSerial: EspDeviceSerial = null;

  async openSettings() {
    return await this.$openDialog(
      // @ts-ignore
      import("./RazerPayDialog.vue"),
      {
        manager: this,
      },
      {
        maxWidth: "80%",
        contentClass: "editor-dialog",
      },
    );
  }

  async connect(user?: boolean, reconnect?: boolean) {
    if (!reconnect && this.serial) {
      return;
    }
    const device = await portPicker(this, reconnect ? undefined : this.serialConfig, user, undefined, "TurnCloud", "Razer");
    if (device) {
      this.disconnect();
      switch (device.type) {
        case "serial": {
          this.serialConfig = device;
          const serial = (this.serial = await connect(device.port, {
            baudRate: 9600,
          }));
          this.serial.on("data", buf => {
            this.serialInput(buf);
          });
          this.serial.on("close", () => {
            if (this.serial === serial) {
              this.disconnect();
            }
          });
          break;
        }
        case "webSerial": {
          this.serialConfig = {
            type: "webSerial",
          };
          this.webSerial = device.device;
          try {
            this.webSerial.addEventListener("disconnect", () => {
              if (this.webSerial === device.device) {
                this.disconnect();
              }
            });
            await this.webSerial.open({
              baudRate: 9600,
            });
            this.webSerialWriter = this.webSerial.writable.getWriter();
            const reader = this.webSerial.readable.getReader();
            const task = (async () => {
              while (true) {
                const data = await reader.read();
                this.serialInput(data.value);
              }
            })();
            task.catch(e => {
              console.error(e);
              if (this.webSerial === device.device) {
                this.disconnect();
              }
            });
          } catch (e) {
            if (this.webSerial === device.device) {
              this.disconnect();
            }
          }
          break;
        }
        case "espSerial": {
          this.serialConfig = {
            type: "espSerial",
            espConfig: device.espConfig,
          };
          const serial = (this.espSerial = (device.device as EspDevice).getSerial());
          this.espSerial.on("data", buf => {
            if (this.espSerial === serial) {
              this.serialInput(buf);
            }
          });
          this.espSerial.on("close", () => {
            if (this.espSerial === serial) {
              this.disconnect();
            }
          });
          break;
        }
      }
      if (this.hasConnection) {
        this.status = "connected";
      }
    }
  }

  async disconnect(save?: boolean) {
    if (this.serial) {
      this.serial.removeAllListeners("data");
      this.serial.close();
      this.serial = null;
    }
    if (this.webSerial) {
      try {
        await this.webSerial.close();
      } catch (e) {
        console.warn(e);
      }
      try {
        await this.webSerial.forget();
      } catch (e) {
        console.warn(e);
      }
      this.webSerial = null;
      this.webSerialWriter = null;
    }
    if (this.espSerial) {
      this.espSerial.removeAllListeners("data");
      this.espSerial.close();
      this.espSerial = null;
    }
    if (save) {
      this.serialConfig = null;
    }
    if (this.status === "connected") {
      this.status = "disconnected";
      this.needReconnect = true;
    }
  }

  get hasConnection() {
    return !!(this.serial || this.webSerial || this.espSerial);
  }

  async waitReady(forceUser?: boolean | "background", timeout = 0, signal?: AbortSignal) {
    if (!this.serialConfig) {
      await this.openSettings();
    }

    if (!this.hasConnection && forceUser !== "background") {
      await this.connect(true);
    }
    // @ts-ignore
    signal?.throwIfAborted?.();
    if (!this.hasConnection && forceUser !== "background") {
      throw new Error("Razer Not connected");
    }
    let cancelReject: (e: Error) => void;
    const cancelHandler = () => {
      cancelReject?.(new Error("Aborted"));
    };
    if(signal) {
      signal.addEventListener("abort", cancelHandler);
    }
    while (this.status !== "connected") {
      // @ts-ignore
      signal?.throwIfAborted?.();
      await new Promise((resolve, reject) => {
        this.$once("connected", resolve);
        if (timeout) {
          setTimeout(() => reject(new Error("Timeout connecting")), timeout);
        }
        cancelReject = reject;
      });

      await new Promise(resolve => setTimeout(resolve, 10));
      break;
    }
  }

  currentTransaction: Promise<RazerPayMessage> = null;
  currentResolve: { resolve: (v: RazerPayMessage) => void; reject: (e: Error) => void; ack?: () => void, nak?: () => void } = null;

  _prevBuf: number[] = null;
  _recvTimeout: any;
  _expectLRC: boolean = false;
  private serialInput(buf: Buffer) {
    for (let i = 0; i < buf.length; i++) {
      const c = buf[i];
      if (this._prevBuf) {
        // this.resetTimeout();
        // receving data
        this._prevBuf.push(c);
        if (this._prevBuf.length <= 3) {
          continue;
        }
        const len = bcdToInt(Buffer.from(this._prevBuf).subarray(1, 3)) + 3;
        
        if (this._expectLRC) {
          // LRC
          this._expectLRC = false;
          const prevBuf = Buffer.from(this._prevBuf);
          this._prevBuf = null;
          console.log("[razer] received packet", prevBuf.toString('hex'));
          if (this._checkMessage(prevBuf)) {
            this._recvTimeout && clearTimeout(this._recvTimeout);
            this._recvTimeout = null;
            this.processMessage(prevBuf.slice(1, prevBuf.length - 3));
            this.ack();
          } else {
            console.warn("Invalid LRC", prevBuf.toString("hex"));
            this._recvTimeout && clearTimeout(this._recvTimeout);
            this._recvTimeout = null;
            this.nak();
          }
        } else if (this._prevBuf.length < len) {
          // razer can send hex
          continue;
        } else if (c === 0x02 || c === 0x06 || c === 0x15) {
          console.warn("Unexpected ", c, this._prevBuf);
        } else if (c === 0x03) {
          // ETX
          this._expectLRC = true;
        }
      } else if (c === 0x02) {
        this._prevBuf = [c];
        // this.resetTimeout();
      } else if (c === 0x06) {
        console.log("[razer] ACK");
        if (this.currentResolve?.ack) {
          this.currentResolve.ack();
        }

      } else if (c === 0x15) {
        console.log("[razer] NAK");
        if (this.currentResolve?.nak) {
          this.currentResolve.nak();
        }
      }
    }
  }

  _checkMessage(buf: Buffer) {
    const length = bcdToInt(Buffer.from(buf).subarray(1, 3)) + 5;
    const lrc = calculateLRC(buf.slice(1, buf.length - 1));
    console.log("LRC", lrc, buf[buf.length - 1]);
    console.log("Length", length, buf.length);
    return length === buf.length && lrc[0] === buf[buf.length - 1];
  }

  async ack() {
    try {
      const ack = Buffer.from([0x06]);
      await this.serialSend(ack);
    } catch (error) {
      console.log(error);
    }
  }

  async nak() {
    try {
      const nak = Buffer.from([0x15]);
      await this.serialSend(nak);
    } catch (error) {
      console.log(error);
    }
  }

  processMessage(payload: Buffer) {
    const message = this.decode(payload);
    console.log(message);
    if(message?.header?.transactionCode === "XX") {
      // skip processing cancel
      return;
    }
    if (this.currentResolve) {
      this.currentResolve.resolve(message);
      this.currentResolve = null;
    }
  }

  async serialSend(buf: Buffer) {
    if (this.serial) {
      await this.serial.send(buf);
    }
    if (this.webSerialWriter) {
      await this.webSerialWriter.write(buf);
    }
    if (this.espSerial) {
      await this.espSerial.write(buf);
    }
  }

  async doTransaction(transaction: Partial<RazerPayMessage>, verify = true, checkCode = true, signal?: AbortSignal) {
    while (this.currentTransaction) {
      // @ts-ignore
      signal?.throwIfAborted?.();
      await this.currentTransaction;
    }
    const task = this.doTransactionInner(transaction, verify, checkCode, signal);
    this.currentTransaction = task;
    try {
      return await task;
    } finally {
      if (this.currentTransaction === task) {
        this.currentTransaction = null;
      }
    }
  }

  async doTransactionInner(transaction: Partial<RazerPayMessage>, verify = true, checkCode = true, signal?: AbortSignal) {
    console.log("Request", transaction);
    const payload = this.encode(transaction);
    const final = Buffer.from(payload);
    console.log("Request Buf", final);

    let resp: RazerPayMessage;

    if(signal) {
      signal.addEventListener("abort", () => {
        this.cancelPayment(transaction.body.transactionId).catch(console.error);
      });
    }

    const promise = new Promise<RazerPayMessage>((resolve, reject) => {
      let ackTimer = setTimeout(() => {
        ackTimer = null;
        cleanUp();
        reject(new RazerPayConnectionError("ACK Timeout"));
      }, 5000);

      let paymentTimer = setTimeout(() => {
        paymentTimer = null;
        cleanUp();
        reject(new Error("Payment Timeout"));
      }, 155000); // Required by razer guys

      if (!verify) resolve(null);

      function cleanUp() {
        if (paymentTimer) {
          clearTimeout(paymentTimer);
          paymentTimer = null;
        }
        if (ackTimer) {
          clearTimeout(ackTimer);
          ackTimer = null;
        }
      }

      this.currentResolve = {
        resolve(e) {
          cleanUp();
          resolve(e);
        },
        reject(e) {
          cleanUp();
          reject(e);
        },
        ack() {
          if (ackTimer) {
            clearTimeout(ackTimer);
            ackTimer = null;
          }
        },
        nak: () => {
          if (ackTimer) {
            clearTimeout(ackTimer);
            ackTimer = null;
          }
          console.log("Resending", final.toString("hex"));
          this.serialSend(final);
          ackTimer = setTimeout(() => {
            ackTimer = null;
            cleanUp();
            reject(new RazerPayConnectionError("ACK Timeout"));
          }, 5000);
        }
      };
    });

    console.log("Sending", final.toString("hex"));

    await this.serialSend(final);

    resp = await promise;

    if (checkCode && resp?.header?.responseCode !== "00") {
      throw new RazerPayError(
        resp,
        "Transaction failed: " + resp?.header?.responseCode
          ? responseCodes[resp.header.responseCode] ?? resp.header.responseCode
            : "unknown",
      );
    }

    return resp;
  }

  async cancelPayment(transactionId: string) {
    try {
      const cancelHeader = new RazerPayHeader();
      cancelHeader.transactionCode = "XX";
      const cancelPayload: Partial<RazerPayMessage> = {
        header: cancelHeader,
        body: {
          transactionId,
        },
      };
      const payload = this.encode(cancelPayload);
      const final = Buffer.from(payload);
      await this.serialSend(final);
    } catch (error) {
      console.warn('[razer] cannot cancel razer payment', error);
    }
  }

  decode(buf: Buffer): RazerPayMessage {
    const result =  {
      ...this.decodeHeader(buf),
      ...this.decodeBody(buf),
    };
    if (result.body) {
      const maskedPan = result.body?.maskedPan || "";
      result.formattedBody = {
        expiryDate: (result.body?.expiryDate?.match(/.{1,2}/g) || []).join('/'),
        transactionDate: (result.body?.transactionDate?.match(/.{1,2}/g) || []).join('/'),
        transactionTime: (result.body?.transactionTime?.match(/.{1,2}/g) || []).join(':'),
        maskedPan: maskedPan ? maskedPan.slice(0, 4) + maskedPan.slice(4, maskedPan.length - 4).replace(/0/g, "X") + maskedPan.slice(-4) : "",
        cardEntryMode: CardEntryMode[result.body?.cardEntryMode] ?? result.body?.cardEntryMode,
      }
    }
    return result;
  }

  encode(payload: Partial<RazerPayMessage>): Buffer {
    const stx = Buffer.from([0x2]);
    const etx = Buffer.from([0x3]);
    const transportHeader = (payload.transportHeader ?? new RazerPayTransportHeader()).toBuffer();
    const header = (payload.header ?? new RazerPayHeader()).toBuffer();
    const body = Object.keys(payload.body).map(e => this.generateField(fieldToFieldCode[e], payload.body[e]));
    const message = Buffer.concat([transportHeader, header, ...body]);
    const messageLength = intToBcd(message.length);
    const fullMessage = Buffer.concat([messageLength, message, etx]);
    const lrc = calculateLRC(fullMessage);
    return Buffer.concat([stx, fullMessage, lrc]);
  }

  generateField(fieldCode: string, data: string): Buffer {
    const fieldCodeBytes = Buffer.from(fieldCode, "ascii");
    const dataBytes = Buffer.from(data, "ascii");
    const lengthBytes = intToBcd(dataBytes.length);
    const separatorByte = Buffer.from([0x1c]);

    return Buffer.concat([fieldCodeBytes, lengthBytes, dataBytes, separatorByte]);
  }

  decodeHeader(buf: Buffer): Omit<RazerPayMessage, "body"> {
    return {
      transportHeader: RazerPayTransportHeader.fromBuffer(Buffer.from(buf.subarray(2, 12))),
      header: RazerPayHeader.fromBuffer(Buffer.from(buf.subarray(12, 20))),
    };
  }

  decodeBody(buf: Buffer): Pick<RazerPayMessage, "body"> {
    const bu = Buffer.from(buf.subarray(20, buf.length));

    const body: {
      [key in keyof RazerPayMessage["body"]]: Buffer;
    } = {};
    for(let i = 0; i < bu.length;) {
      const fieldCode = Buffer.from(bu.subarray(i, i + 2)).toString("ascii");
      i += 2;
      const length = bcd2dec(bu.readUInt16BE(i));
      i += 2;
      const data = Buffer.from(bu.subarray(i, i + length));
      i += length;
      i += 1; // separator
      const key = fieldCodeToField[fieldCode];
      if(!key) {
        console.warn("Unknown field", fieldCode);
        continue;
      }
      body[key] = data;
    }

    const result: RazerPayBody = Object.fromEntries(Object.entries(body).map(([k, v]) => {
      // trim space / 0
      let s = 0;
      while(v[s] === 0x20 || v[s] === 0) s++;
      let e = v.length - 1;
      while(v[e] === 0x20 || v[e] === 0) e--;
      v = v.subarray(s, e + 1);
      let r = Buffer.from(v).toString("ascii");
      return [k, r];
    }));

    if(body.customData) {
      let i = 0;
      result.customDataParsed = {};

      const customData = body.customData;
      for(let i = 0; i < customData.length;) {
        const type = Buffer.from(customData.subarray(i, i + 2)).toString("hex").padStart(4, '0').toUpperCase();
        i+=2;
        const len = customData[i++];
        const data = Buffer.from(customData.subarray(i, i + len)).toString("ascii");
        i+=len;
        const key = customFieldCodeToField[type];
        if(!key) {
          console.warn("Unknown custom field", type);
          continue;
        }
        result.customDataParsed[key] = data;
      }
    }

    return {
      body: result,
    };
  }
}

export class RazerPayError extends Error {
  constructor(public payload: RazerPayMessage, message: string) {
    super(message);
  }
}

export class RazerPayConnectionError extends Error {}

function calculateLRC(buffer) {
  let lrc = 0;
  for (let i = 0; i < buffer.length; i++) {
    lrc = (lrc ^ buffer[i]) & 0xff;
  }
  return Buffer.from([lrc]);
}

const dec2bcd = (dec: number): number => parseInt(dec.toString(10), 16);
const bcd2dec = (bcd: number): number => parseInt(bcd.toString(16), 10);

export function intToBcd(value: number): Buffer {
  const bcd = Buffer.alloc(2);
  bcd.writeUInt16BE(dec2bcd(value));
  return bcd;
}

function bcdToInt(bcd: Buffer): number {
  bcd = Buffer.from(bcd);
  let num = bcd.readUInt16BE(0);
  return bcd2dec(num);
}

export * from "./interface";
export * from "./enum";
