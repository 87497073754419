
import { Vue, Component, Watch } from "@feathers-client";
import moment from "moment";
import { Prop, PropSync } from "nuxt-property-decorator";
import StatusPicker from "../components/statusPicker.vue";
// import OrderListTableStatusPicker from "../components/table/orderSystem/orderListTableStatusPicker.vue";

interface OrderStatus {
  status: string;
}
type DateRange = {
  startDate: Date;
  endDate: Date;
  previousStartDate: Date;
  previousEndDate: Date;
}
type Mode = "today" | "week" | "month" | "year";
type DatePickerData = {
  mode: Mode;
} & DateRange;

@Component({
  component: {
    StatusPicker,
  }
})
export default class OrderListTable extends Vue {
  @Prop({ default: '' })
  title: string;
  @Prop({ default: '' })
  count: string;
  @Prop({ default: '' })
  path: string;
  @Prop({ default: new Date() })
  startDate: Date;
  @Prop({ default: new Date() })
  endDate: Date;

  orderStatusList: OrderStatus[] = [
    {
      status: "confirmed",
    },
    {
      status: "pending",
    },
    {
      status: "done",
    },
    {
      status: "cancelled",
    },
    {
      status: "void",
    },
    {
      status: "partial",
    },
  ]
  status = '' ;

  getName(log) {
    let name = this.$td('Walk-In')
    if (log.user?.name) {
      name = this.$td(log.user?.name)
    }
    return name
  }

  updateStatus(status) {
    this.status = status
    this.$emit('updateStatus', status)
  }
  
  get query() {
    return {
      $populate: ["user"],
      date: { $gte: this.startDate, $lte: this.endDate },
      status: this.status
    }
  }
  get orderSort() {
    return {
      date: -1,
    }
  }
}
