
import { Component, Vue, Prop, VModel } from "@feathers-client";
import EditorTextField from "./EditorTextField.vue";

@Component({
  components: {
    EditorTextField,
  },
})
export default class EditorTopMenu extends Vue {
  async beforeMount() {
    this.$schemas.onLocale(this.$i18n.locale);
  }

  @Prop(Boolean)
  hasSearch: boolean;

  @VModel()
  inputValue: string;

}
