<template functional>
    <component top right :is="injections.components.VTooltip" v-if="props.altText">
        <template v-slot:activator="{on}">
            <component 
                v-if="props.badge" 
                :is="injections.components.VBadge"
                :icon="props.badge"
                overlap
                :color="props.badgeColor"
                right
                :offsetX="props.badgeOffsetX"
                :offsetY="props.badgeOffsetY"
            >
                <component v-press="" :is="injections.components.VBtn" v-bind="{...data.props, ...data.attrs}" v-on="{...on, ...data.on}" v-close-tooltip icon>
                    <slot/>
                </component>
            </component>
            <component v-else v-press="" :is="injections.components.VBtn" v-bind="{...data.props, ...data.attrs}" v-on="{...on, ...data.on}" v-close-tooltip icon>
                <slot/>
            </component>
        </template>
        <span v-text="props.altText"/>
    </component>
    <component v-else :is="injections.components.VBtn" v-press v-bind="{...data.props, ...data.attrs}" v-on="data.on" icon>
        <slot/>
    </component>
</template>


<script>

import {
    VBtn,
    VTooltip,
    VBadge,
} from 'vuetify/lib'

import Vue from 'vue'

Vue.directive('close-tooltip', {
    bind(el, binding, vnode) {
        el.closeTooltip = (e) => {
            let tooltip = vnode.componentInstance?.$parent;
            if(tooltip && !(tooltip instanceof VTooltip)) tooltip = tooltip.$parent;
            if(tooltip && tooltip instanceof VTooltip) {
                tooltip.deactivate();
            }
        };
        el.openTooltip = (e) => {
            let tooltip = vnode.componentInstance?.$parent;
            if(tooltip && !(tooltip instanceof VTooltip)) tooltip = tooltip.$parent;
            if(tooltip && tooltip instanceof VTooltip) {
                tooltip.isActive = { open: true, close: false};
            }
        }
        el.addEventListener('click', el.closeTooltip);
        el.addEventListener('touchstart', el.openTooltip);
        el.addEventListener('touchend', el.closeTooltip);
    },
    unbind(el) {
        el.removeEventListener('click', el.closeTooltip);
        el.removeEventListener('touchstart', el.openTooltip);
        el.removeEventListener('touchend', el.closeTooltip);
    }
});

export default {
    inject: {
        components: {
            default: {
                VBtn,
                VTooltip,
                VBadge,
            }
        }
    },
};


</script>
