
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import { CashierType } from "@common/common";
import _ from "lodash";
import OrderIdList from "./OrderIdList.vue";
import uuid from "uuid/v4";
import EditorObjectPickerNew from "@schemaEditor/EditorObjectPickerNew.vue";
import EditorObjectPickerList from "@schemaEditor/EditorObjectPickerList.vue";

@Component({
  components: {
    OrderIdList,
    EditorObjectPickerNew,
    EditorObjectPickerList,
  },
})
export default class CashierSetup extends Vue {
  EditorObjectPickerList = EditorObjectPickerList;
  loading = false;

  get printerType() {
    return [
      {
        _id: "ordinary",
        name: { $t: "shop_settings.posPrinter.ordinary" },
      },
      {
        _id: "thermal",
        name: { $t: "shop_settings.posPrinter.thermal" },
      },
    ];
  }

  get invoiceTemplateType() {
    return [
      {
        _id: "html",
        name: { $t: "shop_settings.invoiceTemplateType.html" },
      },
      {
        _id: "docx",
        name: { $t: "shop_settings.invoiceTemplateType.docx" },
      },
    ];
  }

  get cashDrawerOpenType() {
    return [
      {
        _id: "none",
        name: { $t: "shop_settings.cashDrawerOpenType.none" },
      },
      {
        _id: "cash",
        name: { $t: "shop_settings.cashDrawerOpenType.cash" },
      },
      {
        _id: "any",
        name: { $t: "shop_settings.cashDrawerOpenType.any" },
      },
    ];
  }

  get cashDrawerManualOpenType() {
    return [
      {
        _id: "none",
        name: { $t: "shop_settings.cashDrawerManualOpenType.none" },
      },
      {
        _id: "manager",
        name: { $t: "shop_settings.cashDrawerManualOpenType.manager" },
      },
    ];
  }

  get weightConnType() {
    return [
      {
        _id: "keyboard",
        name: { $t: "weightConnType.keyboard" },
      },
      {
        _id: "bluetooth",
        name: { $t: "weightConnType.bluetooth" },
      },
      {
        _id: "serial",
        name: { $t: "weightConnType.serial" },
      },
    ];
  }

  get weightUnit() {
    return [
      {
        _id: "kg",
        name: { $t: "weightUnit.kg" },
      },
      {
        _id: "g",
        name: { $t: "weightUnit.g" },
      },
      {
        _id: "oz",
        name: { $t: "weightUnit.oz" },
      },
      {
        _id: "lb",
        name: { $t: "weightUnit.lb" },
      },
      {
        _id: "tael",
        name: { $t: "weightUnit.tael" },
      },
      {
        _id: "hk_catty",
        name: { $t: "weightUnit.hk_catty" },
      },
    ];
  }

  createCashier() {
    return {
      id: uuid(),
      warehouses: [],
    };
  }

  get attributes() {
    return this.$store.getters.attributes;
  }

  mserialPorts: string[] = null;

  @Prop({ type: Boolean, default: true })
  allowDeselect: boolean;

  get serialPorts() {
    if (!this.mserialPorts) {
      this.mserialPorts = [];
      this.loadSerialPorts();
    }
    return this.mserialPorts;
  }

  async loadSerialPorts() {
    try {
      const serial = await import("pos-printer/ports/serial");
      const devices = await serial.getDevices();
      this.mserialPorts = _.map(devices.devices, d => (d as any).comName || d.path);
    } catch (e) {
      console.warn(e);
    }
  }

  async beforeMount() {
    try {
      this.loading = true;
      await this.$pos.init();
      this.loading = false;
    } catch (e) {
      this.$store.commit("SET_ERROR", e.message);
    }
  }

  async useCashier(item: CashierType) {
    try {
      this.loading = true;
      await this.$pos.setCashier(item);
      this.$emit("setCashier", item);
    } catch (e) {
      this.$store.commit("SET_ERROR", e.message);
    } finally {
      this.loading = false;
    }
  }

  async unlinkCashier(item: CashierType) {
    const c = await this.$openDialog(
      import("@feathers-client/components-internal/ConfirmDialog.vue"),
      {
        title: this.$t("shop_settings.posCashier.doYouWantToUnlink"),
      },
      {
        maxWidth: "calc(min(90vw,400px))",
      },
    );
    if (!c) return;
    try {
      this.loading = true;
      await this.$pos.unlinkCashier(item);
    } catch (e) {
      this.$store.commit("SET_ERROR", e.message);
    } finally {
      this.loading = false;
    }
  }
}
