
import { Component, Vue, Prop } from "@feathers-client";
import { LangArrType } from "@feathers-client/i18n";
import { defineComponent } from "site-editor/def";
import { SiteEditorBanner } from "../headerImage/index.vue";

export const componentInfo = defineComponent({
  group: "faq",
  groupProps: {
    editorCols: 2,
  },
  variants: [
    {
      key: "paragraph",
    },
  ],
});

@Component
export default class SiteEditorFaq extends Vue {
  onClick(idx) {
    this.$set(this.expanded, idx, !this.expanded[idx]);
  }
  @Prop()
  variant: string;

  @Prop()
  title: LangArrType;

  @Prop()
  remarks: LangArrType;

  @Prop()
  faq: SiteEditorQA[];

  @Prop()
  titleDisplayMode: "left" | "center" | "right";

  @Prop()
  fontColor: string;

  expanded = [];

  beforeMount() {
    for (let qa in this.faq) this.expanded.push(false);
  }
}

export interface SiteEditorQA {
  id?: string;
  question?: LangArrType;
  answer?: LangArrType;
}
