import { BYTE, BinaryPacket, STR } from "../utils/binaryPack";

export interface RazerPayMessage {
  transportHeader: RazerPayTransportHeader;
  header: RazerPayHeader;
  body: RazerPayBody;
  formattedBody?: Partial<RazerPayBody>;
}

export class RazerPayTransportHeader extends BinaryPacket {
  @STR(() => 2)
  headerType: string = "60";
  @STR(() => 4)
  destination: string = "0000";
  @STR(() => 4)
  source: string = "0000";
}
export class RazerPayHeader extends BinaryPacket {
  @STR(() => 1)
  formatVersion: string = "1";
  @STR(() => 1)
  requestResponseIndicator: string = "0";
  @STR(() => 2)
  transactionCode: string = "20";
  @STR(() => 2)
  responseCode: string = "00";
  @STR(() => 1)
  moreToFollow: string = "0";
  @BYTE
  endOfPresentationHeader: number = 0x1c;
}
export interface RazerPayBody {
  payAccountId?: string;
  approvalCode?: string;
  responseText?: string;
  transactionDate?: string;
  transactionTime?: string;
  retrievalReferenceNo?: string;
  receiptRequiredFlag?: string;
  terminalId?: string;
  receiptFooterMerchantCopy?: string;
  receiptFooterCustomerCopy?: string;
  receiptData?: string;
  encryptedCardNo?: string;
  maskedPan?: string;
  expiryDate?: string;
  cardIssueDate?: string;
  memberExpiryDate?: string;
  accountBalance?: string;
  transactionStatus?: string;
  amount?: string;
  cashbackAmount?: string;
  batchNo?: string;
  forceSettlementFlag?: string;
  screenText?: string;
  screenTimeout?: string;
  traceNo?: string;
  invoiceNo?: string;
  transactionId?: string;
  extendedInvoiceNo?: string;
  salesTotal?: string;
  offlineSalesTotal?: string;
  voidSalesTotal?: string;
  cashbackTotal?: string;
  voidCashbackTotal?: string;
  refundTotal?: string;
  voidRefundTotal?: string;
  batchTransactionCount?: string;
  customData?: string;
  malaysianNric?: string;
  nameAsPerIc?: string;
  isoMessage?: string;
  cashierId?: string;
  compareCardFlag?: string;
  compareCardNumber?: string;
  merchantName?: string;
  merchantNo?: string;
  cardIssuerName?: string;
  hostName?: string;
  cardLabel?: string;
  cardholderName?: string;
  contactType?: string;
  phone?: string;
  email?: string;
  transactionLabel?: string;
  hostCount?: string;
  aid?: string;
  applicationProfile?: string;
  cid?: string;
  applicationCryptogram?: string;
  tsi?: string;
  tvr?: string;
  cardEntryMode?: string;
  eppTenure?: string;
  serialNo?: string;
  walletProductId?: string;

  customDataParsed?: RazerPayCustomData
}

export interface RazerPayCustomData {
  OTSRRedemptionPoint?: string;
  OTSRRedemptionAmount?: string;
  OTSRNetSaleAmount?: string;
  OTSRBalancePoint?: string;
  cashbackConvenienceFee?: string;
  walletStoreID?: string;
  walletTerminalCode?: string;
  walletInvoiceNumber?: string;
  walletPayerID?: string;
  walletReferenceID?: string;
  walletTransactionID?: string;
  walletCurrencyCode?: string;
  walletAmount?: string;
  walletExchangeRate?: string;
  walletProductName?: string;
  walletResponseCode?: string;
}
