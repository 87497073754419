
import { Component, Vue, Prop, Ref } from "@feathers-client";
import { PageBlock } from "../def";
import { SiteEditorComponentVariant, SiteEditorManager } from "..";
import EditorList from "@schemaEditor/EditorList.vue";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import EditorTranslateBox from "@schemaEditor/EditorTranslateBox.vue";
import EditorCheckbox from "@schemaEditor/EditorCheckbox.vue";
import EditorObjectPickerList from "@schemaEditor/EditorObjectPickerList.vue";
import ColorPicker from "../ColorPicker.vue";
import UrlPicker from "../UrlPicker.vue";
import uuid from "uuid/v4";
import { LangArrType } from "@feathers-client/i18n";
import CheckIcon from "@feathers-client/components-internal/CheckIcon.vue";
// @ts-ignore
import DeleteSvg from "!vue-svg-loader!../assets/delete.svg";
// @ts-ignore
import RarrowSvg from "!vue-svg-loader!../assets/rarrow.svg";
// @ts-ignore
import { Watch } from "nuxt-property-decorator";
import LangPicker from "../LangPicker.vue";
import { SiteEditorButton } from "./headerImage.vue";

@Component({
  components: {
    EditorList,
    EditorTextField,
    EditorTranslateBox,
    EditorCheckbox,
    EditorObjectPickerList,
    LangPicker,
    ColorPicker,
    UrlPicker,
    CheckIcon,
    DeleteSvg,
    RarrowSvg,
  },
})
export default class SiteEditorImage extends Vue {
  @Prop()
  block: PageBlock;

  @Prop()
  manager: SiteEditorManager;

  @Prop()
  variant: SiteEditorComponentVariant;

  @Ref()
  router: any;

  // @Prop()
  // modalResult: (save: boolean) => void;

  @Ref()
  search: HTMLInputElement;

  showItemPerRowDropdown = false;

  @Ref()
  datalist: any;

  importItems = false;
  listExist = false;
  selectedItems = [];
  searchKeyword = "";
  onSearch = false;
  customQuery: any = {};
  autoQuery: any = {};
  autoSort: any = {};
  autoNumOfItem = 0;
  autoGenerateItemsList = [];
  haveError = false;
  errorMsg: any = "";

  isStaticItem = this.groupKey === "gallery" && (this.keyName === "irregular" || this.keyName === "gether");

  created() {
    for (let item of this.props.items) {
      if (!item.mainButton) item.mainButton = { enabled: false, urlType: "internal", url: "", title: [] };
    }
  }

  mounted() {
    this.autoNumOfItem = this.props.numOfItem;

    if (this.props.listType && (this.props.selectedList || this.props.listType === "all") && this.autoNumOfItem > 0) {
      this.onGenerateList();
    }
  }

  get editTypes() {
    return [
      {
        _id: "custom",
        name: {
          $t: this.manager.$t("componentGroups.common.custom"),
        },
        desc: this.manager.$t("componentGroups.common.customDesc"),
      },
      {
        _id: "auto",
        name: {
          $t: this.manager.$t("componentGroups.common.auto"),
        },
        desc: this.manager.$t("componentGroups.common.autoDesc"),
      },
    ];
  }

  get textAlignTypes() {
    return [
      {
        _id: "left",
        name: {
          $t: this.manager.$t("componentGroups.common.left"),
        },
      },
      {
        _id: "center",
        name: {
          $t: this.manager.$t("componentGroups.common.center"),
        },
      },
      {
        _id: "right",
        name: {
          $t: this.manager.$t("componentGroups.common.right"),
        },
      },
    ];
  }

  get listTypes() {
    return [
      {
        _id: "all",
        name: {
          $t: this.manager.$t("componentGroups.common.all"),
        },
      },
      {
        _id: "categories",
        name: {
          $t: this.manager.$t("componentGroups.common.categories"),
        },
      },
      {
        _id: "tags",
        name: {
          $t: this.manager.$t("componentGroups.common.tags"),
        },
      },
      {
        _id: "brands",
        name: {
          $t: this.manager.$t("componentGroups.common.brands"),
        },
      },
      {
        _id: "product/relateds",
        name: {
          $t: this.manager.$t("componentGroups.common.product/relateds"),
        },
      },
    ];
  }

  get sizeType() {
    return ["small", "large"];
  }

  get displayModeType() {
    return ["bottomText", "hoverBottomText", "centeredText", "externalText"];
  }

  get alignTypes() {
    return ["left", "center", "right"];
  }

  getItem() {
    return {
      id: uuid(),
      file: null,
      urlType: "internal",
      url: null,
      bgColor: "#FFFFFFFF",
      mainButton: { enabled: false, urlType: "internal", url: "", title: [] } as SiteEditorButton,
    } as SiteEditorImageItem;
  }

  get groupKey() {
    return this.variant.group.key;
  }

  get keyName() {
    return this.variant.key;
  }

  get imageItemSuggest() {
    if (this.isStaticItem)
      return this.manager.$t(`componentGroups.${this.groupKey}.imageItemSuggest2`, {
        count: this.keyName === "gether" ? 3 : 5,
      });
    else return this.manager.$t(`componentGroups.${this.groupKey}.imageItemSuggest`);
  }

  get props() {
    let numOfItem = 1;
    if (this.isStaticItem) numOfItem = this.keyName === "gether" ? 3 : 5;
    return this.manager.defineProps(this.block, {
      initial: true,
      displayPrice: true,
      displayDesc: true,
      editType: "custom",
      listType: "",
      selectedList: "",
      sorting: "recommended",
      numOfItem,
      items: [] as SiteEditorImageItem[],
      title: [] as LangArrType,
      titleDisplayMode: "left",
      remarks: [] as LangArrType,
      fontRemarksColor: "#000000FF",
      fontColor: "#000000FF",
      imageFontColor: "#FFFFFFFF",
      imageBgColor: "#0000009A",
      displayMode: "bottomText",

      contentAlign: "center",
      size: "small",
      itemPerRow: 3,
      expandedMode: false,
      alignCenter: false,
    });
  }

  @Watch("props.listType")
  resetSelectedList() {
    this.props.selectedList = "";
    this.autoNumOfItem = 0;
  }

  selectAll() {
    this.search?.select?.();
  }

  goSearch() {
    this.onSearch = true;
    this.search?.blur?.();
    this.customQuery = {
      ...this.customQuery,
      $keyword: this.searchKeyword,
    };
  }

  onSelect(value) {
    if (this.selectedItems.includes(value)) {
      this.selectedItems = this.selectedItems.filter(item => item._id !== value._id);
    } else {
      this.selectedItems.push(value);
    }
  }

  displayItemPrice(value) {
    return this.$price(value.pricing[0]?.min?.price ?? 0, "pre");
  }

  onDelete(value) {
    if (this.selectedItems.includes(value)) {
      this.selectedItems = this.selectedItems.filter(item => item._id !== value._id);
    }
  }

  async onGenerateList() {
    if (this.props.listType === "product/relateds") {
      const relateds = await this.$feathers.service("shop/product/relateds").get(this.props.selectedList);

      this.autoQuery = {
        productGroup: { $in: relateds.products.map(it => it.group) },
      };
    } else {
      this.autoQuery = {
        ...(this.props.listType === "categories"
          ? {
              "categories.tree._id": this.props.selectedList,
            }
          : {}),
        ...(this.props.listType === "brands"
          ? {
              "brand._id": this.props.selectedList,
            }
          : {}),
        ...(this.props.listType === "tags"
          ? {
              "tags._id": { $in: [this.props.selectedList] },
            }
          : {}),
      };
    }
    this.autoSort = this.props.sorting === "recommended" ? { order: 1 } : { date: -1 };
    this.autoNumOfItem = this.props.numOfItem;
    this.router.navigate("autoList");
    this.listExist = true;
  }

  exportSelectedItem() {
    this.importItems = false;
    const selectedItems =
      this.props.editType === "custom" ? this.selectedItems : this.datalist?.items ? this.datalist?.items : [];
    const newItems = [];
    for (let item of selectedItems) {
      newItems.push({
        id: uuid(),
        file: item.mainImage,
        title: item.name ? item.name : "",
        slug: item.slug ? item.slug : "",
        price: item.pricing[0]?.min?.price ?? 0,
        urlType: "internal",
        url: `/product/${item.slug}?id=${item.productGroup}`,
        productGroup: item.productGroup,
      });
    }

    this.selectedItems = [];
    this.onSearch = false;
    if (this.props.editType === "custom") this.props.items.push(...newItems);
    else this.props.items = newItems;
  }

  showErrorMsg() {
    if (this.props.editType === "custom") {
      if (this.isStaticItem && this.props.items.length !== (this.keyName === "irregular" ? 5 : 3)) {
        this.errorMsg = this.manager.$t("componentGroups.common.valid.numOfItem");
      }
      let isItemValid = true;
      if (!isItemValid) this.errorMsg = this.manager.$t("componentGroups.common.valid.desc");
    } else {
      if (this.datalist?.items.length !== (this.keyName === "irregular" ? 5 : 3))
        this.errorMsg = this.manager.$t("componentGroups.common.valid.numOfItem");
      if (this.datalist?.items.length <= 0) this.errorMsg = this.manager.$t("componentGroups.common.valid.noItem");
    }
  }

  onValidAndConfirm() {
    this.haveError = false;
    if (this.props.editType === "custom") {
      let isItemValid = true;
      this.haveError = !isItemValid;

      if (this.isStaticItem && this.props.items.length !== (this.keyName === "irregular" ? 5 : 3)) {
        this.haveError = true;
      }
    } else {
      if (!this.props.listType || (this.props.listType !== "all" && !this.props.selectedList)) this.haveError = true;
      if (this.autoNumOfItem <= 0) this.haveError = true;
      if (this.groupKey === "gallery" && (this.keyName === "irregular" || this.keyName === "gether"))
        if (this.datalist?.items.length !== (this.keyName === "irregular" ? 5 : 3)) this.haveError = true;
    }
    if (this.haveError) {
      this.showErrorMsg();
      return;
    }

    if (this.props.editType === "auto") this.exportSelectedItem();
    this.$emit("save");
  }
}

export class SiteEditorImageItem {
  id?: string;
  slug?: string;
  file?: any;
  title?: LangArrType;
  desc?: LangArrType;
  price?: number;
  urlType: string;
  url?: string;
  bgColor?: string;
  fromImport?: boolean;
  productGroup?: string;
  mainButton?: SiteEditorButton;
}
