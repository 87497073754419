export default function (to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else {
        const elem = document.querySelector('#scroll-to');
        if (elem) {
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              resolve(window.scrollTo({
                top: Math.round(elem.getBoundingClientRect().top + window.scrollY),
                behavior: 'smooth'
              }));
            }, 500);
          });
        }
        return { x: 0, y: 0 };
      }
    }
