
import _ from "lodash";
import { Component, Vue, mixins, Prop, FindType, FindPopRawType, Watch, checkID, getID } from "@feathers-client";

@Component
export default class ShipmentScheduleOrderDialogItem extends Vue {
  @Prop()
  item: {
    productName: any;
    image: any;
    userName: any;
    remarks: any;
    quantity: any;
    amount: any;
  };
  // @Prop()
  // image: any;
  // @Prop()
  // userName: any;
  // @Prop()
  // quantity: any;
  // @Prop()
  // amount: any;
}
