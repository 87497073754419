import { Component, Vue, Prop, mixins } from "@feathers-client";
import { EspDevice, EspDeviceSerial } from "../esp32";
import SerialDevice, { connect, portPicker, SerialPortSelection } from "../ports/serial";
export * from "./struct";
import { TurnCloudMessage, encode, decode, messageLength, ResponseCode, CardType } from "./struct";
import { ns } from "../messageQueue";
import { getVersion } from "../nativeIntegrations";
import { request } from "../utils/httpProxy";
import { Logger } from "../payments/logger";

export interface TurnCloudConfig {
  serial?: SerialPortSelection;
  posURL?: string;
  useNative?: boolean;
}

@Component
export class TurnCloudManager extends mixins(
  Logger({
    name: "TurnCloud",
  }),
) {
  status: "notSetup" | "connected" | "disconnected" = "notSetup";

  networkList = {
    "00": "qrcode",
    "01": "linepay",
    "02": "weixin",
    "03": "alipay",
    "04": "gash",
    "05": "piwallet",
    "06": "allpay",
    "07": "ezwallet",
    "10": "edenred",
    "13": "jkos",
    "14": "credit",
    "18": "direct",
    "25": "cash",
    "26": "creditp",
    "27": "crediti",
    "30": "ezcard",
    "31": "union",
    "32": "taiwanpay",
  };

  cardType = CardType;

  created() {
    this.connect().catch(console.error);
  }

  @Prop()
  getSetting: () => TurnCloudConfig;

  @Prop()
  setSetting: (v: TurnCloudConfig) => void;

  @Prop()
  whenDestroy: () => void;

  beforeDestroy() {
    this.whenDestroy?.();
  }

  get settings() {
    return this.getSetting?.();
  }

  set settings(v) {
    this.setSetting?.(v);
  }

  get serialConfig() {
    return this.settings?.serial;
  }

  set serialConfig(s) {
    this.settings = {
      ...(this.settings || {}),
      posURL: null,
      serial: s,
    };
  }

  get posURL() {
    return this.settings?.posURL;
  }
  set posURL(v) {
    this.settings = {
      ...(this.settings || {}),
      serial: null,
      posURL: v,
    };
  }

  needReconnect: boolean;
  serial: SerialDevice = null;
  webSerial: any = null;
  webSerialWriter: any = null;
  espSerial: EspDeviceSerial = null;

  async openSettings() {
    return await this.$openDialog(
      // @ts-ignore
      import("./TurnCloudDialog.vue"),
      {
        manager: this,
      },
      {
        maxWidth: "80%",
        contentClass: "editor-dialog",
      },
    );
  }

  async connect(user?: boolean, reconnect?: boolean) {
    if (this.settings?.useNative) {
      if (!(await nativeSupported())) {
        throw new Error("Native not supported");
      }
      this.status = "connected";
      return;
    }
    if (this.settings?.posURL) {
      const hresp = await request("http://" + this.settings.posURL + "/turncloud");
      if (hresp.statusCode !== 200) {
        throw new Error("POS Server Error");
      }
      this.status = "connected";
      return;
    }
    if (!reconnect && this.serial) {
      return;
    }
    const device = await portPicker(
      this,
      reconnect ? undefined : this.serialConfig,
      user,
      undefined,
      "TurnCloud",
      "TurnCloud",
    );
    if (device) {
      this.disconnect();
      switch (device.type) {
        case "serial": {
          this.serialConfig = device;
          const serial = (this.serial = await connect(device.port, {
            baudRate: 9600,
          }));
          this.serial.on("data", buf => {
            this.serialInput(buf);
          });
          this.serial.on("close", () => {
            if (this.serial === serial) {
              this.disconnect();
            }
          });
          break;
        }
        case "webSerial": {
          this.serialConfig = {
            type: "webSerial",
          };
          this.webSerial = device.device;
          try {
            this.webSerial.addEventListener("disconnect", () => {
              if (this.webSerial === device.device) {
                this.disconnect();
              }
            });
            await this.webSerial.open({
              baudRate: 9600,
            });
            this.webSerialWriter = this.webSerial.writable.getWriter();
            const reader = this.webSerial.readable.getReader();
            const task = (async () => {
              while (true) {
                const data = await reader.read();
                this.serialInput(data.value);
              }
            })();
            task.catch(e => {
              console.error(e);
              if (this.webSerial === device.device) {
                this.disconnect();
              }
            });
          } catch (e) {
            if (this.webSerial === device.device) {
              this.disconnect();
            }
          }
          break;
        }
        case "espSerial": {
          this.serialConfig = {
            type: "espSerial",
            espConfig: device.espConfig,
          };
          const serial = (this.espSerial = (device.device as EspDevice).getSerial());
          this.espSerial.on("data", buf => {
            if (this.espSerial === serial) {
              this.serialInput(buf);
            }
          });
          this.espSerial.on("close", () => {
            if (this.espSerial === serial) {
              this.disconnect();
            }
          });
          break;
        }
      }
      if (this.hasConnection) {
        this.status = "connected";
      }
    }
  }

  async disconnect(save?: boolean) {
    if (this.serial) {
      this.serial.removeAllListeners("data");
      this.serial.close();
      this.serial = null;
    }
    if (this.webSerial) {
      try {
        await this.webSerial.close();
      } catch (e) {
        console.warn(e);
      }
      try {
        await this.webSerial.forget();
      } catch (e) {
        console.warn(e);
      }
      this.webSerial = null;
      this.webSerialWriter = null;
    }
    if (this.espSerial) {
      this.espSerial.removeAllListeners("data");
      this.espSerial.close();
      this.espSerial = null;
    }
    if (save) {
      this.serialConfig = null;
    }
    if (this.status === "connected") {
      this.status = "disconnected";
      this.needReconnect = true;
    }
  }

  async printerPrint(payload: Buffer) {
    if (this.settings?.posURL) {
      const task = request(
        "http://" + this.settings.posURL + "/turncloud/print",
        payload.toString(),
        "application/json",
      );
      task.catch(e => {
        this.disconnect();
      });
      const hresp = await task;
      if (hresp.statusCode !== 200) {
        throw new Error("POS Server Error: " + hresp.body);
      }
      this.status = "connected";
      return;
    } else {
      throw new Error("POS URL not set");
    }
  }

  async printerInfo() {
    if (this.settings?.posURL) {
      const task = request("http://" + this.settings.posURL + "/turncloud/printerInfo");
      task.catch(e => {
        this.disconnect();
      });
      const hresp = await task;
      if (hresp.statusCode !== 200) {
        throw new Error("POS Server Error: " + hresp.body);
      }
      this.status = "connected";
      return JSON.parse(hresp.body);
    } else {
      throw new Error("POS URL not set");
    }
  }

  async printerInit() {
    if (this.settings?.posURL) {
      const task = request(
        "http://" + this.settings.posURL + "/turncloud/printerInit",
        JSON.stringify({}),
        "application/json",
      );
      task.catch(e => {
        this.disconnect();
      });
      const hresp = await task;
      if (hresp.statusCode !== 200) {
        throw new Error("POS Server Error: " + hresp.body);
      }
      this.status = "connected";
    } else {
      throw new Error("POS URL not set");
    }
  }

  get hasConnection() {
    return !!(this.serial || this.webSerial || this.espSerial || this.settings?.useNative || this.settings?.posURL);
  }

  async waitReady(forceUser?: boolean | "background", timeout = 0) {
    if (!this.hasConnection && forceUser !== "background") {
      await this.connect(true);
    }
    if (!this.hasConnection && forceUser !== "background") {
      throw new Error("Turn Cloud Not connected");
    }
    while (this.status !== "connected") {
      await new Promise((resolve, reject) => {
        this.$once("connected", resolve);
        if (timeout) {
          setTimeout(() => reject(new Error("Timeout connecting")), timeout);
        }
      });

      await new Promise(resolve => setTimeout(resolve, 10));
      break;
    }
  }

  currentTransaction: Promise<TurnCloudMessage> = null;
  currentResolve: { resolve: (v: TurnCloudMessage) => void; reject: (e: Error) => void; ack?: () => void } = null;

  receiveBuf: Buffer = Buffer.alloc(1024);
  receiveOfs = 0;

  serialInput(buf: Buffer) {
    buf = Buffer.from(buf);
    if (buf[0] === 0x6) {
      this.log("ACK");
      if (this.currentResolve?.ack) {
        this.currentResolve.ack();
      }
    }
    if (!this.receiveOfs) {
      for (let i = 0; i < buf.length; i++) {
        if (buf[i] === 0x2) {
          buf = buf.slice(i);
          break;
        }
      }
      if (buf[0] !== 0x2) {
        return;
      }
    }
    buf.copy(this.receiveBuf, this.receiveOfs);
    this.receiveOfs += buf.length;
    while (this.receiveOfs >= messageLength + 3) {
      if (this.receiveBuf[messageLength + 1] === 0x3) {
        const payload = this.receiveBuf.slice(1, messageLength + 1);
        const lrc = calculateLRC(payload) ^ 3;
        if (this.receiveBuf[messageLength + 2] === lrc) {
          this.processMessage(payload);
          this.receiveBuf.copy(this.receiveBuf, 0, messageLength + 3);
          this.receiveOfs -= messageLength + 3;
          continue;
        } else {
          this.log("LRC mismatch", payload.toString(), lrc.toString());
        }
      }
      this.log("Remain data", this.receiveBuf.slice(0, this.receiveOfs).toString());
      for (let i = 1; i < this.receiveOfs; i++) {
        if (this.receiveBuf[i] === 0x2) {
          this.receiveBuf.copy(this.receiveBuf, 0, i);
          this.receiveOfs -= i;
          break;
        }
      }
    }
  }

  processMessage(payload: Buffer) {
    const message = decode(payload.toString());
    this.log("Received", JSON.stringify(message));
    if (this.currentResolve) {
      this.currentResolve.resolve(message);
      this.currentResolve = null;
    }
  }

  async serialSend(buf: Buffer) {
    if (this.serial) {
      await this.serial.send(buf);
    }
    if (this.webSerialWriter) {
      await this.webSerialWriter.write(buf);
    }
    if (this.espSerial) {
      await this.espSerial.write(buf);
    }
  }

  async doTransaction(transaction: TurnCloudMessage, verify = true) {
    while (this.currentTransaction) {
      await this.currentTransaction;
    }
    const task = this.doTransactionInner(transaction, verify);
    this.currentTransaction = task;
    try {
      return await task;
    } finally {
      if (this.currentTransaction === task) {
        this.currentTransaction = null;
      }
    }
  }

  async doTransactionInner(transaction: TurnCloudMessage, verify = true) {
    // if(!transaction.transDateTime) {
    //   transaction.transDateTime = new Date();
    // }
    this.log("Request", JSON.stringify(transaction));
    const payload = encode(transaction);
    const data = Buffer.from(payload);
    this.log("Sending to POS", payload);

    let resp: TurnCloudMessage;

    if (this.settings.useNative) {
      const result = await ns("turncloud").call("call", {
        data: payload,
      });
      resp = decode(result.data);
      this.log("Received", JSON.stringify(resp));
    } else if (this.settings.posURL) {
      const task = request("http://" + this.settings.posURL + "/turncloud", payload);
      task.catch(e => {
        this.disconnect();
      });
      const hresp = await task;
      if (hresp.statusCode !== 200) {
        throw new Error("POS Server Error" + JSON.stringify(hresp));
      }
      this.status = "connected";
      resp = decode(hresp.body);
      this.log("Received", JSON.stringify(resp));
    } else {
      const final = Buffer.concat([Buffer.from([0x2]), data, Buffer.from([0x3, calculateLRC(data) ^ 3])]);

      const promise = new Promise<TurnCloudMessage>((resolve, reject) => {
        let ackTimer = setTimeout(() => {
          ackTimer = null;
          cleanUp();
          reject(new TurnCloudConnectionError("ACK Timeout"));
        }, 5000);

        let paymentTimer = setTimeout(() => {
          paymentTimer = null;
          cleanUp();
          reject(new Error("Payment Timeout"));
        }, 120000);

        function cleanUp() {
          if (paymentTimer) {
            clearTimeout(paymentTimer);
            paymentTimer = null;
          }
          if (ackTimer) {
            clearTimeout(ackTimer);
            ackTimer = null;
          }
        }

        this.currentResolve = {
          resolve(e) {
            cleanUp();
            resolve(e);
          },
          reject(e) {
            cleanUp();
            reject(e);
          },
          ack() {
            if (ackTimer) {
              clearTimeout(ackTimer);
              ackTimer = null;
            }
          },
        };
      });

      await this.serialSend(final);

      resp = await promise;
    }

    if (resp.responseCode !== "orderSucess") {
      throw new TurnCloudError(resp, "Transaction failed: " + resp.responseCode);
    }

    return resp;
  }
}

export class TurnCloudError extends Error {
  constructor(
    public payload: TurnCloudMessage,
    message: string,
  ) {
    super(message);
  }
}

export class TurnCloudConnectionError extends Error {}

function calculateLRC(buf: Buffer) {
  let lrc = 0;
  for (let i = 0; i < buf.length; i++) {
    lrc ^= buf[i];
  }
  return lrc & 0xff;
}

export function nativeSupported(): Promise<boolean> {
  if (!getVersion()) return Promise.resolve(false);
  return Promise.race([
    ns("turncloud").call("supported"),
    new Promise(resolve => setTimeout(() => resolve(false), 1000)),
  ]).catch(e => false);
}
