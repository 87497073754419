
import { Component, Vue, PropSync, Watch, mixins, Prop } from "@feathers-client";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import EditorObjectPickerList from "@schemaEditor/EditorObjectPickerList.vue";
import RadioIcon from "@feathers-client/components-internal/RadioIcon.vue";
import ItemPickerBase from "@feathers-client/components-internal/ItemPickerBase.vue";
import { SiteEditorManager } from ".";

@Component({
  components: {
    EditorTextField,
    EditorObjectPickerList,
    RadioIcon,
  },
})
export default class UrlPicker extends mixins(ItemPickerBase) {
  @PropSync("url")
  urlLink: string;

  @PropSync("linkType")
  inputValue: string;

  @Prop(Boolean)
  light: boolean;

  @Prop(Boolean)
  hardCode: boolean;

  @Prop(Boolean)
  crop: boolean;

  @Prop()
  readonlyText: string;

  @Prop()
  manager: SiteEditorManager;

  internalLinkType = "";

  internalLink = "";

  externalLink = "";

  selectedId = "";

  ready = false;

  created() {
    if (this.inputValue === "internal") {
      if (this.urlLink && this.urlLink === "/") {
        this.internalLinkType = "main";
      } else if (this.urlLink && this.urlLink.includes("currentFilter")) {
        const match = this.urlLink.match(/.+currentFilter":{"(\w+).+:"([\w|\d]+).+/);

        this.internalLinkType = match[1];
        this.selectedId = match[2];
      } else if (this.urlLink && this.urlLink.includes("/product/")) {
        const match = this.urlLink.match(/.+?id=([\w|\d]+)/);
        this.internalLinkType = "product/groups";
        this.selectedId = match[1];
      } else if (this.urlLink && this.urlLink.includes("/news/")) {
        const match = this.urlLink.match(/.+?id=([\w|\d]+)/);
        this.internalLinkType = "articlePages";
        this.selectedId = match?.[1] || "";
      } else if (this.urlLink) {
        this.internalLinkType = "others";
        this.internalLink = this.urlLink;
      }
    } else {
      this.externalLink = this.urlLink;
    }
    Vue.nextTick(() => (this.ready = true));
  }

  @Watch("externalLink")
  onChangeExternalUrl() {
    if (!this.ready) return;
    this.urlLink = this.externalLink;
  }

  @Watch("internalLink")
  onChangeInternalUrl() {
    if (!this.ready) return;
    this.urlLink = this.internalLink;
  }

  get linkTypes() {
    return [
      { _id: "internal", name: { $t: this.manager.$t("urlPicker.internal") } },
      { _id: "external", name: { $t: this.manager.$t("urlPicker.external") } },
    ];
  }

  @Watch("internalLinkType")
  resetId() {
    if (!this.ready) return;
    if (this.internalLinkType === "main") {
      this.urlLink = "/";
    } else {
      this.urlLink = "";
    }
    this.selectedId = "";
    this.externalLink = "";
    this.internalLink = "";
  }

  @Watch("inputValue")
  resetInput() {
    if (!this.ready) return;
    this.selectedId = "";
    this.urlLink = "";
    this.externalLink = "";
    this.internalLink = "";
  }

  setProductUrl(item) {
    if (this.internalLinkType === "product/groups" && item) {
      this.urlLink = `/product/${item.slug}?id=${item._id}`;
    } else if (this.internalLinkType === "articlePages" && item) {
      this.urlLink = `/news/${item.path}/?id=${item._id}`;
    }
  }

  @Watch("selectedId")
  setUrl() {
    if (!this.ready) return;
    if (this.internalLinkType === "product/groups" || this.internalLinkType === "articlePages") {
      return;
    }
    this.urlLink = `/shop/?query=${JSON.stringify({
      currentFilter: {
        ...(this.internalLinkType === "categories"
          ? {
              "categories.tree._id": { $in: this.selectedId },
            }
          : {}),
        ...(this.internalLinkType === "brands"
          ? {
              "brand._id": { $in: this.selectedId },
            }
          : {}),
        ...(this.internalLinkType === "tags"
          ? {
              "tags._id": { $in: this.selectedId },
            }
          : {}),
      },
    })}`;
  }

  get query() {
    return {
      ...(this.inputValue === "internal" && this.internalLinkType === "articlePages" ? { status: "published" } : {}),
    };
  }
}
