
import _ from "lodash";
import { Component, Vue, Prop, FindType, Watch, getID, VModel } from "@feathers-client";
// @ts-ignore
import ItemPicker from "@feathers-client/components/ItemPicker.vue";

@Component({
  components: {
    ItemPicker,
  },
})
export default class EditorObjectPickerNew extends Vue {
  @Prop()
  items: any[];

  @Prop()
  clearable: boolean;

  @Prop()
  grid: string;

  mitems: any[] = [];

  get finalItems() {
    const set = this.hideItemList;
    const result = this.items || this.mitems || [];
    if (set?.size) {
      return result.filter((it) => !set.has(it._id));
    } else {
      return result;
    }
  }

  @Prop({ type: Boolean, default: true })
  light: boolean;

  @Prop()
  label: string;

  @Prop()
  path: string;

  @VModel()
  inputValue: any;

  @Prop(Boolean)
  multiple: boolean;

  @Prop({ type: Boolean, default: false })
  required: boolean;

  @Prop(Boolean)
  readonly: boolean;

  @Prop(Boolean)
  returnsObject: boolean;

  @Prop({ type: Boolean, default: false })
  preferSingle: boolean;

  @Prop()
  args: any;

  @Prop()
  hideItems: string | string[];

  @Prop(String)
  itemIcon: string;

  @Prop(Boolean)
  pickFirst: boolean;

  @Prop(Boolean)
  hideText: boolean;

  @Prop()
  itemClass: any;

  @Prop()
  buttonClass: string;

  @Prop()
  pickerClass: string;

  get hideItemList() {
    return new Set(
      Array.isArray(this.hideItems) ? this.hideItems : this.hideItems ? [this.hideItems] : [],
    );
  }

  beforeMount() {
    this.loadData();
  }

  async loadData() {
    if (this.path) {
      try {
        this.mitems = await (this as any).$feathers.service(this.path).find({
          query: {
            ...(this.args || {}),
            $paginate: false,
          },
        });
      } catch (e) {
        const resp = await (this as any).$feathers.service(this.path).find({
          query: {
            ...(this.args || {}),
          },
        });
        if (Array.isArray(resp)) {
          this.mitems = resp;
        } else if (Array.isArray(resp?.data)) {
          this.mitems = resp.data;
        }
      }
    }
    if(this.pickFirst && !this.multiple && !this.inputValue && this.finalItems.length) {
      this.inputValue = getID(this.finalItems[0]);
      this.$emit('updated', this.finalItems[0]);
    }
  }
}
