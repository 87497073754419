import { Context } from "@nuxt/types";
import helper from "../helper";
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import {
  CashierType,
  StaffType,
  PaymentMethodType,
  ShippingMethodType,
  PointType,
  DiscountType,
  RankType,
  SessionType,
} from "@common/common";
import _ from "lodash";
import { sign, init, keyHash } from "pos-printer/utils/cloudAuth";
import { CachedList, FindType } from "@feathers-client";
import { getHumanNumber, fromHumanNumber, CurrencyType, Currencies } from "@feathers-client/currency";
import moment from "moment";
import { getID } from "@feathers-client";
import { createScreenWithDialog, Screen } from "pos-printer/multiscreen";
import { IPSManager } from "../ips";

@Component
export class PosHelper extends Vue {
  initPromise: Promise<void>;
  initUserId: string;
  initShopId: string;
  ipsManager: IPSManager;

  created() {
    this.ipsManager = new IPSManager({
      parent: this,
    });
    this.init();
  }

  @Watch("$shop._id")
  @Watch("cashier")
  init() {
    if (this.initUserId !== this.$store.getters.userId || this.initShopId !== `${this.$shop?._id || ""}`) {
      this.initUserId = this.$store.getters.userId;
      this.initShopId = `${this.$shop?._id || ""}`;
      this.initPromise = null;
    }
    return this.initPromise || (this.initPromise = this.initCore());
  }

  eventInited = false;
  initEvent() {
    if (this.eventInited) return;
    this.eventInited = true;
    this.$feathers.service("shop/pos/cashiers").on("created", this.updatedCashier as any);
    this.$feathers.service("shop/pos/cashiers").on("patched", this.updatedCashier as any);
    this.$feathers.on("login", this.init);
  }

  updatedCashier(item: CashierType) {
    const c = this.cashiers.find(it => it._id === item._id);
    const m = this.cashier._id === item._id ? this.cashier : null;
    for (let [k, v] of Object.entries(item)) {
      if (c) Vue.set(c, k, v);
      if (m) Vue.set(m, k, v);
    }
    if (!c && this.cashiers.length) {
      this.cashiers.push(item);
    }
    if (this.cashier._id && this.cashier.deviceId !== this.key) {
      this.cashier = {};
    }
    if (item.deviceId === this.key) {
      this.cashier = item;
    }
  }

  cashier: Partial<CashierType> = {};

  @Watch("cashier")
  onCashierChanged() {
    if (this.cashier?._id) {
      this.$root.$emit("cashier", this.cashier);
    } else {
      this.$root.$emit("cashierReset");
    }
  }

  cashiers: CashierType[] = [];
  paymentMethods: PaymentMethodType[] = [];
  paymentMethodDict: Record<string, PaymentMethodType> = {};
  shippingMethods: ShippingMethodType[] = [];
  points: PointType[] = [];
  pointDict: Record<string, PointType> = {};
  ranks: RankType[] = [];
  rankDict: Record<string, RankType> = {};
  staff: StaffType = null;

  ads: FindType<'shop/ads'>[] = [];
  nextAd: FindType<'shop/ads'> = null;

  get welcomeAds() {
    return this.ads.filter(it => it.type === "welcome");
  }

  get directZoneAds() {
    const ads = this.ads.filter(it => it.type === "directZone");
    return _.groupBy(ads, it => it.fenceId);
  }

  session: SessionType = null;

  get staffId() {
    return getID(this.staff);
  }

  manualDiscounts: DiscountType[] = [];
  pointDiscounts: DiscountType[] = [];

  key: string = "";
  tempFontScale: null | number = null;
  tempRemoteFontScale: null | number = null;
  tempProductDisplay: null | string = null;
  tempShowProductImage: null | boolean = null;
  tempFullScreenProductImage: null | boolean = null;
  fontDiv = 20;
  remoteFontDiv = 40;

  get warehouseFilter() {
    return this.cashier.lockWarehouse
      ? {
          _id: { $in: this.cashier.warehouses || [] },
        }
      : {};
  }

  get warehouse() {
    return this.cashier.warehouses?.[0] || this.$shop.eshopWarehouse;
  }

  get posCode() {
    return this.$shop.posCode;
  }

  get productDisplay() {
    return typeof this.tempProductDisplay === "string"
      ? this.tempProductDisplay
      : this.$pos.cashier.productDisplay ?? "smallPic";
  }
  set productDisplay(v) {
    this.$pos.tempProductDisplay = v;
  }

  get showProductImage() {
    return typeof this.tempShowProductImage === "boolean"
      ? this.tempShowProductImage
      : this.$pos.cashier.showProductImage ?? true;
  }
  set showProductImage(v) {
    this.$pos.tempShowProductImage = v;
  }

  get fullScreenProductImage() {
    return typeof this.tempFullScreenProductImage === "boolean"
      ? this.tempFullScreenProductImage
      : this.$pos.cashier.fullScreenProductImage ?? false;
  }
  set fullScreenProductImage(v) {
    this.$pos.tempFullScreenProductImage = v;
  }

  get fontScale() {
    return typeof this.tempFontScale === "number" ? this.tempFontScale : this.cashier.fontScale ?? 0;
  }

  get fontScaleValue() {
    return (
      this.fontScale < 0 ? 100 - (Math.abs(this.fontScale) / this.fontDiv) * 80 : 100 + this.fontScale * 10
    ).toFixed(0);
  }

  get remoteFontScale() {
    return typeof this.tempRemoteFontScale === "number" ? this.tempRemoteFontScale : this.cashier.remoteFontScale ?? 0;
  }

  get remoteFontScaleValue() {
    return (
      this.remoteFontScale < 0
        ? 100 - (Math.abs(this.remoteFontScale) / this.remoteFontDiv) * 80
        : 100 + this.remoteFontScale * 10
    ).toFixed(0);
  }

  get kioskMode() {
    return this.cashier.kioskMode;
  }

  async initCore() {
    await init();
    this.key = keyHash;
    this.initEvent();
    if (!this.$store.getters.userId) return;

    this.cashier =
      ((
        await this.$feathers.service("shop/pos/cashiers").find({
          query: {
            $paginate: false,
            deviceId: this.key,
          },
          paginate: false,
        })
      )[0] as any) || {};

    if (this.cashier?._id) {
      this.session = (
        await this.$feathers.service("shop/pos/sessions").find({
          query: {
            cashier: this.cashier._id,
            active: true,
            $paginate: false,
          },
          paginate: false,
        })
      )[0];
    }

    this.paymentMethods = await this.$feathers.service("shop/payment/methods").find({
      query: {
        $paginate: false,
        $sort: { order: 1 },
        source: "pos", // Backend Order / POS
      },
      paginate: false,
    });

    this.paymentMethodDict = Object.fromEntries(this.paymentMethods.map(p => [p._id, p]));

    this.shippingMethods = await this.$feathers.service("shop/shipping/methods").find({
      query: {
        $paginate: false,
        $sort: { order: 1 },
      },
      paginate: false,
    });

    this.points = await this.$feathers.service("shop/points").find({
      query: {
        $paginate: false,
        $sort: { order: 1 },
      },
      paginate: false,
    });
    this.pointDict = Object.fromEntries(this.points.map(p => [p._id, p]));

    this.ranks = await this.$feathers.service("shop/ranks").find({
      query: {
        $paginate: false,
      },
      paginate: false,
    });
    this.rankDict = Object.fromEntries(this.ranks.map(p => [p._id, p]));

    this.manualDiscounts = await this.$feathers.service("shop/product/discounts").find({
      query: {
        $paginate: false,
        $sort: { displayOrder: 1 },
        automatic: false,
        allowPosPick: true,

        validFrom: { $lte: moment().toDate() },
        validUntil: { $gt: moment().toDate() },
        status: "valid",
      },
      paginate: false,
    });

    this.pointDiscounts = await this.$feathers.service("shop/product/discounts").find({
      query: {
        $paginate: false,
        $sort: { displayOrder: 1 },
        usePoint: { $ne: null },

        validFrom: { $lte: moment().toDate() },
        validUntil: { $gt: moment().toDate() },
        status: "valid",
      },
      paginate: false,
    });

    this.ads = await this.$feathers.service("shop/ads").find({
      query: {
        $paginate: false,
        status: "published",
        $sort: { date: -1 },
      },
      paginate: false,
    });

    this.ipsManager.init();
  }

  async ensureCashier() {
    if (!this.cashier._id) {
      this.$router.replace("/pos/settings");
    }
    if (!this.cashier._id) {
      throw new Error(`${this.$t("pos.error.cashierNotSetup")}`);
    }
  }

  async ensureSession() {
    if (!this.session) {
      this.$router.replace("/pos/openClose");
      throw new Error(`${this.$t("pos.error.notOpened")}`);
    }
  }

  async loadCashiers() {
    this.cashiers = (await this.$feathers.service("shop/pos/cashiers").find({
      query: {
        $paginate: false,
      },
      paginate: false,
    })) as any[];
  }

  async setCashier(item: CashierType) {
    const newItem = await this.$feathers.service("shop/pos/cashiers").patch(item._id, {
      deviceId: this.key,
    });
    for (let [k, v] of Object.entries(newItem)) {
      Vue.set(item, k, v);
    }
    this.cashier = item;
    this.initPromise = null;
    await this.init();
  }

  async updateCurrentCashier(update: Partial<CashierType>) {
    if (this.cashier?._id) {
      await this.updateCashier(this.cashier as any, update);
    }
  }

  async updateCashier(item: CashierType, update: Partial<CashierType>) {
    if (this.cashier?._id === item._id) {
      for (let [k, v] of Object.entries(update)) {
        Vue.set(this.cashier, k, v);
      }
    }
    const newItem = await this.$feathers.service("shop/pos/cashiers").patch(item._id, update);
    const c = this.cashiers.find(it => it._id === newItem._id);
    const m = this.cashier._id === newItem._id ? this.cashier : null;
    for (let [k, v] of Object.entries(newItem)) {
      Vue.set(item, k, v);
      if (c) Vue.set(c, k, v);
      if (m) Vue.set(m, k, v);
    }
  }

  async unlinkCashier(item: CashierType) {
    if (item.deviceId) {
      if (item.deviceId === this.key) {
        // current cashier
        this.cashier = {};
      }
      const newItem = await this.$feathers.service("shop/pos/cashiers").patch(item._id, {
        deviceId: null,
      });
      for (let [k, v] of Object.entries(newItem)) {
        Vue.set(item, k, v);
      }
    }
  }

  async getCashier(id: string) {
    if (id === this.cashier._id || id === this.cashier.id) {
      return this.cashier;
    }
    if (!this.cashiers?.length) {
      await this.loadCashiers();
    }
    const cashier = this.cashiers.find(it => it._id == id || it.id === id);
    if (cashier) return cashier;
    await this.loadCashiers();
    return this.cashiers.find(it => it._id == id || it.id === id);
  }

  async getCashierName(id: string) {
    const cashier = await this.getCashier(id);
    if (!cashier) return `${id || ""}`;
    return cashier.name;
  }

  getPointName(id: any) {
    return this.pointDict[id]?.name ?? "pt";
  }

  getRankName(id: any) {
    return !id ? "" : this.rankDict[id]?.name ?? `${id}`;
  }

  getPaymentMethodName(id: any) {
    return !id ? "" : this.paymentMethodDict[id]?.name ?? `${id}`;
  }

  leavePos() {}

  secondScreen: Screen = null;

  async connectScreen(reconnect?: boolean) {
    if (reconnect) {
      await this.disconnectScreen();
    }
    if (this.cashier.multiscreen) {
      if (!this.secondScreen) {
        try {
          this.secondScreen = await createScreenWithDialog(this, "/posScreen", this.$config.castId);
          this.secondScreen.on("loaded", () => {
            this.$root.$emit("screenLoaded");
          });
          this.$emit("updateScreen", this.secondScreen);
        } catch (e) {
          console.log(e);
        }
      }
    }
  }

  async disconnectScreen() {
    if (this.secondScreen) {
      await this.secondScreen.close();
      this.secondScreen = null;
      this.$emit("updateScreen", null);
    }
  }

  //#region Currency Helpers

  get currency() {
    return this.$shop?.currency ?? "HKD";
  }

  get currencySymbol() {
    return Currencies[this.currency]?.symbol ?? "$";
  }

  getHumanNumber(amount: number): number {
    return getHumanNumber(amount, this.currency);
  }

  fromHumanNumber(amount: number): CurrencyType {
    return fromHumanNumber(amount, this.currency);
  }

  fromHumanNumberToRaw(amount: number): number {
    return this.fromHumanNumber(amount).amount;
  }

  fromString(amount: string): CurrencyType {
    let v = +amount;
    if (isNaN(v)) return null;
    return this.fromHumanNumber(v);
  }

  fromStringToNumber(amount: string): number {
    return this.fromString(amount)?.amount ?? NaN;
  }

  //#endregion

  hasStaffRole(role: string): boolean {
    return this.$store.getters.role !== "pos" || this.$store.getters.permissionDict[role];
  }

  cacheSettings: Record<string, any> = {};

  get cacheOptions() {
    const defaultValues = {
      lastSession: null as string,
    };
    const keyList = Object.keys(defaultValues);

    const options: typeof defaultValues = {} as any;
    const self = this;
    Object.defineProperties(
      options,
      Object.fromEntries(
        keyList.map(key => [
          key,
          {
            get() {
              return self.cacheSettings?.[key] ?? defaultValues[key];
            },
            set(v) {
              Vue.set(self.cacheSettings, key, v);
              if (!self.$shop._id) return;
              localStorage[`cache/${self.$shop._id}`] = JSON.stringify(self.cacheSettings);
            },
          },
        ]),
      ),
    );
    return options;
  }
}

Vue.prototype.getHumanNumber = getHumanNumber;
Vue.prototype.fromHumanNumber = fromHumanNumber;

export default function (ctx: Context) {
  ctx.app.$posInit = parent => {
    return new PosHelper({ parent });
  };
}

if (!Vue.prototype.hasOwnProperty("$pos")) {
  Object.defineProperty(Vue.prototype, "$pos", {
    get() {
      return this.$root.$options["$pos"] || (this.$root.$options["$pos"] = this.$root.$options["$posInit"](this.$root));
    },
  });
}

declare module "vue/types/vue" {
  export interface Vue {
    $pos?: PosHelper;
  }
}

declare module "@nuxt/types" {
  export interface NuxtAppOptions {
    $pos?: PosHelper;
    $posInit?: (p: Vue) => PosHelper;
  }
}
