import { MemWritableStream } from "~/dep/feathers-client/loader/fake-fs";
import moment from "moment";
import type { Cell, Row, Column, Workbook, Worksheet } from "exceljs";
import stringify from "csv-stringify";
import HeaderProvider from "./mixins/HeaderProvider";
import { DataTableDef, DataTablePagination, DataTableHeader, LangType, options } from "./index";
import flat from "flat";
import { $thumb } from "./mixins/HeaderProvider";
import SelectProvider from "./mixins/SelectProvider";
import _ from "lodash";
import { getMongoCursor } from "./util";

const XLSX_MIME = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
const CSV_MIME = "text/csv";
const PAGE_LIMIT = 1000;
const STREAMING_LIMIT = 1000;
const DEF_MAX_COL_WIDTH = 40;

type CellNested = {
  $nested?: RowChunk[];
  [key: string]: any;
};

type RowCellNested = RowCell[] & {
  $nested?: RowChunk[];
};

type CellOrObjectArray = (CellNested | RowCellNested)[];

export type RowCell =
  | string
  | number
  | {
      $currency: true;
      toString(): string;
    }
  | {
      $image: boolean;
      $base64: string;
      toString(): string;
    };

export interface StyledCell {
  $cell: Partial<Cell>;
  $value: RowCell;
}

export type RowCellStyled = StyledCell | RowCell;

export interface RowItem {
  data: RowCellStyled[];
  level?: number;
  noIndent?: boolean;
  header?: boolean;
  separator?: boolean;
  row?: Partial<Row>;
  merge?: [number, number][];
  cellStyles?: [number, Partial<Cell>][];
  autoStyle?: boolean;
}

export interface RowChunk {
  rows: RowItem[];
  hasNested?: boolean;
  keys?: string[];
  headers?: DataTableHeader[];
}

export interface WorksheetExport {
  load: () => AsyncGenerator<RowChunk>;
  name?: string;
  xSplit?: number;
  ySplit?: number;
  cols?: Record<number, Partial<Column>>;
  maxColWidth?: number;
  removeChunkHeaders?: boolean; // remove headers after first chunk
  autoWidth?: boolean;
  sheet?: Partial<Worksheet>;
}

export async function exportExcel(name: string, data: WorksheetExport[], streaming?: boolean) {
  name = name.replace(/[\*\?\:\\\/\[\]]/g, "");
  const time = moment().format("lll");

  let buffers: (Buffer | Blob)[] = [];
  let streamEnd: Promise<Blob> = null;

  let wb: Workbook & { commit?: () => Promise<void> };

  if (streaming) {
    // if total data is more than 1000 items
    // use streaming
    const ExcelStream = (await import("exceljs/lib/stream/xlsx/workbook-writer")).default;
    const stream = new MemWritableStream();
    wb = new ExcelStream({
      useStyles: true,
      useSharedStrings: false,
      stream,
    });
    streamEnd = new Promise(resolve => {
      stream.on("finish", function (this: any) {
        resolve(this.toBlob(XLSX_MIME));
      });
    });
  } else {
    // use normal in memory workbook
    const Excel = await import("exceljs");
    wb = new Excel.Workbook();
  }

  let sheetIdx = 0;

  for (let srcSheet of data) {
    const sheetName = srcSheet.name
      ? srcSheet.name.replace(/[\*\?\:\\\/\[\]]/g, "")
      : name + (sheetIdx ? sheetIdx + 1 : "");
    sheetIdx++;
    const sheet = wb.addWorksheet(sheetName, {
      views: [{ state: "frozen", xSplit: srcSheet.xSplit ?? 1, ySplit: srcSheet.ySplit ?? 1 }],
    });

    const maxColWidth = srcSheet.maxColWidth ?? DEF_MAX_COL_WIDTH;

    const cols: {
      [idx: number]: {
        sum?: number;
        total?: number;
        max?: number;
        min?: number;
        force?: number;
      };
    } = {};

    let lastLevel = 0;

    function applyRow(r: Row, row: RowItem, hasNested?: boolean) {
      const fontSize = row.row?.font?.size ?? 11;
      let maxLines = 1;
      function applyCell(c: Cell, cellOrStyle: RowCellStyled, jdx: number) {
        const cell = (cellOrStyle as any)?.$cell ? (cellOrStyle as StyledCell)?.$value : (cellOrStyle as RowCell);

        c.alignment = { wrapText: true, vertical: "top" };
        c.border = {
          top: { style: "thin", color: { argb: "FFAAAAAA" } },
          left: { style: "thin", color: { argb: "FFAAAAAA" } },
          bottom: { style: "thin", color: { argb: "FFAAAAAA" } },
          right: { style: "thin", color: { argb: "FFAAAAAA" } },
        };
        if (row.separator) {
          if (!c.border) c.border = {};
          c.border.top = {
            style: "thin",
            color: { argb: "FF000000" },
          };
        }
        if (cell !== undefined && cell !== null) {
          if ((cell as any)?.getValue) {
            c.value = (cell as any).getValue();
          } else {
            c.value = String(typeof cell === "object" && cell.toString ? cell.toString() : cell);
          }
          if ((cell as any).$currency) {
            if (isNaN(+c.value[0])) c.value = String(c.value).substring(1).replace(/,/g, "").trim();
            // fix hard code
            c.numFmt = '"$"#,##0.00;[Red]-"$"#,##0.00';
          } else if ((cell as any).$format) {
            c.numFmt = (cell as any).$format;
          }
          if ((cell as any).$image) {
            r.height = 64;
            maxLines = -1;
            if (!cols[jdx]) cols[jdx] = {};
            cols[jdx].force = (64 / 10.25) * 2;
            if ((cell as any).$base64) {
              var img = wb.addImage?.({
                base64: (cell as any).$base64,
                extension: "png",
              });
              sheet.addImage?.(img, {
                tl: { row: r.number - 1, col: jdx } as any,
                br: { row: r.number, col: jdx + 1 } as any,
                editAs: "oneCell",
              });
            }
          }
          if (options.secureExport && typeof cell === "string") {
            c.value = c.value;
          } else if (/^\s*[+-]?([0-9,]*[.])?[0-9]+\s*$/.test(String(c.value))) {
            c.value = parseFloat(String(c.value).replace(/,/, ""));
          }
        } else {
          c.value = "";
        }
        if (!cols[jdx]) cols[jdx] = { sum: 0, total: 0, max: 0, min: 0 };
        let valLen = c.value ? (c.value && (c.value as any).result) || c.value : c.value;
        if (valLen) {
          const l = Buffer.from("" + valLen).length;
          cols[jdx].total++;
          cols[jdx].sum += l;
          if (l > cols[jdx].max) cols[jdx].max = l;
          if (row.header && l > cols[jdx].min) cols[jdx].min = l;

          const lines = Math.ceil(l / maxColWidth);
          if (maxLines !== -1 && lines > maxLines) maxLines = lines;
        }

        if ((cellOrStyle as any)?.$cell) {
          Object.assign(c, (cellOrStyle as any)?.$cell);
        }
      }
      for (let jdx = 0; jdx < row.data.length; jdx++) {
        const cell: string | any = row.data[jdx];
        const c = r.getCell(jdx + 1 + (row?.noIndent ? 0 : row?.level ?? 0));
        applyCell(c, cell, jdx);
      }
      if (row.autoStyle ?? true) {
        if (hasNested) {
          r.outlineLevel = row.level;
        }
        if (row.level) {
          r.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: {
              argb: "FF".padEnd(8, Math.max(3, 14 - (row.level ?? 0)).toString(16)).toUpperCase(),
            },
          };

          if (!row.noIndent) {
            const c = r.getCell(row?.level ?? 0);
            if (!c.border) c.border = {};
            c.border.right = {
              style: "medium",
              color: { argb: "FF000000" },
            };
            for (let i = 0; i < (row.level ?? 0); i++) {
              const c = r.getCell(row?.level ?? 0);
              c.fill = { type: "pattern", pattern: "none" };
            }
          }
        } else if (row?.header) {
          r.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FF333333" },
          };
        }
        if (row?.header) {
          if (row.level) {
            r.font = { bold: true };
          } else {
            r.font = { bold: true, color: { argb: "FFFFFFFF" } };
          }
        }
        if ((row?.level ?? 0) < lastLevel) {
          if (!r.border) r.border = {};
          r.border.top = {
            style: "thin",
            color: { argb: "FF999999" },
          };
        }
        lastLevel = row?.level ?? 0;
        if (maxLines !== -1) {
          r.height = Math.ceil(fontSize * 1.5) * maxLines;
        }
        if (row.merge) {
          for (let [from, to] of row.merge) {
            sheet.mergeCells(r.number, from + 1, r.number, to + 1);
          }
        }
      }
      if (row.row) {
        Object.assign(r, row.row);
      }
      if (row.cellStyles) {
        for (let [k, v] of row.cellStyles) {
          Object.assign(r.getCell(k + 1), v);
        }
      }
      r.commit?.();
    }

    let firstChunk = true;

    if (srcSheet.cols) {
      for (let [k, v] of Object.entries(srcSheet.cols)) {
        const col = sheet.getColumn(+k + 1);
        Object.assign(col, v);
      }
    }

    for await (let { rows, hasNested } of srcSheet.load()) {
      if (srcSheet.removeChunkHeaders && !firstChunk && rows[0]?.header) {
        rows.shift();
      }
      firstChunk = false;
      for (let row of rows) {
        const r = sheet.addRow({});
        applyRow(r, row, hasNested);
      }
    }
    if (srcSheet.autoWidth ?? true) {
      for (let [idx, info] of Object.entries(cols)) {
        const col = sheet.getColumn(+idx + 1);
        if (info.force) {
          console.log(info.force);
          col.width = info.force;
        } else {
          // const avgLen = info.sum / info.total;
          const width = Math.min(Math.max(info.min, info.max) * 1.1, maxColWidth);
          if (width > 8 && (!col.width || width > col.width)) col.width = width;
        }
      }
    }

    if (srcSheet.sheet) {
      Object.assign(sheet, srcSheet.sheet);
    }

    sheet?.commit?.();
  }

  if (wb.commit) {
    await wb.commit();
  } else {
    buffers.push((await wb.xlsx.writeBuffer()) as any);
  }

  downloadFile(streamEnd ? [await streamEnd] : buffers, `${name}-${time}.xlsx`, XLSX_MIME);
}

function stringifyCell(cell: any) {
  if (cell !== undefined && cell !== null) {
    if (options.secureExport && typeof cell === "string") {
      return cell;
    } else if (/^\s*[+-]?([0-9,]*[.])?[0-9]+\s*$/.test(cell)) {
      return parseFloat(`${cell}`.replace(/,/, ""));
    }
    if (cell.$currency) {
      return `${cell}`.substring(1);
    } else if (cell.$image) {
      return "<IMAGE>";
    }
    if (typeof cell === "object" && cell.getValue) {
      return JSON.stringify(cell.getValue());
    }

    return "" + cell;
  } else {
    return "";
  }
}

export async function exportCsv(name: string, data: WorksheetExport[]) {
  name = name.replace(/[\*\?\:\\\/\[\]]/g, "");

  const time = moment().format("lll");
  let sheetIdx = 0;

  for (let sheet of data) {
    const sheetName = sheet.name ? sheet.name.replace(/[\*\?\:\\\/\[\]]/g, "") : name + (sheetIdx ? sheetIdx + 1 : "");
    sheetIdx++;
    let buffers: (Buffer | Blob)[] = [];
    let lastHead: RowItem;
    for await (let { rows, keys } of sheet.load()) {
      if (sheet.removeChunkHeaders && rows[0]?.header) {
        lastHead = rows.shift();
      }
      if (!rows.length) continue;
      buffers.push(
        new Blob([
          Buffer.from(
            await new Promise<string>((resolve, reject) =>
              stringify(
                rows.map(r => r.data.map(c => stringifyCell(c))),
                (err, output) => (err ? reject(err) : resolve(output)),
              ),
            ),
          ),
        ]),
      );
    }
    if (lastHead) {
      buffers.unshift(
        new Blob([
          Buffer.from(
            await new Promise<string>((resolve, reject) =>
              stringify([lastHead.data.map(c => stringifyCell(c))], (err, output) =>
                err ? reject(err) : resolve(output),
              ),
            ),
          ),
        ]),
      );
    }

    buffers.unshift(Buffer.from("EFBBBF", "hex"));

    downloadFile(buffers, `${sheetName}-${time}.csv`, CSV_MIME);
  }
}

export async function downloadFile(blobs: BlobPart[], name: string, mime?: string) {
  if ((window as any).downloadFile) {
    return await (window as any).downloadFile(blobs, name, mime);
  }
  const blob = new Blob(blobs, { type: mime });
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style.display = "none";
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = name;
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
}

export interface ExportProps {
  $headers?: DataTableHeader[];
  $useHeaders?: boolean;
  $flattenNested?: boolean;
  $nested?: NestedProps[];
}

export type NestedProps = ExportProps & {
  $path: string;
};

async function peeker<T>(iterator: AsyncGenerator<T>) {
  let peeked = await iterator.next();
  let rebuiltIterator = async function* () {
    if (peeked.done) return;
    yield peeked.value;
    yield* iterator;
  };
  return { peeked, iterator: rebuiltIterator };
}

function walkHeader(header: DataTableHeader, it: any, provider: HeaderProvider) {
  switch (header.type) {
    case "multi": {
      let value = provider.get(it, header, true);
      if (header.multiple) {
        if (!value?.length) return;
        return value.map(it => header.inner.map(h => walkHeader(h, it, provider)).join("/")).join(";");
      } else if (value) {
        return header.inner.map(h => walkHeader(h, value, provider)).join("/");
      }
    }
    case "thumbURL":
    case "thumb":
    case "thumbItem":
    case "custom":
    default:
      return provider.get(it, header, true);
  }
}

export type DbExporterHook = (exporter: DbExporter, list: WorksheetExport[]) => Promise<void | boolean>;

export async function* loadDataStream(
  context: Vue,
  path: string,
  convertedQuery: any,
  progress?: (cur: number, total?: number) => void,
  opts?: { limit?: number; supportNoPaginate?: boolean },
) {
  const service = (context as any).$feathers.service(path) as any;
  const limit = opts?.limit ?? PAGE_LIMIT;

  let supportNoPaginate = false;
  if (opts?.supportNoPaginate !== undefined) {
    supportNoPaginate = opts.supportNoPaginate;
  } else {
    try {
      const result = await service.find({
        query: {
          ...convertedQuery,
          $paginate: false,
          $limit: 0,
        },
      });
      supportNoPaginate = Array.isArray(result);
    } catch (e) {
      console.warn(e);
    }
  }

  const total =
    (
      await service.find({
        query: {
          ...convertedQuery,
          $limit: 0,
        },
      })
    )?.total ?? null;
  let current = 0;

  progress?.(current, total);

  // sort by at least id
  let sort = convertedQuery.$sort;
  if (!sort) {
    sort = convertedQuery.$sort = {};
  }
  if (!sort._id) {
    sort._id = 1;
  }

  const condList = {
    $or: [{}],
  };
  if (!convertedQuery.$and) {
    convertedQuery.$and = [];
  }
  convertedQuery.$and.push(condList);

  let lastItem: any;

  while (true) {
    // export by cursor mode
    const query = getMongoCursor(lastItem, convertedQuery, sort);
    if (!query) break;
    const part: any = await service.find({
      query: {
        ...query,
        $limit: limit,
        ...(supportNoPaginate
          ? {
              $paginate: false,
            }
          : {}),
      },
    });
    if (!part) return;
    let result: any[] = [];
    if (Array.isArray(part)) {
      result = part;
    } else if (part.data && Array.isArray(part.data)) {
      result = part.data;
    } else {
      break;
    }
    if (!result.length) break;
    current += result.length;
    yield result;

    lastItem = result[result.length - 1];

    progress?.(current, total);
    if (part?.$ended) {
      break;
    }
  }
}

export class DbExporter {
  constructor(props?: Partial<DbExporter>) {
    Object.assign(this, props || {});
  }

  data?: DataTableDef;
  format: "csv" | "xlsx";
  provider: SelectProvider;
  query: any;
  rawQuery: any;

  exportId = false;

  exporting = true;
  current = 0;
  total = 0;
  progress = 0;
  indeterminate = true;

  prepareExportHook: DbExporterHook;

  cancel() {
    this.exporting = false;
  }

  async convertHeaderToCellData(h: DataTableHeader, v: any, provider: HeaderProvider) {
    if (h.type === "thumb") {
      if (v) {
        v = walkHeader(h, v, provider);
        if (typeof v === "string" || !v?.thumb) {
          if (Array.isArray(v) && !v.length) return "";
          let id = Array.isArray(v) ? v?.[0]?._id ?? v?.[0] : v?._id ?? v;
          if (!id) return "";
          try {
            const buf = await (
              await fetch(
                (this.provider as any).$imgHelper?.thumb?.(id) ||
                  (this.provider as any).$thumb?.(id) ||
                  $thumb.call(this.provider, id),
              )
            ).arrayBuffer();
            return {
              toString() {
                return "";
              },
              $image: true,
              $base64: Buffer.from(buf).toString("base64"),
            };
          } catch (e) {
            console.warn(e);
            return "";
          }
        } else {
          return {
            toString() {
              return "";
            },
            $image: true,
            $base64:
              (this.provider as any).$imgHelper?.thumb?.(v) ||
              (this.provider as any).$thumb?.(v) ||
              $thumb.call(this.provider, v),
          };
        }
      } else return "";
    } else if (h.format === "currency") {
      v = walkHeader(h, v, provider);
      if (v !== undefined && v !== null) {
        return {
          toString() {
            return v;
          },
          $currency: true,
        };
      }
    } else if (h.format === "table-date") {
      v = _.get(v, h.value);
      if (v) {
        return {
          getValue() {
            return moment(v).utcOffset(0, true).toDate();
          },
          $format: "YYYY-MM-DD HH:mm:ss",
        };
      }
    } else if (h.format === "multi") {
      v = walkHeader(h, v, provider);
      if(h.multiple) {
        if(!v?.length) return;
        return v.map(it => h.inner.map(h => this.convertHeaderToCellData(h, it, provider)));
      } else if(v) {
        return h.inner.map(h => this.convertHeaderToCellData(h, v, provider));
      }
      return "";
    } else {
      v = walkHeader(h, v, provider);
      if (options.secureExport && this.format === "csv") {
        return `="${`${v ?? ""}`.replace(/"/g, '""')}"`;
      }
    }

    return v;
  }

  async processBatch(input: any[], exportProps: ExportProps, provider: HeaderProvider) {
    let data: CellOrObjectArray = [];
    const headers: DataTableHeader[] =
      exportProps.$headers || (provider as any)?.headers || (this.provider as any)?.headers;
    if (exportProps.$useHeaders) {
      // First pass
      let pass = 0;
      do {
        for (let it of input) {
          for (let h of headers) {
            walkHeader(h, it, provider);
          }
        }
      } while ((await provider.waitPending()) && ++pass < 5);

      data = await Promise.all(
        input.map(async it => {
          const row = await Promise.all(
            headers.map(async h => {
              return await this.convertHeaderToCellData(h, it, provider);
            }),
          );
          if (exportProps.$useHeaders && this.exportId) {
            row.push((it as any)?._id);
          }
          return row;
        }),
      );
    } else {
      data = input.map(it => flat(it));
    }
    if (exportProps.$nested) {
      for (let i = 0; i < data.length; i++) {
        const target = data[i];
        const rawItem = input[i];
        let $nested: RowChunk[] = [];

        for (let nested of exportProps.$nested) {
          const source = _.get(rawItem, nested.$path);
          if (source) {
            const keys = new Set<string>();
            const items = await this.processBatch(source, nested, provider);
            if (items.length) {
              for (let it of items) {
                for (let k of Object.keys(it)) {
                  keys.add(k);
                }
              }
              $nested.push(this.mergeTable(Array.from(keys), items, nested));
            }
          }
        }
        if ($nested.length) target.$nested = $nested;
      }
    }
    return data;
  }

  mergeTable(keys: string[], items: CellOrObjectArray, exportProps: ExportProps): RowChunk {
    const headers = exportProps.$headers || this.provider.headers;

    if (exportProps.$flattenNested) {
      const rows: RowItem[] = [];
      const headerSet = new Set(headers);

      for (let item of items) {
        const itemCell = item as CellNested;
        if (itemCell.$nested) {
          for (let chunk of itemCell.$nested) {
            for (let header of chunk.headers) {
              headerSet.add(header);
            }
          }
        }
      }

      const rawHeaders = Array.from(headerSet.values());
      const xSplitHeaders = rawHeaders.slice(0, 1);
      const notSplitHeaders = rawHeaders.slice(1);
      notSplitHeaders.sort((a, b) => (a.exportOrder || 0) - (b.exportOrder || 0));

      const sortedHeaders = [...xSplitHeaders, ...notSplitHeaders];
      const headerToIndex = new Map(sortedHeaders.map((it, idx) => [it, idx] as const));

      rows.push({
        level: 0,
        header: true,
        data: sortedHeaders.map(it => it.text),
        cellStyles: Array.from(sortedHeaders.entries())
          .filter(it => !!it[1].exportHeader)
          .map(([k, v]) => [k, v.exportHeader]),
      });

      const applyList = (fields: RowCell[], item: CellNested, headers: DataTableHeader[]) => {
        for (let sidx = 0; sidx < headers.length; sidx++) {
          const header = headers[sidx];
          const idx = headerToIndex.get(header);
          if (typeof idx === "number") {
            fields[idx] = item[sidx];
          }
        }
        return fields;
      };

      const applyNested = (fields: RowCell[], nested: RowChunk[]) => {
        if (nested?.length) {
          const current = nested[0];
          const inner = nested.slice(1);
          for (let item of current.rows) {
            if (item.header) continue;
            const sub = applyList(fields.slice(), item.data, current.headers);
            applyNested(sub, inner);
          }
        } else {
          rows.push({
            level: 1,
            data: fields,
            noIndent: true,
          });
        }
      };

      for (let item of items) {
        const fields: RowCell[] = new Array(sortedHeaders.length);

        const curIdx = rows.length;

        applyList(fields, item, headers);
        applyNested(fields, item.$nested);

        const curRow = rows[curIdx];
        if (curRow) {
          curRow.level = 0;
        }
      }

      return { rows, hasNested: true, headers: sortedHeaders };
    } else {
      const fields: string[] = exportProps.$useHeaders ? headers.map(h => h.text) : keys;
      if (exportProps.$useHeaders && this.exportId) {
        fields.push("ref");
      }
      let rows: RowItem[] = [
        {
          level: 0,
          header: true,
          data: fields,
        },
      ];
      let hasNested = false;

      const source = items.map(item => {
        const row = fields.map((key, idx) => {
          return (exportProps.$useHeaders ? (item as CellNested)[idx] : _.get(item, key) ?? "") as RowCell;
        });
        return row;
      });

      for (let i = 0; i < items.length; i++) {
        const rowData = source[i];
        const raw = items[i];

        const row: RowItem = {
          data: rowData,
          level: 0,
        };

        rows.push(row);
        if (raw.$nested) {
          for (let nested of raw.$nested) {
            for (let row of nested.rows) {
              row.level++;
              rows.push(row);
            }
          }

          hasNested = true;
        }
      }

      return { rows, hasNested };
    }
  }

  async *loadDataStream() {
    this.indeterminate = false;
    const exportProps = this.provider.exportFilter || {};
    const queryProps = _.cloneDeep(exportProps);
    delete queryProps.$useHeaders;
    delete queryProps.$nested;
    delete queryProps.$path;
    delete queryProps.$headers;

    const provider = new HeaderProvider({
      parent: this.provider,
      propsData: {
        feathers: this.provider.feathers,
      },
    });

    if (
      this.data.static ||
      this.data.subpath ||
      this.provider.cursor ||
      (this.provider.selected && !this.provider.exportFilter)
    ) {
      // static items

      const items = this.provider.selected || this.provider.mitems || (this.provider as any).staticItems;
      const chunks = _.chunk(items, 100);
      this.current = 0;
      this.total = items.length;

      for (let chunk of chunks) {
        this.current += chunk.length;
        this.progress = Math.round((this.current / this.total) * 100);
        yield await this.processBatch(chunk, exportProps, provider);
      }
    } else {
      // from database

      let convertedQuery = _.cloneDeep(this.rawQuery);
      const d = this.provider.data || this.data;
      let q = _.cloneDeep(this.query);
      const args = [
        d,
        q,
        {
          ...queryProps,
          ...(this.provider.selected && this.provider.selected.length
            ? {
                [this.provider.mItemKey]: {
                  $in: this.provider.selected.map(it => _.get(it, this.provider.mItemKey)),
                },
              }
            : {}),
        },
      ];

      if (!convertedQuery) {
        q.rowsPerPage = 100;
        q.page = 1;

        convertedQuery = this.provider.convertQuery.apply(this.provider, args as any);
      }

      if (convertedQuery) {
        delete convertedQuery.$skip;
        delete convertedQuery.$limit;

        const service = (this.provider as any).$feathers.service(d.path) as any;

        let supportNoPaginate = false;
        try {
          const result = await service.find({
            query: {
              ...convertedQuery,
              $paginate: false,
              $limit: 0,
            },
          });
          supportNoPaginate = Array.isArray(result);
        } catch (e) {
          console.warn(e);
        }

        this.total =
          (
            await service.find({
              query: {
                ...convertedQuery,
                $limit: 0,
              },
            })
          )?.total ?? null;
        if (this.total === null) {
          this.indeterminate = true;
        }
        this.current = 0;

        // sort by at least id
        let sort = convertedQuery.$sort;
        if (!sort) {
          sort = convertedQuery.$sort = {};
        }
        if (!sort._id) {
          sort._id = 1;
        }

        const condList = {
          $or: [{}],
        };
        if (!convertedQuery.$and) {
          convertedQuery.$and = [];
        }
        convertedQuery.$and.push(condList);

        let lastItem: any;

        while (this.exporting) {
          const query = getMongoCursor(lastItem, convertedQuery, sort);
          if (!query) break;
          // export by cursor mode
          const part: any = await service.find({
            query: {
              ...query,
              $limit: PAGE_LIMIT,
              ...(supportNoPaginate
                ? {
                    $paginate: false,
                  }
                : {}),
            },
          });
          if (!part) return;
          let result: any[] = [];
          if (Array.isArray(part)) {
            result = part;
          } else if (part.data && Array.isArray(part.data)) {
            result = part.data;
          } else {
            break;
          }
          if (!result.length) break;
          this.current += result.length;
          yield await this.processBatch(result, exportProps, provider);
          await provider.waitPending();
          provider.clearCacheUsage(PAGE_LIMIT * 20, PAGE_LIMIT * 10);

          lastItem = result[result.length - 1];

          this.progress = Math.round((this.current / this.total) * 100);
          if (part?.$ended) {
            break;
          }
        }
      } else {
        while (this.exporting) {
          const result = await this.provider.runQuery.apply(this.provider, args as any);

          this.current = result.skip + result.data.length;
          this.total = result.total;
          this.progress = Math.round((this.current / this.total) * 100);

          const data = await this.processBatch(result.data, exportProps, provider);
          yield data;

          if (result.data.length < result.limit) break;
          q.page++;
          q.rowsPerPage = result.limit;
        }
      }

      if (!this.exporting) return;
    }
  }

  async *loadDataProcessed() {
    const exportProps = this.provider.exportFilter || {};
    const keys = new Set<string>();
    for await (let items of this.loadDataStream()) {
      if (!this.exporting) return;
      for (let item of items) {
        for (let key of Object.keys(item)) {
          keys.add(key);
        }
      }

      let { rows, hasNested } = this.mergeTable(Array.from(keys), items, exportProps);
      yield {
        rows,
        hasNested,
        keys: Array.from(keys),
      } as RowChunk;
    }
  }

  async save(name: string) {
    const sheet: WorksheetExport[] = [await this.getSheet(name)];

    if (this.prepareExportHook) {
      const res = await this.prepareExportHook(this, sheet);
      if (typeof res === "boolean" && res === false) return;
    }

    if (this.format === "csv") {
      await exportCsv(name, sheet);
    } else {
      await exportExcel(name, sheet, this.total > STREAMING_LIMIT);
    }
  }

  async getSheet(name?: string) {
    if (!name) {
      name = `${
        (this.data.path && this.data.subpath && `${this.data.path}-${this.data.subpath}`) ||
        this.data.path ||
        this.data.subpath ||
        (this as any).$td(this.data.name)
      }`;
    }
    let loader = this.loadDataProcessed();
    const { peeked, iterator } = await peeker(loader);
    const sheet: WorksheetExport = {
      name,
      load: iterator,
      removeChunkHeaders: true,
    };
    return sheet;
  }
}
