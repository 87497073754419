

import VirtualDataList from "./VirtualDataList.vue"

export default {
    install(Vue : Vue.VueConstructor) {
        Vue.component('virtual-data-list', <any>VirtualDataList)

    }
}

