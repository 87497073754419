
import moment from 'moment'

export default async function (ctx) {
  const { app, store, route, params, error, redirect } = ctx;
  if (route.query.lang) {
    store.commit('SET_LANG', route.query.lang)
  }
  if (!app.i18n.inited) {
    await app.i18n.initI18N();
    store.dispatch('initLang', { ctx });
  }
  if (app.i18n.locale !== store.state.locale) {
    await app.i18n.loadRemoteLocale(store.state.locale);
    app.i18n.locale = store.state.locale
  }
  moment.locale(store.getters.localeCode);
}
