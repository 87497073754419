import helper from '~/plugins/helper'

export default helper('pageController', (ctx) => {
    if(process.server) return;
    
    const store = ctx.store;
    try {
        document.domain = store.state.hostname;
    } catch(e) {
        console.warn(e);
    }

    const loadScript = require('simple-load-script');

    let stripe, captcha;

    return {
        loadStripe() {
            return (stripe = (stripe || loadScript('https://js.stripe.com/v3/')));
        },

        loadCaptcha() {
            return (captcha = (captcha || (async () => {
                await loadScript(`https://www.google.com/recaptcha/api.js?render=${this.$config.recaptchaKey}`);
                if(global.grecaptcha) {

                }
                const grecaptcha = global.grecaptcha;
                await new Promise((resolve) => grecaptcha.ready(resolve));
                return grecaptcha;
            })()));
        },
    }
    
});
