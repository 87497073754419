
export default {
  props: {
    header: {},
    item: {},
    context: {},
    searchPreview: Boolean,
  },
  render(_c) {
    const cell = this.context.renderItem(_c, { item: this.item, header: this.header });
    if(!cell || Array.isArray(cell) && !cell.length || cell && cell.text === '') {
      return this._v('-')
    }
    if (cell && cell.text && !this.searchPreview) {
      return this._c('div', {
        class: "line-clamp-4",
      }, [cell]);
    }
    if (cell && Array.isArray(cell) && cell.length > 1) {
      return this._c('div', cell);
    }
    return cell;
  }
}

