
import { Component, Vue, Prop, getID } from '@feathers-client'
import { ShippingContainer, ShippingProductPick } from '~/plugins/pos/shipment'
import EditorTextField from '@schemaEditor/EditorTextField.vue'
import EditorObjectPickerNew from '@schemaEditor/EditorObjectPickerNew.vue'


@Component({
  components: {
    EditorTextField,
    EditorObjectPickerNew,
  }
})
export default class ShipmentContainerView extends Vue {
    @Prop()
    container: ShippingContainer

    removeItem(item: ShippingProductPick) {
        item.$destroy();
    }

    async generateLabel() {
        const resp = await this.$feathers.service('shop/shipping/shipments/label').create({
            shipment: getID(this.container.parent.item._id),
            containerId: getID(this.container.item._id),
        })

        switch(resp.type) {
            case 'url': {
                window.open(resp.url, "_blank");
                break;
            }
        }
    }

    get hasLabel() {
        return this.container.parent?.status !== 'pending' && this.container.parent?.provider?.capabilities?.shippingLabels && this.container.item?._id;
    }
}

