var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['picker', {dragging: _vm.dragging}],style:({
    width: _vm.mini ? '50px' :  '250px',
    height: _vm.mini ? '50px' : '150px',
}),on:{"dragenter":_vm.dragenter,"dragover":_vm.dragover,"dragleave":_vm.dragleave,"drop":_vm.drop}},[_c('media-library',{attrs:{"type":_vm.type,"parent":_vm.parent,"dir":_vm.dir,"selecting":""},on:{"selected":_vm.addFiles},model:{value:(_vm.mediaLibrary),callback:function ($$v) {_vm.mediaLibrary=$$v},expression:"mediaLibrary"}}),_c('img',{style:({
        width: '100%',
        height: '100%',
        'object-position': 'center',
        'object-fit': 'contain'
    }),attrs:{"src":_vm.thumb},on:{"click":_vm.pickImage}}),(_vm.minfo && !_vm.minfo.complete)?_c('div',{staticClass:"floating"},[_c('v-progress-circular',{attrs:{"value":_vm.minfo.progress * 100,"color":"blue"}})],1):_vm._e(),(_vm.label||_vm.value)?_c('div',{staticClass:"floatingLabel"},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.value)?_c('v-btn',{attrs:{"icon":"","target":"_blank","href":_vm.$image(_vm.value),"small":""}},[_c('v-icon',[_vm._v("get_app")])],1):_vm._e(),(!_vm.readonly)?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":_vm.addFile}},[_c('v-icon',[_vm._v("add")])],1):_vm._e(),(!_vm.readonly)?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":_vm.clearFile}},[_c('v-icon',[_vm._v("clear")])],1):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }