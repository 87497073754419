import { Component, Vue, Prop, Watch } from "nuxt-property-decorator";
import type {
  ShippingProviderOptions,
  ShipmentAddressType,
  ShippingProviderCapabilities,
} from "@server/shop/shippings/base";
import { checkID, getID } from "./util";
import { AdminApplication, AppApplication } from "serviceTypes";
import { MApplication } from "@feathersjs/feathers";
import axios from "axios";
import _ from "lodash";

@Component
export class AddressHelper extends Vue {
  inited = false;
  _initTask: Promise<void>;
  _initDirty: boolean;

  @Prop()
  addressType: "from" | "to" | "default";

  @Watch("provider")
  @Watch("method")
  onValueChanged(v, kv) {
    if (v === kv) return;
    this.reset();
  }

  created() {
    this.reset();
  }

  reset() {
    this.inited = false;
    if (this._initTask) {
      this._initDirty = true;
    } else {
      this._initTask = this.init();
      this._initTask
        .then(() => {
          this.inited = true;
        })
        .finally(() => {
          this._initTask = null;
        });
    }
  }

  get feathers() {
    return (this as any).$feathers as MApplication<AdminApplication | AppApplication>;
  }

  async init() {
    do {
      this._initDirty = false;
      this.clearFields();

      if (this.method) {
        const method = await this.feathers.service("shop/shipping/methods").get(this.method);
        if (method.provider && !checkID(method.provider, this.provider)) {
          this.provider = getID(method.provider);
        }
      }

      if (this.provider) {
        this.capabilities = await this.feathers.service("shop/shipping/providers/capabilities").find({
          query: {
            id: this.provider,
          },
        });
      } else {
        this.capabilities = null;
      }

      this.options = this.mergedFields.map(
        f =>
          new AddressHelperOption({
            parent: this,
            propsData: {
              option: f,
            },
          }),
      );

      for (let opt of this.options) {
        opt.updateDefault();
      }
    } while (this._initDirty);
  }

  clearFields() {
    const options = this.options;
    this.options = [];
    for (let opt of options) opt.$destroy();
  }

  provider: string = "";
  method: string = "";

  capabilities: ShippingProviderCapabilities = null;

  address: Partial<ShipmentAddressType> = {
    providerOptions: [],
  };

  get isEmpty() {
    return _.every(this.options, opt => opt.valid && (!!opt.value || opt.isDefault));
  }

  setAddress(address: Partial<ShipmentAddressType>) {
    this.address = _.defaults(address, {
      providerOptions: [],
    });
    for (let opt of this.options) {
      opt.updateDefault();
    }
  }

  get valid() {
    for (let opt of this.options) {
      if (!opt.cond) continue;
      if (!opt.valid) return false;
    }
    return true;
  }

  get providerProps() {
    return this.address.providerOptions?.find?.(it => checkID(it.provider, this.provider))?.options;
  }

  set providerProps(options: any) {
    let cur = this.address.providerOptions?.find?.(it => checkID(it.provider, this.provider));
    if (cur) cur.options = options;
    else if (this.provider) {
      this.address.providerOptions.push({
        provider: this.provider,
        options,
      } as any);
    }
  }

  options: AddressHelperOption[] = [];

  standardFields: ShippingProviderOptions[] = [
    {
      type: "string",
      key: "name.firstName",
      name: [{ lang: "en", value: "First Name" }],
      standard: true,
    },
    {
      type: "string",
      key: "name.lastName",
      name: [{ lang: "en", value: "Last Name" }],
      standard: true,
    },
    {
      type: "string",
      key: "company",
      name: [{ lang: "en", value: "Company" }],
      standard: true,
    },
    {
      type: "string",
      key: "address",
      name: [{ lang: "en", value: "Line 1" }],
      standard: true,
    },
    {
      type: "string",
      key: "address2",
      name: [{ lang: "en", value: "Line 2" }],
      standard: true,
    },
    {
      type: "string",
      key: "city",
      name: [{ lang: "en", value: "City" }],
      standard: true,
    },
    {
      type: "string",
      key: "district",
      name: [{ lang: "en", value: "District" }],
      standard: true,
    },
    {
      type: "string",
      key: "region",
      name: [{ lang: "en", value: "Region" }],
      standard: true,
    },
    {
      type: "string",
      key: "state",
      name: [{ lang: "en", value: "State" }],
      standard: true,
    },
    {
      type: "string",
      key: "country",
      name: [{ lang: "en", value: "Country" }],
      standard: true,
    },
    {
      type: "string",
      key: "zip",
      name: [{ lang: "en", value: "Zip Code" }],
      standard: true,
    },
    {
      type: "string",
      key: "phone",
      name: [{ lang: "en", value: "Phone" }],
      standard: true,
    },
    {
      type: "string",
      key: "email",
      name: [{ lang: "en", value: "Email" }],
      standard: true,
    },
  ];

  get mergedFields() {
    const opts: ShippingProviderOptions[] = [];
    const standardDict: Record<string, ShippingProviderOptions> = Object.fromEntries(
      this.standardFields.map(it => [it.key, { ...it }]),
    );

    const fields =
      this.addressType === "from"
        ? this.capabilities?.fromAddressOptions ?? []
        : this.capabilities?.toAddressOptions ?? [];
    for (let field of fields) {
      let cur: ShippingProviderOptions;
      if (field.standard) {
        cur = standardDict[field.key] || field;
        Object.assign(cur, field);
        delete standardDict[field.key];
      } else {
        cur = field;
      }
      opts.push(cur);
    }

    for (let field of Object.values(standardDict)) {
      opts.push(field);
    }

    return opts;
  }

  setStandard(key: string, value: any) {
    const item = this.options.find(it => it.option.standard && it.cond && it.option.key === key);
    if (item) {
      item.value = value;
    } else {
      pathSetter(key)(this.address, value);
    }
  }

  setOption(key: string, value: any) {
    const item = this.options.find(it => !it.option.standard && it.cond && it.option.key === key);
    if (item) {
      item.value = value;
    } else {
      pathSetter(key)(this.providerProps, value);
    }
  }

  cachedResources: Record<string, Promise<any>> = {};

  loadResource(url: string) {
    return (
      this.cachedResources[url] ||
      (this.cachedResources[url] = (async () => {
        return Object.freeze((await axios.get(url)).data);
      })())
    );
  }
}

@Component
export class AddressHelperOption extends Vue {
  get parent() {
    return this.$parent as AddressHelper;
  }

  @Prop()
  option: ShippingProviderOptions;

  _loadExternalTask: Promise<any>;
  isDefault = false;

  updateDefault() {
    if (this.cond && this.option.externalSource && !this._loadExternalTask) {
      this._loadExternalTask = this.parent.loadResource(this.option.externalSource.url);
      this._loadExternalTask.then(data => {
        this.externalData = data;
      });
    }
    if (this.cond && this.defaultValue !== undefined && this.value === undefined) {
      this.value = this.defaultValue;
      this.isDefault = true;
      return true;
    }
    return false;
  }

  @Watch("cond")
  onCondChanged() {
    if (this.cond) {
      this.updateDefault();
    }
  }

  get id() {
    return this.option.standard ? "s" + this.option.key : this.option.id || this.option.key;
  }

  get type() {
    return this.option.type ?? "string";
  }

  get valueGetter() {
    return pathGetter(this.option.key);
  }

  get valueSetter() {
    return pathSetter(this.option.key);
  }

  get value() {
    if (this.option.standard) {
      return this.valueGetter(this.parent.address);
    } else {
      return this.valueGetter(this.parent.providerProps);
    }
  }

  set value(v: any) {
    this.isDefault = false;
    if (this.option.standard) {
      this.valueSetter(this.parent.address, v);
    } else {
      if (!this.parent.providerProps) {
        this.parent.providerProps = {};
      }
      this.valueSetter(this.parent.providerProps, v);
      if (this.type === "options") {
        const target = this.options.find(it => it._id === v);
        if (target?.value) {
          for (let [k, v] of Object.entries(target.value.address || {})) {
            this.parent.setStandard(k, v);
          }
          for (let [k, v] of Object.entries(target.value.options || {})) {
            this.parent.setOption(k, v);
          }
        }
        if (target?.item && this.externalValueExp) {
          for (let [k, v] of Object.entries(this.externalValueExp.address || {})) {
            this.parent.setStandard(k, (v as any)(this.context, target.item));
          }
          for (let [k, v] of Object.entries(this.externalValueExp.options || {})) {
            this.parent.setOption(k, (v as any)(this.context, target.item));
          }
        }
      }
    }
  }

  get name() {
    return this.option.name;
  }

  get context() {
    const self = this;
    return {
      get props() {
        return self.parent.providerProps || {};
      },
      get address() {
        return self.parent.address;
      },
      get $td() {
        return (self as any).$td?.bind?.(self) ?? (it => (Array.isArray(it) ? it[0]?.value ?? `${it}` : `${it}`));
      },
      get source() {
        return self.externalData;
      },
    };
  }

  get condExp() {
    return constantOrEval(this.option.cond);
  }

  get cond(): boolean {
    return this.condExp(this.context) ?? true;
  }

  get requiredExp() {
    return constantOrEval(this.option.required);
  }

  get required(): boolean {
    return this.requiredExp(this.context) ?? false;
  }

  get hiddenExp() {
    return constantOrEval(this.option.hidden);
  }

  get hidden(): boolean {
    return this.hiddenExp(this.context) ?? false;
  }

  get valid() {
    return this.required ? !!this.value : true;
  }

  get defaultValueExp() {
    return constantOrEval(this.option.default);
  }

  get defaultValue() {
    return this.defaultValueExp(this.context) ?? "";
  }

  get options() {
    return this.externalOptions ?? this.option.options ?? [];
  }

  externalData: any = null;

  get externalRootExp() {
    return constantOrEval(this.option.externalSource?.rootPath);
  }

  get externalFilterExp() {
    return constantOrEvalWithItem(this.option.externalSource?.filterPath);
  }

  get externalNameExp() {
    return (this.option.externalSource?.namePath ?? []).map(it => ({
      lang: it.lang,
      value: constantOrEvalWithItem(it.value),
    }));
  }

  get externalKeyExp() {
    return constantOrEvalWithItem(this.option.externalSource?.keyPath);
  }

  get externalValueExp() {
    if (!this.option.externalSource?.valuePath) return null;

    return {
      address: this.option.externalSource.valuePath.address
        ? (_.mapValues(this.option.externalSource.valuePath.address, v => constantOrEvalWithItem(v)) as any)
        : null,
      options: this.option.externalSource.valuePath.options
        ? (_.mapValues(this.option.externalSource.valuePath.options, v => constantOrEvalWithItem(v)) as any)
        : null,
    };
  }

  get externalOptions() {
    if (this.externalData) {
      const rootArray = this.externalRootExp(this.context) ?? [];

      const filtered = rootArray.filter(it => {
        return this.externalFilterExp(this.context, it) ?? true;
      });

      return filtered.map(it => ({
        _id: this.externalKeyExp(this.context, it),
        name: this.externalNameExp.map(v => ({ lang: v.lang, value: v.value(this.context, it) })),
        item: it,
      }));
    }
    return null;
  }
}

function constantOrEvalWithItem(exp: any): (context: any, item: any) => any {
  if (typeof exp === "string" && exp.startsWith("$")) {
    return new Function(
      "context",
      "item",
      "try { with(context) { return " + exp.slice(1) + "} } catch(e) { return undefined }",
    ) as any;
  }
  return () => exp;
}

function constantOrEval(exp: any): (context: any) => any {
  if (typeof exp === "string" && exp.startsWith("$")) {
    return new Function(
      "context",
      "try { with(context) { return " + exp.slice(1) + "} } catch(e) { return undefined }",
    ) as any;
  }
  return () => exp;
}

function pathGetter(path: string): (item: any) => any {
  const args = path.split(".");
  return new Function(
    "item",
    args.map(c => `item = item && item[${JSON.stringify(c)}];`).join("\n") + "\nreturn item",
  ) as any;
}

function pathSetter(path: string) {
  const args = path.split(".");
  return (
    new Function(
      "Vue",
      "item",
      "value",
      `
        ${args
          .slice(0, -1)
          .map(
            arg =>
              `item = item[${JSON.stringify(arg)}] ? item[${JSON.stringify(arg)}] : Vue.set(item, ${JSON.stringify(
                arg,
              )}, {});`,
          )
          .join("\n")}
        Vue.set(item, ${JSON.stringify(args[args.length - 1])}, value);
    `,
    ) as any
  ).bind(null, Vue) as (item: any, val: any) => void;
}
