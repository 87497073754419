<template>
<div
    v-feathers.orders.created.patched="onNewOrder"
>
    <v-snackbar
        right
        bottom
        v-model="currentOrder"
        :timeout="-1"
    >
        <v-row align="center" v-if="order">
            <v-col cols="auto">
                <v-img :width="100" :src="order.image"></v-img>
            </v-col>
            <v-col style="display: flex; flex-direction: column;">
                <div style="flex-grow: 1">
                    <div v-t="'orderPopup.newOrderReceived'"></div>
                    <div><span>{{$td(order.productName)}}</span><span v-if="order.remain"> {{$t('orderPopup.moreProducts', { num: order.remain})}}</span></div>
                    <div>{{$t('basic.total')}}: {{ $pos.currency }} <formatter format="currency" :value="order.amount"/></div>
                </div>
                <div class="mt-4">
                    <v-row no-gutters justify="end">
                        <v-col cols="auto">
                            <v-btn @click="currentOrder = false" color="red" style="margin-left: 0; margin-right: 0px;" text>{{$t('basic.skip')}}</v-btn>
                            <v-btn @click="currentOrder = false" :to="`/orders?${getQuery(order._id)}`" color="primary" style="margin-left: 0; margin-right: 0px;" text>{{$t('basic.view')}}</v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>
    </v-snackbar>
</div>
</template>

<script>

import qs from 'qs'

export default {
    data() {
        return {
            closing: false,
            orders: [
            ]
        }
    },
    methods: {
        async onNewOrder(order) {
            console.log('process new order', order);
            if(order.source === 'eshop' && (order.status === 'prepare' || order.status === 'paid')) {
                const oldOrder = this.orders.find(it => it._id === order._id);
                if(oldOrder || !order.products.length) return;
                const product = order.products[0].product;
                let image = this.$thumb(this.$shop.icon) || require('~/assets/images/logo.png');
                if(product) {
                    try {
                        const product = await this.$feathers.service('products').get(product, {
                            query: {
                            }
                        });
                        const img = product && product.images[0] && this.$thumb(product.images[0]);
                        image = img || image;
                    } catch(e) {
                        console.warn(e);
                    }
                }
                this.orders.push({
                    _id: order._id,
                    amount: order.amount,
                    productName: order.products[0].name,
                    remain: order.products.length - 1,
                    image,
                })
            }
        },

        getQuery(id) {
            return qs.stringify({
                query: {
                    currentFilter: {
                        _id: id,
                    }
                }
            })
        },
    },
    computed: {
        order() {
            return this.orders[0];
        },
        currentOrder: {
            get() {
                return !this.closing && !!this.orders.length;
            },
            set(v) {
                if(!v) {
                    if(this.closing) return;
                    this.closing = true;
                    setTimeout(() => {
                        this.orders.shift();
                        this.closing = false;
                    }, 500);
                }
            }
        }
    }
}
</script>
