
import { Component, Vue, Prop } from "@feathers-client";
import { PageBlock } from "../def";
import { SiteEditorManager } from "..";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import EditorTranslateBox from "@schemaEditor/EditorTranslateBox.vue";
import EditorCheckbox from "@schemaEditor/EditorCheckbox.vue";
import { LangArrType } from "@feathers-client/i18n";
import ItemPicker from "@feathers-client/components/ItemPicker.vue";
import ColorPicker from "../ColorPicker.vue";

@Component({
  components: {
    EditorTextField,
    EditorTranslateBox,
    EditorCheckbox,
    ItemPicker,
    ColorPicker,
  },
})
export default class SiteEditorRibbon extends Vue {
  @Prop()
  block: PageBlock;

  @Prop()
  manager: SiteEditorManager;

  get videoTypes() {
    return [
      { _id: "upload", name: { $t: this.manager.$t("componentGroups.video.upload") } },
      { _id: "url", name: { $t: this.manager.$t("componentGroups.video.url") } },
    ];
  }

  get titleDisplayModeType() {
    return ["left", "center", "right"];
  }

  get props() {
    return this.manager.defineProps(this.block, {
      title: [] as LangArrType,
      remarks: [] as LangArrType,
      url: "",
      file: null,
      type: "upload",
      autoPlay: false,
      titleDisplayMode: "left",
      fontColor: "#000000FF",
      fontRemarksColor: "#000000FF",
      height: "500",
    });
  }
}
