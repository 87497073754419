
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import _ from "lodash";
// @ts-ignore
import type { LangArrType, LangType } from "@feathers-client/i18n";

const mappedLocales = {
  "zh-hk": "cht",
  "zh-cn": "chs",
  "en-us": "en",
  "en-hk": "en",
};

@Component({
  components: {},
})
export default class EditorTranslateBox extends Vue {
  renderContainer(_c, { scopedSlots }) {
    return scopedSlots.container({
      menu: scopedSlots.menu,
    });
  }

  menu = false;

  @Prop()
  value: LangType;

  @Prop({ type: String, required: false })
  fallback: string;

  @Prop({ type: String, default: "" })
  url: string;

  @Prop({ type: String, default: "" })
  parent: string;

  @Prop({ type: String, default: "" })
  dir: string;

  @Prop({ type: String, default: "image/*" })
  type: string;

  @Prop()
  mini: boolean;

  @Prop()
  defImage: string;

  @Prop()
  attachment: boolean;

  @Prop()
  attachmentId: boolean;

  @Prop()
  label: string;

  @Prop()
  name: string;

  @Prop()
  readonly: boolean;

  mmissingLangs = null;

  get locales() {
    return Object.fromEntries(Object.entries(this.$store.state.locales).map(([k, v]) => [mappedLocales[k] || k, v]));
  }

  get allLang() {
    return Object.keys(this.locales).length;
  }

  get curLang() {
    return this.translate.filter(it => !!it.value).length;
  }

  get missingLangs_Real() {
    return Math.max(0, this.allLang - this.curLang);
  }

  get missingLangs() {
    return this.mmissingLangs;
  }

  @Watch("missingLangs_Real")
  onMissing(v, ov) {
    if (v === ov) return;
    this.mmissingLangs = v;
  }

  get locale() {
    return (
      this.$schemas.locale ||
      this.$store.state.translateLocale ||
      mappedLocales[this.$store.state.locale] ||
      this.$store.state.locale
    );
  }

  get translate(): LangArrType {
    if (typeof this.value === "string") {
      return [
        {
          lang: this.$store.state.locale,
          value: this.value,
        },
      ];
    } else if (this.value && !_.isArray(this.value)) {
      return _.map(this.value, (v, k) => ({
        lang: k,
        value: v,
      }));
    } else if (!this.value) {
      return [];
    }
    return this.value as any;
  }
  beforeMount() {
    this.mmissingLangs = this.missingLangs_Real;
  }
  mounted() {
    if (this.fallback && (!this.value || !_.isArray(this.value) || _.size(this.value) === 0)) {
      this.$emit("input", [
        {
          lang: this.$store.state.locale,
          value: this.fallback,
        },
      ]);
    } else if (this.value && !_.isArray(this.value)) {
      this.$emit("input", this.translate);
    }

    if (this.value && Array.isArray(this.value) && this.value.find(l => !!mappedLocales[l.lang])) {
      const newItems = Object.fromEntries(this.value.map(l => [mappedLocales[l.lang] || l.lang, l.value]));
      this.$emit(
        "input",
        Object.entries(newItems).map(([k, v]) => ({
          lang: k,
          value: v,
        })),
      );
    }
  }

  getIcon(icon) {
    switch (icon) {
      case "all":
        return "language";
      case "en":
        return "- flag-icon flag-icon-us";
      case "cht":
        return "- flag-icon flag-icon-hk";
      case "chs":
        return "- flag-icon flag-icon-cn";
    }
  }

  setTranslate(lang, value) {
    let v;
    if (lang === "all") {
      v = value ? Object.keys(this.locales).map(lang => ({ lang, value })) : [];
    } else {
      v = _.filter(this.translate, v => v.lang !== lang).concat(
        value
          ? [
              {
                lang,
                value,
              },
            ]
          : [],
      );
    }
    this.$emit("input", v);
    this.$emit("update:fallback", _.get(v.filter(it => it.lang === "en")[0] || v[0], "value") || "");
  }

  getTranslate(lang) {
    if (lang === "all") {
      return this.$td(this.translate);
    }
    return (this.translate.find(it => it.lang === lang) || {}).value || "";
  }
}
