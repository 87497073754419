//@ts-nocheck

import { ObjectID } from "@mfeathers/db/schemas";
import { createDecorator } from "vue-class-component";
import Vue from "vue";

export function getOptions(v: any, props?): any {
  return v.$root
    ? {
        parent: v ? v : null,
        propsData: props,
      }
    : {
        store: (v as any).store || (v as any).$store,
        i18n: (v as any).i18n || (v as any).$i18n,
        $router: (v as any).router || (v as any).$router,
        $feathers: (v as any).$feathers,
        propsData: props,
      };
}

function getIDCore(item: string | null | ObjectID<any>): string | null;
function getIDCore(item: any): string | null;
function getIDCore<T extends { _id: ObjectID<any> }>(item: T): string;
function getIDCore<T extends { _id: any }>(item: T): string;

function getIDCore(item: any): string | null {
  if (!item) {
    return null;
  } else if (typeof item === "string") {
    return item;
  } else if (typeof item === "number" || typeof item === "boolean") {
    return `${item}`;
  } else if (item._id !== undefined) {
    return getIDCore(item._id);
  } else {
    console.warn("Unknown id type", item);
    throw new Error("Unknown id type");
  }
}

function checkIDCore(a: any, b: any): boolean;
function checkIDCore<T extends { _id: ObjectID<any> }>(
  a: string | ObjectID<any> | T | null,
  b: string | ObjectID<any> | T | null | ObjectID<any>,
): boolean;
function checkIDCore<T extends { _id: ObjectID<any> }, T2 extends { _id: ObjectID<any> }>(
  a: string | T | null | ObjectID<any>,
  b: string | T2 | null | ObjectID<any>,
): boolean;
function checkIDCore<T extends { _id: any }>(a: string | T | null, b: string | T | null | ObjectID<any>): boolean;
function checkIDCore<T extends { _id: any }, T2 extends { _id: ObjectID<any> }>(
  a: string | T | null | ObjectID<any>,
  b: string | T2 | null | ObjectID<any>,
): boolean;

function checkIDCore(a: any, b: any): boolean {
  return getIDCore(a) === getIDCore(b);
}

export const getID = getIDCore;
export const checkID = checkIDCore;

const CachedListSymbol = Symbol("CachedListSymbol");

export function flushCached(parent: any) {
  const list: string[] = parent[CachedListSymbol] || [];
  for (let key of list) {
    list[`${key}_cache`] = null;
  }
}

export async function loadCached(parent: any) {
  const list: string[] = parent[CachedListSymbol] || [];
  await Promise.all(list.map(key => parent[`reload_${key}`]()));
}

export async function loadCachedItem<T>(parent: T, key: keyof T) {
  const cached = parent[`${key}_cache`];
  if (cached === null) {
    return await parent[`reload_${key}`]?.();
  } else if (cached instanceof Promise) {
    return await cached;
  }
  return cached;
}

export function CachedList(service: string, findParams: any = {}, def = []) {
  return function (target: Vue, key: string) {
    createDecorator(function (componentOptions, k) {
      componentOptions.mixins.push({
        data() {
          return {
            [key + "_cache"]: null,
          };
        },
        created() {
          if (!this[CachedListSymbol]) this[CachedListSymbol] = [];
          this[CachedListSymbol].push(key);
        },
        methods: {
          ["reload_" + key]() {
            const p = typeof findParams === "function" ? findParams(this) : findParams;
            return (this[key + "_cache"] = this.$feathers
              .service(service)
              .find(p)
              .then(v => {
                this[key + "_cache"] = v;
              }));
          },
        },
      });
      componentOptions.computed[key] = {
        get(this: any) {
          const cached = this[key + "_cache"];

          if (cached === null) {
            this["reload_" + key]();
          } else if (cached instanceof Promise) {
            return def;
          }
          return cached || def;
        },
        set(this: any, v: any) {
          this[key + "_cache"] = v;
        },
      };
    })(target, key);
  };
}

export function CachedDict(service: string, findParams: any = {}, def = {}, subscribe = false) {
  return function (target: Vue, key: string) {
    createDecorator(function (componentOptions, k) {
      componentOptions.mixins.push({
        data() {
          return {
            [key + "_cache"]: null,
          };
        },
        created() {
          if (!this[CachedListSymbol]) this[CachedListSymbol] = [];
          this[CachedListSymbol].push(key);
        },
        beforeDestroy() {
          if (this[key + "_subscribed"]) {
            this.$feathers.service(service).off("created", this["update_" + key]);
            this.$feathers.service(service).off("patched", this["update_" + key]);
            this.$feathers.service(service).off("removed", this["remove_" + key]);
          }
        },
        methods: {
          ["reload_" + key]() {
            const p = typeof findParams === "function" ? findParams(this) : findParams;
            if (subscribe && !this[key + "_subscribed"]) {
              this.$feathers.service(service).on("created", this["update_" + key]);
              this.$feathers.service(service).on("patched", this["update_" + key]);
              this.$feathers.service(service).on("removed", this["remove_" + key]);
              this[key + "_subscribed"] = true;
            }

            return (this[key + "_cache"] = this.$feathers
              .service(service)
              .find(p)
              .then(v => {
                const list = Array.isArray(v?.data) ? v.data : v;
                this[key + "_cache"] = Object.fromEntries(list.map(it => [getID(it), it]));
              }));
          },
          ...(subscribe
            ? {
                ["update_" + key](item) {
                  const cached = this[key + "_cache"];
                  this.$set(cached, getID(item), item);
                },
                ["remove_" + key](item) {
                  const cached = this[key + "_cache"];
                  this.$delete(cached, getID(item));
                },
              }
            : {}),
        },
      });
      componentOptions.computed[key] = {
        get(this: any) {
          const cached = this[key + "_cache"];

          if (cached === null) {
            this["reload_" + key]();
          } else if (cached instanceof Promise) {
            return def;
          }
          return cached || def;
        },
        set(this: any, v: any) {
          this[key + "_cache"] = v;
        },
      };
    })(target, key);
  };
}

const fallbackCopyTextToClipboard = (text: string) => {
  var textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand("copy");
    var msg = successful ? "successful" : "unsuccessful";
    return true;
    //console.log("Fallback: Copying text command was " + msg);
  } catch (err) {
    return false;
    //console.error("Fallback: Oops, unable to copy", err);
  } finally {
    textArea.remove();
  }
};

export async function copyTextToClipboard(text: string) {
  if (!navigator.clipboard) {
    return fallbackCopyTextToClipboard(text);
  }
  try {
    await navigator.clipboard.writeText(text);
  } catch (e) {
    return fallbackCopyTextToClipboard(text);
  }
}

export function getSearchFilter(list, id, sub) {
  const it = list?.find?.(it => it.hasOwnProperty(id));
  return it ? (sub ? _.get(it[id], sub) : it[id]) : null;
}

export function setSearchFilter(list, id, value, sub) {
  const it = list.find(it => it.hasOwnProperty(id));
  if (it) {
    if (sub) {
      if (value) {
        Vue.set(it[id], sub, value);
      } else {
        delete it[id][sub];
      }
      if (_.isEmpty(it[id])) {
        const idx = list.indexOf(it);
        idx !== -1 && list.splice(idx, 1);
      }
    } else {
      if (value) it[id] = value;
      else {
        const idx = list.indexOf(it);
        idx !== -1 && list.splice(idx, 1);
      }
    }
  } else
    list.push({
      [id]: sub ? _.set({}, sub, value) : value,
    });
}

// Who need lodash?
export function groupBy<TKey = string, T = any>(list: T[], keyGetter: (item: T) => TKey): Map<TKey, T[]> {
  const map = new Map<TKey, T[]>();
  if (list) {
    for (let item of list) {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    }
  }
  return map;
}

export function escapeRegExp(text: string) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

export function unescapeRegExp(text: string) {
  return text.replace(/\\(.)/g, "$1");
}

export function sortedIndexBy<T>(array: T[], value: T, iterator: (a: T, b: T) => number, retHighest?: boolean) {
  var low = 0,
    high = array == null ? low : array.length;

  while (low < high) {
    var mid = (low + high) >>> 1,
      computed = array[mid];

    if (computed !== null && (retHighest ? iterator(computed, value) <= 0 : iterator(computed, value) < 0)) {
      low = mid + 1;
    } else {
      high = mid;
    }
  }
  return high;
}

type MongoSortType<T> = {
  [key in keyof T]: 1 | -1;
};

export function compareByMongoSort<T>(fields: MongoSortType<T>) {
  const entries = Object.entries(fields);
  return (a: T, b: T) => {
    if (a === b) return 0;
    for (let [key, dir] of entries) {
      let av = a?.[key];
      let bv = b?.[key];
      if (av === bv) continue;
      if (dir === -1) {
        return av < bv ? 1 : -1;
      } else {
        return av < bv ? -1 : 1;
      }
    }
    return 0;
  };
}

export function runQuery(item: any, filter: any) {
  if (typeof filter === "object") {
    return _.every(filter, (v, k) => {
      if (v instanceof Date) {
        v = v.toJSON();
        if (item instanceof Date) item = item.toJSON();
      }
      if (k.startsWith("$")) {
        switch (k) {
          case "$and":
            return _.every(v, sq => runQuery(item, sq));
          case "$or":
            return _.some(v, sq => runQuery(item, sq));
          case "$eq":
            return item === v;
          case "$ne":
            return item !== v;
          case "$lt":
            return item < v;
          case "$lte":
            return item <= v;
          case "$gt":
            return item > v;
          case "$gte":
            return item >= v;
          case "$in":
            return !!v.find(v => {
              if (Array.isArray(item)) {
                return item.find(it => runQuery(it, v));
              } else {
                return runQuery(item, v);
              }
            });
          case "$nin":
            return !v.find(v => {
              if (Array.isArray(item)) {
                return item.find(it => runQuery(it, v));
              } else {
                return runQuery(item, v);
              }
            });
          case "$sort":
          case "$populate":
          case "$paginate":
            return true;
          default:
            console.warn("unknown", k);
            return true;
        }
      } else if (v && typeof v === "object" && !(v instanceof Date)) {
        return runQuery(item[k], v);
      } else {
        return item[k] === v;
      }
    });
  } else {
    return item === filter;
  }
}

/***
 * A Memory safe timer promise
 */
export function timer(signal?: AbortSignal, delay: number, events?: [any | Promise<any>, string][]) {
  return new Promise<[any, string, any]>((resolve, reject) => {
    const eventCleanup = (events || [])
      .map(([t, e]) => {
        const handler = (r: any) => {
          cleanup();
          resolve([t, e, r]);
        };
        if (t.$on) {
          t.$on(e, handler);
          return () => {
            t.$off(e, handler);
          };
        } else if (t.on) {
          t.on(e, handler);
          return () => {
            t.off(e, handler);
          };
        } else if (t.then) {
          t.then(
            r => {
              cleanup();
              resolve([t, e, r]);
            },
            e => {
              cleanup();
              reject(e);
            },
          );
        }
      })
      .filter(it => !!it);
    const timeout = () => {
      timer = null;
      cleanup();
      resolve(null);
    };
    const abort = () => {
      cleanup();
      reject(new Error("Aborted"));
    };
    let timer = setTimeout(timeout, delay);
    if (signal) {
      signal.addEventListener("abort", abort);
    }
    const cleanup = () => {
      for (let f of eventCleanup) f();
      if (timer) clearTimeout(timer);
      if (signal) {
        signal.removeEventListener("abort", abort);
      }
    };
  });
}

export function getPathAdv(item: any, path: string | string[]) {
  if (Array.isArray(path)) {
    for (let i = 0; i < path.length; i++) {
      const k = path[i];
      if (k === "*") {
        if (Array.isArray(item)) {
          if (i + 1 < path.length) {
            return item.map(it => getPathAdv(it, path.slice(i + 1)));
          } else return item;
        } else return [];
      } else if (item != null) {
        item = item[k];
      }
    }
    return item;
  } else {
    return _.get(item, path);
  }
}

export const isBBMSLP5 = typeof navigator !== "undefined" && navigator.userAgent.includes("P5 Build");
export const isBBPOS = isBBMSLP5 || (typeof navigator !== "undefined" && navigator.userAgent.includes("WiseposPlus"));

export const isMyPay = typeof navigator !== "undefined" && navigator.userAgent.includes("P2_PRO");
export const isSunmi = typeof navigator !== "undefined" && navigator.userAgent.includes("D2s_PLUS_2nd");
export const isA8 = typeof navigator !== "undefined" && navigator.userAgent.includes("APOS A8");
export const isDX8000 = typeof navigator !== "undefined" && navigator.userAgent.includes("DX8000");
export const isIMIN = typeof navigator !== "undefined" && navigator.userAgent.includes("Swan 1");
export const isV3 = typeof navigator !== "undefined" && navigator.userAgent.includes("V3_MIX_STD");
export const isRK3399 = typeof navigator !== "undefined" && navigator.userAgent.includes("rk3399");
export const isRK3288 = typeof navigator !== "undefined" && navigator.userAgent.includes("rk3288");
export const isGHL =
  typeof navigator !== "undefined" &&
  (navigator.userAgent.includes("N82 Build") || navigator.userAgent.includes("N86 Build"));

export const lowSpec =
  isBBPOS ||
  isMyPay ||
  isSunmi ||
  isA8 ||
  isDX8000 ||
  isIMIN ||
  isV3 ||
  isRK3399 ||
  isRK3288 ||
  isGHL ||
  (typeof localStorage !== "undefined" && localStorage.getItem("lowSpec") === "true");
export const medSpec = false;

export const needTouchFix = isRK3399 || isRK3288;
