

import { Component, Prop, Vue, Watch, mixins,FindType, VModel, checkID, getID, PropSync } from "@feathers-client";
import { Order } from "@common/pos";
import _ from 'lodash'

@Component({
})
export default class Cash extends Vue {

    get presets() {
        const self = this;
        return [
            { 
                _id: 'full', 
                name: { $t: 'pos.payment.fullPay' },
                get selected() {
                    return !self.isCustomTips 
                },
                set selected(v) {
                    if(v) {
                        self.onOutstandingChanged();
                    }
                }
            },
        ]
    }

    @Prop()
    session: Order

    @VModel()
    paymentAmountInt: number

    paymentAmountStr = ""

    get paymentAmount() {
        return this.$pos.getHumanNumber(this.paymentAmountInt);
    }
    
    set paymentAmount(v) {
        this.paymentAmountInt = this.$pos.fromHumanNumberToRaw(v);
    }

    get max() {
        return this.$pos.getHumanNumber(this.session.outstandingInt);
    }

    get isCustomTips() {
        return this.paymentAmountInt !== this.session.outstandingInt
    }

    @Watch('session.outstandingInt')
    onOutstandingChanged() {
        this.paymentAmountInt = this.session.outstandingInt
        this.paymentAmountStr = `${this.paymentAmount}`
    }

    confirm() {
        if(!this.paymentAmountStr) {
            this.onOutstandingChanged();
        }
    }

    customTips = false;

}

