
import Dialog from "@feathers-client/mixins/Dialog";
import { Component, mixins, Prop, FindType, checkID } from "@feathers-client";
import MenuListNum from "@feathers-client/components/MenuListNum.vue";
import { FindPopRawType } from "@feathers-client";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import EditorObjectPickerList from "@schemaEditor/EditorObjectPickerList.vue";
import _, { entries } from 'lodash';
import uuid from 'uuid/v4';
import DataTableForErp from "~/components/erp/DataTableForErp.vue";
import type { InventoryEntry, InventoryAction } from "@server/shop/inventory";
import { ObjectID } from "~/dep/typings/dep/feathers/db/schemas";

@Component({
  components: {
    MenuListNum,
    EditorTextField,
    EditorObjectPickerList,
    DataTableForErp
  }
})
export default class InventoryEntryDialog extends mixins(Dialog) {

  @Prop()
  purchaseOrderId: ObjectID

  purchaseOrder: FindPopRawType<
    ['supplier', 'shipTo', 'handler',
      'invoices.payments', 'products.productSku',
      'inventoryRecords.warehouse', 'inventoryRecords.handler',
    ], "shop/erp/purchaseOrders">;

  item: InventoryAction & { entries: Array<InventoryEntry & { refProductId: ObjectID }> } = {
    action: "restock",
    warehouse: null,
    entries: [],
  };
  // export interface InventoryAction {
  //   action: "restock" | "export" | "adjust" | "transfer" | "order" | "setTo";
  //   warehouse: string; /* actually objectid */
  //   target?: string;
  //   entries: InventoryEntry[];
  //   comment?: string;
  // }

  // export interface InventoryEntry {
  //   sku: string;
  //   delta: number;
  //   supplier?: string; // maybe objectid, here use companyUser
  //   comment?: string;
  //   unitPrice?: number;  // priceInt
  //   expiryDate?: Date;
  //   reference?: string;
  //   location?: string;
  //   lots?: string[];
  //   targetLog?: string;
  //   targetStatus?: any;
  //   log?: InventoryLogType;
  // }

  loading: boolean = false;

  staff: FindType<'shop/staffs'> = null;

  checkId = checkID

  productToBeHandle: Array<typeof this.purchaseOrder['products'][number] & { quantityToProcess: number, expand: boolean }> = null;

  get returnObject() {
    return {
    }
  }

  getInitProductsQty(product: typeof this.purchaseOrder['products'][number]) {
    return product.quantity - this.getDoneInventory(product);
  }

  getRemainingProducts(product: typeof this.productToBeHandle[number]) {
    if (!this.item.entries.length) return product.quantity - (this.getDoneInventory(product) ?? 0);
    return product.quantity - _.sumBy(this.item.entries.filter(el => checkID(el['refProductId'], product._id)), 'delta') - (this.getDoneInventory(product) ?? 0);
  }

  getDoneInventory(product: typeof this.purchaseOrder['products'][number]) {
    if (!this.purchaseOrder.inventoryRecords) return 0;
    const handledInventory = this.purchaseOrder.inventoryRecords.filter(el => el.status === "confirmed" && el.action === "restock" && checkID(el.refProductID, product._id));
    return _.sumBy(handledInventory, 'delta');
  }

  getStatus(product: typeof this.purchaseOrder['products'][number]) {
    const done = this.getDoneInventory(product);
    let status = "";
    if (done <= 0) {
      status = "notReceived"
    } else if (done > 0 && done < product.quantity) {
      status = "partial"
    } else if (done >= product.quantity) {
      status = "received"
    }
    return this.$t(`subsequentPaymentOrders.inventoryEntryStatus.${status}`)
  }

  removeFromItem(entry: typeof this.item.entries[number]) {
    this.item.entries.splice(this.item.entries.indexOf(entry), 1);
  }

  addProductToItem(product: typeof this.productToBeHandle[number]) {
    if (!this.staff) {
      this.$store.commit("SET_ERROR", this.$t('subsequentPaymentOrders.erp.pleaseSelectHandler'))
      return;
    }
    if (product.quantityToProcess < 1) return;
    const existEntry = this.item.entries.find(el => checkID(el['refProductId'], product._id));
    if (existEntry) {
      existEntry.delta += product.quantityToProcess;
    } else {
      this.item.entries.push(
        {
          sku: `${product.productSku._id}`,
          delta: product.quantityToProcess,
          supplier: `${this.purchaseOrder.supplier._id}`,
          unitPrice: product.priceInt,
          // abc: 2
          refProductId: product._id,
          log: {
            shop: this.$shop._id,
            user: null,
            admin: null,
            source: "erp",
            order: this.purchaseOrder._id,
            erpOrder: this.purchaseOrder.purchaseOrderId
          } as any
        }
      )
    }

    product.quantityToProcess = this.getRemainingProducts(product)
  }

  async returnData() {
    if (!this.staff) {
      this.$store.commit("SET_ERROR", this.$t('subsequentPaymentOrders.erp.pleaseSelectHandler'))
      return;
    }

    try {
      const res = await this.$feathers.service('shop/erp/purchaseOrders/inventoryEntry').create({
        shop: this.$shop._id,
        orderID: this.purchaseOrder._id,
        item: this.item,
        handler: this.staff._id
      });
      console.log("res", res);
      this.modalResult(true);
    } catch (error) {
      this.$store.commit("SET_ERROR", error.message)
    }



  }

  async beforeMount() {
    const self = this;
    this.purchaseOrder = (await this.$feathers.service('shop/erp/purchaseOrders').find({
      query: {
        _id: this.purchaseOrderId,
        $paginate: false,
        $populate: ['supplier', 'shipTo', 'handler', 'invoices.payments',
          'inventoryRecords.warehouse', 'inventoryRecords.handler', 'products.productSku']
      },
      paginate: false,
    }))[0] as unknown as typeof this.purchaseOrder;
    this.productToBeHandle = (this.purchaseOrder.products.filter(el => el.productSku)).map(it => {
      return {
        ...it,
        quantityToProcess: self.getInitProductsQty(it),
        expand: false
      }
    });
    this.item.warehouse = `${this.purchaseOrder.shipTo._id}`;
  }

  get tableHeader() {
    return [
      this.$t("newSubsequentPaymentOrder.productName"),
      this.$t("orders.quantity"),
      this.$t("subsequentPaymentOrders.erp.waitForEntry"),
      this.$t("subsequentPaymentOrders.erp.enteredWarehouse"),
      this.$t("pos.warehouse"),
      this.$t("subsequentPaymentOrders.erp.entryStatus"),
    ]
  }


}
