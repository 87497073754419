import { Vue, Prop, Watch, Component, mixins } from "nuxt-property-decorator";
import type { PrinterServer } from "pos-printer";
import type { PrintJob, PrintQueue } from "pos-printer/printQueue";
import { VueConstructor } from "vue";
import { CashierType, PosContext } from "@common/common";
import type { PrintSequence } from "pos-printer/printSequence";
import VueI18n from "vue-i18n";
import BBPOS from "./bbpos";

let server: PrinterServer;
const printerDict: {
  [key: string]: any;
} = {};

export interface PrintMixinsTrait {
  cashier: any;
}

export declare class PrintMixinBase extends (Vue as VueConstructor<Vue & PosContext>) {
  readonly posPrinter: any;
  readonly printerConnType: any;
  readonly printer: PrintQueue;
  readonly printerServer: PrinterServer;

  setupPrinter();
  setPrinter();
  updateStatus();
  openCashBox(): Promise<void>;
  printJob(
    templateName: string,
    func: (sequence: PrintSequence, template: string) => PrintJob | Promise<PrintJob>,
    jobId?: string,
  ): Promise<void>;
}

export default function getMixins(name: string, type: string): typeof PrintMixinBase {
  if (printerDict[name]) return printerDict[name];

  @Component
  class PrinterStore extends Vue {
    printer: PrintQueue = null;
  }
  const store = new PrinterStore({});

  @Component
  class PrintMixin extends Vue {
    printerServer: PrinterServer = null;

    async created() {
      if (!server) {
        server = await (
          await import("pos-printer")
        ).init(this, undefined, {
          localFonts: [
            {
              type: "local",
              name: "Noto Sans TC",
              url: require("!!file-loader!~/assets/fonts/noto-sans-tc-v35-chinese-traditional_latin-regular.woff2")
                .default,
              variant: "regular",
              weight: "regular",
              style: "normal",
              subsets: ["chinese-traditional", "latin"],
            },
            {
              type: "local",
              name: "Noto Sans TC",
              url: require("!!file-loader!~/assets/fonts/noto-sans-tc-v35-chinese-traditional_latin-700.woff2").default,
              variant: "700",
              weight: "700",
              style: "normal",
              subsets: ["chinese-traditional", "latin"],
            },
            {
              type: "local",
              name: "Noto Sans SC",
              url: require("!!file-loader!~/assets/fonts/noto-sans-sc-v36-chinese-simplified_latin-regular.woff2")
                .default,
              variant: "regular",
              weight: "regular",
              style: "normal",
              subsets: ["chinese-simplified", "latin"],
            },
            {
              type: "local",
              name: "Noto Sans SC",
              url: require("!!file-loader!~/assets/fonts/noto-sans-sc-v36-chinese-simplified_latin-700.woff2").default,
              variant: "700",
              weight: "700",
              style: "normal",
              subsets: ["chinese-simplified", "latin"],
            },
            {
              type: "local",
              name: "Noto Sans JP",
              url: require("!!file-loader!~/assets/fonts/noto-sans-jp-v52-japanese_latin-regular.woff2").default,
              variant: "regular",
              weight: "regular",
              style: "normal",
              subsets: ["japanese", "latin"],
            },
            {
              type: "local",
              name: "Noto Sans JP",
              url: require("!!file-loader!~/assets/fonts/noto-sans-jp-v52-japanese_latin-700.woff2").default,
              variant: "700",
              weight: "700",
              style: "normal",
              subsets: ["japanese", "latin"],
            },
            {
              type: "local",
              name: "Noto Sans KR",
              url: require("!!file-loader!~/assets/fonts/noto-sans-kr-v36-korean_latin-regular.woff2").default,
              variant: "regular",
              weight: "regular",
              style: "normal",
              subsets: ["korean", "latin"],
            },
            {
              type: "local",
              name: "Noto Sans KR",
              url: require("!!file-loader!~/assets/fonts/noto-sans-kr-v36-korean_latin-700.woff2").default,
              variant: "700",
              weight: "700",
              style: "normal",
              subsets: ["korean", "latin"],
            },
            {
              type: "local",
              name: "Noto Sans Thai",
              url: require("!!file-loader!~/assets/fonts/noto-sans-thai-v25-latin_thai-regular.woff2").default,
              variant: "regular",
              weight: "regular",
              style: "normal",
              subsets: ["thai", "latin"],
            },
            {
              type: "local",
              name: "Noto Sans Thai",
              url: require("!!file-loader!~/assets/fonts/noto-sans-thai-v25-latin_thai-700.woff2").default,
              variant: "700",
              weight: "700",
              style: "normal",
              subsets: ["thai", "latin"],
            },
            {
              type: "local",
              name: "Noto Sans",
              url: require("!!file-loader!~/assets/fonts/noto-sans-v36-cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese-regular.woff2")
                .default,
              variant: "regular",
              weight: "regular",
              style: "normal",
              subsets: [
                "cyrillic",
                "cyrillic-ext",
                "devanagari",
                "greek",
                "greek-ext",
                "latin",
                "latin-ext",
                "vietnamese",
              ],
            },
            {
              type: "local",
              name: "Noto Sans",
              url: require("!!file-loader!~/assets/fonts/noto-sans-v36-cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese-700.woff2")
                .default,
              variant: "700",
              weight: "700",
              style: "normal",
              subsets: [
                "cyrillic",
                "cyrillic-ext",
                "devanagari",
                "greek",
                "greek-ext",
                "latin",
                "latin-ext",
                "vietnamese",
              ],
            },
            {
              type: "local",
              name: "Noto Emoji",
              url: require("!!file-loader!~/assets/fonts/noto-emoji-v50-emoji-regular.woff2").default,
              variant: "regular",
              weight: "regular",
              style: "normal",
              subsets: ["emoji"],
            },
          ],
        });
        server.getAllTags = async function () {
          return [];
        };
        server.formatTag = function (tag) {
          return null;
        };
        const bbmsl = new BBPOS({
          parent: this,
        });
        server.callBBPOS = bbmsl.callPOS;
        this.printerServer = server;
      }
      if (server) {
        try {
          server.registerBitmap({
            tag: "LO",
            legacyId: 1,
            defaultImage: this.$thumb(this.$shop?.icon as any) || require("~/assets/images/logo.png"),
            name: "Logo",
            width: 144,
            hiRes: true,
          });
          server.registerBitmap({
            tag: "BO",
            legacyId: 2,
            defaultImage: require("~/assets/images/logo.png"),
            readonly: true,
            name: "BOXS Logo",
            width: 30,
            hiRes: true,
          });
        } catch (e) {}
        try {
          store.printer = await server.getQueue(type, undefined, "default");
          this.updateStatus();
          store.printer.printer.on("statusChanged", this.updateStatus);
        } catch (e) {
          console.warn(e);
        }
      }
      this.$root.$on("setPrinter", this.setPrinter);
      this.$root.$on("managePrinter", this.managePrinter);
    }

    beforeDestroy() {
      if (store.printer) {
        store.printer.server.removeListener("statusChanged", this.updateStatus);
      }
      this.$root.$off("setPrinter", this.setPrinter);
      this.$root.$off("managePrinter", this.managePrinter);
    }

    get cashier() {
      return this.$pos.cashier;
    }
    get posPrinter() {
      return this.cashier.posPrinter || (this as any).$shop.posPrinter;
    }

    get printerConnType() {
      return this.cashier.posPrinter || (this as any).$shop.printerConnType;
    }

    get printer() {
      return store.printer;
    }

    async setupPrinter() {
      if (!store.printer) {
        try {
          store.printer = await server.getQueue(type, true, name);
          store.printer.printer.on("statusChanged", this.updateStatus);
          this.updateStatus();
        } catch (e: any) {
          this.$store.commit("SET_ERROR", e.message);
          console.warn(e);
        }
      }
      if (!store.printer) {
        throw new Error("No Printer");
      }
      return store.printer;
    }

    async setPrinter() {
      try {
        if (store.printer) {
          if (store.printer.connected || store.printer.printer.connecting) {
            await store.printer.printer.disconnect(true);
          } else {
            await store.printer.printer.requestNewDevice();
            await store.printer.printer.init();
          }
        } else {
          await this.setupPrinter();
        }
      } catch (e: any) {
        this.$store.commit("SET_ERROR", e.message);
      }
    }

    async managePrinter() {
      try {
        await server.managePrint(type, type);
      } catch (e: any) {
        console.log(e.message);
      }
    }

    updateStatus() {
      this.$store.commit("SET_ACTION", {
        action: "setPrinter",
        longAction: "managePrinter",
        icon: "print",
        badge:
          this.printerConnType === "usb"
            ? "usb"
            : store.printer.connected
            ? "bluetooth_connected"
            : "bluetooth_searching",
        badgeColor: store.printer.connected ? "green" : "red",
      });
    }

    async openCashBox() {
      try {
        await this.printer.printer.cashBox();
      } catch (e) {
        this.$store.commit("SET_ERROR", e.message);
      }
    }

    async printJob(
      templateName: string,
      func: (sequence: PrintSequence, template: string) => PrintJob | Promise<PrintJob>,
      jobId?: string,
    ) {
      const queue = await this.setupPrinter();
      const locale = this?.$store?.state?.settings?.printLocale ?? this.$i18n?.locale ?? "en";
      await this.$i18n?.loadLocale?.(locale);

      const template = templateName
        ? (
            await this.$feathers.service("shop/printerTemplates").find({
              query: {
                tag: templateName,
                $paginate: false,
              },
              paginate: false,
            })
          )[0]?.template?.template
        : null;

      const vue = new Vue({
        parent: this,
        name: "PrintContext",
        i18n: new VueI18n({
          locale: locale,
          silentFallbackWarn: true,
          missing: (locale, key, vm, values) => {
            if (!Array.isArray(values)) values = values ? [values] : [];
            return this.$i18n.t(key, locale, ...values) as string;
          },
        }),
      });

      const sequence = queue.createSequence<PrintSequence>(vue);
      const jobItem = await func(sequence, template);
      await queue.print(jobItem);
    }
  }

  printerDict[name] = PrintMixin;
  return PrintMixin as any;
}
