
import _ from "lodash";
import uuid from "uuid/v4";
import draggable from "vuedraggable";
import { Component, Vue, Prop, FindType, FindPopRawType, VModel, Watch, checkID } from "@feathers-client";

@Component({
  components: {
    draggable,
  },
})
export default class DraggableSimpleList extends Vue {
  @VModel()
  inputValue: any[];

  @Prop()
  label: string;

  @Prop()
  default: any;

  @Prop({ type: Boolean, default: false })
  readonly: boolean;

  @Prop({ default: "_id", type: String })
  itemKey: string;

  @Prop()
  headerClass: any;

  @Prop()
  removeState:object;

  editing = false;
  active = 0;

  getDefault() {
    const v = this.default instanceof Function ? this.default() : this.default ? _.cloneDeep(this.default) : {};
    v[this.itemKey] = uuid();
    return v;
  }

  get dragOptions() {
    return {
      animation: 200,
      group: "description",
      disabled: false,
      ghostClass: "ghost",
      handle: ".handle",
    };
  }

  toggleEdit() {
    this.editing = !this.editing;
    if (!this.editing) {
      this.$emit("done");
    }
  }

  remove(idx) {
    const item = this.inputValue[idx];
    this.inputValue = [...this.inputValue.slice(0, idx), ...this.inputValue.slice(idx + 1)];
    this.$emit("remove", item);
    // console.log("remove", item);
    // console.log(typeof idx);
    
  }
}
