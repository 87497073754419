
import { Vue, Component, Prop, FindType } from "@feathers-client";

@Component
export default class HistoryItem extends Vue {
  @Prop()
  item: FindType<"shop/orders">;

  get statusColor() {
    switch(this.item.status) {
      case 'pending':
      case 'partial':
        return this.$config.colors.red300;
      case 'done':
      case 'confirmed':
        return this.$config.colors.green200;

      default:
        return this.$config.colors.grey600;
    }
  }
}
