
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import _ from 'lodash'
import { PriceOptionType } from '@common/common'

@Component
export default class PriceLineName extends Vue {

    @Prop({ })
    value: PriceOptionType

}

