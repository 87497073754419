<template>
    <data-table 
        actions 
        class="mt-6"
        :data="{
            subpath: 'separateProducts',
            name: { $t: 'edit_product.separateProducts' }
        }" 
        :headers="[
            { text: $t('edit_product.name'), sortable: false, value: 'product', source: 'products', select: ['name'], path: null, format: 'tf', tpath: 'name', direct: true, filter: { $shop:true, }  },
            { text: $t('edit_product.sku.inventory.sku'), sortable: false, value: 'sku', format: 'tf', tpath: 'name', computed: true },
            { text: $t('edit_product.separateRatio'), sortable: false, value: 'ratio' },
        ]"
        :default="{
            product: null,
            sku: null,
            ratio: 0,
        }"
        :paginate="0"
        v-model="item"
        :compute="(item, self) => ({
            get product() {
                return self.get(item, {
                    value: 'product',
                    source: 'products',
                    filter: { $shop:true, },
                    select: ['name'],
                    path: null,
                }, true);
            },

            get productId() {
                return item.product
            },

            set productId(v) {
                item.product = v;
                item.sku = null;
            },
        })"
    >
        <template slot="editor" slot-scope="edit">
            <object-picker
                :label="$t('edit_product.sku.inventory.product')" 
                v-model="edit.computed.productId" 
                path="products" 
                :args="{$shop:true, }"
                item-text="name"
            />
            <object-picker
                :label="$t('edit_product.sku.inventory.sku')" 
                v-model="edit.item.sku" 
                v-if="edit.computed.product && edit.computed.product.skus && edit.computed.product.skus.length"
                :items="edit.computed.product && edit.computed.product.skus || []"
                :args="{$shop:true, }"
                :readonly="!!edit.item._id"
                item-value="id"
                item-text="name"
            />

            <v-text-field :label="$t('edit_product.separateRatio')" v-model.number="edit.item.ratio"/>

        </template>
    </data-table>
</template>

<script>

export default {
    props: {
        item: {},
    }
}

</script>
