
import { Component, Vue, Prop, Watch, FindType } from "@feathers-client";
import VueApexCharts from "vue-apexcharts";
import Percentage from "../Percentage.vue";
import _, { keyBy } from "lodash";
import moment from "moment";

interface OrderItem {
  _id: string;
  totalInt: number;
  discountInt: number;
  quantity: number;
  cpriceInt: number;
  num: number;
}

interface Order {
  _id: null;
  totalInt: number;
  discountInt: number;
  quantity: number;
  cpriceInt: number;
  tree: null;
  treeName: null;
  items: OrderItem[];
}

@Component({
  components: {
    Percentage,
    VueApexCharts,
  },
})
export default class NewDoughnutChart extends Vue {
  @Prop({ default: "" }) title!: string;
  @Prop({ default: "" }) type!: string;
  @Prop({ default: "" }) mode!: string;
  @Prop() dataPrevious: Order;
  @Prop() data: Order;

  seriesData: any
  formatDataObject = [];
  pieChartCutoff: number = 0.99;
  formatPreviousDateObject = [];
  shop: FindType<"shops"> = null;
  pieColor = ["#7369DE", "#FF9B33", "#FFD966", "#DB7070", "#A3D7F5", "#4BC77F"];

  get discountPieChart() {
    let a = this.groupData(this.data)
    let v = {
      labels: a.map(v => this.$td(v.name)),
      series: a.map(v => v.value)
    }
    return {
      chart: {
        width: this.$vuetify.breakpoint.mdAndDown ? "100%" : 400,
        type: "donut",
      },
      colors: this.pieColor,
      legend: {
        position: "bottom",
        // horizontalAlign: "right",
        offsetY: 5,

      },
      fill: {
        opacity: 1,
      },
      noData: {
        text: this.$t("cahrtMessage.noData"),
      },
      series: v.series, //[44, 55, 13, 43, 22], //a.series,
      labels: v.labels, //['Team A', 'Team B', 'Team C', 'Team D', 'Team E'], //a.labels,
    };
  }

  get count() {
    let totalInt: Number = Object.values(this.data).length;
    return totalInt
  }
  // get count() {
  //   let totalInt: Number = Object.values(this.data).reduce((acc, it) => { return acc + it.totalInt }, 0);
  //   let discountInt: Number = Object.values(this.data).reduce((acc, it) => { return acc - it.discountInt }, 0);
  //   return (totalInt - discountInt)
  // }
  // get countPrevious() {
  //   let totalInt: Number = Object.values(this.dataPrevious).reduce((acc, it) => { return acc + it.totalInt }, 0);
  //   let discountInt: Number = Object.values(this.dataPrevious).reduce((acc, it) => { return acc - it.discountInt }, 0);
  //   return (totalInt - discountInt)
  // }
  groupData(data) {
    if (data) {
      const grouped = []
      data.forEach(item => {
        const name = item.treeName ? item.treeName[0][0].value : this.$t("basic.other")
        if (!grouped[name]) {
          grouped.unshift({
            name,
            value: (item.totalInt + item.discountInt) / 10000
          })
        } else {
          ((grouped[name].totalInt += item.totalInt) + (grouped[name].discountInt += item.discountInt)) / 10000
        }
        
      })
      return grouped
    }
    else {
      return [{ name: 'no data', value: 0 }]
    }
  }
}
