
import { Component, Vue, Prop, FindType, Watch, checkID, PropSync } from "@feathers-client";
@Component
export default class ShipmentScheduleFilter extends Vue {
  @PropSync("deliveryCar")
  deliveryCarSync: string;

  @PropSync("deliveryDate")
  deliveryDateSync: string;
}
