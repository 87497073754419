<script>
import { Line, mixins } from "vue-chartjs";
import reactive from './reactive'

export default {
    extends: Line,
    mixins: [reactive({chartData: 'mchartData', options: 'moptions'})],
    props: ["max"],
    computed: {
        moptions() {
            return _.merge(
                {
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                        display: false
                    },
                    scales: {
                        xAxes: [
                            {
                                display: false,

                                stacked: false,
                                beginAtZero: true,
                                ticks: {
                                    stepSize: 1,
                                    min: 0,
                                    autoSkip: false
                                }
                            }
                        ],
                        yAxes: [
                            {
                                display: false,
                                ticks: {
                                    max: this.max || 0,
                                }
                            }
                        ]
                    },
                    layout: {
                        padding: {
                            left: 5,
                            right: 5,
                            top: 5,
                            bottom: 5
                        }
                    },
                    tooltips: {
                        enabled: false,
                    },
                },
                this.options || {}
            );
        },
        mchartData() {
            return {
                labels: this.chartData,
                datasets: [
                    {
                        backgroundColor: this.gradient,
                        data: this.chartData
                    }
                ]
            };
        }
    },
    data() {
        return {
            gradient: null
        };
    },
    mounted() {
        const ctx = this.$el.getElementsByTagName("canvas")[0].getContext("2d");
        this.gradient = ctx.createLinearGradient(0, 0, 0, 50);

        this.gradient.addColorStop(0, "rgba(3, 85, 179, 0.9)");
        this.gradient.addColorStop(0.5, "rgba(3, 85, 179, 0.5)");
        this.gradient.addColorStop(1, "rgba(2, 27, 121, 0.25)");

        this.renderChart(this.mchartData, this.moptions);
    }
};
</script>