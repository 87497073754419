import moment from "moment";
import _ from "lodash";

export type LangArrType = { lang: string; value: string }[];
export type LangType = LangArrType | string;

export function translate(text: LangArrType | string, loc: string) {
  if (typeof text === "string") return text;
  let enValue, locValue, defValue;
  _.each(text, v => {
    if (v.lang === loc) locValue = v.value;
    if (v.lang === "en") enValue = v.value;
    defValue = v.value;
  });
  return locValue || enValue || defValue || "";
}

export function getLangs(value: LangType) {
  if (Array.isArray(value)) {
    return value.map(it => it.lang);
  } else if (value && typeof value === "object") {
    return Object.keys(value);
  } else return [];
}

export function langJoin(join: string, ...values: LangType[]): LangArrType {
  if (!values || !values.length) return [];
  const uniqLang = _.uniq(_.flatMap(values, getLangs));
  if (!uniqLang.length) {
    return [{ lang: "en", value: values.join(join) }];
  }
  return uniqLang.map(lang => ({
    lang,
    value: values.map(v => translate(v, lang)).join(join),
  }));
}

function translateItem(text: string, params: { [key: string]: any }, lang: string): string {
  return text.replace(/\{(.+?)\}/g, (cap, gp) => {
    const args = gp.split("||");
    for (let arg of args) {
      const part = arg.trim().split("|");
      let val = _.get(params, part[0].trim());
      if (val) {
        switch ((part[1] || "").trim()) {
          case "enum": {
            const map = (part[2] || "").split(";");
            const it = map.find(it => it.split(",")[0] === val);
            if (it) {
              val = it.split(",")[1];
            }
            break;
          }
        }
        if (!Array.isArray(val)) val = `${val}`;
        return translate(val, lang);
      } else if ((part[1] || "").trim() === "default") {
        return (part[2] || "").trim();
      }
    }
    return "";
  });
}

export function formatTranslate(text: LangArrType, params: { [key: string]: any }): LangArrType {
  return (text || []).map(it => ({
    lang: it.lang,
    value: translateItem(it.value, params, it.lang),
  }));
}

export function createContext(parent: any, data: any) {
  const context = _.cloneDeep(data);

  context.$t = parent.$t.bind(parent);
  context.$td = parent.$td.bind(parent);
  context.$concat = function (...items) {
    return _.flatten(items);
  };
  context.$sumBy = function (list, key) {
    return _.sumBy(list, key);
  };
  context.$toFixed = function (n, c) {
    return (+n).toFixed(c);
  };
  context.$date = function (d, format, dformat, lang) {
    if (lang) {
      return moment(d, dformat).locale(lang).format(format);
    }
    return moment(d, dformat).format(format);
  };
  context.$price = (parent as any).$price?.bind?.(parent);
  if ((parent as any).$price2) {
    context.$price2 = (parent as any).$price2.bind(parent);
  }
  context.$formatTranslate = formatTranslate;
  context.$checkID = checkID;
  context.$getID = getID;
  context.$slice = _.slice;
  context.$push = function (list, ...items) {
    if (list) {
      list.push(...items);
    }
  };
  context.$join = function (list, c) {
    if (list) {
      return list.join(c);
    }
    return "";
  };
  context.$upper = function (text) {
    return String(text || "").toUpperCase();
  };
  context.$lower = function (text) {
    return String(text || "").toLowerCase();
  };
  context.$filter = (item: any[], key: string, value?: any, not?: boolean) => {
    return Array.isArray(item)
      ? item.filter(it =>
          value === undefined ? (not ? !it?.[key] : it?.[key]) : not ? it?.[key] !== value : it?.[key] === value,
        )
      : [];
  };
  context.$round = (num: number, digits: number) => {
    return +num.toFixed(digits);
  }
  return context;
}

function getIDCore(item: string | null | ObjectID<any>): string | null;
function getIDCore(item: any): string | null;
function getIDCore<T extends { _id: ObjectID<any> }>(item: T): string;
function getIDCore<T extends { _id: any }>(item: T): string;

function getIDCore(item: any): string | null {
  if (!item) {
    return null;
  } else if (typeof item === "string") {
    return item;
  } else if (typeof item === "number") {
    return `${item}`;
  } else if (item._id !== undefined) {
    // fix server usage
    return item._id ? `${item._id}` : null;
  } else {
    console.warn("Unknown id type", item);
    throw new Error("Unknown id type");
  }
}

type ObjectID<T> = any;

function checkIDCore(a: any, b: any): boolean;
function checkIDCore<T extends { _id: ObjectID<any> }>(
  a: string | ObjectID<any> | T | null,
  b: string | ObjectID<any> | T | null | ObjectID<any>,
): boolean;
function checkIDCore<T extends { _id: ObjectID<any> }, T2 extends { _id: ObjectID<any> }>(
  a: string | T | null | ObjectID<any>,
  b: string | T2 | null | ObjectID<any>,
): boolean;
function checkIDCore<T extends { _id: any }>(a: string | T | null, b: string | T | null | ObjectID<any>): boolean;
function checkIDCore<T extends { _id: any }, T2 extends { _id: ObjectID<any> }>(
  a: string | T | null | ObjectID<any>,
  b: string | T2 | null | ObjectID<any>,
): boolean;

function checkIDCore(a: any, b: any): boolean {
  return getIDCore(a) === getIDCore(b);
}

export const getID = getIDCore;
export const checkID = checkIDCore;
