

import { Component, Vue, Prop } from '@feathers-client'
import { EditorField } from './plugin'
import components from './components'
import _ from 'lodash'

export function compareLang(
    a: any,
    b: any,
) {
    const at = _.sortBy(a, a => a.lang).map(it => `${it.lang}:${it.value}`).join('.');
    const bt = _.sortBy(b, a => a.lang).map(it => `${it.lang}:${it.value}`).join('.');

    return at === bt;
}


export const containers : Record<string, boolean> = {
    'editor-group': true,
};


export const primitiveComponents : Record<string, boolean> = {
    'v-text-field': true,
};

export const primitiveTypes : Record<string, boolean> = {
    'string': true,
    'number': true,
};

@Component({
    components: components
})
export default class EditorMulti extends Vue {
    @Prop()
    field : EditorField

    @Prop()
    edit : any

    @Prop()
    slots : any

    @Prop({ type: String, default: '' })
    parentPath: string

    @Prop()
    items: any[]

    @Prop()
    formatHeader: (item: any) => string

    get isContainer() {
        return containers[this.field.component]
    }
    get props() {
        return this.isContainer ? {
            ...this.field.props,
            // ...(this.field.propsFunc ? this.field.propsFunc(this.condCtx) : {}),
            ...(this.field.nameField && this.field.name
                ? {
                        [this.field.nameField]: typeof this.field.name === 'string' ? this.$t(this.field.name) : this.$td(this.field.name),
                    }
                : {}),
        } : {}
    }

    get compareFunc() {
        const isLang = this.field.component === 'editor-translate-box';
        const isPrimitive = primitiveComponents[this.field?.component] ?? primitiveTypes[`${this.field?.schema?.type}`]

        if(isLang) return compareLang;
        if(isPrimitive) return (a,b) => {
            return a === b;
        }

        return (a,b) => {

            return false;
        }
    }

    get getFunc() {
        return (item) => {
            return this.field.path === '' ? item : this.field.path ? item[this.field.path] : null
        }
    }

    selectedGroup: any[] = null;

    get mergedItems() {
        const groups: any[][] = [];

        for(let item of this.items) {
            let group = groups.find(it => this.compareFunc(this.getFunc(it[0]), this.getFunc(item)));
            if(!group) {
                groups.push(group = []);
            }
            group.push(item);
        }

        return groups;
    }

    mounted() {
        if(this.mergedItems.length) {
            this.selectedGroup = this.mergedItems[0];
        }
    }
}

