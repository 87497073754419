import {
  NativeBluetoothDevice,
  getVersion,
  lockBluetooth,
  sendBluetoothPick,
  setBluetoothPicker,
  unlockBluetooth,
} from "../nativeIntegrations";
import { ns } from "../messageQueue";

let installPromise: Promise<boolean>;

export function install() {
  return installPromise || (installPromise = installCore());
}

function btDeviceNameIsOk(name: string) {
  "use strict";
  let nameUTF8len = Buffer.from(name).length;
  return nameUTF8len <= 248 && nameUTF8len >= 0;
}

function canonicalUUID(uuidAlias: number) {
  // https://www.bluetooth.com/specifications/assigned-numbers/service-discovery
  uuidAlias >>>= 0; // Make sure the number is positive and 32 bits.
  let strAlias = `0000000${uuidAlias.toString(16)}`;
  strAlias = strAlias.substr(-8);
  return strAlias + "-0000-1000-8000-00805f9b34fb";
}

class EventDispatcher {
  _listeners: any[];

  constructor() {
    this._listeners = [];
  }

  hasEventListener(type, listener, useCapture?) {
    return this._listeners.some(item => item.type === type && item.listener === listener);
  }

  addEventListener(type, listener, useCapture?) {
    if (!this.hasEventListener(type, listener, useCapture)) {
      this._listeners.push({ type, listener, options: { once: false } });
    }
    // console.log(`${this}-listeners:`,this._listeners);
    return this;
  }

  removeEventListener(type, listener, useCapture?) {
    let index = this._listeners.findIndex(item => item.type === type && item.listener === listener);
    if (index >= 0) this._listeners.splice(index, 1);
    //        console.log(`${this}-listeners:`, this._listeners);
    return this;
  }

  removeEventListeners() {
    this._listeners = [];
    return this;
  }

  dispatchEvent(evt) {
    this._listeners
      .filter(item => item.type === evt.type)
      .forEach(item => {
        const {
          type,
          listener,
          options: { once },
        } = item;
        listener.call(this, evt);
        if (once === true) this.removeEventListener(type, listener);
      });
    // console.log(`${this}-listeners:`,this._listeners);
    return this;
  }
}

const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
const shortUUIDRegex = /^[0-9a-f]{4}([0-9a-f]{4})?$/i;

let BluetoothUUID: {
  [table: string]: {
    [name: string]: string;
  };
} = {};
BluetoothUUID.service = {
  alert_notification: canonicalUUID(0x1811),
  automation_io: canonicalUUID(0x1815),
  battery_service: canonicalUUID(0x180f),
  blood_pressure: canonicalUUID(0x1810),
  body_composition: canonicalUUID(0x181b),
  bond_management: canonicalUUID(0x181e),
  continuous_glucose_monitoring: canonicalUUID(0x181f),
  current_time: canonicalUUID(0x1805),
  cycling_power: canonicalUUID(0x1818),
  cycling_speed_and_cadence: canonicalUUID(0x1816),
  device_information: canonicalUUID(0x180a),
  environmental_sensing: canonicalUUID(0x181a),
  generic_access: canonicalUUID(0x1800),
  generic_attribute: canonicalUUID(0x1801),
  glucose: canonicalUUID(0x1808),
  health_thermometer: canonicalUUID(0x1809),
  heart_rate: canonicalUUID(0x180d),
  human_interface_device: canonicalUUID(0x1812),
  immediate_alert: canonicalUUID(0x1802),
  indoor_positioning: canonicalUUID(0x1821),
  internet_protocol_support: canonicalUUID(0x1820),
  link_loss: canonicalUUID(0x1803),
  location_and_navigation: canonicalUUID(0x1819),
  next_dst_change: canonicalUUID(0x1807),
  phone_alert_status: canonicalUUID(0x180e),
  pulse_oximeter: canonicalUUID(0x1822),
  reference_time_update: canonicalUUID(0x1806),
  running_speed_and_cadence: canonicalUUID(0x1814),
  scan_parameters: canonicalUUID(0x1813),
  tx_power: canonicalUUID(0x1804),
  user_data: canonicalUUID(0x181c),
  weight_scale: canonicalUUID(0x181d),
};

BluetoothUUID.characteristic = {
  aerobic_heart_rate_lower_limit: canonicalUUID(0x2a7e),
  aerobic_heart_rate_upper_limit: canonicalUUID(0x2a84),
  aerobic_threshold: canonicalUUID(0x2a7f),
  age: canonicalUUID(0x2a80),
  aggregate: canonicalUUID(0x2a5a),
  alert_category_id: canonicalUUID(0x2a43),
  alert_category_id_bit_mask: canonicalUUID(0x2a42),
  alert_level: canonicalUUID(0x2a06),
  alert_notification_control_point: canonicalUUID(0x2a44),
  alert_status: canonicalUUID(0x2a3f),
  altitude: canonicalUUID(0x2ab3),
  anaerobic_heart_rate_lower_limit: canonicalUUID(0x2a81),
  anaerobic_heart_rate_upper_limit: canonicalUUID(0x2a82),
  anaerobic_threshold: canonicalUUID(0x2a83),
  analog: canonicalUUID(0x2a58),
  apparent_wind_direction: canonicalUUID(0x2a73),
  apparent_wind_speed: canonicalUUID(0x2a72),
  "gap.appearance": canonicalUUID(0x2a01),
  barometric_pressure_trend: canonicalUUID(0x2aa3),
  battery_level: canonicalUUID(0x2a19),
  blood_pressure_feature: canonicalUUID(0x2a49),
  blood_pressure_measurement: canonicalUUID(0x2a35),
  body_composition_feature: canonicalUUID(0x2a9b),
  body_composition_measurement: canonicalUUID(0x2a9c),
  body_sensor_location: canonicalUUID(0x2a38),
  bond_management_control_point: canonicalUUID(0x2aa4),
  bond_management_feature: canonicalUUID(0x2aa5),
  boot_keyboard_input_report: canonicalUUID(0x2a22),
  boot_keyboard_output_report: canonicalUUID(0x2a32),
  boot_mouse_input_report: canonicalUUID(0x2a33),
  "gap.central_address_resolution_support": canonicalUUID(0x2aa6),
  cgm_feature: canonicalUUID(0x2aa8),
  cgm_measurement: canonicalUUID(0x2aa7),
  cgm_session_run_time: canonicalUUID(0x2aab),
  cgm_session_start_time: canonicalUUID(0x2aaa),
  cgm_specific_ops_control_point: canonicalUUID(0x2aac),
  cgm_status: canonicalUUID(0x2aa9),
  csc_feature: canonicalUUID(0x2a5c),
  csc_measurement: canonicalUUID(0x2a5b),
  current_time: canonicalUUID(0x2a2b),
  cycling_power_control_point: canonicalUUID(0x2a66),
  cycling_power_feature: canonicalUUID(0x2a65),
  cycling_power_measurement: canonicalUUID(0x2a63),
  cycling_power_vector: canonicalUUID(0x2a64),
  database_change_increment: canonicalUUID(0x2a99),
  date_of_birth: canonicalUUID(0x2a85),
  date_of_threshold_assessment: canonicalUUID(0x2a86),
  date_time: canonicalUUID(0x2a08),
  day_date_time: canonicalUUID(0x2a0a),
  day_of_week: canonicalUUID(0x2a09),
  descriptor_value_changed: canonicalUUID(0x2a7d),
  "gap.device_name": canonicalUUID(0x2a00),
  dew_point: canonicalUUID(0x2a7b),
  digital: canonicalUUID(0x2a56),
  dst_offset: canonicalUUID(0x2a0d),
  elevation: canonicalUUID(0x2a6c),
  email_address: canonicalUUID(0x2a87),
  exact_time_256: canonicalUUID(0x2a0c),
  fat_burn_heart_rate_lower_limit: canonicalUUID(0x2a88),
  fat_burn_heart_rate_upper_limit: canonicalUUID(0x2a89),
  firmware_revision_string: canonicalUUID(0x2a26),
  first_name: canonicalUUID(0x2a8a),
  five_zone_heart_rate_limits: canonicalUUID(0x2a8b),
  floor_number: canonicalUUID(0x2ab2),
  gender: canonicalUUID(0x2a8c),
  glucose_feature: canonicalUUID(0x2a51),
  glucose_measurement: canonicalUUID(0x2a18),
  glucose_measurement_context: canonicalUUID(0x2a34),
  gust_factor: canonicalUUID(0x2a74),
  hardware_revision_string: canonicalUUID(0x2a27),
  heart_rate_control_point: canonicalUUID(0x2a39),
  heart_rate_max: canonicalUUID(0x2a8d),
  heart_rate_measurement: canonicalUUID(0x2a37),
  heat_index: canonicalUUID(0x2a7a),
  height: canonicalUUID(0x2a8e),
  hid_control_point: canonicalUUID(0x2a4c),
  hid_information: canonicalUUID(0x2a4a),
  hip_circumference: canonicalUUID(0x2a8f),
  humidity: canonicalUUID(0x2a6f),
  "ieee_11073-20601_regulatory_certification_data_list": canonicalUUID(0x2a2a),
  indoor_positioning_configuration: canonicalUUID(0x2aad),
  intermediate_blood_pressure: canonicalUUID(0x2a36),
  intermediate_temperature: canonicalUUID(0x2a1e),
  irradiance: canonicalUUID(0x2a77),
  language: canonicalUUID(0x2aa2),
  last_name: canonicalUUID(0x2a90),
  latitude: canonicalUUID(0x2aae),
  ln_control_point: canonicalUUID(0x2a6b),
  ln_feature: canonicalUUID(0x2a6a),
  "local_east_coordinate.xml": canonicalUUID(0x2ab1),
  local_north_coordinate: canonicalUUID(0x2ab0),
  local_time_information: canonicalUUID(0x2a0f),
  location_and_speed: canonicalUUID(0x2a67),
  location_name: canonicalUUID(0x2ab5),
  longitude: canonicalUUID(0x2aaf),
  magnetic_declination: canonicalUUID(0x2a2c),
  magnetic_flux_density_2D: canonicalUUID(0x2aa0),
  magnetic_flux_density_3D: canonicalUUID(0x2aa1),
  manufacturer_name_string: canonicalUUID(0x2a29),
  maximum_recommended_heart_rate: canonicalUUID(0x2a91),
  measurement_interval: canonicalUUID(0x2a21),
  model_number_string: canonicalUUID(0x2a24),
  navigation: canonicalUUID(0x2a68),
  new_alert: canonicalUUID(0x2a46),
  "gap.peripheral_preferred_connection_parameters": canonicalUUID(0x2a04),
  "gap.peripheral_privacy_flag": canonicalUUID(0x2a02),
  plx_continuous_measurement: canonicalUUID(0x2a5f),
  plx_features: canonicalUUID(0x2a60),
  plx_spot_check_measurement: canonicalUUID(0x2a5e),
  pnp_id: canonicalUUID(0x2a50),
  pollen_concentration: canonicalUUID(0x2a75),
  position_quality: canonicalUUID(0x2a69),
  pressure: canonicalUUID(0x2a6d),
  protocol_mode: canonicalUUID(0x2a4e),
  rainfall: canonicalUUID(0x2a78),
  "gap.reconnection_address": canonicalUUID(0x2a03),
  record_access_control_point: canonicalUUID(0x2a52),
  reference_time_information: canonicalUUID(0x2a14),
  report: canonicalUUID(0x2a4d),
  report_map: canonicalUUID(0x2a4b),
  resting_heart_rate: canonicalUUID(0x2a92),
  ringer_control_point: canonicalUUID(0x2a40),
  ringer_setting: canonicalUUID(0x2a41),
  rsc_feature: canonicalUUID(0x2a54),
  rsc_measurement: canonicalUUID(0x2a53),
  sc_control_point: canonicalUUID(0x2a55),
  scan_interval_window: canonicalUUID(0x2a4f),
  scan_refresh: canonicalUUID(0x2a31),
  sensor_location: canonicalUUID(0x2a5d),
  serial_number_string: canonicalUUID(0x2a25),
  "gatt.service_changed": canonicalUUID(0x2a05),
  software_revision_string: canonicalUUID(0x2a28),
  sport_type_for_aerobic_and_anaerobic_thresholds: canonicalUUID(0x2a93),
  supported_new_alert_category: canonicalUUID(0x2a47),
  supported_unread_alert_category: canonicalUUID(0x2a48),
  system_id: canonicalUUID(0x2a23),
  temperature: canonicalUUID(0x2a6e),
  temperature_measurement: canonicalUUID(0x2a1c),
  temperature_type: canonicalUUID(0x2a1d),
  three_zone_heart_rate_limits: canonicalUUID(0x2a94),
  time_accuracy: canonicalUUID(0x2a12),
  time_source: canonicalUUID(0x2a13),
  time_update_control_point: canonicalUUID(0x2a16),
  time_update_state: canonicalUUID(0x2a17),
  time_with_dst: canonicalUUID(0x2a11),
  time_zone: canonicalUUID(0x2a0e),
  true_wind_direction: canonicalUUID(0x2a71),
  true_wind_speed: canonicalUUID(0x2a70),
  two_zone_heart_rate_limit: canonicalUUID(0x2a95),
  tx_power_level: canonicalUUID(0x2a07),
  uncertainty: canonicalUUID(0x2ab4),
  unread_alert_status: canonicalUUID(0x2a45),
  user_control_point: canonicalUUID(0x2a9f),
  user_index: canonicalUUID(0x2a9a),
  uv_index: canonicalUUID(0x2a76),
  vo2_max: canonicalUUID(0x2a96),
  waist_circumference: canonicalUUID(0x2a97),
  weight: canonicalUUID(0x2a98),
  weight_measurement: canonicalUUID(0x2a9d),
  weight_scale_feature: canonicalUUID(0x2a9e),
  wind_chill: canonicalUUID(0x2a79),
};

BluetoothUUID.descriptor = {
  "gatt.characteristic_extended_properties": canonicalUUID(0x2900),
  "gatt.characteristic_user_description": canonicalUUID(0x2901),
  "gatt.client_characteristic_configuration": canonicalUUID(0x2902),
  "gatt.server_characteristic_configuration": canonicalUUID(0x2903),
  "gatt.characteristic_presentation_format": canonicalUUID(0x2904),
  "gatt.characteristic_aggregate_format": canonicalUUID(0x2905),
  valid_range: canonicalUUID(0x2906),
  external_report_reference: canonicalUUID(0x2907),
  report_reference: canonicalUUID(0x2908),
  value_trigger_setting: canonicalUUID(0x290a),
  es_configuration: canonicalUUID(0x290b),
  es_measurement: canonicalUUID(0x290c),
  es_trigger_setting: canonicalUUID(0x290d),
};

function resolveUUIDName(tableName: string) {
  let table = BluetoothUUID[tableName];
  return function (name: string | number) {
    if (typeof name === "number") {
      return canonicalUUID(name);
    }
    if (uuidRegex.test(name)) {
      //note native IOS bridges converts to uppercase since IOS seems to demand this.
      return name.toLowerCase();
    }
    if (table.hasOwnProperty(name)) {
      return table[name];
    }
    if (shortUUIDRegex.test(name)) {
      // this is not in the spec,
      // https://webbluetoothcg.github.io/web-bluetooth/#resolveuuidname
      // but iOS sends us short UUIDs and so we need to handle it.
      return canonicalUUID(parseInt(name, 16));
    }
    throw new TypeError(`${name} is not a known ${tableName} name.`);
  };
}

const getService = resolveUUIDName("service");
const getCharacteristic = resolveUUIDName("characteristic");
const getDescriptor = resolveUUIDName("descriptor");

function canonicaliseFilter(filter) {
  "use strict";
  // implemented as far as possible as per
  // https://webbluetoothcg.github.io/web-bluetooth/#bluetoothlescanfilterinit-canonicalizing
  const services = filter.services;
  const name = filter.name;
  if (name !== undefined && !btDeviceNameIsOk(name)) {
    throw new TypeError(`Invalid filter name ${name}`);
  }
  const namePrefix = filter.namePrefix;
  if (namePrefix !== undefined && (!btDeviceNameIsOk(namePrefix) || Buffer.from(namePrefix).length === 0)) {
    throw new TypeError(`Invalid filter namePrefix ${namePrefix}`);
  }

  let canonicalizedFilter: any = { name, namePrefix };

  if (services === undefined && name === undefined && namePrefix === undefined) {
    throw new TypeError("Filter has no usable properties");
  }
  if (services !== undefined) {
    if (!services) {
      throw new TypeError("Filter has empty services");
    }
    let cservs = services.map(getService);
    canonicalizedFilter.services = cservs;
  }

  return canonicalizedFilter;
}

type UnionToIntersection<T> = (T extends any ? (x: T) => any : never) extends (x: infer R) => any ? R : never;

class BluetoothRemoteGATTCharacteristicImpl extends EventDispatcher {
  constructor(
    parent: BluetoothRemoteGATTServiceImpl,
    opts: {
      uuid: string;
      properties: any;
    },
  ) {
    super();
    this.uuid = opts.uuid;
    this.service = <any>parent;
    this.properties = opts.properties;
    ns("bluetooth").on(`char/${this.service.device.id}/${this.service.uuid}/${this.uuid}`, v => {
      this.value = new DataView(Buffer.from(v || "", "base64").buffer);
      if (this.oncharacteristicvaluechanged) this.oncharacteristicvaluechanged(new Event("characteristicvaluechanged"));
      this.dispatchEvent(new Event("characteristicvaluechanged"));
    });
  }

  oncharacteristicvaluechanged: (this: this, ev: Event) => any;

  readonly service?: BluetoothRemoteGATTService;
  readonly uuid: string;
  readonly properties: BluetoothCharacteristicProperties;
  value?: DataView;
  async getDescriptor(descriptor: BluetoothDescriptorUUID): Promise<BluetoothRemoteGATTDescriptor> {
    console.warn("getDescriptor not implemented");
    return null;
  }
  async getDescriptors(descriptor?: BluetoothDescriptorUUID): Promise<BluetoothRemoteGATTDescriptor[]> {
    console.warn("getDescriptors not implemented");
    return [];
  }
  async readValue(): Promise<DataView> {
    let d = await ns("bluetooth").call<number[]>("read", this.service.device.id, this.service.uuid, this.uuid);
    if (typeof d === "object" && !Array.isArray(d)) {
      // fix bug when object is returned instead of array
      d = Object.values(d);
    }
    const v = new Uint8Array(d);
    return (this.value = new DataView(v.buffer));
  }
  async writeValue(value: BufferSource): Promise<void> {
    await ns("bluetooth").call<Buffer>(
      "write",
      this.service.device.id,
      this.service.uuid,
      this.uuid,
      Array.prototype.slice.call(Buffer.from(<any>value)),
    );
  }
  async writeValueWithResponse(value: BufferSource): Promise<void> {
    await ns("bluetooth").call<Buffer>(
      "write",
      this.service.device.id,
      this.service.uuid,
      this.uuid,
      Array.prototype.slice.call(Buffer.from(<any>value)),
    );
  }
  async writeValueWithoutResponse(value: BufferSource): Promise<void> {
    await ns("bluetooth").call<Buffer>(
      "write2",
      this.service.device.id,
      this.service.uuid,
      this.uuid,
      Array.prototype.slice.call(Buffer.from(<any>value)),
    );
  }
  notifying = false;
  async startNotifications(): Promise<BluetoothRemoteGATTCharacteristic> {
    if (!this.notifying) {
      this.notifying = true;
      await ns("bluetooth").call("startNotifications", this.service.device.id, this.service.uuid, this.uuid);
    }
    return <any>this;
  }
  async stopNotifications(): Promise<BluetoothRemoteGATTCharacteristic> {
    if (this.notifying) {
      this.notifying = false;
      await ns("bluetooth").call("stopNotifications", this.service.device.id, this.service.uuid, this.uuid);
    }
    return <any>this;
  }
}

class BluetoothRemoteGATTServiceImpl extends EventDispatcher {
  constructor(
    server: BluetoothRemoteGATTServerImpl,
    opts: {
      uuid: string;
      isPrimary: boolean;
      chars: any;
    },
  ) {
    super();
    this.device = server.device;
    this.uuid = opts.uuid;
    this.isPrimary = opts.isPrimary;
    this.chars = opts.chars;
  }

  oncharacteristicvaluechanged: (this: this, ev: Event) => any;
  onserviceadded: (this: this, ev: Event) => any;
  onservicechanged: (this: this, ev: Event) => any;
  onserviceremoved: (this: this, ev: Event) => any;

  chars?: {
    uuid: string;
    descriptors: any;
    properties: any;
  }[];
  readonly device: BluetoothDeviceImpl;
  readonly uuid: string;
  readonly isPrimary: boolean;

  charDict?: {
    [key: string]: BluetoothRemoteGATTCharacteristicImpl;
  } = {};

  async getCharacteristic(characteristic: BluetoothCharacteristicUUID): Promise<BluetoothRemoteGATTCharacteristic> {
    const item = this.chars.find(it => it.uuid === characteristic);
    if (!item) {
      const e: any = new Error(`Characteristic ${characteristic} not found`);
      e.code = 8;
      throw e;
    }
    if (item) {
      return <any>(
        (this.charDict[item.uuid] || (this.charDict[item.uuid] = new BluetoothRemoteGATTCharacteristicImpl(this, item)))
      );
    }
    return null;
  }
  async getCharacteristics(characteristic?: BluetoothCharacteristicUUID): Promise<BluetoothRemoteGATTCharacteristic[]> {
    const items = this.chars.filter(it => !characteristic || it.uuid === characteristic);
    return items.map(
      item =>
        <any>(
          (this.charDict[item.uuid] ||
            (this.charDict[item.uuid] = new BluetoothRemoteGATTCharacteristicImpl(this, item)))
        ),
    );
  }
  async getIncludedService(service: BluetoothServiceUUID): Promise<BluetoothRemoteGATTService> {
    console.warn("getIncludedService not implemented");
    return null;
  }
  async getIncludedServices(service?: BluetoothServiceUUID): Promise<BluetoothRemoteGATTService[]> {
    console.warn("getIncludedServices not implemented");
    return [];
  }
}

class BluetoothRemoteGATTServerImpl extends EventDispatcher {
  constructor(device: BluetoothDeviceImpl) {
    super();
    this.device = device;
    this.connected = true;
  }

  readonly device: BluetoothDeviceImpl;
  readonly connected: boolean;
  async connect(): Promise<BluetoothRemoteGATTServerImpl> {
    if (this.device.cached) {
      if (await ns("bluetooth").call("getCachedDevice", this.device.id)) {
        this.device.cached = false;
      } else {
        throw new Error("Device is not in range");
      }
    }
    await ns("bluetooth").call("connect", this.device.id);
    return this;
  }
  disconnect(): void {
    ns("bluetooth").call("disconnect", this.device.id);
  }

  serviceDict: {
    [key: string]: BluetoothRemoteGATTServiceImpl;
  } = {};

  async getPrimaryService(service: BluetoothServiceUUID): Promise<BluetoothRemoteGATTService> {
    const item = await ns("bluetooth").call<any>("getPrimaryService", this.device.id, getService(service));
    if(!item) {
      const e: any = new Error(`Service ${service} not found`);
      e.code = 8;
      throw e;
    }
    return item
      ? <any>(
          (this.serviceDict[item.uuid] ||
            (this.serviceDict[item.uuid] = new BluetoothRemoteGATTServiceImpl(this, item)))
        )
      : null;
  }
  async getPrimaryServices(service?: BluetoothServiceUUID): Promise<BluetoothRemoteGATTService[]> {
    const items = await ns("bluetooth").call<any[]>(
      "getPrimaryServices",
      this.device.id,
      service ? getService(service) : null,
    );
    return items.map(
      it =>
        <any>(this.serviceDict[it.uuid] || (this.serviceDict[it.uuid] = new BluetoothRemoteGATTServiceImpl(this, it))),
    );
  }
}

class BluetoothDeviceImpl extends EventDispatcher {
  constructor(
    opts: {
      id: string;
      name: string;
      uuids: string[];
      cached?: boolean;
    },
    public parent: BluetoothImpl,
  ) {
    super();
    this.cached = opts.cached || false;
    this.id = opts.id;
    this.name = opts.name;
    this.uuids = opts.uuids;
    this.watchingAdvertisements = false;
    this.gatt = new BluetoothRemoteGATTServerImpl(this);
    ns("bluetooth").on(`device-${this.id}/disconnect`, () => {
      if (this.ongattserverdisconnected) this.ongattserverdisconnected(new Event("gattserverdisconnected"));
      this.dispatchEvent(new Event("gattserverdisconnected"));
    });
    ns("bluetooth").on(`advertise/${this.id}`, () => {
      if (this.watchAdvertisements) {
        if (this.onadvertisementreceived) this.onadvertisementreceived(new Event("advertisementreceived"));
        this.dispatchEvent(new Event("advertisementreceived"));
      }
    });
  }

  cached: boolean;

  readonly id: string;
  readonly name?: string;
  readonly gatt?: BluetoothRemoteGATTServerImpl;
  readonly uuids?: string[];
  watchingAdvertisements: boolean;

  onadvertisementreceived: (this: this, ev: Event) => any;
  ongattserverdisconnected: (this: this, ev: Event) => any;
  oncharacteristicvaluechanged: (this: this, ev: Event) => any;
  onserviceadded: (this: this, ev: Event) => any;
  onservicechanged: (this: this, ev: Event) => any;
  onserviceremoved: (this: this, ev: Event) => any;

  async watchAdvertisements(): Promise<void> {
    if (this.watchingAdvertisements) return;
    this.watchingAdvertisements = true;
    this.parent.updateBgScan();
  }
  unwatchAdvertisements(): void {
    if (!this.watchingAdvertisements) return;
    this.watchingAdvertisements = false;
    this.parent.updateBgScan();
  }

  requestMtu?(mtu: number) {
    return ns("bluetooth").call<number>("requestMtu", this.id, mtu);
  }

  toJSON() {
    return {
      id: this.id,
      name: this.name,
      uuids: this.uuids,
      cached: true,
    };
  }
}

class BluetoothImpl extends EventDispatcher {
  onadvertisementreceived: (this: this, ev: Event) => any;
  ongattserverdisconnected: (this: this, ev: Event) => any;
  oncharacteristicvaluechanged: (this: this, ev: Event) => any;
  onserviceadded: (this: this, ev: Event) => any;
  onservicechanged: (this: this, ev: Event) => any;
  onserviceremoved: (this: this, ev: Event) => any;
  onavailabilitychanged: (this: this, ev: Event) => any;
  readonly referringDevice?: BluetoothDevice;

  paired: {
    [key: string]: BluetoothDeviceImpl;
  } = {};
  pairedInit = false;

  async getDevices(): Promise<BluetoothDeviceImpl[]> {
    if (!this.pairedInit) {
      if (localStorage["bluetoothPolifill.devices"]) {
        for (let info of JSON.parse(localStorage["bluetoothPolifill.devices"] || "[]")) {
          if (!this.paired[info.id]) this.paired[info.id] = new BluetoothDeviceImpl(info, this);
        }
      }
      this.pairedInit = true;
    }

    return Object.values(this.paired);
  }
  async getAvailability(): Promise<boolean> {
    return ns("bluetooth").call("getAvailability");
  }
  async requestDevice(options?: RequestDeviceOptions): Promise<BluetoothDeviceImpl> {
    if (!options) {
      return Promise.reject(new TypeError("requestDeviceOptions not provided"));
    }
    const opts: UnionToIntersection<RequestDeviceOptions> = <any>options;
    let acceptAllDevices = opts.acceptAllDevices;
    let filters = opts.filters;
    if (acceptAllDevices) {
      if (filters && filters.length > 0) {
        return Promise.reject(new TypeError("acceptAllDevices was true but filters was not empty"));
      }
      const resp = await ns("bluetooth").call("requestDevice", {
        acceptAllDevices: true,
      });
      if (this.paired[resp.id]) {
        return this.paired[resp.id];
      } else {
        const r = (this.paired[resp.id] = new BluetoothDeviceImpl(resp, this));
        localStorage["bluetoothPolifill.devices"] = JSON.stringify(Object.values(this.paired).map(it => it.toJSON()));
        return r;
      }
    }

    if (!filters || filters.length === 0) {
      return Promise.reject(new TypeError("No filters provided and acceptAllDevices not set"));
    }
    try {
      filters = Array.prototype.map.call(filters, canonicaliseFilter);
    } catch (e) {
      return Promise.reject(e);
    }
    let validatedDeviceOptions: any = {};
    validatedDeviceOptions.filters = filters;

    // Optional services not yet suppoprted.
    // let optionalServices = requestDeviceOptions.optionalServices;
    // if (optionalServices) {
    //     optionalServices = optionalServices.services.map(window.BluetoothUUID.getService);
    //     validatedDeviceOptions.optionalServices = optionalServices;
    // }
    const resp = await ns("bluetooth").call("requestDevice", validatedDeviceOptions);
    if (this.paired[resp.id]) {
      return this.paired[resp.id];
    } else {
      const r = (this.paired[resp.id] = new BluetoothDeviceImpl(resp, this));
      localStorage["bluetoothPolifill.devices"] = JSON.stringify(Object.values(this.paired).map(it => it.toJSON()));
      return r;
    }
  }
  // async requestLEScan(
  //     options?: RequestLEScanOptions
  // ): Promise<BluetoothLEScan> {
  //     console.warn("requestLEScan not implemented")
  //     return null;
  // }

  updateBgScan() {
    const cnt = Object.values(this.paired).filter(it => it.watchingAdvertisements).length;
    if (cnt) {
      ns("bluetooth").call("startBackgroundScanning");
    } else {
      ns("bluetooth").call("stopBackgroundScanning");
    }
  }
}

let userGesture = false;
let supportDirectConnect = false;
let impl: BluetoothImpl;

export function requestDevice(options?: RequestDeviceOptions): Promise<BluetoothDevice> {
  if (userGesture) {
    const p = new Promise<BluetoothDevice>((resolve, reject) => {
      const request = async function () {
        try {
          const d = await navigator.bluetooth.requestDevice(options);
          resolve(d);
        } catch (e) {
          reject(e);
        } finally {
          if ((window as any)._requestDevice === request) {
            delete (window as any)._requestDevice;
          }
        }
      };
      (window as any)._requestDevice = request;
      ns("bluetooth").call("requestDeviceByUser").catch(reject);
    });

    return p;
  } else {
    return navigator.bluetooth.requestDevice(options);
  }
}

export async function directConnect(context: Vue, device: string | NativeBluetoothDevice, request: any): Promise<BluetoothDevice> {
  if (supportDirectConnect && device) {
    const deviceName = typeof device === "object" ? device.deviceName : device;
    if (typeof device === "object") {
      device = device.deviceId;
    }
    if (userGesture) {
      // electron
      let locked = false;
      if (request.filters) {
        for (let f of request.filters) {
          delete f.name;
        }
      }
      try {
        let resolveDevice: (device: NativeBluetoothDevice) => void;
        let rejectTimeout;
        const handleDevice = async (devices: NativeBluetoothDevice[]) => {
          const d = devices.find(it => it.deviceId === device);
          if (d) {
            if (rejectTimeout) {
              clearTimeout(rejectTimeout);
              rejectTimeout = null;
            }
            resolveDevice(d);
          }
        };

        await lockBluetooth(
          device,
          () => {
            locked = true;
            setBluetoothPicker(handleDevice);
          },
          () => {
            setBluetoothPicker(null);
          },
        );

        let final = requestDevice(request);

        const nativeDevice = await new Promise<NativeBluetoothDevice>((resolve, reject) => {
          resolveDevice = resolve;
          rejectTimeout = setTimeout(() => {
            rejectTimeout = null;
            reject(new Error("Timeout"));
          }, 15000);
        });

        await sendBluetoothPick(nativeDevice);
        const d = await final;
        (d as any).address = device;
        return d;
      } finally {
        if (locked) {
          unlockBluetooth(device);
        }
      }
    } else {
      const d = new BluetoothDeviceImpl(
        {
          id: device,
          name: deviceName,
          uuids: [],
        },
        impl,
      ) as any;
      (d as any).address = device;
      return d;
    }
  } else {
    try {
      return await navigator.bluetooth.requestDevice(request);
    } catch(e) {
      if (e.code === 18 && e.name === "SecurityError") {
        const c = await context.$openDialog(
          import("@feathers-client/components-internal/ConfirmDialog2.vue"),
          {
            title: context.$t("printer.confirmConnectBluetooth"),
          },
          {
            maxWidth: "500px",
          },
        );
        if (c) {
          return directConnect(context, device, request);
        }
      }
      throw e;
    }
  }
}

export function getSupportDirectConnect() {
  return supportDirectConnect;
}

async function installCore() {
  try {
    if (!getVersion()) return false;
    console.log("Check BLE support");
    if (!(await ns("bluetooth").call("supported"))) {
      try {
        if (await ns("bluetooth").call("supportPick")) {
          userGesture = true;
          supportDirectConnect = true;
          return true;
        }
      } catch (e) {}

      return false;
    }

    supportDirectConnect = true;

    Object.defineProperty(navigator, "bluetooth", {
      value: (impl = new BluetoothImpl()),
    });
    console.log("BLE polyfill installed");
    return true;
  } catch (e) {
    console.warn(e);
    return false;
  }
}
