
import Printer, { PrintMixinBase } from "~/mixins/Printer";
import productSequencer, { labelSizes, LabelSizeKey, ProductLabelSequenceOption } from "~/plugins/printer/label";
import { thermalProduct } from "~/plugins/printer/invoiceSequencer";
import { Component, mixins, checkID } from "@feathers-client";
import { Prop, Vue, Watch } from "nuxt-property-decorator";
import { ProductType } from "@common/common";
import _ from "lodash";
import uuid from "uuid/v4";

import EditorObjectPickerList from "@schemaEditor/EditorObjectPickerList.vue";
import EditorTextField from "@schemaEditor/EditorTextField.vue";

@Component({
  components: {
    EditorObjectPickerList,
    EditorTextField,
  },
})
export default class Pos extends mixins(Printer("label", "label")) {
  @Prop()
  value: boolean;

  @Prop()
  selected: ProductType[];

  thermal: PrintMixinBase;

  get inputValue() {
    return this.value;
  }
  set inputValue(v) {
    this.$emit("input", v);
  }

  beforeMount() {
    this.thermal = new (Printer("thermal", "thermal"))({
      parent: this,
    });
  }

  mounted() {
    this.items = _.map(this.selected, it => ({
      sku: it,
      num: 1,
    }));
    this.labelSizeKey = this.$store.getters.local.labelSize || "default";
  }

  editLoading = false;
  items: ProductLabelSequenceOption[] = [];
  showID = true;
  showDate = true;
  showNum = true;
  printingType: "label" | "thermal" = "label";
  labelSizeKey: LabelSizeKey = "default";

  async print() {
    this.editLoading = true;
    try {
      if (this.printingType === "label") {
        await this.setupPrinter();
      } else {
        await this.thermal.setupPrinter();
      }
      const products = await this.$feathers.service("shop/product/groups").find({
        query: {
          _id: { $in: this.items.map(it => it.sku.productGroup) },
          $paginate: false,
        },
        paginate: false,
      });
      for (let item of this.items) {
        const opts = {
          ...item,
          product: products.find(it => checkID(it._id, item.sku.productGroup)),
          showID: this.showID,
          showDate: this.showDate,
          showNum: this.showNum,
          labelSizeKey: this.labelSizeKey,
        };
        if (this.printingType === "label") {
          await this.printJob("productLabel", await thermalProduct(opts, "label"));
        } else {
          await this.thermal.printJob("product", await thermalProduct(opts));
        }
      }
    } catch (e) {
      console.warn(e);
      this.$store.commit("SET_ERROR", e.message);
    } finally {
      this.editLoading = false;
    }
  }

  cloneItem(item) {
    const idx = this.items.indexOf(item);
    this.items.splice(idx, 0, { ...item, id: uuid() });
  }

  get labelSize() {
    return this.labelSizeKey;
  }

  set labelSize(v: LabelSizeKey) {
    this.labelSizeKey = v;
    this.$store.commit("SET_LOCAL", {
      labelSize: this.labelSizeKey,
    });
  }

  get printingTypeOption() {
    return [
      {
        text: this.$t("labelPrint.printingType.thermal"),
        value: "thermal",
      },
      {
        text: this.$t("labelPrint.printingType.label"),
        value: "label",
      },
    ];
  }

  get labelSizeOption() {
    return _.map(labelSizes, size => ({
      text: `${size.width}mm * ${size.height}mm (${this.$t(`labelPrint.labelSize.${size.key}`)})`,
      value: size.key,
    }));
  }
}
