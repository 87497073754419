
  import moment from "moment";
  import _ from "lodash";
  import { Component, Vue, Prop, FindType, Watch, checkID, VModel } from "@feathers-client";
  // @ts-ignore
  import MenuListPicker, { SearchConfig } from "@feathers-client/components/MenuListPicker.vue";
  
  @Component({
    components: {
      MenuListPicker,
    },
  })
  export default class EditorCombobox extends Vue {
    @Prop()
    label: string;
  
    @VModel()
    inputValue: any;

    @Prop(Boolean)
    required: boolean

    @Prop(Boolean)
    readonly: boolean
  }
  