
import { FindType } from '@feathers-client';
import { Component, Vue, Prop, mixins } from '@feathers-client';
import { getStorage } from '@feathers-client/storage';
import moment from 'moment';
import type { PrinterServer } from 'pos-printer';
import { getVersion } from 'pos-printer/nativeIntegrations'
import type { PrinterConf } from 'pos-printer/printers/base';
import type { ServerConf } from 'pos-printer/printers/server';
import { supported, getBattery, on as onBattery, off as offBattery } from 'pos-printer/utils/app';
import Printer, { PrintMixinBase } from "~/mixins/Printer";

// @ts-ignore
const IpsTestingMenu = PRODUCTION_MODE ? undefined : async () => (await import("~/plugins/ips/testingMenu.vue")).default;


let initedBefore = false;

@Component({
  components: {
    IpsTestingMenu,
  }
})
export default class OrderSystemHeader extends mixins(Printer("thermal", "thermal")) {

  hasIPSTestingMenu = !!IpsTestingMenu;

  batteryManager: any = null;
  battery: number = null;
  charging = false;
  batterySupported = false;
  timeStr: string = null;
  batteryHandle: string = null;

  isApp = getVersion();
  isIPad = getIsIPad();
  initing = 5;
  printerSetup = false;
  networkSetup = false;
  paymentSetup = false;
  offlineSetup = false;

  loaded = false;

  get printerCount() {
    return this.printerServer?.queues?.length ?? 0;
  }

  get printerOnline() {
    return this.printerServer?.queues?.filter?.(it => it.status === "printing" || it.status === "ready")?.length ?? 0;
  }

  get printerError() {
    return !!this.printerServer?.queues?.find?.(it => it.status === "offline" || it.status === "error");
  }

  @Prop()
  appHideSupported: boolean

  get batteryLevel() {
    return Math.round(this.battery * 100);
  }

  timer: any = null;

  async mounted() {
    this.timer = setInterval(this.tick, 1000);
    this.tick();
    await this.initBattery();
    if (initedBefore) {
      this.initing = 0;
      await this.init();
    }
  }

  async initBattery() {
    if ((navigator as any).getBattery) {
      try {
        this.batteryManager = await (navigator as any).getBattery();
        this.batteryManager.addEventListener('chargingchange', this.chargingchange)
        this.chargingchange();
      } catch (e) {
      }
    }

    try {
      if (!this.batteryManager && await supported()) {
        const info = await getBattery();
        this.battery = info.level;
        this.charging = info.charging;
        this.batterySupported = true;
        this.batteryHandle = await onBattery('batteryChanged', this.batteryChanged);
      }
    } catch (e) {

    }
  }

  async beforeDestroy() {
    if(this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
    if (this.batteryManager) {
      this.batteryManager.removeEventListener('chargingchange', this.chargingchange)
      this.batteryManager = null;
    }
    if (this.batteryHandle) {
      await offBattery(this.batteryHandle);
      this.batteryHandle = null;
    }
  }

  chargingchange() {
    if (!this.batteryManager) return;
    this.battery = this.batteryManager.level;
    this.charging = this.batteryManager.charging;
  }

  async batteryChanged() {
    try {
      const info = await getBattery();
      this.battery = info.level;
      this.charging = info.charging;
    } catch (e) {
      console.warn(e);
    }
  }

  tick() {
    if (this.initing) {
      --this.initing;
      if (!this.initing) {
        this.init();
      }
    }
    const timeStr = moment().format("HH:mm");
    if (timeStr !== this.timeStr) {
      this.timeStr = timeStr;
    }
  }

  async init() {
    initedBefore = true;
    await import("~/plugins/payments");
    await this.$paymentManager.start();
    this.loaded = true;
  }

  async managePrint() {
    await this.printerServer.managePrint('thermal', 'default', true);
  }

  async managePrinter(item: PrinterConf) {
    await this.printerServer.manageDevice(item);
  }

  formatPrinter(tags: string[] = []) {
    return tags.map(tag => {
      if (tag === 'default') {
        return this.$t('printerType.receipt')
      }
    }).filter(it => !!it).join(', ');
  }
}

function getIsIPad() {
  return [
    'iPad Simulator',
    'iPad',
  ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

