
import _ from "lodash";
import { Types } from "mongoose";
import { Component, Vue, mixins, Prop, FindType, FindPopRawType, Watch, checkID, getID } from "@feathers-client";
import Dialog from "@feathers-client/mixins/Dialog";

@Component({
})
export default class ShipmentScheduleOrderAssignmentDialog extends mixins(Dialog) {
  deliveryDate = null;
  deliveryCar = null;

  confirm(){
    this.modalResult({
      deliveryDate: this.deliveryDate,
      deliveryCar: this.deliveryCar,
    });
  }

  close() {
    this.modalResult(null);
  }
}
