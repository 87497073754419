
import _ from "lodash";
import { Component, Vue, mixins, Prop, FindType, FindPopRawType, Watch, checkID, getID } from "@feathers-client";
import Dialog from "@feathers-client/mixins/Dialog";
//type ShipmentType = FindType<"shop/shipping/shipments">;

@Component
export default class ShipmentScheduleShipmentStatusDialog extends mixins(Dialog) {
  // @Prop()
  // item: ShipmentType;

  @Prop()
  value: any; //ShipmentType["status"];

  @Prop()
  options: {_id:string,name:object}[]
  
  // get scheduleFilters() {
  //   return [
  //     { _id: "pending", name: { $t: "shipping.schedule.status.pending" } },
  //     { _id: "confirmed", name: { $t: "shipping.schedule.status.confirmed" } },
  //     { _id: "scheduled", name: { $t: "shipping.schedule.status.scheduled" } },
  //     { _id: "notDelivered", name: { $t: "shipping.schedule.status.notDelivered" } },
  //     { _id: "expired", name: { $t: "shipping.schedule.status.expired" } },
  //   ];
  // }

  get shipmentStatus() {
    return this.value;
  }

  set shipmentStatus(v) {
    // this.$emit('input', v);

    this.modalResult(v);
  }
}
