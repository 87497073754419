import moment from "moment";

export enum PayType {
  qrCode = "00",
  edenred = "10",
  credit = "14",
  cash = "25",
  creditP = "26",
  creditI = "27",
  ezCard = "30",
}

export enum ActionType {
  sale = "01",
  refund = "02",
  void = "03",
  settle = "04",
}

export enum TransMethod {
  forward = "0",
  reverse = "1",
}

export enum CardType {
  visa = "02",
  master = "03",
  jcb = "04",
  uCard = "05",
  diner = "06",
  ae = "07",
  bank = "08",
  unionPay = "09",
}

export enum TaxType {
  taxable = "1",
}

export enum ResponseCode {
  orderSucess = "0000",
  orderCheck = "0001",
  return = "9800",
  notLogin = "9899",
  userPayNoTooLong = "9981",
  duplicateOrderNo = "9982",
  taxEmpty = "9983",
  timeOut = "9984",
  pageNotAllowed = "9985",
  barcodeNotAllowed = "9986",
  apiVersionNotMatch = "9987",
  amountTooLarge = "9988",
  orderNoTooLong = "9989",
  ipNotAllow = "9990",
  configError = "9991",
  noPermission = "9992",
  macError = "9993",
  dataError = "9996",
  dataNotFound = "9997",
  commitError = "9998",
  fail = "9999",
}

const turnCloudMessage = {
  // 順序  欄位名稱  欄位代號  位置  長度  備註
  // 01  支付別  PAY_TYPE  0 2  參考附錄
  payType: [0, 2, PayType],
  // 02  行為別  ACTION_TYPE 2  2 參考附錄
  actionType: [2, 2, ActionType],
  // 03  訂單編號  ORDER_NO  4 28 由 POS 自編的英數唯一值
  orderNo: [4, 28, ""],
  // 04  交易金額  TRANS_AMOUNT  32  12  至小數後兩位的整數值
  transAmount: [32, 12, 2],
  // 05  授權金額  AUTH_AMOUNT 44 12 目前此欄位暫無對應使用情境
  authAmount: [44, 12, 2],
  // 06  加值金額  DEPOSIT_AMOUNT  56  12  目前此欄位暫無對應使用情境
  depositAmount: [56, 12, 2],
  // 07  餘額  BALANCE_AMOUNT  68  12  目前此欄位暫無對應使用情境
  balanceAmount: [68, 12, 2],
  // 08  其他金額  EXP_AMOUNT  80  12  目前此欄位暫無對應使用情境
  expAmount: [80, 12, 2],
  // 09  交易方式  TRANS_METHOD  92  1 參考附錄
  transMethod: [92, 1, TransMethod],
  // 10  使用者付款條碼  USER_PAY_NO 93 18 掃碼支付時消費者的支付條碼 帶入時系統會自動開啟掃碼頁
  userPayNo: [93, 18, ""],
  // 11  消費者統編  TAX_ID  111 8  由 HOYA 開立電子發票時適用
  taxId: [111, 8, ""],
  // 12  載具條碼或愛心碼  CARRUER_NO  119 15 由 HOYA 開立電子發票時適用
  carruerNo: [119, 15, ""],
  // 13  卡號  CARD_NO 134  19  有使用卡片交易時回傳
  cardNo: [134, 19, ""],
  // 14  卡片到期日  CARD_EXPIRE_DATE  153 4  目前此欄位暫無對應使用情境
  cardExpireDate: [153, 4, ""],
  // 15  分期期數  PERIOD  157 2  信用卡分期交易使用
  period: [157, 2, 0],
  // 16  調閱編號  TRACE_NO  159 8  1.除紅陽外信用卡適用 2.有使用卡片交易時回傳 3.取消時須帶入
  traceNo: [159, 8, ""],
  // 17  授權碼  APPROVAL_NO 167  9 1.除紅陽外信用卡適用 2.有使用卡片交易時回傳 3.退貨時須帶入
  approvalNo: [167, 9, ""],
  // 18  交易序號  REFERENCE_NO  176 50 由系統產生的交易序號
  referenceNo: [176, 50, ""],
  // 19  回傳代碼  RESPONSE_CODE 226  4 參考附錄
  responseCode: [226, 4, ResponseCode],
  // 20  第三方回傳代碼  RETURN_CODE 230  10
  returnCode: [230, 10, ""],
  // 21  實際支付別  PAY_FROM  240 2  參考附錄
  payFrom: [240, 2, ""],
  // 22  端末機代碼  TERMINAL_ID 244  8
  terminalId: [244, 8, ""],
  // 23  裝置號碼  DEVICE_ID 250  18  悠遊卡交易使用(Dongle)
  deviceId: [250, 18, ""],
  // 24  交易日期  TRANS_DATE  268 6  格式:YYMMDD
  // 25  交易時間  TRANS_TIME  274 6  格式:HHMMSS
  transDateTime: [268, 12, "YYMMDDHHMMSS"],
  // 26  卡片總類  CARD_TYPE 280  2 參考附錄
  cardType: [280, 2, CardType],
  // 27  卡片內碼  PCARD_NO  282 20 目前此欄位暫無對應使用情境
  pcardNo: [282, 20, ""],
  // 28  首期金額  DOWN_AMOUNT 302  12 至小數後兩位的整數值 信用卡分期交易使用
  downAmount: [302, 12, 2],
  // 29  每期金額  EACH_AMOUNT 314  12 至小數後兩位的整數值 信用卡分期交易使用
  eachAmount: [314, 12, 2],
  // 30  未稅金額  SALES_AMOUNT  326 12 由 HOYA 開立電子發票時須帶入
  salesAmount: [326, 12, 2],
  // 31  稅別  TAX_TYPE  338 1 參考附錄 由 HOYA 開立電子發票時須帶入 例:0.05 代入 5
  taxType: [338, 1, TaxType],
  // 32  稅率  TAX_RATE  339 3 由 HOYA 開立電子發票時須帶入
  taxRate: [339, 3, 0],
  // 33  稅額  TAX_AMOUNT  342 12 由 HOYA 開立電子發票時須帶入
  taxAmount: [342, 12, 2],
  // 34  保留  KEEP  354 46
  keep: [354, 46, ""],
} as const;

export const messageLength = 400;

type ConvertType<T> = T extends number
  ? number
  : T extends "YYMMDDHHMMSS"
  ? Date
  : T extends string
  ? string
  : keyof T;

export type TurnCloudMessage = {
  -readonly [K in keyof typeof turnCloudMessage]?: ConvertType<typeof turnCloudMessage[K][2]>;
};

type ConvertInType<T> = T extends number
  ? number
  : T extends "YYMMDDHHMMSS"
  ? Date
  : T extends string
  ? string
  : T[keyof T] | keyof T;

export type TurnCloudMessageInput = {
  -readonly [K in keyof typeof turnCloudMessage]?: ConvertInType<typeof turnCloudMessage[K][2]>;
};

export function encode(message: TurnCloudMessageInput): string {
  const parts: string[] = [];
  for (const key in turnCloudMessage) {
    const [start, length, typeDef] = turnCloudMessage[key];
    const value = message[key as keyof TurnCloudMessageInput];
    let part = "".padStart(length, " ");
    if (value !== undefined) {
      switch (typeDef) {
        case "YYMMDDHHMMSS":
          part = moment(value as Date)
            .format("YYMMDDHHMMSS")
            .padStart(length, " ");
          break;
        case 0: {
          const v = value as number;
          part = v.toFixed(0).padStart(length, " ");
          break;
        }
        case 2: {
          const v = value as number;
          part = v.toFixed(2).replace(".", "").padStart(length, " ");
          break;
        }
        case "": {
          const v = value as string;
          part = v.padStart(length, " ");
          break;
        }
        default: {
          if (typeof typeDef === "object") {
            const v = value as keyof typeof typeDef;
            const normalized = typeDef[v] || v;
            part = normalized.padStart(length, " ");
          }
          break;
        }
      }
    }
    if (part.length !== length) {
      throw new Error(`Invalid length for ${key}(${start},${length})`);
    }
    parts.push(part);
  }
  return parts.join("");
}

export function decode(input: string) {
  const message: TurnCloudMessage = {};

  for (const key in turnCloudMessage) {
    const [start, length, typeDef] = turnCloudMessage[key];
    let part = input.slice(start, start + length).trim();
    if (part.length === 0) {
      continue;
    }
    switch (typeDef) {
      case "YYMMDDHHMMSS":
        message[key] = moment(part, "YYMMDDHHMMSS").toDate();
        break;
      case 0:
        message[key] = parseInt(part, 10);
        break;
      case 2:
        message[key] = parseInt(part, 10) / 100;
        break;
      case "":
        message[key] = part;
        break;
      default: {
        if (typeof typeDef === "object") {
          if (!typeDef[part]) {
            for (const k in typeDef) {
              if (typeDef[k] === part) {
                part = k;
                break;
              }
            }
          }
          message[key] = part;
        }
        break;
      }
    }
  }

  return message;
}
