var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"d-flex px-6 items-center",class:_vm.headerClass},[_c('div',[_vm._v(_vm._s(_vm.label))]),_c('div',{staticClass:"flex-grow"}),_c('div',{staticClass:"!text-orange100 py-4",attrs:{"role":"button"},on:{"click":_vm.toggleEdit}},[_vm._v(" "+_vm._s(_vm.editing ? _vm.$t("basic.done") : _vm.$t("basic.edit"))+" ")])]),_c('draggable',_vm._b({staticClass:"relative px-6",model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}},'draggable',_vm.dragOptions,false),_vm._l((_vm.inputValue),function(entry,idx){return _c('div',{key:entry[_vm.itemKey] || idx,staticClass:"d-flex items-center border-t-1 border-t-grey600"},[(_vm.editing)?_c('div',[_c('v-checkbox',{attrs:{"label":_vm.$t('basic.remove')},model:{value:(_vm.removeState[entry[_vm.itemKey]]),callback:function ($$v) {_vm.$set(_vm.removeState, entry[_vm.itemKey], $$v)},expression:"removeState[entry[itemKey]]"}})],1):_vm._e(),_c('div',{staticClass:"flex-grow"},[_vm._t("item",null,{"item":entry,"edit":{
            get item() {
              return entry;
            },
            set item(v) {
              _vm.inputValue = [..._vm.inputValue.slice(0, idx), v, ..._vm.inputValue.slice(idx + 1)];
            },
          }})],2),(_vm.editing)?_c('div',[_c('v-icon',{staticClass:"p-3 rounded-full handle",attrs:{"role":"button"}},[_vm._v("drag_handle")])],1):_vm._e()])}),0)],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }