
import _ from "lodash";
import { Component, Vue, mixins, Prop, FindType, FindPopRawType, Watch, checkID, getID } from "@feathers-client";
import Dialog from "@feathers-client/mixins/Dialog";
type ScheduleType = FindType<"shop/shipping/schedules">;
import { getStatusColor } from "~/plugins/pos/util";

@Component
export default class ShipmentScheduleStatusDialog extends mixins(Dialog) {
  @Prop()
  item: ScheduleType;

  @Prop()
  newStatus: ScheduleType["status"];

  @Prop()
  hideStatus: boolean;

  @Prop({ type: Array })
  contents;

  scheduleStatus: ScheduleType["status"] = null;

  get scheduleFilters() {
    //"pending", "preparing", "delivery", "done"
    return [
      { _id: "pending", name: { $t: "enum.basic.status.pending" } },
      { _id: "preparing", name: { $t: "enum.basic.status.preparing" } },
      { _id: "delivery", name: { $t: "enum.basic.status.delivery" } },
      { _id: "done", name: { $t: "enum.basic.status.done" } },
    ];
  }

  beforeMount() {
    if (this.newStatus) {
      this.scheduleStatus = this.newStatus;
    }
  }

  getStatusColor = getStatusColor;

  async save() {
    try {
      const resp = await this.$feathers.service("shop/shipping/schedules/update").create({
        _id: this.item._id,
        ...(this.scheduleStatus
            ? {
                status: this.scheduleStatus,
              }
            : {}),
      });
      Object.assign(this.item, resp);
      this.modalResult(true);
    } catch (e) {
      this.$store.commit("SET_ERROR", e.message);
    }
  }
}
