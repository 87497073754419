import { Component, Vue, Prop, mixins } from "@feathers-client";
import { SerialMixin } from "../serialMixin";
import { SerialPortSelection } from "../ports/serial";

export type WeightUnit = "kg" | "g" | "lb" | "oz" | "hk_tael" | "hk_catty" | "vn_tael" | "vn_catty" | "my_tael" | "my_catty" | "sg_tael" | "sg_catty" | "twjpkrth_tael" | "twjpkrth_catty" | "cn_tael" | "cn_catty";
export const WeightUnits: WeightUnit[] = ["kg", "g", "lb", "oz", "hk_tael", "hk_catty", "vn_tael", "vn_catty", "my_tael", "my_catty", "sg_tael", "sg_catty", "twjpkrth_tael", "twjpkrth_catty", "cn_tael", "cn_catty"];

export interface WeightConfig {
  serial?: SerialPortSelection;
  inputMode?: "text" | "hid";
  unit?: WeightUnit,
}

@Component
export class WeightManager extends mixins(SerialMixin) {
  input: string[] = [];
  timeout: number | NodeJS.Timer | null = null;

  // @ts-ignore
  declare settings: WeightConfig;

  async openSettings() {
    return await this.$openDialog(
      // @ts-ignore
      import("./WeightDialog.vue"),
      {
        manager: this,
      },
      {
        maxWidth: "80%",
        contentClass: "editor-dialog",
      },
    );
  }

  serialInput(buf: Buffer) {
    let line: string;
    if (this.settings?.inputMode === "hid") {
      const textDecode = {
        0x1e: '1',
        0x1f: '2',
        0x20: '3',
        0x21: '4',
        0x22: '5',
        0x23: '6',
        0x24: '7',
        0x25: '8',
        0x26: '9',
        0x27: '0',
        0x28: '\n',
        0x37: '.',
      }
      const data = Array.prototype.slice.call(buf).filter(it => it).map(it => textDecode[it]).filter(it => it);
      line = data.join('');
    } else {
      line = Buffer.from(buf).toString();
    }
    const parts = line.replace(/[`\x01]/g, '').split(/[\r\n]/g);
    for (let idx = 0; idx < parts.length; idx++) {
      const it = parts[idx];
      if (idx && this.input.length) {
        this.flushBuffer();
      }
      if (it) {
        this.inputBuffer(it);
      }
    }
  };

  inputBuffer(data: string) {
    this.input.push(data);
    if (this.timeout) {
      clearTimeout(this.timeout as any);
      this.timeout = null;
    }
    this.timeout = setTimeout(this.flushBuffer, 500);
  }

  flushBuffer() {
    const line = this.input.join("");
    if (this.timeout) {
      clearTimeout(this.timeout as any);
      this.timeout = null;
    }
    if (line) {
      const f = parseFloat(line);
      if (!isNaN(f)) {
        this.$emit("weight", {
          weight: f,
          unit: this.settings?.unit || "kg",
        });
      } else {
        this.$emit("invalidData", line);
      }
    }

    this.input = [];
  }
}

