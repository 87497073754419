
import { PosOrder } from "~/plugins/pos/order";
import _ from "lodash";
import { Component, Prop, Vue, FindType, mixins } from "@feathers-client";
import Printer from "~/mixins/Printer";
import EditorObjectPickerList from "@schemaEditor/EditorObjectPickerList.vue";

@Component({
  components: {
    EditorObjectPickerList,
  },
})
export default class OrderSystemStaff extends mixins(Printer("thermal", "thermal")) {
  @Prop()
  session: PosOrder;

  currentDate: string = "";
  timer: any = null;

  beforeMount() {
    this.updateTime();
  }

  beforeDestory() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  updateTime() {
    this.timer = null;
    this.currentDate = this.$moment().format("lll");
    this.timer = setTimeout(() => {
      this.updateTime();
    }, 60000 - (Date.now() % 60000));
  }

  async cashbox() {
    await this.openCashBox();
  }

  staff = false;

  updateStaff(staff: FindType<"shop/staffs">) {
    this.$pos.staff = staff;
    this.session.staff = this.$pos.staff;
  }

  async openHistory() {
    await this.$openDialog(
      // @ts-ignore
      import("~/components/table/orderSystem/history/index.vue"),
      {
        nextOrder: e => this.$emit("nextOrder", e),
      },
      {
        contentClass: "m-10 h-[calc(100%-2.5rem)] w-full grid",
      },
    );
  }

  async openSetting() {
    await this.$openDialog(
      // @ts-ignore
      import("~/components/table/orderSystem/setting.vue"),
      {},
      {
        maxWidth: "50vw",
      },
    );
  }

  openCalendar() {
    this.$emit("toggleCalendar");
  }

  async connectScreen() {
    await this.$pos.connectScreen(true);
  }
}
