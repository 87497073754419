
import { Vue, Component, Prop, mixins } from "@feathers-client";
import Dialog from "@feathers-client/mixins/Dialog";
import { ProductGroupExtend } from "~/pages/products/index.vue";
import EditorObjectPickerNew from "@schemaEditor/EditorObjectPickerNew.vue";
import EditorCheckbox from "@schemaEditor/EditorCheckbox.vue";
import { EditorField } from "@schemaEditor/plugin";
import _ from "lodash";

@Component({
  components: {
    EditorObjectPickerNew,
    EditorCheckbox,
  },
})
export default class BatchProductStatusDialog extends mixins(Dialog) {
  @Prop()
  products: ProductGroupExtend[];

  @Prop()
  shop: string | string[];

  @Prop()
  currentStatus: string;

  @Prop()
  filterShops: string[];

  shops: string[] = [];
  sourceShop: string = null;

  createMissing = false;

  status: string = null;

  statusField: EditorField = null;

  loading = false;
  progress = 0;

  async created() {
    if (this.shop) {
      this.shops = Array.isArray(this.shop) ? this.shop : [this.shop];
    } else {
      this.shops = this.$store.state.shops.map(shop => shop._id);
    }
    if (this.currentStatus) [(this.status = this.currentStatus)];
    await this.$schemas.init();
    const config = this.$schemas.getConfigByApiPath("shop/product/skus");
    const status = config.fields.find(it => it.path === "shopTable").inner.find(it => it.path === "status");

    this.statusField = status;
  }

  get needCreate() {
    return this.products.find(it => this.shops.find(s => !it.shopStatus[s]));
  }

  async save() {
    this.loading = true;
    this.$root.$emit("updateDialog", {
      id: this.modalId,
      modal: {
        persistent: true,
      },
    });

    let progress = 0;
    let lastUpdate = Date.now();
    this.progress = 0;

    try {
      const chunks = _.chunk(this.products, 20);

      for (let chunk of chunks) {
        const updated = await this.$feathers.service("shop/product/groups/updateStatus").create({
          shops: this.shops,
          products: chunk.map(it => it._id as string),
          status: this.status,
          sourceShop: this.sourceShop,
          createMissing: this.createMissing,
          filterShops: this.filterShops,
        });

        const curDict = Object.fromEntries(chunk.map(it => [it._id, it]));
        for(let item of updated) {
          const cur = curDict[String(item._id)];
          if(cur) {
            Object.assign(cur, item);
          }
        }

        progress += chunk.length;
        if (Date.now() - lastUpdate > 500) {
          this.progress = (progress / this.products.length) * 100;
          lastUpdate = Date.now();
        }
      }
      this.modalResult(true);
    } catch (e) {
      this.$store.commit("SET_ERROR", e.message);
    } finally {
      this.loading = false;
      this.$root.$emit("updateDialog", {
        id: this.modalId,
        modal: {
          persistent: false,
        },
      });
    }
  }
}
