import _ from "lodash";

export function getMongoCursor(lastItem: any = null, query: any = {}, sort: any = {}): any {
  sort = { ...sort };
  if (!sort._id) sort._id = 1;

  const $and = (query?.$and ?? []).slice();
  if (lastItem) {
    const sortEntries = Object.entries(sort) as [string, number][];
    const conds = sortEntries.map((it, idx) => {
      const entries = sortEntries.slice(0, idx + 1);
      const result = entries.map(([k, v], idx) => {
        const lastVal = _.get(lastItem, k);
        if (lastVal === undefined) {
          if (idx === entries.length - 1) {
            return v < 0 ? null : [k, { $exists: true }];
          } else {
            // keeping undefined for current item
            return [k, { $exists: false }];
          }
        }
        return [k, idx === entries.length - 1 ? { [v < 0 ? "$lt" : "$gt"]: lastVal } : lastVal];
      });

      if (result.includes(null)) {
        return null;
      }

      return Object.fromEntries(result);
    }).filter(it => !!it);
    if (!conds.length) {
      return null;
    }
    $and.push({
      $or: conds,
    });
  }

  return {
    ...query,
    $sort: sort,
    ...($and.length ? { $and } : {}),
  };
}
