
import {
  Component,
  Vue,
  Prop,
  ProvideReactive,
  InjectReactive,
  Watch,
  escapeRegExp,
} from "@feathers-client";
import { EditorConfig, mappedLocales } from "./plugin";
import { AppApplication } from "serviceTypes";
import "./editor.scss";
import EditorMulti from "./EditorMulti.vue";
import EditorSideMenu from "./EditorSideMenu.vue";
import EditorTopMenu from "./EditorTopMenu.vue";
import HeaderRender from "domore-table/HeaderRender.vue";
import type { EditorPageMixin } from "./mixins/EditorPageMixin";
import EditorLangMenu from "./EditorLangMenu.vue";
import { resolveField } from "./plugin/utils";

@Component({
  components: {
    EditorMulti,
    HeaderRender,
    EditorSideMenu,
    EditorTopMenu,
    EditorLangMenu,
  },
})
export default class EditorSinglePage extends Vue {
  @Prop()
  @ProvideReactive("editorRoot")
  item: any;

  @ProvideReactive()
  @InjectReactive({ default: null, from: "feathers" })
  feathers: any;

  @Prop()
  items: any[];

  @Prop({ type: Boolean })
  multiple: boolean;

  @Prop()
  config: EditorConfig;

  @Prop()
  path: keyof AppApplication;

  @Prop()
  itemPath: string;

  @Prop()
  filter: string;

  @Prop()
  exclude: string;

  @Prop()
  filterGroup: string;

  @Prop()
  excludeGroup: string;

  @Prop(Boolean)
  readonly: boolean;

  @Prop()
  formatHeader: (item: any) => string;

  @Prop(Boolean)
  nested: boolean;

  @Prop(Boolean)
  canReload: boolean;

  @Prop(Boolean)
  canRemove: boolean;

  @Prop(Boolean)
  canCancel: boolean;

  @Prop(Boolean)
  canSave: boolean;

  @Prop()
  pageMixin: EditorPageMixin<any>;

  @Prop(Boolean)
  allFields: boolean;

  mconfig: EditorConfig = null;

  search = "";
  msearch = "";

  _applySearchTimer: any;

  @Watch("search")
  onSearch() {
    if (this._applySearchTimer) clearTimeout(this._applySearchTimer);
    this._applySearchTimer = setTimeout(() => {
      this._applySearchTimer = null;
      this.msearch = this.search;
    }, 500);
  }

  get searchMatcher() {
    return this.msearch ? new RegExp(escapeRegExp(this.msearch), "ig") : null;
  }

  get locales() {
    return Object.entries(this.$store.state.locales).map(([k, v]) => ({
      _id: mappedLocales[k] || k,
      name: (v as any).name,
    }));
  }

  get realConfig() {
    return this.mconfig || this.config;
  }

  get matchers() {
    return this.filter ? this.filter.split(",").map((it) => new RegExp(it)) : null;
  }

  get ematchers() {
    return this.exclude ? this.exclude.split(",").map((it) => new RegExp(it)) : null;
  }

  get groupMatchers() {
    return this.filterGroup ? this.filterGroup.split(",").map((it) => new RegExp(it)) : null;
  }

  get groupEmatchers() {
    return this.excludeGroup ? this.excludeGroup.split(",").map((it) => new RegExp(it)) : null;
  }

  get fields() {
    let fields = this.realConfig?.fields ?? [];
    if (this.allFields) {
      fields = [...(this.realConfig?.sideFields || []), ...fields];
    }

    if (this.itemPath) {
      let itemPath = this.itemPath;
      let isArray = false;
      if (itemPath.endsWith(".*")) {
        isArray = true;
        itemPath = itemPath.slice(0, -2);
      }
      const field = resolveField(this.realConfig.item, itemPath, true);
      if (field) {
        const res = this.realConfig.convertField(field, undefined, undefined, {
          selectorShowAll: true
        });
        if (isArray) {
          fields = res.inner;
        } else {
          console.warn("Not implemented", itemPath);
          fields = [];
        }
      } else {
        console.error("Field not found", itemPath);
        fields = [];
      }
    }

    const matchers = this.matchers;
    const ematchers = this.ematchers;
    const searchMatcher = this.searchMatcher;

    if (matchers || ematchers || searchMatcher) {
      fields = fields.filter(
        (f) =>
          (!matchers || !!matchers.find((m) => m.test(f.path))) &&
          (!ematchers || !ematchers.find((m) => m.test(f.path))) &&
          (!searchMatcher ||
            searchMatcher.test(f.path) ||
            (f.name &&
              this.searchMatcher.test(
                Array.isArray(f.name) ? this.$td(f.name) : (this.$t(f.name) as string),
              ))),
      );
    }
    fields = this.$schemas.sortFields(fields);
    // fields = this.$schemas.sortDeep(this.$schemas.sortFields(fields));
    if (this.groupMatchers || this.groupEmatchers) {
      fields = fields.filter(
        (f) =>
          (!this.groupMatchers || !!this.groupMatchers.find((m) => m.test(f.path))) &&
          (!this.groupEmatchers || !this.groupEmatchers.find((m) => m.test(f.path))),
      );
    }
    if (this.readonly) {
      fields = this.$schemas.readOnlyDeep(fields);
    }
    return fields;
  }

  get sideFields() {
    if (!this.realConfig?.sideFields?.length) return null;
    let fields = this.realConfig?.sideFields ?? [];
    if (this.matchers || this.ematchers) {
      fields = fields.filter(
        (f) =>
          (!this.matchers || !!this.matchers.find((m) => m.test(f.path))) &&
          (!this.ematchers || !this.ematchers.find((m) => m.test(f.path))),
      );
    }
    fields = this.$schemas.sortFields(fields);
    // fields = this.$schemas.sortDeep(this.$schemas.sortFields(fields));
    if (this.readonly) {
      fields = this.$schemas.readOnlyDeep(fields);
    }
    return fields;
  }

  async beforeMount() {
    this.$schemas.onLocale(this.$i18n.locale);
    if (this.path && !this.config) {
      await this.$schemas.init();
      this.mconfig = this.$schemas.getConfigByApiPath(this.path as any);
    }
  }

  emitUpdated() {
    if (this.pageMixin) {
      this.pageMixin.markDirty();
    }
    this.$emit("updated");
  }
}
