
import { Component, Vue, Prop } from "@feathers-client";
import { PageBlock } from "../def";
import { SiteEditorComponentVariant, SiteEditorManager } from "..";
import EditorTranslateBox from "@schemaEditor/EditorTranslateBox.vue";
import EditorCheckbox from "@schemaEditor/EditorCheckbox.vue";
import EditorList from "@schemaEditor/EditorList.vue";
import { LangArrType } from "@feathers-client/i18n";
import uuid from "uuid/v4";
// @ts-ignore
import RarrowSvg from "!vue-svg-loader!../assets/rarrow.svg";
import ColorPicker from "../ColorPicker.vue";
import { SiteEditorButton } from "./headerImage.vue";
import UrlPicker from "../UrlPicker.vue";

@Component({
  components: {
    EditorTranslateBox,
    EditorCheckbox,
    EditorList,
    RarrowSvg,
    ColorPicker,
    UrlPicker,
  },
})
export default class SiteEditorText extends Vue {
  @Prop()
  block: PageBlock;

  @Prop()
  manager: SiteEditorManager;

  @Prop()
  variant: SiteEditorComponentVariant;

  showItemPerRowDropdown = false;

  created() {
    for (let item of this.props.items) {
      if (!item.translateFile) item.translateFile = [] as LangArrType;
    }
  }

  get props() {
    return this.manager.defineProps(this.block, {
      title: [] as LangArrType,
      content: [] as LangArrType,
      titleDisplayMode: "left",
      remarks: [] as LangArrType,
      fontColor: "#000000FF",
      itemPerRow: 2,
      displayPosition: "row",
      items: [] as SiteEditorTextWithImageItem[],
      mainButton: { enabled: false, urlType: "internal", url: "", title: [] } as SiteEditorButton,
      subButton: { enabled: false, urlType: "internal", url: "", title: [] } as SiteEditorButton,
    });
  }

  get displayPositions() {
    return [
      {
        _id: "column",
        name: {
          $t: this.manager.$t("componentGroups.text.column"),
        },
      },
      {
        _id: "row",
        name: {
          $t: this.manager.$t("componentGroups.text.row"),
        },
      },
    ];
  }

  getItem() {
    return {
      id: uuid(),
      file: null,
      displayImage: false,
      urlType: "internal",
      url: "",
    } as SiteEditorTextWithImageItem;
  }

  get alignTypes() {
    return ["left", "center", "right"];
  }
}

export class SiteEditorTextWithImageItem {
  id?: string;
  file?: any;
  translateFile?: LangArrType;
  title?: LangArrType;
  desc?: LangArrType;
  displayImage?: boolean;
  url: string;
  urlType: string;
}
