
import { ProductItem } from "@common/pos";
import { FindType } from "@feathers-client";
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";

@Component
export default class PosItemDetails extends Vue {
    @Prop()
    item : ProductItem

    @Prop({ type: Boolean })
    readOnly: boolean
}
