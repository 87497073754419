
import uuid from "uuid/v4";
import { Vue, Component, Prop, PropSync, VModel, Watch, Ref } from "@feathers-client";
import type { NavItem } from "./index.vue";
import _ from "lodash";

@Component
export default class BoxsNavBarItem extends Vue {
  @Prop()
  item: NavItem;

  @Prop()
  activeDrawer: boolean;

  @Prop({ type: Boolean, default: true })
  setHover: boolean;

  expanded = false;

  @Ref("item")
  itemRef;

  active = false;

  @Watch("activeDrawer")
  onWatchActiveDrawer() {
    if (!this.activeDrawer) {
      this.active = false;
    }
  }
  
  get isMobile() {
    return this.$breakpoint.xsOnly;
  }

  isObjectEmpty(item){
    return item.hasOwnProperty("title"||"href")
  }
  hasChild(item) {
    return !!item?.items?.length;
  }

  itemType(item) {
    return this.hasChild(item) ? "div" : "nuxt-link";
  }

  onClick(e: Event) {
    if (this.hasChild) {
      this.expanded = !this.expanded;
      this.active = false;
      return;
    }
  }

  onHoverItem() {
    if (this.hasChild) {
      this.$emit("hoverItem", this.item);
      this.$emit("activeDialog", true);
    }
  }

  mouseLeave() {
    if (this.hasChild) {
      this.$emit("hoverItem", null);
      this.$emit("activeDialog", false);
    }
  }
  get activeParent() {
    return (
      this.hasChild &&
      this.item &&
      this.item?.items &&
      this.item.items.some(it => it.href === this.$router.currentRoute.path)
    );
  }
}
