import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _44fa1245 = () => interopDefault(import('../pages/cashboxLogs.vue' /* webpackChunkName: "pages/cashboxLogs" */))
const _d6ba2df2 = () => interopDefault(import('../pages/charts.vue' /* webpackChunkName: "pages/charts" */))
const _c773eb00 = () => interopDefault(import('../pages/companyUsers/index.vue' /* webpackChunkName: "pages/companyUsers/index" */))
const _2b2da598 = () => interopDefault(import('../pages/couponTemplates/index.vue' /* webpackChunkName: "pages/couponTemplates/index" */))
const _15987052 = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _7ee436da = () => interopDefault(import('../pages/discounts/index.vue' /* webpackChunkName: "pages/discounts/index" */))
const _e116332c = () => interopDefault(import('../pages/ips.vue' /* webpackChunkName: "pages/ips" */))
const _5c98f2ea = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _0b6e37bc = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _04426b7c = () => interopDefault(import('../pages/newsSubscription.vue' /* webpackChunkName: "pages/newsSubscription" */))
const _97c0ce4c = () => interopDefault(import('../pages/orders/index.vue' /* webpackChunkName: "pages/orders/index" */))
const _50bfaca2 = () => interopDefault(import('../pages/payments/index.vue' /* webpackChunkName: "pages/payments/index" */))
const _7ab4fd95 = () => interopDefault(import('../pages/pos/index.vue' /* webpackChunkName: "pages/pos/index" */))
const _2f4c4f9e = () => interopDefault(import('../pages/posScreen.vue' /* webpackChunkName: "pages/posScreen" */))
const _dc4d53ce = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _2e016baa = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _13d26136 = () => interopDefault(import('../pages/promotions/index.vue' /* webpackChunkName: "pages/promotions/index" */))
const _7c7d1ed0 = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _11b51e18 = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _93a433f4 = () => interopDefault(import('../pages/shippings/index.vue' /* webpackChunkName: "pages/shippings/index" */))
const _3694b41a = () => interopDefault(import('../pages/shopUsers/index.vue' /* webpackChunkName: "pages/shopUsers/index" */))
const _de83964e = () => interopDefault(import('../pages/specs/index.vue' /* webpackChunkName: "pages/specs/index" */))
const _1474f466 = () => interopDefault(import('../pages/switch.vue' /* webpackChunkName: "pages/switch" */))
const _19818ce9 = () => interopDefault(import('../pages/domoreTest/label.vue' /* webpackChunkName: "pages/domoreTest/label" */))
const _0542efcf = () => interopDefault(import('../pages/domoreTest/printer.vue' /* webpackChunkName: "pages/domoreTest/printer" */))
const _49f5c92d = () => interopDefault(import('../pages/domoreTest/test.vue' /* webpackChunkName: "pages/domoreTest/test" */))
const _24c4af53 = () => interopDefault(import('../pages/domoreTest/weight.vue' /* webpackChunkName: "pages/domoreTest/weight" */))
const _39d255b8 = () => interopDefault(import('../pages/erp/purchasingOrders/index.vue' /* webpackChunkName: "pages/erp/purchasingOrders/index" */))
const _ca734450 = () => interopDefault(import('../pages/erp/wholesaleOrders/index.vue' /* webpackChunkName: "pages/erp/wholesaleOrders/index" */))
const _637b35e6 = () => interopDefault(import('../pages/eshop/articles/index.vue' /* webpackChunkName: "pages/eshop/articles/index" */))
const _e74aead8 = () => interopDefault(import('../pages/eshop/banners/index.vue' /* webpackChunkName: "pages/eshop/banners/index" */))
const _31ac7e35 = () => interopDefault(import('../pages/eshop/brands/index.vue' /* webpackChunkName: "pages/eshop/brands/index" */))
const _2b3fa385 = () => interopDefault(import('../pages/eshop/categories/index.vue' /* webpackChunkName: "pages/eshop/categories/index" */))
const _593195ba = () => interopDefault(import('../pages/eshop/contactField/index.vue' /* webpackChunkName: "pages/eshop/contactField/index" */))
const _641e70de = () => interopDefault(import('../pages/eshop/contactForm/index.vue' /* webpackChunkName: "pages/eshop/contactForm/index" */))
const _b6bee9cc = () => interopDefault(import('../pages/eshop/ecshopArticles/index.vue' /* webpackChunkName: "pages/eshop/ecshopArticles/index" */))
const _652b205e = () => interopDefault(import('../pages/eshop/pages/index.vue' /* webpackChunkName: "pages/eshop/pages/index" */))
const _20d778de = () => interopDefault(import('../pages/eshop/relateds/index.vue' /* webpackChunkName: "pages/eshop/relateds/index" */))
const _3126784c = () => interopDefault(import('../pages/eshop/settings/index.vue' /* webpackChunkName: "pages/eshop/settings/index" */))
const _2a922725 = () => interopDefault(import('../pages/eshop/specs/index.vue' /* webpackChunkName: "pages/eshop/specs/index" */))
const _4f1926b4 = () => interopDefault(import('../pages/eshop/storeLocations/index.vue' /* webpackChunkName: "pages/eshop/storeLocations/index" */))
const _3ad227b2 = () => interopDefault(import('../pages/eshop/storeTags/index.vue' /* webpackChunkName: "pages/eshop/storeTags/index" */))
const _2e138bc2 = () => interopDefault(import('../pages/eshop/tags/index.vue' /* webpackChunkName: "pages/eshop/tags/index" */))
const _18544afa = () => interopDefault(import('../pages/eshop/warranties/index.vue' /* webpackChunkName: "pages/eshop/warranties/index" */))
const _02d98de8 = () => interopDefault(import('../pages/extras/receipts/index.vue' /* webpackChunkName: "pages/extras/receipts/index" */))
const _7adf5e2a = () => interopDefault(import('../pages/login/reset/index.vue' /* webpackChunkName: "pages/login/reset/index" */))
const _2b5c86ca = () => interopDefault(import('../pages/pos/cashBox.vue' /* webpackChunkName: "pages/pos/cashBox" */))
const _6e5ee1de = () => interopDefault(import('../pages/pos/openClose.vue' /* webpackChunkName: "pages/pos/openClose" */))
const _6c6f67f1 = () => interopDefault(import('../pages/pos/punch.vue' /* webpackChunkName: "pages/pos/punch" */))
const _57e998b3 = () => interopDefault(import('../pages/pos/settings/index.vue' /* webpackChunkName: "pages/pos/settings/index" */))
const _25696e02 = () => interopDefault(import('../pages/products/skus.vue' /* webpackChunkName: "pages/products/skus" */))
const _5acc56d6 = () => interopDefault(import('../pages/profile/changeInfo.vue' /* webpackChunkName: "pages/profile/changeInfo" */))
const _63582643 = () => interopDefault(import('../pages/profile/changePassword.vue' /* webpackChunkName: "pages/profile/changePassword" */))
const _06221786 = () => interopDefault(import('../pages/profile/manage.vue' /* webpackChunkName: "pages/profile/manage" */))
const _0ef1ce23 = () => interopDefault(import('../pages/register/verify.vue' /* webpackChunkName: "pages/register/verify" */))
const _5fbdee64 = () => interopDefault(import('../pages/report/driver/index.vue' /* webpackChunkName: "pages/report/driver/index" */))
const _bf579d96 = () => interopDefault(import('../pages/report/driverReport.vue' /* webpackChunkName: "pages/report/driverReport" */))
const _9216dfe4 = () => interopDefault(import('../pages/settings/access.vue' /* webpackChunkName: "pages/settings/access" */))
const _7f8c683c = () => interopDefault(import('../pages/settings/email.vue' /* webpackChunkName: "pages/settings/email" */))
const _3ea72df8 = () => interopDefault(import('../pages/settings/erpid.vue' /* webpackChunkName: "pages/settings/erpid" */))
const _f7f1166e = () => interopDefault(import('../pages/settings/integrations.vue' /* webpackChunkName: "pages/settings/integrations" */))
const _760932a2 = () => interopDefault(import('../pages/settings/inventory.vue' /* webpackChunkName: "pages/settings/inventory" */))
const _3c24fab6 = () => interopDefault(import('../pages/settings/invoiceTemplates.vue' /* webpackChunkName: "pages/settings/invoiceTemplates" */))
const _931aec8c = () => interopDefault(import('../pages/settings/newsSubscription.vue' /* webpackChunkName: "pages/settings/newsSubscription" */))
const _0bf639cc = () => interopDefault(import('../pages/settings/payment.vue' /* webpackChunkName: "pages/settings/payment" */))
const _132b581a = () => interopDefault(import('../pages/settings/pointTypes.vue' /* webpackChunkName: "pages/settings/pointTypes" */))
const _6c03abcc = () => interopDefault(import('../pages/settings/pos.vue' /* webpackChunkName: "pages/settings/pos" */))
const _dd6d6680 = () => interopDefault(import('../pages/settings/posBanners/index.vue' /* webpackChunkName: "pages/settings/posBanners/index" */))
const _63f45e97 = () => interopDefault(import('../pages/settings/shippingCars.vue' /* webpackChunkName: "pages/settings/shippingCars" */))
const _1e82a3ec = () => interopDefault(import('../pages/settings/shippingContainers.vue' /* webpackChunkName: "pages/settings/shippingContainers" */))
const _7606a0ea = () => interopDefault(import('../pages/settings/shippingMethods.vue' /* webpackChunkName: "pages/settings/shippingMethods" */))
const _70eaa44c = () => interopDefault(import('../pages/settings/shippingProviders.vue' /* webpackChunkName: "pages/settings/shippingProviders" */))
const _333cbcff = () => interopDefault(import('../pages/settings/shippingZones.vue' /* webpackChunkName: "pages/settings/shippingZones" */))
const _37ccf920 = () => interopDefault(import('../pages/settings/shop.vue' /* webpackChunkName: "pages/settings/shop" */))
const _356eec60 = () => interopDefault(import('../pages/settings/shopRankGroups.vue' /* webpackChunkName: "pages/settings/shopRankGroups" */))
const _50f54192 = () => interopDefault(import('../pages/settings/shopRanks.vue' /* webpackChunkName: "pages/settings/shopRanks" */))
const _65472a9f = () => interopDefault(import('../pages/settings/sms.vue' /* webpackChunkName: "pages/settings/sms" */))
const _e85a74d2 = () => interopDefault(import('../pages/settings/social.vue' /* webpackChunkName: "pages/settings/social" */))
const _7a1e76fc = () => interopDefault(import('../pages/settings/socialMediaIntegrations.vue' /* webpackChunkName: "pages/settings/socialMediaIntegrations" */))
const _6718d721 = () => interopDefault(import('../pages/settings/turncloud/index.vue' /* webpackChunkName: "pages/settings/turncloud/index" */))
const _a8feab84 = () => interopDefault(import('../pages/settings/ui.vue' /* webpackChunkName: "pages/settings/ui" */))
const _85185eba = () => interopDefault(import('../pages/settings/verify.vue' /* webpackChunkName: "pages/settings/verify" */))
const _0912a6a4 = () => interopDefault(import('../pages/shippings/driver.vue' /* webpackChunkName: "pages/shippings/driver" */))
const _c8812eda = () => interopDefault(import('../pages/shippings/schedule.vue' /* webpackChunkName: "pages/shippings/schedule" */))
const _66cce3f0 = () => interopDefault(import('../pages/warehouses/inventories/index.vue' /* webpackChunkName: "pages/warehouses/inventories/index" */))
const _25bcb861 = () => interopDefault(import('../pages/warehouses/inventoryActions/index.vue' /* webpackChunkName: "pages/warehouses/inventoryActions/index" */))
const _340e9bbe = () => interopDefault(import('../pages/warehouses/inventoryLogs/index.vue' /* webpackChunkName: "pages/warehouses/inventoryLogs/index" */))
const _3c2812f8 = () => interopDefault(import('../pages/erp/wholesaleOrders/newOrder.vue' /* webpackChunkName: "pages/erp/wholesaleOrders/newOrder" */))
const _965172bc = () => interopDefault(import('../pages/erp/wholesaleOrders/quoSuccess.vue' /* webpackChunkName: "pages/erp/wholesaleOrders/quoSuccess" */))
const _2b2599be = () => interopDefault(import('../pages/eshop/contactField/values.vue' /* webpackChunkName: "pages/eshop/contactField/values" */))
const _78a0815f = () => interopDefault(import('../pages/eshop/specs/values.vue' /* webpackChunkName: "pages/eshop/specs/values" */))
const _23a1cc27 = () => interopDefault(import('../pages/login/reset/reset.vue' /* webpackChunkName: "pages/login/reset/reset" */))
const _799192d1 = () => interopDefault(import('../pages/login/reset/verify.vue' /* webpackChunkName: "pages/login/reset/verify" */))
const _53ea1e10 = () => interopDefault(import('../pages/settings/xero/callback.vue' /* webpackChunkName: "pages/settings/xero/callback" */))
const _d7e202d8 = () => interopDefault(import('../pages/warehouses/inventories/lots.vue' /* webpackChunkName: "pages/warehouses/inventories/lots" */))
const _e22ae178 = () => interopDefault(import('../pages/warehouses/inventories/summary.vue' /* webpackChunkName: "pages/warehouses/inventories/summary" */))
const _462ff52d = () => interopDefault(import('../pages/eshop/settings/dialogs/PageSettingInfoDialog.vue' /* webpackChunkName: "pages/eshop/settings/dialogs/PageSettingInfoDialog" */))
const _262ecfcb = () => interopDefault(import('../pages/eshop/settings/pages/layout.vue' /* webpackChunkName: "pages/eshop/settings/pages/layout" */))
const _e521595a = () => interopDefault(import('../pages/erp/purchasingOrders/edit/_id.vue' /* webpackChunkName: "pages/erp/purchasingOrders/edit/_id" */))
const _41849e1f = () => interopDefault(import('../pages/erp/wholesaleOrders/edit/_id.vue' /* webpackChunkName: "pages/erp/wholesaleOrders/edit/_id" */))
const _c27b92b4 = () => interopDefault(import('../pages/erp/wholesaleOrders/monthlyStmt/_id.vue' /* webpackChunkName: "pages/erp/wholesaleOrders/monthlyStmt/_id" */))
const _a2f1b95e = () => interopDefault(import('../pages/eshop/articles/edit/_id.vue' /* webpackChunkName: "pages/eshop/articles/edit/_id" */))
const _0bc667e3 = () => interopDefault(import('../pages/eshop/banners/edit/_id.vue' /* webpackChunkName: "pages/eshop/banners/edit/_id" */))
const _f0d676bc = () => interopDefault(import('../pages/eshop/brands/edit/_id.vue' /* webpackChunkName: "pages/eshop/brands/edit/_id" */))
const _5bd0455c = () => interopDefault(import('../pages/eshop/categories/edit/_id.vue' /* webpackChunkName: "pages/eshop/categories/edit/_id" */))
const _74055cc6 = () => interopDefault(import('../pages/eshop/ecshopArticles/edit/_id.vue' /* webpackChunkName: "pages/eshop/ecshopArticles/edit/_id" */))
const _648c1d86 = () => interopDefault(import('../pages/eshop/pages/edit/_id.vue' /* webpackChunkName: "pages/eshop/pages/edit/_id" */))
const _0604b1c6 = () => interopDefault(import('../pages/eshop/relateds/edit/_id.vue' /* webpackChunkName: "pages/eshop/relateds/edit/_id" */))
const _db2a97e2 = () => interopDefault(import('../pages/extras/receipts/edit/_id.vue' /* webpackChunkName: "pages/extras/receipts/edit/_id" */))
const _d6357d7a = () => interopDefault(import('../pages/couponTemplates/edit/_id.vue' /* webpackChunkName: "pages/couponTemplates/edit/_id" */))
const _a0c74cf8 = () => interopDefault(import('../pages/discounts/edit/_id.vue' /* webpackChunkName: "pages/discounts/edit/_id" */))
const _185e6bdd = () => interopDefault(import('../pages/orders/edit/_id.vue' /* webpackChunkName: "pages/orders/edit/_id" */))
const _ebe79b84 = () => interopDefault(import('../pages/products/edit/_id.vue' /* webpackChunkName: "pages/products/edit/_id" */))
const _d05ab51c = () => interopDefault(import('../pages/promotions/edit/_id.vue' /* webpackChunkName: "pages/promotions/edit/_id" */))
const _56a4ff31 = () => interopDefault(import('../pages/shippings/edit/_id.vue' /* webpackChunkName: "pages/shippings/edit/_id" */))
const _1dfc9c24 = () => interopDefault(import('../pages/shopUsers/edit/_id.vue' /* webpackChunkName: "pages/shopUsers/edit/_id" */))
const _67324616 = () => interopDefault(import('../pages/invoice/_id.vue' /* webpackChunkName: "pages/invoice/_id" */))
const _410f2eb7 = () => interopDefault(import('../pages/o/_id.js' /* webpackChunkName: "pages/o/_id" */))
const _ae1f48d4 = () => interopDefault(import('../pages/p/_id.js' /* webpackChunkName: "pages/p/_id" */))
const _7c1ba2c6 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))
const _0b283ae0 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/:shop([a-f\\d]{24})/cashboxLogs",
    component: _44fa1245,
    name: "shop-cashboxLogs"
  }, {
    path: "/:shop([a-f\\d]{24})/charts",
    component: _d6ba2df2,
    name: "shop-charts"
  }, {
    path: "/:shop([a-f\\d]{24})/companyUsers",
    component: _c773eb00,
    name: "shop-companyUsers"
  }, {
    path: "/:shop([a-f\\d]{24})/couponTemplates",
    component: _2b2da598,
    name: "shop-couponTemplates"
  }, {
    path: "/:shop([a-f\\d]{24})/dashboard",
    component: _15987052,
    name: "shop-dashboard"
  }, {
    path: "/:shop([a-f\\d]{24})/discounts",
    component: _7ee436da,
    name: "shop-discounts"
  }, {
    path: "/:shop([a-f\\d]{24})/ips",
    component: _e116332c,
    name: "shop-ips"
  }, {
    path: "/:shop([a-f\\d]{24})/login",
    component: _5c98f2ea,
    name: "shop-login"
  }, {
    path: "/:shop([a-f\\d]{24})/logout",
    component: _0b6e37bc,
    name: "shop-logout"
  }, {
    path: "/:shop([a-f\\d]{24})/newsSubscription",
    component: _04426b7c,
    name: "shop-newsSubscription"
  }, {
    path: "/:shop([a-f\\d]{24})/orders",
    component: _97c0ce4c,
    name: "shop-orders"
  }, {
    path: "/:shop([a-f\\d]{24})/payments",
    component: _50bfaca2,
    name: "shop-payments"
  }, {
    path: "/:shop([a-f\\d]{24})/pos",
    component: _7ab4fd95,
    name: "shop-pos"
  }, {
    path: "/:shop([a-f\\d]{24})/posScreen",
    component: _2f4c4f9e,
    name: "shop-posScreen"
  }, {
    path: "/:shop([a-f\\d]{24})/products",
    component: _dc4d53ce,
    name: "shop-products"
  }, {
    path: "/:shop([a-f\\d]{24})/profile",
    component: _2e016baa,
    name: "shop-profile"
  }, {
    path: "/:shop([a-f\\d]{24})/promotions",
    component: _13d26136,
    name: "shop-promotions"
  }, {
    path: "/:shop([a-f\\d]{24})/register",
    component: _7c7d1ed0,
    name: "shop-register"
  }, {
    path: "/:shop([a-f\\d]{24})/settings",
    component: _11b51e18,
    name: "shop-settings"
  }, {
    path: "/:shop([a-f\\d]{24})/shippings",
    component: _93a433f4,
    name: "shop-shippings"
  }, {
    path: "/:shop([a-f\\d]{24})/shopUsers",
    component: _3694b41a,
    name: "shop-shopUsers"
  }, {
    path: "/:shop([a-f\\d]{24})/specs",
    component: _de83964e,
    name: "shop-specs"
  }, {
    path: "/:shop([a-f\\d]{24})/switch",
    component: _1474f466,
    name: "shop-switch"
  }, {
    path: "/:shop([a-f\\d]{24})/domoreTest/label",
    component: _19818ce9,
    name: "shop-domoreTest-label"
  }, {
    path: "/:shop([a-f\\d]{24})/domoreTest/printer",
    component: _0542efcf,
    name: "shop-domoreTest-printer"
  }, {
    path: "/:shop([a-f\\d]{24})/domoreTest/test",
    component: _49f5c92d,
    name: "shop-domoreTest-test"
  }, {
    path: "/:shop([a-f\\d]{24})/domoreTest/weight",
    component: _24c4af53,
    name: "shop-domoreTest-weight"
  }, {
    path: "/:shop([a-f\\d]{24})/erp/purchasingOrders",
    component: _39d255b8,
    name: "shop-erp-purchasingOrders"
  }, {
    path: "/:shop([a-f\\d]{24})/erp/wholesaleOrders",
    component: _ca734450,
    name: "shop-erp-wholesaleOrders"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/articles",
    component: _637b35e6,
    name: "shop-eshop-articles"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/banners",
    component: _e74aead8,
    name: "shop-eshop-banners"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/brands",
    component: _31ac7e35,
    name: "shop-eshop-brands"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/categories",
    component: _2b3fa385,
    name: "shop-eshop-categories"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/contactField",
    component: _593195ba,
    name: "shop-eshop-contactField"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/contactForm",
    component: _641e70de,
    name: "shop-eshop-contactForm"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/ecshopArticles",
    component: _b6bee9cc,
    name: "shop-eshop-ecshopArticles"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/pages",
    component: _652b205e,
    name: "shop-eshop-pages"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/relateds",
    component: _20d778de,
    name: "shop-eshop-relateds"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/settings",
    component: _3126784c,
    name: "shop-eshop-settings"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/specs",
    component: _2a922725,
    name: "shop-eshop-specs"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/storeLocations",
    component: _4f1926b4,
    name: "shop-eshop-storeLocations"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/storeTags",
    component: _3ad227b2,
    name: "shop-eshop-storeTags"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/tags",
    component: _2e138bc2,
    name: "shop-eshop-tags"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/warranties",
    component: _18544afa,
    name: "shop-eshop-warranties"
  }, {
    path: "/:shop([a-f\\d]{24})/extras/receipts",
    component: _02d98de8,
    name: "shop-extras-receipts"
  }, {
    path: "/:shop([a-f\\d]{24})/login/reset",
    component: _7adf5e2a,
    name: "shop-login-reset"
  }, {
    path: "/:shop([a-f\\d]{24})/pos/cashBox",
    component: _2b5c86ca,
    name: "shop-pos-cashBox"
  }, {
    path: "/:shop([a-f\\d]{24})/pos/openClose",
    component: _6e5ee1de,
    name: "shop-pos-openClose"
  }, {
    path: "/:shop([a-f\\d]{24})/pos/punch",
    component: _6c6f67f1,
    name: "shop-pos-punch"
  }, {
    path: "/:shop([a-f\\d]{24})/pos/settings",
    component: _57e998b3,
    name: "shop-pos-settings"
  }, {
    path: "/:shop([a-f\\d]{24})/products/skus",
    component: _25696e02,
    name: "shop-products-skus"
  }, {
    path: "/:shop([a-f\\d]{24})/profile/changeInfo",
    component: _5acc56d6,
    name: "shop-profile-changeInfo"
  }, {
    path: "/:shop([a-f\\d]{24})/profile/changePassword",
    component: _63582643,
    name: "shop-profile-changePassword"
  }, {
    path: "/:shop([a-f\\d]{24})/profile/manage",
    component: _06221786,
    name: "shop-profile-manage"
  }, {
    path: "/:shop([a-f\\d]{24})/register/verify",
    component: _0ef1ce23,
    name: "shop-register-verify"
  }, {
    path: "/:shop([a-f\\d]{24})/report/driver",
    component: _5fbdee64,
    name: "shop-report-driver"
  }, {
    path: "/:shop([a-f\\d]{24})/report/driverReport",
    component: _bf579d96,
    name: "shop-report-driverReport"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/access",
    component: _9216dfe4,
    name: "shop-settings-access"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/email",
    component: _7f8c683c,
    name: "shop-settings-email"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/erpid",
    component: _3ea72df8,
    name: "shop-settings-erpid"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/integrations",
    component: _f7f1166e,
    name: "shop-settings-integrations"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/inventory",
    component: _760932a2,
    name: "shop-settings-inventory"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/invoiceTemplates",
    component: _3c24fab6,
    name: "shop-settings-invoiceTemplates"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/newsSubscription",
    component: _931aec8c,
    name: "shop-settings-newsSubscription"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/payment",
    component: _0bf639cc,
    name: "shop-settings-payment"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/pointTypes",
    component: _132b581a,
    name: "shop-settings-pointTypes"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/pos",
    component: _6c03abcc,
    name: "shop-settings-pos"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/posBanners",
    component: _dd6d6680,
    name: "shop-settings-posBanners"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/shippingCars",
    component: _63f45e97,
    name: "shop-settings-shippingCars"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/shippingContainers",
    component: _1e82a3ec,
    name: "shop-settings-shippingContainers"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/shippingMethods",
    component: _7606a0ea,
    name: "shop-settings-shippingMethods"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/shippingProviders",
    component: _70eaa44c,
    name: "shop-settings-shippingProviders"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/shippingZones",
    component: _333cbcff,
    name: "shop-settings-shippingZones"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/shop",
    component: _37ccf920,
    name: "shop-settings-shop"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/shopRankGroups",
    component: _356eec60,
    name: "shop-settings-shopRankGroups"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/shopRanks",
    component: _50f54192,
    name: "shop-settings-shopRanks"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/sms",
    component: _65472a9f,
    name: "shop-settings-sms"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/social",
    component: _e85a74d2,
    name: "shop-settings-social"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/socialMediaIntegrations",
    component: _7a1e76fc,
    name: "shop-settings-socialMediaIntegrations"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/turncloud",
    component: _6718d721,
    name: "shop-settings-turncloud"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/ui",
    component: _a8feab84,
    name: "shop-settings-ui"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/verify",
    component: _85185eba,
    name: "shop-settings-verify"
  }, {
    path: "/:shop([a-f\\d]{24})/shippings/driver",
    component: _0912a6a4,
    name: "shop-shippings-driver"
  }, {
    path: "/:shop([a-f\\d]{24})/shippings/schedule",
    component: _c8812eda,
    name: "shop-shippings-schedule"
  }, {
    path: "/:shop([a-f\\d]{24})/warehouses/inventories",
    component: _66cce3f0,
    name: "shop-warehouses-inventories"
  }, {
    path: "/:shop([a-f\\d]{24})/warehouses/inventoryActions",
    component: _25bcb861,
    name: "shop-warehouses-inventoryActions"
  }, {
    path: "/:shop([a-f\\d]{24})/warehouses/inventoryLogs",
    component: _340e9bbe,
    name: "shop-warehouses-inventoryLogs"
  }, {
    path: "/:shop([a-f\\d]{24})/erp/wholesaleOrders/newOrder",
    component: _3c2812f8,
    name: "shop-erp-wholesaleOrders-newOrder"
  }, {
    path: "/:shop([a-f\\d]{24})/erp/wholesaleOrders/quoSuccess",
    component: _965172bc,
    name: "shop-erp-wholesaleOrders-quoSuccess"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/contactField/values",
    component: _2b2599be,
    name: "shop-eshop-contactField-values"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/specs/values",
    component: _78a0815f,
    name: "shop-eshop-specs-values"
  }, {
    path: "/:shop([a-f\\d]{24})/login/reset/reset",
    component: _23a1cc27,
    name: "shop-login-reset-reset"
  }, {
    path: "/:shop([a-f\\d]{24})/login/reset/verify",
    component: _799192d1,
    name: "shop-login-reset-verify"
  }, {
    path: "/:shop([a-f\\d]{24})/settings/xero/callback",
    component: _53ea1e10,
    name: "shop-settings-xero-callback"
  }, {
    path: "/:shop([a-f\\d]{24})/warehouses/inventories/lots",
    component: _d7e202d8,
    name: "shop-warehouses-inventories-lots"
  }, {
    path: "/:shop([a-f\\d]{24})/warehouses/inventories/summary",
    component: _e22ae178,
    name: "shop-warehouses-inventories-summary"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/settings/dialogs/PageSettingInfoDialog",
    component: _462ff52d,
    name: "shop-eshop-settings-dialogs-PageSettingInfoDialog"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/settings/pages/layout",
    component: _262ecfcb,
    name: "shop-eshop-settings-pages-layout"
  }, {
    path: "/:shop([a-f\\d]{24})/",
    component: _15987052,
    name: "shop-dashboard-index"
  }, {
    path: "/:shop([a-f\\d]{24})/erp/purchasingOrders/edit/:id?",
    component: _e521595a,
    name: "shop-erp-purchasingOrders-edit-id"
  }, {
    path: "/:shop([a-f\\d]{24})/erp/wholesaleOrders/edit/:id?",
    component: _41849e1f,
    name: "shop-erp-wholesaleOrders-edit-id"
  }, {
    path: "/:shop([a-f\\d]{24})/erp/wholesaleOrders/monthlyStmt/:id?",
    component: _c27b92b4,
    name: "shop-erp-wholesaleOrders-monthlyStmt-id"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/articles/edit/:id?",
    component: _a2f1b95e,
    name: "shop-eshop-articles-edit-id"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/banners/edit/:id?",
    component: _0bc667e3,
    name: "shop-eshop-banners-edit-id"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/brands/edit/:id?",
    component: _f0d676bc,
    name: "shop-eshop-brands-edit-id"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/categories/edit/:id?",
    component: _5bd0455c,
    name: "shop-eshop-categories-edit-id"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/ecshopArticles/edit/:id?",
    component: _74055cc6,
    name: "shop-eshop-ecshopArticles-edit-id"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/pages/edit/:id?",
    component: _648c1d86,
    name: "shop-eshop-pages-edit-id"
  }, {
    path: "/:shop([a-f\\d]{24})/eshop/relateds/edit/:id?",
    component: _0604b1c6,
    name: "shop-eshop-relateds-edit-id"
  }, {
    path: "/:shop([a-f\\d]{24})/extras/receipts/edit/:id?",
    component: _db2a97e2,
    name: "shop-extras-receipts-edit-id"
  }, {
    path: "/:shop([a-f\\d]{24})/couponTemplates/edit/:id?",
    component: _d6357d7a,
    name: "shop-couponTemplates-edit-id"
  }, {
    path: "/:shop([a-f\\d]{24})/discounts/edit/:id?",
    component: _a0c74cf8,
    name: "shop-discounts-edit-id"
  }, {
    path: "/:shop([a-f\\d]{24})/orders/edit/:id?",
    component: _185e6bdd,
    name: "shop-orders-edit-id"
  }, {
    path: "/:shop([a-f\\d]{24})/products/edit/:id?",
    component: _ebe79b84,
    name: "shop-products-edit-id"
  }, {
    path: "/:shop([a-f\\d]{24})/promotions/edit/:id?",
    component: _d05ab51c,
    name: "shop-promotions-edit-id"
  }, {
    path: "/:shop([a-f\\d]{24})/shippings/edit/:id?",
    component: _56a4ff31,
    name: "shop-shippings-edit-id"
  }, {
    path: "/:shop([a-f\\d]{24})/shopUsers/edit/:id?",
    component: _1dfc9c24,
    name: "shop-shopUsers-edit-id"
  }, {
    path: "/:shop([a-f\\d]{24})/invoice/:id?",
    component: _67324616,
    name: "shop-invoice-id"
  }, {
    path: "/:shop([a-f\\d]{24})/o/:id?",
    component: _410f2eb7,
    name: "shop-o-id"
  }, {
    path: "/:shop([a-f\\d]{24})/p/:id?",
    component: _ae1f48d4,
    name: "shop-p-id"
  }, {
    path: "/:shop([a-f\\d]{24})/*",
    component: _7c1ba2c6,
    name: "shop-all"
  }, {
    path: "/cashboxLogs",
    component: _44fa1245,
    name: "cashboxLogs"
  }, {
    path: "/charts",
    component: _d6ba2df2,
    name: "charts"
  }, {
    path: "/companyUsers",
    component: _c773eb00,
    name: "companyUsers"
  }, {
    path: "/couponTemplates",
    component: _2b2da598,
    name: "couponTemplates"
  }, {
    path: "/dashboard",
    component: _15987052,
    name: "dashboard"
  }, {
    path: "/discounts",
    component: _7ee436da,
    name: "discounts"
  }, {
    path: "/ips",
    component: _e116332c,
    name: "ips"
  }, {
    path: "/login",
    component: _5c98f2ea,
    name: "login"
  }, {
    path: "/logout",
    component: _0b6e37bc,
    name: "logout"
  }, {
    path: "/newsSubscription",
    component: _04426b7c,
    name: "newsSubscription"
  }, {
    path: "/orders",
    component: _97c0ce4c,
    name: "orders"
  }, {
    path: "/payments",
    component: _50bfaca2,
    name: "payments"
  }, {
    path: "/pos",
    component: _7ab4fd95,
    name: "pos"
  }, {
    path: "/posScreen",
    component: _2f4c4f9e,
    name: "posScreen"
  }, {
    path: "/products",
    component: _dc4d53ce,
    name: "products"
  }, {
    path: "/profile",
    component: _2e016baa,
    name: "profile"
  }, {
    path: "/promotions",
    component: _13d26136,
    name: "promotions"
  }, {
    path: "/register",
    component: _7c7d1ed0,
    name: "register"
  }, {
    path: "/settings",
    component: _11b51e18,
    name: "settings"
  }, {
    path: "/shippings",
    component: _93a433f4,
    name: "shippings"
  }, {
    path: "/shopUsers",
    component: _3694b41a,
    name: "shopUsers"
  }, {
    path: "/specs",
    component: _de83964e,
    name: "specs"
  }, {
    path: "/switch",
    component: _1474f466,
    name: "switch"
  }, {
    path: "/domoreTest/label",
    component: _19818ce9,
    name: "domoreTest-label"
  }, {
    path: "/domoreTest/printer",
    component: _0542efcf,
    name: "domoreTest-printer"
  }, {
    path: "/domoreTest/test",
    component: _49f5c92d,
    name: "domoreTest-test"
  }, {
    path: "/domoreTest/weight",
    component: _24c4af53,
    name: "domoreTest-weight"
  }, {
    path: "/erp/purchasingOrders",
    component: _39d255b8,
    name: "erp-purchasingOrders"
  }, {
    path: "/erp/wholesaleOrders",
    component: _ca734450,
    name: "erp-wholesaleOrders"
  }, {
    path: "/eshop/articles",
    component: _637b35e6,
    name: "eshop-articles"
  }, {
    path: "/eshop/banners",
    component: _e74aead8,
    name: "eshop-banners"
  }, {
    path: "/eshop/brands",
    component: _31ac7e35,
    name: "eshop-brands"
  }, {
    path: "/eshop/categories",
    component: _2b3fa385,
    name: "eshop-categories"
  }, {
    path: "/eshop/contactField",
    component: _593195ba,
    name: "eshop-contactField"
  }, {
    path: "/eshop/contactForm",
    component: _641e70de,
    name: "eshop-contactForm"
  }, {
    path: "/eshop/ecshopArticles",
    component: _b6bee9cc,
    name: "eshop-ecshopArticles"
  }, {
    path: "/eshop/pages",
    component: _652b205e,
    name: "eshop-pages"
  }, {
    path: "/eshop/relateds",
    component: _20d778de,
    name: "eshop-relateds"
  }, {
    path: "/eshop/settings",
    component: _3126784c,
    name: "eshop-settings"
  }, {
    path: "/eshop/specs",
    component: _2a922725,
    name: "eshop-specs"
  }, {
    path: "/eshop/storeLocations",
    component: _4f1926b4,
    name: "eshop-storeLocations"
  }, {
    path: "/eshop/storeTags",
    component: _3ad227b2,
    name: "eshop-storeTags"
  }, {
    path: "/eshop/tags",
    component: _2e138bc2,
    name: "eshop-tags"
  }, {
    path: "/eshop/warranties",
    component: _18544afa,
    name: "eshop-warranties"
  }, {
    path: "/extras/receipts",
    component: _02d98de8,
    name: "extras-receipts"
  }, {
    path: "/login/reset",
    component: _7adf5e2a,
    name: "login-reset"
  }, {
    path: "/pos/cashBox",
    component: _2b5c86ca,
    name: "pos-cashBox"
  }, {
    path: "/pos/openClose",
    component: _6e5ee1de,
    name: "pos-openClose"
  }, {
    path: "/pos/punch",
    component: _6c6f67f1,
    name: "pos-punch"
  }, {
    path: "/pos/settings",
    component: _57e998b3,
    name: "pos-settings"
  }, {
    path: "/products/skus",
    component: _25696e02,
    name: "products-skus"
  }, {
    path: "/profile/changeInfo",
    component: _5acc56d6,
    name: "profile-changeInfo"
  }, {
    path: "/profile/changePassword",
    component: _63582643,
    name: "profile-changePassword"
  }, {
    path: "/profile/manage",
    component: _06221786,
    name: "profile-manage"
  }, {
    path: "/register/verify",
    component: _0ef1ce23,
    name: "register-verify"
  }, {
    path: "/report/driver",
    component: _5fbdee64,
    name: "report-driver"
  }, {
    path: "/report/driverReport",
    component: _bf579d96,
    name: "report-driverReport"
  }, {
    path: "/settings/access",
    component: _9216dfe4,
    name: "settings-access"
  }, {
    path: "/settings/email",
    component: _7f8c683c,
    name: "settings-email"
  }, {
    path: "/settings/erpid",
    component: _3ea72df8,
    name: "settings-erpid"
  }, {
    path: "/settings/integrations",
    component: _f7f1166e,
    name: "settings-integrations"
  }, {
    path: "/settings/inventory",
    component: _760932a2,
    name: "settings-inventory"
  }, {
    path: "/settings/invoiceTemplates",
    component: _3c24fab6,
    name: "settings-invoiceTemplates"
  }, {
    path: "/settings/newsSubscription",
    component: _931aec8c,
    name: "settings-newsSubscription"
  }, {
    path: "/settings/payment",
    component: _0bf639cc,
    name: "settings-payment"
  }, {
    path: "/settings/pointTypes",
    component: _132b581a,
    name: "settings-pointTypes"
  }, {
    path: "/settings/pos",
    component: _6c03abcc,
    name: "settings-pos"
  }, {
    path: "/settings/posBanners",
    component: _dd6d6680,
    name: "settings-posBanners"
  }, {
    path: "/settings/shippingCars",
    component: _63f45e97,
    name: "settings-shippingCars"
  }, {
    path: "/settings/shippingContainers",
    component: _1e82a3ec,
    name: "settings-shippingContainers"
  }, {
    path: "/settings/shippingMethods",
    component: _7606a0ea,
    name: "settings-shippingMethods"
  }, {
    path: "/settings/shippingProviders",
    component: _70eaa44c,
    name: "settings-shippingProviders"
  }, {
    path: "/settings/shippingZones",
    component: _333cbcff,
    name: "settings-shippingZones"
  }, {
    path: "/settings/shop",
    component: _37ccf920,
    name: "settings-shop"
  }, {
    path: "/settings/shopRankGroups",
    component: _356eec60,
    name: "settings-shopRankGroups"
  }, {
    path: "/settings/shopRanks",
    component: _50f54192,
    name: "settings-shopRanks"
  }, {
    path: "/settings/sms",
    component: _65472a9f,
    name: "settings-sms"
  }, {
    path: "/settings/social",
    component: _e85a74d2,
    name: "settings-social"
  }, {
    path: "/settings/socialMediaIntegrations",
    component: _7a1e76fc,
    name: "settings-socialMediaIntegrations"
  }, {
    path: "/settings/turncloud",
    component: _6718d721,
    name: "settings-turncloud"
  }, {
    path: "/settings/ui",
    component: _a8feab84,
    name: "settings-ui"
  }, {
    path: "/settings/verify",
    component: _85185eba,
    name: "settings-verify"
  }, {
    path: "/shippings/driver",
    component: _0912a6a4,
    name: "shippings-driver"
  }, {
    path: "/shippings/schedule",
    component: _c8812eda,
    name: "shippings-schedule"
  }, {
    path: "/warehouses/inventories",
    component: _66cce3f0,
    name: "warehouses-inventories"
  }, {
    path: "/warehouses/inventoryActions",
    component: _25bcb861,
    name: "warehouses-inventoryActions"
  }, {
    path: "/warehouses/inventoryLogs",
    component: _340e9bbe,
    name: "warehouses-inventoryLogs"
  }, {
    path: "/erp/wholesaleOrders/newOrder",
    component: _3c2812f8,
    name: "erp-wholesaleOrders-newOrder"
  }, {
    path: "/erp/wholesaleOrders/quoSuccess",
    component: _965172bc,
    name: "erp-wholesaleOrders-quoSuccess"
  }, {
    path: "/eshop/contactField/values",
    component: _2b2599be,
    name: "eshop-contactField-values"
  }, {
    path: "/eshop/specs/values",
    component: _78a0815f,
    name: "eshop-specs-values"
  }, {
    path: "/login/reset/reset",
    component: _23a1cc27,
    name: "login-reset-reset"
  }, {
    path: "/login/reset/verify",
    component: _799192d1,
    name: "login-reset-verify"
  }, {
    path: "/settings/xero/callback",
    component: _53ea1e10,
    name: "settings-xero-callback"
  }, {
    path: "/warehouses/inventories/lots",
    component: _d7e202d8,
    name: "warehouses-inventories-lots"
  }, {
    path: "/warehouses/inventories/summary",
    component: _e22ae178,
    name: "warehouses-inventories-summary"
  }, {
    path: "/eshop/settings/dialogs/PageSettingInfoDialog",
    component: _462ff52d,
    name: "eshop-settings-dialogs-PageSettingInfoDialog"
  }, {
    path: "/eshop/settings/pages/layout",
    component: _262ecfcb,
    name: "eshop-settings-pages-layout"
  }, {
    path: "/",
    component: _0b283ae0,
    name: "index"
  }, {
    path: "/erp/purchasingOrders/edit/:id?",
    component: _e521595a,
    name: "erp-purchasingOrders-edit-id"
  }, {
    path: "/erp/wholesaleOrders/edit/:id?",
    component: _41849e1f,
    name: "erp-wholesaleOrders-edit-id"
  }, {
    path: "/erp/wholesaleOrders/monthlyStmt/:id?",
    component: _c27b92b4,
    name: "erp-wholesaleOrders-monthlyStmt-id"
  }, {
    path: "/eshop/articles/edit/:id?",
    component: _a2f1b95e,
    name: "eshop-articles-edit-id"
  }, {
    path: "/eshop/banners/edit/:id?",
    component: _0bc667e3,
    name: "eshop-banners-edit-id"
  }, {
    path: "/eshop/brands/edit/:id?",
    component: _f0d676bc,
    name: "eshop-brands-edit-id"
  }, {
    path: "/eshop/categories/edit/:id?",
    component: _5bd0455c,
    name: "eshop-categories-edit-id"
  }, {
    path: "/eshop/ecshopArticles/edit/:id?",
    component: _74055cc6,
    name: "eshop-ecshopArticles-edit-id"
  }, {
    path: "/eshop/pages/edit/:id?",
    component: _648c1d86,
    name: "eshop-pages-edit-id"
  }, {
    path: "/eshop/relateds/edit/:id?",
    component: _0604b1c6,
    name: "eshop-relateds-edit-id"
  }, {
    path: "/extras/receipts/edit/:id?",
    component: _db2a97e2,
    name: "extras-receipts-edit-id"
  }, {
    path: "/couponTemplates/edit/:id?",
    component: _d6357d7a,
    name: "couponTemplates-edit-id"
  }, {
    path: "/discounts/edit/:id?",
    component: _a0c74cf8,
    name: "discounts-edit-id"
  }, {
    path: "/orders/edit/:id?",
    component: _185e6bdd,
    name: "orders-edit-id"
  }, {
    path: "/products/edit/:id?",
    component: _ebe79b84,
    name: "products-edit-id"
  }, {
    path: "/promotions/edit/:id?",
    component: _d05ab51c,
    name: "promotions-edit-id"
  }, {
    path: "/shippings/edit/:id?",
    component: _56a4ff31,
    name: "shippings-edit-id"
  }, {
    path: "/shopUsers/edit/:id?",
    component: _1dfc9c24,
    name: "shopUsers-edit-id"
  }, {
    path: "/invoice/:id?",
    component: _67324616,
    name: "invoice-id"
  }, {
    path: "/o/:id?",
    component: _410f2eb7,
    name: "o-id"
  }, {
    path: "/p/:id?",
    component: _ae1f48d4,
    name: "p-id"
  }, {
    path: "/*",
    component: _7c1ba2c6,
    name: "all"
  }],

  parseQuery: function(query) {
      return require('qs').parse(query);
    },
  stringifyQuery: function(query) {
      var result = require('qs').stringify(query);
      return result ? '?' + result : '';
    },
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
