
export default {
    props: {
        func: {},
    },
    methods: {
        renderDefault() {
            const node = (<any>this).$scopedSlots.default((<any>this).$scopedSlots);
            for(let n of node) {
                if(n.data?.scopedSlots) {
                    n.data.scopedSlots.$stable = true;
                }
            }
            return node;
        }
    }
}
