
import { Vue, Component, Prop } from "@feathers-client";
import { ProductGroupExtend } from "~/pages/products/index.vue";

@Component
export default class ProductStatusCell extends Vue {
  @Prop()
  product: ProductGroupExtend;

  @Prop()
  shop: string;

  @Prop()
  filterShops: string | string[];

  get item() {
    return this.shop ? this.product?.shopStatus[this.shop] : this.product;
  }

  get status() {
    return this.item?.status || null;
  }

  async openStatus() {
    await this.$openDialog(
      import("~/components/boxs/BatchProductStatusDialog.vue"),
      {
        products: [this.product],
        shop: this.shop || this.filterShops,
        filterShops: Array.isArray(this.filterShops) ? this.filterShops : this.filterShops ? [this.filterShops] : [],
        currentStatus: this.status && this.status !== "mixed" ? this.status : null,
      },
      {
        maxWidth: "600px",
      },
    );
  }

  get sellingStatus() {
    switch(this.status) {
      case 'selling':
        return 'bg-green400';
      case 'mixed':
        return 'bg-orange400';
      case 'draft':
        return 'bg-yellow000';
      default:
        return 'bg-grey400';
    }
  }
}
