
import _ from "lodash";
import { Component, Prop, Vue, Watch, mixins, Ref, PropSync, VModel, FindType, checkID, getID } from "@feathers-client";
// @ts-ignore
import type { SearchConfig } from "@schemaEditor/plugin/defs";
import { getPathAdv } from "@feathers-client/util";
import { escapeRegExp } from "../util";

export function normalizeConfig(conf: string | SearchConfig, value: string) {
  const cfg: SearchConfig =
    typeof conf === "string"
      ? {
          field: conf,
          mode: "regexp",
        }
      : { mode: "regexp", ...conf };

  if (value) {
    if (cfg.field === "_id") {
      if (/^[a-f\d]{24}$/i.test(cfg.mongo)) cfg.mongo = value;
      else {
        cfg.mongo = null;
      }
    } else if (cfg.mode !== "none") {
      if (!cfg.mode) {
        cfg.mode = "regstart";
      }
      const regstr = `${cfg.mode.startsWith("regstart") ? "^" : ""}${
        cfg.mode.endsWith("lowercase")
          ? escapeRegExp(value).toLowerCase()
          : cfg.mode.endsWith("uppercase")
            ? escapeRegExp(value).toUpperCase()
            : escapeRegExp(value)
      }`;
      const regopt = cfg.mode.endsWith("case") ? "" : "i";

      cfg.regexp = regstr;
      cfg.regopt = regopt;
      const field = Array.isArray(cfg.field) ? cfg.field.filter(it => it !== "*").join(".") : cfg.field;
      cfg.mongoField = cfg.translate ? field + ".value" : field;
      cfg.mongo = {
        $regex: regstr,
        $options: regopt,
      };
    } else {
      cfg.regexp = null;
      cfg.regopt = null;
      cfg.mongo = null;
    }
  } else {
    cfg.regexp = null;
    cfg.regopt = null;
    cfg.mongo = null;
  }
  return cfg;
}

function reverseConfig(vue: Vue, conf: SearchConfig, value: string) {
  return conf.translate
    ? [
        {
          // @ts-ignore
          lang: vue.$schemas?.finalLocale ?? vue.$schemas?.locale ?? vue.$i18n?.locale ?? vue.$store.state.locale,
          value,
        },
      ]
    : value;
}

@Component
export default class MenuListPicker extends Vue {
  @Prop()
  path: string;

  @Prop()
  enterkeyhint: string;

  @Prop()
  placeholder: string;

  /**
   * Display fields
   */
  @Prop({ type: Array, default: () => ["name"] })
  fields: (string | SearchConfig)[];

  /**
   * Searchable fields
   */
  @Prop({ type: Array, default: null })
  searchFields: (string | SearchConfig)[];

  @Prop({ type: Boolean, default: true })
  searchable: boolean;

  @Prop(Boolean)
  serverSearch: boolean;

  @Prop({ default: "$empty" })
  rowIcon: string;

  @Prop({ default: "$tick" })
  activeIcon: string;

  @Prop()
  items: any[];

  @Prop(Boolean)
  combo: boolean;

  @Prop(Boolean)
  multiple: boolean;

  @Prop()
  query: any;

  @Prop()
  sort: any;

  @Prop(Boolean)
  readonly: boolean;

  @Prop(Boolean)
  ordered: boolean;

  @Prop({ default: "border-orange100" })
  activeClass: string;

  @Prop({ default: "border-transparent" })
  inactiveClass: string;

  @Prop({ type: Boolean, default: false })
  preferSingle: boolean;

  @Prop({ type: Boolean })
  returnObject: boolean;

  @Prop(Boolean)
  mandatory: boolean;

  @Prop(Boolean)
  stringOnly: boolean;

  @Prop()
  createNameField: string | SearchConfig;

  @Prop({ type: Boolean, default: true })
  border: boolean;

  @Prop({ default: "border-1" })
  borderStyle: string;

  @Prop({ default: "text-sm" })
  textStyle: string;

  @Prop({ type: Boolean, default: false })
  nowrap: boolean;

  @Prop()
  displayLabel: string;

  get createConfig() {
    // @ts-ignore
    return this.path && this.$schemas?.lookupRoute?.(this.path);
  }

  get canCreate() {
    if (!this.createNameField) return false;
    const cfg = this.createConfig;
    if (!cfg) return false;
    if (!cfg.create) return false;
    if (cfg.acl) {
      // @ts-ignore
      const acl = this.$schemas?.aclList?.[cfg.acl];
      return acl && acl(this, "create");
    }
    return true;
  }

  get canEdit() {
    const cfg = this.createConfig;
    if (!cfg) return false;
    if (!cfg.patch) return false;
    if (cfg.acl) {
      // @ts-ignore
      const acl = this.$schemas?.aclList?.[cfg.acl];
      return acl && acl(this, "patch");
    }
    return true;
  }

  get group() {
    if (this.returnObject || !this.path) {
      return undefined;
    }
    return {
      name: "shared-" + this.path,
      pull: "clone",
    };
  }

  get normalizedFields() {
    return this.fields.map(f => normalizeConfig(f, this.searchKeyword));
  }

  get normalizedSearchFields() {
    return this.searchFields?.map?.(f => normalizeConfig(f, this.searchKeyword)) ?? [];
  }

  get cols() {
    return Math.max(1, (this.fields?.length ?? 0) + (this.searchFields?.length ? 1 : 0));
  }

  get colStyle() {
    return `flex-basis: ${100 / this.cols}%; word-break: break-word;`;
  }

  getter(item: any, field: SearchConfig) {
    const v = getPathAdv(item, field.field);
    if (field.multiple) {
      if (Array.isArray(v)) {
        return v
          .map(it => {
            if (field.translate) return this.$td(it);
            return it;
          })
          .join("/");
      } else return item;
    } else if (field.translate) return this.$td(v);
    return v;
  }

  removeItem(item: any) {
    if (this.multiple) {
      if (Array.isArray(this.inputValue)) {
        this.inputValue = this.inputValue.filter(it => !checkID(it, item));
      }
    } else {
      this.inputValue = null;
    }
  }

  chooseItem(item: any) {
    if (this.stringOnly) {
      if (typeof item === "object") {
        item = item._id;
      }
      if (this.multiple) {
        if (!item) return;
        if (Array.isArray(this.inputValue) && this.inputValue.find(it => it === item)) {
          this.inputValue = this.inputValue.filter(it => it !== item);
        } else {
          this.inputValue = [...(Array.isArray(this.inputValue) ? this.inputValue : []), item];
        }
      } else {
        this.inputValue = item || "";
      }
      return;
    }
    if (this.multiple) {
      if (item) {
        let iv = Array.isArray(this.inputValue) ? this.inputValue : [];
        if (iv.find(it => checkID(it, item))) {
          this.inputValue = iv.filter(it => !checkID(it, item));
        } else {
          if (!Array.isArray(this.maybeValue)) {
            this.maybeValue = [];
          }
          if (!this.maybeValue.find(it => checkID(it, item))) {
            this.maybeValue.push(item);
          }
          if (!this.cachedValue) {
            this.cachedValue = [item];
          } else if (Array.isArray(this.cachedValue) && !this.cachedValue.find(it => checkID(it, item))) {
            this.cachedValue.push(item);
          }
          this.inputValue = [...iv, this.returnObject ? item : getID(item)];
        }
      } else {
        this.searchKeyword = "";
        this.inputValue = [];
      }
    } else {
      this.inputValue = this.returnObject ? item : getID(item);
      this.maybeValue = item;
      this.cachedValue = item;
      (this.$refs.input as any)?.blur?.();
      if (item && this.combo) {
        const valueField = this.normalizedFields.map(it => this.getter(item, it)).find(it => !!it);
        if (valueField) {
          this.searchKeyword = valueField;
        }
      } else {
        this.searchKeyword = "";
      }
    }
    this.$emit("choose", item);
    if (!this.multiple) {
      this.activeMenu = false;
    }
  }

  @Prop()
  textValue: string;

  @VModel()
  inputValue: string | string[];

  beforeMount() {
    if (this.textValue) {
      this.searchKeyword = this.textValue;
    }
  }

  @Watch("textValue")
  onTextValue(v, ov) {
    if (v !== ov && v !== this.searchKeyword) {
      this.searchKeyword = v;
    }
  }

  @Watch("searchKeyword")
  onKeyword(v, ov) {
    if (v !== ov) {
      this.$emit("update:textValue", v);
    }
  }

  onCachedLoaded(item, loading) {
    if (!loading) {
      const ids = Array.isArray(this.inputValue)
        ? this.inputValue.map(getID)
        : this.inputValue
          ? [getID(this.inputValue)]
          : [];
      const missingIds = new Set(ids);
      if (Array.isArray(item)) {
        item.forEach(it => {
          missingIds.delete(getID(it));
        });
      } else {
        missingIds.delete(getID(item));
      }
      this.missingIds = missingIds;
    }

    this.cachedValue = item;
    this.$emit("update:cachedValue", item);
    if (!this.multiple) {
      if (this.combo && this.hasCachedValue && !this.searchKeyword) {
        this.searchKeyword = this.cachedName;
      }
    }
  }

  get cachedItems() {
    const source =
      this.items || (Array.isArray(this.cachedValue) ? this.cachedValue : this.cachedValue ? [this.cachedValue] : []);
    const normalized = this.multiple
      ? Array.isArray(this.inputValue)
        ? this.inputValue
        : this.inputValue
          ? [this.inputValue]
          : []
      : this.inputValue
        ? [this.inputValue]
        : [];

    return normalized.map(item => {
      return source.find(it => checkID(item, it)) || item;
    });
  }

  set cachedItems(items: any[]) {
    const ids = items.map(it => getID(it));
    if (this.multiple) {
      this.inputValue = ids;
    } else {
      this.inputValue = ids[0] ?? null;
    }
  }

  get cachedItemsEditor() {
    const self = this;
    return {
      get items() {
        return self.cachedItems;
      },
      set items(v) {
        self.cachedItems = v;
      },
    };
  }

  get cachedName() {
    const iv = this.inputValue;
    const cv = this.cachedItems;
    if (cv.length) {
      return cv
        .map(cv => {
          return this.normalizedFields.map(it => this.getter(cv, it)).find(it => !!it) || cv._id;
        })
        .join(", ");
    }
    return Array.isArray(iv) ? iv.join(", ") : iv;
  }

  get cachedNames(): [string, string, any][] {
    const iv = this.inputValue;
    const cv = this.cachedItems;
    if (cv.length) {
      return cv
        .map(cv => {
          return [
            cv?._id ?? cv,
            this.stringOnly
              ? cv
              : (this.normalizedFields?.map(it => this.getter(cv, it))?.find(it => !!it) || cv._id) ??
                (this.missingIds.has(getID(cv)) ? cv : null),
            cv,
          ] as [string, string, any];
        })
        .filter(it => it[1] !== null);
    }
    return Array.isArray(iv) ? iv.map(it => [it, it, it]) : iv ? [[iv, iv, iv]] : [];
  }

  set cachedNames(v: [string, string, any][]) {
    if (this.multiple) {
      this.inputValue = Array.from(new Set(v.map(it => (this.returnObject ? it[2] : it[0]))));
    } else {
      this.inputValue = this.returnObject ? v[0]?.[2] : v[0]?.[0];
    }
  }

  currentPage = "";
  searchKeyword = "";
  maybeValue: any = null;
  cachedValue: any = null;
  missingIds: Set<string> = new Set();

  get hasCachedValue() {
    return this.multiple ? Array.isArray(this.cachedValue) && !!this.cachedValue.length : !!this.cachedValue;
  }

  get displayKeyword() {
    return this.activeMenu || this.stringOnly ? this.searchKeyword : "";
  }

  activeMenu = false;
  initMenu = false;
  dragging = false;
  mousedown = false;

  closeTimer: any = null;

  async createItem() {
    const name = normalizeConfig(this.createNameField, this.searchKeyword);
    this.activeMenu = false;
    // @ts-ignore
    const item = await this.$schemas.createAndEdit(this, this.path, {
      [name.field as any]: reverseConfig(this, name, this.searchKeyword),
    });
    if (!item) return;
    this.chooseItem(item);
  }

  async editItem(item: any) {
    this.activeMenu = false;
    // @ts-ignore
    const result = await this.$schemas.createAndEdit(this, this.path, item);
    if (!result) return;
    if (result !== item) {
      for (let [k, v] of Object.entries(result)) {
        Vue.set(item, k, v);
      }
    }
  }

  onBackspace() {
    if (this.searchKeyword) return;
    if (this.multiple) {
      if (Array.isArray(this.inputValue)) {
        this.inputValue = this.inputValue.slice(0, -1);
      }
    } else {
      this.inputValue = null;
    }
  }

  clickOuter() {
    if (this.readonly) return;
    this.activeMenu = !this.activeMenu;
    if (this.activeMenu) {
      this.initMenu = true;
    }
  }

  onStart() {
    this.dragging = true;
  }

  onEnd(evt) {
    this.mousedown = false;
    if (this.dragging) {
      this.dragging = false;
      if (evt.from === evt.to) {
        this.clickOuter();
      }
    }
  }

  onMouseDown() {
    this.mousedown = true;
  }

  onMouseUp() {
    this.mousedown = false;
  }
}
