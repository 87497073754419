
import { Component, Prop, Vue, InjectReactive } from "@feathers-client";
import {
  BatchEditorCollection,
  BatchEditorContext,
  BatchEditorItem,
  NormalizedBatchEditorConfig,
} from "./editorContext";
import { EditorField } from "@schemaEditor/plugin";
import BatchEditorSub from "./BatchEditorSub.vue";
import EditorCheckbox from "@schemaEditor/EditorCheckbox.vue";

@Component({
  components: {
    BatchEditorSub,
    EditorCheckbox,
  },
})
export default class BatchEditorRow extends Vue {
  @Prop()
  item: BatchEditorItem;

  @Prop()
  configFields: EditorField[];

  @Prop()
  subFields: NormalizedBatchEditorConfig[];

  @Prop()
  slots: any[];

  get collection() {
    return this.item?.parent;
  }

  @Prop(Boolean)
  nested: boolean;

  @Prop(Boolean)
  expanded: boolean;

  @Prop()
  sourceItem: any;

  @Prop(Boolean)
  checkBox: boolean;

  selfExpanded = false;

  mounted() {
    // console.log(this.configFields)
  }

  emitUpdated() {
    if (this.item) {
      this.item.markDirty();
    }
    this.$emit("updated");
  }

  async duplicate() {
    this.collection.cloneItem(this.item);
  }

  async remove() {
    this.collection.removeItem(this.item);
  }

  async restore() {
    this.collection.restoreItem(this.item);
  }

  async editDetails(item: BatchEditorItem) {
    await this.$schemas.createAndEdit(this, this.collection.path, item.item);
  }
}

Vue.component("batch-editor-row", BatchEditorRow);
