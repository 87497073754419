<template>
  <v-card>
    <v-card-title class="headline" v-text="title"></v-card-title>
    <v-card-text>
      <div>{{ $t("cancelConfirm.pleaseType") }}</div>
      <div>
        <strong>{{ keyText }}</strong>
      </div>
      <v-text-field :label="$t('cancelConfirm.reply')" v-model="reply" />
      <item-picker v-if="canRefund" mandatory :items="refundItems" v-model="refund" :clearable="false" light />
    </v-card-text>
    <v-card-actions>
      <v-text-field class="px-2" :label="$t('cancelConfirm.refundComment')" v-model="refundComment" />
      <v-btn color="red darken-1" @click.native="modalResult(false)" v-t="'basic.back'"></v-btn>
      <v-btn
        :disabled="keyText !== reply"
        color="blue"
        @click.native="modalResult({ refund, refundComment })"
        v-t="'basic.ok'"
      ></v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Dialog from "@feathers-client/mixins/Dialog";
import nuxtend from "@feathers-client/nuxtend";

export default nuxtend({
  mixins: [Dialog],
  props: {
    title: {},
    keyText: { type: String },
    canRefund: { type: Boolean },
  },
  data() {
    return {
      reply: "",
      refund: "refund",
      refundComment: "",
    };
  },
  computed: {
    refundItems() {
      return [
        { _id: "refund", name: { $t: "orderManager.refund" } },
        { _id: "noRefund", name: { $t: "orderManager.noRefund" } },
      ];
    },
  },
});
</script>
