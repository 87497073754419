<template>
  <div>
    <a v-if="!$route.query.noprint" href="javascript:window.print()" class="print-button" v-t="'invoice.print'"></a>
    <nuxt />
  </div>
</template>

<script>
export default {
  head() {
    return {
      htmlAttrs: {
        class: ["invoice-layout"],
      },
    };
  },
};
</script>

<style>
/*
* Author: Vasterad
* URL: http://purethemes.net
*/

/* ------------------------------------------------------------------- */
/* Invoice Styles
---------------------------------------------------------------------- */
@import url("https://fonts.googleapis.com/css?family=Raleway:400,600");

html.invoice-layout {
  font-family: "Raleway", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: none;
  font-size: 100%;
}

html.invoice-layout strong {
  font-weight: 600;
  color: #333;
  display: inline-block;
}

html.invoice-layout body {
  color: #333;
  font-weight: 300;
  line-height: 28px;
}

.invoice {
  background: white;
  width: auto;
  max-width: 900px;
  padding: 60px;
  margin: 0px auto 60px auto;
  border-radius: 4px;
}

html.invoice-layout h1,
html.invoice-layout h2,
html.invoice-layout h3 {
  font-weight: 300;
  color: #333;
  clear: both;
  margin: 0;
}

html.invoice-layout h2 {
  font-size: 24px;
  line-height: 1;
  margin: 10px 0 15px 0;
}

html.invoice-layout p {
  margin: 0;
  padding-bottom: 40px;
  clear: both;
}

html.invoice-layout #logo img {
  max-height: 128px;
}

html.invoice-layout #details {
  text-align: right;
}

html.invoice-layout table {
  width: 100%;
}

table.productList {
  margin: 0 0 0px 0px;
  padding: 1px 0;
  border-spacing: 0;
}

html.invoice-layout .productList th,
html.invoice-layout .productList td {
  padding: 8px 0;
  text-align: left;
}

html.invoice-layout .productList th {
  font-weight: 700;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
  color: #333;
}

html.invoice-layout .productList th:last-child,
html.invoice-layout .productList td:last-child {
  text-align: right;
}

html.invoice-layout .productList th span {
  position: relative;
  display: inline-block;
  height: 100%;
}

.print-button,
.print-button:hover {
  line-height: 24px;
  font-size: 15px;
  font-weight: 600;
  color: #333;
  background-color: #e6e6e6;
  border-radius: 50px;
  padding: 10px 20px;
  display: block;
  text-align: center;
  margin: 40px auto 40px auto;
  max-width: 190px;
  transition: 0.3s;
}

.print-button:hover {
  background-color: #e2e2e2;
}

/* Print Styles*/
@media print {
  .print-button {
    display: none !important;
  }

  body {
    background: #fff;
    color: #333;
  }

  strong,
  th,
  h1,
  h2,
  h3 {
    color: #111;
  }

  table,
  th {
    border-color: #bbb;
  }

  table {
    page-break-inside: auto;
  }
  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  }

  @page {
    size: A4;
  }

  .invoice {
    max-width: 100%;
    padding: 0 12px;
    margin: 0;
    position: relative;
    page-break-before: always;
  }

  .content-block,
  p {
    page-break-inside: avoid;
  }

  /* html,
    body {
        width: 210mm;
        height: 297mm;
    } */
}
</style>
