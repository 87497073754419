
import { Component, Vue, Prop } from "@feathers-client";
import type { EditorPageMixin } from "./mixins/EditorPageMixin"

@Component({
})
export default class EditorTopMenu extends Vue {
  @Prop(Boolean)
  readonly: boolean;

  @Prop(Boolean)
  canReload: boolean

  @Prop(Boolean)
  canRemove: boolean

  @Prop(Boolean)
  canCancel: boolean

  @Prop(Boolean)
  canSave: boolean

  @Prop()
  pageMixin: EditorPageMixin<any>

  get canReloadInner() {
    return this.canReload || !!this.pageMixin?.itemId;
  }

  get canRemoveInner() {
    return this.canRemove || this.pageMixin?.canRemove;
  }

  get canCancelInner() {
    return this.canCancel || !!this.pageMixin;
  }

  get canSaveInner() {
    return this.canSave || !!this.pageMixin;
  }

  doRemove() {
    if(this.pageMixin) {
      this.pageMixin.remove();
    } else {
      this.$emit('remove');
    }
  }

  doReload() {
    if(this.pageMixin) {
      this.pageMixin.confirmReload();
    } else {
      this.$emit('reload');
    }
  }

  doCancel() {
    if(this.pageMixin) {
      this.pageMixin.goBack();
    } else {
      this.$emit('cancel');
    }
  }

  doSave() {
    if(this.pageMixin) {
      this.pageMixin.save();
    } else {
      this.$emit('save');
    }
  }
}
