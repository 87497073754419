<template>
    <div>
        <div v-for="point in points" :key="point._id" >
            <div class="text-h5">{{ $td(point.name) }}</div>
            <data-table
                :data="{
                    path: 'shop/user/points',
                    filter: {
                        $shop: true,
                        point: point._id,
                        user: user
                    },
                    sortBy: ['expiresAt'],
                    sortDesc: [true],
                    name: $t('shopUserPoints.$')
                }"
                :headers="[
                    { text: $t('shopUserPoint.expiresAt'), sortable: true, value: 'expiresAt', format: 'moment' },
                    { text: $t('shopUserPoint.totalIn'), sortable: true, value: 'totalIn' },
                    { text: $t('shopUserPoint.totalOut'), sortable: true, value: 'totalOut' },
                    { text: $t('shopUserPoint.amount'), sortable: true, value: 'amount' },
                    { text: $t('shopUserPoint.used'), sortable: true, value: 'used' },
                ]"
                actions
                no-remove
                no-clone
                no-edit
                no-add
                :default="{
                    point,
                    value: 0,
                    user,
                    message: 'by admin'
                }"
            >
                <template #post-actions="{reload}">
                    <b-btn :alt-text="$t('basic.add')" @click="addPoint(point, reload)" v-if="$checkAclWrite(point)">
                        <v-icon>add</v-icon>
                    </b-btn>
                </template>
                <template slot="editor" slot-scope="edit">
                    <v-text-field :label="$t('point.value')" v-model.number="edit.item.amount"/>
                    <v-text-field :label="$t('point.message')" v-model="edit.item.message"/>
                </template>
            </data-table>
            <data-table
                :data="{
                    path: 'shop/user/point/logs',
                    filter: {
                        $shop: true,
                        point: point._id,
                        user: user
                    },
                    sortBy: ['date'],
                    sortDesc: [true],
                    name: $t('shopUserPointLogs.$'),
                    populate: ['order']
                }"
                :headers="[
                    { text: $t('shopUserPointLog.date'), sortable: true, value: 'date', format: 'moment' },
                    { text: $t('shopUserPointLog.from'), sortable: true, value: 'from' },
                    { text: $t('shopUserPointLog.to'), sortable: true, value: 'to' },
                    { text: $t('shopUserPointLog.delta'), sortable: true, value: 'delta' },
                    { text: $t('shopUserPointLog.message'), sortable: true, value: 'message' },
                ]"
                itemCanClick
            >
            <template v-slot:expand="{ item: log }">
                <v-card flat v-if="log.order">
                    <v-card-text>
                        <order-manager :order="log.order" :addCancel="false" />
                    </v-card-text>
                </v-card>
            </template>
            </data-table>
        </div>
    </div>
</template>

<script>
import nuxtend from '@feathers-client/nuxtend'
import Auth from '~/mixins/auth'
import _ from 'lodash'
import OrderManager from '~/components/OrderManager'

export default nuxtend({
    components: {
        OrderManager
    },
    mixins: [
        Auth
    ],
    props: {
        user: {
            required: true
        },
        points: {
            required: true
        }
    },
    methods: {
        async addPoint(point, reload) {
            const c = await this.$openDialog(import('~/components/boxs/AddPointDialog.vue'), {
                point,
                user: this.user,
            }, {
                maxWidth: '50vw',
            });
            reload();
        }
    }
})
</script>
