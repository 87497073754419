import _ from "lodash";
import moment from "moment";
import Vue from "vue";
import { convertWeight } from "@common/weightUtils";
import { PrintSequence } from "pos-printer/printSequence";
import { PrintQueue } from "pos-printer/printQueue";
import { Pop } from "@feathersjs/feathers";
import type db from "@db";
import type { ShopType, WeightType } from "@common/common";
import { FindType, getID } from "@feathers-client";
import { compile, compileLabel, PrinterTemplateNodeBase } from "~/dep/pos-printer-template/index";
import LRUCache from "lru-cache";
import { createContext } from "~/dep/pos-printer-template/util";
import { ProductLabelSequenceOption } from "./label";
import { ProductGroupInfo, ProductInfo } from "@common/product";

const defaultTemplates = require.context("!!raw-loader!@common/templates", true, /\.vue$/);
const defaultTemplateMap = Object.fromEntries(
  defaultTemplates
    .keys()
    .map(key => {
      const okey = key;
      if (key.endsWith(".vue")) {
        key = key.substring(0, key.length - 4);
      }
      const parts = key.substring(2).split("/");
      if (parts[parts.length - 1] === "index") {
        parts.pop();
      }
      const kebab = parts.map(it => _.kebabCase(it)).join("-");
      const camel = parts.map((it, i) => (i ? _.upperFirst(_.camelCase(it)) : _.camelCase(it))).join("");
      return [[kebab, defaultTemplates(okey).default] as const, [camel, defaultTemplates(okey).default] as const];
    })
    .flat(),
);

export async function compileTemplate(template: string, printerType: "label" | "thermal" = "thermal") {
  let compiled: PrinterTemplateNodeBase<any> = cache.get(template);
  if (!compiled) {
    cache.set(template, (compiled = printerType === "label" ? compileLabel(template) : compile(template)));
  }
  return compiled;
}

let defaultTemplateLoader: (key: string) => Promise<string> = null;

export function setTemplateLoader(loadTemplate: (key: string) => Promise<string>) {
  defaultTemplateLoader = loadTemplate;
}

export async function resolveTemplate(
  templateName: string,
  printerType: "label" | "thermal" = "thermal",
  loadTemplate?: (key: string) => Promise<string>,
) {
  if (!loadTemplate) {
    loadTemplate = defaultTemplateLoader;
  }
  let template: string = await loadTemplate?.(templateName);
  if (!template) {
    template = defaultTemplateMap[templateName];
  }
  if (template) {
    return await compileTemplate(template, printerType);
  }
  return null;
}

type LineItem = typeof db.Order._mongoType["products"][number];
type PopLineItem = Pop<["product"], LineItem>;

type OrderType = Omit<Pop<["sourceOrder", "shop/payments"], typeof db.Order._mongoType>, "products"> & {
  products: PopLineItem[];
};

const cache = new LRUCache<string, PrinterTemplateNodeBase<any>>();

export async function printTemplate(
  sequence: PrintSequence,
  template: string,
  resolveDefault: () => Promise<any>,
  data: any,
) {
  if (!template) {
    // load default template
    // @ts-ignore
    template = ((await resolveDefault()).default as any).toString();
  }

  if ((sequence.context as any).$config?.devMode) {
    console.log("Print template", template, data);
  }
  let compiled: PrinterTemplateNodeBase<any> = cache.get(template);
  if (!compiled) {
    if (sequence.printer?.conf?.type === "label") {
      cache.set(template, (compiled = compileLabel(template)));
    } else {
      cache.set(template, (compiled = compile(template)));
    }
  }
  const ctx = createContext(sequence.context, data);
  ctx.resolveTemplate = resolveTemplate;
  await compiled.execute(sequence, ctx);
}

export interface InvoiceOptions {
  cashbox?: boolean;
  reprint?: boolean;
}

export function getInvoiceSequencer(order: FindType<"shop/orders">, options?: InvoiceOptions) {
  return async (sequence: PrintSequence, template: string) => {
    const context: Vue = sequence.context as any;

    const staff = order.staff && (await context.$feathers.service("shop/staffs").get(order.staff));
    const cashier = order.cashier && (await context.$feathers.service("shop/pos/cashiers").get(order.cashier));
    const user = order.user && (await context.$feathers.service("shop/users").get(order.user));
    const payments = await context.$feathers.service("shop/payments").find({
      query: {
        _id: { $in: order.payments },
        status: "paid",
        $populate: ["method"],
        $paginate: false,
      } as const,
      paginate: false,
    });

    let skuCodeDict: Record<string, string> = {};

    const posCode = context.$pos.posCode;
    if (posCode) {
      const skus = await context.$feathers.service("shop/product/skus").find({
        query: {
          _id: { $in: order.products.map(it => it.sku) },
          $paginate: false,
        },
        paginate: false,
      });
      skuCodeDict = Object.fromEntries(
        skus
          .map(sku => [sku._id, (sku.specs || []).find(it => it.spec === posCode)?.value] as const)
          .filter(it => !!it[1]),
      );
    }

    await printTemplate(sequence, template, () => import("!!raw-loader!~/common/templates/receipt.vue"), {
      order,
      options: options || {},
      shop: context.$shop,
      qrPrefix: context.$qrPrefix,
      b64id: Buffer.from(`${order._id}`, "hex").toString("base64"),
      staff,
      cashier,
      user,
      usePoints: order.usePoints?.map?.(point => ({
        name: context.$pos.getPointName(point.point),
        value: point.value,
      })),
      rewardPoints: order.rewardPoints?.map?.(point => ({
        name: context.$pos.getPointName(point.point),
        value: point.value,
      })),
      payments,
      skuCodeDict,
    });
    return sequence.getJob("Order " + (order.orderId || order._id));
  };
}

export function taiwanTaxSequencer(invoice: FindType<"shop/tw/invoices">) {
  return async (sequence: PrintSequence, template: string) => {
    await printTemplate(sequence, template, () => import("!!raw-loader!~/common/templates/taiwanTax.vue"), {
      invoice,
    });
    return sequence.getJob("Invoice " + invoice.fullInvoiceNo);
  };
}

export function thermalProduct(opts: ProductLabelSequenceOption, type: "thermal" | "label" = "thermal") {
  return async (sequence: PrintSequence, template: string) => {
    const context: Vue = sequence.context as any;

    const sku = opts.sku ? new ProductInfo(context as any, opts.sku) : null;
    const product = opts.product ? new ProductGroupInfo(context as any, opts.product) : null;

    let name = context.$td(sku?.item?.fullName ?? product?.item?.name);
    const productId = opts.sku?.productGroup ?? opts.product?._id;

    const params = [Buffer.from(String(productId), "hex")];

    const posCode = context.$pos.posCode;

    let specCode: string;
    if (posCode) {
      const spec = sku?.specs?.find?.(s => s.spec === posCode) || product?.specs?.find?.(s => s.spec === posCode);
      if (spec?.value) specCode = String(spec.value);
    }

    const productDisplayId =
      specCode ?? opts.sku?.slug ?? opts.product?.slug ?? Buffer.concat(params).toString("base64");

    let originalPrice = sku?.originalPrice ?? (await product?.getPriceInfo?.())?.originalPrice;
    let price = sku?.generalPrice ?? (await product?.getPriceInfo?.())?.generalPrice;

    if (sku) {
      const b = Buffer.alloc(13);
      b[0] = "s".charCodeAt(0);
      Buffer.from(sku._id, "hex").copy(b, 1);
      params.push(b);
    }

    if (opts.quantity) {
      const b = Buffer.alloc(5);
      b[0] = "q".charCodeAt(0);
      b.writeFloatLE(opts.quantity || 1, 1);
      params.push(b);
    }
    const productCode = Buffer.concat(params).toString("base64");

    await printTemplate(
      sequence,
      template,
      () =>
        type === "thermal"
          ? import("!!raw-loader!~/common/templates/product.vue")
          : import("!!raw-loader!~/common/templates/productLabel.vue"),
      {
        ...opts,
        url: `${context.$qrPrefix}/p/${productCode}`,
        num: opts.num || 1,
        name,
        originalPrice,
        price,
        productDisplayId,
        productSlug: opts.product?.slug,
        shop: context.$shop,
      },
    );
    return sequence.getJob("Product " + productCode);
  };
}

export type ProductLabelSequenceData = ProductLabelSequenceOption & {
  url: string;
  name: string;
  originalPrice: number;
  price: number;
  productDisplayId: string;
  shop: ShopType;
};
