import { Component, Vue, Prop, FindType, Watch, checkID } from "@feathers-client";

export type ShipmentType = FindType<"shop/shipping/shipments">;
export type ScheduleType = FindType<"shop/shipping/schedules">;

export function getStatusColor(status: ShipmentType["status"]) {
  switch (status) {
    case "preparing":
    case "delivery":
    case "readyToSelfPickup":
      return "bg-blue100";
    case "received":
    case "shipped":
      return "bg-green100";
    case "done":
      return "bg-green100";
    case "notDelivered":
      return "bg-red200";
    default:
      return "bg-grey500";
  }
}

export function getScheduleStatusColor(status: ScheduleType["status"]) {
  switch (status) {
    case "preparing":
    case "delivery":
      return "bg-blue100";
    case "done":
      return "bg-green100";
    default:
      return "bg-grey500";
  }
}
