
import { Component, Auth, Prop, Vue, FindType, getID } from "@feathers-client";
import DatePicker from "~/components/DatePicker.vue";
import EditorObjectPickerList from "@schemaEditor/EditorObjectPickerList.vue";
import DataTableForErp from "~/components/erp/DataTableForErp.vue";
import moment from "moment";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import { Watch } from "nuxt-property-decorator";
import MenuListNum from "@feathers-client/components/MenuListNum.vue";
import MenuListButton from "@feathers-client/components/MenuListButton.vue";
import ItemPicker from '@feathers-client/components/ItemPicker.vue'
import { map, omit } from "lodash";

@Component({
  components: {
    DatePicker,
    EditorObjectPickerList,
    DataTableForErp,
    EditorTextField,
    MenuListNum,
    MenuListButton,
    ItemPicker
  },
})
export default class MonthlyStmtForm extends Vue {

  @Prop()
  oldStmtID

  editMode = false;
  loading = false;
  deleting = false;
  handler = '';
  remarks = '';
  customerCreditAmt = 0;
  customer: FindType<'shop/erp/companyUsers'> = null;
  billAddr: Partial<FindType<'shop/erp/companyUser/addresses'>> = null;
  oldBillAddr = null;

  dateRangeSelections: 'today' | 'this week' | 'this month' | 'past 30 days' | 'past 3 months' | '' = 'today'

  tableHeader = [
    this.$t('subsequentPaymentOrders.erp.invoiceID'),
    this.$t('subsequentPaymentOrders.dueDate'),
    this.$t('subsequentPaymentOrders.erp.amount'),
  ]

  invoicesCart: Array<FindType<'shop/erp/wholesaleOrder/invoices'>
    & { isSelected: boolean }
    & { status: 'toDelete' | 'noChange' | 'toAdd' | 'notToAdd' }
  > = [];


  async debug() {
    // const orders = await this.$feathers.service("shop/erp/wholesaleOrders").find({
    //   query: {
    //     shop: this.$shop._id,
    //     customer: this.customer,
    //     $select: ['_id'],
    //     $paginate: false,
    //   },
    //   paginate: false
    // });
    // console.log("api test", orders);
    const hehe = await this.$openDialog(import('./dialogs/AlertDialog.vue'), {
      title: this.$t("subsequentPaymentOrders.erp.monthlyStmtUpdated"),
      content: `${this.$t('subsequentPaymentOrders.erp.monthlyStmtID')} #${'ooxx'}`,
      extraContent: this.$t("subsequentPaymentOrders.erp.updateDocToCustomer", { companyName: this.$td(this.customer?.name) ?? '' })
    }, {
      maxWidth: "400px"
    });
  }

  async resetAll() {
    if (this.editMode) return;
    const c = await this.$openDialog(
      import("./dialogs/ErpConfirmDialog.vue"),
      {
        title: this.$t('subsequentPaymentOrders.erp.confirmFinish'),
        content: this.$t('subsequentPaymentOrders.erp.dataWillNotBeSaved'),
      },
      {
        maxWidth: "400px"
      }
    );
    if (!c) {
      this.loading = false;
      return;
    }
    this.invoicesCart = [];
    // this.hasSearched = false;
    this.customer = null;
    this.handler = null;
    this.remarks = '';
    this.contactPerson = '';
    this.contactPersonTel = '';
  }


  async pickInvoice() {
    if (this.loading) return;
    this.loading = true;
    if (!this.customer) {
      this.loading = false;
      return;
    }
    const dialogData: Array<FindType<'shop/erp/wholesaleOrder/invoices'> & { isSelected: boolean }>
      = await this.$openDialog(import("~/components/erp/dialogs/InvoicePickerDialog.vue"),
        {
          customer: this.customer,
          existInv: (this.invoicesCart?.length ? this.invoicesCart.map(el => el._id) : [])
        },
        {
          maxWidth: "1156px"
        });
    if (!dialogData) {
      this.loading = false;
      return;
    }

    for (let returnInv of dialogData) {
      this.invoicesCart.push({
        ...returnInv,
        isSelected: false,
        status: 'toAdd'
      })
    }


    this.loading = false;
  }


  enterDeleting() {
    this.deleting = true;
    for (let inv of this.invoicesCart) {
      inv.isSelected = false;
      if (inv.status === 'toDelete' || inv.status === 'notToAdd') {
        inv.isSelected = true;
      }
    }
  }

  confirmDeleting() {
    for (let inv of this.invoicesCart) {
      if (inv.isSelected === true && inv.status === 'toAdd') {
        inv.status = 'notToAdd';
      } else if (inv.isSelected === true && inv.status === 'noChange') {
        inv.status = 'toDelete';
      } else if (inv.isSelected === false && inv.status === 'notToAdd') {
        inv.status = 'toAdd'
      } else if (inv.isSelected === false && inv.status === 'toDelete') {
        inv.status = 'noChange'
      }
    }

    this.deleting = false;
  }

  totalUsedCredit = 0

  async loadCustomerCredit() {
    const remainingCredit = await this.$feathers.service("shop/erp/companyUserCredit/remainingCredit").find({
      query: {
        shop: this.$shop,
        companyUser: this.customer._id,
      },
    });
    this.customerCreditAmt = remainingCredit['remainingCredit'];
    this.totalUsedCredit = remainingCredit['allUsedCredit'];
  }



  @Watch("customer")
  onCustomer(n, o) {
    if (n === null) {
      this.customerCreditAmt = 0;
      this.billAddr = null;
      return;
    }
    this.billAddr = null;
    this.loadCustomerCredit();
  }

  async newMonthlyStmt() {
    if (this.loading) return;
    this.loading = true;

    const invoicesToMonthlyStmt = (this.invoicesCart.filter(el => el.status === 'toAdd' || el.status === 'noChange'));

    if (invoicesToMonthlyStmt.length <= 0) {
      this.$store.commit('SET_ERROR', this.$t('subsequentPaymentOrders.erp.pleaseSelectInvoice'));
      this.loading = false;
      return;
    }
    if (!this.handler || !this.billAddr || !this.customer || this.contactPerson === '' || this.contactPersonTel === '') {
      this.$store.commit('SET_ERROR', this.$t('subsequentPaymentOrders.erp.pleaseInputAllFields'));
      this.loading = false;
      return;
    }

    if (!this.editMode) {
      const c = await this.$openDialog(
        import("./dialogs/ErpConfirmDialog.vue"),
        {
          title: this.$t('subsequentPaymentOrders.erp.confirmCreateMonthlyStmt'),
          content: `${this.$t('subsequentPaymentOrders.erp.added')} ${invoicesToMonthlyStmt.length} ${this.$t('subsequentPaymentOrders.erp.xInovices')}`,
          moreContent: true,
          loopContent: invoicesToMonthlyStmt.map(el => `${this.$t('invoice.invoice')}# ${el.invoiceID ?? el._id}`),
        },
        {
          maxWidth: "400px"
        }
      );
      if (!c) {
        this.loading = false;
        return;
      }
    }

    if (this.editMode) {
      const invoicesToDel = (this.invoicesCart.filter(el => el.status === 'toDelete'));
      const invoicesToAdd = (this.invoicesCart.filter(el => el.status === 'toAdd'));
      const c = await this.$openDialog(
        import("./dialogs/ErpConfirmDialog.vue"),
        {
          title: this.$t('subsequentPaymentOrders.erp.confirmChange'),
          // content: `${this.$t('subsequentPaymentOrders.erp.added')} ${invoicesToMonthlyStmt.length} ${this.$t('subsequentPaymentOrders.erp.xInovices')}`,
          moreContent: true,
          extraContent: this.$t('subsequentPaymentOrders.erp.deletedInvoices'),
          loopContent: invoicesToDel.map(el => `${this.$t('invoice.invoice')}# ${el.invoiceID ?? el._id}`),
          loopContentTitle2: this.$t('subsequentPaymentOrders.erp.addedInvoices'),
          loopContent2: invoicesToAdd.map(el => `${this.$t('invoice.invoice')}# ${el.invoiceID ?? el._id}`),
        },
        {
          maxWidth: "400px"
        }
      );
      if (!c) {
        this.loading = false;
        return;
      }
    }


    const newStmt = await this.$feathers.service('shop/erp/wholesaleOrders/monthlyStmtHandler').create({
      shop: this.$shop._id,
      handler: this.handler,
      invoices: map(invoicesToMonthlyStmt, it => omit(it, ['isSelected', 'status'])),
      billAddr: omit(this.billAddr, ['companyUser', 'shop', 'default', 'date']),
      remarks: this.remarks,
      customer: this.customer._id,
      oldStmtID: this.editMode ? this.oldStmtID : null,
      contactPerson: this.contactPerson,
      contactPersonTel: this.contactPersonTel,
    });

    console.log('newStmt', newStmt);


    if (newStmt) {
      if (!this.editMode) {
        this.loading = false;
        this.$router.replace(`/erp/wholesaleOrders/quoSuccess?id=${newStmt._id}&orderType=monthlyStmt&orderId=${newStmt.monthlyStmtID
          }`);
      } else {
        // const deletedStatement = await this.$feathers.service('shop/erp/wholesaleOrders/monthlyStmtHandler').find({
        //   query: {
        //     oldStmtID: this.oldStmtID,
        //     cancelDetails: {
        //       handler: newStmt.handler,
        //       remarks: `replaced by new statement #${newStmt._id}`,
        //       date: moment().toDate(),
        //     }
        //   }
        // });
        // console.log('deletedStatement',deletedStatement);
        await this.$openDialog(import('./dialogs/AlertDialog.vue'), {
          title: this.$t("subsequentPaymentOrders.erp.monthlyStmtUpdated"),
          content: `${this.$t('subsequentPaymentOrders.erp.monthlyStmtID')} #${newStmt.monthlyStmtID ?? newStmt._id}`,
          extraContent: this.$t("subsequentPaymentOrders.erp.updateDocToCustomer", { companyName: this.$td(this.customer?.name) ?? '' })
        }, {
          maxWidth: "400px"
        });

        this.loading = false;
        this.$router.replace(`/erp/wholesaleOrders/monthlyStmt/${newStmt._id}`);
      }
    } else {
      this.loading = false;
      console.log("wso error");
    }
  }

  async updated() {
    if (this.oldBillAddr) {
      this.billAddr = this.oldBillAddr;
      this.oldBillAddr = null;
    }
  }

  async mounted() {
    this.loading = true;
    if (this.oldStmtID !== 'null' && this.oldStmtID) {
      this.editMode = true;
      const monthlyStmt = (await this.$feathers.service('shop/erp/wholesaleOrder/monthlyStatements').find({
        query: {
          _id: this.oldStmtID,
          $paginate: false,
          $populate: [
            'invoices',
            'payment'
          ],
        },
        paginate: false
      }))[0];

      // debugger

      if (monthlyStmt.payment && monthlyStmt.payment.find(el => !el.isCancelled)) {
        this.$store.commit('SET_ERROR', 'uneditable statement');
        this.$router.replace(`/erp/wholesaleOrders/`);
      }

      const customer = (await this.$feathers.service('shop/erp/wholesaleOrder/invoices').find({
        query: {
          _id: monthlyStmt.invoices[0]._id,
          $paginate: false,
          $populate: [
            {
              path: 'refOrder',
              model: 'ShopErpWholesaleOrder',
              populate: ['customer']
            },
          ],
        }
      }))[0].refOrder.customer;

      this.customer = customer;
      this.oldBillAddr = monthlyStmt.billingAddress;
      this.remarks = monthlyStmt.remarks;

      for (let inv of monthlyStmt.invoices) {
        this.invoicesCart.push({
          ...inv,
          isSelected: false,
          status: 'noChange'
        })
      }
    }

    this.loading = false;
  }

  get stmtAmount() {
    let sum = 0;
    for (let inv of this.invoicesCart) {
      if (inv.status === 'noChange' || inv.status === 'toAdd')
        sum += inv.amountInt;
    }
    return sum;
  }

  @Watch('billAddr')
  onBillAddr(n: typeof this.billAddr, o) {
    if (n) {
      this.contactPerson = `${n.name?.firstName} ${n.name?.lastName}` ?? '';
      this.contactPersonTel = n.phone;
    }
  }

  contactPerson: string = ''

  contactPersonTel: string = ''

  // get contactPerson() {
  //   if (!this.billAddr) return '-';
  //   return `${this.billAddr.name?.firstName ?? ''} ${this.billAddr.name?.lastName ?? ''}`
  // }

  // get contactPersonTel() {
  //   if (!this.billAddr) return '-';
  //   return this.billAddr.phone ?? ''
  // }

  // get totalUsedCredit() {
  //   return this.customer ? this.customer.creditLimitInt - this.customerCreditAmt : 0;
  // }

  get customerAddrQuery() {
    return {
      companyUser: this.customer
    }
  };

  get dateRanges() {
    return [
      {
        _id: 'today',
        name: 'today'
      },
      {
        _id: 'this week',
        name: 'this weak'
      },
      {
        _id: 'this month',
        name: 'this mouth'
      },
      {
        _id: 'past 30 days',
        name: 'past 30 days'
      },
      {
        _id: 'past 3 months',
        name: 'past 3 mouths'
      },
    ]
  }


}
