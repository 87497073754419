
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";

export interface NumberCalcOpts {
    id: string
    value: number
    original?: number
    integer?: boolean
    dp?: number
    min?: number
    max?: number
    prefix?: string
    postfix?: string
}

@Component
export default class NumberCalc extends Vue {

    numPadOptions : NumberCalcOpts = null;

    get prefix() { return this.numPadOptions?.prefix ?? '' }
    get postfix() { return this.numPadOptions?.postfix ?? '' }

    get min() { return this.numPadOptions?.min }
    get max() { return this.numPadOptions?.max }
    get dp() { return this.numPadOptions?.dp }

    get original() { return this.numPadOptions?.original ?? null }
    get value() { return this.numPadOptions?.value ?? null }

    get integer() { return this.numPadOptions?.integer ?? false }

    numberInputClose() {
        this.opened = false;
    }

    @Watch('$store.state.numPadOptions')
    onNumPadOptions(v) {
        this.numPadOptions = v;
        if(v) {
            this.svalue = this.mvalue === 0 ? '' : this.numberToString(this.mvalue);
            this.opened = true;
            this.selectedAll = true;
        } else {
            this.opened = false;
        }
    }

    @Watch('svalue')
    onSValue() {
        this.$root.$emit('numberInputPreview', {
            options: this.numPadOptions,
            value: this.svalue,
        });
    }

    svalue = ''
    opened = false
    selectedAll = false

    get mvalue() {
        return this.value === null ? this.original : this.value;
    }

    get displayValue() {
        let v = `${this.value === null ? this.original : this.value}`;
        if(v === '') return '';
        else {
            const s = (+v).toFixed(this.dp);
            const parts = s.split('.');
            parts[0] = parts[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            return parts.join('.');
        }
    }

    get rounded() {
        let v = `${this.value === null ? this.original : this.value}`;
        if(v === '') return false;
        else {
            const s = (+v).toFixed(this.dp);
            return +s !== +v;
        }
    }

    get rvalue() {
        if(this.svalue === '.') return 0;
        let v = parseFloat(this.svalue || '0');
        if(this.original !== undefined && (v === this.original || this.svalue === '')) {
            v = null;
        }
        return v;
    }

    numberToString(num : number) {
        if(isNaN(num)) return '';
        return num.toFixed(this.dp);
    }

    commit() {
        if(!this.numPadOptions) return;
        this.$root.$emit('numberInput', {
            options: this.numPadOptions,
            value: this.rvalue,
        });
        this.opened = false;
    }

    done() {
        this.commit();
    }

    bigNumber(c) {
        const newVal = this.selectedAll ? c : this.svalue + c;
        if(newVal.length > 14) return true;
        if(this.dp !== undefined && newVal.indexOf('.') !== -1 && (newVal.split('.')[1] || '').length > this.dp) return true;
        const intVal = newVal === '.' ? 0 : +newVal;
        if(isNaN(intVal)) return true;
        if(this.min !== undefined && intVal < this.min) return true;
        if(this.max !== undefined && intVal > this.max) return true;
        return false;
    }

    inputDigit(c) {
        this.svalue = this.selectedAll ? c : this.svalue + c;
        this.selectedAll = false;
    }

    backspace() {
        this.svalue = this.svalue.substr(0, Math.max(0, this.svalue.length - 1));
        this.selectedAll = false;
    }

    inc() {
        let v = parseFloat(this.svalue || '0');
        if(isNaN(v)) return;
        this.svalue = this.numberToString(v + 1)
    }

    dec() {
        let v = parseFloat(this.svalue || '0');
        if(isNaN(v)) return;
        this.svalue = this.numberToString(v - 1)
    }
}

