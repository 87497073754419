
import _ from "lodash";
import { Types } from "mongoose";
import { Component, Vue, mixins, Prop, FindType, FindPopRawType, Watch, checkID, getID } from "@feathers-client";
import Dialog from "@feathers-client/mixins/Dialog";
import ShipmentScheduleOrderDialogItem from "./ShipmentScheduleOrderDialogItem.vue";
type ShipmentType = FindType<"shop/shipping/shipments">;

@Component({
  components: {
    ShipmentScheduleOrderDialogItem,
  },
})
export default class ShipmentScheduleOrderSplitDialog extends mixins(Dialog) {
  @Prop()
  item: ShipmentType;

  @Prop()
  value: any;

  $root: any;
  orderId: any;

  getOrders(products) {
    return products.length ? _.uniq(products.map(it => it.order)) : [];
  }

  getUniqueProducts(item: ShipmentType) {
    return _.uniq(item.products.map(it => ({ _id: it._id, sku: it.sku })));
  }

  async beforeMount() {
    await this.updateSelectedShipments();
    await this.updateSelectedShipmentsProducts();
    await this.updateSelectedShipmentsOrderIds();
    await this.updateSelectedShipmentsUsers();
  }

  selectedItems = { left: [], right: [] };
  editing = { left: false, right: false };
  selectAll = { left: false, right: false };
  itemSelectState = { left: {}, right: {} };

  @Watch("selectAll.left")
  selectAllLeftChanged() {
    if (this.selectAll.left) {
      this.itemSelectState.left = _.fromPairs(this.allShipment.products?.map(item => [item._id, true]));
    } else if (this.selectAll.left === false) {
      this.itemSelectState.left = _.fromPairs(this.allShipment.products?.map(item => [item._id, false]));
    }
  }

  @Watch("selectAll.right")
  selectAllRightChanged() {
    if (this.selectAll.right) {
      this.itemSelectState.right = _.fromPairs(
        this.newShipments[this.selectedNewShipment].products?.map(item => [item._id, true]),
      );
    } else if (this.selectAll.right === false) {
      this.itemSelectState.right = _.fromPairs(
        this.newShipments[this.selectedNewShipment].products?.map(item => [item._id, false]),
      );
    }
  }

  @Watch("itemSelectState.left", { deep: true })
  itemSelectStateLeftChanged() {
    this.selectedItems.left = Object.entries(this.itemSelectState.left)
      .filter(([k, v]) => v)
      .map(([k, v]) => k);
    if (Object.values(this.itemSelectState.left).every(v => v === true)) {
      this.selectAll.left = true;
    } else if (Object.values(this.itemSelectState.left).every(v => v === false)) {
      this.selectAll.left = false;
    } else {
      this.selectAll.left = null;
    }
  }

  @Watch("itemSelectState.right", { deep: true })
  itemSelectStateRightChanged() {
    this.selectedItems.right = Object.entries(this.itemSelectState.right)
      .filter(([k, v]) => v)
      .map(([k, v]) => k);
    if (Object.values(this.itemSelectState.right).every(v => v === true)) {
      this.selectAll.right = true;
    } else if (Object.values(this.itemSelectState.right).every(v => v === false)) {
      this.selectAll.right = false;
    } else {
      this.selectAll.right = null;
    }
  }

  itemSelectStateLeftUpdate() {
    this.itemSelectState.left = _.fromPairs(this.allShipment.products?.map(item => [item._id, false]));
    this.selectAll.left = false;
  }

  itemSelectStateRightUpdate() {
    this.itemSelectState.right = _.fromPairs(
      this.newShipments[this.selectedNewShipment].products?.map(item => [item._id, false]),
    );
    this.selectAll.right = false;
  }

  leftEditToggle() {
    this.editing.left = !this.editing.left;
  }

  rightEditToggle() {
    this.editing.right = !this.editing.right;
  }

  batchAssign() {
    this.selectedItems.left.forEach(item => {
      this.assignProduct(item);
    });
    this.editing.left = false;
  }

  batchUnassign() {
    this.selectedItems.right.forEach(item => {
      this.unassignProduct(item);
    });
    this.editing.right = false;
  }

  selectedShipments: ShipmentType[] = [];
  selectedShipmentsOrderIdMap: Map<any, any> = new Map();
  shipmentOrderMap: Map<any, any> = new Map();
  shipmentProductMap: Map<any, { _id: any; sku: any }[]> = new Map();
  productSkuMap: Map<any, FindType<"shop/product/skus">> = new Map();
  shipmentUserMap: Map<any, FindType<"shop/users">> = new Map();

  isConfirmed = false;

  allShipmentOrder: any = []; //for frontend display only
  allShipment: Partial<ShipmentType> = {
    products: [],
  };
  newShipmentOrders: any[] = [[]]; //for frontend display only
  newShipments: Partial<ShipmentType>[] = [];

  newShipmentLength: number = 1;
  selectedNewShipment: number = 0;

  selectNewShipment(index) {
    this.selectedNewShipment = index;
    this.editing.right = false;
    this.selectAll.right = false;
    this.itemSelectStateRightUpdate()
  }

  addNewShipment() {
    this.newShipmentLength += 1;
    this.newShipments.push({
      ...structuredClone(this.selectedShipments[0]),
      products: [],
      address: { name:{firstName:'',lastName:''}, ...structuredClone(this.selectedShipments[0]).address },
    });
    this.selectedNewShipment = this.newShipmentLength - 1;
    delete this.newShipments[this.newShipmentLength - 1]._id;
  }

  assignProduct(productID) {
    this.newShipments[this.selectedNewShipment].products.push(
      this.allShipment.products.find(it => it._id === productID),
    );
    this.allShipment.products = this.allShipment.products.filter(it => it._id !== productID);
  }

  unassignProduct(productID) {
    this.allShipment.products.push(
      this.newShipments[this.selectedNewShipment].products.find(it => it._id === productID),
    );
    this.newShipments[this.selectedNewShipment].products = this.newShipments[this.selectedNewShipment].products.filter(
      it => it._id !== productID,
    );
  }

  proceed() {
    if (this.allShipment.products.length > 0) {
      this.$store.commit("SET_ERROR", `${this.$t("pages.shop/shipping/schedules.splitingAutoAssignProductsMessage")}`);
      if (this.newShipments[this.newShipmentLength - 1].products.length > 0) {
        this.newShipmentLength += 1;
        this.newShipments.push({
          ...structuredClone(this.selectedShipments[0]),
          products: [],
          address: { name:{firstName:'',lastName:''}, ...structuredClone(this.selectedShipments[0]).address },
        });
        delete this.newShipments[this.newShipmentLength - 1]._id;
      }
      this.newShipments[this.newShipmentLength - 1].products = [...this.allShipment.products];
      this.allShipment.products = [];
    }
    if (this.newShipments.some(it => it.products.length === 0)) {
      this.$store.commit(
        "SET_ERROR",
        `${this.$t("pages.shop/shipping/schedules.splitingAutoRemoveEmptyOrderMessage")}`,
      );
      this.newShipments = this.newShipments.filter(it => it.products.length > 0);
      this.newShipmentLength = this.newShipments.length;
    }
    this.selectedNewShipment = 0;
    this.isConfirmed = true;
  }

  @Watch("allShipment", { deep: true })
  async updateAllShipmentOrder() {
    this.allShipmentOrder = this.getOrders(this.allShipment.products);
    this.itemSelectStateLeftUpdate();
  }

  @Watch("newShipments", { deep: true })
  async updateNewShipmentOrder() {
    for (let i = 0; i < this.newShipmentLength; i++) {
      this.newShipmentOrders[i] = this.getOrders(this.newShipments[i].products);
    }
    this.itemSelectStateRightUpdate();
  }

  getItemProps(product, shipment) {
    let it = this.productSkuMap.get(product.sku);
    return (
      it && {
        productName: this.$td(it.fullName),
        image: it.images,
        userName: (it => it && (it.fullName || ""))(this.shipmentUserMap.get(`${this.selectedShipments[0].user}`)),
        remarks: "no remarks",
        quantity: shipment.products.find(j => j._id === product._id).quantity,
        amount: shipment.products.find(j => j._id === product._id).totalInt,
      }
    );
  }

  async updateSelectedShipments() {
    this.selectedShipments = await this.$feathers.service("shop/shipping/shipments").find({
      query: {
        _id: { $in: this.value },
        $paginate: false,
      },
      paginate: false,
    });
    this.shipmentOrderMap = new Map(this.selectedShipments.map(it => [it._id, this.getOrders(it.products)]));
    this.shipmentProductMap = new Map(this.selectedShipments.map(it => [it._id, this.getUniqueProducts(it)]));
    await this.updateAllShipment();
    await this.updateNewShipments();
  }
  async updateAllShipment() {
    this.allShipment = { ...structuredClone(this.selectedShipments[0]) };
    this.allShipment.products = [...this.selectedShipments.map(it => it.products)].flat();
    this.allShipmentOrder = this.getOrders(this.allShipment.products);
  }

  async updateNewShipments() {
    this.newShipments = [...Array(this.newShipmentLength)].map(_ => ({
      ...structuredClone(this.selectedShipments[0]),
      products: [],
      address: { name:{firstName:'',lastName:''}, ...structuredClone(this.selectedShipments[0]).address },
    }));
    delete this.newShipments[this.newShipmentLength - 1]._id;
    this.newShipmentOrders = [...Array(this.newShipmentLength)].map(_ => []);
  }

  async updateSelectedShipmentsProducts() {
    let result = await this.$feathers.service("shop/product/skus").find({
      query: {
        _id: { $in: [...this.shipmentProductMap.values()].flat().map(it => it.sku) },
        $paginate: false,
      },
      paginate: false,
    });
    this.productSkuMap = new Map(result.map(it => [it._id, it]));
  }

  async updateSelectedShipmentsOrderIds() {
    let result = (
      await this.$feathers.service("shop/orders").find({
        query: {
          _id: { $in: [...this.shipmentOrderMap.values()].flat() },
          $select: ["_id", "orderId"],
          $paginate: false,
        },
        paginate: false,
      })
    ).map((it): [any, any] => [it._id, it.orderId]);
    this.selectedShipmentsOrderIdMap = new Map(result);
  }

  async updateSelectedShipmentsUsers() {
    let result = (
      await this.$feathers.service("shop/users").find({
        query: {
          _id: { $in: this.selectedShipments.map(it => it.user) },
          $limit: 1000,
          //$paginate: false,
        },
        //paginate: false,
      })
    ).data;
    this.shipmentUserMap = new Map(result.map(it => [it._id, it]));
  }

  async submit() {
    try {
      const resp = await this.$feathers.service("shop/shipping/shipments/split").create({
        splitFrom: this.selectedShipments.map(it => it._id) as string[],
        splitTo: this.newShipments,
      });
      this.modalResult(true);
    } catch (e) {
      this.$store.commit("SET_ERROR", e.message);
    }
  }

  close() {
    this.modalResult(null);
  }
}
