
import EditorList from "@schemaEditor/EditorList.vue";
import { Component, Prop, VModel, Vue, Watch, Ref } from "@feathers-client";

@Component({
	components: {
		EditorList,
	},
})
export default class SpecEditor extends Vue {
	@VModel()
	inputValue: {
		spec: string;
		specValue?: string;
		value?: any;
	}[];

	hasSpec(id: any) {
		return (this.inputValue ?? []).find(it => it.spec === id);
	}

	addSpec(item) {
		this.inputValue.push({
			spec: item,
			specValue: null,
			value: null,
		});
    this.$emit('updated');
	}

	adding = false;

	addFunc() {
		this.adding = !this.adding;
	}

	searchKeyword = "";
	query: any = {};
	@Ref()
	search: HTMLInputElement;

	goSearch() {
		this.search?.blur?.();
		this.query = {
			...this.query,
			$keyword: this.searchKeyword,
		};
	}

	selectAll() {
		this.search?.select?.();
	}
}
