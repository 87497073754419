
import _ from "lodash";
import { Component, Prop, Vue, Watch, mixins, Ref } from "nuxt-property-decorator";
import { Order } from "@common/pos";
import { checkID } from "@feathers-client";
import type { CategoryType, BrandType } from "@common/common";
import QrcodeStream from "@feathers-client/qrcode-scanner/components/QrcodeStream.vue";

interface ListPage {
  _id: string;
  name: any;
  type: "shop/product/searches" | "shop/categories" | "shop/brands";
  component: string;
  query: any;
}

interface ListPageStack {
  name?: any;
  pages: ListPage[];
  page: ListPage;
}

@Component({
  components: {
    QrcodeStream,
  },
})
export default class OrderSystem extends Vue {
  @Prop()
  session: Order;

  @Ref()
  dataList: any;

  scanning = false;

  checkID = checkID;
  searchKeyword = "";

  get selectedCat() {
    return this.curStack.page;
  }
  set selectedCat(v) {
    this.curStack.page = v;
  }

  selectCat(v: ListPage) {
    if (v === this.selectedCat) return;
    if (this.stacks.length > 1 && v.type === "shop/categories") {
      this.goCategory(v as any);
    } else {
      this.selectedCat = v;
      this.searchKeyword = "";
      delete this.selectedCat.query.$keyword;
      this.searchKeyword = "";
    }
  }

  get productComponent() {
    return this.$pos.kioskMode ? "table-order-system-product-kiosk" : "table-order-system-product";
  }

  stacks: ListPageStack[] = [];
  requestStock: any = {};

  sort = { order: 1 };

  beforeMount() {
    if (this.session?.warehouse) {
      Vue.set(this.requestStock, "warehouse", this.session.warehouse);
    }
    this.stacks = [
      {
        pages: [
          {
            _id: "products",
            name: { $t: "pos.products" },
            type: "shop/product/searches",
            component: this.productComponent,
            query: {
              status: { $ne: "draft" },
              $requestStock: this.requestStock,
            },
          },
          {
            _id: "categories",
            name: { $t: "productFilter.categories" },
            type: "shop/categories",
            component: "table-order-system-cat",
            query: {
              parent: null,
              $sort: this.sort,
            },
          },
          {
            _id: "brands",
            name: { $t: "pos.brands" },
            type: "shop/brands",
            component: "table-order-system-cat",
            query: {
              $sort: this.sort,
            },
          },
        ],
        page: null,
      },
    ];
    this.selectCat(this.filteredCats[0]);
  }

  @Watch("session.warehouse")
  onWarehouse(v, ov) {
    if (v === ov) return;
    if (v) {
      Vue.set(this.requestStock, "warehouse", v);
    } else {
      Vue.delete(this.requestStock, "warehouse");
    }

    this.dataList?.onSourceChanged();
  }

  get curStack() {
    return this.stacks[this.stacks.length - 1];
  }

  get filteredCats() {
    return this.curStack.pages;
  }

  get filteredProducts() {
    // if(!this.selectedSection || !this.selectedCat) return []
    // var result = _.filter(this.$shop && this.$shop.products, it => it.sections.some(jt => checkID(jt, this.selectedSection)));
    // if (this.selectedCat) {
    //     result = _.filter(result, it => checkID(it.category, this.selectedCat));
    // }
    // if (this.searchKeyword) {
    //     result = _.filter(result, item => {
    //         if (!item.code) return false;
    //         return _.includes(item.code.toLowerCase(), this.searchKeyword.toLowerCase());
    //     })
    // }
    // TODO: products
    return [];
    // return result;
  }

  get readonly() {
    return this.session.status !== "pending";
  }

  async goCategoryOrBand(item: CategoryType | BrandType) {
    if (this.selectedCat.type === "shop/categories") {
      // category

      await this.goCategory(item as any);
    } else {
      // brand

      await this.goBrand(item as any);
    }
  }

  async goCategory(item: CategoryType) {
    const subCats = await this.$feathers.service("shop/categories").find({
      query: {
        parent: item._id,
        $paginate: false,
        $sort: this.sort,
      },
      paginate: false,
    });

    this.stacks.push({
      pages: [
        {
          _id: "products_" + item._id,
          name: item.name,
          type: "shop/product/searches",
          component: this.productComponent,
          query: {
            source: "pos",
            "categories.tree._id": item._id,
            $requestStock: this.requestStock,
          },
        },
        ...(subCats.map(cat => ({
          _id: cat._id,
          name: cat.name,
          type: "shop/categories",
          component: "table-order-system-cat",
          query: {
            "categories.tree._id": cat._id,
            $sort: this.sort,
          },
        })) as any[]),
      ],
      page: null,
    });
    this.selectCat(this.curStack.pages[0]);
  }

  async goBrand(item: CategoryType) {
    // TODO: filter category in this brand?

    this.stacks.push({
      pages: [
        {
          _id: "products_" + item._id,
          name: item.name,
          type: "shop/product/searches",
          component: this.productComponent,
          query: {
            "brand._id": item._id,
            $requestStock: this.requestStock,
          },
        },
      ],
      page: null,
    });
    this.selectCat(this.curStack.pages[0]);
  }

  popStack() {
    if (this.stacks.length > 1) {
      this.stacks.pop();
    }
  }

  @Ref()
  search: HTMLInputElement;

  goSearch() {
    this.search?.blur?.();
    this.selectedCat.query = {
      ...this.selectedCat.query,
      $keyword: this.searchKeyword,
    };
    if (this.searchKeyword) {
      this.$emit("search", this.searchKeyword);
    }
  }

  selectAll() {
    this.search?.select?.();
  }

  onDecode(code) {
    this.$root.$emit("scanner", { code });
    this.scanning = false;
  }

  get columns() {
    return Math.max(
      2,
      Math.round((this.$breakpoint.width - (this.$breakpoint.mdAndUp ? this.$breakpoint.width / 3 + 150 : 100)) / 150),
    );
  }
}
