import _ from "lodash";
import qs from "querystring";
import url from "url";
import uuid from "uuid/v4";
import {
  Vue,
  Component,
  mixins,
  CurrentApp,
  FindType,
  Watch,
  Prop,
  InjectReactive,
} from "@feathers-client";
import { VueClass } from "vue-class-component/lib/declarations";
import type { SchemaHelper, EditorConfig, NormalizedRole } from "../plugin";

export interface EditorPageOpts<TKey extends keyof CurrentApp> {
  params?: any;
  path: TKey;
  selector?: string;
  replaceRoot?: string;

  default?: Partial<FindType<TKey>> | (() => Partial<FindType<TKey>>);
  single?: boolean;
  canCreate?: boolean;
  canPatch?: boolean;
  canRemove?: boolean;
  canExport?: boolean;

  createQuery?: any;
  patchQuery?: any;
  modifyQuery?: any;

  pageProps?: any;
}

function vueDefaultsDeep(objValue: any, ...sources: any[]) {
  for (let source of sources) {
    for (let [k, v] of Object.entries(source)) {
      if (objValue[k] === undefined) {
        if (typeof v === "object") {
          Vue.set(objValue, k, structuredClone(v));
        } else {
          Vue.set(objValue, k, v);
        }
      } else if (typeof objValue[k] === "object" && typeof v === "object" && v) {
        if (!objValue[k]) Vue.set(objValue, k, structuredClone(v));
        else {
          vueDefaultsDeep(objValue[k], v);
        }
      }
    }
  }
  return objValue;
}

@Component
export class EditorPageMixin<TKey extends keyof CurrentApp> extends Vue {
  editorProps: EditorPageOpts<TKey>;
  item: FindType<TKey> = null;
  saving = false;
  config: EditorConfig = null;

  inited = false;
  dirty = false;

  role: NormalizedRole = null;

  @Prop()
  parentProvider: EditorPageMixin<any>;

  @Prop()
  initItem: any;

  @Prop(Boolean)
  nested: boolean;

  @Prop({ type: Boolean, default: true })
  cloneInitItem: boolean;

  id = uuid();
  activeProvider: EditorPageMixin<any> = null;
  cloneId: string = null;

  savePromise: Promise<FindType<TKey>>;
  savePromiseFunc: (item?: FindType<TKey>) => void;

  @InjectReactive({ default: null, from: "feathers" })
  feathers: any;

  get mfeathers() {
    return this.feathers || this.$feathers;
  }

  get provider() {
    return this;
  }

  get canImport() {
    return (!this.role || this.role.write) && this.config?.import;
  }

  get canCreate() {
    return (
      this.editorProps.canCreate ??
      ((!this.role || this.role.write) &&
        this.config?.create &&
        (!this.editorProps.single || !this.itemId))
    );
  }

  get canPatch() {
    return this.editorProps.canPatch ?? ((!this.role || this.role.write) && this.config?.patch);
  }

  get canRemove() {
    return (
      this.editorProps.canRemove ??
      ((!this.role || this.role.write) && this.config?.remove && !this.editorProps.single)
    );
  }

  get canExport() {
    return this.editorProps.canExport ?? ((!this.role || this.role.read) && this.config?.export);
  }

  get itemId() {
    return (this.item as any)?._id;
  }

  initTask: Promise<void>;

  created() {
    this.initTask = this.doInit();
  }

  async doInit() {
    const schemas: SchemaHelper = this.$schemas;
    await schemas.init();
    const config = schemas.getConfigByApiPath(
      this.editorProps.path,
      this.editorProps.selector,
      this.editorProps.replaceRoot,
    );
    if (!config) return;

    let item = null;

    if (this.initItem) {
      if (typeof this.initItem === "string") {
        item = await (this.mfeathers.service(this.editorProps.path) as any).get(this.initItem, {
          query: {
            ...(this.editorProps.params || {}),
            ...(config.softDelete ? { $disableSoftDelete: true } : {}),
          },
        });
      } else {
        if (this.feathers || !this.cloneInitItem) {
          item = this.initItem;
        } else {
          item = structuredClone(this.initItem);
        }
      }
    } else if (!this.parentProvider) {
      if (this.$route.query.clone || this.$route.params.id) {
        item = await (this.mfeathers.service(this.editorProps.path) as any).get(
          `${this.$route.query.clone || this.$route.params.id}`,
          {
            query: {
              ...(this.editorProps.params || {}),
              ...(config.softDelete ? { $disableSoftDelete: true } : {}),
            },
          },
        );
      } else if (this.editorProps.single) {
        item = (
          await (this.mfeathers.service(this.editorProps.path) as any).find({
            query: {
              ...(this.editorProps.params || {}),
              ...(config.softDelete ? { $disableSoftDelete: true } : {}),
            },
          })
        ).data[0];
      }
      if (item && this.$route.query.clone) {
        this.cloneId = String(item._id);
        delete item._id;
      }
    }

    const def =
      this.editorProps.default instanceof Function
        ? this.editorProps.default()
        : this.editorProps.default;
    this.config = config;
    this.item = vueDefaultsDeep(item || {}, def || {}, this.config.defaultValue || {});
    await this.postInit();
    const actions = [];
    if (this.editorProps.single) {
      actions.push();
    }
    if (!this.parentProvider && !this.nested) {
      this.$store.commit("SET_TITLE", {
        titles: this.titles,
        actions: this.config.actions || [],
        nestedBack: !!this.activeProvider,
        noPadding: true,
        ...(this.editorProps?.pageProps || {}),
      });
    }
    this.dirty = false;
  }

  titlePrefix: any[] = null;

  get titles() {
    return [
      ...(this.parentProvider
        ? this.parentProvider.titles
        : [{ name: { $t: this.config?.name }, to: this.backPath }]),
      ...(this.titlePrefix || []),
      ...(this.config?.nameField
        ? [
            {
              name: this.item?.[this.config.nameField.name] || "-",
              action: ["back-to", this.id],
            },
          ]
        : []),
    ];
  }

  get finalTitles() {
    if (this.nested) return;
    if (this.parentProvider) return;
    if (this.activeProvider) return this.activeProvider.titles;
    return this.titles;
  }

  @Watch("finalTitles")
  onUpdateTitle() {
    if (this.nested) return;
    this.$store.commit("SET_TITLE", {
      titles: this.finalTitles,
      actions: this.config.actions || [],
      nestedBack: !!this.activeProvider,
      noPadding: true,
      ...(this.editorProps?.pageProps || {}),
    });
  }

  async postInit() {}

  async preSave() {}

  async postSave() {}

  mounted() {
    if (!this.parentProvider) {
      this.$root.$on("save", this.save);
      this.$root.$on("add", this.add);
      this.$root.$on("remove", this.remove);
    }
  }
  beforeDestroy() {
    if (!this.parentProvider) {
      this.$root.$off("save", this.save);
      this.$root.$off("add", this.add);
      this.$root.$off("remove", this.remove);
    }
  }
  add() {
    const spath = this.$route.matched[0].path;
    let tpath = spath.replace(/:([a-zA-Z_-]+)(\(((?!\)).)+\))?/g, (m, key) => {
      return key === "id" ? "" : this.$route.params[key];
    });
    this.$router.push(tpath);
  }
  async save(saveOpts?: any) {
    let { path, query, useNew, params } = saveOpts || {};
    params = params || {};
    const pquery = (params.query = params.query || {});
    if (this.config.softDelete) {
      pquery.$disableSoftDelete = true;
    }
    if (this.editorProps?.modifyQuery) {
      Object.assign(pquery, this.editorProps.modifyQuery);
    }
    if (this.itemId && this.editorProps?.patchQuery) {
      Object.assign(pquery, this.editorProps.patchQuery);
    } else if (!this.itemId && this.editorProps?.createQuery) {
      Object.assign(pquery, this.editorProps.createQuery);
    }
    this.$store.commit("SET_PROCESSING", "save");
    this.saving = true;
    try {
      if (this.itemId) {
        await this.preSave();
        const item = await this.mfeathers
          .service(path || this.editorProps.path)
          .patch(this.itemId, this.item, params);
        for (let [k, v] of Object.entries(item)) {
          Vue.set(this.item as any, k, v);
        }
        await this.postSave();
      } else {
        await this.preSave();
        const item = await this.mfeathers
          .service(path || this.editorProps.path)
          .create(this.item, params);
        for (let [k, v] of Object.entries(item)) {
          Vue.set(this.item as any, k, v);
        }
        await this.postSave();
        if (this.parentProvider || this.nested) {
        } else {
          const spath = this.$route.matched[0].path;
          let tpath = spath.replace(/:([a-zA-Z_-]+)(\(((?!\)).)+\))?/g, (m, key) => {
            return key === "id" ? item._id : this.$route.params[key];
          });
          if (query) {
            tpath += "?" + qs.stringify(query);
          }
          //TODO: fix this
          //window.history.replaceState(null, null, tpath);
          this.$router.replace(tpath);
        }
      }
      this.$emit("save");
      Vue.nextTick(() => {
        this.dirty = false;
      });
      this.cloneId = null;
    } catch (e) {
      this.$store.commit("SET_ERROR", e.message);
    } finally {
      this.$store.commit("SET_PROCESSING", null);
      this.saving = false;
    }
  }
  async remove() {
    if (this.parentProvider && !(this.item as any)?._id) {
      this.$emit("remove");
      this.goBack();
      return;
    }
    this.$store.commit("SET_PROCESSING", "remove");
    try {
      const c = await this.$openDialog(
        import("@feathers-client/components-internal/ConfirmDialog.vue"),
        {
          title: this.$t("basic.doYouWantToDelete"),
        },
        {
          maxWidth: "400px",
        },
      );
      if (!c) return;
      const id = this.itemId;
      const service = this.mfeathers.service(this.editorProps.path) as any;
      if (id) {
        await service.remove(id, this.editorProps.params ? { query: this.editorProps.params } : {});
        this.$emit("remove");
        this.goBack();
      }
    } catch (e) {
      this.$store.commit("SET_ERROR", e.message);
    } finally {
      this.$store.commit("SET_PROCESSING", null);
    }
  }

  get backPath() {
    const spath = this.$route.path;
    const tpath = url.resolve(spath, "../");
    return tpath;
  }

  goBack() {
    if (this.parentProvider) {
      this.$root.$emit("back-to", this.parentProvider.id);
      return;
    }
    this.$router.replace(this.backPath);
  }

  async confirmReload() {
    this.$store.commit("SET_PROCESSING", "reload");
    try {
      const c = await this.$openDialog(
        import("@feathers-client/components-internal/ConfirmDialog.vue"),
        {
          title: this.$t("basic.doYouWantToRestore"),
        },
        {
          maxWidth: "400px",
        },
      );
      if (!c) return;
      await this.doInit();
    } catch (e) {
      this.$store.commit("SET_ERROR", e.message);
    } finally {
      this.$store.commit("SET_PROCESSING", null);
    }
  }

  getSubRoute<TKey extends keyof CurrentApp>(props: EditorPageOpts<TKey>, itemOrId: any) {
    return new (EditorPageMixinFactory<TKey>(props))({
      parent: this,
      propsData: {
        parentProvider: this,
        initItem: itemOrId,
        cloneInitItem: false,
      },
    });
  }

  markDirty() {
    this.dirty = true;
    if (this.parentProvider) {
      this.parentProvider.markDirty();
    }
  }

  async editItem(item: any) {
    const SinglePage = await import("../EditorSinglePage.vue");
    await this.$openDialog(
      import("domore-table/dialogs/EditDialog.vue"),
      {
        provider: this,
        source: item,
        origin,
        renderItem: (slot) => {
          return (this as any)._c(SinglePage.default, {
            props: {
              config: this.config,
              item: slot.item,
              canSave: true,
              // canCancel: true,
              canRemove: false,
            },
            on: {
              save: () => {
                this.save();
              },
            },
          });
        },
        slots: {},
        useEdit: false,
      },
      {
        contentClass: "editor-dialog",
      },
    );
  }

  async openPopup() {
    const EditorSinglePage = (await import("../EditorSinglePage.vue")).default;
    return await this.$openDialog(
      import("domore-table/dialogs/EditDialog.vue"),
      {
        source: this.item,
        cancelButton: false,
        renderItem: (edit) => {
          return (this as any)._c(EditorSinglePage, {
            props: {
              config: this.config,
              item: edit.item,
              canSave: true,
              canRemove: false,
              canCancel: true,
            },
            on: {
              save: edit.save,
              cancel: edit.cancel,
            },
          });
        },
        slots: this.$scopedSlots,
        useEdit: false,
      },
      {
        contentClass: "editor-dialog",
      },
    );
  }
}

export function EditorPageMixinFactory<TKey extends keyof CurrentApp>(props: EditorPageOpts<TKey>) {
  return EditorPageMixin.extend({
    data() {
      return {
        editorProps: props,
      };
    },
  }) as VueClass<EditorPageMixin<TKey>>;
}
export default EditorPageMixinFactory;
