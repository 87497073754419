
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import { DataTableDef, DataTableHeader, DataTablePagination, DataTablePaginated } from './index'
import VirtualDataTableCell from './VirtualDataTableCell'
import type VirtualDataTable from './VirtualDataTable.vue'
import {
    VBtn,
} from 'vuetify/lib'
import { IdState } from './vue-virtual-scroller'

@Component
export default class VirtualDataTableRow extends Vue {
    @Prop()
    header : DataTableHeader

    @Prop()
    sort : string[]

    @Prop()
    sortDesc : boolean[]

    get showIndex() {
        return this.sortIndex !== -1 && this.sort.length > 1;
    }

    get sortIndex() {
        return this.sort.indexOf(this.header.sortField || this.header.value);
    }

    get descending() {
        return this.sortIndex === -1 ? null : this.sortDesc[this.sortIndex];
    }

}

