
import { Component, Vue, Prop, FindType, FindPopRawType, VModel, Watch, checkID } from "@feathers-client";

@Component
export default class DataTableForErp extends Vue {

  @Prop()
  header: [string]

  // @Prop({ default: [] })
  // tableData: any

  @Prop({ default: false })
  editing: boolean  // decide if left side have checkboxes

  @Prop({ default: false })
  headerTextCenter: boolean

  @Prop({ default: false })
  extraCol: boolean // decide if right side have one more column for extra functions

}
