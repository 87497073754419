

import { Component, Prop, Vue, Watch, mixins, FindType, getID } from "@feathers-client";
import Dialog from '@feathers-client/mixins/Dialog';

@Component
export default class AddPointDialog extends mixins(Dialog) {
    
    @Prop()
    point: FindType<'shop/points'>

    @Prop()
    user: FindType<'shop/users'>

    amount = 0
    message = 'by admin'
    loading = false;

    async addPoint() {
        try {
            this.loading = true;

            await this.$feathers.service('shop/user/points/adjust').create({
                point: getID(this.point),
                user: getID(this.user),
                amount: this.amount,
                message: this.message,
            })

            this.modalResult(true);
        } catch(e) {
            this.$store.commit("SET_ERROR", e.message);
        } finally {
            this.loading = false;
        }
    }

    
}

