
import { Component, Vue, Prop } from "@feathers-client";
import { PageBlock } from "../def";
import { SiteEditorComponentVariant, SiteEditorManager } from "..";
import EditorTranslateBox from "@schemaEditor/EditorTranslateBox.vue";
import EditorCheckbox from "@schemaEditor/EditorCheckbox.vue";
import EditorList from "@schemaEditor/EditorList.vue";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import { LangArrType } from "@feathers-client/i18n";
import uuid from "uuid/v4";
// @ts-ignore
import RarrowSvg from "!vue-svg-loader!../assets/rarrow.svg";
import ColorPicker from "../ColorPicker.vue";

@Component({
  components: {
    EditorTranslateBox,
    EditorCheckbox,
    EditorList,
    EditorTextField,
    RarrowSvg,
    ColorPicker,
  },
})
export default class SiteEditorProgressBar extends Vue {
  @Prop()
  block: PageBlock;

  @Prop()
  manager: SiteEditorManager;

  @Prop()
  variant: SiteEditorComponentVariant;

  showItemPerRowDropdown = false;

  get props() {
    return this.manager.defineProps(this.block, {
      title: [] as LangArrType,
      titleDisplayMode: "left",
      remarks: [] as LangArrType,
      fontColor: "#000000FF",
      progressColor: "#000000FF",
      titleColor: "#000000FF",
      contentColor: "#000000FF",
      animation: false,
      items: [] as SiteEditorProgressBarItem[],
    });
  }

  getItem() {
    return {
      id: uuid(),
      title: [] as LangArrType,
      desc: [] as LangArrType,
      progress: 100,
    } as SiteEditorProgressBarItem;
  }

  get alignTypes() {
    return ["left", "center", "right"];
  }
}

export class SiteEditorProgressBarItem {
  id?: string;
  title?: LangArrType;
  desc?: LangArrType;
  progress?: Number;
}
