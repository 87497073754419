
import { Component, Prop, Vue, Watch, mixins, Ref } from "nuxt-property-decorator";
import ButtonBase from "../components-internal/ButtonBase.vue";

@Component
export default class MenuListItem extends mixins(ButtonBase) {
  @Prop()
  icon: string;

  @Prop({ default: "w-10 h-10" })
  iconClass: string;

  @Prop()
  appendIcon: string;

  @Prop()
  appendText: string;

  @Prop({ default: "text-current min-h-10 py-2 overflow-hidden text-ellipsis" })
  labelClass: string;

  @Prop(Boolean)
  focusing: boolean;

  @Prop({ type: Boolean })
  lock: boolean;

  @Prop({ type: Boolean })
  paddedLock: boolean;
}
