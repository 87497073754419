
import { Component, Prop, Vue, Watch, mixins, FindType, getID } from "@feathers-client";
import Dialog from "@feathers-client/mixins/Dialog";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import EditorDatePicker from "@schemaEditor/EditorDatePicker.vue";
import EditorObjectPickerNew from "@schemaEditor/EditorObjectPickerNew.vue";
import moment from "moment";
import { regEscape } from "~/common/util";

const statusDict = {
  pending: { $in: ["pending", "partial"] },
  done: { $in: ["confirmed", "done"] },
  cancelled: { $in: ["cancelled", "void"] },
};

@Component({
  components: {
    EditorTextField,
    EditorDatePicker,
    EditorObjectPickerNew,
  },
})
export default class OrderHistory extends mixins(Dialog) {
  @Prop()
  nextOrder: (orderId?: string) => void;

  idSearch = "";
  dateSearch = "";

  source: string = null;
  get sources() {
    return [
      {
        _id: null,
        name: { $t: "basic.all" },
      },
      {
        _id: "pos",
        name: { $t: "enum.pages.shop/orders.source.pos" },
      },
      {
        _id: "eshop",
        name: { $t: "enum.pages.shop/orders.source.eshop" },
      },
    ];
  }

  status: string = null;

  get statuses() {
    return [
      {
        _id: null,
        name: { $t: "basic.all" },
      },
      {
        _id: "pending",
        name: { $t: "orderSystem.status.unpaid" },
      },
      {
        _id: "done",
        name: { $t: "enum.basic.status.done" },
      },
      {
        _id: "cancelled",
        name: { $t: "enum.basic.status.cancelled" },
      },
    ];
  }

  get orderQuery() {
    const date = this.dateSearch && moment(this.dateSearch).isValid() ? moment(this.dateSearch) : null;

    return {
      ...(this.status
        ? {
            status: statusDict[this.status],
          }
        : {}),
      ...(this.source
        ? {
            source: this.source,
          }
        : {}),
      ...(date
        ? {
            date: {
              $gte: date.startOf("day").toDate(),
              $lte: date.endOf("day").toDate(),
            },
          }
        : {}),
      ...(this.idSearch
        ? {
            orderId: {
              $regex: regEscape(this.idSearch),
              $options: "i",
            },
          }
        : {}),
    };
  }

  get orderSort() {
    return {
      date: -1,
    };
  }

  async openOrder(item: FindType<"shop/orders">) {
    this.nextOrder(getID(item));
    this.modalResult();
  }
}
