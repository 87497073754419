
import { Component, Prop, Vue, Watch, mixins, FindType, VModel, checkID, getID, PropSync, Ref } from "@feathers-client";

@Component({})
export default class PaymentButton extends Vue {
	@Ref()
	payButton: HTMLElement;

	@Prop()
	loading: boolean;

	@Prop()
	canPay: boolean;

	mloading = false;

	done = false;

	get isLoading() {
		return this.mloading || this.loading;
	}

	@Prop()
	func: () => Promise<boolean>;

	startDrag(e: MouseEvent | TouchEvent) {
		if (this.isLoading || !this.canPay) return;
		if (!this.payButton) return;

		const elem = this.$el as HTMLElement;
		const pointer = window.TouchEvent && e instanceof TouchEvent ? e.touches[0] : (e as MouseEvent);
		// e.stopPropagation();
		// e.preventDefault();
		const offsetX = pointer.clientX;
		const downX = pointer.clientX;
		const downY = pointer.clientY;
		const style = window.getComputedStyle(elem);

		const fromColor = this.$config.colors["red500"]
			.slice(1)
			.match(/.{2}/g)
			.map(it => parseInt(it, 16));
		const toColor = this.$config.colors["red200"]
			.slice(1)
			.match(/.{2}/g)
			.map(it => parseInt(it, 16));
		const deltaColor = fromColor.map((v, idx) => toColor[idx] - v);

		let padding = parseFloat(style.paddingLeft) + parseFloat(style.paddingRight);
		if (isNaN(padding)) padding = 0;
		const maxW = elem.getBoundingClientRect().width - this.payButton.getBoundingClientRect().width - padding;
		this.payButton.style.transition = null;
		elem.style.transition = null;
		elem.style.transitionProperty = "none";
		this.payButton.style.transitionProperty = "color,filter,opacity";
		let moving = false;
		let currentDelta = 0;
		const mousemove = (e: MouseEvent | TouchEvent) => {
			const pointer = window.TouchEvent && e instanceof TouchEvent ? e.touches[0] : (e as MouseEvent);
			if (!moving && Math.abs(pointer.clientY - downY) > 5) {
				cancel();
				return;
			}
			if (!moving && Math.abs(pointer.clientX - downX) > 5) {
				moving = true;
			}
			if (!moving) return;
			const delta = Math.max(0, Math.min(maxW, pointer.clientX - offsetX));
			if (e.cancelable) {
				e.preventDefault();
			}
			e.stopPropagation();
			currentDelta = delta;
			this.payButton.style.transform = `translate(${delta}px, 0)`;

			const progress = Math.max(0, Math.min(1, currentDelta / maxW));
			const curColor = fromColor.map((v, idx) => v + deltaColor[idx] * progress);
			elem.style.background = `rgba(${curColor[0]},${curColor[1]},${curColor[2]},1)`;
		};
		const mouseup = (e: MouseEvent | TouchEvent) => {
			cancel();
			if (!moving) return;
			if (e.cancelable) {
				e.preventDefault();
			}
			e.stopPropagation();
			document.addEventListener("click", click, { capture: true });
			e.target.addEventListener("click", click);
			const progress = Math.max(0, Math.min(1, currentDelta / maxW));
			const dur = (0.5 - Math.abs(0.5 - progress)) * 0.6; // remain progress
			if (dur) {
				this.payButton.style.transition = `all ${dur}s cubic-bezier(0.4, 0, 0.2, 1)`;
				elem.style.transition = `all ${dur}s cubic-bezier(0.4, 0, 0.2, 1)`;
			}
			this.payButton.style.transform = `translate(${progress > 0.5 ? maxW + "px" : 0},0)`;
			elem.style.background = this.$config.colors[progress > 0.5 ? "red200" : "red500"];

			if (progress > 0.5) {
				if (dur) {
					this.payButton.addEventListener(
						"transitionend",
						() => {
							this.click();
						},
						{
							once: true,
						},
					);
				} else {
					this.click();
				}
			}
		};
		let cancel = () => {
			document.removeEventListener("mousemove", mousemove, { capture: true });
			document.removeEventListener("mouseup", mouseup, { capture: true });
			document.removeEventListener("touchmove", mousemove, { capture: true });
			document.removeEventListener("touchend", mouseup, { capture: true });
		};
		let click = () => {
			if (e.cancelable) {
				e.preventDefault();
			}
			e.stopImmediatePropagation();
			e.stopPropagation();
			document.removeEventListener("click", click, { capture: true });
		};
		document.addEventListener("mousemove", mousemove, { capture: true });
		document.addEventListener("mouseup", mouseup, { capture: true });
		document.addEventListener("touchmove", mousemove, { capture: true, passive: false });
		document.addEventListener("touchend", mouseup, { capture: true });
	}

	async click() {
		try {
			this.mloading = true;
			if (await this.func()) {
				this.done = true;
			}
		} catch (e) {
			console.warn(e);
		} finally {
			const elem = this.$el as HTMLElement;
			if (this.payButton) {
				this.payButton.style.transition = `all .15s cubic-bezier(0.4, 0, 0.2, 1)`;
				this.payButton.style.transform = null;
			}
			if (elem) {
				elem.style.transition = `all .15s cubic-bezier(0.4, 0, 0.2, 1)`;
				elem.style.background = null;
			}
			await new Promise<void>(resolve => {
				if (this.payButton) {
					this.payButton.addEventListener(
						"transitionend",
						() => {
							resolve();
						},
						{
							once: true,
						},
					);
				}
			});
			this.mloading = false;
		}
	}
}
