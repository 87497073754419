
import { Component, Vue, Prop, Ref } from "@feathers-client";
import { PageBlock } from "../def";
import { SiteEditorComponentVariant, SiteEditorManager } from "..";
import EditorList from "@schemaEditor/EditorList.vue";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import EditorTranslateBox from "@schemaEditor/EditorTranslateBox.vue";
import EditorCheckbox from "@schemaEditor/EditorCheckbox.vue";
import EditorObjectPickerList from "@schemaEditor/EditorObjectPickerList.vue";
import ColorPicker from "../ColorPicker.vue";
import UrlPicker from "../UrlPicker.vue";
import uuid from "uuid/v4";
import { LangArrType } from "@feathers-client/i18n";
import CheckIcon from "@feathers-client/components-internal/CheckIcon.vue";
// @ts-ignore
import DeleteSvg from "!vue-svg-loader!../assets/delete.svg";
// @ts-ignore
import RarrowSvg from "!vue-svg-loader!../assets/rarrow.svg";
// @ts-ignore
import LangPicker from "../LangPicker.vue";

@Component({
  components: {
    EditorList,
    EditorTextField,
    EditorTranslateBox,
    EditorCheckbox,
    EditorObjectPickerList,
    LangPicker,
    ColorPicker,
    UrlPicker,
    CheckIcon,
    DeleteSvg,
    RarrowSvg,
  },
})
export default class SiteEditorImage extends Vue {
  @Prop()
  block: PageBlock;

  @Prop()
  manager: SiteEditorManager;

  @Prop()
  variant: SiteEditorComponentVariant;

  showItemPerRowDropdown = false;

  haveError = false;
  errorMsg: any = "";

  get sizeType() {
    return ["small", "large"];
  }

  get titleDisplayModeType() {
    return ["left", "center", "right"];
  }

  getItem() {
    return {
      id: uuid(),
      file: null,
      urlType: "internal",
      url: null,
    } as SiteEditorIconItem;
  }

  get groupKey() {
    return this.variant.group.key;
  }

  get keyName() {
    return this.variant.key;
  }

  get props() {
    return this.manager.defineProps(this.block, {
      items: [] as SiteEditorIconItem[],
      title: [] as LangArrType,
      remarks: [] as LangArrType,
      titleDisplayMode: "left",
      fontColor: "#000000FF",
      fontRemarksColor: "#000000FF",
      size: "small",
    });
  }

  showErrorMsg() {}

  onValidAndConfirm() {
    this.$emit("save");
  }
}

export class SiteEditorIconItem {
  id?: string;

  file?: any;
  urlType: string;
  url?: string;
}
