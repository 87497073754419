var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex lg:px-30 md:px-15 <md:px-8 flex-col gap-y-6",class:`variant-${_vm.variant}`,style:({ '--item-per-row': `repeat(${_vm.itemPerRow},minmax(0,1fr))` })},[_c('div',{staticClass:"d-flex text-[var(--fg-color)]",style:({ '--fg-color': _vm.fontColor, '--fg-remarks-color': _vm.fontRemarksColor })},[_c('div',{staticClass:"flex-grow",class:{ 'text-center': _vm.titleDisplayMode === 'center', 'text-right': _vm.titleDisplayMode === 'right' }},[(_vm.$td(_vm.title))?_c('div',{staticClass:"font-bold text-4xl <md:text-xl",domProps:{"innerHTML":_vm._s(_vm.$htmlContent(_vm.$td(_vm.title)))}}):_vm._e(),_vm._v(" "),(_vm.$td(_vm.remarks))?_c('div',{staticClass:"text-[var(--fg-remarks-color)] font-bold text-xl <md:text-base",domProps:{"innerHTML":_vm._s(_vm.$htmlContent(_vm.$td(_vm.remarks)))}}):_vm._e()]),_vm._v(" "),(_vm.editType === 'auto' && _vm.titleDisplayMode !== 'center')?_c('nuxt-link',{staticClass:"d-flex align-center text-lg <md:text-sm self-end",attrs:{"to":_vm.$localePath(
          `/shop?query=${JSON.stringify({
            currentFilter: {
              ...(_vm.listType === 'categories'
                ? {
                    'categories.tree._id': { $in: _vm.selectedList },
                  }
                : {}),
              ...(_vm.listType === 'brands'
                ? {
                    'brand._id': { $in: _vm.selectedList },
                  }
                : {}),
              ...(_vm.listType === 'tags'
                ? {
                    'tags._id': { $in: _vm.selectedList },
                  }
                : {}),
            },
            ...(_vm.sorting === 'recommended'
              ? {
                  sortBy: ['order'],
                }
              : { sortBy: ['date'] }),

            ...(_vm.sorting === 'recommended'
              ? {
                  sortDesc: [false],
                }
              : { sortDesc: [true] }),
          })}`,
        )}},[_vm._v("\n      "+_vm._s(_vm.$t("viewMore"))+"\n      "),_c('more-svg',{staticClass:"w-5 h-5 custom-icon ml-2"})],1):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"content-container gap-5 grid",style:({ '--image-fg-color': _vm.imageFontColor, '--image-bg-color': _vm.imageBgColor })},[(_vm.editType === 'custom')?_vm._l((_vm.items),function(item,idx){return _c('div',{staticClass:"item-container",on:{"mouseover":function($event){_vm.hovered = item},"mouseleave":function($event){_vm.hovered = null}}},[_c(_vm.getLink(_vm.getItem(item.productGroup, idx)),_vm._b({key:`gallery-${idx}`,tag:"component",staticClass:"d-flex flex-col text-center w-full h-full"},'component',_vm.getLinkProp(_vm.getItem(item.productGroup, idx)),false),[_c('div',{staticClass:"d-flex flex-col gap-y-3 relative w-full"},[_c('v-img',{staticClass:"content-image",attrs:{"aspect-ratio":"1","sizes":"100vw","src":_vm.$imageSet(_vm.getItem(item.productGroup, idx).file)}}),_vm._v(" "),(_vm.displayMode !== 'externalText')?_c('div',{staticClass:"absolute text-[var(--image-fg-color)] w-full d-flex justify-center lg:px-10 md:px-8 <md:px-6 flex-col bottom-0 py-5 gap-y-3",class:{
                hiddenOverlay: _vm.displayMode === 'hoverBottomText' && _vm.hovered !== item,
                centerText: _vm.displayMode === 'centeredText',
                'bg-black/[.6]': !_vm.imageBgColor,
                'bg-[var(--image-bg-color)]': _vm.imageBgColor && _vm.displayMode !== 'centeredText',
                'align-center': _vm.contentAlign === 'center',
                'align-end': _vm.contentAlign === 'right',
              }},[(_vm.$td(_vm.getItem(item.productGroup, idx).title))?_c('div',{staticClass:"overlayTitle content-inner font-bold line-clamp-1"},[_vm._v("\n                "+_vm._s(_vm.$td(_vm.getItem(item.productGroup, idx).title))+"\n              ")]):_vm._e(),_vm._v(" "),(_vm.displayDesc && _vm.$td(_vm.getItem(item.productGroup, idx).desc))?_c('div',{staticClass:"overlayContent break-words whitespace-normal align-left content-inner line-clamp-1"},[_vm._v("\n                "+_vm._s(_vm.$td(_vm.getItem(item.productGroup, idx).desc))+"\n              ")]):_vm._e(),_vm._v(" "),(_vm.displayPrice && _vm.getItem(item.productGroup, idx).price !== null)?_c('div',{staticClass:"overlayPrice content-inner line-clamp-1"},[_vm._v("\n                "+_vm._s(_vm.$price(_vm.getItem(item.productGroup, idx).price, "pre"))+"\n              ")]):_vm._e()]):_vm._e()],1),_vm._v(" "),(_vm.displayMode === 'externalText')?_c('div',{staticClass:"flex-grow w-full d-flex justify-center lg:px-10 md:px-8 <md:px-6 flex-col bottom-0 py-5 gap-y-3",class:{
              'bg-black/[.6]': !_vm.imageBgColor,
              'bg-[var(--image-bg-color)]': _vm.imageBgColor,
              'align-center': _vm.contentAlign === 'center',
              'align-end': _vm.contentAlign === 'right',
            }},[(_vm.$td(item.title))?_c('div',{staticClass:"overlayTitle content-inner font-bold line-clamp-1 text-xl"},[_vm._v("\n              "+_vm._s(_vm.$td(item.title))+"\n            ")]):_vm._e(),_vm._v(" "),(_vm.displayDesc && _vm.$td(item.desc))?_c('div',{staticClass:"overlayContent break-words whitespace-normal align-left content-inner line-clamp-1 text-xl"},[_vm._v("\n              "+_vm._s(_vm.$td(item.desc))+"\n            ")]):_vm._e(),_vm._v(" "),(_vm.displayPrice && item.price !== null)?_c('div',{staticClass:"overlayPrice content-inner line-clamp-1 text-xl"},[_vm._v("\n              "+_vm._s(_vm.$price(item.price, "pre"))+"\n            ")]):_vm._e(),_vm._v(" "),(item.mainButton && item.mainButton.enabled)?_c(_vm.getButtonLink(item.mainButton),_vm._b({tag:"component",staticClass:"py-3 px-6 min-w-32.5 rounded-full !text-[var(--main-button-fg-color)] !bg-[var(--main-button-bg-color)] text-base text-center",style:({
                '--main-button-bg-color': item.mainButton.bgColor,
                '--main-button-fg-color': item.mainButton.fgColor,
              })},'component',_vm.getButtonLinkProp(item.mainButton),false),[_vm._v("\n              "+_vm._s(_vm.$td(item.mainButton.title))+"\n            ")]):_vm._e()],1):_vm._e()])],1)}):(_vm.editType === 'auto')?_vm._l((_vm.searchItems),function(item,idx){return _c('div',{staticClass:"item-container",on:{"mouseover":function($event){_vm.hovered = item},"mouseleave":function($event){_vm.hovered = null}}},[_c(_vm.getLink(item),_vm._b({key:`gallery-${idx}`,tag:"component",staticClass:"d-flex flex-col text-center w-full h-full"},'component',_vm.getLinkProp(item),false),[_c('div',{staticClass:"d-flex flex-col gap-y-3 relative w-full"},[_c('v-img',{staticClass:"content-image",attrs:{"aspect-ratio":"1","sizes":"100vw","src":_vm.$imageSet(item.file)}}),_vm._v(" "),(_vm.displayMode !== 'externalText')?_c('div',{staticClass:"absolute w-full d-flex justify-center lg:px-10 md:px-8 <md:px-6 flex-col bottom-0 py-5 gap-y-3",class:{
                hiddenOverlay: _vm.displayMode === 'hoverBottomText' && _vm.hovered !== item,
                centerText: _vm.displayMode === 'centeredText',
                'bg-black/[.6]': !_vm.imageBgColor,
                'bg-[var(--image-bg-color)]': _vm.imageBgColor && _vm.displayMode !== 'centeredText',
                'align-center': _vm.contentAlign === 'center',
                'align-end': _vm.contentAlign === 'right',
              }},[(_vm.$td(item.title))?_c('div',{staticClass:"overlayTitle content-inner font-bold line-clamp-1"},[_vm._v("\n                "+_vm._s(_vm.$td(item.title))+"\n              ")]):_vm._e(),_vm._v(" "),(_vm.displayDesc && _vm.$td(item.desc))?_c('div',{staticClass:"overlayContent break-words whitespace-normal align-left content-inner line-clamp-1"},[_vm._v("\n                "+_vm._s(_vm.$td(item.desc))+"\n              ")]):_vm._e(),_vm._v(" "),(_vm.displayPrice && item.price !== null)?_c('div',{staticClass:"overlayPrice content-inner line-clamp-1"},[_vm._v("\n                "+_vm._s(_vm.$price(item.price, "pre"))+"\n              ")]):_vm._e()]):_vm._e()],1),_vm._v(" "),(_vm.displayMode === 'externalText')?_c('div',{staticClass:"flex-grow w-full d-flex justify-center lg:px-10 md:px-8 <md:px-6 flex-col bottom-0 py-5 gap-y-3",class:{
              'bg-black/[.6]': !_vm.imageBgColor,
              'bg-[var(--image-bg-color)]': _vm.imageBgColor,
              'align-center': _vm.contentAlign === 'center',
              'align-end': _vm.contentAlign === 'right',
            }},[(_vm.$td(item.title))?_c('div',{staticClass:"overlayTitle content-inner font-bold line-clamp-1 text-xl"},[_vm._v("\n              "+_vm._s(_vm.$td(item.title))+"\n            ")]):_vm._e(),_vm._v(" "),(_vm.displayDesc && _vm.$td(item.desc))?_c('div',{staticClass:"overlayContent break-words whitespace-normal align-left content-inner line-clamp-1 text-xl"},[_vm._v("\n              "+_vm._s(_vm.$td(item.desc))+"\n            ")]):_vm._e(),_vm._v(" "),(_vm.displayPrice && item.price !== null)?_c('div',{staticClass:"overlayPrice content-inner line-clamp-1 text-xl"},[_vm._v("\n              "+_vm._s(_vm.$price(item.price, "pre"))+"\n            ")]):_vm._e()]):_vm._e()])],1)}):_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"d-flex justify-center"},[(_vm.editType === 'auto' && _vm.titleDisplayMode === 'center')?_c('nuxt-link',{staticClass:"w-max d-flex align-center text-lg <md:text-sm justify-center",attrs:{"to":_vm.$localePath(
          `/shop?query=${JSON.stringify({
            currentFilter: {
              ...(_vm.listType === 'categories'
                ? {
                    'categories.tree._id': { $in: _vm.selectedList },
                  }
                : {}),
              ...(_vm.listType === 'brands'
                ? {
                    'brand._id': { $in: _vm.selectedList },
                  }
                : {}),
              ...(_vm.listType === 'tags'
                ? {
                    'tags._id': { $in: _vm.selectedList },
                  }
                : {}),
            },
            ...(_vm.sorting === 'recommended'
              ? {
                  sortBy: ['order'],
                }
              : { sortBy: ['date'] }),

            ...(_vm.sorting === 'recommended'
              ? {
                  sortDesc: [false],
                }
              : { sortDesc: [true] }),
          })}`,
        )}},[_vm._v("\n      "+_vm._s(_vm.$t("viewMore"))+"\n      "),_c('more-svg',{staticClass:"w-5 h-5 custom-icon ml-2"})],1):_vm._e()],1)])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }