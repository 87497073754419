import type { ProductType, PosContext, ProductGroupType, ProductSearchType } from "./common";
import { checkID } from "./util";
import type { LineItem } from "./posLineItem";
import _ from "lodash";
import { dbMixin } from "./common";

export class ProductInfo extends dbMixin<ProductType>()(
  "name",
  "fullName",
  "images",
  "shopTable",
  "specs",
  "productGroup",

  "points",
  "coupons",
  "categories",
  "bundledSkus",
  'productOptions',
  "type",
) {
  constructor(public context: PosContext, product: ProductType) {
    super(product);
  }

  get source() {
    return this.context.$source ?? "pos";
  }

  get priceTable() {
    return this.shopTable?.find?.(it => checkID(it.shop, this.context.$shop?._id));
  }

  get priceLines() {
    return _.orderBy(
      this.priceTable?.pricing,
      [
        p => _.maxBy(p.conditions, c => c.quantity)?.quantity,
        p => _.max(_.map(p.conditions, c => c.ranks?.length ?? 1000)),
        p => _.sumBy(p.points, v => v.value),
        p => p.priceInt,
      ],
      ["asc", "desc", "asc", "asc"],
    );
  }

  get originalPrice() {
    return this.priceTable?.originalPriceInt;
  }

  get generalPrice() {
    return this.priceLines[0]?.priceInt;
  }

  get weightType() {
    return this.priceTable?.weight?.type;
  }

  get weightUnit() {
    return this.priceTable?.weight?.unit;
  }

  get weightUnitQty() {
    return this.priceTable?.weight?.quantity;
  }

  get normalizedCategories() {
    return Array.from(new Set((this.categories || []).flatMap(it => (it.tree || []).map(jt => String(jt._id)))));
  }

  weightToPrice(weight: number) {
    // return this.weightToQuantity(weight) * this.price;
    return 0;
  }

  priceToWeight(price: number) {
    return 0;
    // return this.quantityToWeight(price / this.price);
  }

  weightToQuantity(weight: number) {
    const qty = this.weightUnitQty || 1;
    return weight / qty;
  }

  quantityToWeight(quantity: number) {
    const qty = this.weightUnitQty || 1;
    return quantity * qty;
  }
}

export class ProductPriceInfo extends dbMixin<ProductSearchType>()("name", "pricing", "originalPrice") {
  constructor(public context: PosContext, product: ProductSearchType) {
    super(product);
  }

  get priceLines() {
    return _.orderBy(
      this.pricing,
      [p => (p.tag ? 1 : 0), p => p.conditions?.quantity, p => (p.conditions?.rank ? 0 : 1000)],
      ["asc", "asc", "desc"],
    );
  }

  get generalPrice() {
    return this.priceLines[0]?.min?.price;
  }
}

export class ProductGroupInfo extends dbMixin<ProductGroupType>()("name", "images", "specs") {
  constructor(public context: PosContext, product: ProductGroupType) {
    super(product);
  }

  async getPriceInfo() {
    const price = (
      await this.context.$feathers.service("shop/product/searches").find({
        query: {
          shop: this.context.$shop._id,
          productGroup: this.item._id,
          $paginate: false,
        },
        paginate: false,
      })
    )[0];

    if (!price) return null;
    return new ProductPriceInfo(this.context, price);
  }
}
