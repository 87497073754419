import { DataTableHeader, EditorField, SearchField, ImportOptions, NormalizedRole } from "./defs";
import type {
  SchemaDefParamsService,
  SchemaDefJson,
  SchemaDefParams,
  SchemaFieldJson,
  SchemaTypeFullJson,
  EditorAddonField,
  EditorConfig as DBEditorConfig,
} from "@db/schema";
import type { SchemaHelper } from "./index";
import expressions from "angular-expressions";

import { EditorFieldOptions } from "./defs";

import {
  isEnum,
  isSortable,
  isTranslate,
  isTranslateField,
  lookupType,
  resolveField,
  resolveRoot,
  getNameField,
  getNameFields,
  defHeaders,
  getSearchColor,
  readonlyHeaders,
  parseEditor,
  getDefaultHeaders,
  compileProps,
  getHeaderFields,
  getHeaderFieldsByName,
  convertFieldToSearch,
} from "./utils";

import _ from "lodash";

export class EditorConfig {
  group?: string;
  groupIcon?: string;
  // page name
  name?: string;
  // service path
  service?: string;
  path?: string;
  // page url
  rootPath?: string;
  icon?: string;
  // table headers
  headers?: DataTableHeader[];
  extraHeaders?: DataTableHeader[] = [];
  // editor fields
  fields?: EditorField[];
  // side panel fields
  sideFields?: EditorField[];
  // expand panel fields
  expands?: EditorField[] = [];
  // CURD
  create?: boolean;
  patch?: boolean;
  remove?: boolean;
  export?: boolean;
  import?: boolean;
  clone?: boolean;
  softDelete?: boolean;
  // toolbar buttons
  actions: {
    icon: string;
    altText: string;
    action: string;
    component: string;
  }[];
  // search fields
  _searchFields: EditorField[] = [];
  searchFields: SearchField[];
  searchPin?: string[];
  defaultSearch?: any[];
  // text search fields
  textFields: string[];
  // multi lang text search fields
  translateTextFields?: string[];
  order?: number;
  filter?: Record<string, any>;
  menu?: boolean;
  defaultSort?: string;
  defaultSortDesc?: boolean;
  defaultValue: any;
  linkedTable?: {
    table?: string;
    service?: string;
    field?: string;
    inheritFields?: string[];
  };
  importOptions?: ImportOptions;
  appendRow?: string;
  paginate: boolean;
  roles?: NormalizedRole[];
  acl?: string;
  importChunkSize?: number;
  tp: string;
  nameField?: SchemaFieldJson;

  help?: string;
  selectorFilter?: boolean;
  selectorShowAll?: boolean;

  constructor(
    public parent: SchemaHelper,
    public serviceCfg: SchemaDefParamsService,
    public item: SchemaDefJson,
    public cfg: DBEditorConfig,
    public selector?: string,
    public replaceRoot?: string,
  ) {
    const { path, paginate } = serviceCfg;
    this.tp = "pages." + (cfg.path || path) + ".";

    this.name = this.useStringId(cfg.stringId ? cfg.stringId : cfg.name ? this.tp + cfg.name : this.tp + "$");

    this.filter = cfg.filter || {};
    this.icon = cfg.icon || "";
    this.group = cfg.group || "";
    this.service = cfg.service || path;
    this.path = path;
    this.rootPath = this.parent.resolveRootPath(cfg, serviceCfg);
    this.export = cfg.export ?? this.parent.defaultExport;
    this.import = cfg.readOnly ? false : cfg.import ?? this.parent.defaultImport;
    this.create = cfg.readOnly ? false : cfg.create ?? this.parent.defaultCreate;
    this.patch = cfg.readOnly ? false : cfg.patch ?? this.parent.defaultPatch;
    this.remove = cfg.readOnly ? false : cfg.remove ?? this.parent.defaultRemove;
    this.clone = cfg.readOnly ? false : cfg.clone ?? cfg.create ?? this.parent.defaultClone;
    this.softDelete = cfg.softDelete ?? false;
    this.help = cfg.help ?? null;

    if (this.selector && this.selector.startsWith("!")) {
      this.selector = this.selector.substring(1);
      this.selectorFilter = true;
    }

    if (this.selector && this.selector.startsWith("*")) {
      this.selector = this.selector.substring(1);
      this.selectorShowAll = true;
    }

    this.actions = _.map(cfg.actions, (action) => ({
      altText: this.tp + action.name,
      icon: action.icon,
      action: this.tp + action.name,
      component: action.component,
    }));
    this.order = cfg.order ?? 9999;
    this.menu = cfg.menu ?? true;

    this.linkedTable = cfg.linkedTable;
    this.importOptions = cfg.importOptions || {};
    this.appendRow = cfg.appendRow;
    this.paginate = paginate;
    this.roles = this.parent.normalizedRoles(cfg.roles);
    this.acl = cfg.acl;
    this.searchPin = cfg.searchPin;
    this.defaultSearch = cfg.defaultSearch;
    this.importChunkSize = cfg.importChunkSize;
    this.nameField = getNameField(item);

    const headerFields = getDefaultHeaders(cfg, item);
    this.headers = headerFields.map((it) => this.getHeader(it)).filter((it) => !!it);
    if (cfg.extraHeaders) {
      cfg.extraHeaders
        .map((headerPath) => {
          const field = resolveField(item, headerPath, true);
          return field;
        })
        .filter((it) => !!it)
        .forEach((it) => {
          this._searchFields.push(this.convertField(it));
        });
    }

    let jsonFields = item?.schema?.fields || [];

    if (this.replaceRoot) {
      const root = resolveRoot(item, this.replaceRoot);
      if (root.path) this.tp += root.path + ".";
      if (!root) {
        jsonFields = [];
      }

      jsonFields =
        typeof root.type === "object" && root.type.type === "object" ? root.type.fields : [root];
    }

    this.fields = jsonFields
      .map((it) => this.convertField(it, undefined, true))
      .filter((it) => !!it);
    if (cfg.sideSelector) {
      this.selector = cfg.sideSelector;
      this.selectorFilter = true;
      this.sideFields = jsonFields.map((it) => this.convertField(it)).filter((it) => !!it);
    }
    this.addAddonFields(this.fields, cfg.edit);
    this.addAddonFields(this.expands, cfg.expand);

    this.updateDefaultSort();
    this.updateDefaultValue();

    this.updateSearchFields();
  }

  // get table header definition of a given field
  getHeader(
    it: SchemaFieldJson & { path?: string; headerPath?: string[] },
    editor?: EditorFieldOptions,
    ctp?: string,
  ) {
    if (!editor && it.params?.$editor) {
      editor = parseEditor(it.params?.$editor);
      if (this.selector && editor[this.selector]) {
        editor = editor[this.selector] as EditorFieldOptions;
      }
    }

    const type = lookupType(it.type);
    let header: DataTableHeader = {
      value: it.path || it.name,
      headerValue: it.headerPath || it.path || it.name,
      sortable: isSortable(type),
      multiple: it.headerPath && it.headerPath.includes("*"),
    };
    if (editor?.hideEmpty) {
      header.hideEmpty = true;
    }
    let needSuffix = false;

    switch (type) {
      case "array":
        if (isTranslate(it.type)) {
        } else if (typeof it.type === "object") {
          const itype = it.type.itemType;
          switch (lookupType(itype)) {
            case "object": {
              if (typeof itype === "object") {
                needSuffix = true;
              }
              break;
            }
          }
        }
        break;
      case "object": {
        if (typeof it.type === "object") {
          needSuffix = true;
        }
        break;
      }
    }

    const text = this.useStringId(this.getFieldName(it, needSuffix, ctp, editor));
    header.text = text;

    if (editor) {
      if (editor.sortField) {
        header.sortField = editor.sortField;
        header.sortable = true;
      }
      if (editor.type) {
        const customType = this.parent.customTypes[editor.type];
        if (customType) {
          if (customType.format) {
            header.format = customType.format;
            if(customType.header) {
              Object.assign(header, customType.header);
            }
            return header;
          } else if (customType.fromDb) {
            header.format = customType.fromDb;
          }
        }
      }
      if (editor.format) {
        header.format = editor.format;
        if (editor.headerValue !== undefined) header.headerValue = editor.headerValue;
        return header;
      }
    }

    switch (type) {
      case "object": {
        if (editor?.nameFields) {
          header.type = "multi";
          header.inner = [];
          const nameFields = Array.isArray(editor.nameFields)
            ? editor.nameFields
            : [editor.nameFields];
          for (let path of nameFields) {
            const field = resolveField({ schema: it.type } as any, path, true);
            if (!field) continue;
            const sub = this.getHeader(
              {
                ...field,
                path: header.value + "." + (field.path || field.name),
              },
              undefined,
            );
            if (!sub) continue;
            sub.value = path;
            sub.headerValue = path;
            header.inner.push(sub);
          }
        }
        break;
      }
      case "string": {
        if (isEnum(it)) {
          header.format = "table-enum";
          let rootName =
            defHeaders.indexOf(it.name) === -1
              ? this.getFieldName(it, false, ctp, editor)
              : `${it.name}`;
          if (!rootName.startsWith("enum.")) rootName = "enum." + rootName;
          if (rootName.endsWith(".$")) rootName = rootName.slice(0, -2);

          header.enumList = _.fromPairs(_.map(it.params?.enum, (f) => [f, `${rootName}.${f}`]));
          for (let v of Object.values(header.enumList)) this.useStringId(v);
        }
        break;
      }
      case "id": {
        const path = this.parent.getRefPath(it);
        const table = this.parent.getRefTable(it);
        if (path && table) {
          if (path === "attachments") {
            if (it.params?.fileType === "image") {
              header.type = "thumb";
            } else {
              return null;
            }
          } else {
            header.source = path;
            header.linkSource = this.parent.getEditorPath(path) ?? null;
            if (!header.linkSource) header.noLink = true;
            else header.linkSource = header.linkSource.substring(1);
            header.trailingSlash = false;

            let overridedFormat = false;

            if (it.params?.$editor) {
              let editor = parseEditor(it.params?.$editor);
              if (this.selector && editor[this.selector]) {
                editor = editor[this.selector] as EditorFieldOptions;
              }

              header.limit = editor.headerLimit;
              header.unique = editor.headerUnique;

              if (editor.objectFormat) {
                header.format = editor.objectFormat;
                header.path = null;
                overridedFormat = true;
              }
            }

            if (!overridedFormat) {
              let headers: SchemaFieldJson[];
              if (editor?.nameFields) {
                const nameFields = Array.isArray(editor.nameFields)
                  ? editor.nameFields
                  : editor.nameFields.split(",");
                headers = getHeaderFieldsByName(table, nameFields);
              }
              if (!headers) {
                headers = getHeaderFields(table);
              }
              if (headers.length) {
                header.path = null;
                header.type = "multi";
                header.inner = headers.map((header) => this.getHeader(header));
              } else {
                const nameField = getNameField(table);
                if (nameField) {
                  header.path = nameField.name;
                  if (isTranslate(nameField.type)) {
                    header.format = "table-t";
                  }
                }

                const nameFields = getNameFields(table);
                if (nameFields?.length) {
                  header.paths = nameFields.map((nameField) => {
                    return {
                      path: nameField.name,
                      format: isTranslate(nameField.type) ? "table-t" : undefined,
                    };
                  });
                }
              }
            }
          }
        }
        break;
      }
      case "date": {
        header.format = it.params?.dateOnly ?? false ? "table-date-only" : "table-date";
        break;
      }
      case "array":
        if (isTranslate(it.type)) {
          header.format = "table-t";
          header.sortField = header.path + ".value";
        } else if (typeof it.type === "object") {
          const itype = it.type.itemType;
          Object.assign(
            header,
            this.getHeader(
              {
                ...it,
                params: <any>it.type.opts,
                type: itype,
              },
              editor,
            ),
            {
              multiple: true,
            },
          );
        }
        break;
      case "boolean":
        header.format = "table-b";
        break;
    }

    return header;
  }

  getFieldName(
    it: SchemaFieldJson & { path?: string },
    withSuffix = false,
    ctp?: string,
    editor?: EditorFieldOptions,
  ) {
    ctp = ctp ?? this.tp;
    if (ctp.endsWith(".$.")) ctp = ctp.slice(0, -3) + ".";

    const name = editor?.name ?? it.path ?? it.name;
    if(editor?.stringId) {
      return editor.stringId;
    }

    const matchedRule = this.parent.matchTranslateRule(ctp, name);
    if (matchedRule) return matchedRule;

    const type = lookupType(it.type);
    const useBasic =
      name === "modifiedBy" ||
      type === "string" ||
      type === "number" ||
      type === "boolean" ||
      type === "date" ||
      (type === "array" && isTranslate(it.type));
    let suffix = withSuffix ? ".$" : "";
    return !useBasic || defHeaders.indexOf(name) === -1 ? ctp + name + suffix : "basic." + name;
  }

  // get editor definition of a given field
  convertField(field: SchemaFieldJson & { path?: string }, ctp?: string, root?: boolean, opts?: { selectorFilter?: boolean, selectorShowAll?: boolean }): EditorField {
    ctp = ctp ?? this.tp;
    let defaultValue = field.params?.default;
    const type = lookupType(field.type);

    let editorStr =
      field.params?.$editor ||
      ((field.type as SchemaTypeFullJson)?.itemType as SchemaTypeFullJson)?.params?.$editor ||
      (field.type as SchemaTypeFullJson)?.opts?.$editor;
    if (!editorStr && field.type && typeof field.type === "object")
      editorStr = field.type.params?.$editor;

    const selectorFilter = opts?.selectorFilter ?? this.selectorFilter;
    const selectorShowAll = opts?.selectorShowAll ?? this.selectorShowAll;

    let editor = parseEditor(editorStr);
    if (this.selector) {
      if (editor[this.selector]) {
        editor = editor[this.selector] as EditorFieldOptions;
      } else if (selectorFilter) {
        // console.log(field.name, field, editor);
        return null;
      }
    }

    if (!selectorShowAll && editor.hidden && !editor.search) {
      return null;
    }

    const props: any = {};
    let component = "";
    let nameField = "label";
    let inner: EditorField[];
    let defaultSlot: EditorField[];
    let innerPrimitive: boolean;
    props.outlined = true;
    props.ripple = false;
    props.clearable = true;

    let needSuffix = false;
    const innerTP = this.getFieldName(field, false, ctp, editor);

    switch (type) {
      case "boolean":
        component = "editor-checkbox";
        nameField = "text";
        break;
      case "number":
      case "string":
        if (isEnum(field)) {
          component =
            (field.params?.enum?.length > 20 && !editor.props?.picker) || editor.props?.list
              ? "editor-object-picker-list"
              : "editor-object-picker-new";

          delete props.clearable;

          let rootName = innerTP;
          if (rootName.startsWith("basic.")) rootName = rootName.slice(6);
          if (!rootName.startsWith("enum.")) rootName = "enum." + rootName;
          if (rootName.endsWith(".$")) rootName = rootName.slice(0, -2);

          const hasNull = field.params?.enum?.includes?.(null);

          props.items = (field.params?.enum || []).filter(it => it !== null).map((v) => ({
            _id: v,
            name: { $t: rootName + "." + v },
          }));
          props.translate = true;
          props.name = [{ field: "name", translate: true }];
          if(!hasNull) {
            props.clearable = false;
          }
        } else {
          component = "editor-text-field";
          if (type === "number") {
            props.type = "number";
          }
        }
        break;
      case "date":
        component = "editor-date-picker";
        props["date-time"] = !(field.params?.dateOnly ?? false);
        break;
      case "id":
        const ref = field.params?.ref;
        if (ref === "Attachment") {
          props.attachment = true;
          props.attachmentId = true;
          component =
            field.params?.fileType === "image" || editor?.fileType === "image"
              ? "image-picker"
              : "editor-file-picker";
        } else {
          const refTable = this.parent.getRefTable(field);
          const path = this.parent.getRefPath(field);
          if (path) {
            component = editor?.props?.picker
              ? "editor-object-picker-new"
              : "editor-object-picker-list";
            props.path = path;
            const nameField = getNameField(refTable);
            const nameFields = getNameFields(refTable);

            const extraFields = (refTable.schema?.fields || []).filter(
              (it) => it.name !== nameField?.name && !nameFields.find((f) => f.name === it.name),
            );

            props.fields = extraFields
              .filter(
                (it) => (lookupType(it.type) === "string" && !isEnum(it)) || isTranslate(it.type),
              )
              .map((it) => convertFieldToSearch(it));

            if (field.params.filter) props.query = field.params.filter;
            if (nameFields?.length) {
              props.name = nameFields.map((it) => convertFieldToSearch(it));
            } else if (nameField) {
              props.name = [convertFieldToSearch(nameField)];
              props.createNameField = convertFieldToSearch(nameField);
            }
          } else {
            component = "editor-text-field";
          }
        }
        break;
      case "array": {
        defaultValue = [];
        if (isTranslate(field.type)) {
          component = "editor-translate-box";
          break;
        } else if (isTranslateField(field.type)) {
          component = "editor-translate-item";
          // TODO
          needSuffix = true;
          debugger;
          break;
        } else if (typeof field.type === "object") {
          const itype = field.type.itemType;
          switch (lookupType(itype)) {
            case "object": {
              component = "editor-list";
              if (typeof itype === "object") {
                needSuffix = true;
                inner = _.map(itype.fields, (f) => this.convertField(f, innerTP + ".")).filter(
                  (it) => !!it,
                );
                props.itemFields = inner;
                if (!props.default) {
                  props.default = Object.fromEntries(inner.map((it) => [it.path, it.defaultValue]));
                }
              }
              break;
            }
            default: {
              // try to multiple
              const f = this.convertField(
                {
                  ...field,
                  params: <any>field.type.opts,
                  type: itype,
                },
                ctp,
              );
              if (!f) return null;
              if (f.component === "editor-file-picker" || f.component === "image-picker") {
                f.component = "editor-uploader";
                f.colWidthRem = 12.5;
              } else if (f.component === "editor-text-field") {
                f.component = "editor-combobox";
              } else if (f.component === "editor-translate-box") {
                component = "editor-list";
                props.default = [];
                inner = [f];
                f.path = "item";
                f.rootPath = "edit";
                break;
              } else if (f.component === "editor-date-picker") {
                component = "editor-list";
                props["date-time"] = !(field.params?.dateOnly ?? false);
                inner = [f];
                f.path = "item";
                f.rootPath = "edit";
                props["default"] = Date;
                innerPrimitive = true;
                break;
              }
              f.props.multiple = true;
              if (f.header) f.header.multiple = true;
              if (root) {
                this._searchFields.push(f);
              }
              return f;
            }
          }

          break;
        }
        return null;
      }
      case "object": {
        if (typeof field.type === "object") {
          needSuffix = true;
          inner = _.map(field.type.fields, (f) => this.convertField(f, innerTP + ".")).filter(
            (it) => !!it,
          );
        }
        if (inner) {
          defaultValue = _.merge(
            {},
            ...inner.map((it) => (it.defaultValue ? { [it.path]: it.defaultValue } : {})),
          );
        }
        component = editor?.props?.dateObject ? "editor-date-object" : "editor-group-object";
        break;
      }
      case "buffer": {
        component = "editor-file-picker";
        props.type = "buffer";
        break;
      }
      default:
        console.warn("No suitable editor for", type);
        return null;
    }

    {
      const name = this.useStringId(this.getFieldName(field, needSuffix, ctp, editor));

      if (readonlyHeaders.indexOf(field.name) !== -1) {
        props.readonly = true;
      }

      const result: EditorField = {
        component,
        name,
        nameField,
        path: field.path || field.name,
        props,
        schema: field,
        inner,
        innerPrimitive,
        default: defaultSlot,
        defaultValue,
        type,
      };

      if (type === "number") {
        result.colWidthRem = 8;
      } else if (type === "boolean") {
        result.colWidthRem = 5;
      }

      if (editor.sizes) {
        result.props.sizes = editor.sizes;
      }

      if (editor.type) {
        const type = this.parent.customTypes[editor.type];
        if (type) {
          if (type.component) {
            Object.defineProperty(result, "component", {
              get() {
                return type.component;
              },
              enumerable: false,
            });
          }

          result.customType = type;
          if (type.colWidthRem) {
            result.colWidthRem = type.colWidthRem;
          }
          if (type.props) {
            compileProps(result, type.props);
          }
        } else {
          console.warn("Failed to resolve type", editor.type);
        }
      }

      if (editor.props) {
        compileProps(result, editor.props);
      }

      result.optional = editor.optional;
      result.sort = editor.sort;
      result.gp = editor.gp;
      result.group = editor.group;
      if (editor.cond) result.cond = expressions.compile(editor.cond);
      if (editor.props?.multiLine && result.component === "editor-text-field") {
        result.component = "editor-text-field";
        result.props.multiLine = true;
      }

      if (result.component === "editor-list" && !result.inner.filter((it) => !!it).length) {
        return null;
      }

      if(component === 'image-picker' && props.mini) {
        result.colWidthRem = 4;
      }

      if (editor.group?.preview) {
        result.header = this.getHeader(field, editor, ctp);
      }

      if (result?.props?.readonly) {
        result.props.clearable = false;
      }

      if (result && result.gp) {
        result.group = {
          name: ctp + "groups." + result.gp,
          ...(result.group || {}),
        };
      }

      if (root) {
        this._searchFields.push(result);
      }

      if (!selectorShowAll && editor.hidden) {
        return null;
      }

      return result;
    }
  }

  useStringId(str: string) {
    this.parent.useStringId(str);
    return str;
  }

  // append addons to editor or expand panel
  addAddonFields(list: EditorField[], items: readonly EditorAddonField[]) {
    if (!items) return;
    let prependIdx = 0;
    for (let field of items) {
      let props: any = field.props;
      let dynamic = false;
      const compiled = _.mapValues(field.props, (v) => {
        if (typeof v === "string" && v.startsWith("$")) {
          dynamic = true;
          return expressions.compile(v.substring(1));
        } else {
          return v;
        }
      });
      let propsFunc: (scope: any) => Record<string, any>;

      if (dynamic) {
        props = {};
        propsFunc = (scope) =>
          _.mapValues(compiled, (v) => (typeof v === "function" ? v(scope) : v));
      }
      const e: EditorField = {
        component: field.component,
        props: props,
        propsFunc,
        cond: field.cond ? expressions.compile(field.cond) : null,
        type: null,
      };
      if (field.prepend) {
        list.splice(prependIdx++, 0, e);
      } else {
        list.push(e);
      }
    }
  }

  updateDefaultSort() {
    const cfg = this.cfg;
    // find default sort
    let defaultSort: string = null;
    let defaultSortDesc = false;

    const sortables = this.headers.filter((it) => it.sortable);
    const dateFields = _.sortBy(
      sortables.filter((it) => it.format === "table-date"),
      (f) => (f.value.match(/create/gi) ? 0 : f.value.match(/mod/gi) ? 1 : 2),
    );
    if (cfg.defaultSort) {
      defaultSort = cfg.defaultSort;
      defaultSortDesc = cfg.defaultSortDesc ?? false;
    } else if (dateFields.length) {
      defaultSort = dateFields[0].sortField || dateFields[0].value;
      defaultSortDesc = true;
    } else if (sortables.length) {
      defaultSort = cfg.defaultSort ?? (sortables[0].sortField || sortables[0].value);
      defaultSortDesc = cfg.defaultSortDesc ?? false;
    }
    this.defaultSort = defaultSort;
    this.defaultSortDesc = defaultSortDesc;
  }

  updateDefaultValue() {
    // gather default values
    let defaultValue = {};
    for (let field of this.fields) {
      defaultValue[field.path] = field.defaultValue;
    }
    this.defaultValue = defaultValue;
  }

  updateSearchFields() {
    // convert editor fields to search fields
    const searchFields: SearchField[] = [];
    const textFields: string[] = [];
    const translateTextFields: string[] = [];

    const searchPin = new Set<string>(this.cfg.searchPin ?? []);
    for (let field of this.fields) {
      searchPin.delete(field.path);
    }

    const searchEdit = this._searchFields.slice();
    for (let resolveSearch of searchPin) {
      const field = resolveField(this.item, resolveSearch, true);
      if (!field) continue;
      const e = this.convertField(field);
      if (!e) continue;
      searchEdit.push(e);
    }

    for (let field of searchEdit) {
      if (!field) continue;
      let conds: string[] = [];
      let path = field.path;
      const f = _.cloneDeep(field);
      f.props.readonly = false;
      if (field.customType?.searchComponent) {
        Object.defineProperty(f, "component", {
          get() {
            return field.customType?.searchComponent;
          },
          enumerable: false,
        });
        f.customSearch = true;
      } else {
        switch (f.component) {
          case "editor-checkbox":
            conds = ["eq", "ne"];
            break;
          case "editor-text-field":
            if (lookupType(f.schema.type) === "id") continue;
            if (f.props.type === "number") {
              conds = ["lt", "gt", "lte", "gte", "eq", "ne", "inRange"];
            } else {
              conds = ["contains", "notContains"];
              textFields.push(f.path);
            }
            break;
  
          case "editor-date-picker": {
            conds = ["inRange", "lt", "gt", "lte", "gte", "eq", "ne"];
            break;
          }
  
          case "editor-object-picker":
          case "editor-object-picker-new":
          case "editor-object-picker-list":
            conds = ["in", "nin"];
            f.props.multiple = true;
            break;
  
          case "editor-translate-box":
            conds = ["contains", "notContains"];
            f.component = "editor-text-field";
            path = path + ".value";
            translateTextFields.push(f.path);
            break;
  
          case "editor-file-picker":
          case "image-picker":
          case "editor-uploader":
            continue;
  
          default:
            continue;
        }
      }

      f.props.clearable = true;
      f.props.hideDetails = true;
      const header = this.getHeader(<any>f.schema);
      if (header) {
        if (f.props.multiple) header.multiple = true;
        if (!this.headers.find((it) => it.value === header.value)) {
          this.extraHeaders.push({ ...header });
        }
        header.value = "";
        header.headerValue = [];

        searchFields.push({
          name: field.name,
          path: path,
          color: getSearchColor(field.path),
          edit: f,
          conds,
          cond: conds[0],
          value1: null,
          value2: null,
          header,
        });
      }
    }

    this.searchFields = searchFields;
    this.textFields = textFields;
    this.translateTextFields = translateTextFields;
  }
}
