
import type { Order } from "@common/pos";
import { Component, Prop, Vue, Watch, mixins, FindType } from "@feathers-client";
import _ from "lodash";
import type { LineItem } from "@common/posLineItem";
import { checkID, getID } from "@feathers-client";
import type { OrderProductType } from "@common/common";
import { ProductInfo } from "@common/product";

@Component
export default class OrderSystemProductLine extends Vue {
  get priceModifyPermission() {
    return !(this.$store.getters.role === "pos" && !this.$store.getters.permissionDict.price);
  }

  get item() {
    return this.cart || this.line;
  }

  get stockStatus() {
    return "hidden";
  }

  get itemName() {
    return this.product?.fullName ?? this.product?.item?.fullName ?? this.product?.name ?? this.item?.name ?? "";
  }

  get selected() {
    return ((this.item as any)["selected"] as boolean) || false;
  }
  set selected(v: boolean) {
    Vue.set(this.item, "selected", v);
  }

  @Prop(Boolean)
  isPaying: boolean;

  @Prop()
  cart: LineItem;

  @Prop()
  line: OrderProductType;

  @Prop({ type: Boolean })
  selecting: boolean;

  @Prop(Boolean)
  noMenu: boolean;

  get discounts() {
    return (this.cart?.id && this.session?.discountCompute?.lineResults?.[this?.cart?.id]) ?? [];
  }

  async openProductMenu(cart: LineItem, showOptions: boolean) {
    if (cart == this.cart) {
      if (!this.$el) {
        await Vue.nextTick();
      }
      if (this.$el) {
        // check if in view
        let scrollable = this.$el.parentElement;
        while (scrollable && !scrollable.classList.contains("scrollable")) {
          scrollable = scrollable.parentElement;
        }
        if (!scrollable) return;
        if (scrollable) {
          const rect = this.$el.getBoundingClientRect();
          const scrollRect = scrollable.getBoundingClientRect();

          if (rect.top < scrollRect.top || rect.bottom + 20 > scrollRect.bottom) {
            this.$el.scrollIntoView({ behavior: "smooth" });
            await new Promise<void>(resolve => setTimeout(resolve, 300));
          }
        }
      }

      this.menu = true;
    }
  }

  @Prop()
  session: Order;

  get hasNextOptions() {
    if (!this.cart) return null;
    const list = this.session.products;
    let cartIndex = list.indexOf(this.cart);
    if (cartIndex === -1) return null;

    for (let i = cartIndex + 1; i < list.length; i++) {
      // TODO: fix options
      //if(list[i].pendingOptions) return list[i]
    }
    return null;
  }

  currentPage = "";

  menu = false;
  shortpress() {
    this.$emit("click");
    if (this.selecting) {
      this.selected = !this.selected;
      return;
    }
    if (this.noMenu || this.$pos.kioskMode) return;
    this.menu = !this.menu;
  }

  cacheProduct: FindType<"shop/product/skus"> = null;
  _fetchProduct: Promise<void>;
  fetchProduct() {
    if (this.$store.state.cast) return null;
    if (!this._fetchProduct) {
      this._fetchProduct = this.fetchProductInner();
    }
    return this.cacheProduct;
  }

  async fetchProductInner() {
    this.cacheProduct = (
      await this.$feathers.service("shop/product/skus").find({
        query: {
          _id: this.line.sku,
          $paginate: false,
        },
        paginate: false,
      })
    )[0];
  }

  get product(): Partial<FindType<"shop/product/skus">> & Partial<ProductInfo> {
    return this.cart ? this.cart.sku : this.fetchProduct();
  }

  get orderReadOnly() {
    return this.session?.status !== "pending" || this.isPaying;
  }

  get cancellable() {
    return true;
  }

  get thumb() {
    return (this.item as any).image
      ? this.$thumb((this.item as any).image)
      : (this.product?.images?.[0] && this.$thumb(this.product.images[0])) || require("~/assets/images/logo.png");
  }

  get originalPrice() {
    if (!this.item || !this.item.manualDiscountInt) return;
    if (this.item.priceLine) {
      const price = this.item.overridePriceInt ? this.item.overridePriceInt : this.item.priceLine?.priceInt;
      return this.$price(
        this.item.priceLine?.flatPrice
          ? price
          : price * this.item.quantity,
      );
    }
    return this.$price(this.item.totalInt + this.item.manualDiscountInt * this.item.quantity);
  }

  get totalPrice() {
    if (!this.item) return;
    return this.$price(this.item.totalInt);
  }

  moveFromCart() {
    this.$emit("remove");
  }

  async cancelProduct() {
    this.moveFromCart();
  }

  navigateNext() {
    if (this.hasNextOptions) {
      this.menu = false;
      this.$root.$emit("openProductMenu", this.hasNextOptions, true);
    }
  }

  @Watch("cart.quantity")
  onQuantityChange() {
    if (this.cart.quantity <= 0) {
      this.$emit("remove");
    }
  }

  editItem() {
    this.session.editing = true;
    if (this.cart) {
      this.cart.editing = true;
    }
  }

  async manualAdjustment(item: LineItem) {
    const result: LineItem = await this.$openDialog(
      import("./manualAdjustDialog.vue"),
      {
        item,
      },
      {
        maxWidth: "600px",
      },
    );

    if (result && this.session._id) {
      this.session.beginEdit();
    }

    this.session.saveCart();
  }

  formatTime(slotSize: "day" | "time", date: string, time: number) {
    if (slotSize === "day") {
      return date;
    }
    const h = Math.floor(time / 60);
    const m = time % 60;
    return `${date} ${h.toString().padStart(2, "0")}:${m.toString().padStart(2, "0")}`;
  }

  async updateBooking(product: OrderProductType, status: "inProgress" | "complete" | "confirmed") {
    const c = await this.$openDialog(
      import("@feathers-client/components-internal/ConfirmDialog.vue"),
      {
        title:
          status === "confirmed"
            ? this.$t("booking.confirmBooking")
            : status === "inProgress"
            ? this.$t("booking.confirmCheckIn")
            : this.$t("booking.confirmCheckOut"),
      },
      {
        maxWidth: "400px",
      },
    );
    if (!c) return;

    const item = await this.$feathers.service("shop/orders/updateBooking").create({
      order: getID(this.session._id),
      product: product.id,
      status,
    });
    product.booking.status = status;
  }
}
