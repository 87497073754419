<script>
import _ from 'lodash'
import Vue from 'vue'
import { VList, VListGroup } from 'vuetify/lib'

export default {
    components: {
        VList,
        VListGroup,
    },
    props: {
        items: {
            type: Array,
            default: () => []
        },
        props: {
            type: Object,
            default: () => {}
        },
        keyPath: {
            type: String,
            default: ''
        },
        itemPath: {
            type: String,
            default: 'items',
        },
        container: {
            type: Function,
        },
        wrap: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            actives: {},
        }
    },
    render (_c) {
        const renderItem = (mitems) => (item, idx) => {
            const items = _.get(item, this.itemPath) || [];
            const key = this.keyPath ? item[this.keyPath] : idx;
            return (!this.container || this.container(item)) && items
                ? _c('v-list-group', { 
                    key: key,
                    model: {
                        value: !this.actives[key],
                        callback: ($$v) => {
                            Vue.set(this.actives, key, !$$v);
                        },
                        expression: `!actives[${key}]`
                    }
                }, [
                    _c('template', { slot: 'activator'}, [
                        this._t('item', null, this.wrap ? {item, idx, items: mitems} : item),
                    ]),
                    //this._t('item', null, { slot: 'activator', ...item }),
                    this._l(items, renderItem(items))
                ], 2)
                : this._t('item', null, this.wrap ? {item, idx, items: mitems} : item)
        }
        return this._c('v-list', this._b({}, 'v-list', this.props, false), [
            this._l(this.items, renderItem(this.items))
        ], 2)
    },
    name: 'list-tree'
}
</script>


