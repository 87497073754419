
import {
  Component,
  Prop,
  Vue,
  InjectReactive,
  ProvideReactive,
  Watch,
  Ref,
  getID,
  mixins,
} from "@feathers-client";
import {
  BatchEditorContext,
  BatchEditorConfig,
  NormalizedBatchEditorConfig,
} from "./editorContext";
import { getOptions } from "@feathers-client";
import { sumBy } from "lodash";
import BatchEditorRow from "./BatchEditorRow.vue";
import { EditorConfig, EditorField, EditorViewSetting } from "@schemaEditor/plugin";
// import MenuListPicker from "@feathers-client/components/MenuListPicker.vue";
import EditorObjectPickerList from "@schemaEditor/EditorObjectPickerList.vue";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import EditorSearchMenu from "@schemaEditor/EditorSearchMenu.vue";
import { loadDataStream } from "domore-table/exportExcel";
import { resolveField } from "@schemaEditor/plugin/utils";
import { escapeRegExp } from "@feathers-client";
// @ts-ignore
import type { SchemaDefJson } from "@mfeathers/db/schema";
import _ from "lodash";
import HeaderProvider from "domore-table/mixins/HeaderProvider";

@Component({
  components: {
    BatchEditorRow,
    EditorObjectPickerList,
    EditorTextField,
    EditorSearchMenu,
  },
})
export default class BatchEditor extends mixins(HeaderProvider) {
  @InjectReactive({ default: null })
  context: BatchEditorContext;
  mcontext: BatchEditorContext = null;

  showSearchMenu = false;

  @Prop()
  editFilter: any;

  query = {};

  @Prop()
  defaultRow: any;

  @Prop()
  subFields: (BatchEditorConfig | NormalizedBatchEditorConfig)[];

  serializedState: any = {};

  get self() {
    return this;
  }

  loadFinished = false;
  loadPaused = false;

  get totalWidth() {
    return sumBy(this.configFields, (f) => f.colWidthRem || 12.5) + 10;
  }

  @ProvideReactive("context")
  get finalContext() {
    return this.mcontext || this.context;
  }

  @ProvideReactive("feathers")
  // @ts-ignore
  get feathers() {
    return this.finalContext as any;
  }

  get $feathers() {
    return this.feathers;
  }

  get items() {
    if (!this.collection) return [];
    return this.showDeleted ? this.collection.deletedItems : this.collection.items;
  }

  async created() {
    const path = this.path;
    if (this.$store.state.settings?.batchEditorSettings) {
      this.setting = _.cloneDeep(
        this.$store.state.settings?.batchEditorSettings.find((it) => it.path === path)?.value || {},
      );
    } else if (localStorage["batchEditorSettings_" + path]) {
      try {
        this.setting = JSON.parse(localStorage["batchEditorSettings_" + path]);
      } catch (e) {}
    }

    if (!this.context) {
      this.mcontext = new BatchEditorContext(
        getOptions(this, {
          path,
        }),
      );
    }
    await this.$schemas.init();
    this.updateFilter();
    await this.finalContext.init();
  }

  saveViewSettings() {
    const path = this.path;
    this.$store.commit("SET_SETTINGS", {
      batchEditorSettings: (this.$store.state.settings?.batchEditorSettings || [])
        .filter((it) => it.path && it.path !== path)
        .concat([
          {
            path,
            value: _.cloneDeep(this.setting),
          },
        ]),
    });
    localStorage["batchEditorSettings_" + path] = JSON.stringify(this.setting);
  }

  setting: EditorViewSetting = {};

  mounted() {
    this.$schemas.onLocale((this as any).$i18n.locale || this.$store.state.locale);
    if (!this.nested) {
      this.$store.commit("SET_TITLE", {
        title: { $t: "basic.add" },
        fullPage: true,
        langMenu: true,
      });
    }
  }

  @Prop()
  path: string;

  @Prop()
  fields: string | string[];

  @Prop(Boolean)
  nested: boolean;

  @ProvideReactive("collection")
  get collection() {
    if (!this.finalContext) return null;
    return this.finalContext.service(this.path);
  }

  reactiveConfig: EditorConfig = null;

  get config() {
    return this.collection?.resolveConfig?.();
  }

  @Watch("config", { immediate: true })
  onConfig() {
    this.reactiveConfig = this.config;
  }

  get configFields() {
    if (this.setting?.headers?.length) {
      const cfg = this.config;
      if (!cfg) return null;
      return this.normalizeFieldArray(this.setting.headers, cfg.fields, cfg, cfg.item);
    }
    return this.defaultConfigFields;
  }

  get defaultConfigFields() {
    const cfg = this.config;
    if (!cfg) return null;
    if (!this.fields) {
      return cfg.fields;
    }
    return this.normalizeFieldArray(this.fields, cfg.fields, cfg, cfg.item);
  }

  get normalizedSubFields() {
    if (this.setting?.subFields?.length) {
      const cfg = this.config;
      if (!cfg) return null;
      return this.setting.subFields.map((it) => this.normalizeField(it, cfg)).filter((it) => !!it);
    }
    return this.defaultNormalizedSubFields;
  }

  get defaultNormalizedSubFields() {
    return (this.subFields || []).map((it) => this.normalizeField(it)).filter((it) => !!it);
  }

  get currentFilter() {
    const normalizedFilter: any = structuredClone(this.editFilter || {});
    Object.assign(normalizedFilter, this.query);
    return normalizedFilter;
  }

  get currentNew() {
    const normalizedNew: any = structuredClone(this.defaultRow || {});
    const cfg = this.config;
    for (let field of this.serializedState?.fields || []) {
      if (field.cond === "in") {
        const [normField] = this.normalizeFieldArray(field.path, cfg.fields, cfg, cfg.item);
        if (normField?.props?.multiple) {
          normalizedNew[field.path] = field.value1;
        } else {
          normalizedNew[field.path] = field.value1[0];
        }
      } else if (field.cond === "eq") {
        normalizedNew[field.path] = field.value1;
      }
    }
    return normalizedNew;
  }

  normalizeFieldArray(
    filterFields: string | (string | EditorField)[],
    originalFields: EditorField[],
    cfg: EditorConfig,
    schema: SchemaDefJson,
  ) {
    const fields =
      typeof filterFields === "string"
        ? filterFields.split(",")
        : Array.isArray(filterFields)
          ? filterFields
          : [];

    const cfgFieldsDict = Object.fromEntries(originalFields.map((it) => [it.path, it]));

    return fields
      .map((f) => {
        if (typeof f === "object") {
          return f;
        }
        let field = cfgFieldsDict[f];
        if (!field) {
          const json = resolveField(schema, f, true);
          if (!json) {
            console.warn(`Field ${f} not found in ${schema}`);
            return null;
          }
          field = cfg.convertField(json, undefined, undefined, {
            selectorFilter: false,
            selectorShowAll: true,
          });
          if (!field) return null;
        }
        return field;
      })
      .filter((it) => !!it);
  }

  normalizeField(
    it: BatchEditorConfig | NormalizedBatchEditorConfig,
    pconfig?: EditorConfig,
    arrayField?: EditorField,
  ): NormalizedBatchEditorConfig {
    if (!pconfig) {
      pconfig = this.config;
    }
    if ((it as any)._normalized) {
      return it as NormalizedBatchEditorConfig;
    } else {
      const cfg = it as BatchEditorConfig;
      if (!this.finalContext) return null;

      let field: EditorField;
      if (arrayField) {
        field = arrayField.inner?.find?.((it) => it.path === cfg.path);
      } else {
        field = pconfig?.fields?.find?.((it) => it.path === cfg.path);
      }
      if (!field) {
        console.warn(`Field ${cfg.path} not found in ${pconfig.path}`);
        return null;
      }

      if (field.component === "editor-list") {
        const inner = field.inner;
        return {
          _normalized: true,
          name: field.name,
          type: "array",
          path: cfg.path,
          multiple: true,
          collection: this.collection,
          config: pconfig,
          fields: this.normalizeFieldArray(cfg.fields, inner, pconfig, field.schema),
          subFields: (it.subFields || [])
            .map((it) => this.normalizeField(it, pconfig, field))
            .filter((it) => !!it),
          expanded: cfg.expanded,
        };
      } else {
        const collection = this.finalContext.service(field.props.path);
        const config = collection.resolveConfig();
        if (!config) return null;

        return {
          _normalized: true,
          name: field.name,
          type: "collection",
          path: cfg.path,
          multiple: field.props?.multiple,
          collection,
          config,
          fields: this.normalizeFieldArray(cfg.fields, config.fields, config, field.schema),
          subFields: (it.subFields || [])
            .map((it) => this.normalizeField(it, config))
            .filter((it) => !!it),
          expanded: cfg.expanded,
        };
      }
    }
  }

  @Ref()
  top: HTMLElement;

  @Ref()
  bottom: HTMLElement;

  async addRow() {
    this.loadPaused = true;
    const newItem = await this.collection.create(structuredClone(this.currentNew));
    this.bottom?.scrollIntoView?.({ behavior: "smooth" });
  }

  backToTop() {
    this.top?.scrollIntoView?.({ behavior: "smooth" });
  }

  async save() {
    if (
      await this.$openDialog(
        import("./BatchSaveDialog.vue"),
        {
          context: this.finalContext,
        },
        {
          maxWidth: "max(50vw,500px)",
        },
      )
    ) {
      this.$emit("saved");
    }
  }

  async deleteDraft() {
    await this.finalContext.deleteDraft();
  }

  async updateFilter() {
    if (!this.config) {
      return;
    }
    await this.$nextTick();
    this.$off("scroll-bottom");
    this.collection.purgeCleanItems({
      $not: {
        ...this.currentFilter,
      },
    });
    this.preload();
  }

  async preload() {
    this.loadFinished = false;
    this.loadPaused = false;

    let chunkSize = 10;
    let isInitial = true;
    let loadAll = false;
    for await (let item of loadDataStream(
      this,
      this.path,
      structuredClone({
        ...this.currentFilter,
      }),
      undefined,
      {
        limit: chunkSize,
        supportNoPaginate: false,
      },
    )) {
      for (let row of item) {
        if (!row._id) continue;
        this.collection.addCachedItem(getID(row), row);
      }
      if (isInitial) {
        await this.$nextTick();
        const isBottom = this.$el.scrollHeight - this.$el.scrollTop - this.$el.clientHeight < 1000;
        if (isBottom) {
          continue;
        } else {
          isInitial = false;
        }
      }
      if (loadAll) continue;
      if (
        await new Promise<boolean>((resolve) => {
          this.$once("scroll-bottom", resolve);
        })
      ) {
        loadAll = true;
      }
    }
    this.loadFinished = true;
    this.$emit("load-finished");
  }

  onScroll() {
    if (this.loadFinished || this.loadPaused) return;
    const el: HTMLElement = this.$el as any;
    if (el.scrollHeight - el.scrollTop - el.clientHeight < 1000) {
      this.$emit("scroll-bottom");
    }
  }

  resumeLoad() {
    this.loadPaused = false;
    this.$emit("scroll-bottom");
  }

  async showSettings() {
    await this.$openDialog(
      import("./FieldSetting.vue"),
      {
        editor: this,
        config: this.config,
      },
      {
        contentClass: "editor-dialog",
      },
    );
  }

  showDeleted = false;

  clickDeleted() {
    this.showDeleted = !this.showDeleted;
    if (this.showDeleted) {
      this.clearSelection();
      for (let item of this.collection.deletedItems) {
        item.selected = true;
      }
    }
  }

  clickDirty() {
    this.clearSelection();
    for (let item of this.collection.items) {
      if (item.dirty) {
        item.selected = true;
      }
    }
  }

  clickNew() {
    this.clearSelection();
    for (let item of this.collection.items) {
      if (item.new) {
        item.selected = true;
      }
    }
  }

  clearSelection() {
    for (let item of this.collection.items) {
      item.selected = false;
    }
    for (let item of this.collection.deletedItems) {
      item.selected = false;
    }
  }

  async restoreSelected() {
    if (
      !(await this.$confirm(
        this.$t("batch.confirmRestoreSelected", { items: this.collection.selectionSize }),
      ))
    )
      return;
    this.collection.restoreSelected();
  }

  async removeSelected() {
    if (
      !(await this.$confirm(
        this.$t("batch.confirmDeleteSelected", { items: this.collection.selectionSize }),
      ))
    )
      return;
    this.collection.removeSelected();
  }

  async undoSelected() {
    if (
      !(await this.$confirm(
        this.$t("batch.confirmUndoSelected", { items: this.collection.selectionSize }),
      ))
    )
      return;
    this.collection.undoSelected();
  }

  async batchEdit(field: EditorField) {
    if (!this.loadFinished) {
      if (await this.$confirm(this.$t("batch.confirmLoadAll"))) {
        await new Promise((resolve) => {
          this.$emit("scroll-bottom", true);
          this.$once("load-finished", resolve);
        });
      }
    }

    await this.$openDialog(
      import("./BatchEditorBatchDialog.vue"),
      {
        context: this.finalContext,
        items: this.items,
        field,
        editor: this,
      },
      {
        maxWidth: "max(50vw,500px)",
      },
    );
  }

  // TODO: use virtual list
}
