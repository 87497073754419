import _ from "lodash";
import { parseString } from "xml2js";
import { supported, request } from "pos-printer/utils/httpProxy";
import qs from "qs";
import { Component, Vue, isBBPOS } from "@feathers-client";
import { supported as appSupported, bringToTop, launch } from "pos-printer/utils/app";
import { interfaces, connect, isLocal, getAddresses } from "pos-printer/ports/socket";

export interface CallApiOpts {
  checkResponse?: boolean;
  tryLogin?: boolean;
  toTop?: boolean;
}

@Component
export default class BBPOS extends Vue {
  async callPOS<T = any>(params, posURL?: string, opts?: CallApiOpts) {
    const checkResponse = opts?.checkResponse ?? true;
    const tryLogin = opts?.tryLogin ?? true;
    const toTop = opts?.toTop ?? true;

    if (!(await supported())) {
      throw new Error(`${this.$t("bbpos.notSupported")}`);
    }
    const url = posURL ?? this.$pos.cashier.bbposUrl;
    if (!url) {
      throw new Error(`${this.$t("bbpos.noUrl")}`);
    }
    let resp: Awaited<ReturnType<typeof request>>;
    try {
      resp = await request(`${url}/pos?${qs.stringify(params)}`);
    } catch (e) {
      if (e.message.includes("java.net.ConnectException") && isBBPOS) {
        console.log("going to launch bbpos");
        // try launch bbpos app
        await launch("com.bbpos.www.payment_allinpay");
        await new Promise(resolve => setTimeout(resolve, 1000));
        await this.pollApi();
        return await this.callPOS(params, posURL, {
          checkResponse,
          tryLogin,
          toTop,
        });
      }
      throw e;
    }

    try {
      if (resp.statusCode !== 200) {
        throw new Error(resp.body);
      }
      const body: any = await new Promise((resolve, reject) =>
        parseString(resp.body, (err, data) => (err ? reject(err) : resolve(data))),
      );

      if (checkResponse) {
        const status = body?.WAPIResult?.status?.[0];
        const resultCode = body?.WAPIResult?.resultCode?.[0];
        const responseCode = body?.WAPIResult?.responseCode?.[0];
        const ErrorMessage = body?.WAPIResult?.ErrorMessage?.[0];

        if (ErrorMessage === "Device is Initializing" && isBBPOS) {
          await launch("com.bbpos.www.payment_allinpay");
          await this.pollApi();
          return await this.callPOS(params, posURL, {
            checkResponse,
            tryLogin,
            toTop,
          });
        }

        if (
          resultCode === "RESULT_UNKNOWN_MERCHANT" &&
          tryLogin &&
          this.$pos.cashier.bbposUser &&
          this.$pos.cashier.bbposPass
        ) {
          await this.doLogin();
          return await this.callPOS(params, posURL, {
            checkResponse,
            tryLogin,
            toTop,
          });
        }

        if (
          (status && status !== "SUCCESS") ||
          (resultCode && resultCode !== "RESULT_OK") ||
          (responseCode && responseCode !== "0000")
        ) {
          const err: Error & { status?: string; resultCode?: number; body?: any } = new Error(
            ErrorMessage || resultCode || status || "ERROR",
          );
          err.status = status;
          err.resultCode = resultCode;
          err.body = body;
          throw err;
        }
      }

      return body as T;
    } finally {
      if (toTop && isBBPOS) {
        try {
          await bringToTop();
        } catch (e) {
          console.warn(e);
        }
      }
    }
  }
  async cancelPOS() {
    try {
      await this.callPOS({
        transactionType: "CANCEL",
        isExternal: "true",
      });
      return true;
    } catch (e) {
      console.log("Cancel BBPOS Error: " + e.message);
      return false;
    }
  }

  async testPOS() {
    try {
      const body = await this.callPOS(
        {
          transactionType: "STATUS",
          isExternal: "true",
        },
        undefined,
        { checkResponse: false, toTop: false, tryLogin: false },
      );
      const status = body?.WAPIResult?.status?.[0];
      return status;
    } catch (e) {
      return false;
    }
  }

  async doLogin() {
    const body = await this.callPOS(
      {
        transactionType: "LOGIN",
        isExternal: "true",
        UserName: this.$pos.cashier.bbposUser,
        Password: this.$pos.cashier.bbposPass,
      },
      undefined,
      { checkResponse: true, toTop: false, tryLogin: false },
    );
  }

  async scanPOS(cb: (s: string) => void) {
    const faces = (await interfaces()).filter(isLocal);
    for (let face of faces) {
      const iter = getAddresses(face);

      await Promise.all(
        new Array(256).fill(null).map(async thread => {
          while (true) {
            const next = iter.next();
            if (next.done) return;
            const ip = next.value;
            if (ip === face.address) return;
            const u = `http://${ip}:8080`;
            try {
              await Promise.race([
                this.callPOS(
                  {
                    transactionType: "STATUS",
                    isExternal: "true",
                  },
                  u,
                  { checkResponse: false, toTop: true, tryLogin: false },
                ),
                new Promise((resolve, reject) => setTimeout(reject, 15 * 1000)),
              ]);

              cb(u);
            } catch (e) {
              // console.log(e);
            }
          }
        }),
      );
    }
  }

  async pollApi() {
    for (let i = 0; i < 30; i++) {
      if (await this.testPOS()) {
        return;
      }
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
    throw new Error("BBPOS App cannot be launched");
  }
}
