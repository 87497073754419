import { ns } from "../messageQueue";
import { EventEmitter } from "events";
import { getVersion } from "../nativeIntegrations";

export async function supported(): Promise<boolean | string[]> {
  if (!getVersion()) return Promise.resolve(false);
  try {
    return await Promise.race<boolean | string[]>([
      ns("app").call<boolean | string[]>("supported"),
      new Promise<boolean>(resolve => setTimeout(() => resolve(false), 1000)),
    ])
  } catch(e) {
    return false;
  }
}

export async function supportedOffline() {
  const list = await supported();
  if (list && Array.isArray(list) && list.includes("installOffline")) {
    return true;
  }
  return false;
}

export function model(): Promise<string> {
  return ns("app").call("model");
}

export function bringToTop(): Promise<boolean> {
  return ns("app").call("bringToTop");
}

export function launch(app: string): Promise<boolean> {
  return ns("app").call("launch", app);
}

export function setStatusBarHidden(hidden: boolean): Promise<boolean> {
  return ns("app").call("setStatusBarHidden", hidden);
}

export function getBattery(): Promise<{ level: number; charging: boolean }> {
  return ns("app").call("getBattery");
}

export async function on(event: string, cb: (...args: any[]) => void) {
  return ns("app").on(event, cb);
}

export async function off(handle: string) {
  return ns("app").off(handle);
}

export async function installOffline(domain?: string) {
  return ns("app").call("installOffline", domain);
}

export async function clearCache() {
  return ns("app").call("clearCache");
}

export async function getOfflineInfo(domain?: string): Promise<{
  files: string[];
  zip?: string;
  zipHash?: string;
  BUILD_INFO?: string;
}> {
  return ns("app").call("getOfflineInfo", domain);
}

export async function requestDrawOverlay() {
  return ns("app").call("requestDrawOverlay");
}
