

import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import DiscountAddonProduct from './DiscountAddonProduct.vue'

@Component({
    components: {
        DiscountAddonProduct,
    },
})
export default class DiscountAddonCategory extends Vue {

    @Prop()
    category: string

    categoryItem : any = null;
    products : any[] = [];

    get name() {
        return this.categoryItem ? (this as any).$td(this.categoryItem.name) : `${this.category}`;
    }

    async mounted() {
        try {
            if(!this.category) return;
            this.categoryItem = (await this.$feathers.service('categories').find({
                query: {
                    name: this.category,
                    $shop: true,
                }
            })).data[0];

            this.products = (await this.$feathers.service('products').find({
                query: {
                    normalizedCategories: this.category,
                    $shop: true,
                    $limit: 20,
                }
            })).data;
        } catch(e) {
        }
    }

}

