
import DatePicker from "~/components/DatePicker.vue";
import Payments from "~/pages/payments/index.vue";
import Shippings from "~/pages/shippings/index.vue";
import Printer from "~/mixins/Printer";
import { getInvoiceSequencer } from "~/plugins/printer/invoiceSequencer";

import { Component, Vue, Prop, mixins, Watch } from "nuxt-property-decorator";

import { FindPopRawType, FindType } from "@feathers-client";

import uuid from "uuid/v4";
import _ from "lodash";

import moment from "moment";
import Dialog from "@feathers-client/mixins/Dialog";

const orderPopulate = ["products.sku", "products.bundledSkus.sku", "discounts.gifts.sku", "payments"] as const;

@Component({
  components: {
    DatePicker,
    Payments,
    Shippings,
  },
})
export default class extends mixins(Printer("thermal", "thermal"), Dialog) {
  get paymentQuery() {
    return {
      order: this.order._id,
    };
  }

  get shipmentQuery() {
    return { "products.order": this.order._id };
  }

  @Prop()
  order: FindType<"shop/orders">;

  @Prop({
    type: Boolean,
    default: true,
  })
  addCancel: boolean;

  modifying = false;
  shipmentCompany = "";
  shipmentTracking = "";
  orderDetails: FindPopRawType<typeof orderPopulate, "shop/orders"> = null;

  get addShippingUrl() {
    return `/shippings/edit/?order=${this.order._id}`;
  }

  beforeMount() {
    this.load();
  }

  @Watch("order")
  async load() {
    if (!this.order?._id) return;
    this.orderDetails = await this.$feathers.service("shop/orders").get(this.order._id, {
      query: {
        $populate: orderPopulate,
      },
    });
    Object.assign(this.order, this.orderDetails);
  }

  async reload() {
    await this.load();
    (this.$refs?.payments as any)?.refresh?.();
    (this.$refs?.shippings as any)?.refresh?.();
  }

  get products() {
    return this.flattenProducts(this.orderDetails?.products ?? []);
  }

  get refundDisabled() {
    if (
      this.$store.getters.role === "pos" 
      // &&
      // moment().startOf("day").diff(moment(this.order.date).startOf("day"), "days", true) > 7
    ) {
      return true;
    }
    return false;
  }

  async sendShipment() {
    try {
      const order = this.order;
      this.modifying = true;
      const newOrder = await this.$feathers.service("shop/orders").patch(
        order._id,
        {
          status: "shipped",
          shipment: {
            id: uuid(),
            company: this.shipmentCompany,
            tracking: this.shipmentTracking,
          },
        },
        {
          query: {
            $shop: true,
          },
        },
      );
      this.reload();
      _.assign(order, newOrder);
    } catch (e) {
      this.$store.commit("SET_ERROR", e.message);
    } finally {
      this.modifying = false;
    }
  }

  async cancelOrder() {
    try {
      const order = this.order;
      this.modifying = true;
      let refund = "refund";
      if (order.status !== "pending") {
        const c = await this.$openDialog(
          import("~/components/dialogs/CancelConfirmDialog.vue"),
          {
            title: this.$t("basic.doYouWantToRefund"),
            keyText: order.orderId || order._id,
            canRefund: order.paymentStatus !== "unpaid",
          },
          {
            maxWidth: "400px",
          },
        );
        if (!c) return;
        refund = c.refund;
      }
      const newOrder = await this.$feathers.service("shop/orders/cancel").create({
        _id: order._id,
        ...(order.paymentStatus !== "unpaid" && refund === "refund"
          ? {
              paymentStatus: "refunded",
            }
          : {}),
      });
      _.assign(order, newOrder);
      this.reload();
      try {
        await this.$pos.init();
        if (this.$pos.cashier.posPrinter === "thermal") {
          if (this.$pos.cashier.printOnFinish) {
            await this.setupPrinter();
            await this.printJob(
              "receipt",
              getInvoiceSequencer(order),
            )
          }
        }
      } catch (e) {
        console.warn(e);
      }
    } catch (e) {
      this.$store.commit("SET_ERROR", e.message);
    } finally {
      this.modifying = false;
    }
  }

  flattenProducts(products) {
    return _.flatMap(products, item => {
      if (item.type === "product") {
        return [item];
      } else if (item.type === "discountRule") {
        const result = [
          {
            ...item,
            quantity: item.points?.length ? _.sumBy(item.points as any[], p => p.value) : undefined,
            price: undefined,
            total: item.total === 0 ? undefined : item.total,
          },
        ];

        if (item.discount?.free?.length) {
          for (let free of item.discount.free) {
            result.push({
              type: "product",
              name: free.product?.name,
              price: 0,
              quantity: free.quantity,
              total: 0,
              product: free.product,
            });
          }
        }

        return result;
      }
      return [];
    });
  }

  get productTrumb() {
    return (product: any) => {
      return this.$thumb(product?.images?.[0]) || require("~/assets/images/logo.png");
    };
  }

  get productName() {
    return (product: any) => {
      return this.$td(product?.sku?.fullName) || this.$td(product?.sku?.name) || this.$td(product?.name);
    };
  }

  get gifts() {
    return _.flatten(this.order?.discounts?.map(d => d?.gifts?.map(gift => ({ ...gift, name: d.name })) ?? []));
  }

  async resendOrderEmail(type: "orderReceive" | "orderPaid" | "orderBankTransfer" | "orderBankTransferUploaded") {
    try {
      this.modifying = true;

      await this.$feathers.service("shop/orders/resendEmail").create({
        order: String(this.order._id),
        type,
      });

      this.$store.commit("SET_SUCCESS", this.$t("basic.success"));
    } catch (e) {
      this.$store.commit("SET_ERROR", e.message);
    } finally {
      this.modifying = false;
    }
  }


  async resendShipmentEmail(item: FindType<'shop/shipping/shipments'>) {
    try {
      this.modifying = true;

      await this.$feathers.service("shop/shipping/shipments/resendEmail").create({
        shipment: String(item._id),
        order: String(this.order._id),
      });

      this.$store.commit("SET_SUCCESS", this.$t("basic.success"));
    } catch (e) {
      this.$store.commit("SET_ERROR", e.message);
    } finally {
      this.modifying = false;
    }
  }
}
