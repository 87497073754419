<template>
<v-card v-event.weight="inputWeight">
    <v-card-title class="headline" v-t="'pos.productWeight.$'"></v-card-title>
    <v-card-text>
        <template v-if="device">
            <div>
                <span>{{$t('pos.productWeight.connStatus')}}</span>
                <span>{{device.connecting ? $t('pos.productWeight.connecting') : device.connected ? $t('pos.productWeight.connected') : $t('pos.productWeight.disconnected')}}</span>
            </div>
        </template>
        <template v-if="rawWeight">
            <div>
                <span>{{$t('pos.productWeight.rawWeight')}}{{rawWeight}} </span>
                <formatter :value="rawWeightUnit" format="enum" :args="[null, null, { type: 'weightUnit' }]"/>
            </div>
        </template>
        <v-layout justify-center>
            <number-input style="margin-right: 20px" persist @done="modalResult(quantity)" :postfix="$enum(weightUnit, 'weightUnit')" :label="$t('pos.productWeight.weight')" :min="0" v-model="weight" :dp="6" />
            <number-input persist @done="modalResult(quantity)" :label="$t('pos.productWeight.price')" prefix="$" :min="0" v-model="price" :dp="2" />
        </v-layout>
    </v-card-text>
    <v-card-actions>
        <v-row justify="space-around" no-gutters>
            <v-col cols="auto">
                <v-btn :loading="editLoading" large color="blue" @click.prevent.stop="print">
                    <v-icon>print</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-card-actions>
</v-card>
</template>

<script>

import Dialog from '@feathers-client/mixins/Dialog'
import nuxtend from '@feathers-client/nuxtend'
// import NumberInput from '~/components/numberInput.vue'
import { convertWeight } from '@common/weightUtils'
import { ProductInfo } from '@common/product'
import Printer from '~/mixins/Printer'
import { init } from 'pos-printer/weight'
import { init as initSerial } from 'pos-printer/weight/legacy/serial'
import productSequencer from '~/plugins/printer/label'

export default nuxtend({
    components: {
        // NumberInput
    },
    mixins: [
        Dialog,
        Printer('label', 'label')
    ],
    props: {
        product: {
            required: true
        },
    },
    data() {
        return {
            weight: 0,
            weightUnit: 'kg',
            rawWeight: 0,
            rawWeightUnit: 'kg',
            editLoading: false,
            device: null,
            destroyed: false,
            productInfo: null
        }
    },
    mounted() {
        this.syncWeight();
    },
    beforeMount() {
        this.productInfo = new ProductInfo(this, this.product);
        this.rawWeightUnit = this.cashier.weightUnit || 'kg';
        this.weightUnit = this.productInfo.weightUnit || 'kg';

        if(this.cashier.weightConnType === 'bluetooth') {
            this.device = init(this.$root);
        } else if(this.cashier.weightConnType === 'serial') {
            this.device = initSerial(this.$root, this.cashier.weightConnPort);
        }
    },
    beforeDestroy() {
        this.destroyed = true;
        this.$root.$emit('syncWeight', null);
    },
    methods: {
        inputWeight(v) {
            this.rawWeight = v;
            this.weight = +(convertWeight(v, this.rawWeightUnit, this.weightUnit).toFixed(6));
        },

        async print() {
            this.editLoading = true;
            try {
                await this.setupPrinter();
                const job = await productSequencer(this.printer, this, this.product, {
                    num: 1,
                    showID: true,
                    showDate: false,
                    showNum: false,
                    quantity: this.quantity,
                    weight: this.weight,
                });
                await this.printer.print(job.getJob("Label"));
            } catch (e) {
                console.warn(e);
                this.$store.commit('SET_ERROR', e.message);
            } finally {
                this.editLoading = false;
            }
        },

        syncWeight() {
            if(this.destroyed) return;
            this.$root.$emit('syncWeight', {
                product: this.product,
                name: this.$td(this.product.name),
                weight: this.weight,
                weightUnit: this.weightUnit,
                price: this.price,
            })
        }
    },
    computed: {
        cashier() { return this.$pos.cashier },
        price: {
            get() {
                let val = this.productInfo.weightToPrice(this.weight);
                if(this.$shop.subtotalRoundingFactor && this.$shop.subtotalRoundingFactor !== 0.01) {
                    switch(this.$shop.subtotalRoundingMode) {
                        case 'roundOff':
                            val = Math.round(val / this.$shop.subtotalRoundingFactor) * this.$shop.subtotalRoundingFactor;
                            break;
                        case 'roundDown':
                            val = Math.floor(val / this.$shop.subtotalRoundingFactor) * this.$shop.subtotalRoundingFactor;
                            break;
                        case 'roundUp':
                        default:
                            val = Math.ceil(val / this.$shop.subtotalRoundingFactor) * this.$shop.subtotalRoundingFactor;
                            break;
                    }
                }
                return +(val.toFixed(2));
            },
            set(v) {
                this.weight = +(this.productInfo.priceToWeight(v).toFixed(6));
            }
        },

        quantity() {
            return +(this.productInfo.weightToQuantity(this.weight).toFixed(6));
        }
    },
    watch: {
        price() { this.syncWeight() },
        quantity() { this.syncWeight() },
    }

})

</script>
