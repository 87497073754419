let gain: GainNode;
let context: AudioContext;
let audioBuffers: Record<string, Promise<AudioBuffer>> = {};

export const beep = (freq = 520, duration = 200, vol = 100) => {
  if (!context) {
    context = new AudioContext();
    gain = context.createGain();

    gain.connect(context.destination);
    gain.gain.value = vol * 0.01;
  }
  const oscillator = context.createOscillator();
  oscillator.connect(gain);
  oscillator.frequency.value = freq;
  oscillator.type = "square";
  oscillator.start(context.currentTime);
  oscillator.stop(context.currentTime + duration * 0.001);
};

export const playAudioFile = async (audioFile: string, vol = 100) => {
  if (!context) {
    context = new AudioContext();
    gain = context.createGain();

    gain.connect(context.destination);
    gain.gain.value = vol * 0.01;
  } else {
    context.resume();
  }
  let audioBufferTask = audioBuffers[audioFile];
  if (!audioBufferTask) {
    audioBufferTask = audioBuffers[audioFile] = (async () => {
      const response = await fetch(audioFile);
      const arrayBuffer = await response.arrayBuffer();
      return await context.decodeAudioData(arrayBuffer);
    })();
  }
  const audioBuffer = await audioBufferTask;
  const sourceNode = context.createBufferSource();
  sourceNode.connect(gain);
  sourceNode.buffer = audioBuffer;
  sourceNode.start();
};
