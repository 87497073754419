
import { Component, Prop, Vue, FindType, checkID } from "@feathers-client";
import _ from "lodash";

@Component
export default class ArticleTypePicker extends Vue {
  @Prop()
  value: FindType<"shop/articleTypes">["_id"];

  current: FindType<"shop/articleTypes"> = null;

  showTable = false;

  get currentName() {
    return this.current?.name ? this.$td(this.current.name) : "";
  }

  async beforeMount() {
    if (this.value) {
      this.current = await this.$feathers.service("shop/articleTypes").get(this.value);
    }
  }

  isSelected(type: FindType<"shop/articleTypes">) {
    return this.current && checkID(this.current._id, type._id);
  }

  itemSelected(type: FindType<"shop/articleTypes">) {
    if (type?._id) {
      if (!this.current || !checkID(type._id, this.current._id)) {
        this.current = type;
      } else if (this.current && checkID(type._id, this.current._id)) {
        this.current = null;
      }
    }
  }

  save() {
    this.$emit("input", this.current?._id);
    this.showTable = false;
  }
}
