import SerialDevice, { connect, portPicker, SerialPortSelection } from "./ports/serial";
import { EspDevice, EspDeviceSerial } from "./esp32";
import { Component, Vue, Prop } from "@feathers-client";

export interface SerialManagerConfig {
  serial?: SerialPortSelection;
}

@Component
export class SerialMixin extends Vue {
  connected = false;
  connecting = false;

  needReconnect: boolean;
  serial: SerialDevice = null;
  webSerial: any = null;
  webSerialWriter: any = null;
  espSerial: EspDeviceSerial = null;

  reconnectTimeout: number | NodeJS.Timer | null = null;

  status: "notSetup" | "connected" | "disconnected" = "notSetup";

  @Prop()
  getSetting: () => SerialManagerConfig;

  @Prop()
  setSetting: (v: SerialManagerConfig) => void;

  @Prop()
  whenDestroy: () => void;

  beforeDestroy() {
    this.whenDestroy?.();
  }

  get settings() {
    return this.getSetting?.();
  }

  set settings(v) {
    this.setSetting?.(v);
  }

  get serialConfig() {
    return this.settings?.serial;
  }

  set serialConfig(s) {
    this.settings = {
      ...(this.settings || {}),
      serial: s,
    };
  }

  get espSerialMode() {
    return "TurnCloud";
  }

  created() {
    this.connect().catch(console.error);
  }

  async connect(user?: boolean, reconnect?: boolean) {
    const device = await portPicker(
      this,
      reconnect ? undefined : this.serialConfig,
      user,
      undefined,
      this.espSerialMode as any,
      (this as any).constructor.name,
    );
    if (device) {
      this.disconnect();
      this.clearReconnect();
      switch (device.type) {
        case "serial": {
          this.serialConfig = device;
          const serial = (this.serial = await connect(device.port, {
            baudRate: 9600,
          }));
          this.serial.on("data", buf => {
            this.serialInput(buf);
          });
          this.serial.on("close", () => {
            if (this.serial === serial) {
              this.onDisconnect();
            }
          });
          break;
        }
        case "webSerial": {
          this.serialConfig = {
            type: "webSerial",
          };
          this.webSerial = device.device;
          try {
            this.webSerial.addEventListener("disconnect", () => {
              if (this.webSerial === device.device) {
                this.onDisconnect();
              }
            });
            await this.webSerial.open({
              baudRate: 9600,
            });
            this.webSerialWriter = this.webSerial.writable.getWriter();
            const reader = this.webSerial.readable.getReader();
            const task = (async () => {
              while (true) {
                const data = await reader.read();
                this.serialInput(data.value);
              }
            })();
            task.catch(e => {
              console.error(e);
              if (this.webSerial === device.device) {
                this.onDisconnect();
              }
            });
          } catch (e) {
            if (this.webSerial === device.device) {
              this.onDisconnect();
            }
          }
          break;
        }
        case "espSerial": {
          this.serialConfig = {
            type: "espSerial",
            espConfig: device.espConfig,
          };
          const serial = (this.espSerial = (device.device as EspDevice).getSerial());
          this.espSerial.on("data", buf => {
            if (this.espSerial === serial) {
              this.serialInput(buf);
            }
          });
          this.espSerial.on("close", () => {
            if (this.espSerial === serial) {
              this.onDisconnect();
            }
          });
          break;
        }
      }
      if (this.hasConnection) {
        this.status = "connected";
        this.reconnectTrials = 0;
      }
    } else if (this.serialConfig) {
      this.reconnectTrials++;
      this.scheduleReconnect();
    }
  }

  private onDisconnect() {
    this.disconnect();
    if (this.serialConfig) {
      this.scheduleReconnect();
    }
  }

  async disconnect(save?: boolean) {
    if (this.serial) {
      this.serial.removeAllListeners("data");
      this.serial.close();
      this.serial = null;
    }
    if (this.webSerial) {
      try {
        await this.webSerial.close();
      } catch (e) {
        console.warn(e);
      }
      try {
        await this.webSerial.forget();
      } catch (e) {
        console.warn(e);
      }
      this.webSerial = null;
      this.webSerialWriter = null;
    }
    if (this.espSerial) {
      this.espSerial.removeAllListeners("data");
      this.espSerial.close();
      this.espSerial = null;
    }
    if (save) {
      this.serialConfig = null;
    }
    if (this.status === "connected") {
      this.status = "disconnected";
      this.needReconnect = true;
    }
    this.clearReconnect();
  }

  clearReconnect() {
    if (this.reconnectTimeout) {
      clearTimeout(this.reconnectTimeout as any);
      this.reconnectTimeout = null;
    }
  }

  reconnectTrials = 0;

  scheduleReconnect() {
    this.clearReconnect();
    if (this.serialConfig) {
      const expTimeout =
        Math.min(60000, 3000 * Math.pow(1.5, Math.min(this.reconnectTrials, 20))) * (1 + Math.random());
      this.reconnectTimeout = setTimeout(this.autoReconnect, expTimeout);
    }
  }

  async autoReconnect() {
    this.reconnectTimeout = null;
    if (this.serialConfig) {
      try {
        await this.connect();
      } catch (e) {
        console.error(e);
      }
    }
  }

  get hasConnection() {
    return !!(this.serial || this.webSerial || this.espSerial);
  }

  serialInput(buf: Buffer) {}
}
