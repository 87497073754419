
import { FindType } from "@feathers-client";
import { Component, Prop, Vue, Watch, mixins, Ref, PropSync } from "@feathers-client";
import moment from "moment";
import { OrderProductType, OrderType } from "@common/common";

type SummaryResponseItem = FindType<"shop/booking/timetables/summary">[number];

@Component
export default class CalendarView extends Vue {
  @Ref()
  calendar: any;

  @Prop()
  skus?: string[];

  @Prop()
  mode: "history" | "pick";

  type: "month" | "list" = "month";

  value = "";

  selectStart = "";
  selectEnd = "";
  selectingRange = false;

  dayStats: Record<string, SummaryResponseItem> = {};

  get query() {
    return {
      products: {
        $elemMatch: {
          "booking.date": {
            $lte: this.value,
          },
          "booking.dateEnd": {
            $gte: this.value,
          },
        },
      },
      $populate: ["products.bookingTimetable"],
    };
  }

  created() {
    this.value = moment().format("YYYY-MM-DD");
  }

  prev() {
    switch (this.type) {
      case "month":
        this.calendar.prev();
        break;
      case "list":
        this.value = moment(this.value, "YYYY-MM-DD").subtract(1, "day").format("YYYY-MM-DD");
        break;
    }
  }
  next() {
    switch (this.type) {
      case "month":
        this.calendar.next();
        break;
      case "list":
        this.value = moment(this.value, "YYYY-MM-DD").add(1, "day").format("YYYY-MM-DD");
        break;
    }
  }

  @Watch("value", { immediate: true })
  async loadCalendar() {
    const input = this.value || moment().format("YYYY-MM-DD");

    const start = moment(input).startOf("month").format("YYYY-MM-DD");
    const end = moment(input).endOf("month").format("YYYY-MM-DD");

    const range = await this.$feathers.service("shop/booking/timetables/summary").find({
      query: {
        from: start,
        to: end,
        group: "day",
        ...(this.skus?.length ? { skus: this.skus } : {}),
      },
    });

    this.dayStats = Object.fromEntries(range.map(item => [item._id.date, item]));
  }

  getStat(date: string): "full" | "nearFull" | "available" {
    const item = this.dayStats[date];
    if (item) {
      if (item.usedQuota >= item.quota) {
        return "full";
      }
      // return "nearFull"
    }
    return "available";
  }

  getColor(date: string) {
    switch (this.getStat(date)) {
      case "full":
        return this.$config.colors.red200;
      case "nearFull":
        return this.$config.colors["dark-surface-brand-default"];
      case "available":
        return this.$config.colors.green400;
    }
  }

  getBgColor(date: string) {
    switch (this.getStat(date)) {
      case "full":
        return "#FDF1F1";
      case "nearFull":
        return "#FFFAEA";
      case "available":
        return "#F0FFF6";
    }
  }

  getTextColor(date: string) {
    switch (this.getStat(date)) {
      case "full":
        return this.$config.colors.red500;
      case "nearFull":
        return this.$config.colors["light-action-primary-default"];
      case "available":
        return this.$config.colors["dark-surface-success-default"];
    }
  }

  goList(date: string) {
    if (this.mode === "pick") {
      if (this.selectStart) {
        const min = this.selectStart < date ? this.selectStart : date;
        const max = this.selectStart > date ? this.selectStart : date;
        this.selectingRange = false;
        this.selectStart = min;
        this.selectEnd = max;
        this.$emit("chooseBooking", {
          date: min,
          dateEnd: max,
        });
      } else {
        this.selectStart = date;
        this.selectingRange = true;
      }
    } else {
      this.value = date;
      this.type = "list";
    }
  }

  previewEnd({ date }) {
    if (this.selectingRange) {
      this.selectEnd = date;
    }
  }

  get dateStr() {
    switch (this.type) {
      case "month":
        return moment(this.value, "YYYY-MM-DD").format("YYYY MMM");
      case "list":
        return moment(this.value, "YYYY-MM-DD").format("YYYY MMM DD");
    }
  }

  dateSelected(date: string) {
    if (this.selectStart && this.selectEnd) {
      const min = this.selectStart < this.selectEnd ? this.selectStart : this.selectEnd;
      const max = this.selectStart > this.selectEnd ? this.selectStart : this.selectEnd;
      return date >= min && date <= max;
    }
    return false;
  }

  formatDate(date: string) {
    if (moment(date).isSame(moment(), "day")) {
      return this.$t("booking.today");
    } else if (moment(date).isSame(moment().add(1, "day"), "day")) {
      return this.$t("booking.tomorrow");
    } else if (moment(date).isSame(moment().subtract(1, "day"), "day")) {
      return this.$t("booking.yesterday");
    } else {
      return date;
    }
  }

  formatSlot(booking: OrderProductType["booking"]) {
    if (booking.slotSize === "day") {
      return this.$t("booking.slot.day", { slots: booking.slots });
    } else {
      const dur = moment(booking.dateEnd)
        .add(booking.timeEnd || 0, "minute")
        .diff(moment(booking.date).add(booking.time || 0, "minute"), "hours");
      return this.$t("booking.slot.hour", { slots: moment.duration(dur, "hours").asHours() });
    }
  }

  getBookingStatus(booking: OrderProductType["booking"]) {
    switch (booking?.status) {
      case "inProgress":
        return this.$config.colors.green400;
      case "complete":
        return this.$config.colors.grey400;
      case "cancelled":
        return this.$config.colors.red400;
      case "confirmed":
        return this.$config.colors.blue300;
      case "pending":
      default:
        return "#fff";
    }
  }

  getPaymentStatus(order: OrderType) {
    switch (order?.paymentStatus) {
      case "paid":
        return this.$config.colors.green400;
      case "unpaid":
      case "partial":
      case "refunded":
        return this.$config.colors.red400;
    }
  }
}
