
import _ from "lodash";
import { Component, Vue, mixins, Prop, FindType, FindPopRawType, Watch, checkID, getID } from "@feathers-client";
import Dialog from "@feathers-client/mixins/Dialog";
type ShipmentType = FindType<"shop/shipping/shipments">;
import { getStatusColor } from "~/plugins/pos/util";

@Component
export default class ShipmentScheduleShipmentDialog extends mixins(Dialog) {
  @Prop()
  item: ShipmentType;

  @Prop()
  newStatus: ShipmentType["status"];

  @Prop()
  hideStatus: boolean;

  @Prop({ type: Array })
  contents;

  shipmentStatus: ShipmentType["status"] = null;
  remarks = "";

  get scheduleFilters() {
    return [
      { _id: "pending", name: { $t: "enum.basic.status.pending" } },
      { _id: "preparing", name: { $t: "enum.basic.status.preparing" } },
      { _id: "delivery", name: { $t: "enum.basic.status.delivery" } },
      { _id: "readyToSelfPickup", name: { $t: "enum.basic.status.readyToSelfPickup" } },
      { _id: "received", name: { $t: "enum.basic.status.received" } },
      { _id: "notDelivered", name: { $t: "enum.basic.status.notDelivered" } },
    ];
  }

  beforeMount() {
    if (this.newStatus) {
      this.shipmentStatus = this.newStatus;
    }
    this.remarks = this.item.remarks;
  }

  getStatusColor = getStatusColor;

  async save() {
    try {
      const resp = await this.$feathers.service("shop/shipping/shipments/update").create({
        id: this.item._id,
        shipment: {
          ...(this.shipmentStatus
            ? {
                status: this.shipmentStatus,
              }
            : {}),
          remarks: this.remarks,
        } as any,
      });
      Object.assign(this.item, resp);
      this.modalResult(true);
    } catch (e) {
      this.$store.commit("SET_ERROR", e.message);
    }
  }
}
