var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex items-center relative box-border gap-x-3"},[(_vm.stepperSpace)?_c('div',{class:{
      'disabled !bg-transparent': _vm.readonly || (_vm.minValue !== undefined && _vm.inputValue - _vm.step < _vm.minValue),
      'opacity-0 hidden': !_vm.stepper,
    },attrs:{"role":"button"},on:{"click":function($event){_vm.inputValue -= _vm.step}}},[_c('minus-icon',{staticClass:"custom-icon",class:[_vm.buttonClass ? _vm.buttonClass : '!text-red100']})],1):_vm._e(),_c('div',{staticClass:"flex-grow grid grid-cols-[auto,minmax(0,1fr),auto] border-solid px-2.5 rounded items-center",class:[{ 'border-2 border-dark-border-focus-default': _vm.active }, _vm.containerClass],on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.numPad = !_vm.numPad}}},[(_vm.prefix)?_c('div',{staticClass:"select-none col-start-1 text-grey300"},[_vm._v(_vm._s(_vm.prefix))]):_vm._e(),_c('menu-list-input',{staticClass:"flex-grow text-center outline-none py-2.5 col-start-2",class:[_vm.inputTextClass, { 'text-grey300': !_vm.customReceived && (_vm.numPad || _vm.active || _vm.value === null) }],attrs:{"select-all":"","type":_vm.type,"enterkeyhint":_vm.enterkeyhint,"value":_vm.customReceived || _vm.inputValue,"readonly":_vm.readonly || _vm.disableInputFieldOnly,"integer":_vm.integer,"placeholder":_vm.placeholder,"disabled":_vm.disableInput},on:{"input":function($event){_vm.customReceived = $event},"focus":_vm.focused,"blur":_vm.submitValue}}),(_vm.suffix)?_c('div',{staticClass:"select-none col-start-3 text-grey300"},[_vm._v(_vm._s(_vm.suffix))]):_vm._e()],1),(_vm.stepperSpace)?_c('div',{class:{
      'disabled !bg-transparent': _vm.readonly || (_vm.maxValue !== undefined && _vm.inputValue + _vm.step > _vm.maxValue),
      'opacity-0 hidden': !_vm.stepper,
    },attrs:{"role":"button"},on:{"click":function($event){_vm.inputValue += _vm.step}}},[_c('plus-icon',{staticClass:"custom-icon",class:[_vm.buttonClass ? _vm.buttonClass : '!text-orange400']})],1):_vm._e(),_c('teleport-menu',{attrs:{"wrap":"","placement":_vm.$vuetify.breakpoint.smAndDown ? 'bottom' : 'right',"size":"auto","offset":"center"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('num-pad',{staticClass:"pos-popup rounded-lg",attrs:{"max":_vm.max,"string":_vm.customReceived,"noDefault":"","minInclusive":"","allow-empty":_vm.allowEmpty,"integer":_vm.integer},on:{"update:string":function($event){_vm.customReceived=$event},"confirm":function($event){_vm.numPad = false}},model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}})]},proxy:true}]),model:{value:(_vm.numPad),callback:function ($$v) {_vm.numPad=$$v},expression:"numPad"}})],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }