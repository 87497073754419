import moment from "moment";

export const OperationMode = binEnum({
  Auto: 0,
  Octopus: 1,
  TurnCloud: 2,
});
export type OperationMode = InstanceType<typeof OperationMode>;

export const BLECmdVersion0InfoPort = binStruct({
  port: 1,
  idVendor: 2,
  idProduct: 2,
});

export type BLECmdVersion0InfoPort = InstanceType<typeof BLECmdVersion0InfoPort>;

export const BLECmdVersion0Info = binStruct({
  operationMode: OperationMode,
  state: 1,
  major: 1,
  minor: 1,
  patch: 1,
  ipv4: ["buffer", 4],
  ports: [1, "portData"],
  portData: [[BLECmdVersion0InfoPort], "ports"],
} as const, {
  optional: true,
});

export type BLECmdVersion0Info = InstanceType<typeof BLECmdVersion0Info>;

export const BLECmdVersion0UartOut = binStruct({
  jobSize: [2, "jobData"],
  jobData: ["buffer", "jobSize"],
} as const);

export type BLECmdVersion0UartOut = InstanceType<typeof BLECmdVersion0UartOut>;

export const BLECmdVersion0SetWifi = binStruct({
  ssid: "wide",
  password: "wide",
} as const);

export const BLECmdVersion0DeleteWifi = binStruct({
  ssid: "wide",
} as const);

export const BLECmdVersion0GetWifiInfoItem = binStruct({
  ssid: "wide",
});

export const BLECmdVersion0GetWifiInfo = binStruct({
  ssid: "wide",
  ip: "string",
  prevWifisSize: [2, "prevWifis"],
  prevWifis: [[BLECmdVersion0GetWifiInfoItem], "prevWifisSize"],
} as const);

export type BLECmdVersion0GetWifiInfo = InstanceType<typeof BLECmdVersion0GetWifiInfo>;

export const BLECmdVersion0Error = binStruct({
  code: 1,
  messageSize: [1, "message"],
  message: ["buffer", "messageSize"],
  dataSize: [2, "data"],
  data: ["buffer", "dataSize"],
} as const);

export const SystemStats = binStruct({
  resetReason: 1,
  wifiRssi: -1,
  voltage: 2,

  uptime: 4,
  wifiLastConnect: 4,
  wifiLastDisconnect: 4,
  wifiReconnects: 4,
  wifiLastTry: 4,
  wifiTries: 4,

  mqttLastConnect: 4,
  mqttLastDisconnect: 4,
  mqttLastMessage: 4,
  mqttMessages: 4,
  mqttReconnects: 4,
  mqttLastTry: 4,
  mqttTries: 4,

  usbLastAttch: 4,
  usbLastDetach: 4,
  usbLastConnect: 4,
  usbLastDisconnect: 4,
  usbTries: 4,
  usbLastSend: 4,
  usbLastRecv: 4,
  usbReconnects: 4,
  usbInBytes: 4,
  usbOutBytes: 4,

  bleLastConnect: 4,
  bleLastDisconnect: 4,

  tcpLastConnect: 4,
  tcpLastDisconnect: 4,

  lastPrint: 4,
  prints: 4,

  uartLastSend: 4,
  uartLastRecv: 4,
  uartLastSendFull: 4,
  uartLastRecvFull: 4,
  uartSendFulls: 4,
  uartRecvFulls: 4,
  uartInBytes: 4,
  uartOutBytes: 4,
})

export type SystemStats = InstanceType<typeof SystemStats>;

export const BLECmdVersion0 = binEnum({
  Info: 0,
  Print: 1,
  UartOut: [2, BLECmdVersion0UartOut],
  UartStartRead: 3,
  SetWifi: [4, BLECmdVersion0SetWifi],
  GetWifiInfo: 5,
  DeleteWifi: [6, BLECmdVersion0DeleteWifi],
  ClearWifi: 7,
  Restart: 8,
  SetOperationMode: [9, OperationMode],
  Ota: 0xa,
  Stats: 0xb,
  RestartPort: 0xc,

  InfoResponse: [0x80, BLECmdVersion0Info],
  UartInResponse: [0x82, BLECmdVersion0UartOut],
  GetWifiInfoResponse: [0x85, BLECmdVersion0GetWifiInfo],
  OtaProgress: [0x8a, BLECmdVersion0Error],
  StatsResponse: [0x8b, SystemStats],
  Error: [0xff, BLECmdVersion0Error],
} as const);
export type BLECmdVersion0 = InstanceType<typeof BLECmdVersion0>;

export const BLECmdPayload = binEnum({
  Version0: [0, BLECmdVersion0],
} as const);

export const BLECmdRoot = binStruct({
  timestamp: "unix",
  payload: BLECmdPayload,
} as const);

export const PairingCmdVersion0Success = binStruct({
  key: ["buffer", 32],
  kid: ["buffer", 32],
  deviceId: ["buffer", 4],
} as const);

export const PairingCmdVersion0RequestChallenge = binStruct({
  challenge: ["buffer", 32],
} as const);

export const PairingCmdVersion0Challenge = binStruct({
  signSize: [2, "sign"],
  sign: ["buffer", "signSize"],
} as const);

export const PairingCmdVersion0 = binEnum({
  Pairing: 0,
  RequestChallenge: 1,
  Challenge: [2, PairingCmdVersion0Challenge],

  PairSuccess: [0x80, PairingCmdVersion0Success],
  RequestChallengeResp: [0x81, PairingCmdVersion0RequestChallenge],

  Error: [0xff, BLECmdVersion0Error],
} as const);
export type PairingCmdVersion0 = InstanceType<typeof PairingCmdVersion0>;

export const PairingCmdPayload = binEnum({
  Version0: [0, PairingCmdVersion0],
} as const);

export const PairingCmdRoot = binStruct({
  timestamp: "unix",
  payload: PairingCmdPayload,
} as const);

export type ConvertInnerType<T> = T extends "unix"
  ? Date
  : T extends "buffer"
    ? Buffer
    : T extends "string"
      ? string
      : T extends "wide"
        ? string
        : T extends number
          ? number
          : T extends readonly [infer U, any]
            ? ConvertInnerType<U>
            : T extends readonly [infer U]
              ? ConvertInnerType<U>[]
              : T extends abstract new (...args: any) => any
                ? InstanceType<T>
                : never;
export type ConvertType<T, K extends keyof T> = T[K] extends readonly [number, infer U] ? ConvertInnerType<U> : {};
export type ObjToEnum<T> = {
  -readonly [K in keyof T]?: ConvertType<T, K>;
};
export type ObjToStruct<T> = {
  -readonly [K in keyof T]?: ConvertInnerType<T[K]>;
};

export type BinStruct<T> = {
  new (input?: Partial<T>): T;
  write(item: T): Buffer[];
  read(buf: Buffer, offset: number): [T, number];
};

export type BinEnum<T> = {
  new (input?: Partial<T>): T;
  write(item: T): Buffer[];
  read(buf: Buffer, offset: number): [T, number];
  hasEnum(key: string): boolean;
  getEnumKey(item: T): keyof T;
};

function readValue(buf: Buffer, offset: number, type: any, parent?: any) {
  let val: any;
  switch (type) {
    case "unix": {
      val = moment.unix(buf.readUInt32LE(offset)).toDate();
      offset += 4;
      break;
    }
    case 1: {
      val = buf.readUInt8(offset);
      offset += 1;
      break;
    }
    case -1: {
      val = buf.readInt8(offset);
      offset += 1;
      break;
    }
    case 2: {
      val = buf.readUInt16LE(offset);
      offset += 2;
      break;
    }
    case -2: {
      val = buf.readInt16LE(offset);
      offset += 2;
      break;
    }
    case 4: {
      val = buf.readUInt32LE(offset);
      offset += 4;
      break;
    }
    case -4: {
      val = buf.readInt32LE(offset);
      offset += 4;
      break;
    }
    case "wide": {
      let end = offset;
      while (end + 2 < buf.length && (buf[end] !== 0 || buf[end + 1] !== 0)) {
        end += 2;
      }
      val = buf.slice(offset, end).toString("utf16le");
      offset = end + 2;
      break;
    }
    case "string": {
      let end = offset;
      while (end + 1 < buf.length && buf[end] !== 0) {
        end++;
      }
      val = buf.slice(offset, end).toString("utf8");
      offset = end + 1;
      break;
    }
    default: {
      if (typeof type === "function") {
        [val, offset] = type.read(buf, offset);
      } else if (Array.isArray(type)) {
        switch (type[0]) {
          case "buffer": {
            const len = typeof type[1] === "number" ? type[1] : parent?.[type[1]] ?? 0;
            val = buf.slice(offset, offset + len);
            offset += len;
            break;
          }
          case 1:
          case 2:
          case 4: {
            val = buf.readUIntLE(offset, type[0]);
            offset += type[0];
            break;
          }
          default: {
            if (Array.isArray(type[0])) {
              const len = typeof type[1] === "number" ? type[1] : parent?.[type[1]] ?? 0;
              const res: any[] = [];
              for (let i = 0; i < len; i++) {
                const [v, newOffset] = readValue(buf, offset, type[0][0], parent);
                res.push(v);
                offset = newOffset;
              }
              val = res;
              break;
            }
            throw new Error("invalid type: " + type);
          }
        }
      } else {
        throw new Error("invalid type: " + type);
      }
      break;
    }
  }
  return [val, offset] as const;
}

function writeValue(value: any, type: any, parent?: any) {
  let buf: Buffer;
  switch (type) {
    case "unix": {
      buf = Buffer.alloc(4);
      buf.writeUInt32LE(moment(value).unix(), 0);
      break;
    }
    case 1: {
      buf = Buffer.alloc(1);
      buf.writeUInt8(value, 0);
      break;
    }
    case -1: {
      buf = Buffer.alloc(1);
      buf.writeInt8(value, 0);
      break;
    }
    case 2: {
      buf = Buffer.alloc(2);
      buf.writeUInt16LE(value, 0);
      break;
    }
    case -2: {
      buf = Buffer.alloc(2);
      buf.writeInt16LE(value, 0);
      break;
    }
    case 4: {
      buf = Buffer.alloc(4);
      buf.writeUInt32LE(value, 0);
      break;
    }
    case -4: {
      buf = Buffer.alloc(4);
      buf.writeInt32LE(value, 0);
      break;
    }
    case "wide": {
      buf = Buffer.concat([Buffer.from(value, "utf16le"), Buffer.alloc(2)]);
      break;
    }
    case "string": {
      buf = Buffer.concat([Buffer.from(value, "utf8"), Buffer.alloc(1)]);
      break;
    }
    default: {
      if (typeof type === "function") {
        buf = Buffer.concat(type.write(value));
      } else if (Array.isArray(type)) {
        switch (type[0]) {
          case "buffer": {
            buf = value ? Buffer.from(value) : Buffer.alloc(0);
            break;
          }
          case 1:
          case 2:
          case 4: {
            const refBufLen = parent?.[type[1]]?.length ?? 0;
            buf = Buffer.alloc(type[0]);
            buf.writeUIntLE(refBufLen, 0, type[0]);
            break;
          }
        }
      } else {
        throw new Error("invalid type: " + type);
      }
      break;
    }
  }
  return buf;
}

export function binStruct<T>(input: T, opts?: { optional?: boolean }) {
  class Struct {
    constructor(input?: any) {
      if (input) {
        for (const [k, v] of Object.entries(input)) {
          (this as any)[k] = v;
        }
      }
    }
    static write(item: any) {
      const bufs: Buffer[] = [];
      for (const [k, v] of Object.entries(input)) {
        bufs.push(writeValue(item[k], v, item));
      }
      return bufs;
    }
    static read(buf: Buffer, offset: number) {
      const result: any = {};
      for (const [k, v] of Object.entries(input)) {
        const [val, newOffset] = readValue(buf, offset, v, result);
        result[k] = val;
        offset = newOffset;
        if(opts?.optional && offset >= buf.length) {
          break;
        }
      }
      return [result, offset] as const;
    }
  }
  return Struct as any as BinStruct<ObjToStruct<T>>;
}

export function binEnum<T>(input: T) {
  class Enum {
    constructor(input?: Partial<T>) {
      if (input) {
        for (const [k, v] of Object.entries(input)) {
          (this as any)[k] = v;
        }
      }
    }
    static write(item: any) {
      const bufs: Buffer[] = [];
      for (const [k, v] of Object.entries(input)) {
        if (item[k]) {
          if (Array.isArray(v)) {
            bufs.push(writeValue(v[0], 1));
            bufs.push(writeValue(item[k], v[1]));
          } else {
            bufs.push(writeValue(v, 1));
          }
          break;
        }
      }
      if (!bufs.length) {
        throw new Error("invalid enum");
      }
      return bufs;
    }
    static read(buf: Buffer, offset: number) {
      const [val, newOffset] = readValue(buf, offset, 1);
      for (const [k, v] of Object.entries(input)) {
        if (Array.isArray(v) && val === v[0]) {
          const [val2, newOffset2] = readValue(buf, newOffset, v[1]);
          return [new Enum({ [k]: val2 } as any), newOffset2] as const;
        } else if (val === v) {
          return [new Enum({ [k]: {} } as any), newOffset] as const;
        }
      }
      throw new Error("invalid enum: " + val);
    }
    static hasEnum(key: string): boolean {
      return key in (input as any);
    }
    static getEnumKey(item: T): string {
      for (const [k, v] of Object.entries(input)) {
        if (item[k]) {
          return k;
        }
      }
      throw new Error("invalid enum");
    }
  }
  return Enum as any as BinEnum<ObjToEnum<T>>;
}
