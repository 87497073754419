<template>
<shop-users clickable @pickItem="modalResult($event)" forceInner/>
</template>

<script>
import Dialog from '@feathers-client/mixins/Dialog';
import ShopUsers from "~/pages/shopUsers/index.vue"
export default {
    components: {
        ShopUsers,
    },
    mixins: [
        Dialog,
    ]
}
</script>
