
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
@Component
export default class EditorGroup extends Vue {
  @Prop()
  label: string;

  @Prop()
  nameType: string;

  @Prop()
  nameValue: any;

  @Prop()
  edit: any;

  @Prop({ type: Boolean, default: true })
  useEdit: boolean;

  @Prop({ type: Boolean })
  collapse: boolean;

  @Prop({ type: Boolean })
  hasPreview: boolean;

  @Prop()
  flex: boolean;

  @Prop({ type: Boolean, default: true })
  inlineEdit: boolean;

  get nameComponent() {
    return this.$schemas.customTypes[this.nameType]?.component;
  }

  editing = false;

  toggleEdit() {
    if (this.inlineEdit) {
      this.editing = !this.editing;
    } else {
      this.$emit("edit");
    }
  }
}
