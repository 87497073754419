
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
@Component
export default class EditorGroupObject extends Vue {
  @Prop()
  label: string;

  @Prop()
  value: any;

  @Prop({ type: Boolean, default: true })
  useEdit: boolean;

  @Prop({ type: Boolean })
  collapse: boolean;

  @Prop({ type: Boolean })
  contents: boolean;

  get inputValue() {
    return this.value || {};
  }
  set inputValue(v) {
    this.$emit("input", v);
  }

  beforeMount() {
    if (!this.value) {
      this.inputValue = {};
    }
  }
}
