

import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import { ProductDiscountInfo } from '@common/couponUtils'
import { OrderDiscountType } from '@common/common'

@Component
export default class DiscountLine extends Vue {

    @Prop()
    productInfo: ProductDiscountInfo

    @Prop()
    discount: OrderDiscountType

    @Prop(Boolean)
    showAll: boolean

    @Prop(Boolean)
    showAlways: boolean

    get amount() {
        return this.productInfo ? this.productInfo.appliedAmountInt : this.discount.totalInt
    }

    get discountInfo() {
        return this.productInfo ? this.productInfo.discount : this.discount;
    }
    
    get show() {
        return this.showAlways || this.amount || this.discountInfo.rewardPoints?.length;
    }
}

