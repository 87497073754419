
import {
  // @ts-ignore
  FindType as ServerFindType,
} from "@feathersjs/feathers";
// @ts-ignore
import { Component, Vue } from "@feathers-client";
import moment from "moment";

export interface CachedPlanInfo {
  autoRenew?: boolean;
  paymentDue?: number;
  periodEnd?: number;
  noticePeriod?: number;
}

@Component
export default class PlanInfoBanner extends Vue {
  get showBanner() {
    const now = Date.now() / 1000;
    return (
      this.cachedPlanInfo.periodEnd &&
      (now > this.cachedPlanInfo.paymentDue ||
        (!this.cachedPlanInfo.autoRenew &&
          this.cachedPlanInfo.noticePeriod &&
          this.cachedPlanInfo.periodEnd - this.cachedPlanInfo.noticePeriod / 1000 < now))
    );
  }

  cachedPlanInfo: CachedPlanInfo = {};
  async beforeMount() {
    this.cachedPlanInfo = this.$config.cachedPlanInfo || {};
    // @ts-ignore
    this.$feathers.service("planInfo/cached").on("patched", this.updateInfo);
    try {
      // @ts-ignore
      this.cachedPlanInfo = await this.$feathers.service("planInfo/cached").find({});
    } catch (e) {
      console.error(e);
    }
  }

  beforeDestroy() {
    // @ts-ignore
    this.$feathers.service("planInfo/cached").off("patched", this.updateInfo);
  }

  updateInfo(info: CachedPlanInfo) {
    this.cachedPlanInfo = info;
  }

  get endingIn() {
    return moment.unix(this.cachedPlanInfo.periodEnd).diff(new Date(), "days");
  }

  get expired() {
    const u = moment().unix();
    return u > this.cachedPlanInfo.periodEnd || u > this.cachedPlanInfo.paymentDue;
  }
}
