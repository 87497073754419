
import { Component, Vue, Prop } from "@feathers-client";

@Component
export default class Card extends Vue {
  @Prop()
  title: string;

  @Prop()
  color: string;

  @Prop()
  contentClass: any;

  @Prop({ type: Boolean, default: true })
  scrollable: boolean;

  @Prop({ type: Boolean })
  close: boolean;

  @Prop({ type: Boolean })
  flex: boolean;
}
