<template>
  <div>
    <div style="min-height: 400px" :style="{ background: bgColor }"></div>
    <media-library type="image/*" selecting multiple v-model="mediaLibrary" @selected="addFiles" />
  </div>
</template>

<script>
import _ from "lodash";
import SlotRender from "./SlotRender.vue";
import StaticRender from "./StaticRender.vue";
import MediaLibrary from "~/components/MediaLibrary.vue";
import "~/assets/quill/quill.snow.css";
import QuillBetterTable from "quill-better-table";
import "~/dep/domore-table/quill/quill-better-table.css";

const customFontSize = ["0", "1", "2", "3", "4", "5", "body", "footnote"];
const customFont = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

async function initQuillInner() {
  if (process.server) return;
  const Quill = (await import("quill/dist/quill")).default;
  window.Quill = Quill;
  const ImageResize = (await import("@botom/quill-resize-module")).default;
  Quill.register("modules/imageResize", ImageResize);

  var BaseImageFormat = Quill.import("formats/image");
  const ImageFormatAttributesList = ["alt", "width", "style"];

  class ImageFormat extends BaseImageFormat {
    static formats(domNode) {
      return ImageFormatAttributesList.reduce(function (formats, attribute) {
        if (domNode.hasAttribute(attribute)) {
          formats[attribute] = domNode.getAttribute(attribute);
        }
        return formats;
      }, {});
    }
    format(name, value) {
      if (ImageFormatAttributesList.indexOf(name) > -1) {
        if (value) {
          this.domNode.setAttribute(name, value);
        } else {
          this.domNode.removeAttribute(name);
        }
      } else {
        super.format(name, value);
      }
    }
  }

  Quill.register(ImageFormat, true);

  const Size = Quill.import("attributors/class/size");
  Size.whitelist = customFontSize;

  Quill.register("attributors/class/size", Size);

  Quill.register(
    {
      "modules/better-table": QuillBetterTable,
    },
    true,
  );

  // const Font = Quill.import("formats/font");
  // // We do not add Sans Serif since it is the default
  // Font.whitelist = ["1", "2", "3", "4", "5"];
  // Quill.register(Font, true);

  // const Parchment = Quill.import("parchment");

  // const customFontFamilyAttributor = new Parchment.ClassAttributor(
  //   "custom-family-attributor",
  //   "ql-font",
  // );
  // const customSizeAttributor = new Parchment.ClassAttributor("custom-size-attributor", "ql-size");
  // const customColorAttributor = new Parchment.StyleAttributor("custom-color-attributor", "color");

  // const ListItemBlot = Quill.import("formats/list");

  // class CustomListItem extends ListItemBlot {
  //   optimize(context) {
  //     super.optimize(context);

  //     if (this.children.length >= 1) {
  //       const child = this.children.head;
  //       const attributes = child?.attributes?.attributes;

  //       if (attributes) {
  //         for (const key in attributes) {
  //           const element = attributes[key];
  //           let name = element.keyName;
  //           const value = element.value(child.domNode);

  //           if (name === "color") super.format("custom-color-attributor", value);
  //           else if (name === "ql-font") super.format("custom-family-attributor", value);
  //           else if (name === "ql-size") super.format("custom-size-attributor", value);
  //         }
  //       } else {
  //         super.format("custom-color-attributor", false);
  //         super.format("custom-family-attributor", false);
  //         super.format("custom-size-attributor", false);
  //       }
  //     }
  //   }
  // }

  // Quill.register(customColorAttributor, true);
  // Quill.register(customFontFamilyAttributor, true);
  // Quill.register(customSizeAttributor, true);
  // Quill.register(CustomListItem, true);
}

let _initQuill;
function initQuill() {
  return _initQuill || (_initQuill = initQuillInner());
}

function escapeRegExp(text) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

export default {
  components: {
    SlotRender,
    StaticRender,
    MediaLibrary,
  },
  name: "quill-editor",
  data() {
    return {
      bgColor: null,
      mediaLibrary: false,
      _content: "",
      defaultModules: {
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          ["blockquote", "code-block"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }],
          [{ indent: "-1" }, { indent: "+1" }],
          [
            {
              color: [
                "#000000",
                "#e60000",
                "#ff9900",
                "#ffff00",
                "#008a00",
                "#0066cc",
                "#9933ff",
                "#ffffff",
                "#facccc",
                "#ffebcc",
                "#ffffcc",
                "#cce8cc",
                "#cce0f5",
                "#ebd6ff",
                "#bbbbbb",
                "#f06666",
                "#ffc266",
                "#ffff66",
                "#66b966",
                "#66a3e0",
                "#c285ff",
                "#888888",
                "#a10000",
                "#b26b00",
                "#b2b200",
                "#006100",
                "#0047b2",
                "#6b24b2",
                "#444444",
                "#5c0000",
                "#663d00",
                "#666600",
                "#003700",
                "#002966",
                "#3d1466",
                "custom-color",
              ],
            },
            {
              background: [
                "#000000",
                "#e60000",
                "#ff9900",
                "#ffff00",
                "#008a00",
                "#0066cc",
                "#9933ff",
                "#ffffff",
                "#facccc",
                "#ffebcc",
                "#ffffcc",
                "#cce8cc",
                "#cce0f5",
                "#ebd6ff",
                "#bbbbbb",
                "#f06666",
                "#ffc266",
                "#ffff66",
                "#66b966",
                "#66a3e0",
                "#c285ff",
                "#888888",
                "#a10000",
                "#b26b00",
                "#b2b200",
                "#006100",
                "#0047b2",
                "#6b24b2",
                "#444444",
                "#5c0000",
                "#663d00",
                "#666600",
                "#003700",
                "#002966",
                "#3d1466",
                "",
                "custom-color",
              ],
            },
          ],
          [{ size: customFontSize }],
          [{ font: [] }],
          [{ align: [] }],
          ["clean"],
          ["link", "image", "video"],
          ["table"],
          [],
        ],
        imageResize: {
          locale: {
            altTip: "Hold down the alt key to zoom",
            floatLeft: "Left",
            floatRight: "Right",
            center: "Center",
            restore: "Restore",
          },
        },
        table: false, // disable table module
        "better-table": {
          operationMenu: {
            items: {
              unmergeCells: {
                text: "Another unmerge cells name",
              },

              showTableBorder: {
                text: "Show Table Border",
                // iconSrc: operationIcon1,
                handler() {
                  const tableContainer = Quill.find(this.table);
                  console.log(this.quill.root.parentNode);
                  for (let el of tableContainer.domNode.querySelectorAll("td")) {
                    el.style = "";
                  }
                },
              },
              hideTableBorder: {
                text: "Hide Table Border",
                // iconSrc: operationIcon1,
                handler() {
                  const tableContainer = Quill.find(this.table);
                  console.log(this.quill.root.parentNode);
                  for (let el of tableContainer.domNode.querySelectorAll("td")) {
                    el.style = "border: none;";
                  }
                },
              },
            },
            color: {
              colors: [
                "#000000",
                "#e60000",
                "#ff9900",
                "#ffff00",
                "#008a00",
                "#0066cc",
                "#9933ff",
                "#ffffff",
                "#facccc",
                "#ffebcc",
                "#ffffcc",
                "#cce8cc",
                "#cce0f5",
                "#ebd6ff",
                "#bbbbbb",
                "#f06666",
                "#ffc266",
                "#ffff66",
                "#66b966",
                "#66a3e0",
                "#c285ff",
                "#888888",
                "#a10000",
                "#b26b00",
                "#b2b200",
                "#006100",
                "#0047b2",
                "#6b24b2",
                "#444444",
                "#5c0000",
                "#663d00",
                "#666600",
                "#003700",
                "#002966",
                "#3d1466",
              ], // colors in operationMenu
              text: "Background Colors", // subtitle
            },
          },
        },
        keyboard: {
          bindings: QuillBetterTable.keyboardBindings,
        },
      },
    };
  },
  props: {
    content: String,
    value: String,
    label: String,
    outlined: Boolean,
    hideDetails: Boolean,
    options: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
  async mounted() {
    await initQuill();
    this.initialize();
  },
  beforeDestroy() {
    this.quill = null;
  },
  methods: {
    async initialize() {
      this.applyLang();
      this.applyStyle();
      if (this.$el) {
        // options and instance
        var self = this;
        self.options.theme = self.options.theme || "snow";
        self.options.bounds = self.options.bounds || self.$el;
        self.options.modules = self.options.modules || self.defaultModules;
        self.options.modules.toolbar = self.options.modules.toolbar || self.defaultModules.toolbar;
        self.options.placeholder = self.placeholder;
        self.options.readOnly = self.options.readOnly !== undefined ? self.options.readOnly : false;
        self.options.modules.toolbar =
          self.options.modules.toolbar || defaultOptions.modules.toolbar;
        // self.options.modules.clipboard = self.options.modules.clipboard || {};
        // self.options.modules.clipboard.matchers = self.options.modules.clipboard.matchers || [];

        // self.options.modules.clipboard.matchers.push([
        //     'img',
        //     function matchStyles(node, delta) {
        //         var formats = {};
        //         var style = node.style || {};
        //         const img = delta?.ops?.[0];
        //         if(img) {
        //             img.attributes = img.attributes || {};
        //             img.attributes.style = 'background: red'
        //         }
        //         return delta;
        //     }
        // ])

        self.quill = new Quill(self.$el.children[0], self.options);
        self.quill.getModule("toolbar").addHandler("color", (value) => {
          // if the user clicked the custom-color option, show a prompt window to get the color
          if (value == "custom-color") {
            value = prompt("Enter Hex/RGB/RGBA");
          }

          self.quill.format("color", value);
        });

        self.quill.getModule("toolbar").addHandler("background", (value) => {
          // if the user clicked the custom-color option, show a prompt window to get the color
          if (value == "custom-color") {
            value = prompt("Enter Hex/RGB/RGBA");
          }

          self.quill.format("background", value);
        });

        // set editor content
        if (self.value || self.content) {
          self.quill.clipboard.dangerouslyPasteHTML(
            (self.value || self.content).replace(
              /\{\{ATTACHMENT\(([a-f\d]{24})\)\}\}/gi,
              (m, id) => {
                return `${this.url}/api/attachments/${id}`;
              },
            ),
          );
        }

        // mark model as touched if editor lost focus
        self.quill.on("selection-change", (range) => {
          if (!range) {
            self.$emit("blur", self.quill);
          } else {
            self.$emit("focus", self.quill);
          }
        });

        // update model if text changes
        self.quill.on("text-change", async (delta, oldDelta, source) => {
          var html = self.$el.querySelector(".ql-editor").innerHTML;
          const text = self.quill.getText();
          if (html === "<p><br></p>") html = "";

          const images = [...html.matchAll(/data:image\/[^;]+;base64[^"]+/gi)];

          for (let [image] of images) {
            const parts = image.split(",");
            if (parts.length !== 2) continue;
            const buf = Buffer.from(parts[1], "base64");
            const mime = parts[0].split(";")[0].split(":")[1] || "image/png";

            const id = await this.uploadImage(
              new Blob([buf], {
                type: mime,
              }),
            );

            html = html.replace(`${image}`, `${this.url}/api/attachments/${id}`);
          }

          html = html.replace(
            new RegExp(`${escapeRegExp(this.url + "/api/attachments/")}([a-f\\d]{24})`, "ig"),
            (m, id) => {
              return `{{ATTACHMENT(${id})}}`;
            },
          );
          self._content = html;
          self.$emit("input", self._content);
          self.$emit("change", {
            editor: self.quill,
            html: html,
            text: text,
          });
        });

        // quill editor add image handler
        self.quill.getModule("toolbar").addHandler("image", () => {
          this.mediaLibrary = true;
        });

        let tableModule = self.quill.getModule("better-table");

        let toolbar = self.quill.getModule("toolbar");
        toolbar.addHandler("table", function () {
          tableModule.insertTable(3, 3);
        });

        // emit ready
        self.$emit("ready", self.quill);
      }
    },

    applyLang() {
      _.forEach(this.lang, (v, k) => {
        document.documentElement.style.setProperty(k, `'${v}'`);
      });
    },

    applyStyle() {
      _.forEach(this.style, (v, k) => {
        document.documentElement.style.setProperty(k, `${v}`);
      });
    },

    addFiles(files) {
      this.mediaLibrary = false;
      const editor = this.quill;
      const range = editor.getSelection();
      for (let f of files) {
        editor.insertEmbed(
          range ? range.index : editor.getLength(),
          "image",
          `${this.url}/api/attachments/${f._id}`,
        );
      }
    },

    async uploadImage(file) {
      // generate a form data
      const data = new FormData();

      // or just append the file
      data.append("file", file);

      const res = await this.$feathers.post(
        `attachments/upload/${this.dir || "others"}/${this.parent || "others"}`,
        data,
      );

      return res.data.info._id;
    },
  },
  watch: {
    content(newVal, oldVal) {
      if (this.quill) {
        if (!!newVal && newVal !== this._content) {
          this._content = newVal;
          this.quill.clipboard.dangerouslyPasteHTML(
            newVal.replace(/\{\{ATTACHMENT\(([a-f\d]{24})\)\}\}/gi, (m, id) => {
              return `${this.url}/api/attachments/${id}`;
            }),
          );
        } else if (!newVal) {
          this.quill.setText("");
        }
      }
    },
    value(newVal, oldVal) {
      if (this.quill) {
        if (newVal !== this._content) {
          this._content = newVal;
          this.quill.clipboard.dangerouslyPasteHTML(
            newVal.replace(/\{\{ATTACHMENT\(([a-f\d]{24})\)\}\}/gi, (m, id) => {
              return `${this.url}/api/attachments/${id}`;
            }),
          );
        } else if (!newVal) {
          this.quill.setText("");
        }
      }
    },
    placeholder(val) {
      if (this.$el) {
        const editor = this.$el.getElementsByClassName("ql-editor")[0];
        if (editor) editor.setAttribute("data-placeholder", val);
      }
    },
    lang(val) {
      this.applyLang();
    },
  },
  computed: {
    url() {
      return this.$config.apiUrl;
    },
    placeholder() {
      return this.options.placeholder || this.$t("quill.placeholder");
    },
    lang() {
      return {
        "--ql-size-small": this.$t("quill.size.small"),
        "--ql-size-normal": this.$t("quill.size.normal"),
        "--ql-size-large": this.$t("quill.size.large"),
        "--ql-size-huge": this.$t("quill.size.huge"),

        "--ql-size-0": this.$t("quill.size.0"),
        "--ql-size-1": this.$t("quill.size.1"),
        "--ql-size-2": this.$t("quill.size.2"),
        "--ql-size-3": this.$t("quill.size.3"),
        "--ql-size-4": this.$t("quill.size.4"),
        "--ql-size-5": this.$t("quill.size.5"),
        "--ql-size-6": this.$t("quill.size.6"),
        "--ql-size-body": this.$t("quill.size.body"),
        "--ql-size-footnote": this.$t("quill.size.footnote"),

        "--ql-enter-video": this.$t("quill.enter-video"),
        "--ql-enter-formula": this.$t("quill.enter-formula"),
        "--ql-enter-link": this.$t("quill.enter-link"),

        "--ql-save": this.$t("basic.save"),
        "--ql-remove": this.$t("basic.remove"),

        "--ql-font-1": "Inconsolata",
        "--ql-font-2": "Roboto",
        "--ql-font-3": "Mirza",
        "--ql-font-4": "Arial",
        "--ql-font-5": "Times New Roman",
        "--ql-font-6": "Inconsolata",
        "--ql-font-7": "Roboto",
        "--ql-font-8": "Mirza",
        "--ql-font-9": "Arial",
        "--ql-font-10": "Times New Roman",
      };
    },
    style() {
      return {
        "--ql-font-style-1": "Inconsolata",
        "--ql-font-style-2": "Roboto",
        "--ql-font-style-3": "Mirza",
        "--ql-font-style-4": "Arial",
        "--ql-font-style-5": "Times New Roman",
        "--ql-font-style-6": "Inconsolata",
        "--ql-font-style-7": "Roboto",
        "--ql-font-style-8": "Mirza",
        "--ql-font-style-9": "Arial",
        "--ql-font-style-10": "Times New Roman",

        "--ql-size-style-0": this.$schemas.resolveFontCss({
          family: "GenJyuuGothic,Noto Sans HK,Roboto,sans-serif",
          variant: "regular",
          size: "54",
          lineHeight: "77",
        }),
        "--ql-size-style-1": this.$schemas.resolveFontCss({
          family: "GenJyuuGothic,Noto Sans HK,Roboto,sans-serif",
          variant: "",
          size: "38",
          lineHeight: "46",
        }),
        "--ql-size-style-2": this.$schemas.resolveFontCss({
          family: "GenJyuuGothic,Noto Sans HK,Roboto,sans-serif",
          variant: "",
          size: "30",
          lineHeight: "40",
        }),
        "--ql-size-style-3": this.$schemas.resolveFontCss({
          family: "GenJyuuGothic,Noto Sans HK,Roboto,sans-serif",
          variant: "",
          size: "24",
          lineHeight: "32",
        }),
        "--ql-size-style-4": this.$schemas.resolveFontCss({
          family: "GenJyuuGothic,Noto Sans HK,Roboto,sans-serif",
          variant: "",
          size: "20",
          lineHeight: "28",
        }),
        "--ql-size-style-5": this.$schemas.resolveFontCss({
          family: "'GenJyuuGothic,Noto Sans HK,Roboto,sans-serif",
          variant: "",
          size: "16",
          lineHeight: "24",
        }),
        "--ql-size-style-body": this.$schemas.resolveFontCss({
          family: "'GenJyuuGothic,Noto Sans HK,Roboto,sans-serif",
          variant: "",
          size: "14",
          lineHeight: "22",
        }),
        "--ql-size-style-footnote": this.$schemas.resolveFontCss({
          family: "'GenJyuuGothic,Noto Sans HK,Roboto,sans-serif",
          variant: "regular",
          size: "12",
          lineHeight: "20",
        }),
      };
    },
  },
};
</script>

<style lang="scss">
.quill-editor img {
  max-width: 100%;
}

.ql-background {
  .ql-picker-options [data-value=""] {
    background: linear-gradient(to top left, #fff calc(50% - 1px), black, #fff calc(50% + 1px));
  }
}

.ql-color,
.ql-background {
  .ql-picker-options [data-value="custom-color"] {
    background: none !important;
    width: 100% !important;
    height: 20px !important;
    text-align: center;
  }
}
.ql-color,
.ql-background {
  .ql-picker-options [data-value="custom-color"]:before {
    content: "Custom Color";
  }
}
.ql-color,
.ql-background {
  .ql-picker-options [data-value="custom-color"]:hover {
    border-color: transparent !important;
  }
}

.ql-toolbar.ql-snow,
.ql-container.ql-snow {
  border: 0;
}

* >>> .qlbt-operation-menu {
  z-index: 300 !important;
  overflow-y: scroll;
  height: 400px;
}
</style>
