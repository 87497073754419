

import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import _ from "lodash";
import { PaymentType } from '@common/common'
import EditorObjectPicker from '@schemaEditor/EditorObjectPicker.vue'

@Component({
    components: {
        EditorObjectPicker,
    }
})
export default class PaymentOptionsEditor extends Vue {
    @Prop()
    label : string

    @Prop()
    value : number | null

    @Prop()
    item : PaymentType

    get needOptions() {
        return this.item?.type === 'payme' || this.item?.type === 'yedpay' || this.item?.type === 'bbposWeb'
    }

    get inputValue() {
        const v = this.value;
        return v || {};
    }

    set inputValue(v) {
        this.$emit('input', v);
    }

    get options() {
        const self = this;
        const keys = ['signingKey', 'signingKeyId', 'clientId', 'clientSecret', 'domain', 'privateKey', 'publicKey'] as const;

        const r : Record<(typeof keys)[number], any> = {} as any;
        for(let key of keys) {
            Object.defineProperty(r, key, {
                get() { return self.inputValue[key] },
                set(v) {
                    self.inputValue = {
                        ...self.inputValue,
                        [key]: v
                    }
                }
            })
        }

        return r;
    }
};
