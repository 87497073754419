
import { Vue, Component, Prop, Watch } from 'nuxt-property-decorator'

@Component
export default class VirtualDataListRow extends Vue {

    @Prop()
    source: {
        _id: number,
        items: any[]
    }

    @Prop()
    columns: number

    @Prop()
    itemKey: string

    @Prop()
    component : any

}

