

import helper from '@feathers-client/helper'
import { Context } from '@nuxt/types';

let templateContext: any;

export function getTemplateContext() {
  return templateContext;
}

export function setTemplateContext(context: any) {
  templateContext = context;
}

export default helper('schemaEditorAddonsPosPrinter', (ctx : Context) => {
    if(process.server) return;
    ctx.app.$schemas.registerType("printerTemplate", {
        component: () => import('./TemplateEditor.vue'),
    });
});

