
import { Component, Prop, Vue, Watch, mixins, Ref, VModel } from "nuxt-property-decorator";

@Component
export default class MenuListItem extends Vue {
  @Prop()
  text: string;

  @Prop({ default: "w-6 h-6 box-border" })
  checkerClass: any;

  @VModel()
  inputValue: boolean;

  @Prop(Boolean)
  button: boolean;

  @Prop()
  toggleFunction: (res: boolean) => Promise<boolean>;

  changed = false;

  async toggleValue() {
    if (this.toggleFunction) {
      if(await this.toggleFunction(!this.inputValue)) {
        this.changed = true;
      }
    } else {
      this.inputValue = !this.inputValue;
      this.changed = true;
    }
  }
}
