
import _ from "lodash";
import { Component, Prop, Watch, Vue, lowSpec, medSpec } from "@feathers-client";
import moment from "moment";
import OrderPopup from "~/components/boxs/OrderPopup.vue";

import StatusHost from "@feathers-client/components/StatusHost.vue";
import DialogHost from "@feathers-client/components/DialogHost.vue";
import FullscreenHost from "@feathers-client/components/FullscreenHost.vue";
import preventOverscroll from "@feathers-client/directives/preventOverscroll";
import PlanInfoBanner from "@feathers-client/planInfo/banner.vue";
import {
	supported,
	getLogin,
	setLogin,
	logout,
	reportLogin,
	loginLoader,
	switchOtherLogin,
} from "pos-printer/utils/nativeAuth";
import url from "url";
import { supported as appSupported, setStatusBarHidden } from "pos-printer/utils/app";

import type { NavItem } from "@feathers-client/components/BoxsNavBar/index.vue";

interface NavFilter {
	role?: "user" | "pos" | "admin" | "warehouse";
	permission?: string;
}

type NavItemWithFilter = NavItem & {
	feature?: string | string[];
	cond?: NavFilter[] | NavFilter;
	attribute?: string;
	shopAttribute?: string;
	items?: NavItemWithFilter[];
};

@Component({
	components: {
		StatusHost,
		DialogHost,
		FullscreenHost,
		OrderPopup,
		PlanInfoBanner,
	},
	directives: {
		preventOverscroll,
	},
	provide: {
		__reactiveInject__: null,
	},
})
export default class Default extends Vue {
	drawer: null | boolean = null;
	mini = false;
	lang = false;

	head() {
    const title = this.$td(this.$store.state.title && this.$store.state.title.$t ? { ...this.$store.state.title, $ta: this.$store.getters.margs } : this.$store.state.title);
    const titleParts = [title, this.$config.appName || 'boxsStore'].filter(it => !!it).join(" | ");
		return {
      title: titleParts,
			htmlAttrs: {
				style: [this.$store.state.useFontSize ? `font-size: ${this.$pos.fontScaleValue}%` : ""].join(" "),
				class: [
					this.$store.state.disableTextSelect ? "disable-text-select" : "",
					this.$store.state.fullPage ? "root-full-page" : "",
          lowSpec ? "low-gpu" : "",
          medSpec ? "med-gpu" : "",
				].join(" "),
			},
		};
	}

	get dark() {
		return this.$store.state.dark;
	}

	get shop() {
		return this.$store.state.shop;
	}

	get fullPage() {
		return this.$store.state.fullPage;
	}

	get attributes() {
		return this.$store.getters.attributes;
	}

	get role() {
		return this.$store.getters.role;
	}

	get isAdmin() {
		return this.role === "admin";
	}

	get isUser() {
		return this.isAdmin || this.role === "user";
	}

	get isPos() {
		return this.isUser || this.role === "pos";
	}

	get isWarehouse() {
		return this.isUser || this.role === "warehouse";
	}

	get fullscreen() {
		return this.$store.state.fullscreen;
	}

	get items(): NavItem[] {
		return this.$store.getters["userId"] &&
			(this.$config.features.verify === false || this.$store.getters["isVerified"])
			? this.$store.state.tableMode
				? this.normalizeNav(this.posItems)
				: this.normalizeNav(this.logginedItems)
			: this.normalizeNav(this.guestItems);
	}

	normalizeNav(items: NavItemWithFilter[]): NavItem[] {
		return items
			.map(item => {
				if (item.feature) {
					const features = Array.isArray(item.feature) ? item.feature : [item.feature];
					const isFullfilled = features.find(f => _.get(this.$config.features, f));
					if (!isFullfilled) return null;
				}
				if (
					item.attribute &&
					(item.attribute.startsWith("!")
						? this.attributes[item.attribute.substring(1)]
						: !this.attributes[item.attribute])
				) {
					return null;
				}
				if (
					item.shopAttribute &&
					(item.shopAttribute.startsWith("!")
						? this.$shop[item.shopAttribute.substring(1)]
						: !this.$shop[item.shopAttribute])
				) {
					return null;
				}
				if (item.cond) {
					const conds = Array.isArray(item.cond) ? item.cond : [item.cond];
					const isFullfilled = conds.find(c => {
						if (c.role) {
							switch (c.role) {
								case "warehouse":
									if (this.role === "warehouse") break;
									return false;
								case "pos":
									if (this.role === "pos") break;
								case "user":
									if (this.role === "user") break;
								case "admin":
									if (this.role === "admin") break;
								default:
									return false;
							}
						}
						if (c.permission && !this.$store.getters.permissionDict[c.permission]) {
							return false;
						}
						return true;
					});
					if (!isFullfilled) return null;
				}
				if (item.items) {
					const child = this.normalizeNav(item.items);
					if (!child.length) return null;
					return {
						...item,
						items: child,
					};
				}
				return item;
			})
			.filter(it => !!it);
	}

	get guestItems(): NavItemWithFilter[] {
		return [
			{
				href: "/login",
				title: "pages.login",
				action: "vpn_key",
				exact: false,
			},
			{
				href: "/register",
				title: "pages.register",
				action: "create",
				feature: "register",
			},
		];
	}

	get logginedItems(): NavItemWithFilter[] {
		return [
			{
				href: "/dashboard",
				title: "pages.home",
				action: "home",
				exact: true,
				cond: { role: "user" },
			},

			{
				href: "/pos",
				title: "pages.pos",
				action: "shopping_cart",
				attribute: "pos",
				cond: { role: "pos" },
			},

      {
        href: "/ips",
        title: "pages.ips.$",
        exact: true,
        cond: { role: "admin" },
      },

			{
				href: "/orders",
				title: "pages.sales",
				action: "$bill",
				exact: true,
				cond: [{ role: "user" }, { role: "pos" }],
				items: [
					{
						href: "/orders",
						title: "pages.orders",
						action: "fa fa-shopping-cart",
						exact: true,
						cond: [{ role: "user" }, { role: "pos" }],
					},
					{
						href: "/payments",
						title: "pages.payments",
						action: "history",
						cond: { role: "user" },
					},
					{
						title: "pages.groups.twInvoices.$",
						action: "fas fa-money-check-alt",
						href: "/shop/tw/invoices/",
						feature: "turnCloud",
						cond: [{ role: "user" }, { role: "pos" }],
					},
					{
						href: "/shippings",
						title: "pages.shippings.$",
						action: "fa fa-truck",
						exact: true,
						cond: [{ role: "user" }, { role: "pos" }],
						attribute: "!selfShipping",
					},
					{
						href: "/shop/warranties",
						title: "pages.shop/warranties.$",
						action: "history",
						attribute: "warranty",
					},
				],
			},
			{
				href: "/shippings",
				title: "pages.shippings.$",
				action: "fa fa-truck",
				items: [
					{
						href: "/shippings",
						title: "pages.shippings.allItems",
						action: "fa fa-truck",
						exact: true,
					},
					{
						href: "/shippings/schedule",
						title: "pages.shippings.schedule.$",
						action: "fa fa-calendar",
					},
					{
						href: "/shippings/driver",
						title: "pages.shippings.driver.$",
						action: "fa fa-check-double",
					},
					{
						href: "/report/driverReport",
						title: "pages.shippings.driverReport.$",
						action: "fa fa-check-double",
					},
				],
				cond: [{ role: "user" }, { role: "pos" }],
				attribute: "selfShipping",
			},

			{
				href: "/products",
				title: "pages.productInfo",
				action: "fas fa-cube",
				cond: { role: "user" },
				items: [
					{
						href: "/products",
						title: "pages.products",
						action: "fas fa-cube",
						cond: { role: "user" },
						exact: true,
					},
					{
						href: "/products/skus",
						title: "pages.shop/product/skus.$",
						action: "fas fa-cube",
						cond: { role: "user" },
					},
					{
						href: "/shop/product/options",
						title: "pages.shop/product/options.$",
						action: "fas fa-box",
					},
					{
						href: "/shop/product/customizations",
						title: "pages.shop/product/customizations.$",
						action: "fas fa-brush",
						attribute: "productCust",
					},
					{
						href: "/eshop/categories",
						title: "pages.categories",
						action: "fas fa-boxes",
						exact: false,
					},
					{
						href: "/eshop/brands",
						title: "pages.brands",
						action: "fa-star",
						exact: false,
					},
					{
						href: "/eshop/specs/",
						title: "pages.specs.$",
						action: "fas fa-info",
						exact: true,
					},
					{
						href: "/eshop/specs/values/",
						title: "pages.spec/values.$",
						action: "fas fa-hashtag",
						exact: false,
					},
					{
						href: "/eshop/tags/",
						title: "pages.tags.$",
						action: "$tag",
						exact: false,
					},
					{
						href: "/eshop/relateds/",
						title: "pages.shop/product/relateds.$",
						action: "fas fa-object-group",
						exact: false,
					},
				],
			},

			{
				title: "pages.bookings.$",
				attribute: "booking",
				action: "calendar_month",
				items: [
					{
						href: "/shop/booking/settings",
						title: "pages.bookings.settings.$",
					},
					{
						href: "/shop/booking/timetables",
						title: "pages.bookings.timetables.$",
					},
				],
				cond: { role: "user" },
			},

			{
				href: "/warehouses",
				title: "pages.warehouses.$",
				action: "$inventory",
				items: [
					{
						href: "/warehouses/inventories",
						title: "pages.warehouses.inventories",
						action: "fa fa-archive",
						exact: false,
					},
					{
						href: "/warehouses/inventoryLogs",
						title: "pages.warehouses.inventoryLogs",
						action: "fas fa-history",
						exact: false,
					},
					{
						href: "/warehouses/inventoryActions",
						title: "pages.warehouses.inventoryActions",
						action: "fas fa-history",
						exact: false,
					},
				],
				cond: [{ role: "user" }, { role: "pos", permission: "warehouse" }, { role: "warehouse" }],
			},

			{
				href: "/eshop",
				title: "pages.eshop",
				action: "web",
				exact: false,
				items: [
					{
						href: "/eshop/banners",
						title: "pages.banners.$",
						action: "fas fa-audio-description",
						exact: false,
					},
					{
						href: "/eshop/articles",
						title: "pages.articles",
						action: "fas fa-newspaper",
						exact: false,
					},
					{
						href: "/eshop/ecshopArticles",
						title: "pages.articlePage",
						action: "fas fa-newspaper",
						exact: false,
					},
					{
						href: "/eshop/pages",
						title: "pages.ecshop.$",
						action: "fas fa-newspaper",
						exact: false,
					},
					{
						href: "/newsSubscription",
						title: "pages.shop/subscriptions.$",
						action: "fas fa-newspaper",
						exact: false,
					},
					{
						href: "/eshop/contactForm",
						title: "pages.shop/contactForm.$",
						action: "fas fa-newspaper",
						exact: false,
					},
					{
						href: "/eshop/storeLocations",
						title: "pages.shop/storeLocation.$",
						action: "fas fa-newspaper",
						exact: false,
					},
					{
						href: "/eshop/storeTags",
						title: "pages.shop/storeTag.$",
						action: "$tag",
						exact: false,
					},
					{
						href: "/eshop/warranties",
						title: "pages.shop/warranty.$",
						action: "fas fa-newspaper",
						exact: false,
						attribute: "warranty",
					},
					{
						href: "/eshop/contactField/",
						title: "pages.contactField.$",
						action: "fas fa-info",
						exact: true,
					},
					{
						href: "/eshop/contactField/values/",
						title: "pages.contactField/values.$",
						action: "fas fa-hashtag",
						exact: false,
					},
					{
						href: "/eshop/settings/",
						title: "pages.ecshopSetting.$",
						action: "settings",
						exact: false,
					},
				],
				cond: { role: "user" },
			},

			// discounts
			{
				href: "/discounts",
				title: "pages.discountsAndPromotions",
				action: "$promo",
				items: [
					{
						href: "/discounts",
						title: "pages.discounts",
						action: "fas fa-percentage",
						cond: { role: "user" },
						attribute: "discount",
					},
					{
						href: "/couponTemplates",
						title: "pages.couponTemplates",
						action: "fas fa-ticket-alt",
						cond: { role: "user" },
						attribute: "discount",
					},
					{
						href: "/promotions",
						title: "pages.promotions",
						action: "$promo",
						cond: { role: "user" },
						attribute: "promotion",
					},
				],
			},

			{
				href: "/charts",
				title: "pages.charts",
				action: "$chart",
				cond: { role: "user" },
			},

			{
				href: "/shopUsers",
				title: "pages.shopUsers.$",
				action: "people",
				cond: { role: "user" },
			},

			{
				href: "/erp",
				title: "ERP", // "pages.subsequentPayments",
				action: "fas fa-credit-card",
				items: [
					{
						href: "/companyUsers",
						title: "pages.companyUsers",
						action: "people",
						attribute: "erp",
					},
					{
						href: "/erp/purchasingOrders",
						title: "pages.purchasingOrders",
						action: "fa fa-shopping-cart",
						attribute: "erp",
					},
					{
						href: "/erp/wholesaleOrders",
						title: "pages.bulkOrders",
						action: "fa fa-shopping-cart",
						attribute: "erp",
					},
					// {
					//   href: "/bulkOrders",
					//   title: "pages.bulkOrderss",
					//   action: "fa fa-shopping-cart",
					//   attribute: "erp",
					// },
				],
				cond: { role: "user" },
				feature: "erp",
			},

			{
				href: "/extras",
				title: "pages.extras.$",
				action: "fas fa-splotch",
				items: [
					{
						href: "/extras/receipts",
						title: "pages.extras.shopUserReceipts.$",
						action: "fas fa-receipt",
						feature: "extra.userReceipt",
					},
				],
				cond: { role: "admin" },
				feature: "extra",
			},

			{
				href: "/settings",
				title: "pages.eshop_settings.$",
				action: "settings",
				exact: false,
				cond: { role: "admin" },
			},

			// {
			//   href: "/profile",
			//   title: "pages.profile.$",
			//   action: "supervised_user_circle",
			//   exact: false,
			// },
			// { href: "/logout", title: "pages.logout", action: "exit_to_app" },
		];
	}

	get posItems(): NavItemWithFilter[] {
		return [
			{
				href: "/pos",
				title: "pages.pos",
				action: "shopping_cart",
				attribute: "pos",
				cond: { role: "pos" },
				exact: true,
			},
			{
				href: "/pos/openClose",
				title: "pos.openClose.$",
				action: "add_business",
				attribute: "pos",
				cond: { role: "pos" },
			},
			{
				href: "/pos/cashBox",
				title: "pos.cashBox.$",
				action: "account_balance",
				attribute: "pos",
				cond: { role: "pos" },
			},
			{
				href: "/pos/punch",
				title: "pos.punch.$",
				action: "manage_search",
				attribute: "pos",
				cond: { role: "pos" },
			},
			{
				href: "/pos/settings",
				title: "pages.posSettings.$",
				action: "settings",
				attribute: "pos",
				cond: { role: "pos" },
			},
		];
	}

	async beforeMount() {
		this.$vuetify.theme.dark = this.dark;
		this.$store.commit("INIT_LOCAL");

		
        const site = (
			await this.$feathers.service("shop/sites").find({
				query: {
				$paginate: false,
				},
				paginate: false,
			})
		)?.[0];
		if (site) {
			this.$store.commit("SET_CUSTOMFONTS", site.customTextStyle || []);
			this.$store.commit("SET_QUILLEDITORFONTLIST", site.editorFontList || []);
			this.$store.commit("SET_QUILLEDITORPRESETSTYLE", site.textStyle || []);
		}
    // const fonts = await this.$feathers.service("googleFonts").find();
    // console.log(fonts)
    // this.$store.commit("SET_FONTS", fonts);
	}
	async mounted() {
		this.$store.commit("SET_HYDRATED");
		if (await supported()) {
			this.nativeAuthSupported = true;
		}
	}

	changeShop(shop) {
		this.$store.commit("SET_SHOP", shop);
		this.$router.replace("/dashboard");
	}

	onOrderPatched(order) {
		console.log("order patched", order);
	}

	formatDate(date) {
		return moment(date).format("YYYY-MM-DD HH:mm:ss");
	}

	@Watch("fullscreen")
	onFullScreenChanged(val) {
		if (val) this.drawer = false;
	}

	@Watch("drawer")
	onDrawer(val) {
		if (val && this.fullscreen) {
			Vue.nextTick(() => {
				this.drawer = false;
			});
		}
	}

	@Watch("dark")
	onDark(val) {
		this.$vuetify.theme.dark = val;
		if (val) {
			this.mini = true;
		} else {
			this.mini = false;
		}
	}

	loginLoader = loginLoader;
	switchOtherLogin = switchOtherLogin;
	nativeAuthSupported = false;

	async switchLogin(auth: any) {
		if (await supported()) {
			try {
				await setLogin({
					jwt: auth.accessToken,
					startURL: url.resolve(location.toString(), this.$router.resolve("/pos").href),
					username: auth.user?.email?.toLowerCase?.() ?? "",
				});
			} catch (e) {
				console.warn(e);
			}
		}
	}

	appSupported: boolean | string[] = null;
	appHideSupported = false;

	@Watch("$store.state.customHeader", { immediate: true })
	async onTableMode(v: boolean) {
		if (this.appSupported === null) {
			this.appSupported = await appSupported();
		}
		if (this.appSupported && Array.isArray(this.appSupported) && this.appSupported.includes("setStatusBarHidden")) {
			try {
				this.appHideSupported = await setStatusBarHidden(v);
			} catch (e) {
				this.appSupported = false;
				console.warn(e);
			}
		}
  }

  get isDefaultName() {
    return !this.$config.appName || this.$config.appName === "boxsStore" || this.$config.appName === "BOXS"
  }
}
