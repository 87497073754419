
import { Vue, Component, Prop, VModel } from "@feathers-client";
// @ts-ignore
import RarrowSvg from "!vue-svg-loader!./assets/rarrow.svg";

@Component({
  components: {
    RarrowSvg,
  },
})
export default class RatioPicker extends Vue {
  @VModel()
  inputValue: string;

  @Prop()
  label: string;

  values = [
    { value: "1", name: "1:1" },
    { value: "16/9", name: "16:9" },
    { value: "16/10", name: "16:10" },
    { value: "21/9", name: "21:9" },
    { value: "32/9", name: "32:9" },
    { value: "4/3", name: "4:3" },
    { value: "3/4", name: "3:4" },
  ];

  isOpen: boolean = false;

  get displayValue() {
    return this.values.find(value => value.value === this.inputValue)?.name || "";
  }
}
