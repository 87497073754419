
import _ from "lodash";
import { Component, Prop, Vue, Watch, mixins, Ref, PropSync } from "@feathers-client";
import type { Order } from "@common/pos";

@Component
export default class CartPriceDetails extends Vue {
  @Prop()
  session: Order;
}
