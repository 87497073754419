

import { ProductType, WeightType } from './common'

const weightRate : { [key : string] : number } = {
    // = ?kg
    "kg": 1,
    "g": 1 / 1000,
    "oz": 0.0283495231,
    "lb": 0.45359237,
    "tael": 37.799364167 / 1000,
    "hk_catty": 0.60478982,
}

export function convertWeight(value : number, unit : WeightType = 'kg', toUnit : WeightType = 'kg') {
    if(!weightRate[unit]) throw new Error(`Unsupported unit ${unit}`);
    if(!weightRate[toUnit]) throw new Error(`Unsupported unit ${toUnit}`);

    return value * weightRate[unit] / weightRate[toUnit];
}
