
import { Component, Vue, Prop } from "@feathers-client";
import { LangType } from "@feathers-client/i18n";

@Component
export default class MultiLangText extends Vue {
  @Prop()
  text: LangType;

  @Prop()
  texts: LangType[];

  @Prop()
  t: string;

  @Prop(Boolean)
  line: boolean;

  @Prop(Boolean)
  showEmpty: boolean;

  get textNormalized() {
    return this.text ? this.text : this.t ? { $t: this.t } : this.texts ? { $join: this.texts } : '';
  }

  get primary() {
    return this.$td(this.textNormalized) || this.secondaryText;
  }

  get secondary() {
    if (this.secondaryText && this.secondaryText !== this.primary) return this.secondaryText;
  }

  get secondaryText() {
    const locale = this.$store?.state?.secondaryLocale;
    if (locale && typeof this.textNormalized === "object") {
      return this.$td(this.textNormalized, locale);
    }
  }
}
