
import { Vue, Component, Prop } from "@feathers-client";
import { mappedLocales } from "@schemaEditor/plugin";
import { SiteEditorManager } from ".";
import { Watch } from "nuxt-property-decorator";

@Component
export default class LangPicker extends Vue {
  @Prop()
  manager: SiteEditorManager;

  changeLang = false;

  async beforeMount() {
    this.$schemas.onLocale(this.$i18n.locale);
  }

  get localeName() {
    return this.locales.find(v => v._id === this.$schemas.locale).name;
  }

  get locales() {
    return Object.entries(this.$store.state.locales).map(([k, v]) => ({
      _id: mappedLocales[k] || k,
      name: (v as any).name,
    }));
  }

  onChangeLang() {
    this.changeLang = true;
  }

  @Watch("$schemas.locale")
  onConfirmLang() {
    this.changeLang = false;
  }
}
