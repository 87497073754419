
import { Component, Prop, PropSync, Vue, Watch } from "@feathers-client";

@Component
export default class NumCounter extends Vue {
  @Prop({ type: Number, required: true })
  quantity: number;

  @Prop({ type: Boolean, default: true })
  showRemove: boolean;

  @Prop({ type: Number, default: 1 })
  min: number;

  @Prop({ type: Number })
  max: number;

  get quantityP() {
    return this.quantity;
  }

  set quantityP(v: number) {
    if (this.min > v) {
      this.$emit("update:quantity", this.min);
    } else if (this.max != null && v > this.max) {
      this.$emit("update:quantity", this.max);
    } else {
      this.$emit("update:quantity", v);
    }
  }

  minus() {
    // if (this.quantityP - 1 < this.min || this.quantityP - 1 === 0) {
    //   this.$emit("remove");
    // }
    // else {
    //   this.quantityP -= 1;
    // }
    if (this.quantityP - 1 < this.min || this.quantityP - 1 === 0) {
      this.$emit("remove");
    }
    this.quantityP -= 1;
  }
}
