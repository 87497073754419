


import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import _ from 'lodash'
import StateProvider from "./StateProvider";

@Component
export default class SelectProvider extends mixins(StateProvider) {

    @Prop({ type: Boolean, default: false })
    select : boolean;

    @Prop()
    selected : any[];
    selectedLocal: any[] = [];

    @Prop({ type: Boolean, default: false })
    noMulti : boolean

    get mselected() { return (this.select ? this.selected : this.selectedLocal) || [] }
    set mselected(val) { 
        if(this.select) {
            this.$emit('update:selected', val);
        } else {
            this.selectedLocal = val;
        }
    }
    get mselect() {
        return this.select || this.mselected.length > 0 && !this.noMulti;
    }
    get selectState() {
        const selected = this.mselected;
        const dict = _.fromPairs(_.map(this.mitems, it => [_.get(it, this.mItemKey), true]));
        let num = 0;
        _.each(selected, it => {
            if(dict[_.get(it, this.mItemKey)]) {
                num++;
            }
        });
        if(selected.length === this.total) return 'all';
        else if(num === this.mitems.length) return 'pageAll';
        else if(num) return 'pageSome';
        else if(selected.length) return 'some';
        else return 'none';  
    }

    async deleteItemCore(mitem : any, delay? : boolean) {
        const service = this.data.path && (this as any).mfeathers.service(this.data.path);
        let cb : () => void = null;
        if(!service) {
            cb = () => {
                const idx = this.mitems.findIndex(it => _.get(mitem, this.mItemKey) === _.get(it, this.mItemKey));
                idx !== -1 && this.mitems.splice(idx, 1);
            }
        } else {
            if (this.data.subpath) {
                const result = await service.patch(this.data.id, {
                    '$pull': {
                        [this.data.subpath]: {
                            [this.mItemKey]: _.get(mitem, this.mItemKey)
                        }
                    }
                });
                this.setPropHandler(this.mvalue, result);
            } else {
                await service.remove(_.get(mitem, this.mItemKey), { query: {...this.data.filter || {}} });
                cb = () => {
                    const idx = this.mitems.findIndex(it => _.get(mitem, this.mItemKey) === _.get(it, this.mItemKey));
                    idx !== -1 && this.mitems.splice(idx, 1);
                    this.currentDeltaTotal--;
                }
            }
        }

        const fcb = () => {
            if(cb) cb();
            const cur = _.get(mitem, this.mItemKey);
            const selected = this.mselected.find(it => _.get(it, this.mItemKey) === cur);
            this.mselected = _.filter(this.mselected, it => it !== selected);
            this.$emit('update:items', this.mitems);
        }
        if(delay) return fcb;
        fcb();
    }
}

  

