
import type { Order } from "@common/pos";
import { Component, FindType, Prop, Ref, VModel, Vue } from "@feathers-client";
import Editor from "@schemaEditor/Editor.vue";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import { UserType } from "~/common/common";

type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

@Component({
  components: {
    Editor,
    EditorTextField,
  },
})
export default class People extends Vue {
  @VModel()
  menu: boolean;

  @Prop()
  session: Order;

  @Ref()
  userTable: Editor;

  setUser(user: FindType<"shop/users">) {
    this.session.setUser(user);
  }

  query: any = {};

  currentPage = "";
  searchKeyword = "";

  user: DeepPartial<UserType> = {
    name: {
      firstName: "",
      lastName: "",
    },
    phone: "",
    areaCode: "852",
  };

  @Ref()
  search: HTMLInputElement;

  goSearch() {
    this.search?.blur?.();
    this.query = {
      ...this.query,
      $keyword: this.searchKeyword,
    };
  }

  selectAll() {
    this.search?.select?.();
  }

  async addUser() {
    const staffName = this.session.item?.staff
      ? (await this.$feathers.service("shop/staffs").get(this.session.item.staff))?.name ?? ""
      : "";

    const user = await this.$feathers.service("shop/users").create({
      createStaff: staffName,
      ...this.user,
    } as any);

    console.log(user);

    this.session.setUser(user);
  }
}
