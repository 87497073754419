
import { Component, Vue, Prop } from "@feathers-client";
import { LangArrType } from "@feathers-client/i18n";
import { defineComponent } from "site-editor/def";
import { SiteEditorTextWithImageItem } from "../../editorComponents/text.vue";
import { SiteEditorButton } from "../../editorComponents/headerImage.vue";

export const componentInfo = defineComponent({
  group: "text",
  groupProps: {
    editorCols: 2,
  },
  variants: [
    {
      key: "text",
    },
    {
      key: "textWithImage",
    },
  ],
});

@Component
export default class SiteEditorText extends Vue {
  @Prop()
  variant: string;

  @Prop()
  title: LangArrType;

  @Prop()
  content: LangArrType;

  @Prop()
  titleDisplayMode: "left" | "center" | "right";

  @Prop()
  remarks: LangArrType;

  @Prop()
  fontColor: string;

  @Prop()
  itemPerRow: number;

  @Prop()
  displayPosition: "column" | "row";

  @Prop()
  items: SiteEditorTextWithImageItem[];

  @Prop()
  mainButton: SiteEditorButton;

  @Prop()
  subButton: SiteEditorButton;

  getLinkProp(url: string, urlType: string) {
    if (!url) return {};
    if (urlType === "external") return { href: url, target: "_blank", rel: "noopener noreferrer" };
    else return { to: this.$localePath(url) };
  }

  getLink(url: string, urlType: string) {
    if (!url) return "div";
    if (urlType === "internal") {
      return "nuxt-link";
    } else {
      return "a";
    }
  }
}
