
import { Component, Vue, Prop, Watch, FindType } from "@feathers-client";
import { Pop } from "@feathersjs/feathers";
import _ from "lodash";
// @ts-ignore
import rarrowSvg from "!vue-svg-loader!../../../assets/rarrow.svg";
// @ts-ignore
import hotspotSvg from "!vue-svg-loader!../../../assets/hotspot.svg";
import { SiteEditorImageWithPinSlideShow, SiteEditorPinItem } from "../../../editorComponents/ImageWithPin.vue";
import { Ref } from "nuxt-property-decorator";

@Component({
  components: {
    rarrowSvg,
    hotspotSvg,
  },
})
export default class SlideShow extends Vue {
  @Prop()
  slideShow: SiteEditorImageWithPinSlideShow;

  @Prop({ type: String, default: "#FFFFFFFF" })
  hotspotColor: string;

  skus: Pop<["specs.specValue"], FindType<"shop/product/skus">>[] = [];

  selectedPin = 0;

  @Ref()
  productDetail: any;

  searchItems: Record<string, SiteEditorPinItem> = {};

  getItem(id: string, idx: number) {
    return this.searchItems[id] || this.slideShow.items[idx];
  }

  async beforeMount() {
    this.getCurrentProductSkus();
    const searches = await this.$feathers.service("shop/product/searches").find({
      query: {
        productGroup: { $in: _.map(this.slideShow.items, item => item.productGroup) },
        $paginate: false,
      },
      paginate: false,
    });

    const mappedSearchItems = searches.map(search => {
      return {
        ...search,
        file: search.mainImage || null,
        title: search.name ? search.name : "",
        slug: search.slug ? search.slug : "",
        price: search.pricing[0]?.min?.price ?? 0,
        urlType: "internal",
        url: `/product/${search.slug}?id=${search._id}`,
        productGroup: search.productGroup,
      };
    });

    this.searchItems = Object.fromEntries(mappedSearchItems.map(search => [search.productGroup, search]));
  }

  @Watch("selectedPin")
  fetchSkus() {
    this.getCurrentProductSkus();
  }

  get currentItem() {
    return this.slideShow.items[this.selectedPin];
  }

  getLinkProp(item: SiteEditorPinItem) {
    if (!item.url) return {};
    if (item.urlType === "external") return { href: item.url, target: "_blank", rel: "noopener noreferrer" };
    else return { to: this.$localePath(item.url) };
  }

  getLink(item: SiteEditorPinItem, active: boolean = true) {
    if (!item.url || !active) return "div";
    if (item.urlType === "internal") {
      return "nuxt-link";
    } else {
      return "a";
    }
  }

  async getCurrentProductSkus() {
    if (this.currentItem.productGroup)
      // @ts-ignore
      this.skus = await this.$feathers.service("shop/product/skus").find({
        query: {
          productGroup: this.currentItem.productGroup,
          $paginate: false,
          $populate: ["specs.spec", "specs.specValue"],
        } as const,
        paginate: false,
      });
    else this.skus = [];
  }

  formatSkusSpec(sku: Pop<["specs.specValue"], FindType<"shop/product/skus">>) {
    return this.$td(sku?.specs?.[1]?.specValue?.name || "");
  }

  onClickPin(idx) {
    this.selectedPin = idx;
    if (this.productDetail) {
      let headerOffset = document.getElementById("header").clientHeight;
      let elementPosition = this.productDetail.$el.getBoundingClientRect().top;
      let offsetPosition = elementPosition - document.body.getBoundingClientRect().top - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }
}
