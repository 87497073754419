
import Dialog from "@feathers-client/mixins/Dialog";
import { Component, mixins, Prop, FindType } from "@feathers-client";
import MenuListNum from "@feathers-client/components/MenuListNum.vue";
import { FindPopRawType } from "@feathers-client";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import EditorObjectPickerList from "@schemaEditor/EditorObjectPickerList.vue";
import { Watch } from "nuxt-property-decorator";
import uuid from 'uuid/v4';
import _ from 'lodash';
import DataTableForErp from "~/components/erp/DataTableForErp.vue";

@Component({
  components: {
    MenuListNum,
    EditorTextField,
    EditorObjectPickerList,
    DataTableForErp,
  }
})
export default class DetailsViewer extends mixins(Dialog) {

  @Prop()
  customTitle: string

  @Prop()
  docType: 'receipt' | 'invoice' | null

  @Prop()
  doc: any


  loading = false;

  paymentOptions = [
    {
      name: this.$t('paymentMethod.cash'),
      value: 'cash'
    },
    {
      name: this.$t('paymentMethod.bank'),
      value: 'bank transfer'
    },
    {
      name: this.$t('subsequentPaymentOrders.erp.cheque'),
      value: 'cheque'
    },
  ]

  async downloadFile(fileID) {
    if (this.loading) return;
    this.loading = true;
    const file = await this.$feathers.service("attachments").get(
      fileID
    );
    console.log('download', file)
    const url = file.thumb ? `data:image/png;base64,${file.thumb}` : this.$attach(file._id);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    a.target = '_blank';
    a.href = url;
    a.download = file.name;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
    this.loading = false;
  }

  attachments: FindType<'attachments'>[] = [];

  handler: FindType<'shop/staffs'> = null;

  cancelHandler: FindType<'shop/staffs'> = null;

  async beforeMount() {

    this.loading = true;
    if (this.doc.attachment || this.doc.attachments) {
      this.attachments = (await this.$feathers.service('attachments').find({
        query: {
          shop: this.$shop._id,
          // $paginate: false,
          _id: { $in: this.doc.attachment ?? this.doc.attachments },
        },
        paginate: false
      }))['data'];
    }

    if (this.doc.handler) {
      this.handler = await this.$feathers.service('shop/staffs').get(this.doc.handler);
    }

    if(this.doc.cancelDetails?.handler){
      const cancelStaff = await this.$feathers.service('shop/staffs').get(this.doc.cancelDetails.handler);
      this.cancelHandler = cancelStaff;
    }
    
    this.loading = false;
  }


}
