
import { Component, Vue, Prop, Watch } from "@feathers-client";
import PopularProductPercentage from "./PopularProductPercentage.vue";
import { collapseTextChangeRangesAcrossMultipleVersions } from "typescript";

@Component({
  components: {
    PopularProductPercentage
  }
})
export default class PopularProduct extends Vue {
  @Prop() data!: any;
  @Prop() history!: any;
  @Prop({ default: 0 }) count!: number;

  findTotalIng(id) {
    let v = this.history.find((it) => it._id === id)
    if (v && v.totalInt) {
      return v.totalInt
    }
    else { return 0}
  }
}
