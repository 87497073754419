const iconsCtx = require.context("!!vue-svg-loader!~/assets/images/icons", true, /\.svg$/);
const iconsBaseCtx = require.context("!!vue-svg-loader!~/dep/feathers-client/icons", true, /\.svg$/);

const icons = {
  ...Object.fromEntries(iconsBaseCtx.keys().map(k => [k.slice(2).split('.')[0], {
    component: iconsBaseCtx(k)
  }])),
  ...Object.fromEntries(iconsCtx.keys().map(k => [k.slice(2).split('.')[0], {
    component: iconsCtx(k)
  }])),
};

import en from 'vuetify/lib/locale/en';
import cht from 'vuetify/lib/locale/zh-Hant';
import chs from 'vuetify/lib/locale/zh-Hans';

export default {
  lang: {
    locales: {
      en,
      'zh-hk': cht,
      'zh-cn': chs,
    }
  },
  breakpoint: {
    thresholds: {
      xs: 640,
      sm: 768,
      md: 1024,
      lg: 1280,
    },
  },
  icons: {
    iconfont: 'md',
    values: icons,
  },
  theme: {
    themes: {
      light: {
        bg: '#F8F7FA',
        black: '#4F4F4F',
        purple: '#6B30BE'
      },
    },
    options: {
      customProperties: true,
    },
  },
}
