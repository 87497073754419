
import { Component, mixins } from "@feathers-client";
import StatusHost from "@feathers-client/components/StatusHost.vue";

@Component
export default class KioskStatusHost extends mixins(StatusHost) {
  closing = false;

  get errorOrSuccess() {
    return !this.closing && (this.error || this.success);
  }

  set errorOrSuccess(v) {
    if (!v) {
      this.closing = true;
      setTimeout(() => {
        this.closing = false;
        this.error = this.success = false;
      }, 500);
    }
  }
}
