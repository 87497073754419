
import _ from "lodash";
import { Component, Vue, Prop, FindType, FindPopRawType, Watch, checkID, getID, mixins } from "@feathers-client";
import moment from "moment";

type ScheduleType = FindPopRawType<"shipments", "shop/shipping/schedules">;
type ShipmentType = FindType<"shop/shipping/shipments">;
import { getScheduleStatusColor } from "~/plugins/pos/util";
import { ShipmentScheduleExport } from "~/plugins/shipping/excel";

@Component
export default class ShipmentScheduleShipmentManager extends mixins(ShipmentScheduleExport) {
  @Prop()
  deliveryDate: Date;

  @Prop()
  deliveryCar: string;

  @Prop()
  componentState: any;

  // @Prop()
  // selectedSchedule: ScheduleType;

  getScheduleStatusColor = getScheduleStatusColor;

  schedule: ScheduleType = null;
  //pendingRemove: string[] = [];
  //pendingRemoveState: object = {};

  selectedItems = [];
  editing = false;
  selectAll = false;
  itemSelectState = {};

  @Watch("selectAll")
  selectAllChanged() {
    if (this.selectAll) {
      this.itemSelectState = _.fromPairs(this.schedule.shipments?.map(item => [item._id, true]));
    } else if (this.selectAll === false) {
      this.itemSelectState = _.fromPairs(this.schedule.shipments?.map(item => [item._id, false]));
    }
  }

  @Watch("itemSelectState", { deep: true })
  itemSelectStateChanged() {
    this.selectedItems = Object.entries(this.itemSelectState)
      .filter(([k, v]) => v)
      .map(([k, v]) => k);
    if (Object.values(this.itemSelectState).every(v => v === true)) {
      this.selectAll = true;
    } else if (Object.values(this.itemSelectState).every(v => v === false)) {
      this.selectAll = false;
    } else {
      this.selectAll = null;
    }
  }

  editToggle() {
    this.editing = !this.editing;
  }

  get status() {
    return this.schedule ? this.schedule.status || "pending" : null;
  }

  async beforeMount() {
    await this.updateItem();
  }

  @Watch("deliveryDate")
  @Watch("deliveryCar")
  @Watch("componentState.value")
  updateFilter() {
    this.schedule = null;
    this.updateItem();
  }

  // @Watch("pendingRemove")
  // updatePendingRemoveState() {
  //   this.pendingRemoveState = Object.fromEntries(this.item.shipments?.map((item) => [getID(item), false]));
  //   this.pendingRemove.forEach((id) => {
  //     this.pendingRemoveState[id] = true;
  //   });
  // }

  async updateItem() {
    this.schedule = (
      await this.$feathers.service("shop/shipping/schedules").find({
        query: {
          deliveryDate: this.deliveryDate,
          deliveryCar: this.deliveryCar,
          $populate: [
            {
              path: "shipments",
            },
          ],
          $paginate: false,
        },
        paginate: false,
      } as any)
    )[0] as any;
    //this.pendingRemove = [];
    // this.pendingRemoveState =
    //   this.schedule && Object.fromEntries(this.schedule.shipments?.map(item => [getID(item), false]));
    if (this.schedule) {
      this.itemSelectState = Object.fromEntries(this.schedule?.shipments?.map(item => [getID(item), false]));
    }
    this.$emit("selectedSchedule",this.schedule)  ;
  }

  removeItem(item: any) {
    //this.pendingRemove.push(getID(item));
    this.itemSelectState[getID(item)] = true;
  }

  async removeItems() {
    if (!this.schedule) return;

    //if (this.pendingRemove.length) {
    if (Object.values(this.itemSelectState).some(it => it === true)) {
      const c = await this.$openDialog(
        import("@feathers-client/components-internal/ConfirmDialog.vue"),
        {
          title: this.$t("shipping.schedule.confirmRemoveFromShippingCar"),
        },
        {
          maxWidth: "50vw",
        },
      );
      if (!c) return;
      await this.$feathers.service("shop/shipping/schedules/assign").create({
        deliveryDate: null,
        deliveryCar: null,
        shipments: Object.entries(this.itemSelectState)
          .filter(it => it[1] === true)
          .map(it => it[0]),
      });
      await this.updateItem();
      this.componentState.value += 1;
    }
  }

  // async saveOrder() {
  //   if (!this.schedule) return;

  //   //if (this.pendingRemove.length) {
  //   if (Object.values(this.pendingRemoveState).some(it => it === true)) {
  //     const c = await this.$openDialog(
  //       import("@feathers-client/components-internal/ConfirmDialog.vue"),
  //       {
  //         title: this.$t("shipping.schedule.confirmRemoveFromShippingCar"),
  //       },
  //       {
  //         maxWidth: "50vw",
  //       },
  //     );
  //     if (!c) return;
  //     await this.$feathers.service("shop/shipping/schedules/assign").create({
  //       deliveryDate: null,
  //       deliveryCar: null,
  //       shipments: Object.entries(this.pendingRemoveState)
  //         .filter(it => it[1] === true)
  //         .map(it => it[0]),
  //     });
  //   }
  //   const resp = await this.$feathers.service("shop/shipping/schedules/reorder").create({
  //     shipments: this.schedule.shipments.map(getID),
  //     _id: this.schedule._id,
  //   });

  //   if (resp._id === this.schedule?._id) {
  //     await this.updateItem();
  //     this.componentState.value += 1;
  //   }
  // }

  async beginPrepare() {
    const resp = await this.$feathers.service("shop/shipping/schedules/prepare").create({
      _id: this.schedule._id,
    });

    if (resp._id === this.schedule?._id) {
      await this.updateItem();
      this.componentState.value += 1;
    }
  }

  async beginDelivery() {
    const notPrepared = this.schedule.shipments.filter(it => !it.metadata?.num);

    if (notPrepared.length) {
      const c = await this.$openDialog(
        import("@feathers-client/components-internal/ConfirmDialog.vue"),
        {
          title: this.$t("shipping.schedule.someNotPrepared"),
        },
        {
          maxWidth: "50vw",
        },
      );
      if (!c) return;
    }

    try {
      const resp = await this.$feathers.service("shop/shipping/schedules/delivery").create({
        _id: this.schedule._id,
      });
    } catch (e) {
      console.warn(e);
      this.$store.commit("SET_ERROR", e.message);
    }

    await this.updateItem();
    this.componentState.value += 1;
  }

  async markComplete() {
    const notReceived = this.schedule.shipments.filter(it => it.status !== "cancelled" && it.status !== "received");

    if (notReceived.length) {
      const c = await this.$openDialog(
        import("@feathers-client/components-internal/ConfirmDialog.vue"),
        {
          title: this.$t("shipping.schedule.someNotDelivered"),
        },
        {
          maxWidth: "50vw",
        },
      );
      if (!c) return;
    }
    try {
      const resp = await this.$feathers.service("shop/shipping/schedules/complete").create({
        _id: this.schedule._id,
      });
    } catch (e) {
      console.warn(e);
      this.$store.commit("SET_ERROR", e.message);
    }
    await this.updateItem();
    this.componentState.value += 1;
  }

  async cancel(item: ShipmentType) {
    const c = await this.$openDialog(
      import("@feathers-client/components-internal/ConfirmDialog.vue"),
      {
        title: this.$t("shipping.schedule.confirmRemoveFromShippingCar"),
      },
      {
        maxWidth: "50vw",
      },
    );
    if (!c) return;

    await this.$feathers.service("shop/shipping/schedules/assign").create({
      deliveryDate: null,
      deliveryCar: null,
      shipments: [item._id],
    });
    await this.updateItem();
    this.componentState.value += 1;
  }

  async printLabel(item: ShipmentType) {
    const c = await this.$openDialog(
      import("~/components/boxs/ShipmentScheduleLabelDialog.vue"),
      {
        item: item,
      },
      {
        maxWidth: "50vw",
        contentClass: "h-100",
      },
    );
    if (!c) return;
  }

  async exportExcel() {
    try {
      const u = new URL(`${this.$config.apiUrl}/api/shop/shipping/schedules/shippingNote`);
      u.searchParams.set("car", this.deliveryCar);
      u.searchParams.set("shop", String(this.$shop._id));
      u.searchParams.set("date", moment(this.deliveryDate).format("YYYY-MM-DD"));
      u.searchParams.set("token", await (this.$feathers as any).authentication.getAccessToken());
      u.searchParams.set("lang", this.$i18n.locale);

      window.open(u, "_blank");

      await this.exportRawData({
        date: this.deliveryDate,
        car: this.deliveryCar,
      });
    } catch (e) {
      console.warn(e);
      this.$store.commit("SET_ERROR", e.message);
    }
  }
}
