
import { Component, Vue, Prop, Watch } from "@feathers-client";
import { Order } from "@common/pos";
import EditorObjectPickerNew from "@schemaEditor/EditorObjectPickerNew.vue";
import EditorTextField from "@schemaEditor/EditorTextField.vue";

@Component({
	components: {
		EditorObjectPickerNew,
		EditorTextField,
	},
})
export default class TwTax extends Vue {
	@Prop()
	session: Partial<Order>;

	scanning = false;

	mounted() {
		if (!this.session.twTaxType) {
			Vue.set(this.session, "twTaxType", "paper");
		}
	}

	get items() {
		return [
			{
				_id: "paper",
				name: this.$t("turnCloud.taxType.paper"),
			},
			{
				_id: "electronic",
				name: this.$t("turnCloud.taxType.electronic"),
			},
			{
				_id: "company",
				name: this.$t("turnCloud.taxType.company"),
			},
			{
				_id: "nrt",
				name: this.$t("turnCloud.taxType.nrt"),
			},
			{
				_id: "donate",
				name: this.$t("turnCloud.taxType.donate"),
			},
			{
				_id: "none",
				name: this.$t("turnCloud.taxType.noInvoice"),
			},
		];
	}

	async flushUpdates() {
		if (this.session.twTaxType === "nrt") {
			this.session.twTaxVehicle = null;
			this.session.twTaxComp = null;
			this.session.twTaxNrt = this.session.twTaxNrt;
			this.session.twDonate = null;
		} else if (this.session.twTaxType === "company") {
			this.session.twTaxVehicle = null;
			this.session.twTaxComp = this.session.twTaxComp;
			this.session.twTaxNrt = null;
			this.session.twDonate = null;
		} else if (this.session.twTaxType === "electronic") {
			this.session.twTaxVehicle = this.session.twTaxVehicle;
			this.session.twTaxComp = null;
			this.session.twTaxNrt = null;
			this.session.twDonate = null;
		} else if (this.session.twTaxType === "donate") {
			this.session.twTaxVehicle = null;
			this.session.twTaxComp = null;
			this.session.twTaxNrt = null;
			this.session.twDonate = this.session.twDonate;
		} else {
			this.session.twTaxVehicle = null;
			this.session.twTaxComp = null;
			this.session.twTaxNrt = null;
			this.session.twDonate = null;
		}
		this.session.saveCart();
	}
}
