
import _ from "lodash"
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import { checkID, FindType } from '@feathers-client'
import type { CategoryType, BrandType } from '@common/common'

@Component
export default class OrderSystemProduct extends Vue {
    @Prop()
    item : CategoryType | BrandType;

    @Prop({ type: Boolean, default: false })
    shortMode: boolean

    get thumb() {
        return this.item?.icon && this.$thumb(this.item.icon) || require('~/assets/images/logo.png')
    }

    lastPress = 0;

    shortpress() {
        if(Date.now() - this.lastPress < 50) return;
        this.lastPress = Date.now();
        this.$emit('goCategory', this.item)
    }

    longpress() {
        if(Date.now() - this.lastPress < 50) return;
        this.lastPress = Date.now();
        this.$emit('goCategory', this.item, true)
    }
}
