
import { Component, Prop, Vue, Watch, mixins, InjectReactive } from "nuxt-property-decorator";
import _ from 'lodash'
import { getID, checkID } from '@feathers-client'
import { CacheLoader } from '../cacheLoader'

@Component
export default class AsyncPopulate extends Vue {
    @Prop()
    path : string

    @Prop()
    value : string | string[]

    @Prop(Boolean)
    multiple: boolean

    @Prop()
    args : any

    @Prop(Boolean)
    allowEmpty: boolean

    @Prop(Boolean)
    local: boolean

    @Prop()
    maybeValue: any | any[]

    @Prop({ default: '_id', type: String })
    itemKey: string

    @Prop(Boolean)
    hideEmpty: boolean

    @Prop(Boolean)
    hideLoading: boolean

    @Prop(Boolean)
    keepPrevious: boolean

    @Prop()
    loader: CacheLoader

    @Prop({ type: Boolean })
    subscribe: boolean

    cachedValue : any | any[] = null;
    loading = true;
    
    exitHooks: (() => void)[] = [];

    @InjectReactive({ default: null, from: "feathers" })
    feathers: any;

    get cacheKey() {
        return this.path + JSON.stringify(this.args || null) + this.itemKey;
    }

    get cache() {
        if(this.loader) return this.loader;
        const ref = this.feathers ? this.feathers : this.local ? (this as any).$parent : (this as any).$root;
        let cache : {
            [key : string]: CacheLoader
        } = (ref._asyncPopulates || (ref._asyncPopulates = {}));
        let loader : CacheLoader = cache[this.cacheKey];
        if(!loader) {
            Vue.set(cache, this.cacheKey, loader = new CacheLoader({
                parent: this.feathers ? this.feathers : this.local ? this.$parent : this.$root,
                key: this.cacheKey,
                propsData: {
                    path: this.path,
                    args: this.args,
                    itemKey: this.itemKey,
                    feathers: this.feathers,
                }
            }))
        }
        return loader
    }

    beforeMount() {
        this.reload();
        if(this.subscribe) {
            const cache = this.cache;
            cache.$on('update', this.updateValue);
            this.exitHooks.push(() => {
                cache.$off('update', this.updateValue);
            })
        }
    }

    beforeDestroy() {
        for(let item of this.exitHooks) item();
    }

    get getID() {
        return this.itemKey ? new Function('item', `return item ? typeof item === 'string' ? item : item.${this.itemKey} : null`) : getID
    }

    get checkID() {
        return this.itemKey === '_id' ? checkID : ((a, b) => this.getID(a) === this.getID(b));
    }
    
    dirty = false;

    @Watch('value')
    reload() {
        this.queueReload();
    }

    reloadInner() {
        if(this.multiple) {
            const v = this.value;
            if(v && Array.isArray(v)) {
                this.loading = false;
                const mv = Array.isArray(this.maybeValue) ? this.maybeValue : null;
                this.cachedValue = v.map(v => {
                    if(mv) {
                        const r = mv.find(it => this.checkID(it, v));
                        if(r !== undefined) return r;
                    }
                    return this.cache.query(v, () => {
                        this.loading = true
                    }, this.queueReload)
                }).filter(it => !!it);
                this.$emit('cachedValue', this.cachedValue, this.loading);
            } else {
                if(!this.keepPrevious) this.cachedValue = [];
                this.$emit('cachedValue', [], this.loading);
            }
        } else if(this.value) {
            if(typeof this.value === 'object') {
                this.cachedValue = this.value;
                this.$emit('cachedValue', this.cachedValue, this.loading);
            } else {
                if(this.maybeValue && this.checkID(this.maybeValue, this.value)) {
                    this.cachedValue = this.maybeValue;
                    this.$emit('cachedValue', this.cachedValue, this.loading);
                } else {
                    if(!this.keepPrevious) this.cachedValue = null;
                    const newValue = this.cache.query(this.value, () => {
                        this.loading = true;
                    }, (v) => {
                        this.cachedValue = v || null;
                        this.loading = false;
                        this.$emit('cachedValue', this.cachedValue, this.loading);
                    }) || null
                    if(!this.keepPrevious || newValue) this.cachedValue = newValue;
                    if(newValue === undefined) {
                        this.loading = true;
                    }
                    this.$emit('cachedValue', this.cachedValue, this.loading);
                }
            }
        } else {
            this.cachedValue = null;
            this.$emit('cachedValue', null, this.loading);
        }
    }

    reloadTimer : any = null;
    queueReload() {
        if(this.reloadTimer) {
            clearTimeout(this.reloadTimer);
            this.reloadTimer = null;
        }
        this.reloadTimer = setTimeout(() => {
            this.reloadInner();
        }, 50);
    }

    updateValue(item: any) {
        if(this.multiple) {
            const someId = Array.isArray(this.value) && this.value.find(it => this.checkID(item, it));
            if(someId) {
                this.queueReload();
            }
        } else if(this.checkID(item, this.value)) {
            this.cachedValue = item;
        }
    }
}

