
import { Component, Prop, Vue, Watch, mixins, FindType, VModel, checkID, getID, PropSync } from "@feathers-client";

// @ts-ignore
import TickIcon from "../components-internal/TickIcon.vue";
// @ts-ignore
import BackspaceIcon from "../components-internal/BackspaceIcon.vue";

@Component({
  components: {
    TickIcon,
    BackspaceIcon,
  },
})
export default class NumPad extends Vue {
  isNaN = isNaN;

  @VModel()
  inputValue: number;

  @PropSync("string", { default: "" })
  strValue: string;

  @Prop({ type: Number, default: 10000000 })
  max: number;

  @Prop({ type: Number, default: 0 })
  min: number;

  @Prop(Boolean)
  maxInclusive: boolean;

  @Prop(Boolean)
  minInclusive: boolean;

  @Prop(Boolean)
  integer: boolean;

  @Prop(Boolean)
  noDefault: boolean;

  @Prop(Boolean)
  allowEmpty: boolean;

  @Prop(Boolean)
  allowLeadingZero: boolean;

  @Prop(Boolean)
  fill: boolean;

  @Prop()
  digits: number;

  @Prop()
  rounding: number;

  @Prop({type: String, default: "min(3rem, 4vw)" })
  filledFontSize: string;

  get finalDigits() {
    return this.digits ?? (this.rounding ? Math.ceil(-Math.log10(this.rounding)) : 2);
  }

  @Prop({ type: Boolean, default: true })
  confirmButton: boolean;

  mounted() {
    if (!this.noDefault) {
      this.strValue = `${this.inputValue || ""}`;
    }
  }

  canAction(btn: number | string) {
    if (btn === "-") return true;
    else if ((typeof btn === "number" || btn === "00") && !isNaN(+this.strValue)) {
      if (!this.allowLeadingZero && (btn === 0 || btn === "00")) {
        if (this.strValue.length && !+this.strValue && !this.strValue.includes(".")) return false;
      }
      const parts = (this.strValue + btn).split(".");

      if (this.strValue.startsWith("-")) {
        const v = +(this.minInclusive ? parts.join(".") : parts[0] || "0");
        const minCheck = this.minInclusive ? v < this.min : v <= this.min;
        if (minCheck) {
          return false;
        }
      } else {
        const v = +(this.maxInclusive ? parts.join(".") : parts[0] || "0");
        const maxCheck = this.maxInclusive ? v > this.max : v >= this.max;
        if (maxCheck) {
          return false;
        }
      }

      if (parts[1] && parts[1].length > this.finalDigits) return false;
    } else if (btn === "$backspace" && !this.strValue.length) return false;
    else if (btn === "." && this.strValue.includes(".")) return false;
    return true;
  }

  get btns() {
    return [
      7,
      8,
      9,
      "$backspace",
      4,
      5,
      6,
      "C",
      1,
      2,
      3,
      this.min < 0 ? "-" : "",
      0,
      "00",
      this.integer || this.finalDigits === 0 ? "" : ".",
      this.confirmButton ? "$tick" : "",
    ];
  }

  confirm() {
    if (this.allowEmpty && this.strValue === "") {
      this.inputValue = null;
    } else {
      if (isNaN(+this.strValue)) return;
      if (this.strValue !== "") {
        this.inputValue = +this.strValue;
      }
    }
    this.$emit("close");
  }

  action(btn: number | string) {
    // if (!this.canAction(btn)) return;
    if (typeof btn === "number") {
      this.strValue += btn;
    } else if (btn) {
      switch (btn) {
        case "00":
          this.strValue += "00";
          break;
        case ".": {
          if (this.strValue.indexOf(".") === -1) this.strValue += ".";
          break;
        }
        case "$backspace": {
          if (this.strValue) this.strValue = this.strValue.slice(0, this.strValue.length - 1);
          break;
        }
        case "$tick": {
          this.confirm();
          this.$emit("confirm");
          break;
        }
        case "C": {
          this.strValue = "";
          if (this.allowEmpty) {
            this.inputValue = null;
          }
          break;
        }
        case "-": {
          if (this.strValue.startsWith("-")) {
            this.strValue = this.strValue.substring(1);
            let v = +this.strValue;
            if (this.maxInclusive ? v > this.max : v >= this.max) {
              v = this.maxInclusive ? this.max : this.max - 1;
              this.strValue = v.toString();
            }
          } else {
            this.strValue = "-" + this.strValue;
            let v = +this.strValue;
            if (this.minInclusive ? v < this.min : v <= this.min) {
              v = this.minInclusive ? this.min : this.min + 1;
              this.strValue = v.toString();
            }
          }
          break;
        }
      }
    }
    this.$emit("action", btn);
  }
}
