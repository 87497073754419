function ApplyType<
  T extends {
    [key: string]: CurrencyDef;
  },
>(defs: T) {
  return defs;
}

let scale = 1;

export function setScale(tscale: number) {
  scale = tscale;
  for (let currency of Object.values(Currencies)) {
    currency.multiplier = currency.defaultMultiplier * scale;
  }
}

export function addCurrency(def: Partial<CurrencyDef>) {
  Currencies[def.name] = {
    ...def,
    multiplier: def.defaultMultiplier * scale,
  };
}

export const Currencies = ApplyType({
  HKD: {
    symbol: "$",
    name: "HKD",
    multiplier: 100,
    defaultMultiplier: 100,
  },
  USD: {
    symbol: "$",
    name: "USD",
    multiplier: 100,
    defaultMultiplier: 100,
  },
  TWD: {
    symbol: "$",
    name: "TWD",
    multiplier: 100,
    defaultMultiplier: 100,
  },
  SGD: {
    symbol: "$",
    name: "SGD",
    multiplier: 100,
    defaultMultiplier: 100,
  },
  MYR: {
    symbol: "RM",
    name: "MYR",
    alias: ["MYR", "RM"],
    multiplier: 100,
    defaultMultiplier: 100,
  },
  RM: {
    symbol: "RM",
    name: "MYR",
    alias: ["MYR", "RM"],
    hidden: true,
    multiplier: 100,
    defaultMultiplier: 100,
  },
  VND: {
    symbol: "₫",
    name: "VND",
    multiplier: 100,
    defaultMultiplier: 100,
  },
  THB: {
    symbol: "฿",
    name: "THB",
    multiplier: 100,
    defaultMultiplier: 100,
  },
  JPY: {
    symbol: "¥",
    name: "JPY",
    multiplier: 100,
    defaultMultiplier: 100,
  },
  AUD: {
    symbol: "$",
    name: "AUD",
    multiplier: 100,
    defaultMultiplier: 100,
  },
  CNY: {
    symbol: "¥",
    name: "CNY",
    multiplier: 100,
    defaultMultiplier: 100,
  },
});

export type CurrencyKey = string;

export interface CurrencyDef {
  symbol?: string;
  name: string;
  multiplier: number;
  defaultMultiplier?: number;
  hidden?: boolean;
  alias?: string[];
}

export const CurrencyRaw = {
  type: Number,
  $editor: "type=currency props.raw",
};

export const CurrencySpec = {
  type: String,
  // enum: Object.keys(Currencies) as (CurrencyKey)[],
};

export const Currency = {
  currency: { ...CurrencySpec },
  amount: Number,
  $editor: "type=currency",
};

export const MultiCurrency = [
  {
    ...Currency,
    $editor: "type=currency props.multiple",
  },
];

export interface CurrencyType {
  currency: CurrencyKey | CurrencyDef;
  amount: number;
}

export type MultiCurrencyType = CurrencyType[];

export function validateCurrency(c: string): CurrencyKey {
  if (Currencies[c]) return <any>c;
  throw new Error("Invalid currency " + c);
}

export function convert(from: CurrencyType | MultiCurrencyType, target?: CurrencyKey | CurrencyDef) {
  if (!from) return null;
  else if (typeof from === "number") throw new Error("Invalid value" + from);
  else if (Array.isArray(from)) {
    if (!from.length) throw new Error("Invalid value");
    const item = from.find(it => it.currency === target);
    return convert(item || from[0], target);
  } else {
    if (target && from.currency !== target) {
      throw new Error("Not implemented");
    }

    const info = typeof from.currency === "object" ? from.currency : Currencies[from.currency];
    if (!info) throw new Error("Invalid currency: " + from.currency);

    return from.amount / info.multiplier;
  }
}

export function format(
  from: CurrencyType | MultiCurrencyType,
  target?: CurrencyKey | CurrencyDef,
  type: "short" | "long" | "num" | "symbol" = "short",
  separator = true,
) {
  try {
    if (!target && !Array.isArray(from)) {
      target = from.currency;
    }
    let v = convert(from, target);
    if (v === null) return "";
    else if (isNaN(v)) return "NaN";

    const sign = v < 0 ? "-" : "";
    v = Math.abs(v);
    let fv = `${v}`;
    const r = fv.split(".");
    if (separator) r[0] = r[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    fv = r.join(".");
    const info = typeof target === "object" ? target : Currencies[target];

    if (type === "short") return `${sign}${info.symbol} ${fv}`;
    else if (type === "num") return sign + fv;
    else if (type === "symbol") return info.symbol;
    else return `${sign}${info.name} ${fv}`;
  } catch (e) {
    return "";
  }
}

export function currencySymbol(target: CurrencyKey) {
  const info = Currencies[target];
  return info?.symbol || "";
}

export function currencyName(target: CurrencyKey) {
  const info = Currencies[target];
  return info?.name || "";
}

export function sum(...values: CurrencyType[]): CurrencyType;
export function sum(...values: MultiCurrencyType[]): MultiCurrencyType;
export function sum(...values: CurrencyType[] | MultiCurrencyType[]): CurrencyType | MultiCurrencyType {
  if (!values.length) throw new Error("Invalid value");
  const v0 = values[0];
  if (Array.isArray(v0)) {
    const cur = v0.map(it => ({ ...it }));
    for (let i = 1; i < values.length; i++) {
      const vi = values[i];
      if (Array.isArray(vi) && vi.length === v0.length) {
        for (let j = 0; j < vi.length; j++) {
          if (cur[j].currency !== vi[j].currency) throw new Error("Invalid Order");
          cur[j].amount += vi[j].amount;
        }
      } else {
        throw new Error("Invalid value");
      }
    }
    for (let it of cur) it.amount = Math.round(it.amount);
    return cur;
  } else {
    const cur: CurrencyType = { currency: v0.currency, amount: v0.amount };
    for (let i = 1; i < values.length; i++) {
      const vi = values[i];
      if (Array.isArray(vi)) {
        throw new Error("Invalid value");
      } else {
        if (vi.currency !== cur.currency) throw new Error("Invalid value");
        cur.amount += vi.amount;
      }
    }
    cur.amount = Math.round(cur.amount);
    return cur;
  }
}

export function trySum(...values: CurrencyType[]): CurrencyType;
export function trySum(...values: MultiCurrencyType[]): MultiCurrencyType;
export function trySum(...values: CurrencyType[] | MultiCurrencyType[]): CurrencyType | MultiCurrencyType {
  try {
    return sum(...(<any>values));
  } catch (e) {
    return null;
  }
}

export function getHumanNumber(amount: number, currency: CurrencyKey): number {
  return amount / Currencies[currency]?.multiplier;
}

export function fromNumber(amount: number, currency: CurrencyKey | CurrencyDef): CurrencyType {
  return { amount, currency };
}

export function fromHumanNumber(amount: number, currency: CurrencyKey | CurrencyDef): CurrencyType {
  return {
    amount: Math.round(typeof currency === "object" ? currency.multiplier : Currencies[currency].multiplier * amount),
    currency: typeof currency === "object" ? currency.name : currency,
  };
}

export function fromHumanNumberToRaw(amount: number, currency: CurrencyKey): number {
  return fromHumanNumber(amount, currency).amount;
}

export function fromString(amount: string, currency: CurrencyKey): CurrencyType {
  let v = +amount;
  if (isNaN(v)) return null;
  return fromHumanNumber(v, currency);
}

export function fromStringToNumber(amount: string, currency: CurrencyKey): number {
  return fromString(amount, currency)?.amount ?? NaN;
}

export function multiply(value: CurrencyType, amount: number): CurrencyType;
export function multiply(value: MultiCurrencyType, amount: number): MultiCurrencyType;
export function multiply(value: CurrencyType | MultiCurrencyType, amount: number): CurrencyType | MultiCurrencyType {
  if (Array.isArray(value)) {
    return value.map(it => ({
      currency: it.currency,
      amount: Math.floor(it.amount * amount),
    }));
  } else {
    return {
      currency: value.currency,
      amount: Math.floor(value.amount * amount),
    };
  }
}

export function divide(value: CurrencyType, amount: number): CurrencyType;
export function divide(value: MultiCurrencyType, amount: number): MultiCurrencyType;
export function divide(value: CurrencyType | MultiCurrencyType, amount: number): CurrencyType | MultiCurrencyType {
  if (Array.isArray(value)) {
    return value.map(it => ({
      currency: it.currency,
      amount: Math.floor(it.amount / amount),
    }));
  } else {
    return {
      currency: value.currency,
      amount: Math.floor(value.amount / amount),
    };
  }
}

export function equals(a: CurrencyType, b: CurrencyType) {
  return a.currency === b.currency && a.amount === b.amount;
}

export function equalsRaw(a: CurrencyType | MultiCurrencyType, b: number, target: CurrencyKey) {
  const from = convertCurrency(a, target);
  return from.amount === b && from.currency === target;
}

export function lessThan(a: CurrencyType, b: CurrencyType) {
  return a.currency === b.currency && a.amount < b.amount;
}

export function greaterThan(a: CurrencyType, b: CurrencyType) {
  return a.currency === b.currency && a.amount > b.amount;
}

export function convertCurrency(value: CurrencyType | MultiCurrencyType, target: CurrencyKey): CurrencyType {
  if (Array.isArray(value)) {
    const item = value.find(it => it.currency === target);
    if (!item) {
      throw new Error("Invalid value");
    }
    return item;
  } else {
    if (value.currency !== target) throw new Error("Invalid value");
    return value;
  }
}

export function getRaw(value: CurrencyType): number;
export function getRaw(value: MultiCurrencyType, target: CurrencyKey): number;
export function getRaw(value: CurrencyType | MultiCurrencyType, target?: CurrencyKey): number {
  if (Array.isArray(value)) {
    const item = value.find(it => it.currency === target);
    if (!item) {
      throw new Error("Invalid value");
    }
    return getRaw(item);
  } else {
    return value.amount;
  }
}

export function isZero(value: CurrencyType | MultiCurrencyType): boolean {
  if (Array.isArray(value)) {
    return value.every(it => it.amount === 0);
  } else {
    return value.amount === 0;
  }
}
