
import { Component, Vue, Prop, Watch } from "@feathers-client";
import { LangArrType } from "@feathers-client/i18n";
import { defineComponent } from "site-editor/def";
import _ from "lodash";
// @ts-ignore
import rarrowSvg from "!vue-svg-loader!../../assets/rarrow.svg";
// @ts-ignore
import hotspotSvg from "!vue-svg-loader!../../assets/hotspot.svg";
import { SiteEditorImageWithPinSlideShow } from "../../editorComponents/ImageWithPin.vue";
import SlideShow from "./components/SlideShow.vue";

export const componentInfo = defineComponent({
  group: "imageWithPin",
  groupProps: {
    editorCols: 2,
  },
  variants: [
    {
      key: "hotspot",
    },
  ],
  hideConfirm: true,
});

@Component({
  components: {
    rarrowSvg,
    hotspotSvg,
    SlideShow,
  },
})
export default class SiteEditorImage extends Vue {
  @Prop()
  variant: string;

  @Prop()
  title: LangArrType;

  @Prop()
  remarks: LangArrType;

  @Prop()
  slideShows: SiteEditorImageWithPinSlideShow[];

  @Prop()
  bgColor: string;

  @Prop()
  hotspotColor: string;

  @Prop({ default: "500px" })
  desktopHeight: any;

  @Prop({ default: "500px" })
  mobileHeight: any;

  @Prop()
  titleDisplayMode: "left" | "center" | "right";

  @Prop()
  fontColor: string;

  get slideHeight() {
    return this.$breakpoint.lgAndUp ? this.desktopHeight : this.mobileHeight;
  }

  selectedSlideShow = 0;

  // @Watch("selectedSlideShow")
  // resetSelectedPin() {
  //   console.log("selectedSlideShow", this.selectedSlideShow);
  //   this.selectedPin = 0;
  //   this.getCurrentProductSkus();
  // }
}
