
import { Component, Vue, Prop } from "@feathers-client";
import { LangArrType } from "@feathers-client/i18n";
import { defineComponent } from "site-editor/def";
import { SiteEditorButton } from "../../editorComponents/headerImage.vue";

export const componentInfo = defineComponent({
  group: "slideShow",
  groupProps: {
    editorCols: 2,
  },
  variants: [
    {
      key: "slideShow",
    },
  ],
});

@Component
export default class SiteEditorSlideShow extends Vue {
  @Prop()
  variant: string;

  @Prop({ type: Boolean, default: true })
  autoPlay: boolean;

  @Prop({ type: Number, default: 5000 })
  time: number;

  @Prop()
  manualChange: boolean;

  @Prop()
  stretchType: string;

  @Prop()
  slides: SiteEditorSlides[];

  @Prop()
  ratio: string;

  @Prop({ type: String, default: "500" })
  fixedHeight: string;

  @Prop({ type: String, default: "fixed" })
  resizeMethod: "fixed" | "ratio";

  getLinkProp(button: SiteEditorButton) {
    if (!button.url) return {};
    if (button.urlType === "external") return { href: button.url, target: "_blank", rel: "noopener noreferrer" };
    else return { to: this.$localePath(button.url) };
  }

  getLink(button: SiteEditorButton) {
    if (!button.url) return "div";
    if (button.urlType === "internal") {
      return "nuxt-link";
    } else {
      return "a";
    }
  }

  get bannerHeight() {
    return this.resizeMethod === "fixed"
      ? `max(${this.fixedHeight}${Number(this.fixedHeight) ? "px" : ""},calc(100vw / (${this.ratio})))`
      : `calc(100vw / (${this.ratio})`;
  }

  convertPostion(positionIndex) {
    switch (positionIndex) {
      case 1:
        return "top-0 left-0";
      case 2:
        return "top-0 left-1/2 transform -translate-x-1/2";
      case 3:
        return "top-0 right-0";
      case 4:
        return "top-1/2 left-0 transform -translate-y-1/2";
      case 5:
        return "top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2";
      case 6:
        return "top-1/2 right-0 transform -translate-y-1/2";
      case 7:
        return "bottom-0 left-0";
      case 8:
        return "bottom-0 left-1/2 transform -translate-x-1/2";
      case 9:
        return "bottom-0 right-0";
    }
  }

  buttonAlign(positionIndex) {
    switch (positionIndex) {
      case 1:
      case 4:
      case 7:
        return "";
      case 2:
      case 5:
      case 8:
        return "justify-center";
      case 3:
      case 6:
      case 9:
        return "justify-end";
    }
  }

  carouselNum = 0;
}

export interface SiteEditorSlides {
  id?: string;
  type?: "video" | "image";
  translateFile?: LangArrType;
  file?: any;
  url?: string;
  title?: LangArrType;
  content?: LangArrType;
  positionIndex?: number;
  position?: string;
  fgColor?: string;
  bgColor?: string;
  mainButton?: SiteEditorButton;
  subButton?: SiteEditorButton;
}
