
import { Component, Prop, Vue, FindType, Watch } from "@feathers-client";
@Component
export default class ProductPreviewSvgInstructions extends Vue {
  @Prop()
  item: FindType<"shop/product/skus">;

  instructions: {
    option: FindType<"shop/product/options">;
    choice: FindType<"shop/product/options">["choices"][number];
    customization: FindType<"shop/product/customizations">;
    customizationId: string;
  }[] = [];

  @Watch("item.productOptions", { immediate: true })
  async updateOptions() {
    const optionIds = Array.from(new Set((this.item?.productOptions || []).map(it => it.option)));
    const options = await this.$feathers.service("shop/product/options").find({
      query: {
        _id: {
          $in: optionIds,
        },
        $paginate: false,
      },
      paginate: false,
    });

    const customizationsIds = Array.from(
      new Set(
        options
          .flatMap(option => option.choices.flatMap(choice => choice.customizations || []))
          .map(it => it.customization),
      ),
    );

    const customizations = await this.$feathers.service("shop/product/customizations").find({
      query: {
        _id: {
          $in: customizationsIds,
        },
        $paginate: false,
      },
      paginate: false,
    });

    const customizationsMap = new Map(customizations.map(it => [it._id, it]));

    this.instructions = options
      .flatMap(option =>
        option.choices.flatMap(choice =>
          (choice.customizations || []).map(customization => ({
            option,
            choice,
            customization: customizationsMap.get(customization.customization),
            customizationId: customization.id,
          })),
        ),
      )
      .filter(it => it.option && it.choice && it.customization);
  }
}
