
import { Component, Vue, Prop, Watch } from "@feathers-client";
import { PageBlock } from "../def";
import { SiteEditorManager } from "..";
import EditorCheckbox from "@schemaEditor/EditorCheckbox.vue";
import EditorList from "@schemaEditor/EditorList.vue";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import EditorTranslateBox from "@schemaEditor/EditorTranslateBox.vue";
import RatioPicker from "../RatioPicker.vue";
import uuid from "uuid/v4";
import ColorPicker from "../ColorPicker.vue";
import UrlPicker from "../UrlPicker.vue";
// @ts-ignore
import RarrowSvg from "!vue-svg-loader!../assets/rarrow.svg";
import { SiteEditorSlides } from "../components/slideShow/index.vue";
import { SiteEditorButton } from "./headerImage.vue";
import { LangArrType } from "@feathers-client/i18n";

@Component({
  components: {
    EditorCheckbox,
    EditorList,
    EditorTextField,
    EditorTranslateBox,
    ColorPicker,
    RatioPicker,
    UrlPicker,
    RarrowSvg,
  },
})
export default class SiteEditorSlideShow extends Vue {
  @Prop()
  block: PageBlock;

  @Prop()
  manager: SiteEditorManager;

  timePicker = false;

  getBanner() {
    return {
      id: uuid(),
      type: "image",
      file: null,
      translateFile: [] as LangArrType,
      url: null,
      positionIndex: 5,
      position: "left",
      bgColor: "#FFFFFFFF",
      fgColor: "#000000FF",
      mainButton: { enabled: false, urlType: "internal", url: "", title: [] } as SiteEditorButton,
      subButton: { enabled: false, urlType: "internal", url: "", title: [] } as SiteEditorButton,
    } as SiteEditorSlides;
  }

  get stretchTypes() {
    return [
      { _id: "full", name: { $t: this.manager.$t("componentGroups.headerImage.stretchToFull") } },
      { _id: "normal", name: { $t: this.manager.$t("componentGroups.headerImage.stretchWithMargin") } },
    ];
  }

  get mediaTypes() {
    return [
      { _id: "image", name: { $t: this.manager.$t("componentGroups.headerImage.image") } },
      { _id: "video", name: { $t: this.manager.$t("componentGroups.headerImage.video") } },
    ];
  }

  get postionTypes() {
    return [
      { _id: "left", name: { $t: this.manager.$t("componentGroups.ribbon.byLeft") } },
      { _id: "right", name: { $t: this.manager.$t("componentGroups.ribbon.byRight") } },
    ];
  }

  get resizeMethodTypes() {
    return [
      { _id: "fixed", name: { $t: this.manager.$t("componentGroups.headerImage.fixed") } },
      { _id: "ratio", name: { $t: this.manager.$t("componentGroups.headerImage.ratio") } },
    ];
  }

  get props() {
    return this.manager.defineProps(this.block, {
      autoPlay: true,
      time: 5000,
      manualChange: true,
      stretchType: "full",
      fixedHeight: "500",
      resizeMethod: "fixed",
      slides: [] as SiteEditorSlides[],
      ratio: "16/9",
    });
  }

  beforeMount() {
    for (let slide of this.props.slides) {
      if (!slide.mainButton) Vue.set(slide, "mainButton", { enabled: false, urlType: "internal", url: "", title: [] });
      if (!slide.subButton) Vue.set(slide, "subButton", { enabled: false, urlType: "internal", url: "", title: [] });
      if (!slide.translateFile) slide.translateFile = [] as LangArrType;
    }
  }
}
