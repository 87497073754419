export const ShortCodeMapping = {
  shipment: [{ type: "const", value: "s" }, { type: "id" }]
} as const;

export type ShortCodeType = keyof typeof ShortCodeMapping;

export function getUrl(context: Vue, type: ShortCodeType, ...data: any[]) {
  const params: Buffer[] = [];

  const mapping = ShortCodeMapping[type];
  for (let item of mapping) {
    switch (item.type) {
      case "const": {
        params.push(Buffer.from(item.value));
        break;
      }
      case "id": {
        params.push(Buffer.from(data.shift(), "hex"));
        break;
      }
    }
  }

  const code = Buffer.concat(params).toString("base64");

  return {
    code,
    url: `${context.$qrPrefix}/c/${code}`
  };
}

export function parseUrl(context: Vue, code: string) {
  if (!code.startsWith(context.$qrPrefix)) {
    return null;
  }

  code = code.substring(context.$qrPrefix.length);
  if (!code.startsWith("/c/")) {
    return null;
  }

  code = code.substring(3);
  try {
    const buf = Buffer.from(code, "base64");
    for (let [k, v] of Object.entries(ShortCodeMapping)) {
      const result = parseMapping(k as any, v, buf);
      if (result) return result;
    }
  } catch (e) {
    console.warn(e);
    return null;
  }
}

export function parseMapping(
  type: ShortCodeType,
  mapping: typeof ShortCodeMapping[ShortCodeType],
  buf: Buffer
) {
  let ofs = 0;

  const data: any[] = [];

  for (let item of mapping) {
    switch (item.type) {
      case "const": {
        const expect = Buffer.from(item.value);
        const v = buf.slice(ofs, ofs + expect.length);
        ofs += expect.length;
        if (v.length !== expect.length) return null;
        break;
      }

      case "id": {
        const v = buf.slice(ofs, ofs + 12);
        ofs += 12;
        if (v.length !== 12) return null;
        data.push(v.toString("hex"));
        break;
      }
    }
  }

  return {
    data,
    type
  };
}
