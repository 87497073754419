import { Context } from "@nuxt/types";

export default ({ app } : Context) => {
    const router = app.router;
    const matcher = (router as any).matcher;
    const match = matcher.match;
    matcher.match = function(raw, currentRoute, redirectedFrom) {
        if(raw && typeof raw === 'string') {
            const r = match.call(this, raw, currentRoute, redirectedFrom);
            if(!r) return null;
            raw = {
                path: r.path,
                params: r.params,
                query: r.query,
                hash: r.hash,
            }
        }
        if(raw && typeof raw === 'object' && currentRoute && typeof currentRoute === 'object') {
            if(currentRoute.params.shop) {
                if(raw.path) {
                    if(!raw.path.match(/^\/([a-f\d]{24})\//)) {
                        raw.path = '/' + currentRoute.params.shop + raw.path;
                    }
                }
            }
        }
        return match.call(this, raw, currentRoute, redirectedFrom);
    }
}

