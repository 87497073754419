
import { Component, Prop, Vue, Watch, mixins, FindType, VModel, getID } from "@feathers-client";
import ItemPickerBase from "../components-internal/ItemPickerBase.vue";
import RadioIcon from "../components-internal/RadioIcon.vue";
import CheckIcon from "../components-internal/CheckIcon.vue";

@Component({
  components: {
    RadioIcon,
    CheckIcon,
  },
})
export default class ItemPicker extends mixins(ItemPickerBase) {
  @Prop()
  buttonClass: string;

  @Prop({ default: false })
  noPadding: boolean;

  @Prop({ type: Boolean })
  bottomBar: boolean;

  @Prop(Boolean)
  wrap: boolean;

  @Prop(Boolean)
  light: boolean;

  @Prop(Boolean)
  radio: boolean;

  @Prop(Boolean)
  check: boolean;

  @Prop({ type: String, default: "2.5rem" })
  itemHeight: string;

  @Prop()
  grid: string;

  @Prop({ type: Boolean, default: true })
  outlined: boolean;

  @Prop({ type: Boolean })
  lock: Boolean;

  @Prop({ type: Boolean, default: false })
  around: boolean;

  @Prop()
  selectedColor: string;

  @Prop(Boolean)
  hideText: boolean;

  @Watch("inputValue", { deep: true })
  onChange() {
    this.$emit("change");
  }
}
