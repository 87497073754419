import { render, staticRenderFns } from "./Editor.vue?vue&type=template&id=481e7751&scoped=true"
import script from "./Editor.vue?vue&type=script&lang=ts"
export * from "./Editor.vue?vue&type=script&lang=ts"
import style0 from "./Editor.vue?vue&type=style&index=0&id=481e7751&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "481e7751",
  null
  
)

/* custom blocks */
import block0 from "./Editor.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VIcon})


/* hot reload */
if (module.hot) {
  var api = require("/home/jason/p/boxs/server/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('481e7751')) {
      api.createRecord('481e7751', component.options)
    } else {
      api.reload('481e7751', component.options)
    }
    module.hot.accept("./Editor.vue?vue&type=template&id=481e7751&scoped=true", function () {
      api.rerender('481e7751', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "dep/schema-editor/Editor.vue"
export default component.exports