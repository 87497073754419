

import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import { Order } from "@common/pos";
import { DiscountRule } from '@common/couponUtils'
import { FindPopRawType, FindType } from '@feathers-client'
import { groupBy, getID } from '@feathers-client/util'

@Component
export default class OrderSystemPoint extends Vue {
    @Prop()
    session : Order;

    get pointRules() {
        return Array.from(groupBy(this.$pos.pointDiscounts, it => getID(it.usePoint))
            .entries())
            .map(([k, v]) => {
                v = v.filter(it => DiscountRule.basicCheck(it, this.session))
                v.sort((a, b) => b.usePointRatioInt - a.usePointRatioInt);

                return v[0] ? {
                    point: k,
                    discount: v[0],
                } : null;
            })
            .filter(it => !!it)
    }

}

