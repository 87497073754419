
import _ from "lodash";
import { Component, Prop, Vue, Watch, mixins, Ref } from "@feathers-client";
import { Order } from "@common/pos";
import { checkID } from "@feathers-client";
import { createScreen, Screen } from "pos-printer/multiscreen";
import BBPOS from "~/mixins/bbpos";
import AllinpayPOS from "~/mixins/allinpay";
import { ProductType, ProductSearchType, OrderProductType } from "@common/common";

@Component
export default class OrderSystemCart extends mixins(BBPOS, AllinpayPOS) {
  @Prop()
  session: Order;

  checkID = checkID;

  peopleMenu = false;

  @Watch("peopleMenu")
  onPeopleMenuChange() {
    if (this.peopleMenu) {
      this.calendarView = false;
    }
  }

  paying = false;
  // for back button in partial payment
  forceCart = false;

  createOrderOnly = false;

  showingCart = false;

  calendarView = false;

  @Watch("calendarView")
  onCalendarViewChange() {
    if (this.calendarView) {
      this.peopleMenu = false;
    }
  }


  calendarFilterSkus: string[] = null;
  calendarMode: 'history' | 'pick' = 'history';
  amountChange: string = "";
  cashValidCart: boolean = false;

  get cartProducts() {
    return this.session?.products.length;
  }

  closeCurrent(): void {
    switch (this.currentScreen) {
      case "table-order-system-people":
        this.peopleMenu = false;
        break;
      case "table-order-system-calendar":
        this.calendarView = false;
        break;
      default:
        break;
    }
  }

  get currentScreen() {
    if (this.peopleMenu) {
      return "table-order-system-people";
    } else if (this.calendarView) {
      return "table-order-system-calendar";
    } else if (this.isDone || this.isRefunded || this.isVoid || this.isCancelled) {
      return "table-order-system-finish-order";
    } else if (this.isPaying) {
      return "table-order-system-checkout";
    }

    return "table-order-system-picker";
  }

  get readonly() {
    return this.session.status !== "pending";
  }

  get isPaying() {
    return this.session.status === "partial" ? !this.forceCart : this.session.status === "pending" ? this.paying : false;
  }

  get isDone() {
    return this.session.status === "confirmed" || this.session.status === "done";
  }

  get isVoid() {
    return this.session.status === "void";
  }

  get isCancelled() {
    return this.session.status === "cancelled";
  }

  get isRefunded() {
    return this.session.paymentStatus === "refunded";
  }

  loading = false;
  get canCheckBill() {
    return (this.session.status === "pending" || this.session.status === "partial") && this.session.cartValid && !this.session.cartEmpty;
  }

  get totalPriceInt() {
    return this.session.outstandingInt ?? this.session.amountInt;
  }

  async confirmOrder() {
    this.paying = true;
    this.forceCart = false;
  }

  async cancelBilling() {
    this.paying = false;
    this.forceCart = true;
  }

  exitingKiosk = false;

  async menuButton() {
    if (this.isPaying) {
      this.cancelBilling();
    } else {
      if (this.$pos.kioskMode) {
        if (
          !(await this.$openDialog(
            import("~/components/table/orderSystem/kioskPasswordDialog.vue"),
            {},
            {
              maxWidth: "400px",
            },
          ))
        ) {
          return;
        }
        this.exitingKiosk = true;
      }
      this.$root.$emit("toggleDrawer");
    }
  }

  updateDrawer(drawer: boolean) {
    if (!drawer) {
      setTimeout(() => (this.exitingKiosk = false), 100);
    }
  }

  async handleRouteLeave(to, from, next) {
    if (this.isPaying) {
      await this.cancelBilling();
      next();
    } else {
      if (!this.exitingKiosk && this.$pos.kioskMode) {
        if (
          !(await this.$openDialog(
            import("~/components/table/orderSystem/kioskPasswordDialog.vue"),
            {},
            {
              maxWidth: "400px",
            },
          ))
        ) {
          next(false);
          return;
        }
        this.exitingKiosk = true;
        next();
      } else {
        next();
      }
    }
  }

  nextOrder(orderId?: string, ...args: any[]) {
    this.calendarMode = "history";
    this.calendarView = false;
    this.paying = false;
    this.$emit("nextOrder", orderId, ...args);
  }

  cleanOnUpdated = false;

  onScanner({ code, device }) {
    code = `${code || ""}`.trim();
    this.handleProductCode(code);
  }

  onScannerKeyboard({ code }) {
    code = `${code || ""}`.trim();
    this.handleProductCodeOrWeight(code);
  }

  handleProductCodeOrWeight(code) {
    this.handleProductCode(code);
  }

  cachedRequest: Record<string, ScanningRequest> = {};
  pending: ScanningRequest[] = [];

  handleProductCode(code) {
    let request = this.cachedRequest[code];
    if (!request) {
      this.cachedRequest[code] = request = new ScanningRequest(this, code);
    }
    request.apply();
  }

  async handleSearchCode(code: string) {
    let request = this.cachedRequest[code];
    if (!request) {
      this.cachedRequest[code] = request = new ScanningRequest(this, code);
    }
    request.apply(true);
  }

  async addToCart(item: ProductSearchType) {
    let request = this.cachedRequest[`${item.productGroup}`];
    if (!request) {
      this.cachedRequest[`${item.productGroup}`] = request = new ScanningRequest(this, `${item.productGroup}`, item);
    }
    request.apply();
  }

  async addSkuToCart(item: ProductType) {
    let request = this.cachedRequest[`${item._id}`];
    if (!request) {
      this.cachedRequest[`${item._id}`] = request = new ScanningRequest(this, `${item._id}`, null, item);
    }
    await request.apply();
  }

  bookingResp: (booking: OrderProductType['booking']) => void;

  toggleCalendar() {
    this.calendarView = !this.calendarView;
    this.calendarFilterSkus = null;
    this.calendarMode = 'history';
    if(!this.calendarView) {
      this.bookingResp?.(null);
      this.bookingResp = null;
    }
  }

  async chooseTimeslotBySku(skuId: string) {
    this.calendarView = true;
    this.calendarFilterSkus = [skuId];
    this.calendarMode = 'pick'
    return new Promise<OrderProductType['booking']>(resolve => {
      this.bookingResp = resolve;
    });
  }

  async chooseBooking(booking: OrderProductType['booking']) {
    this.bookingResp?.(booking);
    this.bookingResp = null;
    this.calendarView = false;
  }
}

export class ScanningRequest {
  constructor(
    public parent: OrderSystemCart,
    public code: string,
    public item: ProductSearchType = null,
    public sku: ProductType = null,
  ) {
    this.loadPromise = this.load();
    this.loadPromise.finally(() => {
      this.loadPromise = null;
      this.parent.pending = this.parent.pending.filter(it => it !== this);
    });
  }

  get displayName() {
    return this.sku?.name ?? this.item?.name ?? this.code;
  }

  loadPromise: Promise<void>;

  type: "product" | "order" | "user" = "product";

  items: ProductSearchType[] = [];
  skus: ProductType[] = [];
  quantity = 1;
  orderId: string = null;
  userId: string = null;

  async load() {
    if (!this.item) {
      if (this.code.startsWith("http://") || this.code.startsWith("https://")) {
        // handle url
        const domains = new Set(
          [
            this.parent.$shop?.posInvoiceDomain,
            window.location.hostname,
            "boxs.hk",
            this.parent.$qrPrefix ? this.parent.$qrPrefix.substring(this.parent.$qrPrefix.lastIndexOf("/") + 1) : "",
          ].filter(it => !!it),
        );

        const u = new URL(this.code);
        if (domains.has(u.hostname)) {
          const parts = u.pathname.substring(1).split("/");
          const remain = parts.slice(1);
          const remainFull = remain.join("/");

          switch (parts[0]) {
            case "o": {
              this.orderId = Buffer.from(remainFull, "base64").toString("hex");
              this.type = "order";
              return;
            }

            case "p": {
              const data = Buffer.from(remainFull, "base64");
              const groupId = data.slice(0, 12).toString("hex");
              this.item = (
                await this.parent.$feathers.service("shop/product/searches").find({
                  query: {
                    productGroup: groupId,
                    shop: this.parent.$shop._id,
                    $paginate: false,
                  },
                  paginate: false,
                })
              )[0];
              let skuId: string;
              let extra = data.slice(12);
              for (let i = 0; i < extra.length; ) {
                const c = String.fromCharCode(extra[i++]);
                switch (c) {
                  case "q":
                    this.quantity = +extra.readFloatLE(i).toFixed(6);
                    i += 4;
                    break;
                  case "s":
                    skuId = extra.slice(i, i + 12).toString("hex");
                    i += 12;
                    break;
                  default:
                    throw new Error(`${this.parent.$t("pos.error.badExtraCode")}`);
                }
              }

              if (skuId) {
                this.sku = (
                  await this.parent.$feathers.service("shop/product/skus").find({
                    query: {
                      _id: skuId,
                      $paginate: false,
                    },
                    paginate: false,
                  })
                )[0];
              }
              break;
            }

            case "u": {
              this.type = "user";
              this.userId = Buffer.from(remainFull, "base64").toString("hex");
              return;
            }
          }
        }
      } else {
        await Promise.all([this.findBySlug(), this.findByPos()]);
      }
    }
    if (this.item && !this.sku && !this.skus?.length) {
      this.skus = (
        await this.parent.$feathers.service("shop/product/skus").find({
          query: {
            productGroup: this.item.productGroup,
            $paginate: false,
          },
          paginate: false,
        })
      ).filter(it => {
        const shopTable = it.shopTable.find(it => checkID(it.shop, this.parent.$shop));
        return shopTable && shopTable.status !== "draft";
      });
    }
    if (this.skus?.length === 1) {
      this.sku = this.skus[0];
    }
  }

  async findBySlug() {
    const skus = await this.parent.$feathers.service("shop/product/skus").find({
      query: {
        slug: this.code,
        $paginate: false,
      },
      paginate: false,
    });

    this.skus.push(...skus);
    if (!skus.length) {
      const products = await this.parent.$feathers.service("shop/product/searches").find({
        query: {
          slug: this.code,
        },
      });
      if (products.total > 10) {
        throw new Error("Too many options");
      }
      this.items.push(...products.data);
    }
  }

  async findByPos() {
    if (this.parent.$pos.posCode) {
      const products = await this.parent.$feathers.service("shop/product/searches").find({
        query: {
          specs: {
            $elemMatch: {
              spec: this.parent.$pos.posCode,
              value: this.code,
            },
          },
          shop: this.parent.$shop._id,
        },
      });
      if (products.total > 10) {
        throw new Error("Too many options");
      }
      this.items.push(...products.data);
      if (this.items.length === 1) {
        this.item = this.items[0];
      } else if (!this.items.length) {
        this.skus.push(
          ...(await this.parent.$feathers.service("shop/product/skus").find({
            query: {
              specs: {
                $elemMatch: {
                  spec: this.parent.$pos.posCode,
                  value: this.code,
                },
              },
              $paginate: false,
            },
            paginate: false,
          })),
        );
      }
    }
  }

  async apply(silent = false) {
    try {
      if (this.loadPromise) {
        this.parent.pending.push(this);
        await this.loadPromise;
      }
      switch (this.type) {
        case "product": {
          await this.applyProdudct(silent);
          break;
        }
        case "order": {
          await this.applyOrder();
          break;
        }
        case "user": {
          await this.applyUser();
          break;
        }
      }
    } catch (e) {
      console.warn(e);
      if (!silent) {
        this.parent.$store.commit("SET_ERROR", e.message);
      }
    }
  }

  async applyProdudct(silent: boolean) {
    if (this.sku) {
      let booking;
      if (this.sku.type === "booking") {
        booking = await this.parent.chooseTimeslotBySku(this.sku._id);
        if (!booking) return;
      }
      await this.parent.session.addProduct(
        {
          sku: this.sku,
          ...(booking ? { booking } : {}),
        },
        {
          quantity: this.quantity,
          moveToBottom: true,
        },
      );
    } else if (this.skus?.length) {
      const sku = await this.parent.$openDialog(
        import("~/components/dialogs/pos/SkuSelector.vue"),
        {
          product: this.item,
          skus: this.skus,
          warehouse: this.parent.session?.warehouse,
        },
        {
          maxWidth: "80vw",
          contentClass: "editor-dialog",
        },
      );
      if (sku) {
        await this.parent.addSkuToCart(sku);
      }
    } else if (this.items?.length) {
      // handle multiple products case
      const item = await this.parent.$openDialog(
        import("~/components/dialogs/pos/ProductSelector.vue"),
        {
          products: this.items,
        },
        {
          maxWidth: "80vw",
          contentClass: "editor-dialog",
        },
      );
      if (item) {
        await this.parent.addToCart(item);
      }
    } else if (!silent) {
      this.parent.$store.commit("SET_ERROR", this.parent.$t("pos.noSkuFound"));
    }
  }

  async applyOrder() {
    this.parent.nextOrder(this.orderId);
  }

  async applyUser() {
    const user = await this.parent.$feathers.service("shop/users").get(this.userId);
    await this.parent.session.setUser(user);
    this.parent.peopleMenu = true;
  }
}
