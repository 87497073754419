

import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import _ from "lodash";
import { Currencies, CurrencyDef, CurrencyType, MultiCurrencyType } from '@feathers-client/currency'
import EditorTextField from '@schemaEditor/EditorTextField.vue'

@Component({
  components: {
    EditorTextField,
  }
})
export default class CurrencyEditor extends Vue {
    @Prop()
    label : string

    @Prop()
    value : number | null

    @Prop(Boolean)
    readonly : boolean

    @Prop(Boolean)
    editor : boolean

    @Prop(Boolean)
    multiLine : boolean

    @Prop({ type: Boolean })
    outlined : boolean

    @Prop({ type: Boolean })
    dense : Boolean

    @Prop({ type: Boolean })
    hideDetails : Boolean

    @Prop(Boolean)
    revert: boolean
    
    @Prop()
    placeholder: string

    get inputValue() {
        const v = this.value;
        if(typeof v === 'number' || !isNaN(+v) && v !== null) {
            if(this.revert) {
                return this.$pos.fromHumanNumberToRaw(+v)
            }
            return this.$pos.getHumanNumber(+v);
        }
        return '';
    }

    set inputValue(v) {
        if(typeof v === 'number' || !isNaN(+v)) {
            if(this.revert) {
                this.$emit('input', this.$pos.getHumanNumber(+v));
            } else {
                this.$emit('input', this.$pos.fromHumanNumberToRaw(+v));
            }
        } else if(this.inputValue !== null) {
            this.$emit('input', null);
        }
    }

    getIcon(icon) {
        switch(icon) {
            case 'USD': return '- flag-icon flag-icon-us';
            case 'HKD': return '- flag-icon flag-icon-hk';
            case 'CNY': return '- flag-icon flag-icon-cn';
        }
    }
};
