
import { Component, Vue, Prop, Ref } from "@feathers-client";
import { LangArrType } from "@feathers-client/i18n";
import { defineComponent } from "site-editor/def";
import { SiteEditorImageItem } from "../../editorComponents/image.vue";
// @ts-ignore
import moreSvg from "!vue-svg-loader!../../assets/more.svg";
import _ from "lodash";

export const componentInfo = defineComponent({
  group: "image",
  groupProps: {
    editorCols: 3,
  },
  variants: [
    {
      key: "square",
    },
    {
      key: "circle",
    },
    {
      key: "full",
    },
  ],
  hideConfirm: true,
});

@Component({
  components: {
    moreSvg,
  },
})
export default class SiteEditorImage extends Vue {
  @Prop()
  variant: string;

  @Prop()
  title: LangArrType;

  @Prop()
  remarks: LangArrType;

  @Prop()
  displayPrice: boolean;

  @Prop({ type: Boolean, default: true })
  displayDesc: boolean;

  @Prop()
  editType: string;

  @Prop()
  listType: string;

  @Prop()
  selectedList: string;

  @Prop()
  sorting: string;

  @Prop()
  numOfItem: number;

  @Prop()
  items: SiteEditorImageItem[];

  @Prop()
  titleDisplayMode: "left" | "center" | "right";

  @Prop()
  contentAlign: "left" | "center" | "right";

  @Prop()
  fontRemarksColor: string;

  @Prop()
  fontColor: string;

  @Prop()
  imageFontColor: string;

  @Prop()
  size: "small" | "large";

  searchItems: Record<string, SiteEditorImageItem> = {};

  showLeftArrow = false;

  showRightArrow = false;

  getLinkProp(item: SiteEditorImageItem) {
    if (!item.url) return {};
    if (this.editType === "custom" && item.urlType === "external")
      return { href: item.url, target: "_blank", rel: "noopener noreferrer" };
    else return { to: this.$localePath(item.url) };
  }

  getLink(item: SiteEditorImageItem) {
    if (!item.url) return "div";
    if (item.urlType === "internal") {
      return "nuxt-link";
    } else {
      return "a";
    }
  }

  getItem(id: string, idx: number) {
    return this.searchItems[id] || this.items[idx];
  }

  async beforeMount() {
    let searches = [];
    if (this.editType === "custom") {
      searches = await this.$feathers.service("shop/product/searches").find({
        query: {
          productGroup: { $in: _.map(this.items, item => item.productGroup) },
          $paginate: false,
        },
        paginate: false,
      });
    } else {
      if (this.listType === "product/relateds") {
        const relateds = await this.$feathers.service("shop/product/relateds").get(this.selectedList);

        searches = await this.$feathers.service("shop/product/searches").find({
          query: {
            productGroup: { $in: relateds.products.map(it => it.group) },
            $sort: this.sorting === "recommended" ? { order: 1, _id: -1 } : { date: -1, _id: -1 },
            $paginate: false,
            $limit: this.numOfItem,
          },
          paginate: false,
        });
      } else {
        searches = await this.$feathers.service("shop/product/searches").find({
          query: {
            ...(this.listType === "categories"
              ? {
                  "categories.tree._id": { $in: this.selectedList },
                }
              : {}),
            ...(this.listType === "brands"
              ? {
                  "brand._id": { $in: this.selectedList },
                }
              : {}),
            ...(this.listType === "tags"
              ? {
                  "tags._id": { $in: this.selectedList },
                }
              : {}),
            $sort: this.sorting === "recommended" ? { order: 1, _id: -1 } : { date: -1, _id: -1 },
            $paginate: false,
            $limit: this.numOfItem,
          },
          paginate: false,
        });
      }
    }
    const mappedSearchItems = searches.map(search => {
      return {
        ...search,
        file: search.mainImage || null,
        title: search.name ? search.name : "",
        slug: search.slug ? search.slug : "",
        price: search.pricing[0]?.min?.price ?? 0,
        urlType: "internal",
        url: `/product/${search.slug}?id=${search._id}`,
        productGroup: search.productGroup,
      };
    });

    this.searchItems = Object.fromEntries(mappedSearchItems.map(search => [search.productGroup, search]));
  }
}
