
import { Context } from '@nuxt/types'

export default async function (ctx: Context) {
    const { store, redirect, req, app } = ctx;
    if(store.getters.userId && !store.state.shops) {
        const shops = (await app.$feathers.service('shops').find({
            query: {
                $populate: ['icon']
            },
            noAuthCheck: true,
        })).data || [];
        const shop = ctx.route && shops.find(it => `${it._id}` === `${ctx.route.params.shop}`) || shops[0];
        store.commit('SET_SHOPS', {
            shops,
            shop: shop,
        });
    }
    if(!store.state.shops || !store.state.shops.length) return;
    const currentShop = store.state.shop && store.state.shop._id || null;
    const targetShop = ctx.route.params.shop || store.state.shops[0]._id;

    if(targetShop) {
        if(targetShop !== currentShop) {
            const shop = store.state.shops.find(it => it._id === targetShop);
            if(!shop) {
                redirect('/dashboard');
                return;
            }
            store.commit("SET_SHOP", shop);
        }
    }
}