
import { Vue, Component, Prop, VModel, Watch } from "@feathers-client";
import _ from "lodash";
import { PaymentMethod } from "@common/common";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import EditorObjectPickerNew from "@schemaEditor/EditorObjectPickerNew.vue";
import { RazerPaymentMethod } from "pos-printer/razerpay";
import { PayType } from "pos-printer/turncloud";
import EditorCheckbox from "@schemaEditor/EditorCheckbox.vue";
import { PaymentMethodBase } from "pos-printer/payments/methodBase";
import "~/plugins/payments";

@Component({
  components: {
    EditorTextField,
    EditorObjectPickerNew,
    EditorCheckbox,
  },
})
export default class PaymentOptionsEditor extends Vue {
  @Prop()
  label: string;

  @VModel({ default: () => ({}) })
  inputValue: any;

  @Prop()
  item: PaymentMethod;

  manager: PaymentMethodBase = null;

  @Watch("item.type", { immediate: true })
  async onMethod() {
    this.manager = null;
    if (this.item?.type) {
      const type = this.item.type;
      const manager = await this.$paymentManager.getAllMethod(type);
      if (manager && type === this.item?.type) {
        this.manager = manager;
      }
    }
  }

  mounted() {}

  get needOptions() {
    if(this.manager) {
      return true;
    }
    return ["bbpos", "allinpay", "point", "bank", "razer", "turnCloud"].includes(this.item?.type);
  }

  get options() {
    const self = this;
    const keys = [
      "methods",
      "point",
      "usePointRatioInt",
      "minutesToExtendExpiry",
      "showReceipt",
      "allinpaySubTypes",
      "currency",
      "turnCloudTaxEnabled",
    ] as const;

    const r = {};
    for (let key of keys) {
      Object.defineProperty(r, key, {
        get() {
          return self.inputValue[key];
        },
        set(v) {
          self.inputValue = {
            ...self.inputValue,
            [key]: v,
          };
        },
      });
    }

    return r as Record<typeof keys[number], any>;
  }

  get bbposOptions() {
    return [
      { _id: "CREDIT", name: this.$t("paymentMethods.CREDIT") },
      { _id: "ALIPAY", name: this.$t("paymentMethods.ALIPAY") },
      { _id: "WECHAT", name: this.$t("paymentMethods.bbpos_wechat") },
      { _id: "OCTOPUS", name: this.$t("paymentMethods.OCTOPUS") },
      { _id: "QUICKPASS", name: this.$t("paymentMethods.QUICKPASS") },
      { _id: "OTHERS", name: this.$t("basic.others") },
    ];
  }

  get availAllinpaySubTypes() {
    return [
      { _id: "CREDIT", name: this.$t("paymentMethods.CREDIT") },
      { _id: "SCAN", name: this.$t("paymentMethods.SCAN") },
      { _id: "QRCODE", name: this.$t("paymentMethods.QRCODE") },
    ];
  }

  get availAllinpayCurrencies() {
    return [
      { _id: "HKD", name: "HKD" },
      { _id: "SGD", name: "SGD" },
      { _id: "USD", name: "USD" },
    ];
  }

  get availRazerSubTypes() {
    return Object.keys(RazerPaymentMethod).map(key => ({
      _id: key,
      name: this.$t(`razer.${key}`),
    }));
  }

  get availTurnCloudSubTypes() {
    return Object.keys(PayType).map(key => ({
      _id: key,
      name: this.$t(`turncloud.${key}`),
    }));
  }

  // Generic methods options

  get availSubMethods() {
    const subMethods = this.manager?.capabilities?.subMethods;
    if(subMethods) {
      return subMethods.map(method => ({
        _id: method.subType,
        name: this.$td(method.name)
      }))
    }
  }

  get subMethods() {
    return this.inputValue?.subMethods ?? [];
  }

  set subMethods(v) {
    this.inputValue = {
      ...this.inputValue,
      subMethods: v || [],
    };
  }

  get secretFields() {
    return this.manager?.capabilities?.propsFields || {};
  }

  get hasSecretFields() {
    return Object.keys(this.secretFields).length > 0;
  }

  getField(key: string) {
    return this.inputValue?.[key];
  }

  setField(key: string, value: any) {
    this.inputValue = {
      ...this.inputValue,
      [key]: value,
    };
  }

  get hasEnv() {
    return !!this.manager?.capabilities?.envs?.length;
  }

  get availEnvs() {
    return (this.manager?.capabilities?.envs || []).map(it => ({
      _id: it.id,
      name: it.name || (it.testing ? this.$t("basic.testing") : this.$t("basic.production")),
    }));
  }

  get env() {
    return this.item.env;
  }

  set env(v) {
    const env = this.manager?.capabilities?.envs?.find?.(it => it.id === v);
    if (!env) return;
    Vue.set(this.item, "env", env.id);
    Vue.set(this.item, "testing", env.testing ?? false);
  }
}
