import type * as libType from "./lib";
export type { OctopusDriver, OctopusOptions } from "./lib";
export * from "./lib";

export async function init(archive: Uint8Array) {
  const file = parseArchive(archive);
  const libFile = file.files.find((it) => it.path === "lib");
  if (!libFile) {
    throw new Error("No lib");
  }
  const data = Buffer.from(file.blobs[libFile.blob]).toString();
  const func: typeof libType = new Function(
    "const exports = {};" + data + ";return exports;"
  )();
  return func.init.bind(null, file);
}

export function parseArchive(buf: Uint8Array) {
  const blobs: Buffer[] = [];

  let ofs = 0;
  const octopusBuf = Buffer.from(buf);
  while (ofs < octopusBuf.length) {
    const size = octopusBuf.readUInt32LE(ofs);
    ofs += 4;
    const blob = octopusBuf.slice(ofs, ofs + size);
    ofs += size;
    blobs.push(blob);
  }
  const json = JSON.parse(blobs[0].toString()) as {
    path: string;
    size: number;
    mtime: number;
    mode: number;
    uid: number;
    gid: number;
    blob: number;
  }[];

  return {
    blobs,
    files: json,
  } as libType.ArchiveFile;
}
