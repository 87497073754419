var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex flex-col gap-y-6",class:`variant-${_vm.variant}`},[_c('div',{staticClass:"lg:px-30 md:px-15 <md:px-8 d-flex flex-col gap-y-6 text-[var(--fg-color)]",style:({ '--fg-color': _vm.fontColor, '--fg-remarks-color': _vm.fontRemarksColor })},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"flex-grow",class:{ 'text-center': _vm.titleDisplayMode === 'center', 'text-right': _vm.titleDisplayMode === 'right' }},[(_vm.$td(_vm.title))?_c('div',{staticClass:"font-bold text-4xl <md:text-xl",domProps:{"innerHTML":_vm._s(_vm.$htmlContent(_vm.$td(_vm.title)))}}):_vm._e(),_vm._v(" "),(_vm.$td(_vm.remarks))?_c('div',{staticClass:"text-[var(--fg-remarks-color)] font-bold text-xl <md:text-base",domProps:{"innerHTML":_vm._s(_vm.$htmlContent(_vm.$td(_vm.remarks)))}}):_vm._e()]),_vm._v(" "),(_vm.editType === 'auto' && _vm.titleDisplayMode !== 'center')?_c('nuxt-link',{staticClass:"d-flex align-center text-lg <md:text-sm self-end",attrs:{"to":_vm.$localePath(
            `/shop?query=${JSON.stringify({
              currentFilter: {
                ...(_vm.listType === 'categories'
                  ? {
                      'categories.tree._id': { $in: _vm.selectedList },
                    }
                  : {}),
                ...(_vm.listType === 'brands'
                  ? {
                      'brand._id': { $in: _vm.selectedList },
                    }
                  : {}),
                ...(_vm.listType === 'tags'
                  ? {
                      'tags._id': { $in: _vm.selectedList },
                    }
                  : {}),
              },
              ...(_vm.sorting === 'recommended'
                ? {
                    sortBy: ['order'],
                  }
                : { sortBy: ['date'] }),

              ...(_vm.sorting === 'recommended'
                ? {
                    sortDesc: [false],
                  }
                : { sortDesc: [true] }),
            })}`,
          )}},[_vm._v("\n        "+_vm._s(_vm.$t("viewMore"))+"\n        "),_c('more-svg',{staticClass:"w-5 h-5 custom-icon ml-2"})],1):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"lg:px-30 md:px-15 <md:px-8 content-container d-flex flex-wrap gap-6 justify-center"},[(_vm.editType === 'custom')?_vm._l((_vm.items),function(item,idx){return _c(_vm.getLink(_vm.getItem(item.productGroup, idx)),_vm._b({key:`image-${idx}`,tag:"component",staticClass:"d-flex flex-col self-start gap-y-5 w-min max-w-75"},'component',_vm.getLinkProp(_vm.getItem(item.productGroup, idx)),false),[_c('v-img',{staticClass:"content-image self-center",class:{ 'h-40 w-40': _vm.size === 'small', 'h-48 w-48': _vm.size === 'large' },attrs:{"aspect-ratio":1,"src":_vm.$imageSet(_vm.getItem(item.productGroup, idx).file)}}),_vm._v(" "),_c('div',{staticClass:"d-flex flex-col gap-y-2 align-center text-[var(--image-fg-color)]",style:({
            ...(_vm.contentAlign === 'left' ? { 'align-items': 'start !important' } : {}),
            ...(_vm.contentAlign === 'right' ? { 'align-items': 'end !important' } : {}),
            '--image-fg-color': _vm.imageFontColor,
          })},[(_vm.$td(_vm.getItem(item.productGroup, idx).title))?_c('div',{staticClass:"content-inner font-bold whitespace-normal text-xl text-center"},[_vm._v("\n            "+_vm._s(_vm.$td(_vm.getItem(item.productGroup, idx).title))+"\n          ")]):_vm._e(),_vm._v(" "),(_vm.displayDesc && _vm.$td(_vm.getItem(item.productGroup, idx).desc))?_c('div',{staticClass:"break-words whitespace-normal align-left content-inner text-center"},[_vm._v("\n            "+_vm._s(_vm.$td(_vm.getItem(item.productGroup, idx).desc))+"\n          ")]):_vm._e(),_vm._v(" "),(_vm.displayPrice && _vm.getItem(item.productGroup, idx).price !== null)?_c('div',{staticClass:"content-inner"},[_vm._v("\n            "+_vm._s(_vm.$price(_vm.getItem(item.productGroup, idx).price, "pre"))+"\n          ")]):_vm._e()])],1)}):(_vm.editType === 'auto')?_vm._l((_vm.searchItems),function(item,idx){return _c(_vm.getLink(item),_vm._b({key:`image-idx`,tag:"component",staticClass:"d-flex flex-col self-start gap-y-2 w-min max-w-75"},'component',_vm.getLinkProp(item),false),[_c('v-img',{staticClass:"content-image self-center",class:{ 'h-40 w-40': _vm.size === 'small', 'h-48 w-48': _vm.size === 'large' },attrs:{"aspect-ratio":1,"src":_vm.$imageSet(item.file)}}),_vm._v(" "),_c('div',{staticClass:"d-flex flex-col gap-y-2 align-center text-[var(--image-fg-color)]",style:({
            ...(_vm.contentAlign === 'left' ? { 'align-items': 'start !important' } : {}),
            ...(_vm.contentAlign === 'right' ? { 'align-items': 'end !important' } : {}),
            '--image-fg-color': _vm.imageFontColor,
          })},[(_vm.$td(item.title))?_c('div',{staticClass:"content-inner font-bold text-xl text-center"},[_vm._v("\n            "+_vm._s(_vm.$td(item.title))+"\n          ")]):_vm._e(),_vm._v(" "),(_vm.displayDesc && _vm.$td(item.desc))?_c('div',{staticClass:"break-words whitespace-normal align-left content-inner text-center"},[_vm._v("\n            "+_vm._s(_vm.$td(item.desc))+"\n          ")]):_vm._e(),_vm._v(" "),(_vm.displayPrice && item.price !== null)?_c('div',{staticClass:"content-inner"},[_vm._v("\n            "+_vm._s(_vm.$price(item.price, "pre"))+"\n          ")]):_vm._e()])],1)}):_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"d-flex justify-center"},[(_vm.editType === 'auto' && _vm.titleDisplayMode === 'center')?_c('nuxt-link',{staticClass:"w-max d-flex align-center text-lg <md:text-sm justify-center",attrs:{"to":_vm.$localePath(
          `/shop?query=${JSON.stringify({
            currentFilter: {
              ...(_vm.listType === 'categories'
                ? {
                    'categories.tree._id': { $in: _vm.selectedList },
                  }
                : {}),
              ...(_vm.listType === 'brands'
                ? {
                    'brand._id': { $in: _vm.selectedList },
                  }
                : {}),
              ...(_vm.listType === 'tags'
                ? {
                    'tags._id': { $in: _vm.selectedList },
                  }
                : {}),
            },
            ...(_vm.sorting === 'recommended'
              ? {
                  sortBy: ['order'],
                }
              : { sortBy: ['date'] }),

            ...(_vm.sorting === 'recommended'
              ? {
                  sortDesc: [false],
                }
              : { sortDesc: [true] }),
          })}`,
        )}},[_vm._v("\n      "+_vm._s(_vm.$t("viewMore"))+"\n      "),_c('more-svg',{staticClass:"w-5 h-5 custom-icon ml-2"})],1):_vm._e()],1)])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }