
import { Component, Prop, Vue, Watch, mixins, Ref, VModel } from "nuxt-property-decorator";

@Component
export default class MenuListItem extends Vue {
  @Prop()
  text: string;

  @Prop({ default: "w-6 h-6 box-border" })
  checkerClass: any;

  @Prop({ type: Boolean })
  lock: Boolean;

  @VModel()
  inputValue: boolean;

  @Prop(Boolean)
  disabled: boolean;

  changed = false;

  toggleValue() {
    this.inputValue = !this.inputValue;
    this.changed = true;
  }
}
