
let device;
import SerialDevice, { getDevices, connect } from '../../ports/serial'
import { getVersion } from '../../nativeIntegrations'
import Vue from 'vue'

class WeightDevice {
    constructor(public context : Vue, public name : string) {
        this.context = context;
        this.connected = false;
        this.connecting = false;
        this.device = null;
        this.input = [];
        this.timeout = null;
    }

    connected = false;
    connecting = false;
    device : SerialDevice = null;

    timeout : number | NodeJS.Timer | null = null;
    input : string[] = [];

    async connect() {
        try {
            if(this.device || this.connecting) return;
            this.connecting = true;

            const device = await connect(this.name);
            device.on('data', this.onData);
            this.connected = true;
        } finally {
            this.connecting = false;
        }
    }

    onData = (buf : Buffer) => {
        const line = Buffer.from(buf).toString();
        const parts = line.split('\n');
        parts.forEach((it, idx) => {
            idx && this.flushBuffer();
            it && this.inputBuffer(it);
        })
    }

    inputBuffer(data) {
        this.input.push(data);
        if(this.timeout) {
            clearTimeout(<number>this.timeout);
            this.timeout = null;
        }
        this.timeout = setTimeout(this.flushBuffer.bind(this), 500);
    }

    flushBuffer() {
        const line = this.input.join('');
        if(this.timeout) {
            clearTimeout(<number>this.timeout);
            this.timeout = null;
        }
        if(line) {
            const f = parseFloat(line) / 1000;
            if(!isNaN(f)) {
                this.context.$emit('weight', f);
            }
        }

        this.input = [];
    }
}

export function init(ctx : Vue, name : string) {
    if(!getVersion()) return;

    if(device) return device;
    device = new WeightDevice(ctx, name);
    device.connect();
    return device;
}

