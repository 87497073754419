
import { Component, FindType, Prop, Vue } from "@feathers-client";
import EditorDatePicker from "@schemaEditor/EditorDatePicker.vue";
import moment from "moment";

@Component({
  components: {
    EditorDatePicker,
  },
})
export default class TableDatePicker extends Vue {
  name = 'dropdown';
  mode = "today";
  buttonName = "button.today"
  isOpen = false;
  shop: FindType<"shops"> = null;
  startDate = moment().startOf("day").toDate();
  endDate = moment().endOf("day").subtract(1, "second").toDate();
  previousStartDate = moment(new Date()).toDate();
  previousEndDate = moment(new Date()).toDate();

  setDate(mode) {
    this.mode = mode;
    let closeHour = "00:00";
    let closeMinute = "23:59";

    //latest date
    // const today = moment().startOf("day").toDate();
    const todayStart = moment().startOf("day").toDate();
    const todayEnd = moment(todayStart).add(1, "day").subtract(1, "second").toDate();

    const weekStart = moment(todayStart)
      .subtract(moment(todayStart).get("isoWeekday") - 1, "day")
      .toDate();
    const weekEnd = moment(weekStart).add(7, "day").subtract(1, "second").toDate();

    const monthStart = moment().startOf("month").toDate();
    const monthEnd = moment(monthStart).add(1, "month").subtract(1, "second").toDate();

    const yearStart = moment().startOf("year").toDate();
    const yearEnd = moment(yearStart).add(1, "year").subtract(1, "second").toDate();

    const yesterdayStart = moment(todayStart).subtract(1, "day").toDate();
    const yesterdayEnd = moment(yesterdayStart).add(1, "day").subtract(1, "second").toDate();

    const lastWeekStart = moment(weekStart).subtract(7, "day").toDate();
    const lastWeekEnd = moment(lastWeekStart).add(7, "day").subtract(1, "second").toDate();

    const lastMonthStart = moment(monthStart).subtract(1, "month").toDate();
    const lastMonthEnd = moment(lastMonthStart).add(1, "month").subtract(1, "second").toDate();
    const lastMonthToday = moment(todayEnd).subtract(1, "month").toDate();

    const lastYearStart = moment(yearStart).subtract(1, "year").toDate();
    const lastYearEnd = moment(lastYearStart).add(1, "year").subtract(1, "second").toDate();

    if (mode == "today") {
      this.startDate = todayStart;
      this.endDate = todayEnd;
      this.previousStartDate = yesterdayStart;
      this.previousEndDate = yesterdayEnd;
      this.buttonName = "button.today"
    } else if (mode == "week") {
      this.startDate = weekStart;
      this.endDate = weekEnd;
      this.previousStartDate = lastWeekStart;
      this.previousEndDate = lastWeekEnd;
      this.buttonName = "button.thisWeek"
    } else if (mode == "month") {
      this.startDate = monthStart;
      this.endDate = todayEnd;
      this.previousStartDate = lastMonthStart;
      this.previousEndDate = lastMonthToday;
      this.buttonName = "button.thisMonth"
    } else if (mode == "year") {
      this.startDate = yearStart;
      this.endDate = yearEnd;
      this.previousStartDate = lastYearStart;
      this.previousEndDate = lastYearEnd;
      this.buttonName = "button.thisYear"
    }
    this.$emit('updateDates',
      {
        startDate: this.startDate,
        endDate: this.endDate,
        previousStartDate: this.previousStartDate,
        previousEndDate: this.previousEndDate,
        mode: mode
      })
  }

  normalizedDay(date) {
    let openHour = "00:00";

    const time = openHour?.split?.(":");
    const hh = isNaN(+time?.[0]) ? 0 : +time?.[0];
    const mm = isNaN(+time?.[1]) ? 0 : +time?.[1];

    const now = moment();
    const day = moment().startOf("day").add(hh, "hours").add(mm, "minutes");
    const cur = moment(date).add(hh, "hours").add(mm, "minutes");

    if (day.isAfter(now)) {
      return cur.subtract(1, "day").toDate();
    }
    return cur.toDate();
  }
}

