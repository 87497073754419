import type {
  ImportContext,
  ImportField,
} from "../importCommon";
import type {
  SchemaFieldJson,
} from "@db/schema";
import type { ImportConfig } from "../importField";
import type { LangArrType } from "@feathers-client/i18n";

export interface EditorViewSetting {
  headers?: string[];
  sort?: string[];
  sortDesc?: boolean[];
  searchPin?: string[];
  [key: string]: any;
}

export interface EditorField {
  customType?: CustomType;
  component?: string;
  props?: Record<string, any>;
  propsFunc?: (scope: any) => any;
  name?: string | LangArrType;
  // path for root object in slot props (default item)
  rootPath?: string;
  // path in root object
  path?: string;
  nameField?: string;
  schema?: SchemaFieldJson;
  inner?: EditorField[];
  innerPrimitive?: boolean;
  default?: EditorField[];

  _inner?: EditorField[];
  _default?: EditorField[];

  cond?: (scope: any) => boolean;
  defaultValue?: any;
  type: string;
  optional?: boolean;
  sort?: string;
  gp?: string;
  group?: EditorGroupOptions;
  displayPath?: string;
  header?: DataTableHeader;
  colWidthRem?: number;

  customSearch?: boolean;

  getter?: (self: Vue, item: any) => any;
  setter?: (self: Vue, item: any, value: any) => void;
}

export interface SearchField {
  name: string | LangArrType;
  path: string;
  edit: EditorField;
  color: string;
  conds: string[];
  cond: string;
  value1: any;
  value2: any;
  header: DataTableHeader;
}

export interface SearchConfig {
  field: string | string[],
  mode?: 'regstart' | 'regstart' | 'regexp' | 'regstart-case' | 'regexp-case' | 'regstart-uppercase' | 'regexp-uppercase' | 'regstart-lowercase' | 'regexp-lowercase' | 'none'
  regexp?: string,
  regopt?: string,
  mongo?: any,
  mongoField?: string,
  translate?: boolean
  multiple?: boolean
}

export interface NormalizedRole {
  role: string;
  read?: boolean;
  write?: boolean;
}

export type RoleDef = string | NormalizedRole;

export interface ImportLinkField {
  from: string;
  to: string;
  params?: Partial<ImportConfig>;
}

export interface ImportOptions {
  horizontal?: boolean;
  importPreprocess?: string;
  links?: {
    path: string;
    mode: "single";
    fields?: ImportLinkField[];
  }[];
}

export interface GUIHeader {
  title: string;
  action: string;
  href: string;
  items?: GUIHeader[];
  gpKey?: string;
  gpIcon?: string;
  order: number;
}
[];

export interface DataTablePopObj {
  path: string;
  populate?: DataTablePop[];
}
export type DataTablePop = string | DataTablePopObj;
export interface DataTableHeader {
  computed?: boolean;

  // when sorting header path (ui) != data header path
  headerValue?: string | string[];
  linkValue?: string | string[];
  value?: string;
  id?: string;
  revertShow?: boolean;
  toggleable?: boolean;

  source?: string | ((item: any) => string);

  noLink?: boolean;
  linkSource?: string | ((item: any) => string);
  trailingSlash?: boolean;
  direct?: boolean;

  unique?: boolean;
  limit?: number;

  path?: string;
  paths?: {
    path: string;
    format?: string | ((self: Vue, value: any, item: any) => string);
  }[];

  objectOnly?: boolean;
  format?: string | ((self: Vue, value: any, item: any) => string);

  itemKey?: string;

  filter?: any;
  populate?: DataTablePop[];
  select?: string[];

  type?: string;
  slot?: string;

  text?: string;
  hideDelete?: boolean;

  multiple?: boolean;
  sortable?: boolean;
  sortField?: string;
  action?: boolean;
  enumList?: Record<string, string>;
  hideEmpty?: boolean;
  inner?: DataTableHeader[];
}

export interface EditorFieldOptions {
  sizes?: string[];
  gp?: string;
  type?: string;
  hidden?: boolean;
  search?: boolean;
  optional?: boolean;
  sort?: string;
  props?: Record<string, any>;
  linked?: EditorFieldOptions;
  group?: EditorGroupOptions;
  cond?: string;
  name?: string;
  format?: string;
  headerValue?: string;
  objectFormat?: string;
  headerUnique?: boolean;
  headerLimit?: number;
  hideEmpty?: boolean;

  sortField?: string

  nameFields?: string | string[];
  fileType?: string;
  stringId?: string;
}

export interface EditorGroupOptions {
  props?: any;
  name?: string;
  preview?: boolean;
  hasInnerGroup?: boolean;
}

export interface CustomType {
  component?: string | (() => Promise<any>);
  searchComponent?: string | (() => Promise<any>);
  importer?: (context: ImportContext, field: EditorField, parent?: ImportField) => ImportField;
  format?: string | ((self: Vue, value: any, item: any) => string);
  header?: Partial<DataTableHeader>;
  props?: Record<string, any>;
  fromDb?: (self: Vue, value: any, root: any) => any;
  toDb?: (self: Vue, value: any, root: any) => any;
  colWidthRem?: number;
}

export type AclHandler = (
  context: Vue,
  action: "create" | "patch" | "remove" | "clone",
  item?: any,
) => boolean;

export interface TranslateRule {
  [key: string]: string | TranslateRule
}

export type TranslateRules = {
  common: string[]
} & TranslateRule;

export default {};
