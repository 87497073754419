
import { Component, Prop, Vue, Watch, mixins, checkID } from "@feathers-client";
import Dialog from "domore-table/mixins/Dialog";
import _ from "lodash";
import VueI18n from "vue-i18n/types";
import EditorObjectPickerNew from "@schemaEditor/EditorObjectPickerNew.vue";
import EditorObjectPickerList from "@schemaEditor/EditorObjectPickerList.vue";

type productDisplayMode = {
  _id: string;
  name: VueI18n.TranslateResult;
  normalIcon: string;
  activeIcon: string;
  displayImage: boolean;
  fullScreenImage: boolean;
};

@Component({
  components: {
    EditorObjectPickerNew,
    EditorObjectPickerList,
  },
})
export default class DisplaySetting extends mixins(Dialog()) {
  fetch({ store }) {
    store.commit("SET_TITLE", { fullPage: true, tableMode: true, disableTextSelect: true, dark: true });
  }

  async saveItem() {
    this.modalResult(true);
  }

  async reconnectScreen() {
    await this.$pos.connectScreen(true);
  }

  //   sortModes = [
  //     {
  //       _id: "default",
  //       name: { $t: "productSort.default" },
  //     },
  //     {
  //       _id: "alphabetFirst",
  //       name: { $t: "productSort.alphabetFirst" },
  //     },
  //   ];

  //   newOrderTypes = [
  //     {
  //       _id: "followLast",
  //       name: { $t: "newOrderType.followLast" },
  //     },
  //     {
  //       _id: "dineInNoTable",
  //       name: { $t: "newOrderType.dineInNoTable" },
  //     },
  //     {
  //       _id: "takeAway",
  //       name: { $t: "newOrderType.takeAway" },
  //     },
  //   ];

  productDisplayMode: productDisplayMode[] = [
    {
      _id: "textOnly",
      name: this.$t("productDisplay.textOnly"),
      normalIcon: "$textOnlyNormal",
      activeIcon: "$textOnlyActive",
      displayImage: false,
      fullScreenImage: false,
    },
    {
      _id: "smallPic",
      name: this.$t("productDisplay.smallPic"),
      normalIcon: "$smallPicNormal",
      activeIcon: "$smallPicActive",
      displayImage: true,
      fullScreenImage: false,
    },
    {
      _id: "fullPic",
      name: this.$t("productDisplay.bgPic"),
      normalIcon: "$fullPicNormal",
      activeIcon: "$fullPicActive",
      displayImage: true,
      fullScreenImage: true,
    },
  ];

  selectingDisplayMode(item: productDisplayMode) {
    this.productDisplay = item._id;
    this.showProductImage = item.displayImage;
    this.fullScreenProductImage = item.fullScreenImage;
  }

  remoteFontDiv = 5;

  getScale(value) {
    return (value < 0 ? 100 - (Math.abs(value) / this.remoteFontDiv) * 80 : 100 + value * 10).toFixed(0);
  }

  get productDisplay() {
    return this.$pos.tempProductDisplay ?? this.$pos.cashier.productDisplay ?? "smallPic";
  }
  set productDisplay(v) {
    this.$pos.tempProductDisplay = v;
  }

  get showProductImage() {
    return this.$pos.tempShowProductImage ?? this.$pos.cashier.showProductImage ?? true;
  }
  set showProductImage(v) {
    this.$pos.tempShowProductImage = v;
  }

  get fullScreenProductImage() {
    return this.$pos.tempFullScreenProductImage ?? this.$pos.cashier.fullScreenProductImage ?? false;
  }
  set fullScreenProductImage(v) {
    this.$pos.tempFullScreenProductImage = v;
  }

  get mscale() {
    return this.$pos.tempFontScale ?? this.$pos.cashier.fontScale ?? 0;
  }
  set mscale(v) {
    this.$pos.tempFontScale = v;
  }

  get remoteScale() {
    return this.$pos.tempRemoteFontScale ?? this.$pos.cashier.remoteFontScale ?? 0;
  }
  set remoteScale(v) {
    this.$pos.tempRemoteFontScale = v;
  }

  loading = false;

  async apply() {
    this.loading = true;
    try {
      this.$pos.updateCurrentCashier({
        fontScale: this.mscale,
        remoteFontScale: this.remoteScale,
        productDisplay: this.productDisplay,
        showProductImage: this.showProductImage,
        fullScreenProductImage: this.fullScreenProductImage,
      });
      this.modalResult(true);
    } catch (e) {
      this.$store.commit("SET_ERROR", e.mssage);
    } finally {
      this.loading = false;
    }
  }

  //   get locales() {
  //     return Object.entries(((this as any).$i18n || (this as any).i18n)?.locales || {}).map(([k, v]) => ({
  //       _id: (v as any).code || k,
  //       name: (v as any).name,
  //     }));
  //   }

  //   get remoteLocale() {
  //     return this.$shop.localOptions.secondScreenPrimaryLocale;
  //   }
  //   set remoteLocale(v) {
  //     this.$shop.localOptions.secondScreenPrimaryLocale = v;
  //     this.$shop.syncSecondScreenLocale();
  //   }

  //   get remoteSecondaryLocale() {
  //     return this.$shop.localOptions.secondScreenSecondaryLocale;
  //   }
  //   set remoteSecondaryLocale(v) {
  //     this.$shop.localOptions.secondScreenSecondaryLocale = v;
  //     this.$shop.syncSecondScreenLocale();
  //   }
}
