
import Dialog from "@feathers-client/mixins/Dialog";
import { Component, mixins, Prop, FindType } from "@feathers-client";
import MenuListNum from "@feathers-client/components/MenuListNum.vue";
import { FindPopRawType } from "@feathers-client";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import EditorObjectPickerList from "@schemaEditor/EditorObjectPickerList.vue";

@Component({
  components: {
    MenuListNum,
    EditorTextField,
    EditorObjectPickerList,
  }
})
export default class CustomProduct extends mixins(Dialog) {

  productName: string = null

  Inputamount: number = null

  quantity: number = null

  get returnObject() {
    return [{
      price: this.Inputamount * 10000,
      custProductName: this.productName,
      quantity: this.quantity,
      sku: null,
    }]
  }

  returnData() {
    if (!this.Inputamount || !this.productName || !this.quantity) {
      this.$store.commit("SET_ERROR", this.$t('subsequentPaymentOrders.erp.pleaseInputAllFields'))
      return;
    }
    this.modalResult(this.returnObject)
  }

  async beforeMount() {
    // const res = await this.$feathers.service('users').find()

    // console.log('user', res)
  }


}
