
import moment from "moment";
import _ from "lodash";
import { Component, Vue, Prop, FindType, Watch, checkID } from "@feathers-client";
import MenuListInput from "@feathers-client/components/MenuListInput.vue";

@Component({
  components: {
    MenuListInput,
  },
})
export default class EditorDateObject extends Vue {
  @Prop()
  value: {
    year: number;
    month: number;
    day: number;
  };

  get year() {
    return this.value?.year > 0 ? this.value.year : "";
  }
  set year(val) {
    val = +val;
    this.value.year = val > 9999 ? 9999 : val < 0 ? 0 : val;
    this.$emit("input", this.value);
  }

  get month() {
    return this.value?.month > 0 ? this.value.month : "";
  }
  set month(val) {
    val = +val;
    this.value.month = val > 12 ? 12 : val < 0 ? 0 : val;
    this.$emit("input", this.value);
  }

  get day() {
    return this.value?.day > 0 ? this.value.day : "";
  }
  set day(val) {
    val = +val;
    this.value.day = val > 31 ? 31 : val < 0 ? 0 : val;
    this.$emit("input", this.value);
  }

  @Prop()
  label: string;

  @Prop({ default: "text-secondary" })
  labelClass: string;

  @Prop({ type: Boolean, default: false })
  clearable: boolean;

  @Prop({ default: "border-orange400" })
  activeClass: string;

  @Prop(Boolean)
  readonly: boolean;

  focused = false;
}
