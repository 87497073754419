var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex flex-col gap-y-2 overflow-hidden flex-shrink-0"},[(_vm.label)?_c('div',{staticClass:"text-sm text-secondary d-flex items-center gap-x-1.5"},[_c('span',[_vm._v(_vm._s(_vm.label))]),_vm._t("nameAppend")],2):_vm._e(),(_vm.editor)?_c('editor-quill',{staticClass:"input-item border border-solid border-grey200 rounded-lg px-3 py-2.5 text-sm outline-0 focus:border-orange400",class:_vm.containerClass,attrs:{"readonly":_vm.readonly},model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}}):(_vm.number)?_c('menu-list-num',{staticClass:"flex-grow input-item py-2.5 text-sm outline-0",attrs:{"readonly":_vm.readonly,"placeholder":_vm.placeholder,"containerClass":_vm.containerClass,"integer":_vm.integer,"minValue":_vm.minValue,"maxValue":_vm.maxValue,"stepperSpace":_vm.stepperSpace,"prefix":_vm.prefix,"suffix":_vm.suffix,"placeholderValue":_vm.placeholderValue},on:{"focus":_vm.focus,"blur":_vm.blur},model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}}):_c('div',{staticClass:"rounded-lg border border-solid d-flex px-3 gap-x-3 items-center",class:[
      _vm.borderStyle,
      _vm.containerClass,
      { [_vm.activeBorderColor]: _vm.active, fill: _vm.fill },
      _vm.multiLine ? 'editor-text-multi-line' : 'editor-text-single-line'
    ]},[_vm._t("prepend-inner"),(_vm.multiLine)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],class:[
        'flex-grow input-item py-2.5 text-sm outline-0 basis-0 overflow-hidden text-ellipsis',
        _vm.inputTextStyle,
      ],attrs:{"readonly":_vm.readonly,"placeholder":_vm.placeholder,"rows":_vm.rows},domProps:{"value":(_vm.inputValue)},on:{"focus":_vm.focus,"blur":_vm.blur,"input":function($event){if($event.target.composing)return;_vm.inputValue=$event.target.value}}}):_c('menu-list-input',{class:[
        'flex-grow input-item py-2.5 text-sm outline-0 basis-0 overflow-hidden text-ellipsis',
        _vm.inputTextStyle,
      ],attrs:{"readonly":_vm.readonly,"placeholder":_vm.placeholder,"enterkeyhint":_vm.enterkeyhint,"pattern":_vm.pattern,"type":_vm.type,"autocomplete":_vm.autocomplete,"name":_vm.name,"maxlength":_vm.maxlength},on:{"focus":_vm.focus,"blur":_vm.blur,"submit":function($event){return _vm.$emit('submit')}},model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}}),_vm._t("append-inner")],2),_vm._t("append")],2)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }