
import qs from 'querystring'

export default {
    async asyncData ({ $config, route, store, redirect, app }) {
        if(!store.getters['userId']) {
            redirect(`/login?${qs.stringify({
                from: route.fullPath
            })}`)
            return 'shortcut';
        }
        if(store.getters['resetRequired']) {
            redirect('/login/reset/reset')
            return 'shortcut';
        }
        if($config.features.verify !== false && !store.getters['isVerified']) {
            redirect('/register/verify')
            return 'shortcut';
        }
    },
}

export const AuthOnly = {
    async mounted() {
    }
}

