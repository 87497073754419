
import _ from "lodash";
import { Component, Vue, mixins, Prop, FindType, FindPopRawType, Watch, checkID, getID } from "@feathers-client";
import Dialog from "@feathers-client/mixins/Dialog";
import ShipmentScheduleOrderDialogItem from "./ShipmentScheduleOrderDialogItem.vue";
type ShipmentType = FindType<"shop/shipping/shipments">;

@Component({
  components: {
    ShipmentScheduleOrderDialogItem,
  },
})
export default class ShipmentScheduleOrderCancelDialog extends mixins(Dialog) {
  @Prop()
  item: ShipmentType;

  @Prop()
  value: any;

  $root: any;
  orderId: any;

  // get scheduleFilters() {
  //   return [
  //     { _id: "pending", name: { $t: "shipping.schedule.status.pending" } },
  //     { _id: "confirmed", name: { $t: "shipping.schedule.status.confirmed" } },
  //     { _id: "scheduled", name: { $t: "shipping.schedule.status.scheduled" } },
  //     { _id: "notDelivered", name: { $t: "shipping.schedule.status.notDelivered" } },
  //     { _id: "expired", name: { $t: "shipping.schedule.status.expired" } },
  //   ];
  // }

  // get shipmentStatus() {
  //   return this.value;
  // }

  // set shipmentStatus(v) {
  //   // this.$emit('input', v);

  //   this.modalResult(v);
  // }

  getOrders(item: ShipmentType) {
    return _.uniq(item.products.map(it => it.order));
  }

  getUniqueProducts(item: ShipmentType) {
    return _.uniq(item.products.map(it => ({ _id: it._id, sku: it.sku })));
  }

  async beforeMount() {
    await this.updateSelectedShipments();
    await this.updateSelectedShipmentsProducts();
    await this.updateSelectedShipmentsOrderIds();
    await this.updateSelectedShipmentsUsers();
  }

  selectedShipments: ShipmentType[] = [];
  selectedShipmentsOrderIdMap: Map<any, any> = new Map();
  shipmentOrderMap: Map<any, any> = new Map();
  shipmentProductMap: Map<any, { _id: any; sku: any }[]> = new Map(); //  { [key: string]: {_id:string,sku:string}[] } = {};
  productSkuMap: Map<any, FindType<"shop/product/skus">> = new Map();
  shipmentUserMap: Map<any, FindType<"shop/users">> = new Map();

  isConfirmed = false;

  // newShipmentOrder: any[] = []; //for frontend display only

  // newShipment: Partial<ShipmentType> = {
  //   products: [],
  // };

  selectedShipmentSelectedAddress: {
    address: string;
    name: string;
    phone: string;
    remarks: string;
  } = {
    address: "",
    name: "",
    phone: "",
    remarks: "",
  };


  getItemProps(product, shipment) {
    let it = this.productSkuMap.get(product.sku);
    return (
      it && {
        productName: this.$td(it.fullName),
        image: it.images,
        userName: (it => it && (it.fullName || ""))(this.shipmentUserMap.get(`${this.selectedShipments[0].user}`)),
        remarks: "no remarks",
        quantity: shipment.products.find(j => j._id === product._id).quantity,
        amount: shipment.products.find(j => j._id === product._id).totalInt,
      }
    );
  }

  async updateSelectedShipments() {
    this.selectedShipments = await this.$feathers.service("shop/shipping/shipments").find({
      query: {
        _id: { $in: this.value },
        $paginate: false,
      },
      paginate: false,
    });
    this.shipmentOrderMap = new Map(this.selectedShipments.map(it => [it._id, this.getOrders(it)]));
    this.shipmentProductMap = new Map(this.selectedShipments.map(it => [it._id, this.getUniqueProducts(it)]));
    this.updateSelectedShipmentSelectedAddress(0);
  }
  updateSelectedShipmentSelectedAddress(idx) {
    this.selectedShipmentSelectedAddress.address = `${this.selectedShipments[idx].address?.address} ${this.selectedShipments[idx].address?.address2}`;
    this.selectedShipmentSelectedAddress.name = `${this.selectedShipments[idx].address?.name?.firstName} ${this.selectedShipments[idx].address?.name?.lastName}`;
    this.selectedShipmentSelectedAddress.phone = this.selectedShipments[idx].address?.phone;
    this.selectedShipmentSelectedAddress.remarks = this.selectedShipments[idx].remarks;
  }

  async updateSelectedShipmentsProducts() {
    let result = await this.$feathers.service("shop/product/skus").find({
      query: {
        _id: { $in: [...this.shipmentProductMap.values()].flat().map(it => it.sku) },
        $paginate: false,
      },
      paginate: false,
    });
    this.productSkuMap = new Map(result.map(it => [it._id, it]));
  }

  async updateSelectedShipmentsOrderIds() {
    let result = (
      await this.$feathers.service("shop/orders").find({
        query: {
          _id: { $in: [...this.shipmentOrderMap.values()].flat() },
          $select: ["_id", "orderId"],
          $paginate: false,
        },
        paginate: false,
      })
    ).map((it): [any, any] => [it._id, it.orderId]);
    this.selectedShipmentsOrderIdMap = new Map(result);
  }

  async updateSelectedShipmentsUsers() {
    let result = (
      await this.$feathers.service("shop/users").find({
        query: {
          _id: { $in: this.selectedShipments.map(it => it.user) },
          $limit: 1000,
          //$paginate: false,
        },
        //paginate: false,
      })
    ).data;
    this.shipmentUserMap = new Map(result.map(it => [it._id, it]));
  }

  async submit() {
    try {
      const resp = await this.$feathers.service("shop/shipping/shipments/cancel").create({
        id: this.selectedShipments.map(it => it._id),
      });
      this.modalResult(true);
    } catch (e) {
      this.$store.commit("SET_ERROR", e.message);
    }
  }

  close() {
    this.modalResult(null);
  }
}
