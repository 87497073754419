
import { Component, Vue, Prop, Ref } from "@feathers-client";
import { SiteEditorBanner } from "../index.vue";
import { StringLiteral } from "typescript";

@Component
export default class MobileRibbonCard extends Vue {
  @Prop()
  item: SiteEditorBanner;

  getLink(item: SiteEditorBanner) {
    if (!item.url) return "div";
    if (item.url.startsWith("/")) {
      return "nuxt-link";
    } else {
      return "a";
    }
  }

  getLinkProp(item: SiteEditorBanner) {
    if (!item.url) return { class: "contents" };
    if (item.url.startsWith("/")) {
      return { to: item.url };
    } else {
      return { href: item.url, target: "_blank", rel: "noopener noreferrer" };
    }
  }
}
