
import { Component, Prop, Vue, Watch, mixins, VModel, Ref } from "nuxt-property-decorator";
import { CreateElement, VNode } from "vue";
// @ts-ignore
import Popup from "../components-internal/popover.vue";
// @ts-ignore
import Popup2 from "../components-internal/popover2.vue";
import ResizeSensor from "@feathers-client/components/ResizeSensor.vue";
import { lowSpec } from "../util";

@Component({
  components: {
    ResizeSensor,
  },
})
export default class TeleportMenu extends Vue {
  menu: TeleportMenuContainer;

  @Prop({ type: Boolean })
  value: boolean;

  get inputValue() {
    return this.value && (!this.useBounds || !!this.bounds);
  }

  set inputValue(v: boolean) {
    this.$emit("input", v);
  }

  @Prop()
  contentClass: any;

  @Prop({ default: "center" })
  thumb: "start" | "center" | "end" | false;

  @Prop({ default: "bottom" })
  placement: "left" | "right" | "top" | "bottom";

  @Prop(Boolean)
  cover: boolean;

  @Prop({ default: "start" })
  offset: "start" | "center" | "end";

  @Prop({ default: "fill" })
  size: "fill" | "auto" | "max";

  // size for perpendicular axis
  @Prop()
  minSize: string;

  // size for calculating reverse bounds
  @Prop()
  preferFill: string;

  @Prop(Boolean)
  wrap: boolean;

  @Prop({ type: Boolean, default: true })
  overlay: boolean;

  @Prop(Boolean)
  useBounds: boolean;

  @Prop(Boolean)
  dialog: boolean;

  @Prop()
  bounds: { x: number; y: number; width: number; height: number };

  @Prop()
  maxWidth: any;

  @Prop(Boolean)
  persistent: boolean;

  @Prop()
  overlayClass: string;

  @Prop({ default: lowSpec ? "" : "animate-popexpand" })
  animateClass: string;

  mounted() {
    if (this.inputValue) {
      this.showMenu();
    }
  }

  @Watch("inputValue")
  onInputValue(v, ov) {
    if (v === ov) return;
    if (v) {
      this.showMenu();
    } else {
      this.hideMenu();
    }
  }

  showMenu() {
    this.beginObserve();
    if (this.menu) {
      this.menu.show();
    } else {
      const app = document.querySelector("#app");
      let zIndex = +(app.lastElementChild as HTMLElement).style.zIndex;

      let cur = this.$el as HTMLElement;
      while(cur && cur !== app) {
        if(cur.style?.zIndex) {
          zIndex = Math.max(zIndex, +cur.style.zIndex);
        }
        cur = cur.parentElement;
      }

      if (isNaN(zIndex) || zIndex < 200) zIndex = 200;

      this.menu = new TeleportMenuContainer({
        parent: this,
        propsData: {
          zIndex,
        },
      });
      this.updateMenu();
      this.menu.$on("close", () => (this.inputValue = false));
      const elem = document.createElement("div");
      app.appendChild(elem);
      this.menu.$mount(elem);
    }
  }

  @Watch("placement")
  @Watch("size")
  @Watch("bounds")
  updateMenu() {
    if (this.menu) {
      if (this.dialog) return;
      if (this.useBounds) {
        if (this.bounds) {
          this.menu.bounds = new DOMRect(this.bounds.x, this.bounds.y, this.bounds.width, this.bounds.height);
        }
      } else {
        const bounds = this.$el.getBoundingClientRect();
        if (this.dialog || this.overlay) {
          this.menu.bounds = new DOMRect(bounds.x, bounds.y, bounds.width, bounds.height);
        } else {
          this.menu.bounds = new DOMRect(
            bounds.x + document.scrollingElement.scrollLeft,
            bounds.y + document.scrollingElement.scrollTop,
            bounds.width,
            bounds.height,
          );
        }
      }
    }
  }

  hideMenu() {
    this.endObserve();
    if (this.menu) {
      return this.menu.hide().then(this.removeMenu);
    }
  }

  removeMenu() {
    if (this.menu) {
      if (this.menu.$el) {
        this.menu.$el.remove();
      }
      this.menu.$destroy();
      this.menu = null;
    }
  }

  beforeDestroy() {
    if (this.inputValue) {
      this.hideMenu();
    }
  }

  legacySize = false;
  resizeObserver: ResizeObserver;

  beginObserve() {
    if (!this.$el || !(this.$el instanceof Element)) return;
    if (typeof ResizeObserver === "undefined") {
      this.legacySize = true;
    } else if (!this.resizeObserver) {
      this.resizeObserver = new ResizeObserver(this.updateMenu);
      this.resizeObserver.observe(this.$el);
    }
    document.addEventListener("scroll", this.updateMenu, {
      passive: true,
    });
  }

  endObserve() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }
    document.removeEventListener("scroll", this.updateMenu);
  }
}

const reversedPlacement = {
  left: "right",
  right: "left",
  top: "bottom",
  bottom: "top",
} as const;

@Component({
  directives: {
    "menu-wrap": {
      inserted(el, binding, vnode) {
        const menu = vnode.context as TeleportMenuContainer;
        menu.updatePosition(el);
      },
      update(el, binding, vnode) {
        const menu = vnode.context as TeleportMenuContainer;
        menu.updatePosition(el);
      },
    },
  },
})
class TeleportMenuContainer extends Vue {
  bounds: DOMRect = null;

  @Prop()
  zIndex: number;

  @Ref()
  animate: HTMLElement;

  // @ts-ignore
  $parent: TeleportMenu;
  get wrap() {
    return this.$parent.wrap;
  }
  get thumb() {
    return this.$parent.thumb;
  }
  get placement() {
    return this.$parent.placement;
  }
  get size() {
    return this.$parent.size;
  }
  get offset() {
    return this.$parent.offset;
  }
  get overlay() {
    return this.$parent.overlay;
  }
  get dialog() {
    return this.$parent.dialog;
  }
  get maxWidth() {
    return this.$parent.maxWidth;
  }
  get persistent() {
    return this.$parent.persistent;
  }
  get animateClass() {
    return this.$parent.animateClass;
  }
  get minSize() {
    return this.$parent.minSize;
  }
  get preferFill() {
    return this.$parent.preferFill;
  }
  get cover() {
    return this.$parent.cover;
  }

  reversed = false;
  capped = false;

  get parent() {
    return this.$parent as TeleportMenu;
  }

  hiding = false;
  lastShow = Date.now();

  updateNum: number;

  get style() {
    let style: {
      width?: number | string;
      height?: number | string;
      top?: number;
      bottom?: number;
      left?: number;
      right?: number;
      "min-height"?: string;
      "min-width"?: string;
    } = {};
    let transform: string;
    let innerClass: string;
    let popupClass: any = {};
    let contentStyle: string;

    let placement = this.reversed || this.capped ? reversedPlacement[this.placement] : this.placement;
    const isX = placement === "left" || placement === "right";
    const isY = !isX;

    if (this.size === "fill") {
      if (isX) {
        style.height = this.bounds.height;
      } else {
        style.width = this.bounds.width;
      }
    } else if (this.size === "max") {
      if (isX) {
        style.height = this.minSize ? `max(${this.minSize},${this.bounds.height}px)` : `${this.bounds.height}px`;
      } else {
        style.width = this.minSize ? `max(${this.minSize},${this.bounds.width}px)` : `${this.bounds.width}px`;
      }
    }

    if (isX) {
      if (this.offset === "center") {
        style.top = this.bounds.top + this.bounds.height / 2;
        transform = "translate(0, -50%)";
      } else if (this.offset === "start") {
        style.top = this.bounds.top;
      } else {
        style.bottom = this.bounds.bottom;
      }
    } else {
      if (this.offset === "center") {
        style.left = this.bounds.left + this.bounds.width / 2;
        transform = "translate(-50%, 0)";
      } else if (this.offset === "start") {
        style.left = this.bounds.left;
      } else {
        style.right = this.bounds.right;
      }
    }

    switch (this.thumb) {
      case "start":
        if (isY) popupClass["left-3"] = true;
        else popupClass["top-3"] = true;
        break;
      case "center":
        if (isY) {
          popupClass["left-1/2"] = true;
          popupClass["-translate-x-1/2"] = true;
        } else {
          popupClass["top-1/2"] = true;
          popupClass["-translate-y-1/2"] = true;
        }
        break;
      case "end":
        if (isY) popupClass["right-3"] = true;
        else popupClass["bottom-3"] = true;
        break;
    }

    if (isX) {
      popupClass["w-4 h-12.75"] = true;
    } else {
      popupClass["w-12.5 h-3.5"] = true;
    }

    switch (placement) {
      case "top": {
        style.bottom = this.capped ? 0 : Math.max(0, window.innerHeight - (this.cover ? this.bounds.bottom : this.bounds.top));
        if (this.thumb) {
          innerClass = "pb-3.5";
        }
        popupClass["bottom-0 rotate-180 -translate-y-[1px]"] = true;
        break;
      }
      case "bottom": {
        style.top = this.capped ? 0 : Math.max(0, this.bounds.top + (this.cover ? 0 : this.bounds.height));
        if (this.thumb) {
          innerClass = "pt-3.5";
        }
        popupClass["top-0 translate-y-[1px]"] = true;
        break;
      }
      case "right": {
        style.left = this.capped ? 0 : Math.max(0, this.bounds.left + (this.cover ? 0 : this.bounds.width));
        if (this.thumb) {
          innerClass = "pl-4";
        }
        popupClass["left-0 translate-x-[0.25px]"] = true;
        break;
      }
      case "left": {
        style.right = this.capped ? 0 : Math.max(0, window.innerWidth - (this.cover ? this.bounds.right : this.bounds.left));
        if (this.thumb) {
          innerClass = "pr-4";
        }
        popupClass["right-0 rotate-180 -translate-x-[0.25px]"] = true;
        break;
      }
    }

    let originX: string;
    let originY: string;

    switch (placement) {
      case "right": {
        originX = "left";
        originY = this.offset === "start" ? "top" : this.offset === "end" ? "bottom" : "center";
        break;
      }
      case "bottom": {
        originY = "top";
        originX = this.offset === "start" ? "left" : this.offset === "end" ? "bottom" : "center";
        break;
      }
      case "left": {
        originX = "right";
        originY = this.offset === "start" ? "top" : this.offset === "end" ? "bottom" : "center";
        break;
      }
      case "top": {
        originY = "bottom";
        originX = this.offset === "start" ? "left" : this.offset === "end" ? "bottom" : "center";
        break;
      }
    }

    contentStyle = `transform-origin: ${originX} ${originY}`;

    return {
      main: style,
      inner: {
        transform,
      },
      innerClass,
      popupClass,
      contentStyle,
      isX,
    };
  }

  render(e: CreateElement) {
    if (this.updateNum) {
      ++this.updateNum;
    } else {
      this.updateNum = 1;
      setTimeout(() => {
        this.updateNum = 0;
      }, 10);
    }

    let inner: VNode;

    if (this.dialog) {
      inner = e(
        "div",
        {
          staticClass: "absolute inset-0 flex justify-center place-items-center",
        },
        [
          e(
            "div",
            {
              staticClass: this.animateClass + " flex-grow",
              class: this.parent.contentClass,
              style: {
                maxWidth: this.maxWidth,
              },
              ref: "animate",
              on: {
                touchstart: this.touchstart2,
                mousedown: this.touchstart2,
              },
            },
            [this.parent.$scopedSlots.default({})],
          ),
        ],
      );
    } else {
      const s = this.style;
      const transform = s.inner.transform;
      const styleStr = Object.entries(s.main)
        .map(it => `${it[0]}: ${it[1]}${typeof it[1] === "string" && isNaN(+it[1]) ? "" : "px"}`)
        .join(";");

      inner = e(
        "div",
        {
          attrs: {
            style: `${styleStr}; transform: ${transform}; ${this.overlay ? "" : `z-index: ${this.zIndex};`}`,
          },
          directives: [
            {
              name: "menu-wrap",
              value: this.style,
            },
          ],
          staticClass: "absolute",
          class: this.parent.contentClass,
        },
        [
          e(
            "div",
            {
              staticClass: this.animateClass,
              style: s.contentStyle,
              class: s.innerClass,
              ref: "animate",
              on: {
                touchstart: this.touchstart2,
                mousedown: this.touchstart2,
              },
            },
            [
              this.parent.$scopedSlots.default({}),
              ...(this.thumb !== false
                ? [
                    e(s.isX ? Popup2 : Popup, {
                      attrs: {},
                      staticClass: "pos-popup-thumb transform mx-auto absolute",
                      class: s.popupClass,
                    }),
                  ]
                : []),
              e(ResizeSensor, {
                on: {
                  resized: this.updateStyle,
                },
                props: {
                  debounce: 0,
                },
              }),
            ],
          ),
        ],
      );
    }

    if (!this.overlay) {
      return inner;
    }

    return e(
      "div",
      {
        staticClass: "fixed inset-0 overlay-wheel",
        class: this.$parent.overlayClass,
        style: `z-index: ${this.zIndex}`,
        on: {
          touchstart: this.touchstart,
          mousedown: this.touchstart,
          // 'contextmenu': (e) => {
          //     e.preventDefault();
          //     e.stopPropagation();
          //     e.cancelBubble = true;
          //     return false;
          // }
        },
      },
      [inner],
    );
  }

  updateStyle() {
    if (this.updateNum) {
      return;
    }
    this.reversed = false;
    this.capped = false;
    (this as any)._computedWatchers["style"].run();
    this.$forceUpdate();
  }

  updatePosition(el: HTMLElement) {
    if (this.updateNum > 3) {
      console.warn("Layout loop detected");
      return;
    }
    const rect = el.getBoundingClientRect();
    const top = rect.top;
    const left = rect.left;

    let height = el.clientHeight;
    let width = el.clientWidth;

    if(this.preferFill) {
      let fillWidth = 0;
      for(let part of this.preferFill.split(",")) {
        if(part.endsWith("rem")) {
          fillWidth = Math.max(fillWidth, parseFloat(part) * parseFloat(getComputedStyle(document.documentElement).fontSize));
        } else if(part.endsWith("vh")) {
          fillWidth = Math.max(fillWidth, parseFloat(part) * window.innerHeight / 100);
        } else if(part.endsWith("vw")) {
          fillWidth = Math.max(fillWidth, parseFloat(part) * window.innerWidth / 100);
        } else {
          fillWidth = Math.max(fillWidth, parseFloat(part));
        }
      }
      if(!isNaN(fillWidth)) {
        if(this.placement === "left" || this.placement === "right") {
          width = Math.max(width, fillWidth);
        } else {
          height = Math.max(height, fillWidth);
        }
      }
    }

    const bottom = top + height;
    const right = left + width;

    const child = el.firstElementChild.firstElementChild as HTMLElement;
    const thumbSize = this.thumb ? 20 : 0;

    if (this.wrap) {
      let newReversed: boolean;
      let newCapped: boolean;
      switch (this.placement) {
        case "left": {
          newReversed = left - thumbSize < 0;
          if(newReversed && right + thumbSize + width > window.innerWidth) {
            newReversed = false;
            newCapped = true;
          }
          break;
        }
        case "right": {
          newReversed = right + thumbSize > window.innerWidth;
          if(newReversed && left - width - thumbSize < 0) {
            newReversed = false;
            newCapped = true;
          }
          break;
        }
        case "top": {
          newReversed = top - thumbSize < 0;
          if(newReversed && bottom + thumbSize + height > window.innerHeight) {
            newReversed = false;
            newCapped = true;
          }
          break;
        }
        case "bottom": {
          newReversed = bottom + thumbSize > window.innerHeight;
          if(newReversed && top - height - thumbSize < 0) {
            newReversed = false;
            newCapped = true;
          }
          break;
        }
      }
      if (newReversed && !this.reversed) {
        this.reversed = newReversed;
        this.capped = false;
      }
      if (newCapped && !this.capped) {
        this.capped = true;
      }
    } else if (this.reversed) {
      this.reversed = false;
      this.capped = false;
    }

    switch (this.placement) {
      case "left":
      case "right": {
        if (top - 20 < 0) {
          child.style.transform = ` translate(0, ${-(top - 20)}px)`;
        } else if (bottom + 20 > window.innerHeight) {
          child.style.transform = ` translate(0, ${window.innerHeight - (bottom + 20)}px)`;
        } else {
          child.style.transform = null;
        }
        break;
      }
      case "top":
      case "bottom": {
        if (left - 20 < 0) {
          child.style.transform = ` translate(${-(left - 20)}px, 0)`;
        } else if (right + 20 > window.innerWidth) {
          child.style.transform = ` translate(${window.innerWidth - (right + 20)}px, 0)`;
        } else {
          child.style.transform = null;
        }
        break;
      }
    }
  }

  async hide() {
    this.hiding = true;
    if (this.animate && this.animateClass) {
      const waitLast = 500 - (Date.now() - this.lastShow);
      if (waitLast > 0) {
        await new Promise(resolve => setTimeout(resolve, waitLast));
      }
      if (!this.animate) return;
      this.animate.classList.remove(this.animateClass);
      await new Promise(resolve => setTimeout(resolve, 15));
      if (this.animate) {
        this.animate.classList.add(this.animateClass + "-leave");
        await Promise.race([
          new Promise<void>(resolve => {
            this.animate.addEventListener(
              "animationend",
              () => {
                if (this.hiding) {
                  this.reversed = false;
                  this.capped = false;
                  resolve();
                }
              },
              { once: true },
            );
          }),
          new Promise<void>(resolve =>
            setTimeout(() => {
              if (this.hiding) {
                resolve();
              }
            }, 300),
          ),
        ]);
      }
      this.reversed = false;
      this.capped = false;
    } else {
      this.reversed = false;
      this.capped = false;
    }
  }

  show() {
    this.hiding = false;
    if (this.animate && this.animateClass) {
      this.lastShow = Date.now();
      this.animate.classList.remove(this.animateClass + "-leave");
      this.animate.classList.add(this.animateClass);
    }
  }

  touchstart(e) {
    e.preventDefault();
    e.stopPropagation();
    if (this.persistent) return;
    this.$emit("close");
  }

  touchstart2(e) {
    e.stopPropagation();
  }
}
