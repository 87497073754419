
import { ShopType } from "@common/common";
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import { checkID } from '@feathers-client'

interface ItemAcl {
    shop: string,
    write?: boolean,
    owner?: boolean
}

interface ItemWithAcl {
    _id: string,
    shop: string,
    accessShop: ItemAcl[]
}

@Component
export default class AclEditor extends Vue {
    @Prop()
    item : ItemWithAcl

    get hasPermission() {
        return this.item.accessShop?.find?.(it => it.shop === `${this.$shop._id}` && it.owner) || `${this.$shop._id}` === this.item.shop
    }

    get only1Onwer() {
        return this.item.accessShop?.filter?.(it => it.owner).length === 1;
    }

    canDelete(item : ItemAcl) {
        return !item.owner;
    }

    filterShop(acl : ItemAcl) {
        return (item : ShopType) => {
            return checkID(acl.shop, item._id) || !this.item.accessShop?.find?.(it => checkID(it.shop, item._id));
        }
    }
}


