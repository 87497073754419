import { render, staticRenderFns } from "./LoginMenu.vue?vue&type=template&id=3869fd9d&scoped=true"
import script from "./LoginMenu.vue?vue&type=script&lang=ts"
export * from "./LoginMenu.vue?vue&type=script&lang=ts"
import style0 from "./LoginMenu.vue?vue&type=style&index=0&id=3869fd9d&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3869fd9d",
  null
  
)

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
installComponents(component, {VDialog,VIcon,VList})


/* hot reload */
if (module.hot) {
  var api = require("/home/jason/p/boxs/server/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3869fd9d')) {
      api.createRecord('3869fd9d', component.options)
    } else {
      api.reload('3869fd9d', component.options)
    }
    module.hot.accept("./LoginMenu.vue?vue&type=template&id=3869fd9d&scoped=true", function () {
      api.rerender('3869fd9d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/LoginMenu.vue"
export default component.exports