

import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";

@Component
export default class MenuList extends Vue {
    @Prop(Boolean)
    wrap: boolean
}

