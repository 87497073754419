


import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import _ from 'lodash'

@Component
export default class ComputeProvider extends Vue {
    @Prop({})
    compute : ((item : any, self? : ComputeProvider) => any) | {
        [key : string] : (((item : any) => any) | {
            get(item : any) : any
            set(item : any, v : any)
        })
    };

    getComputed (item : any) {
        if (this.compute) {
            if (this.compute instanceof Function) {
                return this.compute(item, this);
            } else {
                const obj = {};
                Object.defineProperties(obj, _.mapValues(this.compute, h => ({
                    get () {
                        return (h instanceof Function) ? h(item) : h.get(item);
                    },
                    set (v) {
                        if((h instanceof Function)) {
                        } else {
                            h.set(item, v);
                        }
                    }
                })));
                return obj;
            }
        }
        return {};
    }
}

