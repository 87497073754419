import { DropImageFetchError } from "./errors.js";
// @ts-ignore
import { eventOn } from "callforth";

const adaptOldFormat = detectedCodes => {
  if (detectedCodes.length > 0) {
    const [ firstCode ] = detectedCodes;

    const [
      topLeftCorner,
      topRightCorner,
      bottomRightCorner,
      bottomLeftCorner
    ] = firstCode.cornerPoints

    return {
      content: firstCode.rawValue,
      location: {
        topLeftCorner,
        topRightCorner,
        bottomRightCorner,
        bottomLeftCorner,

        // not supported by native API:
        topLeftFinderPattern: {},
        topRightFinderPattern: {},
        bottomLeftFinderPattern: {}
      },
      imageData: null
    }
  } else {
    return {
      content: null,
      location: null,
      imageData: null
    }
  }
}

/**
 * Continuously extracts frames from camera stream and tries to read
 * potentially pictured QR codes.
 */
export const keepScanning = async (videoElement, options) => {
  const { detectHandler, locateHandler, minDelay, formats } = options;

  const barcodeDetector = new (window as any).BarcodeDetector({ formats: formats || ["qr_code"] });

  let lastContentTime = null;
  let contentBefore = null
  let lastLocationTime = null;
  let lastScanned = performance.now()
  let emitContetTimeout = null;
  let lastEmitTime = null;

  while(videoElement.readyState > 1) {
      const timeNow = performance.now();
    if (timeNow - lastScanned >= minDelay && (!lastEmitTime || timeNow - lastEmitTime > 1000)) {
        const detectedCodes = await barcodeDetector.detect(videoElement);
        const { content, location, imageData } = adaptOldFormat(detectedCodes)

        let contentChanged = false;

        if(content) {
            if (content !== contentBefore) {
                contentChanged = true
                if(emitContetTimeout) {
                    clearTimeout(emitContetTimeout);
                    emitContetTimeout = null;
                }
                emitContetTimeout = setTimeout(() => {
                    emitContetTimeout = null;
                    lastEmitTime = timeNow;
                    detectHandler({ content, location, imageData });
                }, 250)
            }
        }


        if (location && timeNow - lastLocationTime > 500 || !content && !contentBefore && lastLocationTime || contentChanged) {
            lastLocationTime = location ? timeNow : 0;
            locateHandler(detectedCodes);
        }

        lastScanned = timeNow;
        if(content) {
            contentBefore = content;
            lastContentTime = timeNow;
        } else if(timeNow - lastContentTime > 500) {
            contentBefore = null;
        }
    }
    await new Promise((resolve) => window.requestAnimationFrame(resolve))
  }
};

const imageElementFromUrl = async url => {
  if (url.startsWith("http") && url.includes(location.host) === false) {
    throw new DropImageFetchError();
  }

  const image = document.createElement("img");
  image.src = url;

  await eventOn(image, "load");

  return image;
}

export const processFile = async file => {
  const barcodeDetector = new (window as any).BarcodeDetector({ formats: ["qr_code"] })
  const detectedCodes = await barcodeDetector.detect(file)

  return adaptOldFormat(detectedCodes)
}

export const processUrl = async url => {
  const barcodeDetector = new (window as any).BarcodeDetector({ formats: ["qr_code"] })
  const image = await imageElementFromUrl(url);
  const detectedCodes = await barcodeDetector.detect(image)

  return adaptOldFormat(detectedCodes)
}
