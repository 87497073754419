

import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import { ImportConfig, ImportField, ImportContext, ImportFileSource } from "./importCommon";

@Component({
    components: {
    },
})
export default class EditorImportFileSelect extends Vue {
    @Prop()
    context : ImportContext

    @Prop()
    item : ImportConfig

    get source() { return this.item.fileSource }
    set source(v) {
        Vue.set(this.item, 'fileSource', v);
    }

    get sourceType() {
        return this.source?.type ?? '';
    }

    set sourceType(type : string) {
        if(type === this.sourceType) return;
        else this.source = ImportFileSource.create(type, this);
    }

    get field() {
        return this.item.field;
    }

    get fileSources() {
        return [
            { text: this.$t("fileSources.folder"), value: 'folder' },
            { text: this.$t("fileSources.archive"), value: 'archive' },
            { text: this.$t("fileSources.url"), value: 'url' },
        ]
    }

    get valid() {
        return this.source && this.source.valid;
    }

    pickFolder(files : FileList) {
        console.log(files);
    }


}

