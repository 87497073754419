import _ from 'lodash'
import type { ObjectID } from '@mfeathers/db/schemas'

//@ts-ignore
import type { LangArrType } from "./common";
function getIDCore(item : string | null | ObjectID<any>) : string | null;
function getIDCore(item : any) : string | null;
function getIDCore<T extends { _id: ObjectID<any>}>(item : T) : string;
function getIDCore<T extends { _id: any}>(item : T) : string;

function getIDCore(item : any) : string | null {
    if(!item) {
        return null;
    } else if(typeof item === 'string') {
        return item;
    } else if(item?._bsontype === 'ObjectID' || item?._bsontype === 'ObjectId') {
        return item.toString();
    } else if(item._id !== undefined) {
        return getIDCore(item._id);
    } else {
        console.warn("Unknown id type", item);
        throw new Error("Unknown id type");
    }
}

function checkIDCore(a : any, b : any) : boolean;
function checkIDCore<T extends { _id: ObjectID<any>}>(a : string | ObjectID<any> | T | null, b : string | ObjectID<any> | T | null| ObjectID<any>) :boolean;
function checkIDCore<T extends { _id: ObjectID<any>}, T2 extends { _id: ObjectID<any>}>(a : string | T | null| ObjectID<any>, b : string | T2 | null| ObjectID<any>) : boolean;
function checkIDCore<T extends { _id: any}>(a : string | T | null, b : string | T | null| ObjectID<any>) :boolean;
function checkIDCore<T extends { _id: any}, T2 extends { _id: ObjectID<any>}>(a : string | T | null| ObjectID<any>, b : string | T2 | null| ObjectID<any>) : boolean;

function checkIDCore(a : any, b : any) : boolean {
    return getIDCore(a) === getIDCore(b);
}

export const getID = getIDCore;
export const checkID = checkIDCore;

export function regEscape(text : string) : string {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export function wrap(fn) {  
    return (req, res, next) => {
        const routePromise = fn(req, res, next);
        if (routePromise.catch) {
            routePromise.catch(err => next(err));
        }
    }
}

export function wrapSocket(fn) {  
    return (socket, next) => {
        const routePromise = fn(socket, next);
        if (routePromise.catch) {
            routePromise.catch(err => next(err));
        }
    }
}

export function hashCode(str : string) {
    var hash = 0, i, chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
        chr   = str.charCodeAt(i);
        hash  = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
};

// TODO: Improve or Use library
function getDecimalLength(v: number): number {
    const list = v.toString().split('.');
    return (list[1] && list[1].length) || 0;
}

export function multiplyDecimals(v1: number, v2: number): number {
    const intVal1 = +(v1.toString().replace('.', ''));
    const intVal2 = +(v2.toString().replace('.', ''));
    const decimalLength = getDecimalLength(v1) + getDecimalLength(v2);
    return (intVal1 * intVal2) / Math.pow(10, decimalLength);
}

export function divideDecimals(v1: number, v2: number): number {
    const adjuster = Math.pow(10, Math.max(getDecimalLength(v1), getDecimalLength(v2)));
    return multiplyDecimals(v1, adjuster) / multiplyDecimals(v2, adjuster);
}

export function sumByGroup<T, TRes extends { value: number }>(
    getter: (item : T) => TRes[], 
    keyGetter: (item : TRes) => string | string[],
    valueKeys: string[] = ['value'],
) {
    return (accum: TRes[], cur: T) => {
        const list = getter(cur) || [];
        if(list.length) {
            if(accum.length) {
                for(let it of list) {
                    const b = keyGetter(it);
                    const idx = accum.findIndex(a => _.isEqual(keyGetter(a), b));
                    if(idx === -1) {
                        accum.push(it)
                    } else {
                        const newItem = { ...accum[idx] };
                        for(let key of valueKeys) {
                            newItem[key] = (newItem[key] || 0) + (it[key] || 0);
                        }
                        accum[idx] = newItem
                    }
                }
            } else {
                accum.push(...list);
            }
        }
        return accum;
    }
}

export function roundValue(valueInt: number, roundFactorInt: number, roundingMode: 'roundOff' | 'roundUp' | 'roundDown' = 'roundUp') {
  if(!roundFactorInt || isNaN(roundFactorInt)) return valueInt;
  switch(roundingMode) {
    case 'roundDown': {
      return valueInt - valueInt % roundFactorInt
    }
    case 'roundOff': {
      const hround = roundFactorInt / 2;
      const remain = (valueInt % roundFactorInt);
      if(remain >= hround) {
        return valueInt + (roundFactorInt - valueInt % roundFactorInt)
      } else {
        return valueInt - valueInt % roundFactorInt
      }
    }
    default: {
      const remain = valueInt % roundFactorInt;
      if(remain !== 0) {
        return  valueInt + (roundFactorInt - valueInt % roundFactorInt)
      }
      return valueInt;
    }
  }
}
