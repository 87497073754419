import { render, staticRenderFns } from "./MobilePageView.vue?vue&type=template&id=61c6ec32&scoped=true"
import script from "./MobilePageView.vue?vue&type=script&lang=ts"
export * from "./MobilePageView.vue?vue&type=script&lang=ts"
import style0 from "./MobilePageView.vue?vue&type=style&index=0&id=61c6ec32&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61c6ec32",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})


/* hot reload */
if (module.hot) {
  var api = require("/home/jason/p/boxs/server/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('61c6ec32')) {
      api.createRecord('61c6ec32', component.options)
    } else {
      api.reload('61c6ec32', component.options)
    }
    module.hot.accept("./MobilePageView.vue?vue&type=template&id=61c6ec32&scoped=true", function () {
      api.rerender('61c6ec32', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/boxs/MobilePageView.vue"
export default component.exports