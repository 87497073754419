
import { Component, Prop, VModel, Vue } from "@feathers-client";
import EditorDatePicker from "@schemaEditor/EditorDatePicker.vue";
import moment from "moment";

@Component({
  components: {
    EditorDatePicker,
  },
})
export default class DateButtonGroup extends Vue {
  name = "dropdown";
  isOpen = false;
  @Prop({ type: String, default: "today" })
  mode: string;

  startDate = moment().startOf("day").toDate();
  endDate = moment().endOf("day").subtract(1, "second").toDate();

  get dateStartSync() {
    return this.startDate;
  }
  set dateStartSync(v) {
    this.setDate("custom");
  }
  get dateEndSync() {
    return this.endDate;
  }
  set dateEndSync(v) {
    this.setDate("custom");
  }

  setDate(mode: string, type?: "start" | "end", date?: Date) {
    //latest date
    // const today = moment().startOf("day").toDate();
    const todayStart = moment().startOf("day").toDate();
    const todayEnd = moment(todayStart).add(1, "day").subtract(1, "second").toDate();

    const weekStart = moment(todayStart)
      .subtract(moment(todayStart).get("isoWeekday") - 1, "day")
      .toDate();
    const weekEnd = moment(weekStart).add(7, "day").subtract(1, "second").toDate();

    const monthStart = moment().startOf("month").toDate();
    const monthEnd = moment(monthStart).add(1, "month").subtract(1, "second").toDate();

    const yearStart = moment().startOf("year").toDate();
    const yearEnd = moment(yearStart).add(1, "year").subtract(1, "second").toDate();

    if (mode === "today") {
      this.startDate = todayStart;
      this.endDate = todayEnd;
    } else if (mode === "week") {
      this.startDate = weekStart;
      this.endDate = weekEnd;
    } else if (mode === "month") {
      this.startDate = monthStart;
      this.endDate = monthEnd;
    } else if (mode === "year") {
      this.startDate = yearStart;
      this.endDate = yearEnd;
    } else if (mode === "custom") {
      if (type === "start") this.startDate = date;
      else if (type === "end") this.endDate = date;
    } else this.endDate = moment(date).add(1, "day").subtract(1, "second").toDate();
    this.$emit("updateDates", {
      startDate: this.startDate,
      endDate: this.endDate,
      mode: mode,
    });
  }

  normalizedDay(date) {
    let openHour = "00:00";

    const time = openHour?.split?.(":");
    const hh = isNaN(+time?.[0]) ? 0 : +time?.[0];
    const mm = isNaN(+time?.[1]) ? 0 : +time?.[1];

    const now = moment();
    const day = moment().startOf("day").add(hh, "hours").add(mm, "minutes");
    const cur = moment(date).add(hh, "hours").add(mm, "minutes");

    if (day.isAfter(now)) {
      return cur.subtract(1, "day").toDate();
    }
    return cur.toDate();
  }
}
