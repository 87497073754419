
import _ from "lodash";
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import { checkID, FindType } from "@feathers-client";
import type { ProductSearchType, ProductType } from "@common/common";

@Component
export default class OrderSystemProduct extends Vue {
  @Prop()
  item: ProductSearchType & {
    stock?: FindType<"shop/inventory/summaries">;
  };

  get stockStatus() {
    return "hidden";
  }

  get mainPricing() {
    return this.item.pricing?.[0];
  }

  get stockLevel() {
    if (this.stock < 10) {
      return "bg-red300";
    } else {
      return "bg-white";
    }
  }

  get stock() {
    return this.item?.stock?.amount ?? 0;
  }

  @Prop({ type: Boolean, default: false })
  shortMode: boolean;

  @Prop({ type: Boolean })
  readonly: boolean;

  get actualPrice() {
    return this.mainPricing?.min?.price ?? 0;
  }

  get maxActualPrice() {
    return this.mainPricing?.max?.price ?? 0;
  }

  get thumb() {
    return (this.item?.mainImage && this.$thumb(this.item.mainImage)) || require("~/assets/images/logo.png");
  }

  lastPress = 0;

  shortpress() {
    if (Date.now() - this.lastPress < 50) return;
    this.lastPress = Date.now();
    this.$emit("addToCart", this.item);
  }

  longpress() {
    if (Date.now() - this.lastPress < 50) return;
    this.lastPress = Date.now();
    this.$emit("addToCart", this.item, true);
  }
}
