import _ from "lodash";
import { parseString } from "xml2js";
import { supported, request } from "~/plugins/httpProxy";
import qs from "qs";
import { Vue, Component, isBBPOS } from "@feathers-client";
import { interfaces, connect, isLocal, getAddresses } from "pos-printer/ports/socket";
import { supported as appSupported, bringToTop, launch } from "pos-printer/utils/app";

export interface CallApiOpts {
  timeout?: number
}

@Component
export default class AllinpayPOS extends Vue {
  async callAllinpayPOS<T = any>(params, posURL?: string, opts?: CallApiOpts) {
    if (!(await supported())) {
      throw new Error(`${this.$t("bbpos.notSupported")}`);
    }

    const url = posURL ?? this.$pos.cashier.allinpayUrl;
    console.log(url)

    if (!url) {
      throw new Error(`${this.$t("bbpos.noUrl")}`);
    }
    let resp: Awaited<ReturnType<typeof request>>;
    try {
      resp = await request(`${url}/trans`, qs.stringify(params), "application/x-www-form-urlencoded", {
        timeout: opts?.timeout ?? 300 * 1000
      });

      if(resp.statusCode !== 200) {
        throw new Error(`Invalid status code ${resp.statusCode}: ${resp.body}`)
      } else {
        const body = JSON.parse(resp.body);
        if(body.REJCODE !== "00") {
          const e = Error(`Transaction failed: ${body.REJCODE_CN}`);
          Object.assign(e, body);
          throw e;
        }
        return body;
      }
    } catch (e) {
      // if (e.message.includes("java.net.ConnectException") && isBBPOS) {
      //   console.log("going to launch bbpos");
        // try launch bbpos app
        // await launch("com.bbpos.www.payment_allinpay");
        // await new Promise(resolve => setTimeout(resolve, 1000));
        // await this.pollApi();
        // return await this.callPOS(params, posURL, {
        //   checkResponse,
        //   tryLogin,
        //   toTop,
        // });
      // }
      throw e;
    }

    return resp;
  }

  async getAllinpayInfo() {
    return await this.callAllinpayPOS({
      BUSINESS_ID: "900100001" // BUSI_MANAGER_TRANS_LOGON
    }, undefined, {
      timeout: 3000,
    }) as AllinpayInfo
  }

  async testAllinpay() {
    try {
      return await this.getAllinpayInfo();
    } catch (e) {
      return false;
    }
  }

  async scanAllinpay(cb: (s: string) => void) {
    const faces = (await interfaces()).filter(isLocal);
    for (let face of faces) {
      const iter = getAddresses(face);

      await Promise.all(new Array(256).fill(null).map(async thread => {
        while(true) {
          const next = iter.next();
          if(next.done) return;
          const ip = next.value;
          if (ip === face.address) return;
          const u = `http://${ip}:9801`;
          try {
            await Promise.race([
              this.callAllinpayPOS({
                BUSINESS_ID: "900100001" // BUSI_MANAGER_TRANS_LOGON
              }, u, { timeout: 3000 }),
              new Promise((resolve, reject) => setTimeout(reject, 15 * 1000))
            ])
  
            cb(u);
          } catch (e) {
            // console.log(e);
          }
        }
      }));
    }
  }
}

export interface AllinpayInfo {
  MERCH_ID: string
  MERCH_NAME: string
  TER_ID: string
  REJCODE: string
  REJCODE_CN: string
  PRINT_FLAG?: string
  BUSINESS_ID?: string
}
