
import _ from "lodash";
import { Component, Prop, Vue, Watch, mixins, VModel } from "nuxt-property-decorator";
import { Order } from "@common/pos";
import { FindPopRawType, FindType } from "@feathers-client";
import { checkID } from "@feathers-client";

@Component
export default class OrderSystemTax extends Vue {
  @Prop()
  session: Order;

  setTaxEnable(serviceContent: any, enabled: boolean) {
    if(!this.session.disabledTaxRules) {
      this.session.disabledTaxRules = [];
    }
    if(!enabled) {
      if(!this.session.disabledTaxRules.includes(serviceContent)) {
        this.session.disabledTaxRules.push(serviceContent);
      }
    } else {
      const idx = this.session.disabledTaxRules.indexOf(serviceContent);
      if(idx !== -1) {
        this.session.disabledTaxRules.splice(idx, 1);
      }
    }
  }
}
