

import { Vue, Component, FindType, Prop  } from '@feathers-client'
import Editor from '@schemaEditor/Editor.vue'
import _ from 'lodash'

@Component({
    components: {
        Editor,
    }
})
export default class OrderEditor extends Vue {

    @Prop(Boolean)
    nested: boolean

    @Prop()
    filter: any

    @Prop()
    headers: string[]

    modifying = false;

    async confirmBank(payment) {
        try {
            this.modifying = true;
            const mpayment = await this.$feathers.service('shop/payments/update').create({
                _id: payment._id,
                status: 'paid',
                reference: payment.reference,
                customerRemarks: payment.customerRemarks,
            });
            _.assign(payment, mpayment);
            this.$emit('update');
        } catch (e) {
            console.warn(e);
            this.$store.commit('SET_ERROR', e.message);
        } finally {
            this.modifying = false;
        }
    }
    async cancelBank(payment) {
        try {
            this.modifying = true;
            const mpayment = await this.$feathers.service('shop/payments/update').create({
                _id: payment._id,
                status: 'cancelled'
            });
            _.assign(payment, mpayment);
            this.$emit('update');
        } catch (e) {
            console.warn(e);
            this.$store.commit('SET_ERROR', e.message);
        } finally {
            this.modifying = false;
        }
    }
    isEShop(payment) {
        const source = _.get(payment, 'metadata.source', '');
        return source === 'eshop';
    }


    refresh() {
        (this.$refs?.editor as any)?.refresh?.();
    }
}

