import { Component, FindType, Prop, Vue, getID } from "@feathers-client";
import _ from "lodash";
import { exportExcel, RowItem, RowChunk } from "domore-table/exportExcel";
import moment from "moment";
import { checkID } from "../../common/util-core";

type WholesaleOrdersType = Array<FindType<"shop/erp/wholesaleOrders">>;

@Component
export default class ExcelExporter extends Vue {
  async exportWholesaleOrders(orderQuery?) {
    const rawData: WholesaleOrdersType = (await this.$feathers.service("shop/erp/wholesaleOrders").find({
      query: orderQuery ?? {
        shop: this.$shop._id,
        $sort: { date: -1 },
        $paginate: false,
      },
      paginate: false,
    })) as WholesaleOrdersType;

    const self = this;

    await exportExcel(this.$t("pages.bulkOrders") as string, [
      {
        name: this.$t("pages.bulkOrders") as string,
        cols: {
          8: { hidden: true },
        },
        load: async function* () {
          yield {
            rows: [
              {
                data: [
                  self.$t("pages.shop/shipping/shipments.orders") as string,
                  self.$t("pos.orderType") as string,
                  self.$t("basic.status") as string,
                  self.$t("basic.date") as string,
                  self.$t("basic.total") as string,
                  self.$t("pos.customer") as string,
                  "ref Quotation",
                ],
                header: true,
              },
            ],
          };

          for (let order of rawData) {
            const rows: RowItem[] = [];

            const refQuo = order.refQuotation ? _.find(rawData, el => checkID(el._id, order.refQuotation)) : null;

            let orderTotalAmt = 0;
            for (let product of order.products) {
              orderTotalAmt +=
                (product.finalPriceInt ?? product["finalPrice"]) * product.quantity - product.totalDiscountAmtInt;
            }

            rows.push({
              data: [
                order?.orderId ?? order?._id,
                order?.orderType ? self.$t(`subsequentPaymentOrders.erp.${order.orderType}`) : "",
                order.status ? self.$t(`subsequentPaymentOrders.erp.${order.status}`) : "",
                order.date ? moment(order.date).format("lll") : "-",
                self.$price(orderTotalAmt) as string,
                order.customer ?? "",
                refQuo ? refQuo.orderId ?? refQuo._id : "",
              ],
            });

            rows[0].separator = true;
            yield {
              hasNested: true,
              rows,
            };
          }
        },
      },
    ]);
  }

  async testExport() {
    const rawData = await this.$feathers.service("shop/erp/wholesaleOrders").find({
      query: {
        shop: this.$shop._id,
        $sort: { date: -1 },
        $paginate: false,
        $populate: ["invoice"],
      },
      paginate: false,
    });

    const self = this;

    await exportExcel(this.$t("pages.bulkOrders") as string, [
      {
        name: this.$t("pages.bulkOrders") as string,
        cols: {
          8: { hidden: true },
        },
        load: async function* () {
          yield {
            rows: [
              {
                data: [
                  self.$t("pages.shop/shipping/shipments.orders") as string,
                  self.$t("pos.orderType") as string,
                  self.$t("basic.status") as string,
                  self.$t("basic.date") as string,
                  self.$t("basic.total") as string,
                  self.$t("pos.customer") as string,
                  "dllm",
                  "ref Quotation",
                ],
                header: true,
              },
            ],
          };

          for (let order of rawData) {
            const rows: RowItem[] = [];

            const refQuo = order.refQuotation ? _.find(rawData, el => checkID(el._id, order.refQuotation)) : null;

            let orderTotalAmt = 0;
            for (let product of order.products) {
              orderTotalAmt +=
                (product.finalPriceInt ?? product["finalPrice"]) * product.quantity - product.totalDiscountAmtInt;
            }


            rows.push({
              data: [
                order?.orderId ?? order?._id,
                order?.orderType ? self.$t(`subsequentPaymentOrders.erp.${order.orderType}`) : "",
                order.status ? self.$t(`subsequentPaymentOrders.erp.${order.status}`) : "",
                order.date ? moment(order.date).format("lll") : "-",
                self.$price(orderTotalAmt) as string,
                order.customer ?? "",
                order.invoice.map(el=> el.invoiceID ?? el._id),
                refQuo ? refQuo.orderId ?? refQuo._id : "",
              ],
            });

            rows[0].separator = true;
            yield {
              hasNested: true,
              rows,
            };
          }
        },
      },
    ]);
  }

  async exportInvoices(query?, rawData?) {
    if (rawData) {
      const self = this;
      await exportExcel(`ERP ${this.$t("invoice.invoice")}` as string, [
        {
          name: `ERP ${this.$t("invoice.invoice")}` as string,
          cols: {
            8: { hidden: true },
          },
          load: async function* () {
            yield {
              rows: [
                {
                  data: [
                    self.$t("subsequentPaymentOrders.erp.invoiceID") as string,
                    self.$t("subsequentPaymentOrders.erp.issueDate") as string,
                    self.$t("basic.status") as string,
                    self.$t("basic.total") as string,
                    self.$t("remarks") as string,
                    "ref Order",
                  ],
                  header: true,
                },
              ],
            };

            for (let order of rawData) {
              const rows: RowItem[] = [];

              const refQuo = order.refQuotation ? _.find(rawData, el => checkID(el._id, order.refQuotation)) : null;

              let orderTotalAmt = 0;
              for (let product of order.products) {
                orderTotalAmt +=
                  (product.finalPriceInt ?? product["finalPrice"]) * product.quantity - product.totalDiscountAmtInt;
              }

              rows.push({
                data: [
                  order?.orderId ?? order?._id,
                  order?.orderType ? self.$t(`subsequentPaymentOrders.erp.${order.orderType}`) : "",
                  order.status ? self.$t(`subsequentPaymentOrders.erp.${order.status}`) : "",
                  order.date ? moment(order.date).format("lll") : "-",
                  self.$price(orderTotalAmt) as string,
                  order.customer ?? "",
                  refQuo ? refQuo.orderId ?? refQuo._id : "",
                ],
              });

              rows[0].separator = true;
              yield {
                rows,
              };
            }
          },
        },
      ]);
    }
  }
}
