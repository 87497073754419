
import { Component, mixins, Auth, Prop, Vue, FindType, getID } from "@feathers-client";
import Dialog from "@feathers-client/mixins/Dialog";
import DatePicker from "~/components/DatePicker.vue";
import EditorObjectPickerList from "@schemaEditor/EditorObjectPickerList.vue";
import DataTableForErp from "~/components/erp/DataTableForErp.vue";
import moment from "moment";
import EditorTextField from "@schemaEditor/EditorTextField.vue";
import { Watch } from "nuxt-property-decorator";
import MenuListNum from "@feathers-client/components/MenuListNum.vue";
import MenuListButton from "@feathers-client/components/MenuListButton.vue";
import ItemPicker from '@feathers-client/components/ItemPicker.vue'
import { keyBy, map, sortBy, sumBy } from "lodash";
import { FindPopRawType } from "@feathers-client";
import Editor from '@schemaEditor/Editor.vue'

@Component({
  components: {
    DatePicker,
    EditorObjectPickerList,
    DataTableForErp,
    EditorTextField,
    MenuListNum,
    MenuListButton,
    ItemPicker,
    Editor
  },
})
export default class InvoicePickerDialog extends mixins(Dialog) {

  // @Prop()
  // customer: FindType<'shop/erp/companyUsers'>;

  // @Prop()
  // existInv;

  @Prop()
  filter;

  // @Prop({ default: false })
  // forExportExcel: boolean;

  // existInvHash: object = {}

  // isExist(invID) {
  //   return this.existInvHash[invID];
  // }

  async mounted() {
    // if (this.existInv) {
    //   this.existInvHash = keyBy(this.existInv)
    // }
    if(this.filter) {
      this.chosenCust = this.filter.customer
      this.chosenOrderType = this.filter.orderType
      this.chosenStatus = this.filter.status
      this.chosenShipmentStatus = this.filter.shippingStatus
      this.chosenSku = this.filter["products.productSku"]
      this.inputID = this.filter._id || this.filter.orderId
    }
  }


  // async searchUnpaidInv() {
  //   // const orders = await this.$feathers.service("shop/erp/wholesaleOrders").find({
  //   //   query: {
  //   //     shop: this.$shop._id,
  //   //     ...this.customer ? { customer: this.customer } : {},
  //   //     $select: ['_id'],
  //   //     $paginate: false,
  //   //   },
  //   //   paginate: false
  //   // });

  //   const invoices = (await this.$feathers.service('shop/erp/wholesaleOrder/invoices').find({
  //     query: {
  //       shop: this.$shop._id,
  //       // _id: { $nin: this.existInv },
  //       // refOrder: { $in: map(orders, el => el._id) },
  //       customer: this.customer,
  //       isCancelled: { $ne: true },
  //       monthlyStmt: null,
  //       amountInt: { $gt: 0 },
  //       // ...this.includePaid ? {payment: null} : {},
  //       expiryDate: { $gte: this.dateRange.startDate, $lt: this.dateRange.endDate },
  //       $sort: { issueDate: -1 },
  //       $paginate: false,
  //       $populate: ['payment']
  //     },
  //     paginate: false
  //   })).map(el => {
  //     return {
  //       ...el,
  //       isSelected: false,
  //     }
  //   });

  //   let invoiceArr = [];
  //   for (let inv of invoices) {
  //     if (!inv.payment || !inv.payment.length || inv.payment.every(el => el.isCancelled)) {
  //       invoiceArr.push(inv);
  //     }
  //   }
  //   sortBy(invoiceArr, 'date');
  //   this.invoices = invoiceArr;
  //   this.hasSearched = true;
  // }

  async resetAll() {
    const c = await this.$openDialog(
      import("./ErpConfirmDialog.vue"),
      {
        title: this.$t('subsequentPaymentOrders.erp.confirmFinish'),
        content: this.$t('subsequentPaymentOrders.erp.dataWillNotBeSaved'),
      },
      {
        maxWidth: "400px"
      }
    );
    if (!c) {
      return;
    }
    this.invoices = [];
    this.hasSearched = false;
  }

  tableHeader = [
    this.$t('subsequentPaymentOrders.erp.invoiceID'),
    this.$t('subsequentPaymentOrders.dueDate'),
    this.$t('subsequentPaymentOrders.erp.amount'),
  ]

  invoices: Array<FindPopRawType<'payment', 'shop/erp/wholesaleOrder/invoices'> & { isSelected: boolean }> = [];

  hasSearched = false;

  dateRangeSelections: 'today' | 'this week' | 'this month' | 'past 30 days' | 'past 3 months' | '' = 'today'


  @Watch('dateRangeSelections')
  onDateRangeSelections(n, o) {
    switch (n) {
      case 'today':
        this.dateRange.startDate = moment().startOf('day').toDate();
        this.dateRange.endDate = moment().startOf('day').add(1, 'day').toDate();
        break;
      case 'this week':
        this.dateRange.startDate = moment().startOf('day').subtract(7, 'days').toDate();
        this.dateRange.endDate = moment().startOf('day').add(1, 'day').toDate();
        break;
      case 'this month':
        this.dateRange.startDate = moment().startOf('month').toDate();
        this.dateRange.endDate = moment().startOf('month').add(1, 'month').toDate();
        break;
      case 'past 30 days':
        this.dateRange.startDate = moment().startOf('day').subtract(30, 'days').toDate();
        this.dateRange.endDate = moment().startOf('day').add(1, 'day').toDate();
        break;
      case 'past 3 months':
        this.dateRange.startDate = moment().startOf('day').subtract(3, 'months').toDate();
        this.dateRange.endDate = moment().startOf('day').add(1, 'day').toDate();
        break;
      default:
        break;
    }
  }

  dateRange = {
    startDate: moment().startOf('day').toDate(),
    endDate: moment().startOf('day').add(1, 'day').toDate()
  };

  get orderType() {
    return [
      {
        title: this.$t('subsequentPaymentOrders.erp.quotation'),
        action: 'quo'
      },
      {
        title: this.$t('basic.order'),
        action: 'wso'
      },
    ]
  } 

  get orderStatus() {
    if (!this.chosenOrderType) {
      return [
        {
          name: this.$t('unconfirmed'),
          value: 'unconfirmed'
        },
        {
          name: this.$t('confirmed'),
          value: 'confirmed'
        },
        {
          name: this.$t('orders.status.unpaid'),
          value: 'unpaid'
        },
        {
          name: this.$t('orders.status.paid'),
          value: 'paid'
        },
        {
          name: this.$t('basic.cancelled'),
          value: 'cancelled'
        }
      ]
    }
    if (this.chosenOrderType === 'quo') {
      return [
        {
          name: this.$t('unconfirmed'),
          value: 'unconfirmed'
        },
        {
          name: this.$t('confirmed'),
          value: 'confirmed'
        },
      ]
    } else if (this.chosenOrderType === 'wso') {
      return [
        {
          name: this.$t('orders.status.unpaid'),
          value: 'unpaid'
        },
        {
          name: this.$t('orders.status.paid'),
          value: 'paid'
        },
        {
          name: this.$t('basic.cancelled'),
          value: 'cancelled'
        }
      ];
    }

  }

  get orderShipmentStatus() {
    return [
      {
        name: this.$t('subsequentPaymentOrders.shipmentStatus.pending'),
        value: 'pending'
      },
      {
        name: this.$t('subsequentPaymentOrders.shipmentStatus.mixed'),
        value: 'mixed'
      },
      {
        name: this.$t('subsequentPaymentOrders.shipmentStatus.shipped'),
        value: 'shipped'
      },
    ]
  } 
  
  set selectAll(v: boolean) {
    for (let inv of this.invoices)
      inv.isSelected = v
  }

  get selectAll() {
    return this.invoices?.every(el => el.isSelected === true)
  }

  get selectedCount() {
    if (this.invoices)
      return this.invoices.filter(el => el.isSelected === true).length
  }

  get dateRanges() {
    return [
      {
        _id: 'today',
        name: this.$t('dateButton.today'),
      },
      {
        _id: 'this week',
        name: this.$t('dateButton.thisWeek'),
      },
      {
        _id: 'this month',
        name: this.$t('dateButton.thisMonth'),
      },
      {
        _id: 'past 30 days',
        name: this.$t('dateButton.pastDays', { count: 30 }),
      },
      {
        _id: 'past 3 months',
        name: this.$t('dateButton.pastMonths', { count: 3 }),
      },
    ]
  }

  // for export excel

  isObjectID(str) {
    return /^[a-f\d]{24}$/i.test(str);
  }

  chosenCust = null
  chosenOrderType = null
  chosenStatus = null
  chosenShipmentStatus = null
  chosenSku = null
  inputID = ""

  get exportFilter() {
    const inputID = this.inputID?.trim();
    return {
      shop: this.$shop._id,
      ...this.chosenCust ? { customer: this.chosenCust } : {},
      ...this.chosenOrderType ? { orderType: this.chosenOrderType === 'wso' ? 'order' : 'quotation' } : {},
        ...this.chosenStatus ? { status: this.chosenStatus } : {},
        ...this.chosenShipmentStatus ? { shippingStatus: this.chosenShipmentStatus } : {},
        ...this.chosenSku ? { "products.productSku": this.chosenSku } : {},
        ...inputID && inputID !== '' ? {
          ...this.isObjectID(inputID)
            ? { _id: inputID }
            : { orderId: { $regex: `.*${inputID}.*`, $options: 'i' } },
        }
          : {},
      date: { $gte: this.dateRange.startDate, $lte: this.dateRange.endDate },
      $sort: { date: -1 },
      testing: false,
      $populate: [
        'invoice',
        {
          path: 'products.productSku',
          populate: [
            {
              path: 'productGroup',
              select: ['slug']
            },
          ],
          select: ['slug']
        }, 
        'staff', 
        'customer'
      ]
    };
  }

  async exportWholesaleOrders() {
    (this.$refs.e as any).exportWithNested()
  }

  exportFlatten = true

  get exportFilterOverride() {
    const self = this
    return {
      $flattenNested: this.exportFlatten,
      $headers: [
        {
          text: this.$t("pages.shop/orders.orderId"), sortable: true,
          value: "orderId",
          format: "t"
        },
        // {
        //   text: this.$t("basic.cancelled"), sortable: true,
        //   value: "isCancelled",
        //   format: (ctx, item) => { return self.$t(`basic.${!!item}`) }
        // },
        {
          text: this.$t("basic.status"), sortable: true,
          value: "status",
          format: (ctx, item) => { return self.$t(`subsequentPaymentOrders.erp.${item}`) }
        },
        {
          text: this.$t("orders.shippingStatus.$"), sortable: true,
          value: "shippingStatus",
          format: (ctx, item) => { return self.$t(`orders.shippingStatus.${item}`) }
        },
        {
          text: this.$t("subsequentPaymentOrders.erp.issueDate"), sortable: true,
          value: "date",
          format: (ctx, item) => moment(item).format('lll')
        },
        {
          text: this.$t("invoice.user"), sortable: true,
          value: "customer.name",
          format: 't'
        },
        {
          text: this.$t("subsequentPaymentOrders.erp.dealer"), sortable: true,
          value: "staff.name",
          format: 't'
        },
        {
          text: this.$t("subsequentPaymentOrders.erp.contactPerson"), sortable: true,
          value: "contactPerson",
          format: 't'
        },
        {
          text: this.$t("subsequentPaymentOrders.erp.contactPersonTel"), sortable: true,
          value: "contactTel",
          format: 't'
        },
        {
          text: this.$t("orders.rawData.receiver.name"), sortable: true,
          value: "recipient",
          format: 't'
        },
        {
          text: this.$t("orders.rawData.receiver.phone"), sortable: true,
          value: "recipientTel",
          format: 't'
        },
        {
          text: this.$t("orders.amount"), sortable: true,
          value: "products",
          format: (ctx, item) => self.$price(sumBy(item, el => el['finalPriceInt'] * el['quantity'] - el['totalDiscountAmtInt']))
        },
        {
          text: this.$t("charts.category.totalDiscount"), sortable: true,
          value: "products",
          format: (ctx, item) => self.$price(sumBy(item, 'totalDiscountAmtInt'))
        },
        {
          text: this.$t("remarks"), sortable: true,
          value: "remarks",
          format: 't'
        },
      ],
      $nested: [
        {
          $path: "products",
          $useHeaders: true,
          $flattenNested: this.exportFlatten,
          $headers: [
            {
              text: this.$t("edit_product.name"),
              sortable: true,
              value: "productName",
              format: 't',
            },
            {
              text: this.$t("edit_product.code"),
              sortable: true,
              value: "productSku.productGroup.slug",
            },
            {
              text: this.$t("edit_product.skuCode"),
              sortable: true,
              value: "productSku.slug",
            },
            { text: this.$t("charts.product.quantity"), sortable: true, value: "quantity" },
            {
              text: this.$t("charts.product.total"), sortable: true,
              value: "finalPriceInt",
              format: (ctx, item) => this.$price(item)
            },
            {
              text: this.$t("charts.category.totalDiscount"),
              sortable: true,
              value: "totalDiscountAmtInt",
              format: (ctx, item) => this.$price(item)
            },
            // {
            //   text: this.$t("charts.category.income"),
            //   sortable: true,
            //   value: null,
            //   format: (ctx, item) => {
            //     return this.$price(item.totalInt + (item.discountInt || 0));
            //   },
            // },
          ],
        },
        {
          $path: "invoice",
          $useHeaders: true,
          $flattenNested: this.exportFlatten,
          $headers: [
            {
              text: this.$t("subsequentPaymentOrders.erp.invoiceID"), sortable: true,
              value: "invoiceID",
              format: "t"
            },
            {
              text: this.$t("basic.cancelled"), sortable: true,
              value: "isCancelled",
              format: (ctx, item) => {
                // console.log(!!item); 
                return self.$t(`basic.${!!item}`)
              }
            },
            {
              text: this.$t("subsequentPaymentOrders.erp.issueDate"), sortable: true,
              value: "issueDate",
              format: (ctx, item) => moment(item).format('lll')
            },
            {
              text: "Deposit Requested", sortable: true,
              value: "amountInt",
              format: (ctx, item) => self.$price(item)
            },
            {
              text: this.$t("pages.shop/orders.paymentStatus"), sortable: true,
              value: "paymentStatus",
              format: (ctx, item) => self.$t(`enum.pages.shop/orders.paymentStatus.${item}`)
            },
            // {
            //   text: this.$t("orders.rawData.receiver.name"), sortable: true,
            //   value: "refOrder.recipient",
            //   format: 't'
            // },
            // {
            //   text: this.$t("orders.rawData.receiver.phone"), sortable: true,
            //   value: "refOrder.recipientTel",
            //   format: 't'
            // },
            // {
            //   text: this.$t("orders.amount"), sortable: true,
            //   value: "refOrder.products",
            //   format: (ctx, item) => self.$price(sumBy(item, el => el['finalPriceInt'] * el['quantity'] - el['totalDiscountAmtInt']))
            // },
            // {
            //   text: this.$t("charts.category.totalDiscount"), sortable: true,
            //   value: "refOrder.products",
            //   format: (ctx, item) => self.$price(sumBy(item, 'totalDiscountAmtInt'))
            // },
            // {
            //   text: this.$t("remarks"), sortable: true,
            //   value: "refOrder.remarks",
            //   format: 't'
            // },
          ]
        },
      ],
    };
  }

    // for export excel end


}
