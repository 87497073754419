
import _ from "lodash";
import { Component, Prop, Vue, Watch, mixins, Ref, PropSync } from "@feathers-client";
import type { Order } from "@common/pos";
import { LineItem } from "@common/posLineItem";
import type { ScanningRequest } from "./cart.vue";
import type { OrderProductType } from "@common/common";

@Component
export default class CartList extends Vue {
  @Prop()
  session: Order;

  @Prop()
  pending: ScanningRequest[];

  @Prop()
  staticItems: OrderProductType[];

  @Prop()
  selectingCart: boolean;

  @Prop(Boolean)
  isPaying: boolean;

  get items() {
    return this.session?.products;
  }

  get products() {
    return this.session?.products;
  }

  remove(item: LineItem) {
    this.session.remove(item);
    this.$emit("updated");
  }

  scrollAfterAnimation = false;

  @Watch("products")
  onProducts() {
    this.scrollAfterAnimation = true;
  }

  @Watch("staticItems")
  onItems() {
    this.scrollAfterAnimation = true;
  }

  handleResize() {
    if (!this.scrollAfterAnimation) return;
    const elem = this.$refs.orderDetails as Element;
    elem.scrollTop = elem.scrollHeight;
    this.scrollAfterAnimation = false;
  }

  async splitProduct(item) {
    item.quantity--;
    await this.session.addProduct(
      {
        sku: item.sku,
      },
      {
        moveToBottom: true,
        mustAdd: true,
        manualDiscountInt: item.manualDiscountInt,
        manualPercentDiscount: item.manualPercentDiscount,
        overridePriceInt: item.overridePriceInt,
      },
    );
  }
}
