
import { Component, Prop, Vue, Watch, mixins,FindType, VModel, getID } from "@feathers-client";

@Component({
})
export default class ItemPickerBase extends Vue {

    @Prop({ default: '' })
    itemClass: string

    @Prop()
    items: any[]

    @VModel()
    inputValue: any | any[];

    @Prop(Boolean)
    multiple : boolean

    @Prop(Boolean)
    inline: boolean

    @Prop(Boolean)
    disabled: boolean

    // use inner selected
    @Prop(String)
    innerSelected: string

    @Prop(Boolean)
    returnsObject: boolean

    @Prop({ type: Boolean, default: true })
    clearable: boolean

    @Prop({ type: Boolean })
    mandatory: boolean

    @Prop(String)
    itemKey: string

    @Prop(Number)
    maxSelect: number

    @Prop(Function)
    compare: (a:any,b:any) => boolean

    @Prop(Boolean)
    eventMode: boolean

    @Prop(Boolean)
    readonly: boolean

    @Prop(Boolean)
    preferSingle: boolean

    @Prop(String)
    selectedColor: string

    @Prop(String)
    itemIcon: string;

    get mselectedColor() {
        return this.selectedColor || this.$config.colors?.['dark-surface-secondary-default']
    }

    get getID() {
        return this.itemKey ? new Function('item', `return item ? typeof item === 'string' ? item : item.${this.itemKey} : null`) : getID
    }

    get checkID() {
        return this.compare ?? ((a, b) => this.getID(a) === this.getID(b));
    }

    toggleSelected(item, force?: boolean) {
        if(this.disabled || item.disabled || this.readonly) return;
        if(this.eventMode) {
            if(this.isSelected(item)) {
                this.$emit('remove', item);
            } else {
                this.$emit('add', item);
            }
            this.$emit('toggle', item);
            return;
        }
        if(this.innerSelected) {
            item[this.innerSelected] = !item[this.innerSelected];
        } else if(this.multiple) {
            const list = this.inputValue || [];
            const has = list.findIndex(it => this.checkID(it, item))
            if(this.inline) {
                if(!this.inputValue) this.inputValue = list;
                if(has !== -1) {
                    if(this.mandatory && list.length === 1) return;
                    list.splice(has, 1);
                } else {
                    if(this.maxSelect && list.length === this.maxSelect) {
                        this.inputValue.shift();
                    }
                    if(this.preferSingle && !force) {
                        this.inputValue.splice(0, this.inputValue.length);
                    }
                    list.push(this.returnsObject ? item : this.getID(item))
                }
            } else {
                if(has !== -1) {
                    if(this.mandatory && list.length === 1) return;
                    this.inputValue = list.filter(it => !this.checkID(it, item))
                } else {
                    this.inputValue = [
                        ...(this.preferSingle && !force ? [] : list.length === this.maxSelect ? list.slice(1) : list),
                        this.returnsObject ? item : this.getID(item),
                    ]
                }
            }
        } else {
            this.inputValue = this.checkID(this.inputValue, item) ? this.mandatory ? this.inputValue : null : this.returnsObject ? item : this.getID(item)
        }
        this.$emit('updated', item);
    }

    clearSelected() {
        this.$emit('beforeClear');
        if(this.disabled) return;
        if(this.eventMode) {
            this.$emit('clear');
            return;
        }
        if(this.innerSelected) {
            for(let item of this.items) item[this.innerSelected] = false;
        } else if(this.multiple) {
            if(this.inline) {
                this.inputValue.splice(0, this.inputValue.length);
            } else {
                this.inputValue = [];
            }
        } else {
            this.inputValue = null;
        }
        this.$emit('updated');
    }

    isSelected(item : any) {
        if(this.innerSelected) {
            return item[this.innerSelected];
        } else if(this.multiple) {
            return !!this.inputValue?.find?.(it => this.checkID(it, item))
        } else {
            return this.checkID(this.inputValue, item);
        }
    }

}

