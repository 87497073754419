
import _ from "lodash";
import { Component, Prop, Vue, Watch, mixins, Ref, FindType } from "@feathers-client";
import Cart from "./cart.vue";
import moment from "moment";
import QrcodeStream from "@feathers-client/qrcode-scanner/components/QrcodeStream.vue";

@Component({
  components: {
    QrcodeStream,
  },
})
export default class OrderSystemKiosk extends mixins(Cart) {
  bannerIndex = 0;
  banners: FindType<"shop/pos/banners">[] = [];
  paymentAmountInt = 0;
  payLoading = false;
  canPay = false;
  scanning = false;

  @Ref()
  page: any;

  async mounted() {
    this.banners = await this.$feathers.service("shop/pos/banners").find({
      query: {
        $paginate: false,
        publish: {
          $lt: moment().toDate(),
        },
        expiry: {
          $gt: moment().toDate(),
        },
        type: "kioskTop",
        status: "published",
        $sort: {
          publish: 1,
        },
      },
      paginate: false,
    });
  }

  doPay() {
    this.scanning = false;
    return this.page.navigateAndPay();
  }

  cancellingPay = false;

  @Watch("payLoading")
  onPayLoading() {
    this.cancellingPay = false;
  }

  async cancelPay() {
    this.cancellingPay = true;
    try {
      this.scanning = false;
      await this.page.cancelCurrentPayment?.();
    } finally {
      this.cancellingPay = false;
    }
  }

  onDecode(code) {
    this.$root.$emit("scanner", { code });
    this.scanning = false;
  }

  async kioskReset() {
    this.scanning = false;
    this.payLoading = false;
    if (this.session._id && this.session.status === "pending") {
      this.session.status = "cancelled";
      try {
        await this.$feathers.service("shop/orders/cancel").create({
          _id: this.session._id,
        });
      } catch (e) {
        console.warn(e);
      }
    }
    const welcomeAd = this.$pos.welcomeAds[(Math.random() * this.$pos.welcomeAds.length) | 0];
    if (welcomeAd) {
      await (this.$refs.adPlayer as any).playAd(welcomeAd);
    }
    this.nextOrder();
  }
}
