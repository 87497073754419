
import { Component, Prop, Vue, Watch, mixins, VModel, PropSync } from "nuxt-property-decorator";
import type { SearchField, EditorConfig, EditorViewSetting } from "./plugin";
import _ from "lodash";
import components from "./components";
import EditorSearchMenuChip from "./EditorSearchMenuChip.vue";

export function regEscape(text: string): string {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

@Component({
  components: {
    ...components,
    EditorSearchMenuChip,
  },
})
export default class EditorSearchMenu extends Vue {
  @Prop()
  config: EditorConfig;

  @Prop()
  filter: any;

  @Prop()
  filterFuncResult: any;

  @VModel()
  query: any;

  @Prop()
  context: any;

  @PropSync("showSearchMenu")
  showSearchMenuSync: boolean;

  @PropSync("serializedState")
  serializedStateSync: any;

  @Prop()
  setting: EditorViewSetting;

  @Prop()
  dateCutOffTime: string;

  @Prop(Boolean)
  disablePin: boolean;

  activeState: any = {};
  keywordInput = "";

  created() {
    this.updateSearch = _.throttle(this.updateSearchCore, 1000);
    this.keyword = this.serializedStateSync?.keyword;
    if (this.serializedStateSync?.fields) {
      for (let f of this.serializedStateSync?.fields) {
        const cur = this.config.searchFields.find((p) => p.path === f.path);
        cur.cond = f.cond;
        cur.value1 = f.value1;
        cur.value2 = f.value2;
      }
    } else if (this.config.defaultSearch) {
      for (let f of this.config.defaultSearch) {
        const cur = this.config.searchFields.find((p) => p.path === f.path);
        cur.cond = f.cond;
        cur.value1 = f.value1;
        cur.value2 = f.value2;
      }
    }
  }

  keyword = "";

  onBackspace() {
    if (this.keyword) {
      this.keyword = "";
      return;
    }
    for (let field of this.activeSearchFields.slice().reverse()) {
      this.clearCond(field);
      return;
    }
    for (let field of this.searchPinFields.slice().reverse()) {
      this.clearCond(field);
      return;
    }
  }

  submit() {
    this.keyword = this.keywordInput;
  }

  @Watch("config.searchFields", { deep: true })
  @Watch("keyword")
  @Watch("filter")
  onSearchCommit() {
    if (!this.config) return;
    this.updateSearch();
  }

  updateSearchCore() {
    const query = _.merge({}, this.config.filter, this.filter, this.filterFuncResult);
    for (let field of this.config.searchFields.filter(
      (it) =>
        it.value1 !== null && it.value1 !== "" && !(Array.isArray(it.value1) && !it.value1.length),
    )) {
      if (field.edit?.customSearch) {
        const $and = (query.$and || (query.$and = [])) as any[];
        if (field.value1) {
          $and.push(field.value1);
        }
        continue;
      }
      let val;
      switch (field.cond) {
        case "contains":
          val = {
            $regex: regEscape((field.value1 || "").trim()),
            $options: "i",
          };
          break;
        case "notContains":
          val = {
            $not: {
              $regex: regEscape((field.value1 || "").trim()),
              $options: "i",
            },
          };
          break;
        case "inRange":
          val = { $gte: field.value1, $lte: field.value2 };
          break;
        case "eq":
          val = field.value1;
          break;
        default:
          val = { ["$" + field.cond]: field.value1 };
          break;
      }
      query[field.path] = val;
    }
    if (this.keyword && (this.config.textFields.length || this.config.translateTextFields.length)) {
      const q = {
        $regex: regEscape(this.keyword.trim()),
        $options: "i",
      };
      if (!query.$or) query.$or = [];
      query.$or.push(
        ..._.map(this.config.textFields, (f) => ({
          [f]: q,
        })),
      );
      query.$or.push(
        ..._.map(this.config.translateTextFields, (f) => ({
          [f + ".value"]: q,
        })),
      );
    }
    if (this.keyword && /^[a-f\d]{24}$/i.test(this.keyword.trim())) {
      if (!query.$or) query.$or = [];
      query.$or.push({ _id: this.keyword.trim() });
    }
    this.query = {
      ...this.config.filter,
      ...query,
    };
    this.serializedStateSync = {
      keyword: this.keyword,
      fields: this.config.searchFields
        .map((f) => ({
          path: f.path,
          cond: f.cond,
          value1: f.value1,
          value2: f.value2,
        }))
        .filter((it) => it.cond && it.value1),
    };
  }

  updateSearch: () => void;

  get searchPin() {
    return this.disablePin ? [] : this.setting?.searchPin ?? this.config.searchPin ?? [];
  }

  get searchPinFields() {
    const fields = this.config?.searchFields || [];
    return this.searchPin.map((f) => fields.find((it) => it.path === f)).filter((it) => !!it);
  }

  get activeSearchFields() {
    return (
      this.config?.searchFields?.filter(
        (it) => !this.searchPin.includes(it.path) && this.isSearchActive(it),
      ) || []
    );
  }

  isSearchActive(it: SearchField) {
    return (
      it.value1 !== null && it.value1 !== "" && !(Array.isArray(it.value1) && !it.value1.length)
    );
  }

  toggleCond(item: SearchField) {
    const idx = item.conds.indexOf(item.cond);
    item.cond = item.conds[(idx + 1) % item.conds.length];
  }

  clearCond(item: SearchField) {
    item.value1 = null;
    item.value2 = null;
  }
}
